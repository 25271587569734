import { SET_SAVED_LIST } from '../actions/gig';
import _get from 'lodash/get';

const Initial_value = {
  listError: {}
};

const savedList = (state = Initial_value, action) => {
  switch (action.type) {
    case SET_SAVED_LIST:
      return {
        ...state,
        pagination: _get(action, 'payload.paginationResult', {}),
        listError: {}
      };

    default:
      return state;
  }
};

export default savedList;
