import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Gig from './Gig';
import { resetNewGig } from '../../../store/actions/newGig';

const NewGig = (props) => {
  const [createNewGig, setCreateNewGig] = useState();
  // console.log(createNewGig)

  useEffect(() => {
    props.resetNewGig({});
    setCreateNewGig(true);
  }, []);

  return <>{createNewGig && <Gig />}</>;
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    resetNewGig: (details) => dispatch(resetNewGig(details))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewGig);
