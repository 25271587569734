import React from 'react';
import { TextField } from '@mui/material';
import _get from 'lodash/get';

const FormTextField = (props) => {
  return (
    <TextField
      fullWidth
      name={props.name}
      required={props.required}
      label={props.label}
      onChange={props.handleChange}
      InputProps={props.InputProps}
      onBlur={props.handleBlur}
      value={props.value}
      placeholder={props.placeholder}
      error={_get(props.touched, props.name) && Boolean(_get(props.errors, props.name))}
      helperText={_get(props.touched, props.name) && _get(props.errors, props.name)}    
      variant="outlined"
      InputLabelProps={{ shrink: true }}
      multiline={props.multiline || false}
      type={_get(props, 'type', 'text')}
      {...(props.multiline && { maxRows: '3' })}
    />
  );
};
export default FormTextField;
