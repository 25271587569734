import styled from 'styled-components';

const Name = styled.div`
  // padding-left: 10px;
  font-size: 14px;
  font-weight: 550;
  // font: 14px Poppins;
  // letter-spacing: 0px;
  color: #000; 
  cursor: pointer;
  &:hover {
    color: #0140AA; 
  }
`;

const Custom = styled.div`
  .update-btn {
    color: #f99600;
    border: 1px solid #f99600;
    &:hover {
      border: 1px solid #f99600;
      background-color: #f99600;
      color: #fff;
    }
  }

  .cancle-btn {
    color: #7e84a3;
    border-radius: #7e84a3;
    border: 1px solid #7e84a3;
    &:hover {
      border: 1px solid #7e84a3;
      background-color: #7e84a3;
      color: #fff;
      border-radius: #7e84a3;
    }
  }
`;

const Iconbtn = styled.div`
  .icon-btn {
    &:hover {
      border-radius: 0;
      background-color: transparent;
      cursor: default;
    }
  }
  .icon-btn1 {
    &:hover {
      border-radius: 55%;
      padding: 18px;
    }
  }
`;

const Iconbtn1 = styled.div`
  .icon-btn1 {
    border-radius: 55%;
    padding: 10px;
  }
`;

export { Name, Custom, Iconbtn, Iconbtn1 };
