import React from 'react';
import './FooterAfterSignIn.styles.css';
import { useHistory } from 'react-router-dom';
import FindGigPopup from '../HomePage/FindGigPopup';
import FindEngineerPopup from '../HomePage/FindEngineerPopup';
import GigLogoBlue from '../../assests/image/gigLogoBlue.svg'
import InstagramF from '../../assests/image/instagramF.svg'
import TwiterF from '../../assests/image/twiterF.svg'
import LinkedinF from '../../assests/image/linkedinF.svg'
import FacebookF from '../../assests/image/facebookF.svg'
import { Link } from '@mui/material';

const FooterAfterSignIn = (props) => {

    const [open, setOpen] = React.useState(false);
    const [openSignUp, setOpenSignUp] = React.useState(false);
    const [openForgetEngineer, setOpenForgetEngineer] = React.useState(false);
    const [openSignUpModal, setOpenSignUpModal] = React.useState(false);
    const [openBusinessModal, setOpenBusinessModal] = React.useState(false);
    const [openForgetBusiness, setopenForgetBusiness] = React.useState(false);

    const modalEngineerSignUpOpen = () => {
        setOpenSignUp(true);
    }

    const businessModal = () => {
        setOpenBusinessModal(true);
    }

    const history = useHistory();
    return (
        <React.Fragment>
            <div className="footer">
                <div className='footer-large'>
                    <div className="f-logo">
                        <img src={GigLogoBlue} alt="GigLogoBlue" fontSize="medium" />
                    </div>

                    <div className="desc">

                        <div className="desc-div1">
                            <p>
                                Designed by passionate engineers, Gig Engineer seamlessly connects businesses with the precise skills they
                                need, when they them. Our platform enables companies to respond quicker to changes in market conditions
                                and fluctuating demand, enhancing business agility and efficiency, while empowering engineers with greater
                                independence and flexibility to work on exciting projects from across the world.
                            </p>
                            <p>
                                At Gig Engineer, we're not not just envisioning the Future of Engineering Work, we're building it!
                            </p>
                        </div>

                        <div className="desc-div2">
                            <h2 style={{ color: '#003FAA' }}>About Us</h2>
                            <p onClick={() => { history.push('/aboutus') }}>About</p>
                            <p>
                                <a style={{ color: '#0B213E' }} className='contact-and-faqs' href='/#connected-text'>
                                    Contact
                                </a>
                            </p>
                            <p onClick={modalEngineerSignUpOpen}>Find Gig</p>
                            <p onClick={businessModal}>Find Engineer</p>
                        </div>

                        <div className="desc-div3">
                            <h2 style={{ color: '#003FAA' }}>Quick Links</h2>
                            {/* <p>Features</p> */}
                            <p>
                                <a style={{ color: '#0B213E', textDecoration: 'none' }} href='/#faq-redirect'>
                                    FAQs
                                </a>
                            </p>
                            <p onClick={() => { history.push('/privacypolicy') }}>Privacy Policy</p>
                            <p onClick={() => { history.push('/termsconditions') }}>Terms & Conditions</p>
                        </div>

                        <div className="desc-div4">
                            <h2 style={{ color: '#003FAA' }}>Community Links</h2>
                            <p onClick={() => {
                                history.push(`/signup`);
                            }}>Register</p>
                            <p>
                                <a style={{ color: '#0B213E', textDecoration: 'none' }} target="_blank" href='https://www.linkedin.com/company/gig-engineer/posts/?feedView=articles&viewAsMember=true'>
                                    Insights
                                </a>
                            </p>
                        </div>

                        <div className="desc-div5">
                            <h2 style={{ color: '#003FAA' }}>Social Media</h2>
                            <div className='insta-twiter-container'>
                                <a href="http://Instagram.com/gig.engineer/" target="_blank">
                                    <img src={InstagramF} alt="InstagramF" fontSize="medium" width="18%" />
                                </a>
                                <a href="https://twitter.com/GigEngineer" target="_blank">
                                    <img style={{ marginLeft: '6px' }} src={TwiterF} alt="TwiterF" fontSize="medium" width="18%" />
                                </a>
                            </div>
                            <div className='linkedin-facebook-container'>
                                <a href=" https://www.linkedin.com/company/gig-engineer" target="_blank">
                                    <img src={LinkedinF} alt="LinkedinF" fontSize="medium" width="18%" />
                                </a>
                                <a href="https://www.facebook.com/GigEngineer/" target="_blank">
                                    <img style={{ marginLeft: '6px' }} src={FacebookF} alt="FacebookF" fontSize="medium" width="18%" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='break-line-footer'></div>
                <div className='copy-right-text-footer'>Copyright © 2024 Gig Engineer. All Rights Reserved</div>

            </div>
            {open ? <FindGigPopup signInEngineerPopup={open} modalEngineerOpen={(e) => { setOpen(true) }} modalEngineerClose={(e) => { setOpen(false) }} modalEngineerSignUpOpen={(e) => { setOpenSignUp(true) }} modalForgetEngineer={(e) => { setOpenForgetEngineer(true) }} modalEngineerSignUpClose={(e) => { setOpenSignUp(false) }} modalForgetClose={(e) => { setOpenForgetEngineer(false) }} /> : <></>}

            {openSignUp ? <FindGigPopup signUpModalOpen={openSignUp} modalEngineerSignUpOpen={(e) => { setOpenSignUp(true) }} modalEngineerOpen={(e) => { setOpen(true) }} modalEngineerSignUpClose={(e) => { setOpenSignUp(false) }} modalEngineerClose={(e) => { setOpen(false) }} modalForgetClose={(e) => { setOpenForgetEngineer(false) }} /> : <></>}

            {openForgetEngineer ? <FindGigPopup forgetSignIn={openForgetEngineer} modalForgetEngineer={(e) => { setOpenForgetEngineer(true) }} modalEngineerSignUpOpen={(e) => { setOpenSignUp(true) }} modalForgetClose={(e) => { setOpenForgetEngineer(false) }} modalEngineerClose={(e) => { setOpen(false) }} modalEngineerSignUpClose={(e) => { setOpenSignUp(false) }} /> : <></>}


            {openSignUpModal ? <FindEngineerPopup signInBusinessPopup={openSignUpModal} modalBusinessOpen={(e) => { setOpenSignUpModal(true) }} modalBusinessClose={(e) => { setOpenSignUpModal(false) }} businessModal={(e) => { setOpenBusinessModal(true) }} ForgetBusinessModalOpen={(e) => { setopenForgetBusiness(true) }} modalBusinessSignUpClose={(e) => { setOpenBusinessModal(false) }} modalBusinessForgetClose={(e) => { setopenForgetBusiness(false) }} /> : <></>}

            {openBusinessModal ? <FindEngineerPopup signUpBusinessModal={openBusinessModal} businessModal={(e) => { setOpenBusinessModal(true) }} modalBusinessOpen={(e) => { setOpenSignUpModal(true) }} modalBusinessSignUpClose={(e) => { setOpenBusinessModal(false) }} modalBusinessClose={(e) => { setOpenSignUpModal(false) }} modalBusinessForgetClose={(e) => { setopenForgetBusiness(false) }} /> : <></>}

            {openForgetBusiness ? <FindEngineerPopup forgetBusinessModal={openForgetBusiness} ForgetBusinessModalOpen={(e) => { setopenForgetBusiness(true) }} modalBusinessOpen={(e) => { setOpenSignUpModal(true) }} businessModal={(e) => { setOpenBusinessModal(true) }} modalBusinessForgetClose={(e) => { setopenForgetBusiness(false) }} modalBusinessClose={(e) => { setOpenSignUpModal(false) }} modalBusinessSignUpClose={(e) => { setOpenBusinessModal(false) }} /> : <></>}
        </React.Fragment>
    );
};

export default FooterAfterSignIn;
