import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Container, Grid, Typography } from '@mui/material';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import { Stepper, Step } from 'react-form-stepper';
import { TypographyBold, PaddedBox, CustomRow, EditedCard, PaddedFooter } from '../../app.styles';
import { GigContainer } from './NewGig.Styles';
import { identifyNewGigStep } from '../../../utils/schemaValidation';
import Step1 from './sections/Step_1';
import Step2 from './sections/Step_2';
import Step3 from './sections/Step_3';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import StepHeader from './sections/StepHeader';
import Footer from '../../../components/footer1/footer';
// import './sections/Step';

const NewGig = (props) => {

  const defaultObject = {
    titleAndSkills: {
      projectTitle: '',
      projectDetails: '',
      skillsRequired: []
    },
    scopeAndBudget: {
      projectDuration: '',
      startDate: '',
      availabilityHoursPerWeek: '',
      hourlyRate: '',
      budget: '',
      currency: 'R'
    },
    deliverablesAndLocation: {
      location: {
        country: '',
        state: '',
        city: ''
      },
      deliverables: [
        {
          deliverableTitle: '',
          deliverableDesc: ''
        }
      ]
    },
    reComputeStep: true,
    newGigSaveSucess: false
  };

  const [activeStep, setActiveStep] = useState();
  const newGigDetails = !_isEmpty(_get(props, 'newGig', {})) ? props.newGig : defaultObject;

  useEffect(() => {
    async function someasync() {
      const stepDetails = await identifyNewGigStep(newGigDetails);
      setActiveStep(stepDetails);
    }
    newGigDetails.reComputeStep && someasync();
  }, [newGigDetails]);

  const changeActiveStep = (newStep) => {
    if (newStep && newStep < activeStep && !_get(props, 'newGig.newGigSaveSucess')) {
      setActiveStep(newStep);
    }
  };

  const getHeadTitle = (title) => {
    if (title) {
      setTitleState(title);
    }
  };

  const getStepComponent = (step, defaultObject) => {
    switch (step) {
      case 1:
        return <Step1 defaultObject={defaultObject} changeHeadTitle={getHeadTitle} />;
      case 2:
        return <Step2 defaultObject={defaultObject} methodBackClick={changeActiveStep} changeHeadTitle={getHeadTitle} />;
      case 3:
        return <Step3 defaultObject={defaultObject} methodBackClick={changeActiveStep} changeHeadTitle={getHeadTitle} />;
    }
  };

  const [titleState, setTitleState] = useState("");

  return (
    <>
      <PaddedBox style={{ paddingLeft: `${!!props.auth && !!props.auth.isDrawerOpen && props.auth.isDrawerOpen == true ? "240px" : "0px"}` }} secondary>
        <CustomRow sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start !important" }} container>
          <Typography
            variant="h6"
            component="h6"
            color="pColor.main"
            sx={{ fontWeight: 'Medium', textTransform: 'capitalize', fontSize: '22px' }}
          >
            Post New Gig
          </Typography>
          <Card sx={{ marginTop: '20px', marginBottom: '40px', width: "100%" }}>
            <CardContent>
              <GigContainer container>
                {activeStep && (
                  <>
                    <Grid container item xs={12} justifyContent="center" alignItems="center">

                    </Grid>
                    <Grid container item xs={12} justifyContent="center">
                      {getStepComponent(activeStep, defaultObject)}
                    </Grid>
                  </>
                )}
              </GigContainer>
            </CardContent>
          </Card>
        </CustomRow>
      </PaddedBox>
      <PaddedFooter style={{ paddingLeft: `${!!props.auth && !!props.auth.isDrawerOpen && props.auth.isDrawerOpen == true ? "240px" : "0px"}` }}>
        <Footer />
      </PaddedFooter>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    newGig: state.newGig
    //   error: state.profile.error,
    //   saveSuccess: state.profile.saveSuccess
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // setProfileError: (details) => dispatch(setProfileError(details))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewGig);
