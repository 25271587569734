export const foods = [
  'Frozen yoghurt',
  'Ice cream sandwich',
  'Eclair',
  'Cupcake',
  'Gingerbread',
  'Yoghurt',
  'Sandwich',
  'Curd',
  'Biscuits',
  'Cake'
];

export const persons = [
  'Olivia',
  'Noah',
  'Emma',
  'Oliver',
  'Ava',
  'William',
  'Sophia',
  'Elijah',
  'Isabella',
  'James'
];

function createData(name, calories, fat, carbs, protein) {
  return [name, calories, fat, carbs, protein];
}

export function getData(val, data = foods) {
  const arr = [];
  for (let i = 0; i < 10; i++) {
    let stringval = val * 10 + i + '';
    arr.push(
      createData(
        `${data[val]}-${stringval}`,
        (val + 1) * (val + 1),
        (val + 2) * (val + 2),
        (val + 3) * (val + 3),
        (val + 4) * (val + 4)
      )
    );
  }
  return arr;
}

export function checkIfPublished(arr){
  let tempArr = [];
  arr.map((item,index)=>{
    if(item.isPublished){
      tempArr.push(item)
    }
  })
  return tempArr
}