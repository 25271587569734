import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Grid } from '@mui/material';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
// import { AbsolutePosDiv } from './app.styles';
import Header from './header/header';
import PersistentDrawerLeft from '../containers/AuthLayout/AuthLayout';
import RegisterChat from '../components/Messenger/RegisterChat';
import sideNavLinks from '../routes/sideNavLinks';
import { getProfileDetApi } from '../services/profile';
import UnAuthorizedComp from '../components/NotFound/UnAuthorized';
import { getUserId, getUserType } from '../utils/user';
import { setProfileDetails, resetProfileSuccess } from '../store/actions/profile';
import { setNotification } from '../store/actions/chat';
import { getUserProfileRoute } from '../routes/authcheck';
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";

const Authorization = (props) => {
  const userType = getUserType();
  const userId = getUserId();
  const onGetNotifications = (data) => {
    props.setNotification(data);
    // console.log(data);
  }
  useEffect(() => {
    !_get(props, 'profile.basicInfo.name') && getProfileDetApi(props.setProfileDetails);
    //get specific user chat
    if (userType === "business") {
      socket.emit('getNotification', { "businessId": userId, "userRole": ["business"] });
      socket.on('getNotification', onGetNotifications)
    }
    if (userType === "engineer") {
      socket.emit('getNotification', { "engineerId": userId, "userRole": ["engineer"] }, (msg) => console.log(msg));
      socket.on('getNotification', onGetNotifications)
    }
    if (userType === "admin") {
      socket.emit('getNotification', { "userRole": ["admin"] });
      socket.on('getNotification', onGetNotifications)
    }
  }, []);

  const menuList = sideNavLinks(props.menuType); // get the menu list to be showed on the left nav
  // const userType = getUserType(); // get the user type from tokens
  // user type in tokens and in user type mentioned route should allways match
  const routeAuthorized = (props.routeAuth && props.routeAuth.indexOf(userType) >= 0);

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
      </Box>
      {/* {(userType === 'business') && (
        <Header
          profileName={_get(props, 'profile.basicInfo.name', 'Name Not updated')}
          displayPic={_get(props, 'profile.companyDetails.profilePic', '')}
          userType={userType}
        />
      )}

      {(userType === 'engineer') && (
        <Header
          profileName={_get(props, 'profile.basicInfo.name', 'Name Not updated')}
          displayPic={_get(props, 'profile.myProfile.profilePic', '')}
          userType={userType}
        />
      )}
      {(userType === 'admin') && (
        <Header
          profileName={_get(props, 'profile.basicInfo.name', 'Name Not updated')}
          displayPic={_get(props, 'profile.basicInfo.profilePic', '')}
          userType={userType}
        />
      )} */}


      {/* unauthorized user show error template */}
      {!routeAuthorized && <UnAuthorizedComp />}

      {routeAuthorized && (
        <>
          {!_isEmpty(menuList) && (
            // <PersistentDrawerLeft
            //     menuList={menuList}
            //     percentage={_get(props, 'profile.profileCompletionPercent', 0)}
            //     userType={userType}
            //     menuType={props.menuType}
            //     resetProfileSuccess={props.resetProfileSuccess}
            //   >
            <>
              {_get(props, 'profile.basicInfo.name', '') != '' ? <RegisterChat name={_get(props, 'profile.basicInfo.name')}></RegisterChat> : <></>}

              <>
                {(_get(props, 'profile.basicInfo.emailId') || userType === 'admin') && props.renderComp}
              </>
            </>
            // </PersistentDrawerLeft>
          )}


        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    profile: state.profile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    resetProfileSuccess: (details) => dispatch(resetProfileSuccess(details)),
    setProfileDetails: (details) => dispatch(setProfileDetails(details)),
    setNotification: (details) => dispatch(setNotification(details)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Authorization);
