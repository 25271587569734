import axios from 'axios';
import _get from 'lodash/get';
import { store } from '..';
import { setLocationData } from '../store/actions/gig';
import apiService from './apiServices';

export const getExpertiseSkillsDetApi = async () => {
  const request = {
    method: 'get',
    url: '/static/expertisewithskills/'
  };
  try {
    const response = await apiService(request);
    if (response) {
      return response;
    }
  } catch (err) {
    // console.log(err, 'err');
  }
};

export const getSkillsList = async () => {
  const request = {
    method: 'get',
    url: '/static/skillslist/'
  };
  try {
    const response = await apiService(request);
    if (response) {
      return response;
    }
  } catch (err) {
    // console.log(err, 'err');
  }
};

export const getToolslist = async () => {
  const request = {
    method: 'get',
    url: '/static/tools/'
  };
  try {
    const response = await apiService(request);
    if (response) {
      return response;
    }
  } catch (err) {
    // console.log(err, 'err');
  }
};

export const getCountryStateCity = async () => {
  const headers = {
    'Content-Type': 'application/json',
    'Content-Encoding': 'gzip'
  };
  if(!!store.getState().gigs.locationData){
    return store.getState().gigs.locationData
  }
  else{
    try {
      const response = await axios.get("https://gigengineer-production.s3.af-south-1.amazonaws.com/public/countrystatecity.json.gz",{headers: headers});
      if (response) {
        store.dispatch(setLocationData({"data": response.data}))
        return response;
      }
    } catch (err) {
      console.log(err, 'err');
    }
  }
};

export const getLanguage = async () => {
  const request = {
    method: 'get',
    url: '/static/language/'
  };
  try {
    const response = await apiService(request);
    if (response) {
      return response;
    }
  } catch (err) {
    // console.log(err, 'err');
  }
};


export const getUniversityApi = async () => {
  const headers = {
    'Content-Type': 'application/json',
    'Content-Encoding': 'gzip'
  };
  try {
    const response = await axios.get("https://gigengineer-production.s3.af-south-1.amazonaws.com/public/EducationDataset.json.gz",{headers: headers})
    if (response) {
      return response;
    }
  } catch (err) { }
};
