import _get from 'lodash/get';
import apiService from './apiServices';

export const getFavorites = async (data, setGigsList, setListDataEngineer) => {
  const request = {
    data,
    method: 'post',
    url: '/opportunity/getFavourites'
  };
  const response = await apiService(request);
  if (response && response.status === 200) {
    // sucess action
    setGigsList(_get(response, 'data', {}));
    setListDataEngineer(_get(response, 'data', {}))
  }
};

export const updateFavorite = async (data, updateFavoriteStatus) => {
  const request = {
    data,
    method: 'post',
    url: '/opportunity/updateFavourite'
  };
  const response = await apiService(request);
  if (response && response.status === 200) {
    updateFavoriteStatus(_get(response, 'data', {}), data);
  }
};
