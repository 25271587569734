import { toast } from 'react-toastify';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';

const recursiveError = (error, returnSet) => {
  const childData = _get(error, 'children', []);
  if (!_isEmpty(childData)) {
    _forEach(childData, (child) => {
      return recursiveError(child, returnSet);
    });
  } else {
    _forEach(_get(error, 'constraints', {}), (value) => {
      returnSet.add(value);
    });
    return {};
  }
};

const getValidationErrors = (errdata) => {
  const returnSet = new Set();
  const errors = _get(errdata, 'data', []);
  if (errors && errors.length > 0) {
    errors.map((error) => {
      recursiveError(error, returnSet);
    });
  }
  return Array.from(returnSet);
};

const showVaildationErrors = (error) => {
  const validationErrors = getValidationErrors(error);
  if (!_isEmpty(validationErrors)) {
    validationErrors.map((value, i) => {
      toast.warn(value);
    });
  } else if (error && error.message) {
    toast.warn(error.message);
  } else {
    toast.warn('OOPS!!! Something went wrong please try again.');
  }
};

export default showVaildationErrors;
