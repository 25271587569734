import styled from 'styled-components';

const HomeBgDiv = styled.div``;
const MainHeroSection = styled.div`
.roleHeroHomeBtn{
  background-color: #fff;
  color: #0140AA;
}
.roleHeroHomeBtn:hover{
  background-color: #99B4E3;
  color: #fff;
}
`;
const MdScreen = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  justify-content: center;
  gap: 165px;
  padding-left: 140px;
    padding-right: 40px;
    padding-top: 15px;
    padding-bottom: 15px;
@media only screen and (min-width: 320px) and (max-width: 750px) {
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  justify-content: center;
  gap: 0px;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 24px;
  padding-bottom: 24px;
}

@media only screen and (min-width: 900px) and (max-width: 1020px) {
  display: grid;
  grid-template-columns: auto 1fr auto;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  gap: 0px;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 24px;
  padding-bottom: 24px;
  margin-right: 0px;
}

@media only screen and (min-width: 1020px) and (max-width: 1100px) {
  display: grid;
  grid-template-columns: auto 1fr auto;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  gap: 50px;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 24px;
  padding-bottom: 24px;
  margin-right: 0px;
}

@media only screen and (min-width: 1100px) and (max-width: 1300px) {
  display: grid;
  grid-template-columns: auto 1fr auto;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  gap: 60px;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 24px;
  padding-bottom: 24px;
  margin-right: 105px;
}

@media only screen and (min-width: 1050px) and (max-width: 1430px) {
  display: grid;
  grid-template-columns: auto 1fr auto;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  gap: 70px;
  padding-left: 140px;
  padding-right: 0px;
  padding-top: 24px;
  padding-bottom: 24px;
  margin-right: 0px;
}

@media only screen and (min-width: 1430px) and (max-width: 1530px) {
  display: grid;
  grid-template-columns: auto 1fr auto;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  gap: 140px;
  padding-left: 140px;
  padding-right: 0px;
  padding-top: 24px;
  padding-bottom: 24px;
}

@media only screen and (min-width: 1540px) and (max-width: 2400px) {
  display: grid;
  grid-template-columns: auto 1fr auto;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  gap: 280px;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 24px;
  padding-bottom: 24px;
}
}
  .BtnSignup {
    background-color: #F0F6FF;
    color: #003FAA;
    padding: 5px 20px;
    text-transform: none;
    border: 1px solid #003FAA;
  }
  .BtnSignup:hover {
    background-color: #003FAA;
    color: #fff;
  }
  .MenuLinkCst {
    color: #003FAA;
    font-size: 15px;
    font-weight: 500;
    position: relative;
    cursor: pointer;
  }
  .MenuLinkCst:hover {
    font-weight: 500;
  }
  .MenuLinkCst::after {
    content: '';
    display: block;
    width: 0;
    height: 3px;
    margin-top: -2px;
    background: #f99600;
    transition: width 0.3s;
  }
  .MenuLinkCst:hover::after {
    width: 60%;
  }
  .BtnSignin {
    /* background-image: linear-gradient(-90deg, #0277bd, #0277bd); */
    background-color: #F0F6FF;
    color: #003FAA;
    text-transform: none;
    padding: 5px 24px;
    border: 1px solid #003FAA;
  }
  .BtnSignin:hover {
    background-color: #003FAA;
    color: #fff;
  }
`;

const FeatureItem = styled.div``;
const LinkRightArrow = styled.div`
  margin-left: 30px;
  font-size: 25px;
`;
const LeftArtworkSection = styled.div`
  position: relative;
  padding: 50px 0;
`;
const RightArtworkSection = styled.div`
  position: relative;
  padding: 50px 0;
`;
const LeftArtWorkSec = styled.div``;
const RightArtWorkSec = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 70%;
  overflow: hidden;
  z-index: -1;
`;
const TimelineSection = styled.div`
padding-top: 50px;

`;
const PerformanceCalculatorSection = styled.div`
margin: 50px 0;

`;
const TestimonialSection = styled.div`
margin: 50px 0;

`;
const ProfileImage = styled.div`
img{
width: 130px;
height: 160px;
border-radius: 10px;
object-fit: cover;
}
`;
const ProfileDescription = styled.div`
`;

export {
  ProfileDescription,
  ProfileImage,
  TestimonialSection,
  PerformanceCalculatorSection,
  TimelineSection,
  LeftArtWorkSec,
  RightArtWorkSec,
  MdScreen,
  MainHeroSection,
  HomeBgDiv,
  FeatureItem,
  LinkRightArrow,
  LeftArtworkSection,
  RightArtworkSection
};