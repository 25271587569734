import React, { useEffect, useState } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button, Typography, Dialog, Grid } from '@mui/material';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _find from 'lodash/find';
import { TypographyBold, PaddedBox, CustomRow, PaddedFooter } from '../../app.styles';
import { Box } from '@mui/system';
import './gigDetail.css';
import GigDetails_Attributes from './GigDetails_Attributes';
import { gigDetailId, opportunitywithdrawApplications } from '../../../services/gig';
import { getUserType } from '../../../utils/user';
import Footer1 from '../../../components/footer1/footer';
import InvoiceModal from '../../../containers/invoices/common/InvoiceModal/index';
import PageLoader from '../../../components/Loaders/PageLoader';

const GigDetailsP = (props) => {
  const [job, setList] = useState([]);
  const { id } = useParams();
  const history = useHistory();
  const [userType, setUserType] = React.useState(getUserType());
  const location = useLocation();
  const [withdrowState, setWithdrowState] = useState(false);
  const [open, setOpen] = useState(false);
  const [addInvoiceModalOpen, setaddInvoiceModalOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const withdrowSubmit = async (e) => {
    let data = {
      gigId: id
    };

    opportunitywithdrawApplications(data).then((e) => {
      socket.emit('postNotification', {
        notificationDescription: `You have withdrawn your application for ${job?.gig.titleAndSkills.projectTitle}`,
        userRole: ['engineer'],
        engineerId: props.profile.basicInfo.userId,
        isRead: false,
        engineerProfilePic: props.profile.myProfile.profilePic,
        redirectUrl: '/applied'
      });
      setWithdrowState(true);
    });
  };

  const setData = (data) => {
    setList(data);
  };

  const fetchData = async () => {
    const gigd = await gigDetailId(id);
    setData(gigd);
  };

  useEffect(() => {
    if (userType === 'admin') {
      setList(location.state);
    }

    fetchData();
  }, []);

  return (
    <>
      <PaddedBox
        style={{
          paddingLeft: `${
            !!props.auth && !!props.auth.isDrawerOpen && props.auth.isDrawerOpen == true
              ? '240px'
              : '0px'
          }`
        }}
      >
        {addInvoiceModalOpen && (
          <InvoiceModal
            job={job?.gig}
            addInvoiceModalOpen={addInvoiceModalOpen}
            setaddInvoiceModalOpen={setaddInvoiceModalOpen}
          />
        )}
        <CustomRow container>
          <TypographyBold
            variant="h6"
            component="h6"
            sx={{
              color: '#0140AA'
            }}
          >
            <Button
              variant="outlined"
              className="h-color-button-outlined smallButton"
              onClick={() => {
                history.goBack(-1);
              }}
            >
              Back
            </Button>
            <span>&nbsp;&nbsp;&nbsp;</span>
            Gig Details
          </TypographyBold>
        </CustomRow>
        {_isEmpty(job) && (
          <Grid
            container
            item
            justifyContent="center"
            xs={12}
            className="h-margin-top-10 h-padding-top-16"
          >
            <PageLoader />
          </Grid>
        )}
        <div className="main-container-gig-details-page">
          <div className="Detail-View-Container">
            <div className="Edge-gig">
              <GigDetails_Attributes />

              <div className="gig-details-BackBtn">
                {userType === 'engineer' ? (
                  <div>
                    {location.pathname.includes('active') && (
                      <Button
                        variant="contained"
                        className="primary-Main-Btn"
                        sx={{ width: '140px' }}
                        onClick={() => {
                          setaddInvoiceModalOpen(true);
                        }}
                      >
                        New Invoice
                      </Button>
                    )}
                  </div>
                ) : (
                  <div></div>
                )}

                {location.pathname.includes('hires') && (
                  <Button
                    variant="contained"
                    sx={{
                      width: '100px',
                      height: '45px',
                      backgroundColor: '#3F52B6',
                      borderRadius: '5pdx'
                    }}
                    onClick={() => {
                      history.goBack(-1);
                    }}
                  >
                    Unhire
                  </Button>
                )}
                {userType === 'engineer' || userType === 'business' ? (
                  <div>
                    {location.pathname.includes('applied') && (
                      <Button
                        variant="contained"
                        className={`primary-Btn`}
                        sx={{
                          width: '120px',
                          height: '45px',
                          backgroundColor: '#3F52B6',
                          borderRadius: '5pdx'
                        }}
                        onClick={() => {
                          setOpen(true);
                        }}
                      >
                        Withdraw
                      </Button>
                    )}
                    <Dialog open={open} onClose={handleClose}>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          padding: '20px'
                        }}
                      >
                        <br />
                        <Typography>
                          Are you sure you want to withdraw your application from this gig? This
                          will remove the gig from your applied gigs.
                        </Typography>
                        <Box
                          sx={{
                            display: 'flex',
                            marginTop: '50px'
                          }}
                        >
                          <Button
                            variant="contained"
                            className="popup-process"
                            sx={{
                              width: '100px',
                              height: '38px',
                              backgroundColor: '#3F52B6',
                              borderRadius: '5pdx'
                            }}
                            onClick={() => {
                              withdrowSubmit();
                              history.push(`/discover`);
                            }}
                          >
                            Proceed
                          </Button>
                          <Box
                            sx={{
                              marginLeft: '20px'
                            }}
                          >
                            <Button
                              variant="contained"
                              className="popup-cancel"
                              type="submit"
                              sx={{
                                width: '100px'
                              }}
                              onClick={handleClose}
                            >
                              Cancel
                            </Button>
                          </Box>
                        </Box>
                      </Box>
                    </Dialog>
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
            </div>
          </div>
        </div>
      </PaddedBox>
      <PaddedFooter
        style={{
          paddingLeft: `${
            !!props.auth && !!props.auth.isDrawerOpen && props.auth.isDrawerOpen == true
              ? '200px'
              : '0px'
          }`
        }}
      >
        <Footer1 />
      </PaddedFooter>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    profile: state.profile
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    resetProfileSuccess: (details) => dispatch(resetProfileSuccess(details)),
    setProfileDetails: (details) => dispatch(setProfileDetails(details))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GigDetailsP);
