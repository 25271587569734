import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Pagination from '@mui/material/Pagination';
import './GridTable.css';
import VerifiedIcon from '../../assests/VerifiedTick.svg';
const EmptyBox = (props) => {
  return (
    <Box display="flex" height={140}>
      <Box m="auto" className="emptyBox">
        {props.emptyMessage}
        {props.emptyBoxButtonText ? (
          <div>
            <br />
            <Button
              m="auto"
              variant="outlined"
              className="h-color-button-outlined smallButton"
              onClick={() => {
                props.onEmptyBoxButtonClick && props.onEmptyBoxButtonClick();
              }}
            >
              {props.emptyBoxButtonText}
            </Button>
          </div>
        ) : (
          <></>
        )}
      </Box>
    </Box>
  );
};

const ViewAll = (props) => {
  return (
    <Box display="flex" height={26} sx={{ marginTop: '10px' }}>
      <Box className="viewAll">
        <span
          onClick={() => {
            props.viewAll && props.viewAll();
          }}
        >
          View All {'>'}
        </span>
      </Box>
    </Box>
  );
};

export default function GridTable(props) {
  const history = useHistory();

  const goToGigs = (row) => {
    history.push(`/admin/owner/details/${row[row.length - 1]}/allgigs`);
  };

  const handleRowClick = (e, val) => {
    props.handleRowClick && props.handleRowClick(val);
  };

  const handleRClick = (e, val) => {
    props.handleRClick && props.handleRClick(val);
  };

  return (
    <React.Fragment>
      <TableContainer
        xs={12}
        md={12}
        lg={12}
        component={Paper}
        className="tableStyles bottom-Margin"
      >
        <Box sx={{
          fontSize: "1.25rem", color: "#0140AA", lineHeight: '1.6', letterSpacing: '0.0075em',
          marginBottom: '0.25em', fontWeight: '500'
        }}>{props.headProp}</Box>
        <Table aria-label="simple table" >
          <TableHead>
            <TableRow className={props.className}>
              {props.headers.map((heading, index) => (
                <TableCell align="center" key={heading + '-' + index} className="heading" sx={{
                  lineHeight: '24px', fontStyle: 'bold', fontSize: '14px', fontWeight: '700', backgroundColor: '#F2F5F8'
                }}>
                  {heading}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.rows.map((row, index) => (
              <TableRow
                className='word-break'
                key={index}>
                {row.map(
                  (value, i) =>
                    props.headers.length > i && (
                      <TableCell
                        title={i < 2 && (i == 0 ? "View Gig Details" : "View Applicants")}
                        align="center"
                        key={value + '-' + i}
                        onClick={(e) => {
                          if (i == 1) {
                            handleRowClick(e, row);
                          }
                          if (i == 0) {
                            handleRClick(e, row);
                          }
                        }}
                      >
                        {props.dataFrom == 'ownerList' && i == 5 ? (
                          <Box
                            variant="outlined"
                            className="sectionButton1"
                            onClick={() => goToGigs(row)}
                          >
                            {value}
                          </Box>
                        ) :
                          i == 0 ?
                            (<div className='pointer-tooltip-tabledata-business'>
                              {value}
                              {
                                !!row[6] && !!row[6]?.userId?.profileData?.isVerified && (row[6]?.userId?.profileData?.profileCompletionPercent == 100)
                                &&
                                <img className='verified-icon-applicate' src="https://gigengineer-production.s3.af-south-1.amazonaws.com/Assets/Blue+Tick+Verification.svg" />
                              }
                            </div>)
                            :
                            i == 1 ?
                              (<div className='tooltip-applicant-text'>
                                {value}
                              </div>)
                              :
                              (
                                value
                              )}

                      </TableCell>
                    )
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {props.rows.length === 0 ? EmptyBox(props) : <></>}
        {props.viewAll ? ViewAll(props) : <></>}
      </TableContainer>
      {
        props.rows.length === 0 || props.hidePagination ? (
          <></>
        ) : (
          <Pagination
            count={props.pagination}
            shape="rounded"
            className="pagination-root"
            onChange={props.handleChange}
            defaultPage={props.defaultPage || 1}
          />
        )
      }
    </React.Fragment >
  );
}

GridTable.propTypes = {
  /**
   * Optional change handler
   */
  handleRowClick: PropTypes.func,
  handleRClick: PropTypes.func,
  /**
   * incrementby value
   */
  headers: PropTypes.arrayOf(PropTypes.string),
  /**
   * Stepper label
   */
  rows: PropTypes.array.isRequired,
  /**
   * Stepper label
   */
  pagination: PropTypes.number.isRequired,
  /**
   * func legend
   */
  handleChange: PropTypes.func.isRequired,
  /**
   * empty box message
   */
  emptyMessage: PropTypes.string,
  /**
   * empty box button string
   */
  emptyBoxButtonText: PropTypes.string,
  /**
   * func on click of empty button
   */
  onEmptyBoxButtonClick: PropTypes.func,

  headProp: PropTypes.string,
};

GridTable.defaultProps = {
  handleRowClick: () => { },
  handleRClick: () => { },
  headers: [],
  rows: [[], [], []],
  pagination: 1,
  handleChange: () => { },
  emptyMessage: '',
  headProp: '',
  emptyBoxButtonText: '',
  onEmptyBoxButtonClick: () => { }
};
