import styled from 'styled-components';

const CustomBox = styled.div`
  padding: 10px;
  max-width: 328px;
`;

const SignUpMsg = styled.div`
  font-size: 13px;
  text-align: center;
`;

const ForgotPwd = styled.div`
  font-size: 12px;
  text-align: center;
  a {
    color: #f0142f;
  }
`;

const SignInDisclamer = styled.div`
  margin-top: -10px;
  font-size: 12px;
  text-align: center;
  padding: 10px 0;
`;

const RadioInput = styled.div`
  color: rgba(0, 0, 0, 0.6);
`;

export { CustomBox, SignUpMsg, ForgotPwd, SignInDisclamer, RadioInput };
