import React, { useEffect, useState } from 'react';
import './NotUserName.scss';
import Notusernameimg from '../../assests/Notusernameimg.svg'
import { useHistory } from 'react-router-dom';
import { getUserType } from '../../utils/user';

const NotUserName = () => {

    const history = useHistory();
    const userType = getUserType();
    const [seconds, setSeconds] = useState(10);

    useEffect(() => {
        const interval = setInterval(() => {
            setSeconds(seconds => seconds - 1);
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    const redirectPage = () => {
        if (userType === 'engineer') {
            history.push('/myprofile')
        } else {
            history.push('/')
        }
    }

    return (
        <div>
            <div className='main-container-notusername'>
                <div className='all-items-user'>
                    <div className='img-not-username'>
                        <img src="https://gigengineer-production.s3.af-south-1.amazonaws.com/Assets/Link+Does+Not+Exist.svg" alt="notusernameimg" fontSize="medium" width="30%" />
                    </div>
                    <p className='user-1-text'>This Username does not exist!</p>
                    <p className='user-2-text'>You are being redirected back in {seconds === 0 ? redirectPage() : seconds} seconds...</p>
                </div>
            </div>
        </div>
    )
}

export default NotUserName;