import React from 'react'
import { toast } from 'react-toastify';
import './verifyRating.scss'

function RejectRatingModal(props) {
    const { handleCloseRejectRatingModal, handleRejectReview, activeRating, activeRatingIndex } = props;

    return (
        <div className='verify-modal-wrapper'>
            <div className="modal-card">
                <div className="modal-top">
                    <h2>Reject review</h2>
                    <span onClick={()=>{handleCloseRejectRatingModal();}}>&times;</span>
                </div>
                <div className="modal-text">
                    Are you sure you want to reject this review?
                </div>
                <div className='cta-wrapper'>
                    <button className='confirm-cta cancel' onClick={()=>{handleCloseRejectRatingModal();}}>Cancel</button>
                    <button className='confirm-cta' onClick={()=>{handleRejectReview(activeRating,activeRatingIndex,true)}}>Reject</button>
                </div>
            </div>
        </div>
    )
}

export default RejectRatingModal