import styled from 'styled-components';

const AboutusWrapper = styled.div`
  width: 100%;
`;

const AboutusFormWrapper = styled.div``;
const FormBox = styled.div`
  width: 60%;
  margin: auto;
  margin-top: 5rem;
`;
const AboutusAddressWrap = styled.div`
  width: 50%;
  margin: auto;
  padding-top: 2rem;
  padding-bottom: 5rem;
`;
const AboutusAddressGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  justify-content: center;
  gap: 0 40px;
`;
const AboutusAddressItem = styled.div`
  text-align: center;
  h5 {
    text-align: center;
    font-size: 24px;
    font-weight: 500;
    color: #000;
    margin-bottom: 0;
  }
  p,
  a {
    text-decoration: none;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    color: black;
    margin-top: 0;
  }
`;

export {
  AboutusWrapper,
  AboutusFormWrapper,
  FormBox,
  AboutusAddressWrap,
  AboutusAddressGrid,
  AboutusAddressItem
};
