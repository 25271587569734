import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Container, Grid } from '@mui/material';
import HeaderWithLogo from '../../components/HeaderWithLogo/HeaderWithLogo';
import Footer from '../../components/Footer/basicFooter';
import { BlogImage } from './Signin.styles';
import WorkingBlog from '../../assests/workingBlog.png';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import ResetPasswordForm from '../../components/SignInForm/ResetPasswordForm';
import './signIn.css';

const ResetPassword = (props) => {
  const { search } = useLocation();
  const query = React.useMemo(() => new URLSearchParams(search), [search]);
  const token = query.get('token');
  // console.log(token, 'search');
  return (
    <>
      <Container maxWidth="lg">
        <Grid container spacing={2} >
        <div className='gig-logo-and-text' >          
           <Grid container item xs={12} md={11}  justifyContent="flex-end"  >
            <HeaderWithLogo />
          </Grid>
          </div>
          <Grid item container xs={12} >
            <Grid item xs={12} md={6}>
              <BlogImage>
                <img src={WorkingBlog} alt="WorkingBlog" />
              </BlogImage>
            </Grid>
            <Grid container item xs={12} md={6} justifyContent="center">
              <ResetPasswordForm token={token} />
            </Grid>
          </Grid>
          <Grid item xs={11} >
            <Footer />
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default ResetPassword;
