export const SET_NEWGIG_DETAILS = '@gig/newgig/details';
export const SET_NEWGIG_STEP = '@gig/newgig/next';
export const SET_NEWGIG_ERROR = '@gig/newgig/error';
export const SET_NEWGIG_SUCCESS = '@gig/newgig/success';
export const RESET_NEWGIG_DETAILS = '@gig/newgig/details/reset';

export const setNewGigDetails = (data) => ({
  type: SET_NEWGIG_DETAILS,
  payload: data
});

export const setNewGigStep = (data) => ({
  type: SET_NEWGIG_STEP,
  payload: data
});

export const setNewGigError = (data) => ({
  type: SET_NEWGIG_ERROR,
  payload: data
});

export const setNewGigSuccess = (data) => ({
  type: SET_NEWGIG_SUCCESS,
  payload: data
});

export const resetNewGig = (data) => ({
  type: RESET_NEWGIG_DETAILS,
  payload: data
});
