import React from 'react'
import { Button, Card, CardContent, Grid, IconButton, InputAdornment, TextField, Typography } from '@mui/material'
import { CustomRow, PaddedBox, PaddedFooter } from '../app.styles';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import './ResetPasswordCom.css';
import { connect } from 'react-redux';
import { Box } from '@mui/system';
import { resetPasswordValidationSchema } from '../../utils/formikValidations';
import { Form, Formik } from 'formik';
import resetPassword from '../../services/resetPasswordCom';
import Footer from '../../components/footer1/footer';

const ResetPasswordCom = (props) => {

    const [showForgetPassword, setShowForgetPassword] = React.useState(false);
    const [showForgetPass, setShowForgetPass] = React.useState(false);
    const [showForget, setShowForget] = React.useState(false);

    const handleClickShowPassword = () => {
        setShowForgetPassword(!showForgetPassword);
    };
    const handleClickShowPass = () => {
        setShowForgetPass(!showForgetPass);
    };
    const handleClickShow = () => {
        setShowForget(!showForget);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <>
        <PaddedBox
            style={{ paddingLeft: `${!!props.auth && !!props.auth.isDrawerOpen && props.auth.isDrawerOpen == true ? "240px" : "0px"}`, marginTop: '90px', minHeight: "580px" }}>
            <Card>
                <CardContent>
                    <Formik
                        initialValues={{
                            emailId: props.basicInfo.emailId,
                            oldPassword: '',
                            newPassword: '',
                            confirmPassword: ''
                        }}
                        enableReinitialize={true}
                        validationSchema={resetPasswordValidationSchema}
                        onSubmit={(values) => {
                            const obj = { ...values };
                            resetPassword(obj);
                        }}
                    >
                        {({ values, touched, errors, handleChange, handleBlur }) => (
                            <Form noValidate autoComplete="off">
                                <div className='reset-password-page-responsive'>
                                    <CustomRow container>
                                        <Typography
                                            variant="subtitle1"
                                            color="pColor.main"
                                            sx={{
                                                fontWeight: '500',
                                                textTransform: 'capitalize',
                                                fontSize: '22px',
                                                marginTop: '-10px'
                                            }}
                                        >
                                            Reset Password
                                        </Typography>
                                    </CustomRow>

                                    <Box sx={{ marginTop: '15px' }}>
                                        <Grid container
                                            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                                            rowSpacing={{ xs: 3, sm: 2, md: 3 }}
                                        >
                                            <Grid item xs={12} md={4} lg={4}>
                                                <TextField
                                                    fullWidth
                                                    label='Current Password'
                                                    type={showForgetPassword ? "text" : "password"}
                                                    id="oldPassword"
                                                    name="oldPassword"
                                                    autoComplete="old-password"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={touched.oldPassword && Boolean(errors.oldPassword)}
                                                    helperText={touched.oldPassword && errors.oldPassword}
                                                    value={values.oldPassword}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    onClick={handleClickShowPassword}
                                                                    onMouseDown={handleMouseDownPassword}
                                                                >
                                                                    {showForgetPassword ? <Visibility /> : <VisibilityOff />}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={4} lg={4}>
                                                <TextField
                                                    fullWidth
                                                    label='New Password'
                                                    type={showForgetPass ? "text" : "password"}
                                                    id="newPassword"
                                                    name="newPassword"
                                                    autoComplete="new-password"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={touched.newPassword && Boolean(errors.newPassword)}
                                                    helperText={touched.newPassword && errors.newPassword}
                                                    value={values.newPassword}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    onClick={handleClickShowPass}
                                                                    onMouseDown={handleMouseDownPassword}
                                                                >
                                                                    {showForgetPass ? <Visibility /> : <VisibilityOff />}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                        <Grid item xs={12} md={4} lg={4} mt={3}>
                                            <TextField
                                                fullWidth
                                                label='Confirm New Password'
                                                type={showForget ? "text" : "password"}
                                                id="confirmPassword"
                                                name="confirmPassword"
                                                autoComplete="confirm-password"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={touched.confirmPassword && Boolean(errors.confirmPassword)}
                                                helperText={touched.confirmPassword && errors.confirmPassword}
                                                value={values.confirmPassword}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                onClick={handleClickShow}
                                                                onMouseDown={handleMouseDownPassword}
                                                            >
                                                                {showForget ? <Visibility /> : <VisibilityOff />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    )
                                                }}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Button
                                        className="btn-text-save"
                                        type="submit"
                                    >
                                        Save
                                    </Button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </CardContent>
            </Card>
        </PaddedBox>
        <PaddedFooter style={{ paddingLeft: `${!!props.auth && !!props.auth.isDrawerOpen && props.auth.isDrawerOpen == true ? "240px" : "0px"}` }}>
        <Footer />
      </PaddedFooter>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        myProfile: state.profile.myProfile || {},
        error: state.profile.error,
        basicInfo: state.profile.basicInfo,
        profile: state.profile
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setProfileError: (details) => dispatch(setProfileError(details)),
        setProfileSuccess: (details) => dispatch(setProfileSuccess(details)),
        resetProfileSuccess: (details) => dispatch(resetProfileSuccess(details)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordCom);