import React from 'react'
import { Button, Dialog, DialogTitle, IconButton, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useHistory } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';

const StepPopup = (props) => {

    const history = useHistory();

    const handleCloseforComplete = () => {
        props.modalClose();
    };

    const BootstrapDialogTitle = (props) => {
        const { children, onClose } = props;

        return (
            <DialogTitle sx={{ m: 0, p: 2 }}>
                {children}
                {onClose ? (
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon sx={{ marginRight: '20px' }} />
                    </IconButton>
                ) : null}
            </DialogTitle>
        );
    };

    return (
        <div>
            <Dialog open={props.modalOpen}>
                <Box
                    sx={{ display: 'flex', flexDirection: 'column', padding: '30px' }}
                >
                    <BootstrapDialogTitle id="customized-dialog-title" onClose={handleCloseforComplete}>
                    </BootstrapDialogTitle>
                    <div>
                        <Typography sx={{ marginBottom: '15px', fontSize: '19px', fontWeight: '500', marginTop: '-40px' }}>
                            Complete your profile!
                        </Typography>
                        <Box sx={{ borderBottom: '1px solid grey' }}></Box>
                        <Typography sx={{ marginTop: '15px' }}>
                            Please complete your profile. Once your profile is complete you will be able to post gigs and connect with engineers.
                        </Typography>
                    </div>
                    <Box sx={{ borderBottom: '1px solid grey', marginTop: '17px' }}></Box>
                    <Button
                        className='go-to-profile-btn'
                        type="button"
                        onClick={() => {
                            history.push(`/representative`);
                        }}
                    >
                        Go to profile
                    </Button>
                </Box>
            </Dialog>
        </div>
    )
}

export default StepPopup
