import { React } from 'react';
import { PaddedBox } from '../../containers/app.styles';
import Header from '../HomePage/Header';
import Footer from '../footer1/footer';
import "./PrivacyPolicy.css";
import { connect } from 'react-redux';

const PrivacyPolicy = (props) => {

    return (
        <div>
            <Header />
                <div className='heading'>
                    <h1>PRIVACY POLICY </h1>
                </div>
                <div className='para'>
                    <h3>Gig Engineer (Pty) Ltd Privacy Policy</h3>
                </div>
                <div className='paras1'>
                    <h5>Gig Engineer (Pty) Ltd is committed to maintaining robust privacy protections for its users.  Our Privacy Policy (“Privacy Policy”) is designed to help you understand how we collect, use and safeguard the information you provide to us and to assist you in making informed decisions when using our Service.  </h5>
                    <h5>For purposes of this Agreement, “Website” refers to the website operated and maintained by Gig Engineer, which can be accessed at: gigengineer.io</h5>
                    <h5>“Service” refers to Gig Engineer services accessed via the Website, whereby Gig Engineer is is an online platform which enables the connection between clients and independent professionals. </h5>
                    <h5>“Clients” are individuals and/or businesses seeking to obtain professional services from Independent Professionals or Engineers. </h5>
                    <h5>“Independent Professionals” or “Engineers” means a User that offers and provides services or identifies as a Seller through the Website. </h5>
                    <h5>“User” means an individual who visits or uses the Website. A user may be both a Buyer and a Seller on our Website.</h5>
                    <h5>The terms “we,” “us,” and “our” refer to Gig Engineer (Pty) Ltd. </h5>
                    <h5>“You” refers to you, as a user of our Website or Service. </h5>
                    <h5>By accessing our Website or Service, you accept our Privacy Policy and Terms and Conditions (accessible on our homepage: gigengineer.io), and you consent to our collection, storage, use and disclosure of your Personal Information as described in this Privacy Policy.</h5>
                    <h5>I. INFORMATION WE COLLECT</h5>
                    <h5>We collect “Non-Personal Information” and “Personal Information.” Non-Personal Information includes information that cannot be used to personally identify you, such as anonymous usage data, general demographic information we may collect, referring/exit pages and URLs, platform types, preferences you submit and preferences that are generated based on the data you submit and number of clicks. Personal Information includes your personal contact details, specific information regarding professional qualification(s) and experience, business profiles, which you submit to us through the registration process on the Website.</h5>
                    <h5>1.   Information collected via Technology</h5>
                    <h5>To activate the Service you do not need to submit any Personal Information other than your email address. To use the Service thereafter, you do need to submit further Personal Information, which may include personal contact information such as your phone number. However, in an effort to improve the quality of the Service, we track information provided to us by your browser or by our software application when you view or use the Service, such as the website you came from (known as the “referring URL”), the type of browser you use, the device from which you connected to the Service, the time and date of access, and other information that does not personally identify you. We track this information using cookies, or small text files which include an anonymous unique identifier. Cookies are sent to a user’s browser from our servers and are stored on the user’s computer hard drive. Sending a cookie to a user’s browser enables us to collect Non-Personal information about that user and keep a record of the user’s preferences when utilizing our services, both on an individual and aggregate basis. For example, Gig Engineer (Pty) Ltd may use cookies to collect the following information: </h5>
                    <h5>●	Your general location.</h5>
                    <h5>●	The pages you visit.</h5>
                    <h5>●	Events such as form submissions.</h5>
                    <h5>Gig Engineer (Pty) Ltd may use session cookies to maintain login state for users, session cookies expire when you logout or close your browser.  </h5>
                    <h5>2.   Information you provide us by registering for an account</h5>
                    <h5>In addition to the information provided automatically by your browser when you visit the Site, to become a subscriber to the Service you will need to create a personal profile. You can create a profile by registering with the Service and entering your email address and a password. By registering, you are authorizing us to collect, store and use your email address in accordance with this Privacy Policy.
                    </h5>
                    <h5>User Profiles Users may create a profile, with specific or all publicly available information. You have the opportunity to create a profile, which consists of information about you, your company (if you are registering as a business), and may include Personal Information, photographs, examples of your work, information on work previously performed via the Service and outside the Service, skills, tests taken, test scores, hourly pay rates and earnings information, feedback/rating information and other information, including your username (“Profile”). The information in your Profile may be publicly visible to all users of our Service. You may edit certain information in your Profile via your account and may choose to limit who is able to view certain content you post to your Profile. Clients may also have the opportunity to create an organisation or business Profile. If, in any case, you believe that an unauthorized profile has been created on your behalf, you can request for it to be removed by contacting us at: info@gigengineer.io.</h5>
                    <h5>3. Children’s Privacy</h5>
                    <h5>The Site and the Service are not directed to anyone under the age of 18. The Site does not knowingly collect or solicit information from anyone under the age of 18, or allow anyone under the age of 18 to sign up for our Service. In the event that we learn that we have gathered personal information from anyone under the age of 18 without the consent of a parent or guardian, we will delete that information as soon as possible. If you believe we have collected such information, please contact us at: info@gigengineer.io.</h5>
                    <h5>II. HOW WE USE AND SHARE INFORMATION</h5>
                    <h5>Personal Information:</h5>
                    <h5>Except as otherwise stated in this Privacy Policy, we do not sell, trade, rent or otherwise share for marketing purposes your Personal Information with third parties without your consent. We do share Personal Information with vendors who are performing services for and on behalf of us, such as the Gig Engineer (Pty) Ltd servers for the purposes of our email communications, who are provided access to the user’s email address for the purposes of sending out to them, our emails. Those vendors use your Personal Information only at our direction and in accordance with our Privacy Policy.</h5>
                    <h5>In general, the Personal Information you provide to us is used to help us communicate with you. For example, we may use Personal Information to contact users in response to questions, solicit feedback from users, provide technical support, and inform users about upcoming gig work.</h5>
                    <h5>We may share Personal Information with outside parties if we have a good-faith belief that access, use, preservation or disclosure of the information is reasonably necessary to meet any applicable legal process or enforceable governmental request; to enforce applicable Terms and Conditions of Service, including investigation of potential violations; address fraud, security or technical concerns; or to protect against harm to the rights, property, or safety of our users or the public as required or permitted by law. </h5>
                    <h5>Non-Personal Information:</h5>
                    <h5>In general, we use Non-Personal Information to help us improve the Service and customize the user experience. We also may aggregate Non-Personal Information in order to track trends and analyse use patterns on the Website. This Privacy Policy does not limit in any way our use or disclosure of Non-Personal Information and we reserve the right to use and disclose such Non-Personal Information to our partners, advertisers and other third parties at our discretion.</h5>
                    <h5>In the event we undergo a business transaction such as a merger, acquisition by another company, or sale of all or a portion of our assets, your Personal Information may be among the assets transferred. You acknowledge and consent that such transfers may occur and are permitted by this Privacy Policy, and that any acquirer of our assets may continue to process your Personal Information as set forth in this Privacy Policy. If our information practices change at any time in the future, we will post the policy changes to the Website so that you may opt out of the new information practices. We suggest that you check the Website periodically if you are concerned about how your information is used.</h5>
                    <h5>III. HOW WE PROTECT INFORMATION</h5>
                    <h5>
                        We implement security measures designed to protect your information from unauthorised access. Your account is protected by your account password and we urge you to take steps to keep your personal information safe by not disclosing your password and by logging out of your account after each use. We further protect your information from potential security breaches by implementing certain technological security measures including encryption, secure socket layer technology. However, these measures do not guarantee that your information will not be accessed, disclosed, altered or destroyed by breach of such secure server software. By using our Service, you acknowledge that you understand and agree to assume these risks.
                    </h5>
                    <h5>IV. YOUR RIGHTS REGARDING THE USE OF YOUR PERSONAL INFORMATION</h5>
                    <h5>You have the right at any time to prevent us from contacting you for marketing purposes. When we send a promotional communication to a user, the user can opt out of further promotional communications by following the unsubscribe instructions provided in each promotional email. You can also indicate that you do not wish to receive marketing communications from us in the opting out when registering on the Website. Please note that notwithstanding the promotional preferences you indicate by either unsubscribing or opting out when unregistering from the Website, we may continue to send you administrative emails including, for example, periodic updates to our Privacy Policy.</h5>
                    <h5>V. LINKS TO OTHER WEBSITES</h5>
                    <h5>As part of the Service, we may provide links to or compatibility with other websites or applications. However, we are not responsible for the privacy practices employed by those websites or the information or content they contain. This Privacy Policy applies solely to information collected by us through the Website and the Service. Therefore, this Privacy Policy does not apply to your use of a third-party website accessed by selecting a link on our Website or via our Service. To the extent that you access or use the Service through or on another website or application, then the privacy policy of that other website or application will apply to your access or use of that site or application. We encourage our users to read the privacy statements of other websites before proceeding to use them.</h5>
                    <h5>VI. CHANGES TO OUR PRIVACY POLICY</h5>
                    <h5>Gig Engineer (Pty) Ltd reserves the right to change this policy and our Terms and Conditions of Service at any time.  We will notify you of significant changes to our Privacy Policy by sending a notice to the primary email address specified in your account or by placing a prominent notice on our site. Significant changes will go into effect 30 days following such notification. Non-material changes or clarifications will take effect immediately. You should periodically check the Website and this Privacy Policy page for updates.</h5>
                    <h5>VII. CONTACT US</h5>
                    <h5>If you have any questions regarding this Privacy Policy or the practices of our Website, please contact us by sending an email to: info@gigengineer.io .</h5>
                    <h5>Last Updated: This Privacy Policy was last updated on 11 July 2022.</h5>

                </div>

            <Footer />
        </div >


    );

};
const mapStateToProps = (state) => {
    return {
      auth: state.auth,
    };
};
export default connect(mapStateToProps)(PrivacyPolicy);
