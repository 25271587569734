import styled from 'styled-components';

const BlogImage = styled.div`
  img {
    max-width: 100%;
    width: 75%;
  }
`;

export { BlogImage };
