import React from 'react';
import PropTypes from 'prop-types';
import { MenuItem, FormControl, Select } from '@mui/material';
import _map from 'lodash/map';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import EditOffIcon from '@mui/icons-material/EditOff';

export default function DropDown({ dropDownChange, defaultValue, values, editIcon = 0, getEditOption, id }) {
  return (
    <FormControl size="small" key={Math.random()}>
      <div className='edit-btn-center'>
        <Select
          defaultValue={defaultValue}
          className="dropDown-select dropDown-list"
          onChange={(event) => {
            const action = event.target.value;
            dropDownChange && dropDownChange(action);
          }}
        >
          {_map(values, (val) => (
            <MenuItem key={`dropdown-${val}`} value={val} className="dropDown-list">
              {val}
            </MenuItem>
          ))}
        </Select>
        {editIcon == 0 ? (id != 'inactive' && id != 'closed') ?
          <div title="Edit the Gig.">
            <ModeEditIcon fontSize='30' sx={{ margin: "7px", cursor: "pointer" }} onClick={() => getEditOption("Edit", id)} ></ModeEditIcon>
          </div>
          : "" :
          (id != 'inactive' && id != 'closed') ?
            <div title="Not able to edit.">
              <EditOffIcon fontSize='30' sx={{ margin: "7px", cursor: "pointer" }} >
              </EditOffIcon>
            </div>
            : ""}
      </div>
    </FormControl>
  );
}

DropDown.propTypes = {
  defaultValue: PropTypes.string,
  dropDownChange: PropTypes.func,
  values: PropTypes.arrayOf(PropTypes.string)
};

DropDown.defaultProps = {
  defaultValue: '',
  dropDownChange: () => { },
  values: []
};
