import { Typography, styled, TableCell, tableCellClasses, TableRow } from '@mui/material';
import React from 'react'
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { CustomRow, PaddedBox, PaddedFooter } from '../../app.styles';
import { useState } from 'react';
import './styles.scss'
import { useEffect } from 'react';
import { fetchInvoiceListBusiness, fetchInvoiceListEngineer } from '../../../services/invoicing';
import { getUserId, getUserType } from '../../../utils/user';
import InvoiceModal from '../common/InvoiceModal';
import Footer from '../../../components/footer1/footer';
import { getActiveGigs } from '../../../services/engineerGig';
import { NumberFormatter } from '../../../utils/helpers';

function InvoiceSummary(props) {

    const dispatch = useDispatch();
    const history = useHistory();
    const userType = getUserType();
    const [totalInvoiced, settotalInvoiced] = useState(undefined);
    const [totalPaid, settotalPaid] = useState(undefined);
    const [totalPending, settotalPending] = useState(undefined);
    const [gigList, setgigList] = useState(undefined)

    const [addInvoiceModalOpen, setaddInvoiceModalOpen] = useState(false)

    const handleSetData = (data) => {
        setgigList(data.gigList)
    }

    const fetchData = () => {
        const obj = {
            'userId': getUserId(),
            'paginationInput': {
                pageNumber: 1,
                pageSize: 100
            },
            'status': ['ACTIVE']
        };
        getActiveGigs(obj, handleSetData);
    };

    useEffect(() => {
        fetchData()
    }, [])


    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));

    useEffect(() => {
        try {
            if (userType == "engineer") {
                fetchInvoiceListEngineer({
                    "paginationInput": {
                        "pageNumber": 1,
                        "pageSize": 3
                    },
                    "engineerId": getUserId(),
                    "status": [
                        "PENDING",
                        "PAYMENTINITIATED",
                        "PAYMENTRECIEVED",
                        "PAIDTOENGINEER"
                    ],
                }).then((e) => {
                    if (!!e.totalInvoiced) {
                        settotalInvoiced(e.totalInvoiced);
                    }
                    if (!!e.totalPaid) {
                        settotalPaid(e.totalPaid);
                    }
                    if (!!e.totalPending) {
                        settotalPending(e.totalPending);
                    }
                }).catch((err) => {
                    console.error(err)
                })
            }
            else if (userType == "business") {
                fetchInvoiceListBusiness({
                    "paginationInput": {
                        "pageNumber": 1,
                        "pageSize": 3
                    },
                    "businessId": getUserId(),
                    "status": [
                        "PENDING",
                        "PAYMENTINITIATED",
                        "PAYMENTRECIEVED",
                        "PAIDTOENGINEER"
                    ],
                }).then((e) => {
                    if (!!e.totalInvoiced) {
                        settotalInvoiced(e.totalInvoiced);
                    }
                    if (!!e.totalPaid) {
                        settotalPaid(e.totalPaid);
                    }
                    if (!!e.totalPending) {
                        settotalPending(e.totalPending);
                    }
                }).catch((err) => {
                    console.error(err)
                })
            }
        }
        catch (e) {
            console.error(e)
        }
    }, [])



    return (
        <>
            <PaddedBox style={{ paddingLeft: `${!!props.auth && !!props.auth.isDrawerOpen && props.auth.isDrawerOpen == true ? "240px" : "0px"}`, minHeight: "580px" }}>
                {addInvoiceModalOpen && <InvoiceModal addInvoiceModalOpen={addInvoiceModalOpen} setaddInvoiceModalOpen={setaddInvoiceModalOpen} />}
                <CustomRow container>
                    <Typography variant="h6" component="h6" color="pColor.main" sx={{ fontWeight: 'Medium', textTransform: 'capitalize', fontSize: '22px' }}>
                        Summary
                    </Typography>
                    {
                        userType == 'engineer'
                        &&
                        (!!gigList && gigList.length > 0 ?
                            <button onClick={() => { setaddInvoiceModalOpen(true) }} className='new-invoice-cta'>&#43; New Invoice</button>
                            :
                            <button style={{ background: "grey", cursor: "not-allowed" }} className='new-invoice-cta'>&#43; New Invoice</button>)
                    }
                </CustomRow>
                <div className='summary-wrapper'>
                    <div className='summary-box'>
                        <p>Total Invoiced</p>
                        <div className='summary-currency-wrapper'>
                            <div>
                                <p>Zar</p>
                                <span>R {!!totalInvoiced && (typeof (totalInvoiced.randAmount) == 'number') ? NumberFormatter(totalInvoiced.randAmount) : 0}</span>
                            </div>
                            <div className='us-doller-gap'>
                                <p>US Dollar</p>
                                <span>$ {!!totalInvoiced && (typeof (totalInvoiced.usdAmount) == 'number') ? NumberFormatter(totalInvoiced.usdAmount) : 0}</span>
                            </div>
                        </div>
                    </div>

                    <div className='summary-box'>
                        <p>Total {userType == "engineer" ? "Earned" : "Paid"}</p>
                        <div className='summary-currency-wrapper'>
                            <div>
                                <p>Zar</p>
                                <span>R {!!totalPaid && (typeof (totalPaid.randAmount) == 'number') ? NumberFormatter(totalPaid.randAmount) : 0}</span>
                            </div>
                            <div className='us-doller-gap'>
                                <p>US Dollar</p>
                                <span>$ {!!totalPaid && (typeof (totalPaid.usdAmount) == 'number') ? NumberFormatter(totalPaid.usdAmount) : 0}</span>
                            </div>
                        </div>
                    </div>

                    <div className='summary-box'>
                        <p>Total Pending</p>
                        <div className='summary-currency-wrapper'>
                            <div>
                                <p>Zar</p>
                                <span>R {!!totalPending && (typeof (totalPending.randAmount) == 'number') ? NumberFormatter(totalPending.randAmount)
                                 : 0}</span>
                            </div>
                            <div className='us-doller-gap'>
                                <p>US Dollar</p>
                                <span>$ {!!totalPending && (typeof (totalPending.usdAmount) == 'number') ? NumberFormatter(totalPending.usdAmount)
                                 : 0}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </PaddedBox>
            <PaddedFooter style={{ paddingLeft: `${!!props.auth && !!props.auth.isDrawerOpen && props.auth.isDrawerOpen == true ? "200px" : "0px"}` }}>
                <Footer />
            </PaddedFooter>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        gigs: state.gigs
    };
};

export default connect(mapStateToProps)(InvoiceSummary)
