export const SET_PROFILE_DETAILS = '@gig/profile/details';
export const SET_PROFILE_ERROR = '@gig/profile/error';
export const SET_PROFILE_SUCCESS = '@gig/profile/success';
export const RESET_PROFILE_SUCCESS = '@gig/profile/reset';

export const setProfileDetails = (data) => ({
  type: SET_PROFILE_DETAILS,
  payload: data
});

export const setProfileError = (data) => ({
  type: SET_PROFILE_ERROR,
  payload: data
});

export const setProfileSuccess = (data) => ({
  type: SET_PROFILE_SUCCESS,
  payload: data
});

export const resetProfileSuccess = (data) => ({
  type: RESET_PROFILE_SUCCESS,
  payload: data
});
