import styled from 'styled-components';
import ListItemButton from '@mui/material/ListItemButton';

const ImageUI = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  .progress-bar {
    height: 60%;
    width: 60%;
  }
`;

const CustomListItemBtn = styled(ListItemButton)(
  (props) => `
   ${props.active &&
    `
     background: #0140AA;  /* fallback for old browsers */
background: -webkit-linear-gradient(to right, #1A53B2, #0140AA);  /* Chrome 10-25, Safari 5.1-6 */
background: linear-gradient(to right, #1A53B2, #0140AA); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

      color: #fff !important;
      font-weight: 700;
      svg {
        color: #fff;
      }
    `
    }
`
);
export { ImageUI, CustomListItemBtn };
