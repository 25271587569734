import _get from 'lodash/get';
import apiService from './apiServices';

const forgotPassword = async (data, setAuthSignError) => {
  const request = {
    data,
    method: 'post',
    url: '/auth/password/forgot'
  };
  const response = await apiService(request);
  if (response && response.status === 200) {
    return response;
  } else {
    setAuthSignError(response);
  }
};

export default forgotPassword;
