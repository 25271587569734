import _get from 'lodash/get';
import { signUpApiService } from './apiServices';

const signUpReq = async (obj, setAuthSignError) => {

  let data = {
    name: obj?.fullName,
    emailId: obj?.emailId,
    password: obj?.password,
    userRole: obj?.userType,
    promoOpted: obj?.promoOpted
  };

  if (obj && obj.userType === 'business') data['companyName'] = obj.companyName;

  const request = {
    data,
    method: 'post',
    url: '/auth/register'
  };
  // console.log(request, 'request');
  const response = await signUpApiService(request);
  if (response && response.status === 200) {
    return response;
  } else {
    // show global error message via props
    setAuthSignError(response);
  }
};

export default signUpReq;
