import React, { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import { connect } from 'react-redux';
import { Grid, Button, Box, Avatar, Rating } from '@mui/material';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import { PaddedBox, PaddedFooter } from '../../../app.styles';
import { myprofileScreenValidationSchema } from '../../../../utils/formikValidations';
import CircularProgress from '@mui/material/CircularProgress';
import { setEngProfileApi } from '../../../../services/profile';
import _map from 'lodash/map';
import Select from '@mui/material/Select';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { toast } from 'react-toastify';
import HelpIcon from '@mui/icons-material/Help';
import { blue, red } from '@mui/material/colors';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import { useHistory } from 'react-router-dom';
import _difference from 'lodash/difference';
import TextField from '@mui/material/TextField';
import DragDropiconred from '../../../../assests/DragDropiconred.svg';
import Autocomplete from '@mui/material/Autocomplete';
import {
  setProfileError,
  setProfileSuccess,
  resetProfileSuccess
} from '../../../../store/actions/profile';
import { resetSuccessState } from '../../../../utils/helpers';
import Chip from '@mui/material/Chip';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Prompt } from 'react-router-dom';
import { getUploadApi } from '../../../../services/upload';
import Typography from '@mui/material/Box';
import { getExpertiseSkillsDetApi, getSkillsList, getToolslist } from '../../../../services/expertisewithskills';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import { setCookie, getCookie } from '../../../../utils/cookie';
import NumberFormat from 'react-number-format';
import apiService from '../../../../services/apiServices';
import Footer from '../../../../components/footer1/footer';
import VerifiedIcon from '../../../..//assests/VerifiedTick.svg';
import RightIcon from '../../../../assests/RightIcon.svg';
import CrossIcon from '../../../../assests/CrossIcon.svg';
import './MyProfile.scss';
import { DragDropIcon } from '../DragDrop/DragDrop';
import RichTextEditor from 'react-rte';
import { cancelProfileFeedBack, profileFeedBack, profilecheckUsername } from '../../../../services/gig';

const otherskills = [];

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
    />
  );
});

const MyProfile = (props) => {
  const history = useHistory();
  const [userKey, setUserKey] = useState('');

  const profileDefaults = {
    title: '',
    overview: '',
    availabilityHoursPerWeek: '',
    response: '',
    hourlyRate: '',
    profilePic: '',
    levelOfExperience: '',
    fieldOfSpecialization: [],
    isRemote: 'true',
    tools: [],
    otherskills: [],
    userName: userKey
  };

  NumberFormatCustom.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  };

  let pro = { ...profileDefaults, ...props.myProfile || {} };
  const [datachange, setDataChange] = useState(false);
  const [otherskill, setOtherSkill] = useState(props.myProfile?.otherskills || []);
  const percentage = _get(props, 'profile.profileCompletionPercent', 0);
  const [open, setOpen] = useState(getCookie('setOpen'));
  const [openComplete, setOpenComplete] = useState(getCookie('setOpenComplete'));
  const [rating, setRating] = useState(1);
  const [currency, setCurrency] = useState(props.myProfile.currency || 'R');
  // const [feedback, setFeedback] = useState('');
  const [ratingError, setRatingError] = useState('');
  const isFeedbackGiven = _get(props, 'profile.isFeedbackGiven', false);
  const [toolTip, isToolTip] = useState();
  const [copyBtn, setCopyBtn] = useState('COPY');
  const [titleError, setTitleError] = useState('');

  const submitUsername = async (userName) => {
    let data = {
      userName: userName
    };
    
    const request = {
      data,
      method: 'post',
      url: '/profile/check-username'
    };
    const response = await apiService(request);
    if (response && response.status === 200) {
      isToolTip(response.data);
    } else {
    }
  };

  const keyPress = (event) => {
    const userName = event.target.value;
    if (userName != "") {
      setUserKey(userName); /* Set username into userKey */
      submitUsername(userName);
    }
  }

  const handleClose = () => {
    setOpen(false);
    setCookie('setOpen', false, 30);
  };

  const handleCloseforComplete = () => {
    setOpenComplete(false);
    setCookie('setOpenComplete', false, 30);
    cancelMethod();
  };

  const submitMethod = async () => {
    let feedback = document.getElementById("standard-search").value;
    let data = {
      feedback: feedback,
      rating: rating,
      isGiven: true,
      type: "profileCompletion"
    };
    if (data.rating === 0) {
      return setRatingError('Please give rating before submit.')
    }
    profileFeedBack(data).then((e)=>{
      setOpenComplete(false);
      toast.success("Success");
      setCookie('setOpenComplete', false, 30);
    })
  };

  const cancelMethod = async () => {
    // let feedback = document.getElementById("standard-search").value;
    let data = {
      isGiven: false,
      type: "profileCompletion"
    };
    cancelProfileFeedBack(data).then((e)=>{
      setOpenComplete(false);
    })
  };

  const profileDet = props.basicInfo;

  const [img1, setImg1] = useState(props.myProfile?.profilePic || "");
  const [loading, setLoading] = useState(false);
  const [fileKey, setfileKey] = useState(Math.random().toString(36));

  const handelChange = async (filename, e) => {
    setfileKey(Math.random().toString(36))
    const checkedSize = 1024 * 1024 * 5;
    const selectedFileSize = filename && filename.size;
    if (selectedFileSize > checkedSize) {
      toast.error('File size should be less than 5 MB');
      return;
    }

    if (props.onSelect) {
      props.onSelect(filename);
    } else {
      setLoading(true);
      let img = await getUploadApi(filename);
      setImg1(img?.data?.fileUrl);
      setLoading(false);
    }
  };

  const handleDeleteChange = async (e) => {
    setDataChange(true);
    setImg1("");
  };

  const [expertiseList, setExpertiseList] = useState([]);

  const getExpertiseList = async () => {
    let expertise = await getExpertiseSkillsDetApi();
    if (expertise?.data && expertise?.data.length > 0) {
      setExpertiseList(expertise.data);
    }
  };

  const [skillsList, setSkillList] = useState([]);
  const [tools, setToolsList] = useState([]);

  const getSkills = async () => {
    let skills = await getSkillsList();
    if (skills?.data && skills?.data.length > 0) {
      // skills?.data.push({ "skills": "Other" });
      setSkillList(skills.data);
    }
  };

  const getTools = async () => {
    let tool = await getToolslist();
    if (tool?.data && tool?.data.length > 0) {
      setToolsList(tool.data);
    }
  };

  useEffect(() => {
    getExpertiseList();
    getSkills();
    getTools();
  }, [props]);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 300
      }
    }
  };


  const toolbarConfig = {
    // Optionally specify the groups to display (displayed in the order listed).
    display: [
      "INLINE_STYLE_BUTTONS",
    ],
    INLINE_STYLE_BUTTONS: [
      { label: "Bold", style: "BOLD", className: "custom-css-class" },
      { label: "Italic", style: "ITALIC" },
      { label: "Underline", style: "UNDERLINE" }
    ],
    BLOCK_TYPE_DROPDOWN: [
      { label: "Normal", style: "unstyled" },
      { label: "Heading Large", style: "header-one" },
      { label: "Heading Medium", style: "header-two" },
      { label: "Heading Small", style: "header-three" }
    ],
    BLOCK_TYPE_BUTTONS: [
      { label: "UL", style: "unordered-list-item" },
      { label: "OL", style: "ordered-list-item" }
    ]
  };

  const [editorValue, setEditorValue] = useState(
    RichTextEditor.createValueFromString(!!props.myProfile?.overview?props.myProfile?.overview:"", "html")
  );
  const [ckeValue, setCkeValue] = useState(!!RichTextEditor.createValueFromString(props.myProfile?.overview || "", "html").toString("html")?RichTextEditor.createValueFromString(props.myProfile?.overview || "", "html").toString("html"):"");
  const [hasChanged, sethasChanged] = useState(false);

  /**
   * Handle editor's change.
   * @param {import('react-rte').EditorValue} value Value from the editor.
   */
  function onChange(value) {
    // console.log(value.toString("html"));
    setEditorValue(value);
    setDataChange('overview', value.toString("html"))
  }

  return (
    <React.Fragment>
      <Prompt
        when={datachange}
        message={() => 'You have unsaved changes, Are you sure you want to leave?'}
      ></Prompt>
      {percentage < 60 && (
        <div>
          <BootstrapDialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
          >
            <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
              Welcome to Gig Engineer!
            </BootstrapDialogTitle>
            <DialogContent dividers>
              <Typography gutterBottom>
                Please complete your profile. Once your profile is complete you
                will be able to search and apply for  gigs.
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button autoFocus onClick={handleClose}>
                Close
              </Button>
            </DialogActions>
          </BootstrapDialog>
        </div>
      )}
      {(percentage === 100 && !isFeedbackGiven && (getCookie('setOpenComplete') != false)) && (
        <div>
          <Dialog open={openComplete} onClose={handleCloseforComplete}>
            <Box
              sx={{ display: "flex", flexDirection: "column", padding: "20px" }}
            >
              <BootstrapDialogTitle id="customized-dialog-title" onClose={handleCloseforComplete}>
              </BootstrapDialogTitle>
              <h2>Hey there!</h2>
              <Typography>
                Congratulations on successfully completing your Gig Engineer
                profile! We would love to get feedback on your experience.
              </Typography>
              <Box sx={{ display: "flex" }}>
                <Typography sx={{ marginTop: "12px" }}>
                  <Rating
                    name="size-large"
                    defaultValue={rating}
                    size="large"
                    onChange={(event) => {
                      let starValue = event.target.value;
                      if (rating != parseInt(starValue)) {
                        setRating(parseInt(starValue));
                        setRatingError(null)
                      }
                      if (rating === parseInt(starValue)) {
                        setRating(0)
                        setRatingError('Please provide the rating.')
                      }
                    }}

                  />
                </Typography>
                <Box
                  sx={{ marginTop: "12px", marginLeft: "20px", fontSize: "20px" }}
                >
                  ({rating}/5)
                </Box>
              </Box>
              <Typography
                sx={{
                  fontSize: '12px',
                  color: 'red'
                }}
              >
                {ratingError}
              </Typography>
              <Box
                component="form"
                sx={{
                  "& .MuiTextField-root": { m: 1, width: "60ch" },
                  marginTop: "-10px",
                }}
                autoComplete="off"
              >
                <div>
                  <TextField
                    id="standard-search"
                    label="Feedback (Optional)"
                    // value={feedback}
                    type="search"
                    variant="standard"
                  />
                </div>
              </Box>
              <Box
                sx={{
                  textAlign: "right",
                }}
              >
                <Button
                  variant="contained"
                  className="popup-process"
                  type="submit"
                  sx={{
                    width: "120px",
                  }}
                  onClick={submitMethod}
                >
                  Submit
                </Button>
                <br />
              </Box>
            </Box>
          </Dialog>
        </div>
      )}
      <PaddedBox
        style={{ paddingLeft: `${!!props.auth && !!props.auth.isDrawerOpen && props.auth.isDrawerOpen == true ? "240px" : "0px"}`, minHeight: "580px" }}>
        <Formik
          initialValues={pro}
          enableReinitialize={true}
          validationSchema={myprofileScreenValidationSchema}
        >
          {({ values, touched, errors, handleChange, handleBlur, setFieldValue }) => (
            <Form
              noValidate
              autoComplete="off"
              onChange={() => {
                resetSuccessState(props);
              }}
              onSubmit={(e) => {
                e.preventDefault();
                let arr = []
                let co = {}
                if(!!values.skills&&values.skills.length>0){
                  for (let i = 0; i < values.skills.length; i++) {
                    co = { skillName: values.skills[i], level: 'entry' }
                    arr.push(co)
                  }
                }
                let finalData = { ...values, 'profilePic': img1, 'otherskills': otherskill };
                if (finalData.fieldOfSpecialization === "") {
                  finalData = { ...finalData, 'fieldOfSpecialization': [""] };
                }
                finalData['overview'] = ckeValue ? ckeValue : "";
                delete finalData?.editorValue;
                setDataChange(false);
                const obj = { myProfile: { ...finalData }, skillList: arr };
                if (finalData['title'] === "") {
                  return setTitleError('Title is required!')
                }
                setEngProfileApi(obj, props.setProfileError, props.setProfileSuccess);
                setTitleError('');
                toast.success('Data Saved!');
                if (values?.action === 'next') {
                  setTimeout(() => {
                    history.push(`/engineer`);
                  }, 1000);
                }
              }}
            >
              <Card sx={{ marginTop: '20px', marginBottom: '40px' }}>
                <CardContent>
                  <Box
                    sx={{
                      fontSize: '20px',
                      marginBottom: '15px',
                      fontWeight: '600',
                      color: '#0140AA',
                      // textTransform: 'uppercase'
                    }}
                  >
                    My Profile
                  </Box>
                  <Grid item container spacing={3} className="section-Margin-data">
                  <div className='company-details-items'>
                      <div className='company-detail-grid-data'>
                    <Grid item xs={12} md={9} lg={9}>
                      <Grid container spacing={3} alignItems="center">

                        <Grid item xs={12} md={6} lg={6}>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              gap: '5px',
                              alignItems: 'center'
                            }}
                          >
                            <TextField
                              fullWidth
                              label="Professional title"
                              required
                              name="title"
                              placeholder="e.g. Senior Chemical Engineer"
                              value={values.title}
                              onChange={(e) => {
                                setTitleError('');
                                handleChange(e);
                                setDataChange(true);
                              }}
                              variant="outlined"
                              InputLabelProps={{ shrink: true }}
                            />
                            <HelpIcon sx={{ color: 'white', fontSize: 16 }} />
                          </Box>
                          <div className='error-msg-pro-url'>
                           {titleError}
                           </div>
                        </Grid>

                        <Grid item xs={12} md={6} lg={6}>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              gap: '5px',
                              alignItems: 'center'
                            }}
                          >
                            <FormControl variant="outlined" fullWidth>
                              <InputLabel>Availability</InputLabel>
                              <Select
                                name="availabilityHoursPerWeek"
                                value={values.availabilityHoursPerWeek}
                                label="AVAILABILITY"
                                placeholder="Do you have any response."
                                onChange={(e) => {
                                  handleChange(e);
                                  setDataChange(true);
                                }}
                                MenuProps={MenuProps}
                                InputLabelProps={{ shrink: true }}
                              >
                                <MenuItem value="">
                                  <em>What's your availability?</em>
                                </MenuItem>
                                <MenuItem value={'10'}>0-10 hours per week </MenuItem>
                                <MenuItem value={'20'}>10-20 hours per week </MenuItem>
                                <MenuItem value={'30'}>20-30 hours per week</MenuItem>
                                <MenuItem value={'40'}>30-40 hours per week</MenuItem>
                              </Select>
                            </FormControl>
                            <div title="Availability means the number of hours you are available per week for engineering gigs.">
                              <HelpIcon sx={{ color: blue[500], fontSize: 16 }} />
                            </div>
                          </Box>
                        </Grid>

                        <Grid item xs={12} md={6} lg={6}>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              gap: '5px',
                              alignItems: 'center'
                            }}
                          >
                            <FormControl variant="outlined" fullWidth>
                              <InputLabel>Response time</InputLabel>
                              <Select
                                name="response"
                                value={values.response}
                                label="RESPONSE TIME"
                                placeholder="Do you have any response."
                                onChange={(e) => {
                                  handleChange(e);
                                  setDataChange(true);
                                }}
                                MenuProps={MenuProps}
                              >
                                <MenuItem value="">
                                  <em>What's your response time?</em>
                                </MenuItem>
                                <MenuItem value={'Less than 1hr'}>Less than 1hr</MenuItem>
                                <MenuItem value={'2-12 hours'}>2-12 hours</MenuItem>
                                <MenuItem value={'12-24 hours'}>12-24 hours</MenuItem>
                                <MenuItem value={'24-48 hours'}>24-48 hours</MenuItem>
                              </Select>
                            </FormControl>
                            <div title="Response time means your average turn-around time when responding to gig requests.">
                              <HelpIcon sx={{ color: blue[500], fontSize: 16 }} />
                            </div>
                          </Box>
                        </Grid>

                        <Grid item xs={12} md={6} lg={6}>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              gap: '5px',
                              alignItems: 'center'
                            }}
                          >
                            <FormControl variant="outlined" fullWidth>
                              <InputLabel>Currency</InputLabel>
                              <Select
                                name="currency"
                                value={values.currency}
                                label="Currency"
                                placeholder="Select your currency"
                                onChange={(e) => {
                                  handleChange(e);
                                  setDataChange(true);
                                  setCurrency(e.target.value);
                                }}
                                MenuProps={MenuProps}
                                InputLabelProps={{ shrink: true }}
                              >
                                <MenuItem value={'R'}>R ZAR </MenuItem>
                                <MenuItem value={'$'}>$ Dollar</MenuItem>
                              </Select>
                            </FormControl>
                            <HelpIcon sx={{ color: 'white', fontSize: 16 }} />
                          </Box>
                        </Grid>

                        <Grid item xs={12} md={6} lg={6}>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              gap: '5px',
                              alignItems: 'center'
                            }}
                          >
                            <FormControl variant="outlined" fullWidth>
                              <TextField
                                fullWidth={true}
                                label="Hourly rate"
                                required
                                name="hourlyRate"
                                value={values.hourlyRate}
                                onChange={(e) => {
                                  let hRate = Number(e.target.value);
                                  setFieldValue('hourlyRate', hRate);
                                }}
                                error={touched.hourlyRate && Boolean(errors.hourlyRate)}
                                helperText={touched.hourlyRate && errors.hourlyRate}
                                variant="outlined"
                                InputProps={{
                                  inputComponent: NumberFormatCustom,
                                  startAdornment: <InputAdornment position="start">{currency}</InputAdornment>,
                                  endAdornment: <InputAdornment position="start">/hr</InputAdornment>
                                }}
                              />
                            </FormControl>
                            <div title="Hourly rate means the rate you would like to be paid for a gig.">
                              <HelpIcon sx={{ color: blue[500], fontSize: 16 }} />
                            </div>
                          </Box>
                        </Grid>

                        <Grid item xs={12} md={6} lg={6}>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              gap: '5px',
                              alignItems: 'center'
                            }}
                          >
                            <FormControl
                              fullWidth
                              variant="outlined"
                              error={
                                _get(touched, 'levelOfExperience') &&
                                Boolean(_get(errors, 'levelOfExperience'))
                              }
                            >
                              <InputLabel>Level of expertise</InputLabel>
                              <Select
                                value={_get(values, 'levelOfExperience')}
                                label="LEVEL OF EXPERTISE"
                                onChange={(event) => {
                                  setDataChange(true);
                                  setFieldValue('levelOfExperience', event.target.value);
                                }}
                                MenuProps={MenuProps}
                              >
                                <MenuItem value={'Entry'}>{`Entry (<3 years experience)`}</MenuItem>
                                <MenuItem value={'Senior'}>{`Intermediate (4-10 years experience)`}</MenuItem>
                                {/* <MenuItem
                                value={'Intermediate'}
                              >{`Intermediate (5-10 years experience)`}</MenuItem> */}
                                <MenuItem value={'Senior'}>{`Senior (10-20 years experience)`}</MenuItem>
                                <MenuItem value={'Expert'}>{`Expert (>20 years experience)`}</MenuItem>
                              </Select>
                            </FormControl>
                            <HelpIcon sx={{ color: 'white', fontSize: 16 }} />
                          </Box>
                        </Grid>

                        <Grid item xs={12} md={6} lg={6}>
                          <Box
                            sx={{
                              display: 'grid',
                              gridTemplateColumns: '1fr auto',
                              gap: '5px',
                              alignItems: 'center',
                            }}
                          >
                            <Autocomplete
                              multiple
                              value={values.fieldOfSpecialization || []}
                              options={expertiseList.map((option) => option.areaofexpertise)}
                              getOptionDisabled={(options) => (values.fieldOfSpecialization.length > 2 ? true : false)}
                              onChange={(event, newValue) => {
                                setDataChange(true);
                                setFieldValue('fieldOfSpecialization', newValue);
                              }}
                              renderTags={(value, getTagProps) =>
                                value.map((option, index) => (
                                  <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                                ))
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  label="Field of engineering"
                                  helperText={values.fieldOfSpecialization.length > 2 ? <div className='maximum-limit-my-pro'>Maximum limit reached</div> : ''}
                                />
                              )}
                            />
                            <HelpIcon sx={{ color: 'white', fontSize: 16 }} />
                          </Box>
                        </Grid>

                        <Grid item xs={12} md={6} lg={6}>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              gap: '5px',
                              alignItems: 'center'
                            }}
                          >
                            <FormControl
                              fullWidth
                              variant="outlined"
                              error={_get(touched, 'isRemote') && Boolean(_get(errors, 'isRemote'))}
                            >
                              <InputLabel>Remote job preferred*</InputLabel>
                              <Select
                                value={values.isRemote}
                                label="Remote Job Preferred"
                                onChange={(event) => {
                                  setDataChange(true);
                                  setFieldValue('isRemote', event.target.value);
                                }}
                                MenuProps={MenuProps}
                              >
                                <MenuItem value={'true'}>{`Yes`}</MenuItem>
                                {/* <MenuItem value={'true'}>{`No`}</MenuItem> */}
                                <MenuItem value={'false'}>{`No`}</MenuItem>
                                <MenuItem value={'Hybrid'}>{`Hybrid`}</MenuItem>
                              </Select>
                            </FormControl>
                            <div title="Indicate your preference for remote or site based work.">
                              <HelpIcon sx={{ color: blue[500], fontSize: 16 }} />
                            </div>
                          </Box>
                        </Grid>

                        <Grid item xs={12} md={12} lg={12}>
                          <div className='tooltip-msg-url'>
                            <div className='main-container-url'>
                              <p class="label">Profile URL</p>
                              <div className='profile-input-container-wrapper'>
                              <span
                                class="input-container"
                              >
                                <p className='url-copy-origin'>{window.location.origin + '/profile/'}</p>
                                <TextField
                                  className='test-feild-text'
                                  placeholder="Profile Username"
                                  name="userName"
                                  onKeyUp={keyPress}
                                  value={values.userName}
                                  onBlur={handleBlur}
                                  size="small"
                                  error={touched.userName && Boolean(errors.userName)}
                                  onChange={(e) => {
                                    setFieldValue('userName', e.target.value);
                                  }}
                                />
                              </span>
                              <div className='right-icon-btn'>
                                {toolTip === true &&
                                  <img sx={{ color: '#0000FF' }} src={RightIcon} alt="righticon" fontSize="medium" width="50%" title='Profile Username is available.' />
                                }
                                {toolTip === false &&
                                  <img sx={{ color: '#0000FF' }} src={CrossIcon} alt="crossicon" fontSize="medium" width="50%" title='Profile Username is not available.' />
                                }
                              </div>
                             
                                <Button
                                className={`url-copy-btn-profile ${copyBtn === 'COPIED' ? 'url-copied-profile' : ''}`}
                                onClick={() => {
                                  navigator.clipboard.writeText(window.location.origin + `/profile/${props.profile.myProfile.userName}`);
                                  setCopyBtn('COPIED');
                                }}
                                variant="outlined"
                              >
                                {copyBtn}
                              </Button>
                             
                              </div>
                            </div>
                            <div title="Copy this link to share your Gig Engineer profile directly with prospective clients.">
                              <HelpIcon sx={{ color: blue[500], fontSize: 16 }} />
                            </div>
                          </div>
                          <div className='error-msg-pro-url'>{errors.userName}</div>
                        </Grid>

                        <Grid item xs={12} md={12} lg={12}>
                          <Box
                            sx={{
                              display: 'grid',
                              gridTemplateColumns: '1fr auto',
                              gap: '5px',
                              alignItems: 'center',
                            }}
                          >
                            <FormControl variant="outlined" fullWidth>
                              <RichTextEditor
                                className="new-post-editor-profile"
                                onChange={(value) => {
                                  setEditorValue(value);
                                  setCkeValue(value.toString("html"));
                                  setDataChange(true);
                                  sethasChanged(true);
                                }}
                                value={editorValue}
                                toolbarConfig={toolbarConfig}
                                placeholder='Profile overview'
                              />
                              {
                                !!editorValue && !editorValue.getEditorState().getCurrentContent().hasText() && hasChanged
                                &&
                                <p className='error-msg-my-profile'>Profile overview is required</p>
                              }
                            </FormControl>
                            <HelpIcon sx={{ color: 'white', fontSize: 16 }} />
                          </Box>
                        </Grid>

                        <Grid item xs={12} md={12} lg={12}>
                          <Box
                            sx={{
                              display: 'grid',
                              gridTemplateColumns: '1fr auto',
                              gap: '5px',
                              alignItems: 'center',
                            }}
                          >
                            <Autocomplete
                              multiple
                              options={skillsList.map((option) => option.skills)}
                              freeSolo
                              // getOptionDisabled={(options) => (values.skills.length > 9 ? true : false)}
                              value={values.skills}
                              onChange={(event, newValue) => {
                                setDataChange(true);
                                setFieldValue('skills', newValue);
                              }}
                              renderTags={(value, getTagProps) =>
                                value.map((option, index) => (
                                  <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                                ))
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  required
                                  label="Skills"
                                // placeholder="Skills"
                                />
                              )}
                            />
                            <div title="Please add your top 10 engineering skills e.g. Simulation.">
                              <HelpIcon sx={{ color: blue[500], fontSize: 16 }} />
                            </div>
                          </Box>
                        </Grid>

                        <Grid item xs={12} md={12} lg={12}>
                          <Box
                            sx={{
                              display: 'grid',
                              gridTemplateColumns: '1fr auto',
                              gap: '5px',
                              alignItems: 'center',
                            }}
                          >
                            <Autocomplete
                              multiple
                              options={tools.map((option) => option.tools)}
                              freeSolo
                              value={values.tools}
                              onChange={(event, newValue) => {
                                setDataChange(true);
                                setFieldValue('tools', newValue);
                              }}
                              renderTags={(value, getTagProps) =>
                                value.map((option, index) => (
                                  <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                                ))
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  required
                                  variant="outlined"
                                  label="Engineering tools & technologies"
                                // placeholder="Engineering tools & technologies"
                                />
                              )}
                            />

                            <div title="Please add the engineering tools and technologies you use on a regular basis e.g. AutoCAD.">
                              <HelpIcon sx={{ color: blue[500], fontSize: 16 }} />
                            </div>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                    </div>
                    <Grid item xs={12} md={3} lg={3} justifyContent="center">
                      <Box display="flex" justifyContent="center" alignItems="center" minHeight="188px">
                        <div className="avtar_profile">
                          <Avatar alt="Remy Sharp" src={img1} sx={{ width: 150, height: 150 }} />
                          <div className="avtar_group_profile" title="Max Size 5MB">
                            <React.Fragment>
                              {loading ? <CircularProgress className="upload-Loading"></CircularProgress> : props.icon ? props.icon :
                                <div className='profile-icon-drag'>
                                  <DragDropIcon onFileChange={(e) => handelChange(e)} icons={<img src={DragDropiconred} alt="dragdropiconred" />}></DragDropIcon>
                                </div>
                              }
                            </React.Fragment>
                            <Button
                              variant="outlined"
                              component="label"
                              sx={{ width: '40px', minWidth: '40px', height: '40px', minHeight: '40px', border: 'none', borderRadius: '50%', backgroundColor: '#ffff', boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px' }}
                              onClick={handleDeleteChange}
                            >
                              <DeleteForeverOutlinedIcon
                                sx={{ color: 'red', cursor: 'pointer' }}
                                fontSize="small"
                              />
                            </Button>
                          </div>
                        </div>
                      </Box>
                      <Grid align="center"
                        item
                        xs={12}
                        md={12}
                        lg={12}
                      >
                       <div className='text-company-icon'>

                          {props?.profile?.basicInfo?.name}
                          {
                            !!props?.profile?.isVerified && (props?.profile?.profileCompletionPercent == 100)
                            &&
                            <img className='verified-name-ico' src="https://gigengineer-production.s3.af-south-1.amazonaws.com/Assets/Blue+Tick+Verification.svg" />
                          }
                        </div>
                      </Grid>
                    </Grid>
                    
                    </div>
                  </Grid>
                </CardContent>
              </Card>

              <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
                {toolTip === false ?
                  <Button
                    variant="contained"
                    className={`primary-Btn ${props.saveSuccess && 'successActive'}`}
                    type="submit"
                    disabled
                  >
                    {props.saveSuccess ? 'saved' : 'save'}
                  </Button>
                  :
                  <Button
                    variant="contained"
                    className={`primary-Btn ${props.saveSuccess && 'successActive'}`}
                    type="submit"
                    onClick={() => {
                      setFieldValue("action", "save");
                    }}
                  >
                    {props.saveSuccess ? 'saved' : 'save'}
                  </Button>
                }
                <Button
                  variant="contained"
                  className="primary-Main-Btn"
                  type="submit"
                  onClick={() => {
                    setFieldValue("action", "next");
                  }}
                >
                  Next
                </Button>
              </Grid>
            </Form>
          )}
        </Formik>

      </PaddedBox>
      <PaddedFooter style={{ paddingLeft: `${!!props.auth && !!props.auth.isDrawerOpen && props.auth.isDrawerOpen == true ? "200px" : "0px"}` }}>
        <Footer />
      </PaddedFooter>
    </React.Fragment >
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    myProfile: state.profile.myProfile || {},
    skillList: state.profile.skillList || [],
    error: state.profile.error,
    saveSuccess: state.profile.saveSuccess,
    basicInfo: state.profile.basicInfo,
    profile: state.profile
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setProfileError: (details) => dispatch(setProfileError(details)),
    setProfileSuccess: (details) => dispatch(setProfileSuccess(details)),
    resetProfileSuccess: (details) => dispatch(resetProfileSuccess(details))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyProfile);
