export const unRegisterChatServices = () => {
  if(socket) {
    socket.off('newuser');
    socket.off('privatemessage');
    socket.off('get-all-users');
    socket.off('get-user-chat');
    socket.off('getNotification');
    socket.off('postNotification');
    socket.off('reconnect');
    socket.off('notifications');
    socket.off('disconnect');
    socket.off('checkPointGet');
    socket.off('checkPointUpdate');
    socket.off('get-user-name');
  }
}