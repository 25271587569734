import _get from 'lodash/get';
import apiService from './apiServices';

export const getGigs = async (data, setGigsList) => {
  const request = {
    data,
    method: 'post',
    url: '/opportunity/'
  };
  const response = await apiService(request);
  if (response && response.status === 200) {
    // sucess action
    setGigsList(_get(response, 'data', {}));
  }
};

export const getAppliedGigs = async (data, setGigsList, setListDataEngineer) => {
  const request = {
    data,
    method: 'post',
    url: '/opportunity/getApplications'
  };
  const response = await apiService(request);
  if (response && response.status === 200) {
    // sucess action
    setGigsList(_get(response, 'data', {}));
    setListDataEngineer(_get(response, 'data', {}));

  }
};

export const applyToGigApi = async (data, setGigsList) => {
  const request = {
    data,
    method: 'post',
    url: '/gig/apply'
  };
  const response = await apiService(request);
  if (response && response.status === 200) {
    // sucess action
    //setGigsList(_get(response, 'data', {}));
    if (!!setGigsList) {
      setGigsList(_get(response, 'data', {}));
    }
    return response;
  } else {
    return response;
  }
};

export const getActiveGigs = async (data, setGigsList, setListData) => {
  const request = {
    data,
    method: 'post',
    url: '/opportunity/getActive'
  };
  const response = await apiService(request);
  if (response && response.status === 200) {
    // sucess action
    setGigsList(_get(response, 'data', {}));
    if (!!setListData) {
      setListData(_get(response, 'data', {}));
    }
  }
};

export const getCompletedGigs = async (data, setGigsList, setListData) => {
  const request = {
    data,
    method: 'post',
    url: '/opportunity/getComplete'
  };
  const response = await apiService(request);
  if (response && response.status === 200) {
    // sucess action
    setGigsList(_get(response, 'data', {}));
    if(!!setListData){
      setListData(_get(response, 'data', {}));
    }
  }
};
