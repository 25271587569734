export const SET_GIGS_LIST = '@gig/gigs/list';
export const SET_SAVED_LIST = '@gig/gigs/saved';
export const SET_GIGS_DRAFT = '@gig/owner/gigs/draft';
export const SET_LOCATION_DATA = "SET_LOCATION_DATA"
export const SET_UNSET_INVOICE_TYPE = "SET_UNSET_INVOICE_TYPE"
export const SET_NEW_FEEDBACK = 'SET_NEW_FEEDBACK';
export const GET_ALL_FEEDBACK = 'GET_ALL_FEEDBACK';
export const GET_FEEDBACK_DETAIL = 'GET_FEEDBACK_DETAIL';
export const SET_FEEDBACK_MANUAL = 'SET_FEEDBACK_MANUAL';
export const SET_VERIFY_FEEDBACK = 'SET_VERIFY_FEEDBACK';

export const setGigsList = (data) => ({
  type: SET_GIGS_LIST,
  payload: data
});

export const setSavedList = (data) => ({
  type: SET_SAVED_LIST,
  payload: data
});

export const setDraftGigList = (data) => ({
  type: SET_GIGS_DRAFT,
  payload: data
});

export const setLocationData = (data) => ({
  type: SET_LOCATION_DATA,
  payload: data
});

export const setUnsetInvoiceType = (data) => ({
  type: SET_UNSET_INVOICE_TYPE,
  payload: data
});

export const setNewFeedback = (data) => ({
  type: SET_NEW_FEEDBACK,
  payload: data
});

export const getAllFeedback = (data) => ({
  type: GET_ALL_FEEDBACK,
  payload: data
});

export const getFeedbackDetail = (data) => ({
  type: GET_FEEDBACK_DETAIL,
  payload: data
});

export const setFeedbackManual = (data) => ({
  type: SET_FEEDBACK_MANUAL,
  payload: data
});

export const setVerifyFeedback = (data) => ({
  type: SET_VERIFY_FEEDBACK,
  payload: data
});