import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import {
  Box,
  Grid,
  Divider,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  TextField
} from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { Formik, Form } from 'formik';
import InputUnstyled from '@mui/base/InputUnstyled';
import Chip from '@mui/material/Chip';
import DatePicker from '@mui/lab/DatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import { TypographyBold, CustomRow, CalendarBox } from '../../../containers/app.styles';
import { ApplyBtn, CancelBtn } from '../sfs.styles';

import { setFliters } from '../../../store/actions/sfs';
import { Typography, Button } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { getCountryStateCity } from '../../../../src/services/expertisewithskills';
import { getSkillsList } from '../../../../src/services/expertisewithskills';
import { getExpertiseSkillsDetApi } from '../../../../src/services/expertisewithskills';
import { getUserType } from '../../../utils/user';

function getStyles(name, skills) {
  return {
    fontWeight: skills.indexOf(name) === -1 ? 'normal' : 'bold'
  };
}

const filter = createFilterOptions();

const FiltersList = (props) => {
  // const defaultVal = {
  //   fieldOfSpecialization: '',
  //   isRemote: null,
  //   availabilityHoursPerWeek: 0
  // };

  let filterDefal = !_isEmpty(props.filters) ? props.filters : {};

  const [countries, setCountries] = useState([]);
  const [optedCountry, setOptedCountry] = useState('');
  const [optedProvince, setOptedProvince] = useState('');
  const [optedCity, setOptedCity] = useState('');
  const [skillList, setSkillList] = useState([]);
  const [expertiseList, setExpertiseList] = useState([]);
  const [skills, setSkills] = React.useState(props.selectedSkills || []);
  const [valueExpertise, setValueExpertise] = useState('');

  const userType = getUserType();
  const initialRender = useRef(true);

  const getExpertiseList = async () => {
    let expertise = await getExpertiseSkillsDetApi();
    if (expertise?.data && expertise?.data.length > 0) {
      setExpertiseList(expertise.data);
    }
  };

  const getSkills = async () => {
    let skills = await getSkillsList();
    if (skills?.data && skills?.data.length > 0) {
      setSkillList(skills.data);
    }
  };

  const getCountriesList = async () => {
    let countries = await getCountryStateCity();
    if (countries?.data && countries?.data.length > 0) {
      setCountries(countries.data);
    }
  };

  const getLanguageList = async () => {
    let language = await getLanguage();
    if (language?.data && language?.data.length > 0) {
      setLanguage(language.data);
    }
  };

  const getCountryValue = (val) => {
    if (val) setOptedCountry(val);
  };

  const getProvinceValue = (val) => {
    if (val) setOptedProvince(val);
  };

  const getCityValue = (val) => {
    if (val) setOptedCity(val);
  };

  const handleOnChange = (event) => {
    const {
      target: { value }
    } = event;
    typeof value === 'string' ? value.split(',') : value;
    skills.push(value);
  };

  useEffect(() => {
    getCountriesList();
    setOptedCountry(
      props?.filters?.location?.country ? props.filters.location.country : 'South Africa'
    );
    setOptedProvince(props?.filters?.location?.province);
    setOptedCity(props?.filters?.location?.city);
    getSkills();
    getExpertiseList();
  }, [props]);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 300
      }
    }
  };

  return (
    <Box sx={{ width: 350, padding: '24px' }}>
      <Typography
        variant="h6"
        component="h6"
        color="pColor.main"
        sx={{
          fontWeight: 'Medium',
          textTransform: 'capitalize',
          fontSize: '22px',
          margin: '0 0 10px 0'
        }}
      >
        Filters
      </Typography>
      {/* <Divider light variant="fullWidth" sx={{ margin: '10px 0px' }} /> */}

      <Formik
      
        
        // initialValues={{
        //   ...filterDefal
        // }}

        initialValues={
          !_isEmpty(props.filters) ? { ...filterDefal } : { location: { country: optedCountry } }
        }
        enableReinitialize={true}
        //validationSchema={newGigProjectDetailsSchema}
        onSubmit={(values) => {
          const obj = {
            ...values
          };
          props.toggleDrawer(false);
          props.setFliters(obj);
        }}
      >
        {(formProps) => {
          const { values, setFieldValue, handleChange, touched, errors, handleReset } = formProps;

          return (
            <Form noValidate autoComplete="off">
              <Grid item container spacing={3}>
                <Grid item xs={12} md={12} lg={12}>
                  <Autocomplete
                    value={valueExpertise}
                    onChange={(event, newValue) => {
                      if (newValue) {
                        // Create a new value from the user input
                        setFieldValue(
                          'fieldOfSpecialization.areaofexpertise',
                          newValue.areaofexpertise
                        );
                        setValueExpertise(newValue.areaofexpertise);
                      }
                    }}
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);
                      const { inputValue } = params;
                      // Suggest the creation of a new value
                      const isExisting = options.some(
                        (option) => inputValue === option.areaofexpertise
                      );
                      if (inputValue !== '' && !isExisting) {
                        filtered.push({
                          inputValue,
                          areaofexpertise: `${inputValue}`
                        });
                      }
                      return filtered;
                    }}
                    // selectOnFocus
                    handleHomeEndKeys
                    id="free-solo-with-text-demo"
                    options={expertiseList}
                    getOptionLabel={(option) => {
                      // Value selected with enter, right from the input
                      if (typeof option === 'string') {
                        return option;
                      }
                      // Regular option
                      return option.areaofexpertise;
                    }}
                    renderOption={(props, option) => <li {...props}>{option.areaofexpertise}</li>}
                    // sx={{ width: 428 }}
                    freeSolo
                    renderInput={(params) => <TextField {...params} label="FIELD OF ENGINEERING" />}
                  />

                  {/* <FormControl key={Math.random()} fullWidth variant="outlined" error={props.validationError}>
                    <InputLabel>FIELD OF ENGINEERING</InputLabel>
                    <Select
                      label="FIELD OF ENGINEERING"
                      value={valueExpertise}
                      // MenuProps={{ PaperProps: { sx: { maxHeight: 300 } } }}

                      onChange={(event) => {
                        const action = event.target.value;
                        setFieldValue('fieldOfSpecialization.areaofexpertise', action);
                        setValueExpertise(action);
                      }}
                      variant="outlined"
                      MenuProps={MenuProps}
                    >
                      {expertiseList.length > 0 &&
                        expertiseList.map((name) => {
                          return (
                            <MenuItem value={name.areaofexpertise} key={name.areaofexpertise}>
                              {name.areaofexpertise}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl> */}
                </Grid>

                <Grid item xs={12} md={12} lg={12}>
                  <Autocomplete
                    multiple
                    id="tags-skills"
                    value={values.skillsList}
                    options={skillList.map((option) => option.skills)}
                    onChange={(event, newValue) => {
                      let fielsOfEng = _get(values, 'skillsList', '');
                      const cloneSkillList = [...skillList];
                      if (newValue.length > fielsOfEng.length) {
                        const skillAfterRemoving = cloneSkillList.filter(
                          (e) => e.skills != newValue[newValue.length - 1]
                        );
                        setSkillList(skillAfterRemoving);
                      } else {
                        const difference = fielsOfEng.filter((e) => newValue.indexOf(e) === -1);
                        cloneSkillList.push({ skills: difference[0] });
                        const uniqueSkills = [
                          ...new Map(cloneSkillList.map((item) => [item['skills'], item])).values()
                        ];
                        setSkillList(uniqueSkills);
                      }

                      let finalArray = [];
                      for (let item of newValue) {
                        if (typeof item === 'object') {
                          finalArray.push(item.skills);
                        } else {
                          finalArray.push(item);
                        }
                      }
                      setFieldValue('skillsList', finalArray);
                    }}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="SKILLS"
                        placeholder="SKILLS"
                      />
                    )}
                  />

                  {/* <FormControl key={Math.random()} fullWidth variant="outlined" error={props.validationError}>
                    <InputLabel>Skills</InputLabel>
                    <Select
                      label="Skills"
                      value={values.skillsList}
                      variant="outlined"
                      MenuProps={MenuProps}
                      onChange={(event) => {
                        handleOnChange(event);
                        setFieldValue('skillsList', skills);
                      }}
                      renderValue={(selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                          {selected.map((value) => (
                            <Chip key={value} label={value} />
                          ))}
                        </Box>
                      )}
                    >
                      {skillList.length > 0 &&
                        skillList.map((name) => {
                          return (
                            <MenuItem value={name.skills} key={name.skills}>
                              {name.skills}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl> */}
                </Grid>

                <Grid item xs={12} md={12} lg={12}>
                  <FormControl variant="outlined" sx={{ width: '100%' }}>
                    <InputLabel id="demo-simple-select-standard-label">COUNTRY</InputLabel>
                    <Select
                      value={optedCountry}
                      onChange={(e) => {
                        handleChange(e);
                        setFieldValue('location.country', e.target.value);
                        getCountryValue(e.target.value);
                      }}
                      label="COUNTRY"
                      MenuProps={MenuProps}
                      InputLabelProps={{ shrink: true }}
                      required
                    >
                      {countries.length > 0 &&
                        countries.map(({ name }) => {
                          return (
                            <MenuItem value={name} key={name}>
                              {name}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <FormControl variant="outlined" sx={{ width: '100%' }}>
                    <InputLabel id="demo-simple-select-standard-label">PROVINCE</InputLabel>
                    <Select
                      value={optedProvince}
                      onChange={(e) => {
                        handleChange(e);
                        setFieldValue('location.province', e.target.value);
                        getProvinceValue(e.target.value);
                      }}
                      label="PROVINCE"
                      MenuProps={MenuProps}
                      InputLabelProps={{ shrink: true }}
                    >
                      {countries.length > 0 &&
                        countries
                          .find((x) => x.name === optedCountry)
                          ?.states.map(({ name }) => {
                            return (
                              <MenuItem value={name} key={name}>
                                {name}
                              </MenuItem>
                            );
                          })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel id="demo-simple-select-standard-label">CITY</InputLabel>
                    <Select
                      value={optedCity}
                      onChange={(e) => {
                        handleChange(e);
                        setFieldValue('location.city', e.target.value);
                        getCityValue(e.target.value);
                      }}
                      label="CITY"
                      MenuProps={MenuProps}
                      InputLabelProps={{ shrink: true }}
                    >
                      {countries.length > 0 &&
                        countries
                          .find((el) => el.name === optedCountry)
                          ?.states.find((el) => el.name === optedProvince)
                          ?.cities.map(({ name }) => {
                            return (
                              <MenuItem value={name} key={name}>
                                {name}
                              </MenuItem>
                            );
                          })}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={12} lg={12}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel>REMOTE JOB</InputLabel>
                    <Select
                      value={_get(values, 'isRemote', '')}
                      label="REMOTE JOB"
                      onChange={(event) => {
                        setFieldValue('isRemote', event.target.value);
                      }}
                    >
                      <MenuItem value={'true'}>{`Yes`}</MenuItem>
                      <MenuItem value={'false'}>{`No`}</MenuItem>
                      <MenuItem value={'Hybrid'}>{`Hybrid`}</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={12} lg={12}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      gap: '5px',
                      alignItems: 'center'
                    }}
                  >
                    <FormControl
                      fullWidth
                      variant="outlined"
                    >
                      <InputLabel id="demo-simple-select-label">Currency</InputLabel>
                      <Select
                        value={_get(values, 'currency')}
                        label="Availability (per week)"
                        onChange={(event) => {
                          setFieldValue('currency', event.target.value);
                        }}
                        MenuProps={MenuProps}
                        InputLabelProps={{ shrink: true }}
                      >
                        <MenuItem value={'R'}>R ZAR </MenuItem>
                        <MenuItem value={'$'}>$ Dollar</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>

                <Grid item xs={12} md={12} lg={12}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel>AVAILABILITY (per week)</InputLabel>
                    <Select
                      value={_get(values, 'availabilityHoursPerWeek', '')}
                      label="AVAILABILITY (per week)"
                      onChange={(event) => {
                        setFieldValue('availabilityHoursPerWeek', event.target.value);
                      }}
                    >
                      <MenuItem value={10}>0-10 hours per week</MenuItem>
                      <MenuItem value={20}>10-20 hours per week</MenuItem>
                      <MenuItem value={30}>20-30 hours per week</MenuItem>
                      <MenuItem value={40}>30-40 hours per week</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                {props.filterType === 'gig' && (
                  <Grid item xs={12} md={12} lg={12}>
                    <TextField
                      fullWidth
                      name="minHourlyRate"
                      label="MIN HOURLY RATE"
                      value={values.minHourlyRate || ''}
                      onChange={handleChange}
                      variant="outlined"
                      type="number"
                    />
                  </Grid>
                )}

                {props.filterType === 'gig' && (
                  <Grid item xs={12} md={12} lg={12}>
                    <Box sx={{ marginBottom: '5px', color: 'primary.main', marginTop: '10px' }}>
                      Project start date after
                    </Box>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        disablePast
                        views={['year', 'month', 'day']}
                        value={new Date(values.startDateAfter || '')}
                        name="startDateAfter"
                        inputFormat="dd/MM/yyyy"
                        onChange={(newValue) => {
                          setFieldValue('startDateAfter', newValue);
                        }}
                        renderInput={({ inputRef, inputProps, InputProps }) => (
                          <CalendarBox sx={{ border: '1px solid #7E84A3', marginTop: '10px' }}>
                            {InputProps?.endAdornment}
                            <InputUnstyled className="calendar-Input" ref={inputRef} {...inputProps} />
                          </CalendarBox>
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                )}

                {props.filterType === 'engineer' && (
                  <Grid item xs={12} md={12} lg={12}>
                    <TextField
                      fullWidth
                      name="maxHourlyRate"
                      label="MAX HOURLY RATE"
                      value={values.maxHourlyRate || ''}
                      onChange={handleChange}
                      variant="outlined"
                      type="number"
                    />
                  </Grid>
                )}
              </Grid>

              <Divider light variant="fullWidth" sx={{ margin: '20px 0px' }} />

              <Grid container spacing={2} justifyContent="center">
                <Grid item xs={12} sm={12} md={12}>
                  <ApplyBtn
                    variant="contained"
                    className="h-color-button"
                    type="submit"
                    sx={{ marginLeft: '10px', minWidth: '160px' }}
                  >
                    Apply
                  </ApplyBtn>
                </Grid>
              </Grid>
              <Box
                variant="outlined"
                type="button"
                // className="h-color-button-outlined"
                sx={{
                  color: '#f99600',
                  position: 'absolute',
                  right: '20px',
                  top: '20px',
                  cursor: 'pointer'
                }}
                onClick={() => {
                  props.toggleDrawer(false);
                  props.setFliters({});
                }}
                fontSize="large"
              >
                <CancelIcon />
              </Box>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    filters: state.sfs.filters
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setFliters: (details) => dispatch(setFliters(details))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FiltersList);
