import { TableContainer, Typography, Paper, Table, TableBody, styled, TableCell, tableCellClasses, TableRow, TableHead, Pagination } from '@mui/material';
import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { CustomRow, PaddedBox, PaddedFooter } from '../../app.styles';
import { useState } from 'react';
import NoInvoiceGraphic from '../common/images/NoInvoiceGraphic.png'
import MoreVert from '../common/images/more_vert.svg'
import './styles.scss'
import { getUserId, getUserType } from '../../../utils/user';
import { fetchInvoiceListBusiness, fetchInvoiceListEngineer } from '../../../services/invoicing';
import { _get } from 'lodash/get';
import { _toNumber } from 'lodash/toNumber';
import Footer from '../../../components/footer1/footer';
import { NumberFormatter } from '../../../utils/helpers';

function InvoicePending(props) {

    const dispatch = useDispatch();
    const history = useHistory();
    const userType = getUserType();
    const [invoiceList, setinvoiceList] = useState([]);
    const [paginationVal, setPaginationVal] = useState(1);
    const [page, setPage] = useState(1); // active enginner list page number
    const [count, setCount] = useState(1); // count of total engineer
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
          backgroundColor: theme.palette.common.black,
          color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
          fontSize: 14,
        },
    }));
      
    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
          backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
          border: 0,
        },
    }));

    const createTableRows = (data) => {
        let rows = []
        data.invoiceList.map((item,index)=>{
            rows.push({
                invoiceId: item._id,
                invoiceNumber: item.invoiceId,
                gigName: item.gigDetails.titleAndSkills.projectTitle,
                invoiceType: item.invoiceType,
                dateCreated: (new Date(item.invoiceDate).toLocaleDateString('en-in', {year:"numeric", month:"short", day:"numeric"})),
                amount: item.invoiceTotal,
                currency: item.currency,
                pdflink: item.pdflink,
                engineerName: !!item.engineerDetails?.profileData?.basicInfo?.name?item.engineerDetails?.profileData?.basicInfo?.name:"-",
                gigId: item.gigId,
                engineerId: item.engineerId,
            })
        })
        setinvoiceList(rows)
    }

    const fetchData = (pageNumber = 1) => {
        if(userType=="engineer"){
            fetchInvoiceListEngineer({
                "paginationInput": {
                    "pageNumber": pageNumber,
                    "pageSize": 10
                },
                "status": [
                    "PENDING",
                    "PAYMENTINITIATED",
                    "PAYMENTRECIEVED"
                ],
                "engineerId": getUserId()
            }).then((e)=>{
                createTableRows(e)
                setListData(e)
            }).catch((err)=>{
                console.error(err)
            })
        }
        else if(userType=="business"){
            fetchInvoiceListBusiness({
                "paginationInput": {
                    "pageNumber": pageNumber,
                    "pageSize": 10
                },
                "status": [
                    "PENDING",
                    "PAYMENTINITIATED",
                ],
                "businessId": getUserId()
            }).then((e)=>{
                createTableRows(e)
                setListData(e)
                
            }).catch((err)=>{
                console.error(err)
            })
        }
    }

    useEffect(() => {
        try{
            fetchData(1)
        }
        catch(e){
            console.error(e)
        }
    }, [])
    
    const handleRedirectToInvoice = (id) => {
        if(userType=="engineer"){
            history.push(`/engineer/invoices/${id}`)
        }
        else if(userType=="business"){
            history.push(`/business/invoices/${id}`)
        }
    }

    const handleChange = (event, View) => {
        // scrollOnTop();
        fetchData(View);
        setPaginationVal(View);
    };

    const setListData = (data) => {
        setPage(data.paginationResult.currentPage);
        setCount(data.paginationResult.totalPages);
        setPaginationVal(data.paginationResult.totalPages);
    };

    const handleGigClick = (gigId) => {
        if(userType=="admin"){
            history.push(`/admin/owner/allgigs/details/${gigId}`)
        }
        else if(userType=="business"){
            history.push(`/gig/active/${gigId}`)
        }
        else if(userType=="engineer"){
            history.push(`/active/${gigId}`)
        }
        else{
            console.error("Failed to get user type")
        }
    }

    return (
        <>
        <PaddedBox style={{ paddingLeft: `${!!props.auth && !!props.auth.isDrawerOpen && props.auth.isDrawerOpen==true?"240px":"0px"}`, minHeight: "580px" }}>
            <CustomRow container>
                <Typography variant="h6" component="h6" color="pColor.main" sx={{ fontWeight: 'Medium', textTransform: 'capitalize', fontSize: '22px' }}>                    
                    Pending
                </Typography>
            </CustomRow>
            
            {
                !!invoiceList&&invoiceList.length>0?
                <div className='data-table-wrapper'>
                    <TableContainer sx={{overflow: "visible"}} component={Paper}>
                        <Table sx={{ minWidth: 700 }} aria-label="customized table">
                            <TableHead>
                            <TableRow>
                                <TableCell align="center">Invoice Number</TableCell>
                                <TableCell align="center">Gig Name</TableCell>
                                {
                                    userType=="business"
                                    &&
                                    <TableCell align="center">Engineer Name</TableCell>
                                }
                                <TableCell align="center">Invoice Type</TableCell>
                                <TableCell align="center">Date Created</TableCell>
                                <TableCell align="center">Amount</TableCell>
                                <TableCell align="center">&nbsp;</TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                            {invoiceList.map((row,index) => (
                                <StyledTableRow key={row.invoiceNumber}>
                                <TableCell align="center"><span className='invoice-id' title={"Click to view details"} onClick={()=>{handleRedirectToInvoice(row.invoiceId)}}>{row.invoiceNumber}</span></TableCell>
                                <TableCell align="center"><span className='invoice-id' title={"Click to view details"} onClick={()=>{handleGigClick(row.gigId)}}>{row.gigName}</span></TableCell>
                                {
                                    userType=="business"
                                    &&
                                    <TableCell align="center"><span className='invoice-id' title={"Click to view details"} onClick={()=>{history.push(`/engineer/details/${row.engineerId}`)}}>{row.engineerName}</span></TableCell>
                                }
                                <TableCell align="center">{row.invoiceType}</TableCell>
                                <TableCell align="center">{row.dateCreated}</TableCell>
                                <TableCell align="center">{row.currency} {NumberFormatter(row.amount)}</TableCell>
                                <TableCell align="center">
                                    <div className='list-cta-dd'>
                                        <img src={MoreVert} />
                                        <a href={row.pdflink} target={"_blank"} className='dd-pdf-cta'>DOWNLOAD</a>
                                    </div>
                                </TableCell>
                                </StyledTableRow>
                            ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <br/>
                    <Pagination
                        page={page}
                        count={paginationVal}
                        shape="rounded"
                        className="pagination-root"
                        onChange={handleChange}
                        defaultPage={page}
                    />
                </div>
                :
                <div className='no-data-wrapper'>
                    <img src={NoInvoiceGraphic} />
                    <p>No invoices found</p>
                </div>
            }
        </PaddedBox>
        <PaddedFooter style={{ paddingLeft: `${!!props.auth && !!props.auth.isDrawerOpen && props.auth.isDrawerOpen == true ? "200px" : "0px"}` }}>
         <Footer />
       </PaddedFooter>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
      auth: state.auth,
      gigs: state.gigs
    };
};

export default connect(mapStateToProps)(InvoicePending)
