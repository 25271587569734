import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _toNumber from 'lodash/toNumber';
import _last from 'lodash/last';
import { PaddedBox, PaddedFooter } from '../../app.styles';
import { Grid } from '@mui/material';
import GridTable from '../../../components/GridTable/GridTable';
import { getQueryParams } from '../../../utils/helpers';
import { getGigs, updateGigStatus } from '../../../services/gig';
import Prompt from '../../../components/Prompt/Prompt';
import ActionDropDown from '../../../components/DropDown/DropDown';
import Typography from '@mui/material/Box';
import { connect } from 'react-redux';
import '../gigDashboard/GigDashboard.css';
import Footer from '../../../components/footer1/footer';
import PageLoader from '../../../components/Loaders/PageLoader';

const InActiveGigs = (props) => {
  const query = getQueryParams();
  const history = useHistory();
  const queryPage = query.page;
  const [open, setOpen] = useState(false);
  const [promptData, setPromptData] = useState(undefined);
  const [messageBody, setMessageBody] = useState('');
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(queryPage || 1);
  const [paginationVal, setPaginationVal] = useState(1);

  const createData = (name, duration, rate, hours, location, id, dropdown) => {
    if (rate === "") {
      rate = "Not Disclosed";
    }
    return [name, duration, rate, hours, location, id, dropdown];
  };

  const resetPage = (data) => {
    const dd = getDropDown(data.id, data.index);
    if (data.index != undefined)
      rows[data.index][5] = dd;
    setRows(rows);
  };

  const onPromptSelected = (data) => {
    setOpen(false);

    if (data.action == "Edit" && data.type == "yes") {
      history.push(`/gig/edit/${data.id}`);
      return;
    }

    if (data.type === 'no') {
      resetPage(data);
    } else {
      resetPage(data);
      // action
      const obj = {
        _id: data.id,
        status: data.action === 'Close' ? 'CLOSED' : 'ACTIVE'
      };
      updateGigStatus(
        obj,
        (response) => {
          //error response
        },
        (response) => {
          //success response
          fetchData(page);
        }
      );
    }
  };

  const getDropDown = (id, index, count) => {
    const onDropDownValueChange = (action) => {
      const valueObj = {
        Active: 'activate',
        Close: 'close'
      };
      if (action !== 'Inactive') {
        setPromptData({ action, id, index });
        setMessageBody(`Do you want to ${valueObj[action]} the gig?`);
        setOpen(true);
      }
    };
    return (
      <ActionDropDown
        dropDownChange={onDropDownValueChange}
        defaultValue={'Inactive'}
        values={['Active', 'Inactive']}
        editIcon={count}
        getEditOption={getEditOption}
        id={"inactive"}
      />
    );
  };

  const getEditOption = (action, id) => {
    if (action == 'Edit') {
      setPromptData({ action, id });
      setMessageBody(`Do you want to ${action} this gig?`);
      setOpen(true);
    }
  };

  const setData = (data) => {
    const rowData = [];
    const gigList = _get(data, 'gigList', []);
    const paginationObject = _get(data, 'paginationResult', {});
    for (let i = 0; i < gigList.length; i++) {
      const gig = gigList[i];
      const name = _get(gig, 'titleAndSkills.projectTitle', '');
      const duration = _get(gig, 'applicantCount', 0);
      const rate = _get(gig, 'scopeAndBudget.hourlyRate', '') ? `${_get(gig, 'scopeAndBudget.currency', 'R')} ${_get(gig, 'scopeAndBudget.hourlyRate', '')} /hr` : '';
      const hours = _get(gig, 'scopeAndBudget.availabilityHoursPerWeek', '') ? `${_get(gig, 'scopeAndBudget.availabilityHoursPerWeek', '')} hrs/week` : '';
      const location = _get(gig, 'deliverablesAndLocation.location.country', '') ? _get(gig, 'deliverablesAndLocation.location.country', '') : "Remote";

      const id = _get(gig, '_id', '');

      rowData.push(
        createData(name, duration, rate, hours, location, getDropDown(id, i, duration), { id, name })
      );
    }
    setRows(rowData);
    setPaginationVal(paginationObject.totalPages || 1);
  };

  const handleRowClick = (data) => {
    const gigData = _last(data);
    history.push(`/gig/inact/applicants/${gigData.id}?page=${page}&gigname=${gigData.name}`);
  };

  const handleRClick = (data) => {
    const gigData = _last(data);
    history.push(`/gig/inact/${gigData.id}`);
  };

  const fetchData = (currentPage) => {
    const obj = {
      paginationInput: {
        pageNumber: _toNumber(currentPage),
        pageSize: 10
      },
      status: ['INACTIVE']
    };
    getGigs(obj, setData);
  };

  useEffect(() => {
    fetchData(page);
  }, []);

  const handleChange = (event, value) => {
    fetchData(value);
    setPage(value);
  };

  const headers = ['GIG NAME', 'APPLICATIONS', 'RATE', 'AVAILABILITY', 'LOCATION', 'ACTIONS'];

  return (
    <>
    <PaddedBox style={{ paddingLeft: `${!!props.auth && !!props.auth.isDrawerOpen && props.auth.isDrawerOpen==true?"240px":"0px"}`, minHeight: "580px" }}>
      <Grid container>
        <Typography
          className='font-fix'
          variant="h6"
          component="h6"
          color="pColor.main"
        >
          Inactive Gigs
        </Typography>        
        <Grid container className="table-Padding">
          <GridTable
            headers={headers}
            rows={rows}
            handleRowClick={handleRowClick}
            handleRClick={handleRClick}
            handleChange={handleChange}
            pagination={paginationVal}
            defaultPage={_toNumber(page)}
            emptyMessage="You don’t have any Inactive Gigs"
          ></GridTable>
        </Grid>
      </Grid>
      {open ? (
        <Prompt
          message={messageBody}
          show={open}
          heading="Confirmation"
          data={promptData}
          values={['yes', 'no']}
          onPromptSelected={(data) => {
            onPromptSelected(data);
          }}
        ></Prompt>
      ) : (
        <></>
      )}
    </PaddedBox>
     <PaddedFooter style={{ paddingLeft: `${!!props.auth && !!props.auth.isDrawerOpen && props.auth.isDrawerOpen == true ? "240px" : "0px"}` }}>
     <Footer />
   </PaddedFooter>
   </>
  );
};
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};
export default connect(mapStateToProps)(InActiveGigs);
