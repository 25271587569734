import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid } from '@mui/material';
import { PaddedBox, PaddedFooter } from '../../app.styles';
import _last from 'lodash/last';
import _toNumber from 'lodash/toNumber';
import _get from 'lodash/get';
import Typography from '@mui/material/Box';
import { connect } from 'react-redux';
import { useEffect } from 'react';
import _isEmpty from 'lodash/isEmpty';
import axios from 'axios';
import apiService from '../../../services/apiServices';
import { getUserId } from '../../../utils/user';
import { formatDate, getStringLocation } from '../../../utils/helpers';
import GridTableEngineer from '../../../components/GridTable/GridTableEngineer';
import './EngineerDashboard.css';
import Footer from '../../../components/footer1/footer';
import PageLoader from '../../../components/Loaders/PageLoader';

const EngineerDashboard = (props) => {
    const history = useHistory();
    const [appliedGigs, setAppliedGigs] = useState([]);
    const [appliedRecords, setAppliedRecords] = useState(false);
    const [activeGigs, setActiveGigs] = useState([]);
    const [activeRecords, setActiveRecords] = useState(false);
    const [completedGigs, setCompletedGigs] = useState([]);
    const [completedRecords, setCompletedRecords] = useState(false);

    const fetchData = (type, path) => {
        const requestBody = {
            'userId': getUserId(),
            'paginationInput': {
                pageNumber: 1,
                pageSize: 3
            },
            'status': [type]
        };

        const request = {
            data: requestBody,
            method: 'post',
            url: path
        };
        return apiService(request);
    };

    const setData = (data, setGigs, setRecords) => {
        const rowData = [];
        const gigList = _get(data, 'gigList', []);
        const totalRecords = _get(data, 'paginationResult.totalRecords', 0);
        if (gigList && gigList.length > 0) {
            for (let obj of gigList) {
                const gigName = _get(obj, 'titleAndSkills.projectTitle', '');
                const gigId = _get(obj, 'applicationDetails.gigId', '');
                const userId = _get(obj, 'applicationDetails.userId', '');
                const location = getStringLocation(obj.deliverablesAndLocation, obj.isRemote);
                const startDate = _get(obj, 'scopeAndBudget.startDate', '') ? formatDate(_get(obj, 'scopeAndBudget.startDate', '')) : '-';
                const toDate = '-';
                const id = _get(obj, '_id', '');
                rowData.push([gigName, location, startDate, toDate, { id, name }, obj, gigId, userId]);
            }
        }
        setGigs(rowData);
        if (totalRecords > 3) {
            setRecords(true);
        }
    };

    useEffect(() => {
        axios
            .all([fetchData('ACTIVE', '/opportunity/getActive'), fetchData('APPLIED', '/opportunity/getApplications'),
            fetchData('COMPLETED', '/opportunity/getComplete')])
            .then(
                axios.spread((...responses) => {
                    const actGigs = responses[0];
                    const appliedGigs = responses[1];
                    const completedGigs = responses[2];
                    setData(actGigs.data, setActiveGigs, setActiveRecords);
                    setData(appliedGigs.data, setAppliedGigs, setAppliedRecords);
                    setData(completedGigs.data, setCompletedGigs, setCompletedRecords);
                })
            )
            .catch((errors) => {
            });
    }, []);

    const handleAppliedClick = (obj, data) => {
        history.push(`/applied/${_get(obj[5], '_id', '')}`, obj[5]);
    };

    const handleActiveClick = (obj, data) => {
        history.push(`/active/${_get(obj[5], '_id', '')}`, obj[5]);
    };

    const handleCompleteClick = (obj, data) => {
        history.push(`/complete/${_get(obj[5], '_id', '')}`, obj[5]);
    };

    const headers = ['GIG NAME', 'LOCATION', 'START DATE', 'END DATE'];

    const redirectToAppliedGigs = () => {
        history.push('/applied');
    };

    const redirectToActiveGigs = () => {
        history.push('/active');
    };

    const redirectToCompletedGigs = () => {
        history.push('/complete');
    };

    return  (
      <>
            <PaddedBox style={{ paddingLeft: `${!!props.auth && !!props.auth.isDrawerOpen && props.auth.isDrawerOpen == true ? "240px" : "0px"}` }}>
                <Grid container>
                    <Typography
                        className='engineer-dashboard-heading'
                        variant="h6"
                        component="h6"
                        color="pColor.main"
                    >
                        My Gigs
                    </Typography>
                </Grid>
                <Grid container>
                    <Grid container className="table-heading-text">
                        <GridTableEngineer
                            className="active-gigs-grid"
                            TableHeadCellWidth="25%"
                            headProp="Applied Gigs"
                            handleRClick={handleAppliedClick}
                            headers={headers}
                            hidePagination={true}
                            rows={appliedGigs}
                            emptyMessage="You don’t have any Applied Gigs"
                            viewAll={appliedRecords ? redirectToAppliedGigs : undefined}
                        >
                        </GridTableEngineer>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid container className="table-heading-text">
                        <GridTableEngineer
                            className="active-gigs-grid"
                            headProp="Active Gigs"
                            handleRClick={handleActiveClick}
                            headers={headers}
                            hidePagination={true}
                            rows={activeGigs}
                            emptyMessage="You don’t have any Active Gigs"
                            viewAll={activeRecords ? redirectToActiveGigs : undefined}
                        ></GridTableEngineer>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid container className="table-heading-text">
                        <GridTableEngineer
                            className="active-gigs-grid"
                            headProp="Completed Gigs"
                            handleRClick={handleCompleteClick}
                            headers={headers}
                            hidePagination={true}
                            rows={completedGigs}
                            emptyMessage="You don’t have any Completed Gigs"
                            viewAll={completedRecords ? redirectToCompletedGigs : undefined}
                        ></GridTableEngineer>
                    </Grid>
                </Grid>
            </PaddedBox>
            <PaddedFooter style={{ paddingLeft: `${!!props.auth && !!props.auth.isDrawerOpen && props.auth.isDrawerOpen == true ? "200px" : "0px"}` }}>
                <Footer />
            </PaddedFooter>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        profile: state.profile
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        resetProfileSuccess: (details) => dispatch(resetProfileSuccess(details)),
        setProfileDetails: (details) => dispatch(setProfileDetails(details))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EngineerDashboard);


