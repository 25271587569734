import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Grid, CardContent, Avatar, Typography, Pagination, Link, Box, Chip } from '@mui/material';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _toNumber from 'lodash/toNumber';
import { PaddedBox, CustomRow, EditedCard, PaddedFooter } from '../../app.styles';
import { getAllEngineerList } from '../../../services/profile';
import PageLoader from '../../../components/Loaders/PageLoader';
import { getQueryParams, scrollOnTop } from '../../../utils/helpers';
import { getUserType } from '../../../utils/user';
import AccountDeactivate from '../../../components/AccountDeactivate/AccountDeactivate';
import Search from '../../../components/SearchBar/Search';
import { resetSFS } from '../../../store/actions/sfs';
import Filter from '../../../components/SearchBar/Filter';
import Sort from '../../../components/SearchBar/Sort';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import GridViewIcon from '@mui/icons-material/GridView';
import GTranslateOutlinedIcon from '@mui/icons-material/GTranslateOutlined';
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import Button from '@mui/material/Button';
import NavigateNextOutlinedIcon from '@mui/icons-material/NavigateNextOutlined';
import ChatIcon from '@mui/icons-material/Chat';
import TableView from '../../../assests/TableView.svg'
import '../../gigOwner/gigDashboard/GigDashboard.css';
import ReactGA from 'react-ga';
import GridTableAdmin from '../../../components/GridTable/GridTableAdmin';
ReactGA.initialize(`G-1596JC7760`);
import PopUpPercentage from '../../../components/PopUpPercentage/PopUpPercentage';
import Footer from '../../../components/footer1/footer';
ReactGA.pageview(window.location.pathname + window.location.search);
import VerifiedIcon from '../../../assests/VerifiedTick.svg';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import Skills from '../../../assests/Skills.png';
import './engineerProfile.scss';

const EngTite = ({ name, loc }) => {
  return (
    <>
      <Box sx={{ textAlign: 'center', paddingTop: '10px' }}>
        <Typography sx={{ fontSize: '18px', fontWeight: '500' }}>{name}</Typography>
        <Typography variant="body2" className="card-Styles" display="block" color="text.secondary">
          {loc}
        </Typography>
      </Box>
    </>
  );
};

const getArrayList = (arrayVal, key) => {
  return Array.prototype.map.call(arrayVal, (s) => ` ${s[key]}`).toString();
};

const EngineersList = (props) => {
  const history = useHistory();
  const userType = getUserType(); // get the user type from tokens
  const query = getQueryParams();
  const queryPage = query.page || 1; // active engineer page
  const sfsReset = useRef(true); // reset search filter sort on navigate to another page
  const filterApplied =
    !_isEmpty(props.searchKey) || !_isEmpty(props.filters) || !_isEmpty(props.sortBy);

  const [list, setList] = useState([]); // acitve engineer list returned from api
  const [page, setPage] = useState(_toNumber(queryPage)); // active enginner list page number
  const [count, setCount] = useState(1); // count of total engineer
  const [tableData, setTableData] = useState("tableView");
  const [paginationVal, setPaginationVal] = useState(1);
  const [engineerListData, setEngineerListData] = useState([]);
  const [openDashboard, setOpenDashboard] = useState(false);
  const percentage = _get(props, 'profile.profileCompletionPercent', 0);

  const modalOpen = () => {
    setOpenDashboard(true);
  }
  const setListDataCard = (data) => {
    setList(_get(data, 'engineerList', []));
    setPage(_get(data, 'paginationResult.currentPage', 1));
    setCount(_get(data, 'paginationResult.totalPages', 1));
  };

  const getMessageLink = (userId, obj) => {
    return (
      <div>
        {userType === 'business' && (
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <Button
              className="admin-all-btn"
              sx={{ color: "#FFFFFF", borderRadius: '5px', width: '127px', height: '32px', textTransform: 'capitalize', fontSize: '14px', backgroundColor: '#0543ab '}}
              onClick={() => {
                if (percentage < 60) {
                  modalOpen();
                } else {
                  history.push(`/engineer/details/${userId}`, obj);
                }
              }}
            >
              View Details
            </Button>
          </Box>
        )}
      </div>
    )
  }

  const setData = (data) => {
    const rowData = [];
    const engineerDataTable = _get(data, 'engineerList', []);
    const totalRecords = _get(data, 'paginationResult', {});
    if (engineerDataTable && engineerDataTable.length > 0) {
      for (let obj of engineerDataTable) {
        const engineerName = _get(obj, 'basicInfo.name', '');
        const availability = _get(obj, 'myProfile.availabilityHoursPerWeek', '') ? `${_get(obj, 'myProfile.availabilityHoursPerWeek', '')} Hrs/Week` : '-';
        const hourlyRate = _get(obj, 'myProfile.hourlyRate', '') ? `${_get(obj, 'myProfile.currency', 'R')} ${_get(obj, 'myProfile.hourlyRate', '')}/hr` : '-';
        const location = _get(obj, 'basicInfo.country', '-');
        const userId = _get(obj, 'basicInfo.userId', '');
        const id = _get(obj, '_id', '');
        rowData.push([engineerName, availability, hourlyRate, location, getMessageLink(userId, obj), { id }, userId, '', obj]);
      }
    }
    setEngineerListData(rowData);
    setPaginationVal(totalRecords.totalPages || 1);
  };

  const handleRClick = (data) => {
    if (percentage < 60) {
      modalOpen();
    } else {
      history.push(`/engineer/details/${data[6]}`);
    }
  };

  const fetchMoreData = (currentPage) => {
    const obj = {
      paginationInput: {
        pageNumber: currentPage,
        pageSize: 12
      },
      filter: {
        userStatusList: ['ACTIVE'],
        profileCompletionPercent: 60,
        isPrivate: true
      },
      ...props.sortBy
    };

    // admin page we need show the inactive users also
    if (userType === 'admin') {
      delete obj.filter.profileCompletionPercent;
      obj.filter.userStatusList.push('INACTIVE');
      delete obj.filter.isPrivate;
    }

    if (!_isEmpty(props.searchKey)) {
      obj.search = props.searchKey;
    }

    if (!_isEmpty(props.filters)) {
      obj.filter = {
        ...obj.filter,
        ...props.filters
      };
    }

    getAllEngineerList(obj, setData, setListDataCard);
  };

  useEffect(() => {
    if (percentage < 60) {
      window.addEventListener('scroll', () => {
        scrollOnTop();
        modalOpen();
      });
    } else {
      window.removeEventListener('scroll', () => {
        modalOpen();
      });
    }
  }, []);

  useEffect(() => {
    if (tableData === 'tableView') {
      fetchMoreData(filterApplied ? 1 : paginationVal);
    } else {
      fetchMoreData(filterApplied ? 1 : page);
    }
  }, [props.searchKey, props.filters, props.sortBy]);

  // click on pagination number
  const onPaginationClick = (event, value) => {
    scrollOnTop();
    fetchMoreData(value);
    setPage(value);
  };

  const onTableChange = (event, displayView) => {
    fetchMoreData(1);
    if (displayView === 'cardView') {
      setTableData('cardView');
      setPaginationVal(1);
    } else {
      setTableData('tableView');
      setPage(1);
    }
  };

  const handleChange = (event, view) => {
    scrollOnTop();
    fetchMoreData(view);
    setPaginationVal(view);
  };

  useEffect(() => {
    fetchMoreData(filterApplied ? 1 : page);
  }, [props.searchKey, props.filters, props.sortBy]);

  // navigation engineer details view page
  const engineerDetailsView = (engineerId) => {
    sfsReset.current = false;

    const redirectUrl = `${userType === 'admin' ? '/admin' : ''}/engineer/list?page=${page}`;
    history.push(
      `${userType === 'admin' ? '/admin' : ''
      }/engineer/details/${engineerId}?redirectUrl=${encodeURIComponent(redirectUrl)}`
    );
  };

  useEffect(() => {
    return () => {
      sfsReset.current && props.resetSFS('');
    };
  }, []);

  const headers = ['ENGINEER NAME', 'AVAILABILITY', 'HOURLY RATE', 'LOCATION', 'ACTIONS'];

  return (
    <>
      <PaddedBox style={{ paddingLeft: `${!!props.auth && !!props.auth.isDrawerOpen && props.auth.isDrawerOpen == true ? "240px" : "0px"}`, minHeight: "580px" }} secondary>
        <CustomRow container>
          <Typography
            className='font-fix'
            variant="h6"
            component="h6"
            color="pColor.main"
          >
            Browse Engineer Profiles
            {filterApplied && (
              <Chip
                label="Remove Filters"
                variant="outlined"
                sx={{
                  fontWeight: 'normal',
                  marginLeft: '10px'
                }}
                onDelete={() => {
                  props.resetSFS('');
                }}
              />
            )}
          </Typography>

          <Grid container item xs="auto" justifyContent="flex-end">
            <Search placeholder="Search by Engineering Discipline or Skills" />
            <Filter filterType="engineer" />
            <Sort sortType="engineer" />
            {(tableData === 'tableView') ?
              <Box sx={{ cursor: 'pointer', marginTop: '15px', marginRight: '7px' }}>
                <img sx={{ color: '#818181' }} src={TableView} alt="logotable" fontSize="small"
                  onClick={(e) => onTableChange(e, 'cardView')}
                /></Box>
              :
              <GridViewIcon onClick={(e) => onTableChange(e, 'tableView')} sx={{ fontSize: '32px', color: '#818181', cursor: 'pointer', marginTop: '12px' }} />
            }
          </Grid>
        </CustomRow>

        {_isEmpty(list) && filterApplied && (
          <Grid
            container
            item
            justifyContent="center"
            xs={12}
            className="h-margin-top-10 h-padding-top-16"
          >
            <Typography variant="h6" component="h6">
              No records found, Please refine your search
            </Typography>
          </Grid>
        )}

        {_isEmpty(list) && !filterApplied && (
          <Grid
            container
            item
            justifyContent="center"
            xs={12}
            className="h-margin-top-10 h-padding-top-16"
          >
            <PageLoader />
          </Grid>
        )}
        {(tableData === 'tableView') ?
          <PaddedBox style={{ paddingLeft: `0px`, marginTop: '-15px', width: "100%" }} secondary>
            <Grid container item className="section-Margin" spacing={4} xs={12} md={12} lg={12}>
              {list.map((job, index) => (
                <Grid item xs={12} sm={4} md={4} lg={4} key={index}>
                  <EditedCard sx={{ minHeight: '460px' }}>
                    <Box
                      sx={{ cursor: 'pointer' }}
                      onClick={(event) => {
                        if (percentage < 60) {
                          modalOpen();
                        } else {
                          // event.stopPropagation();
                          engineerDetailsView(_get(job, 'basicInfo.userId'));
                        }
                      }}
                    >
                      <Box sx={{ margin: 'auto' }}>
                        <Avatar
                          src={_get(job, 'myProfile.profilePic', '')}
                          sx={{
                            width: 80,
                            height: 80,
                            objectFit: 'cover',
                            margin: 'auto',
                            border: '3px solid #eee',
                            boxShadow: 'rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset',
                            backgroundColor: '#CCD8EE'
                          }}
                        >
                          {_get(job, 'basicInfo.name[0]', '')}
                        </Avatar>
                        <Typography
                          variant="h6"
                          component="h6"
                          color="pColor.main"
                          sx={{
                            fontWeight: 'Medium',
                            textTransform: 'capitalize',
                            textAlign: 'center',
                            marginTop: '15px',
                          }}
                        >
                          <Typography
                            title="Gig Name"
                            sx={{
                              textAlign: 'center',
                              fontSize: '16px',
                              fontWeight: '600',
                              lineHeight: '1.43'
                            }}
                          >
                            {`${_get(job, 'basicInfo.name', '')}`}
                            {
                              !!_get(job, 'isVerified') && (_get(job, 'profileCompletionPercent') == 100)
                              &&
                              <img className='verified-name-ico' src="https://gigengineer-production.s3.af-south-1.amazonaws.com/Assets/Blue+Tick+Verification.svg" />
                            }
                          </Typography>
                        </Typography>
                      </Box>
                    </Box>
                    <Box>
                      <CardContent className="card-Content">
                        <Typography
                          variant="subtitle1"
                          gutterBottom
                          sx={{
                            color: '#f99600',
                            textAlign: 'center',
                            fontSize: '16px',
                            fontWeight: '600',
                            marginTop: '5px'
                          }}
                        >
                          {_get(job, 'myProfile.title') ? _get(job, 'myProfile.title', '') : '-'}
                        </Typography>
                        <List>
                          <ListItem disablePadding title="Availability" sx={{ cursor: 'default' }}>
                            <ListItemIcon sx={{ minWidth: '20px', marginLeft: '-0px' }}>
                              <AssignmentTurnedInOutlinedIcon sx={{ fontSize: '19px', color: '#000' }} />
                            </ListItemIcon>
                            <ListItemText
                              primary={
                                <Typography
                                  sx={{
                                    fontSize: '16px',
                                    color: '#383838',
                                    overflow: 'hidden',
                                    marginLeft: '5px'
                                  }}
                                >
                                  {_get(job, 'myProfile.availabilityHoursPerWeek', '0')
                                    ? `${_get(job, 'myProfile.availabilityHoursPerWeek', '0')} hrs/week`
                                    : 'Not Disclosed'}
                                </Typography>
                              }
                            />
                          </ListItem>
                          <ListItem disablePadding title="Hourly Rate" sx={{ cursor: 'default', marginTop: '5px' }}>
                            <ListItemIcon sx={{ minWidth: '25px', marginLeft: '-0px', marginTop: '5px' }}>
                              <span
                                enterTouchDelay={0}
                              >
                                <AccessTimeIcon sx={{ fontSize: '19px', color: '#000' }} />
                              </span>
                            </ListItemIcon>
                            <ListItemText
                              primary={
                                <Typography
                                  sx={{
                                    fontSize: '16px',
                                    color: '#383838',
                                    overflow: 'hidden',
                                    marginLeft: '3px'
                                  }}
                                >
                                  {_get(job, 'myProfile.hourlyRate', '0')
                                    ? `${_get(job, 'myProfile.currency', 'R')} ${_get(
                                      job,
                                      'myProfile.hourlyRate',
                                      '0'
                                    )}/hr`
                                    : 'Not Disclosed'}
                                </Typography>
                              }
                            />
                          </ListItem>
                          <ListItem disablePadding title="Location" sx={{ cursor: 'default', marginTop: '5px' }}>
                            <ListItemIcon sx={{ minWidth: '25px', marginLeft: '-3px', marginTop: '5px' }}>
                              <span
                                enterTouchDelay={0}
                              >
                                <PlaceOutlinedIcon sx={{ fontSize: '23px', color: '#000' }} />
                              </span>
                            </ListItemIcon>
                            <ListItemText
                              primary={
                                <Typography
                                  sx={{
                                    fontSize: '16px',
                                    color: '#383838',
                                    overflow: 'hidden',
                                    marginLeft: '3px'
                                  }}
                                >
                                  {_get(job, 'basicInfo.city') ? ` ${_get(job, 'basicInfo.city')}, ${_get(job, 'basicInfo.country')}` : 'Not Disclosed'}
                                </Typography>
                              }
                            />
                          </ListItem>
                          <ListItem disablePadding title="Skills" sx={{ cursor: 'default', marginTop: '5px' }}>
                            <ListItemIcon sx={{ minWidth: '25px', marginTop: '5px' }}>
                              <span
                                enterTouchDelay={0}
                              >
                                <img sx={{ color: '#0000FF' }} src={Skills} alt="skills" width="19px" />
                              </span>
                            </ListItemIcon>
                            <ListItemText
                              sx={{ fontSize: '12px' }}
                              primary={
                                <Typography
                                  sx={{
                                    fontSize: '16px',
                                    color: '#383838',
                                    overflow: 'hidden'
                                  }}
                                >
                                  {getArrayList(_get(job, 'skillList', []), 'skillName') ? getArrayList(_get(job, 'skillList', []), 'skillName') : 'Not Disclosed'}
                                </Typography>
                              }
                            />
                          </ListItem>
                          <ListItem disablePadding title="Language" sx={{ cursor: 'default', marginTop: '5px' }}>
                            <ListItemIcon sx={{ minWidth: '20px' }}>
                              <GTranslateOutlinedIcon sx={{ fontSize: '18px', color: '#000' }} />
                            </ListItemIcon>
                            <ListItemText
                              primary={
                                <Typography
                                  sx={{
                                    fontSize: '16px',
                                    color: '#383838',
                                    overflow: 'hidden',
                                    marginLeft: '4px'
                                  }}
                                >
                                  {getArrayList(_get(job, 'languageList', []), 'language') ? getArrayList(_get(job, 'languageList', []), 'language') : 'Not Disclosed'}
                                </Typography>
                              }
                            />
                          </ListItem>
                        </List>
                      </CardContent>
                    </Box>

                    {userType === 'admin' && (
                      <Box
                        className="card-Action"
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          marginTop: '15px'
                        }}
                      >
                        <AccountDeactivate btnType="primary" profileDet={job} />
                      </Box>
                    )}
                    {userType !== 'admin' && (
                      <Box
                        className="card-Action"
                        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '16px', marginTop: '10px' }}
                      >
                        <Button
                          sx={{ color: '#0140AA', fontSize: '16px' }}
                          onClick={(event) => {
                            if (percentage < 60) {
                              modalOpen();
                            } else {
                              event.stopPropagation();
                              engineerDetailsView(_get(job, 'basicInfo.userId'));
                            }
                          }}
                        >
                          View Details
                          <NavigateNextOutlinedIcon />
                        </Button>
                      </Box>
                    )}
                    {userType === 'business' && (
                      <Box
                        sx={{ position: 'absolute', top: '10px', right: '20px' }}
                      >
                        <Link
                          title="Chat"
                          className="card-Link"
                          component="button"
                          variant="body2"
                          onClick={(event) => {
                            if (percentage < 60) {
                              modalOpen();
                            } else {
                              event.stopPropagation();
                              history.push(`/ochat?to=${_get(job, 'basicInfo.userId', '')}`);
                            }
                          }}
                        >
                          <ChatIcon fontSize="large" />
                        </Link>
                      </Box>
                    )}
                  </EditedCard>
                </Grid>
              ))}
            </Grid>

            {!_isEmpty(list) && (
              <Box sx={{ padding: '30px 0 10px 0' }}>
                <Pagination
                  page={page}
                  count={count}
                  shape="rounded"
                  className="pagination-root"
                  onChange={onPaginationClick}
                  defaultPage={page}
                />
              </Box>
            )}
          </PaddedBox>
          :
          <Grid container className="table-Padding" mt={2}>
            <GridTableAdmin
              headers={headers}
              handleRClick={handleRClick}
              rows={engineerListData}
              handleChange={handleChange}
              pagination={paginationVal}
              defaultPage={_toNumber(paginationVal)}
            ></GridTableAdmin>
          </Grid>
        }
        {openDashboard ? <PopUpPercentage modalOpen={openDashboard} modalClose={(e) => { setOpenDashboard(false) }} /> : <></>}
      </PaddedBox >
      <PaddedFooter style={{ paddingLeft: `${!!props.auth && !!props.auth.isDrawerOpen && props.auth.isDrawerOpen == true ? "200px" : "0px"}` }}>
        <Footer />
      </PaddedFooter>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    searchKey: state.sfs.searchKey,
    filters: state.sfs.filters,
    sortBy: state.sfs.sortBy,
    newGig: state.newGig,
    error: state.newGig.error,
    saveSuccess: state.newGig.saveSuccess,
    representative: state.profile.basicInfo || {},
    profile: state.profile
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    resetSFS: (details) => dispatch(resetSFS(details)),
    setNewGigStep: (details) => dispatch(setNewGigStep(details)),
    setNewGigSuccess: (details) => dispatch(setNewGigSuccess(details)),
    setNewGigError: (details) => dispatch(setNewGigError(details)),
    setProfileError: (details) => dispatch(setProfileError(details)),
    setProfileSuccess: (details) => dispatch(setProfileSuccess(details)),
    resetProfileSuccess: (details) => dispatch(resetProfileSuccess(details))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EngineersList);
