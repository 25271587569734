import _get from 'lodash/get';
import { toast } from 'react-toastify';
import apiService from './apiServices';

export const getDraftGigByIdApi = async (id, setNewGigDetails) => {
  const request = {
    method: 'get',
    url: `/gig/${id}`
  };
  const response = await apiService(request);
  if (response && response.status === 200) {
    // sucess action
    setNewGigDetails(_get(response, 'data.gig', {}));
  }
};

export const saveNewGigApi = async (data, setNewGigError, setNewGigSuccess) => {
  const request = {
    data,
    method: 'post',
    url: '/gig/'
  };
  const response = await apiService(request);
  if (response && response.status === 200) {
    // sucess action what to do
    //setNewGigSuccess();
    toast.success('Gig Posted Sucessfully');
    setNewGigSuccess({ newGigSaveSucess: true });
  } else {
    // show global error message via props
    setNewGigError(response);
  }
};

export const saveNewDraftGigApi = async (data, setNewGigError, setNewGigSuccess) => {
  const request = {
    data,
    method: 'post',
    url: '/gig/'
  };
  const response = await apiService(request);
  if (response && response.status === 200) {
    // sucess
    toast.success('Draft Saved Sucessfully');
    setNewGigSuccess(_get(response, 'data.gig', {}));
  } else {
    // show global error message via props
    setNewGigError(response);
  }
};

export const getDraftGigApi = async (data, setDraftGigList) => {
  const request = {
    data: {
      ...data,
      status: ['DRAFT']
    },
    method: 'post',
    url: '/gig/all'
  };
  const response = await apiService(request);
  if (response && response.status === 200) {
    // sucess action
    setDraftGigList(_get(response, 'data', {}));
  }
};

export const getGigs = async (data, setGigsList) => {
  const request = {
    data,
    method: 'post',
    url: '/gig/all'
  };
  const response = await apiService(request);
  if (response && response.status === 200) {
    // sucess action
    setGigsList(_get(response, 'data', {}));
  }
};

export const getPastGigs = async (data, setGigsList) => {
  const request = {
    data,
    method: 'post',
    url: '/gig/all'
  };
  const response = await apiService(request);
  if (response && response.status === 200) {
    // sucess action
    setGigsList(_get(response, 'data', {}));
  }
};

export const getRehireGigsData = async (data, setGigsList) => {
  const request = {
    data,
    method: 'post',
    url: '/hire/rehire/all'
  };
  const response = await apiService(request);
  if (response && response.status === 200) {
    // sucess action
    setGigsList(_get(response, 'data', {}));
  }
};

export const getCurrentGig = async (data, setGigsList) => {
  const request = {
    data,
    method: 'post',
    url: '/hire/currentHireDetails'
  };
  const response = await apiService(request);
  if (response && response.status === 200) {
    // sucess action
    if (!!setGigsList) {
      setGigsList(_get(response, 'data', {}));
    }
    else {
      return response
    }
  }
};

export const getPastGig = async (data, setGigsList) => {
  const request = {
    data,
    method: 'post',
    url: '/hire/pastHireDetails'
  };
  const response = await apiService(request);
  if (response && response.status === 200) {
    // sucess action
    setGigsList(_get(response, 'data', {}));
  }
};

export const engineerProfileGigs = async (data, setGigsList, setListData) => {
  const request = {
    data,
    method: 'post',
    url: '/opportunity/discoverEngineers'
  };
  const response = await apiService(request);
  if (response && response.status === 200) {
    // sucess action
    setGigsList(_get(response, 'data', {}));
    setListData(_get(response, 'data', {}));
  }
};

export const engineerProfileGig = async (data, setGigsList) => {
  const request = {
    data,
    method: 'post',
    url: '/opportunity/discoverEngineers'
  };
  const response = await apiService(request);
  if (response && response.status === 200) {
    // sucess action
    setGigsList(_get(response, 'data', {}));
  }
};

export const getGigsById = async (id) => {
  const request = {
    method: 'get',
    url: `/gig/${id}`
  };
  const response = await apiService(request);
  if (response && response.status === 200) {
    // sucess action
    return _get(response, 'data', {});
  }
};

export const getGigApplicants = async (data, setGigsList) => {
  const request = {
    data: {
      ...data,
      applicationStatusList: ['APPLIED']
    },
    method: 'post',
    url: '/gig/applicants'
  };
  const response = await apiService(request);
  if (response && response.status === 200) {
    // sucess action
    setGigsList(_get(response, 'data', {}));
  }
};

export const getGigAllApplicants = async (data, setGigsList) => {
  const request = {
    data: {
      ...data,
      applicationStatusList: ['APPLIED']
    },
    method: 'post',
    url: '/gig/allApplicants'
  };
  const response = await apiService(request);
  if (response && response.status === 200) {
    // sucess action
    setGigsList(_get(response, 'data', {}));
  }
};


export const updateGigStatus = async (data, setNewGigError, setNewGigSuccess) => {
  const request = {
    data,
    method: 'post',
    url: '/gig/'
  };
  const response = await apiService(request);
  if (response && response.status === 200) {
    // sucess action what to do
    //setNewGigSuccess();
    setNewGigSuccess({ newGigSaveSucess: true });
  } else {
    // show global error message via props
    setNewGigError(response);
  }
};

export const deleteGigApi = async (data, gigId) => {
  const request = {
    data,
    method: 'post',
    url: '/gig/delete'
  };
  const response = await apiService(request);
  if (response && response.status === 200) {
    // sucess action what to do
    //setNewGigSuccess();
  } else {
    // show global error message via props
  }
};

export const getAllFeedbackApi = async (data) => {
  const request = {
      data,
      method: 'post',
      url: '/referrals/all'
  };
  const response = await apiService(request);
  if (response && response.status === 200) {
    return response
  } else {
      console.log(response)
  }
};

export const getFeedbackDetail = async (data, id) => {
  const request = {
      data,
      method: 'get',
      url: `/referrals/${id}`
  };
  const response = await apiService(request);
  if (response && response.status === 200) {
    return response
  } else {
      console.log(response)
  }
};

export const addNewFeedbackApi = async (data) => {
  const request = {
      data,
      method: 'post',
      url: '/referrals/'
  };
  const response = await apiService(request);
  if (response && response.status === 200) {
      return response
  } else {
      console.error(response)
      return response
  }
};

export const addManualFeedbackApi = async (data) => {
  const request = {
      data,
      method: 'post',
      url: '/profile/engineer'
  };
  const response = await apiService(request);
  if (response && response.status === 200) {
    return response
  } else {
      console.log(response)
  }
};

export const verifyManualFeedbackApi = async (data) => {
  const request = {
      data,
      method: 'post',
      url: '/profile/verify-reviews'
  };
  const response = await apiService(request);
  if (response && response.status === 200) {
    return response
  } else {
      console.log(response)
  }
};

export const postUserCollection = async (data) => {
  const request = {
      data,
      method: 'post',
      url: '/usercollection'
  };
  const response = await apiService(request);
  if (response && response.status === 200) {
    return response
  } else {
      console.log(response)
  }
};

export const withdrawApplicaionGig = async (data) => {
  const request = {
      data,
      method: 'post',
      url: '/opportunity/withdrawApplications'
  };
  const response = await apiService(request);
  if (response && response.status === 200) {
    return response
  } else {
      console.log(response)
  }
};

export const profilePrivateEngineer = async (data) => {
  const request = {
      data,
      method: 'post',
      url: '/profile/private-engineer'
  };
  const response = await apiService(request);
  if (response && response.status === 200) {
    return response
  } else {
      console.log(response)
  }
};

export const QueryContactUs = async (data) => {
  const request = {
      data,
      method: 'post',
      url: '/query/contactus'
  };
  const response = await apiService(request);
  if (response && response.status === 200) {
    return response
  } else {
      console.log(response)
  }
};

export const deleteGigdraft = async (data) => {
  const request = {
      data,
      method: 'post',
      url: '/gig/delete'
  };
  const response = await apiService(request);
  if (response && response.status === 200) {
    return response
  } else {
      console.log(response)
  }
};

export const hireUnHire = async (data) => {
  const request = {
      data,
      method: 'post',
      url: '/hire/unhire'
  };
  const response = await apiService(request);
  if (response && response.status === 200) {
    return response
  } else {
      console.log(response)
  }
};

export const hireRehire = async (data) => {
  const request = {
      data,
      method: 'post',
      url: '/hire/rehire'
  };
  const response = await apiService(request);
  if (response && response.status === 200) {
    return response
  } else {
      console.log(response)
  }
};

export const feedBackApiApplicant = async (data) => {
  const request = {
      data,
      method: 'post',
      url: '/feedback'
  };
  const response = await apiService(request);
  if (response && response.status === 200) {
    return response
  } else {
      console.log(response)
  }
};

export const cancelFeedBackApi = async (data) => {
  const request = {
      data,
      method: 'post',
      url: '/feedback'
  };
  const response = await apiService(request);
  if (response && response.status === 200) {
    return response
  } else {
      console.log(response)
  }
};

export const hireApplicant = async (data) => {
  const request = {
      data,
      method: 'post',
      url: '/hire'
  };
  const response = await apiService(request);
  if (response && response.status === 200) {
    return response
  } else {
      console.log(response)
  }
};

export const opportunitywithdrawApplications = async (data) => {
  const request = {
      data,
      method: 'post',
      url: '/opportunity/withdrawApplications'
  };
  const response = await apiService(request);
  if (response && response.status === 200) {
    return response
  } else {
      console.log(response)
  }
};

export const profileFeedBack = async (data) => {
  const request = {
      data,
      method: 'post',
      url: '/feedback'
  };
  const response = await apiService(request);
  if (response && response.status === 200) {
    return response
  } else {
      console.log(response)
  }
};

export const cancelProfileFeedBack = async (data) => {
  const request = {
      data,
      method: 'post',
      url: '/feedback'
  };
  const response = await apiService(request);
  if (response && response.status === 200) {
    return response
  } else {
      console.log(response)
  }
};

export const queryrequestquery = async (data) => {
  const request = {
      data,
      method: 'post',
      url: '/query/requestquery'
  };
  const response = await apiService(request);
  if (response && response.status === 200) {
    return response
  } else {
      console.log(response)
  }
};

export const gigDetailId = async (id) => {
  const request = {
      method: 'get',
      url: `/gig/${id}`
  };
  const response = await apiService(request);
  if (response && response.status === 200) {
    return response
  } else {
      console.log(response)
  }
};

export const deleteRecord = async (data) => {
  const request = {
      data,
      method: 'post',
      url: '/profile/engineer'
  };
  const response = await apiService(request);
  if (response && response.status === 200) {
    return response
  } else {
    return response
  }
};
