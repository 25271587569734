

import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Grid,
  CardHeader,
  CardContent,
  Avatar,
  Typography,
  Pagination,
  Box,
  Chip,
  Zoom,
  IconButton,
  Link,
  CircularProgress,
} from '@mui/material';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _toNumber from 'lodash/toNumber';
import { PaddedBox, CustomRow, EditedCard, PaddedFooter, NolongerApctContainer } from '../../app.styles';
import { formatDate, getGigLocation, getQueryParams, scrollOnTop, getDurationLabel } from '../../../utils/helpers';
import { getUserType } from '../../../utils/user';
import { resetSFS } from '../../../store/actions/sfs';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import './Discover.css'
import _find from 'lodash/find';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Filter from '../../../components/SearchBar/Filter';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import Button from '@mui/material/Button';
import GridViewIcon from '@mui/icons-material/GridView';
import ReactGA from "react-ga";
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import { getAllAdminList } from '../../../services/profile';
import PageLoader from '../../../components/Loaders/PageLoader';
import Search from '../../../components/SearchBar/Search';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import Sort from '../../../components/SearchBar/Sort';
import logo from "../../../assests/TableView.svg"
import Footer from '../../../components/footer1/footer';
import StarIcon from '@mui/icons-material/Star';
import { applyToGigApi } from '../../../services/engineerGig';
import PopUpPercentage from '../../../components/PopUpPercentage/PopUpPercentage';
import { updateFavorite } from "../../../services/favorites"
import GridTableEngineer from '../../../components/GridTable/GridTableEngineer';
import Hours from "../../../assests/Hours.svg";
import Days from "../../../assests/Days.svg";
import Weeks from "../../../assests/Weeks.svg";
import Months from "../../../assests/Months.svg";
import Skills from "../../../assests/Skills.png";
import { color } from '@mui/system';
ReactGA.initialize(`G-1596JC7760`);
ReactGA.pageview(window.location.pathname + window.location.search);

const Discover = (props) => {


  const history = useHistory();
  const userType = getUserType(); // get the user type from tokens
  const query = getQueryParams();
  const queryPage = query.page || 1; // active engineer page

  const [disableApply, setDisableApply] = useState(0);
  const [tableData, setTableData] = useState("tableView")
  const sfsReset = useRef(true); // reset search filter sort on navigate to another page
  const filterApplied =
    !_isEmpty(props.searchKey) || !_isEmpty(props.filters) || !_isEmpty(props.sortBy);
  const [paginationVal, setPaginationVal] = useState(1);
  const [list, setList] = useState([]); // acitve engineer list returned from api
  const [page, setPage] = useState(_toNumber(queryPage)); // active enginner list page number
  const [count, setCount] = useState(1); // count of total engineer
  const [engineerProfile, setEngineerProfile] = useState([]);
  const [openDashboard, setOpenDashboard] = useState(false);
  const percentage = _get(props, 'profile.profileCompletionPercent', 0);



  const modalOpenProfile = () => {
    setOpenDashboard(true)
  }

  const setListData = (data) => {
    setList(_get(data, 'gigList', []));
    setPage(_get(data, 'paginationResult.currentPage', 1));
    setCount(_get(data, 'paginationResult.totalPages', 1));
  };

  const getMessageLink = (id, obj) => {
    return (
      <div>
        {userType === 'engineer' && (
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <Button
              className="admin-all-btn"
              sx={{ color: "#FFFFFF", borderRadius: '5px', width: '127px', height: '32px', textTransform: 'capitalize' }}
              onClick={() => {

                history.push(`/discover/${_get(obj, '_id', '')}`, obj);
              }}
            >
              View Details
            </Button>
          </Box>
        )}

      </div>
    )
  }

  const setListDataEngineer = (data) => {
    const rowData = [];
    const engineerListData = _get(data, 'gigList', []);
    const totalRecords = _get(data, 'paginationResult', {});
    if (engineerListData && engineerListData.length > 0) {
      for (let obj of engineerListData) {
        const gigName = _get(obj, 'titleAndSkills.projectTitle', '') ? _get(obj, 'titleAndSkills.projectTitle', '') : '-';
        const postOn = formatDate(_get(obj, 'createdAt', '-') ? _get(obj, 'createdAt', '-') : '-');
        const gigId = _get(obj, 'applicationDetails.gigId', '');
        const location = _get(obj, 'deliverablesAndLocation.location.country', '-') ? _get(obj, 'deliverablesAndLocation.location.country', '-') : '-';
        const postBy = _get(obj, 'companyDetails.companyName', '') ? _get(obj, 'companyDetails.companyName', '') : '-';
        const userId = _get(obj, 'userId', '');
        const id = _get(obj, '_id', '');
        rowData.push([gigName, postOn, location, postBy, getMessageLink(id, obj), { id, name }, obj, gigId, userId]);
      }
    }
    setEngineerProfile(rowData);
    setPaginationVal(totalRecords.totalPages || 1);
  };



  const fetchMoreData = (currentPage) => {
    const obj = {
      paginationInput: {
        pageNumber: currentPage,
        pageSize: 12
      },
      filter: {
        gigStatusList: ['ACTIVE'],
        profileCompletionPercent: 60
      },
      ...props.sortBy
    };

    // admin page we need show the inactive users also
    if (userType === 'admin') {
      obj.filter.gigStatusList.push('INACTIVE');
      obj.filter.gigStatusList.push('CLOSED');
    }

    if (!_isEmpty(props.searchKey)) {
      obj.search = props.searchKey;
    }

    if (!_isEmpty(props.filters)) {
      obj.filter = {
        ...obj.filter,
        ...props.filters
      };
    }

    getAllAdminList(obj, setListData, setListDataEngineer);
  };

  useEffect(() => {
    if (percentage < 60) {
      scrollOnTop();
      modalOpenProfile();
      // window.addEventListener('scroll', () => {
      // });
    }
    // else {
    //   window.removeEventListener('scroll', () => {
    //     modalOpenProfile();
    //   });
    // }
  }, []);

  useEffect(() => {
    if (tableData === 'tableView') {
      fetchMoreData(filterApplied ? 1 : page);
    } else {
      fetchMoreData(filterApplied ? 1 : paginationVal);
    }
  }, [props.searchKey, props.filters, props.sortBy]);

  // click on pagination number
  const onPaginationClick = (event, value) => {
    scrollOnTop();
    fetchMoreData(value);
    setPage(value);
  };



  const onTableChange = (event, displayView) => {
    fetchMoreData(1);
    if (displayView === 'cardView') {
      setTableData('cardView');
      setPaginationVal(1);
    } else {
      setTableData('tableView');
      setPage(1);
    }
  };

  const handleChange = (event, View) => {
    scrollOnTop();
    fetchMoreData(View);
    setPaginationVal(View);
  };

  useEffect(() => {
    fetchMoreData(filterApplied ? 1 : page);
  }, [props.searchKey, props.filters, props.sortBy]);


  const getSkillsList = (skills) => {
    return Array.prototype.map.call(skills, (s) => ` ${s.engineerSkill}`).toString();
  };



  const changeFavoriteIcon = (res, data) => {
    const selected = _find(list, { _id: data.gigId });
    selected.isFavourite = !selected.isFavourite;
    setList([...list]);
  };

  const updateFavoriteStatus = (job) => {
    const obj = {
      gigId: _get(job, '_id', ''),
      isFavourite: !_get(job, 'isFavourite', '')
    };
    updateFavorite(obj, changeFavoriteIcon);
  };

  const applyToGig = async (id, index, title) => {
    setDisableApply(index + 1);
    const obj = {
      gigId: id
    };
    const gigApply = await applyToGigApi(obj);

    if (gigApply.status == 200) {
      socket.emit('postNotification', {
        "notificationDescription": `You have successfully applied for ${title}`,
        "userRole": ["engineer"],
        "engineerId": props.profile.basicInfo.userId,
        "redirectUrl": "/applied",
        "isRead": false,
        "engineerProfilePic": props.profile.myProfile.profilePic,
      });
    }
    list[index] = gigApply.data;
    setList([...list]);
    setDisableApply(0);
  };


  useEffect(() => {
    return () => {
      sfsReset.current && props.resetSFS('');
    };
  }, []);

  const headers = ['Gig Name', 'Posted on', 'Location', 'Posted By', 'Action'];
  const handleRClick = (obj) => {

    if (percentage < 60) {
      modalOpenProfile();
    } else {
      history.push(`/discover/${_get(obj[6], '_id', ' ')}`, obj[6]);
    }
  };


  return (
    <>
      <PaddedBox style={{ paddingLeft: `${!!props.auth && !!props.auth.isDrawerOpen && props.auth.isDrawerOpen == true ? "240px" : "0px"}`, minHeight: "580px" }} secondary>
        <CustomRow container>
          <Typography
            variant="h6"
            component="h6"
            color="pColor.main"
            sx={{ fontWeight: 'Medium', textTransform: 'capitalize', fontSize: '22px' }}
          >
            Discover Gigs
            {filterApplied && (
              <Chip
                label="Remove Filters"
                variant="outlined"
                sx={{
                  fontWeight: 'normal',
                  marginLeft: '10px'
                }}
                onDelete={() => {
                  props.resetSFS('');
                }}
              />
            )}
          </Typography>
          <div className='search-sort-tiles'>
            {/* <div className='search'> */}
            <Grid container item xs="auto" justifyContent="flex-end" >
              <Search placeholder="Search by date, job title or job description" />
              {/* </div>
            <div> */}
              <Filter filterType="gig" />
              <Sort sortType="engineer" />
              {/* </div> */}
            </Grid>
            <div>
              {
                (tableData === 'tableView') ?
                  <Box sx={{ cursor: 'pointer', marginTop: '15px' }}>
                    <img sx={{ color: '#818181' }} src={logo} alt="logotable" fontSize="small"
                      onClick={(e) => onTableChange(e, 'cardView')}
                    /></Box>
                  :
                  <GridViewIcon onClick={(e) => onTableChange(e, 'tableView')} sx={{ fontSize: '32px', color: '#818181', cursor: 'pointer', marginTop: '11px' }} />
              }
            </div>
          </div>
        </CustomRow >

        {_isEmpty(list) && filterApplied && (
          <Grid
            container
            item
            justifyContent="center"
            xs={12}
            className="h-margin-top-10 h-padding-top-16"
          >
            <Typography variant="h6" component="h6">
              No records found, Please refine your search
            </Typography>
          </Grid>
        )
        }
        {
          (tableData === 'tableView') ?
            <>

              <Grid container item className="section-Margin" spacing={4} xs={12} md={12} lg={12}>
                {_isEmpty(list) && (
                  <Grid
                    container
                    item
                    justifyContent="center"
                    xs={12}
                    className="no-record-text-table"
                  >
                    You don’t have any Discover Gigs
                  </Grid>
                )}
                {list.map((job, index) => (
                  <Grid item xs={12} sm={6} md={4} lg={4} key={index}>
                    <EditedCard sx={{ minHeight: '480px' }}>
                      <CardHeader
                        sx={{ position: "absolute", top: "3", right: "0", marginRight: '20px' }}
                        action={
                          <span
                            title={`${_get(job, 'isFavourite', false)
                              ? 'This will Remove from saved gigs'
                              : 'This will be added to your saved gigs.'
                              }`}
                            arrow
                            TransitionComponent={Zoom}
                            enterTouchDelay={0}
                          >
                            <IconButton
                              aria-label="favorite"
                              onClick={() => {
                                if (percentage < 60) {
                                  modalOpen();
                                } else {
                                  updateFavoriteStatus(job);
                                }
                              }}
                            >
                              {_get(job, 'isFavourite', false) ? (
                                <StarIcon sx={{ fontSize: '20px', color: '#f99600' }} />
                              ) : (
                                <StarOutlineIcon sx={{ fontSize: '22px' }} />
                              )}
                            </IconButton>
                          </span>
                        }
                      />
                      <Box sx={{ cursor: 'pointer', margin: 'auto' }}>
                        <Link onClick={() => {
                          if (percentage < 60) {
                            modalOpen();
                          } else {
                            history.push(`/discover/${_get(job, '_id', '')}`, job);
                          }
                        }}>
                          <Avatar
                            src={_get(job, 'companyDetails.logo', '')}
                            sx={{
                              width: 100,
                              height: 100,
                              objectFit: 'cover',
                              margin: 'auto',
                              border: '3px solid #eee',
                              boxShadow: 'rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset',
                              backgroundColor: '#CCD8EE',
                              // marginTop: '20px'
                            }}
                          >
                            {_get(job, 'companyDetails.companyName[0]', '')}
                          </Avatar>
                        </Link>
                      </Box>
                      <div className='project-title-discover-gig' title="Project Title">

                        {_get(job, 'titleAndSkills.projectTitle', '') ? _get(job, 'titleAndSkills.projectTitle', '') : "-"}
                      </div>

                      <CardContent className="card-Content">
                        <Box sx={{ cursor: 'pointer', textAlign: 'center' }}>
                          <Link onClick={() => {
                            if (percentage < 60) {
                              modalOpen();
                            } else {
                              history.push(`/discover/${_get(job, '_id', '')}`, job);
                            }
                          }}>
                            <Typography variant="h6"
                              component="h6"
                              color="pColor.main"
                              sx={{ fontSize: '16px', marginTop: '-10px', marginLeft: '10px' }}>More Details</Typography>
                          </Link>
                        </Box>
                        <List>
                          <ListItem disablePadding sx={{ cursor: 'default' }} title="Skills">
                            <ListItemIcon sx={{ minWidth: '25px', marginLeft: '2px', marginTop: '5px' }}>
                              <span
                                enterTouchDelay={0}
                              >
                                <img sx={{ color: '#0000FF' }} src={Skills} alt="skills" width="19px" />
                              </span>
                            </ListItemIcon>
                            <ListItemText
                              primary={
                                <Typography
                                  sx={{
                                    fontSize: '16px',
                                    color: '#383838',
                                    overflow: 'hidden'
                                  }}
                                >
                                  {getSkillsList(_get(job, 'titleAndSkills.skillsRequired', []))}
                                </Typography>
                              }
                            />
                          </ListItem>
                          <ListItem disablePadding sx={{ marginTop: '8px', cursor: 'default' }} title="Location">
                            <ListItemIcon sx={{ minWidth: '25px', marginLeft: '-1px', marginTop: '5px' }}>
                              <span
                                enterTouchDelay={0}
                              >
                                <PlaceOutlinedIcon sx={{ fontSize: '23px', color: '#000' }} />
                              </span>
                            </ListItemIcon>
                            <ListItemText
                              primary={
                                <Typography
                                  sx={{
                                    fontSize: '16px',
                                    color: '#383838',
                                    overflow: 'hidden',
                                    marginLeft: '2px'
                                  }}
                                >
                                  {getGigLocation(job.deliverablesAndLocation, job.isRemote)}
                                  {/* {_get(job, 'deliverablesAndLocation.location.city')}, {_get(job, 'deliverablesAndLocation.location.country')} */}
                                </Typography>
                              }
                            />
                          </ListItem>

                          <ListItem disablePadding sx={{ marginTop: '8px', cursor: 'default' }} title="Duration">
                            <ListItemIcon sx={{ minWidth: '25px', marginLeft: '2px', marginTop: '5px' }}>
                              <span
                                enterTouchDelay={0}
                              >
                                {(_get(job, 'scopeAndBudget.durationUnit')) === "PERHOUR" ? <img sx={{ color: '#0000FF' }} src={Hours} alt="hours" fontSize="medium" /> : (_get(job, 'scopeAndBudget.durationUnit')) === "PERDAY" ? <img sx={{ color: '#0000FF' }} src={Days} alt="days" fontSize="medium" /> : (_get(job, 'scopeAndBudget.durationUnit')) === "PERWEEK" ? <img sx={{ color: '#0000FF' }} src={Weeks} alt="weeks" fontSize="medium" /> : (_get(job, 'scopeAndBudget.durationUnit')) === "PERMONTH" ? <img sx={{ color: '#0000FF' }} src={Months} alt="months" fontSize="medium" /> : <img sx={{ color: '#0000FF' }} src={Hours} alt="hours" fontSize="medium" />}
                              </span>
                            </ListItemIcon>
                            <ListItemText
                              primary={
                                <Typography
                                  sx={{
                                    fontSize: '16px',
                                    color: '#383838',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden'
                                  }}
                                >
                                  {_get(job, 'scopeAndBudget.projectDuration', '-')} {getDurationLabel(_get(job, 'scopeAndBudget.durationUnit', 'Hours'))}
                                </Typography>
                              }
                            />
                          </ListItem>

                          <ListItem disablePadding sx={{ marginTop: '8px', cursor: 'default' }} title="Rate">
                            <ListItemIcon sx={{ minWidth: '25px', marginLeft: '2px', marginTop: '5px' }}>
                              <span
                                enterTouchDelay={0}
                              >
                                <AccessTimeIcon sx={{ fontSize: '20px', color: '#000' }} />
                              </span>

                            </ListItemIcon>
                            <ListItemText
                              primary={
                                <Typography
                                  sx={{
                                    fontSize: '16px',
                                    color: '#383838',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                  }}
                                >
                                  {_get(job, 'scopeAndBudget.hourlyRate')
                                    ? ` ${_get(job, 'scopeAndBudget.currency', 'R')} ${_get(job, 'scopeAndBudget.hourlyRate')}/hr `
                                    : 'Not Disclosed'}
                                </Typography>
                              }
                            />
                          </ListItem>

                        </List>

                        <Grid container className="card-Padding" justifyContent="right"
                        >
                          <Grid
                            container
                            item
                            xs={12}
                            md={12}
                            lg={12}
                            mt={2}
                            justifyContent="center"
                            alignItems="center"
                          >
                            {(_get(job, 'hireCount') === 0) && <Button
                              variant="contained"
                              className={`${_get(job, 'applicationDetails.status', '') === 'APPLIED' ? 'success primaryMainBtnDisable' : 'primary-Main-Btn'
                                }`}
                              disabled={
                                disableApply == index + 1 ||
                                _get(job, 'applicationDetails.status', '') === 'APPLIED'
                              }
                              onClick={() => {
                                if (percentage < 60) {
                                  modalOpenProfile();
                                } else {
                                  _get(job, 'applicationDetails.status', '') !== 'APPLIED' &&
                                    applyToGig(_get(job, '_id'), index, _get(job, 'titleAndSkills.projectTitle'));
                                }
                              }}
                              sx={{ width: '140px' }}
                            >
                              {disableApply ?
                                disableApply == index + 1 && <CircularProgress color='inherit' size='1.3rem' />
                                :
                                `${_get(job, 'applicationDetails.status', '') === 'APPLIED'
                                  ? 'Applied' : 'Apply Now'}`
                              }
                            </Button>}

                            {(_get(job, 'hireCount') >= 1) &&
                              <NolongerApctContainer>
                                <div className='all-contents-text'>
                                  <ReportGmailerrorredIcon sx={{ fontSize: '25px', color: 'red', marginLeft: '0px' }} />
                                  <div className='longer-text'>No longer considering applications for this gig</div>
                                </div>
                              </NolongerApctContainer>
                            }

                          </Grid>
                        </Grid>
                      </CardContent>
                    </EditedCard>
                  </Grid>
                ))}
              </Grid>


              {!_isEmpty(list) && (
                <Box sx={{ padding: '30px 0 10px 0' }}>
                  <Pagination
                    page={page}
                    count={count}
                    shape="rounded"
                    className="pagination-root"
                    onChange={onPaginationClick}
                    defaultPage={page}
                  />
                </Box>
              )}
            </>

            :
            <Grid container className="table-Padding" mt={2}>
              <GridTableEngineer
                headers={headers}
                rows={engineerProfile}
                handleRClick={handleRClick}
                handleChange={handleChange}
                pagination={paginationVal}
                defaultPage={_toNumber(paginationVal)}
                emptyMessage="You don’t have any Discover Gigs"
              ></GridTableEngineer>
            </Grid>
        }
        {openDashboard ? <PopUpPercentage modalOpen={openDashboard} modalClose={(e) => { setOpenDashboard(false) }} /> : <></>}
      </PaddedBox >
      <PaddedFooter style={{ paddingLeft: `${!!props.auth && !!props.auth.isDrawerOpen && props.auth.isDrawerOpen == true ? "200px" : "0px"}` }}>
        <Footer />
      </PaddedFooter>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    searchKey: state.sfs.searchKey,
    filters: state.sfs.filters,
    sortBy: state.sfs.sortBy,
    newGig: state.newGig,
    error: state.newGig.error,
    saveSuccess: state.newGig.saveSuccess,
    representative: state.profile.basicInfo || {},
    profile: state.profile
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    resetSFS: (details) => dispatch(resetSFS(details)),
    setNewGigStep: (details) => dispatch(setNewGigStep(details)),
    setNewGigSuccess: (details) => dispatch(setNewGigSuccess(details)),
    setNewGigError: (details) => dispatch(setNewGigError(details)),
    setProfileError: (details) => dispatch(setProfileError(details)),
    setProfileSuccess: (details) => dispatch(setProfileSuccess(details)),
    resetProfileSuccess: (details) => dispatch(resetProfileSuccess(details))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Discover);
