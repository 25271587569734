import styled from 'styled-components';
import { Stack } from '@mui/material';

const CustomStack = styled(Stack)`
  && {
    font-size: 12px;
    padding: 20px;
    a {
      color: #000000;
    }
  }
`;

export { CustomStack };
