import React, { useEffect, useState } from 'react'
import { Button, Card, CardContent, FormControl, Grid, InputLabel, Select, TextField, Typography } from '@mui/material'
import { connect } from 'react-redux';
import Footer from '../../../../components/footer1/footer';
import { CustomRow, PaddedBox, PaddedFooter } from '../../../app.styles';
import './bankDetails.css';
import Norecord from '../../../../assests/NoRecored.svg';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { Box } from '@mui/system';
import { engineerProfileGigs } from '../../../../services/gig';
import { resetProfileSuccess, setProfileError, setProfileSuccess } from '../../../../store/actions/profile';

const viewBankDetails = (props) => {
    const history = useHistory();
    const [billingDetails, setbillingDetails] = useState(null)

    useEffect(() => {
        if (!!history.location.state.billingDetails) {
            setbillingDetails(history.location.state.billingDetails)
        }
    }, [history])

    return (
        <>
            <PaddedBox
                style={{ paddingLeft: `${!!props.auth && !!props.auth.isDrawerOpen && props.auth.isDrawerOpen == true ? "240px" : "0px"}`, marginTop: '90px', minHeight: "580px" }}>

                {
                    !!billingDetails ?
                        <>
                            <div className='bank-page-responsive'>
                                <CustomRow sx={{ display: "flex", justifyContent: "flex-start !important" }} container>
                                    <Button variant="outlined" className="h-color-button-outlined smallButton" onClick={() => { history.go(-1); }}>Back</Button>
                                    <Typography
                                        variant="subtitle1"
                                        color="pColor.main"
                                        sx={{
                                            fontWeight: '500',
                                            textTransform: 'capitalize',
                                            fontSize: '22px',
                                            marginLeft: '20px'
                                        }}
                                    >
                                        View Billing Details
                                    </Typography>
                                </CustomRow>
                            </div>
                            <Card className='wrapper-text-no-data'>
                                <CardContent>
                                    <Box sx={{ marginTop: '25px' }}>
                                        <Grid container
                                            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                                            rowSpacing={{ xs: 3, sm: 2, md: 3 }}
                                        >
                                            <Grid item xs={12} md={3.5} lg={3.5}>
                                                <TextField
                                                    fullWidth
                                                    label='Account holder name'
                                                    variant="outlined"
                                                    value={billingDetails.holderName}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={3.5} lg={3.5}>
                                                <TextField
                                                    fullWidth
                                                    label='Bank Name'
                                                    variant="outlined"
                                                    value={billingDetails.bankName}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={3.5} lg={3.5}>
                                                <TextField
                                                    fullWidth
                                                    label='Account Number'
                                                    variant="outlined"
                                                    value={billingDetails.accountNumber}
                                                />
                                            </Grid>
                                            {
                                                billingDetails.branchCode === "" ?
                                                ""
                                                :
                                                <Grid item xs={12} md={3.5} lg={3.5}>
                                                <TextField
                                                    fullWidth
                                                    label='Swift/branch code (optional)'
                                                    variant="outlined"
                                                    value={billingDetails.branchCode}
                                                />
                                            </Grid>
                                            }
                                            <Grid item xs={12} md={3.5} lg={3.5}>
                                                <TextField
                                                    fullWidth
                                                    label='Account Type'
                                                    variant="outlined"
                                                    value={billingDetails.accountType}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </CardContent>
                            </Card>
                        </>
                        :
                            <>
                                <div className='bank-page-responsive'>
                                    <CustomRow sx={{ display: "flex", justifyContent: "flex-start !important" }} container>
                                        <Button variant="outlined" className="h-color-button-outlined smallButton" onClick={() => { history.go(-1); }}>Back</Button>
                                        <Typography
                                            variant="subtitle1"
                                            color="pColor.main"
                                            sx={{
                                                fontWeight: '500',
                                                textTransform: 'capitalize',
                                                fontSize: '22px',
                                                marginLeft: '20px'
                                            }}
                                        >
                                            View Billing Details
                                        </Typography>
                                    </CustomRow>
                                </div>
                                <div className='img-or-text-bank'>
                                    <div>
                                        <img src={Norecord}
                                            alt="norecord"
                                            fontSize="medium"
                                            width="30%" />
                                    </div>
                                    <div className='no-record-bank'>
                                        No bank details recorded
                                    </div>
                                </div>
                                </>
                }
            </PaddedBox>
            <PaddedFooter style={{ paddingLeft: `${!!props.auth && !!props.auth.isDrawerOpen && props.auth.isDrawerOpen == true ? "240px" : "0px"}` }}>
                <Footer />
            </PaddedFooter>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        myProfile: state.profile.myProfile || {},
        error: state.profile.error,
        basicInfo: state.profile.basicInfo,
        saveSuccess: state.profile.saveSuccess,
        profile: state.profile,
        billing: state.profile.billingDetails,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setProfileError: (details) => dispatch(setProfileError(details)),
        setProfileSuccess: (details) => dispatch(setProfileSuccess(details)),
        resetProfileSuccess: (details) => dispatch(resetProfileSuccess(details)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(viewBankDetails);