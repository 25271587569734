import { SET_GIGS_LIST, SET_LOCATION_DATA, SET_UNSET_INVOICE_TYPE, GET_ALL_FEEDBACK, GET_FEEDBACK_DETAIL, SET_NEW_FEEDBACK, SET_FEEDBACK_MANUAL, SET_VERIFY_FEEDBACK } from '../actions/gig';
import _get from 'lodash/get';

const Initial_value = {
  listError: {},
  invoiceType: undefined,
  feedbacks: null,
  feedbackDetail: null
};

const gigsList = (state = Initial_value, action) => {
  switch (action.type) {
    case SET_GIGS_LIST:
      return {
        ...state,
        pagination: _get(action, 'payload.paginationResult', {}),
        listError: {}
      };
    case SET_LOCATION_DATA:
      return {
        ...state,
        locationData: action.payload
      }
    case SET_UNSET_INVOICE_TYPE:
      return {
        ...state,
        invoiceType: action.payload.invoiceType
      }
    case GET_ALL_FEEDBACK:
      return {
        ...state,
        feedbacks: action.payload
      }
    case GET_FEEDBACK_DETAIL:
      return {
        ...state,
        feedbackDetail: action.payload
      }
    case SET_NEW_FEEDBACK:
      return {}
    case SET_FEEDBACK_MANUAL:
      return {}
    case SET_VERIFY_FEEDBACK:
      return {}
    default:
      return state;
  }
};

export default gigsList;
