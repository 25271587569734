import {
  SET_PROFILE_DETAILS,
  SET_PROFILE_ERROR,
  SET_PROFILE_SUCCESS,
  RESET_PROFILE_SUCCESS
} from '../actions/profile';

const Initial_value = {
  educationInfoList: [],
  languageList: [],
  certificationList: [],
  publicationList: [],
  expertiseList: [],
  skillList: [],
  projectList: [],
  socialUrls: [],
  basicInfo: {},
  employmentHistoryList: [],
  error: {},
  saveSuccess: false
};

const profile = (state = Initial_value, action) => {
  switch (action.type) {
    case SET_PROFILE_DETAILS:
      return {
        ...state,
        ...action.payload,
        error: {},
        saveSuccess: false
      };
    case SET_PROFILE_ERROR:
      return {
        ...state,
        saveSuccess: false,
        error: {
          ...action.payload
        }
      };
    case SET_PROFILE_SUCCESS:
      return {
        ...state,
        ...action.payload,
        error: {},
        saveSuccess: true
      };
    case RESET_PROFILE_SUCCESS: {
      return {
        ...state,
        error: {},
        saveSuccess: false
      };
    }
    default:
      return state;
  }
};

export default profile;
