import { ListItemIcon, ListItemText } from '@mui/material';
import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { CustomListItemBtn } from '../../components/Sidebar/Sidebar.styles';
import { Notifier } from '../../containers/app.styles';
import _isEmpty from 'lodash/isEmpty';
import _findIndex from 'lodash/findIndex';

const SidebarLink = styled(Link)((props) =>
  `
   ${props.active ?
    ` display: flex;
    color: #fff;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    list-style: none;
    height: 50px;
    text-decoration: none;
    font-size: 18px;
    background-color: #0140aa;
  
    
    &:active {
      background:#0140aa;
      cursor: pointer;
      color: #f5f5f5;
    }
    .icontext{
      display: flex;
      align-items: center;
      padding: 8px 8px;
    }
    svg {
      path {
        fill : #fff;
      }
    }
  }` : ` display: flex;
    color: #595757;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    list-style: none;
    height: 50px;
    text-decoration: none;
    font-size: 18px;
  
    &:hover {
      background:#f5f6fa;
      cursor: pointer;
    }
    &:active {
      background:#0140aa;
      cursor: pointer;
      color: #f5f5f5;
    }
    a.active {
      background:#0140aa;
    }
    .icontext{
      display: flex;
      align-items: center;
      padding: 8px 8px;
    }
    .iconopen{
      marginTop: -20px;
    }
  }`
  }
`);

const SidebarLabel = styled.span`
  margin-left: 6px;
`;

const DropdownLink = styled(Link)((props) =>
  `${props.active ?
    ` height: 50px;
  padding-left: 3rem;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #fff;
  font-size: 14px;
  background-color: #0140aa;

  &:active {
    background:#0140aa;
    cursor: pointer;
    color: #f5f5f5;
  }
` : ` height: 50px;
padding-left: 3rem;
display: flex;
align-items: center;
text-decoration: none;
color: #595757;
font-size: 14px;

&:hover {
  background: #f5f6fa;
  cursor: pointer;
}
&:active {
  background:#0140aa;
  cursor: pointer;
  color: #f5f5f5;
}
` }`);

const SubMenu = ({ text, icon, path, subMenuRoutes, subNav, iconClosed, iconOpened, notifications, props }) => {
  const [subMenuRoutess, setsubMenuRoutes] = useState(false);

  const showsubMenuRoutes = () => setsubMenuRoutes(!subMenuRoutess);
  if (!subNav) {
    subNav = [];
  }

  const location = useLocation();

  const isMenuActive = (location, path, subMenuRoutes = []) => {
    if (location.pathname === path) {
      return true;
    } else if (!_isEmpty(subMenuRoutes)) {
      return (
        _findIndex(subMenuRoutes, (o) => {
          return location.pathname.search(o) !== -1;
        }) !== -1
      );
    }
    return false;
  };

  return (
    <>
      <SidebarLink to={path} onClick={subMenuRoutes && showsubMenuRoutes} {...(isMenuActive(location, path, subMenuRoutes) && iconClosed === undefined && { active: 'true' })}>
        <div className='icontext'>
          {icon}
          <SidebarLabel>{text}</SidebarLabel>
        </div>
        <div className='iconopen'>
          {subMenuRoutes && subMenuRoutess
            ? iconOpened
            : subMenuRoutes
              ? iconClosed
              : null}
        </div>
        {(notifications && (props.chatNotifications.length > 0 || props.notifier)) ? <Notifier></Notifier> : <></>}
      </SidebarLink>
      {subMenuRoutess &&
        subNav.map((item, index) => {
          return (
            <DropdownLink to={item.path} key={index} {...(location.pathname.includes(item.path) && iconOpened !== undefined && { active: 'false' })}>
              <SidebarLabel>{item.title}</SidebarLabel>
              {item.icon}
            </DropdownLink>
          );
        })}
    </>
  );
};

export default SubMenu;