import _get from 'lodash/get';
import { SET_GIGS_DRAFT } from '../actions/gig';

const Initial_value = {
  listError: {}
};

const gigDrafts = (state = Initial_value, action) => {
  switch (action.type) {
    case SET_GIGS_DRAFT:
      return {
        ...state,
        listError: {}
      };

    default:
      return state;
  }
};

export default gigDrafts;
