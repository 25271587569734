import SignIn from '../containers/signin/SignIn';
import ResetPassword from '../containers/signin/ResetPassword';
import SignUp from '../containers/signup/SignUp';
import EngineerDetailsPage from '../containers/EngineerDetailsPage/EngineerDetailsPage';
import HomePage from '../components/HomePage';
import AboutUs from '../components/AboutUs';
import Contact from '../components/Contact';
import TermsConditions from '../components/TermsConditions/TermsConditions';
import PrivacyPolicy from '../components/Privacypolicy/PrivacyPolicy';
import { isNonRegisteredUserRoute } from './authcheck';
import UnAuthorizedComp from '../components/NotFound/UnAuthorized';
import Comingsoon from '../components/ComingSoon/comingSoon';
import signInDialog from '../components/SignInForm/signInDialog';
import { LinkedInCallback } from 'react-linkedin-login-oauth2';
import GigDetailpublicView from '../containers/gigUser/gigdetails/GigDetailpublicView';

const publicRoutes = [
  {
    path: '/',
    component: isNonRegisteredUserRoute(HomePage),
    exact: true
  },
  {
    path: '/signin',
    component: isNonRegisteredUserRoute(SignIn),
    exact: true
  },
  {
    path: '/signin',
    component: isNonRegisteredUserRoute(signInDialog),
    exact: true
  },
  {
    path: '/verified/signin',
    component: isNonRegisteredUserRoute(SignIn),
    exact: true
  },
  {
    path: '/signup',
    component: isNonRegisteredUserRoute(SignUp),
    exact: true
  },
  {
    path: '/aboutus',
    component: isNonRegisteredUserRoute(AboutUs),
    exact: true
  },
  {
    path: '/user/reset-password',
    component: isNonRegisteredUserRoute(ResetPassword),
    exact: true
  },
  {
    path: '/unauthorised',
    component: UnAuthorizedComp,
    exact: true
  },
  {
    path: '/contact',
    component: isNonRegisteredUserRoute(Contact),
    exact: true
  },
  {
    path: '/termsconditions',
    component: isNonRegisteredUserRoute(TermsConditions),
    exact: true
  },
  {
    path: '/privacypolicy',
    component: isNonRegisteredUserRoute(PrivacyPolicy),
    exact: true
  },
  {
    path: '/profile/:username',
    component: isNonRegisteredUserRoute(EngineerDetailsPage),
    exact: true
  },
  {
    path: '/linkedin',
    component: LinkedInCallback,
    exact: true
  },
  {
    path: '/gig/public/:id',
    component: isNonRegisteredUserRoute(GigDetailpublicView),
    exact: true
  }

];

export default publicRoutes;
