import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { TextField, InputAdornment, IconButton } from '@mui/material';
import _trim from 'lodash/trim';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { SearchBarGid, SearchIconbtn } from './sfs.styles';
import { setSearchKey } from '../../store/actions/sfs';
import _get from 'lodash/get';
import PopUpPercentage from '../PopUpPercentage/PopUpPercentage';

const searchKeyValidation = (key) => {
  return _trim(key);
};

const Search = (props) => {

  const [searchKeyword, setSearchKeyword] = useState(props.searchKey);
  const [openDashboard, setOpenDashboard] = useState(false);
  const percentage = _get(props, 'profile.profileCompletionPercent', 0);

  const modalOpen = () => {
    props.setSearchKey(searchKeyword);
    setOpenDashboard(true);

  }

  useEffect(() => {
    setSearchKeyword(props.searchKey);
  }, [props.searchKey]);

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        event.stopPropagation();
        props.setSearchKey(searchKeyValidation(searchKeyword));
      }}
    >
      <SearchBarGid>
        {(percentage < 60) ?
          <TextField
            placeholder={props.placeholder ? props.placeholder : "Search..."}
            size="small"
            className="inputRounded"
            value={searchKeyword}
            onChange={(event) => {
              setSearchKeyword(event.target.value)
              setOpenDashboard(true);
            }}
            
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {searchKeyword && (
                    <IconButton
                      onClick={() => {
                        setSearchKeyword('');
                        props.setSearchKey('');
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  )}
                </InputAdornment>
              )
            }}
            sx={{ height: '25px', marginTop: '6px' }}
          />

          :
          <TextField
            placeholder={props.placeholder ? props.placeholder : "Search..."}
            size="small"
            className="inputRounded"
            value={searchKeyword}
            onChange={(event) => {
              setSearchKeyword(event.target.value);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {searchKeyword && (
                    <IconButton
                      onClick={() => {
                        setSearchKeyword('');
                        props.setSearchKey('');
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  )}
                </InputAdornment>
              )
            }}
            sx={{ height: '25px', marginTop: '6px' }}
          />
        }
        {(percentage < 60) ?
          <SearchIconbtn aria-label="search" sx={{ marginTop: '6px' }}>
            <SearchIcon sx={{ height: '25px' }} onClick={modalOpen} />
          </SearchIconbtn>
          :
          <SearchIconbtn type="submit" aria-label="search" sx={{ marginTop: '6px' }}>
            <SearchIcon sx={{ height: '25px' }} />
          </SearchIconbtn>
        }
      </SearchBarGid>
      {openDashboard ? <PopUpPercentage modalOpen={openDashboard} modalClose={(e) => { setOpenDashboard(false) }} /> : <></>}
    </form>
  );
};

const mapStateToProps = (state) => {
  return {
    searchKey: state.sfs.searchKey,
    myProfile: state.profile.myProfile || {},
    profile: state.profile,
    representative: state.profile.basicInfo || {},
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSearchKey: (details) => dispatch(setSearchKey(details))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Search);
