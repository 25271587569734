import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _toNumber from 'lodash/toNumber';
import _last from 'lodash/last';
import { PaddedBox, PaddedFooter } from '../../app.styles';
import { Button, Dialog, Grid } from '@mui/material';
import GridTable from '../../../components/GridTable/GridTable';
import { formatDate, getGigLocation, getHireLocation, getQueryParams } from '../../../utils/helpers';
import { getPastGig, getPastGigs, getRehireGigsData, hireRehire } from '../../../services/gig';
import Typography from '@mui/material/Box';
import { Box } from '@mui/system';
import { resetSFS } from '../../../store/actions/sfs';
import { connect } from 'react-redux';
import Message from '../../../assests/message.svg';
import { toast } from 'react-toastify';
import './Rehire.css';
import Footer from '../../../components/footer1/footer';
import GridPastTable from '../../../components/GridTable/GridPastTable';
import PageLoader from '../../../components/Loaders/PageLoader';

const Pasthires = (props) => {

  const query = getQueryParams();
  const queryPage = query.page;

  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(queryPage || 1);
  const [open, setOpen] = React.useState(false);
  const [paginationVal, setPaginationVal] = useState(1);
  const [pastHire, setPastHire] = useState({});
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('lg');
  const history = useHistory();

  const getMessageLink = (msglink, engineerName, userId) => {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Button
          variant="contained"
          className="btn-renhire"
          sx={{
            textTransform: 'capitalize',
            alignItems: 'center',
            height: '30px',
            borderRadius: '4px',
            border: '1px solid #A7A7A7',
            width: '90px',
            height: '32px',
            background: '#0543ab',
            padding: '0px'
          }}
          onClick={() => {
            setOpen(true);
            setPastHire({ engineerName, userId })
            activeFetchData(pageActive, userId);
          }}
        >
          Rehire
        </Button>
        <br />
        <Box sx={{ marginLeft: '7px' }}>
          <a
            className="message-Link"
            onClick={() => {
              history.push(msglink);
            }}
          >
            <img src={Message} alt="message" fontSize="medium" width="100%" />
          </a>
        </Box>
      </Box>
    )
  }

  const pastHires = ['ENGINEER NAME', 'GIG NAME', 'LOCATION', 'START DATE', 'ACTION'];

  const setData = (data) => {
    const rowData = [];
    const hireList = _get(data, 'hiredetailsList', []);
    const paginationObject = _get(data, 'paginationResult', {});
    if (hireList && hireList.length > 0) {
      for (let obj of hireList) {
        const engineerName = _get(obj, 'engineerName', '');
        const gigName = _get(obj, 'gigName', '');
        const userId = _get(obj, 'userId.profileData.basicInfo.userId', '');
        const gigId = _get(obj, 'gigId._id', '');
        const bussinessId = _get(obj, 'bussinessId', '');
        let location = getHireLocation(obj.hireDetails, obj.isRemote);
        const fromDate = _get(obj, 'fromDate', '') ? formatDate(_get(obj, 'fromDate', '')) : '-';
        const message = `/ochat?to=${_get(obj, 'userId.profileData.basicInfo.userId', '')}`
        rowData.push([engineerName, gigName, location, fromDate, getMessageLink(message, engineerName, userId), gigId, userId, '', obj]);
      }
    }
    setRows(rowData);
    setPaginationVal(paginationObject.totalPages || 1);
  };

  const fetchData = (currentPage) => {
    const obj = {
      paginationInput: {
        pageNumber: currentPage,
        pageSize: 12
      },
      filter: {
        userStatusList: ['ACTIVE']
      },
    };

    getPastGig(obj, setData);
  };

  useEffect(() => {
    fetchData(page);
  }, []);

  const handleChange = (event, value) => {
    fetchData(value);
    setPage(value);
  };

  const handleClose = () => {
    setOpenActive(false);
    setOpen(false);
    fetchData(1);
  }

  const handleRowClick = (data) => {
    const gigData = _last(data);
    history.push(`/gig/hires/pasthires/${gigData?.gigId?._id}`);
  };

  const handleRClick = (data) => {
    const gigData = _last(data);
    history.push(`/engineer/details/${gigData?.userId?.profileData?.basicInfo?.userId}`);
  };

  const activeHeaders = ['GIG NAME', 'APPLICATIONS', 'RATE', 'AVAILABILITY', 'LOCATION', 'ACTIONS'];
  const [rowsActive, setRowsActive] = useState([]);
  const [pageActive, setPageActive] = useState(queryPage || 1);
  const [paginationActive, setPaginationActive] = useState(1);
  const [openActive, setOpenActive] = useState(false);
  const [activeRehire, setActiveRehire] = useState({});

  const createDataForGig = (name, duration, rate, hours, location, id) => {
    if (rate === "") {
      rate = "Not Disclosed";
    }
    return [name, duration, rate, hours, location, id];
  };

  const getActiveLink = (id, name, reHireCount) => {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        {
          !!reHireCount && reHireCount > 0 ?
            <Button
              variant="muted"
              className="btn-renhire-select-muted"
              sx={{
                textTransform: 'capitalize',
                alignItems: 'center',
                height: '30px',
                borderRadius: '4px',
                border: '1px solid #A7A7A7',
                width: '122px',
                height: '32px',
                cursor: 'not-allowed'
              }}
            >
              Requested
            </Button>
            :
            <Button
              variant="contained"
              className="btn-renhire-select"
              sx={{
                textTransform: 'capitalize',
                alignItems: 'center',
                height: '30px',
                borderRadius: '4px',
                border: '1px solid #A7A7A7',
                width: '122px',
                height: '32px',
              }}
              onClick={() => {
                setOpenActive(true);
                setOpen(false);
                setActiveRehire({ id, name });
              }}
            >
              select
            </Button>
        }
      </Box>
    )
  }

  const rehireSubmit = async (e) => {
    e.preventDefault();
    let data = {
      gigId: activeRehire.id,
      gigName: activeRehire.name,
      bussinessId: props.profile.basicInfo.userId,
      userId: pastHire.userId,
      engineerName: pastHire.engineerName,
    }

    hireRehire(data).then((e)=>{
      toast.success("Rehire success");
      setOpenActive(false);
      fetchData(1);
    })
  }

  const activeFetchData = (currentPage, userId) => {
    const obj = {
      paginationInput: {
        pageNumber: _toNumber(currentPage),
        pageSize: 5
      },
      status: ['ACTIVE'],
      userId: !!pastHire.userId ? pastHire.userId : !!userId ? userId : undefined
    };
    getRehireGigsData(obj, setActiveData);
  };

  const setActiveData = (data) => {
    const rowData = [];
    const gigList = _get(data, 'gigList', []);
    const paginationObject = _get(data, 'paginationResult', {});
    for (let i = 0; i < gigList.length; i++) {
      const gig = gigList[i];
      const name = _get(gig, 'titleAndSkills.projectTitle', '');
      const duration = _get(gig, 'applicantCount', 0);
      const rate = _get(gig, 'scopeAndBudget.hourlyRate', '') ? `R ${_get(gig, 'scopeAndBudget.hourlyRate', '')} /hr` : '';
      const hours = _get(gig, 'scopeAndBudget.availabilityHoursPerWeek', '') ? `${_get(gig, 'scopeAndBudget.availabilityHoursPerWeek', '')} hrs/week` : '';
      const location = _get(gig, 'deliverablesAndLocation.location.country', '') ? _get(gig, 'deliverablesAndLocation.location.country', '') : 'Remote';
      const gigId = _get(gig, 'userId', '');
      const id = _get(gig, '_id', '');
      const reHireCount = _get(gig, 'reHireCount', '');

      rowData.push(createDataForGig(name, duration, rate, hours, location, getActiveLink(id, name, reHireCount), id, gigList[i])
      );
    }
    setRowsActive(rowData);
    setPaginationActive(paginationObject.totalPages || 1);
  };

  const handleActiveChange = (event, value) => {
    activeFetchData(value);
    setPageActive(value);
  };

  return (
    <>
      <PaddedBox style={{ textAlign: 'center', paddingLeft: `${!!props.auth && !!props.auth.isDrawerOpen && props.auth.isDrawerOpen == true ? "240px" : "0px"}`, minHeight: "580px" }}>
        <Grid container>
          <Typography
            variant="h6"
            component="h6"
            color="#0543AB"
            sx={{ fontWeight: '500px', textTransform: 'capitalize', fontSize: '22px', lineHeight: '33px', fontFamily: 'Poppins', fontWeight: 'normal', marginTop: '0px' }}
          >
            Past Hires
          </Typography>
          <Grid container sx={{ marginTop: '-34px' }}>
            <Grid container className="table-Padding">
              <GridPastTable
                headers={pastHires}
                rows={rows}
                handleRowClick={handleRowClick}
                handleRClick={handleRClick}
                handleChange={handleChange}
                pagination={paginationVal}
                defaultPage={_toNumber(page)}
                emptyMessage="You don't have any current hires yet."
              ></GridPastTable>
            </Grid>
          </Grid>
        </Grid>
        <Dialog fullWidth={fullWidth} maxWidth={maxWidth} open={open} onClose={handleClose} sx={{ height: '100%', justifyContent: 'center', justifyItems: 'center', textAlign: 'center', alignContent: 'center', alignItems: 'center' }}>
          <Grid container className='padd-data'>
            <Typography
              variant="h6"
              component="h6"
              color="black"
              sx={{ fontWeight: '400px', fontSize: '22px', alignItems: 'center', marginTop: '-0px' }}
            >
              Select an active gig
            </Typography>

            <Grid container className='rehire-table'>
              <GridTable
                textCenter={true}
                backgroundColor={true}
                headers={activeHeaders}
                rows={rowsActive}
                handleChange={handleActiveChange}
                pagination={paginationActive}
                defaultPage={_toNumber(pageActive)}
              ></GridTable>
            </Grid>
          </Grid>
        </Dialog>

        <Dialog open={openActive} onClose={handleClose} >
          <Typography
            sx={{
              fontSize: '26px',
              lineHeight: '55px',
              fontWeight: '600',
              paddingLeft: '20px'
            }}>
            Confirm rehire request!
          </Typography>
          <Typography
            sx={{
              textAlign: 'center',
              borderTop: "1px solid #E1E1E1",
              borderBottom: "1px solid #E1E1E1",
              width: '500px',
              fontSize: '20px',
              lineHeight: '55px',
              fontWeight: '400',
            }}>
            Are you sure you want to send a request for rehire?
          </Typography>
          <Box
            sx={{
              marginLeft: '20px',
              marginTop: '20px',
              paddingBottom: '20px',
              marginLeft: '220px'
            }}
          >
            <Button
              variant="contained"
              className="cancel-btn"
              type="submit" sx={{
                marginRight: '10px',
                textTransform: 'none',
                width: '120px',
                height: '40px',
                border: '2px solid grey',
                boxShadow: "none"
              }}
              onClick={handleClose}
            >
              No
            </Button>
            <Button
              variant="contained"
              className="popup-process"
              type="submit" sx={{
                textTransform: 'none',
                width: '120px',
                height: '40px',
                boxShadow: "none"
              }}
              onClick={(e) => rehireSubmit(e)}
            >
              Yes
            </Button>
          </Box>
        </Dialog>


      </PaddedBox >
      <PaddedFooter style={{ paddingLeft: `${!!props.auth && !!props.auth.isDrawerOpen && props.auth.isDrawerOpen == true ? "240px" : "0px"}` }}>
        <Footer />
      </PaddedFooter>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    newGig: state.newGig,
    profile: state.profile,
    searchKey: state.sfs.searchKey,
    filters: state.sfs.filters,
    sortBy: state.sfs.sortBy
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setNewGigDetails: (data) => dispatch(setNewGigDetails(data)),
    resetSFS: (details) => dispatch(resetSFS(details))
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Pasthires);
