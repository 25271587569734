import React from 'react';
import { connect } from 'react-redux';
import { IconButton, InputAdornment, Link } from '@mui/material';
import { TextField, Button, Alert } from '@mui/material';
import Box from '@mui/material/Box';
import { Formik, Form } from 'formik';
import _isEmpty from 'lodash/isEmpty';
import Dialog from '@mui/material/Dialog';
import _get from 'lodash/get';
import { SignInMsg, SignInDisclamer, DialogCss } from './SignInForm.styles';

import {
  signInScreenValidationSchema,
  forgotPasswordScreenValidationSchema
} from '../../utils/formikValidations';
import forgotPassword from '../../services/forgotPassword';
import { setAuthSignError } from '../../store/actions/auth';
import { Grid } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import ReactGA from "react-ga";
import { signInReq } from '../../services/signIn';
ReactGA.initialize(`G-1596JC7760`);
ReactGA.pageview(window.location.pathname + window.location.search);
const SignInForm = (props) => {

  const [open, setOpen] = React.useState(false);
  const [isResetLink, setIsResetLink] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('sm');
  const [emailMsg, setEmailMsg] = React.useState('');

  const isValidEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const handleClose = () => {
    setOpen(false);
    setIsResetLink(false);
  };

  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <>
      {!_isEmpty(_get(props, 'auth.signError', {})) && (
        <Alert severity="error" className="h-margin-bottom-16">
          {_get(props, 'auth.signError.message', 'some thing went wrong please try again')}
        </Alert>
      )}
      <Formik
        initialValues={{
          emailId: '',
          password: ''
        }}
        enableReinitialize={true}
        validationSchema={signInScreenValidationSchema}
        onSubmit={(values) => {
          const obj = { ...values };
          signInReq(obj, props.setAuthSignError);
        }}
      >
        {({ values, touched, errors, handleChange, handleBlur, setFieldValue }) => (
          <Form noValidate autoComplete="off">
            <TextField
              margin="normal"
              fullWidth
              id="emailId"
              name="emailId"
              label="Email"
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.emailId && Boolean(errors.emailId)}
              helperText={touched.emailId && errors.emailId}
              value={values.emailId}
              variant="outlined"
            />
            <TextField
              margin="normal"
              fullWidth
              type={showPassword ? "text" : "password"}
              id="password"
              name="password"
              label="Password"
              autoComplete="current-password"
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.password && Boolean(errors.password)}
              helperText={touched.password && errors.password}
              value={values.password}
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2, paddingTop: "10px", paddingBottom: "10px" }}
            >
              SIGN IN
            </Button>
          </Form>
        )}
      </Formik>
      <div style={{display: 'flex', justifyContent: 'space-between', gap: '172px'}}>
          <Link href="#" variant="body2" onClick={() => {
            setOpen(true);
          }}>
                    Forgot password?
                  </Link>
                <SignInMsg>
                Want to create a new account instead? 
                  <Link href="/signup" variant="body2"  sx={{cursor: 'pointer', fontWeight: '600', fontSize: '14px', ml: '4px'}}>
                 Sign up
                  </Link>
                </SignInMsg>
                </div>
    
      <SignInDisclamer>
        By continuing, you agree to accept our Privacy Policy & Terms of Service.
      </SignInDisclamer>
      <Dialog fullWidth={fullWidth} maxWidth={maxWidth} open={open} onClose={handleClose}>
        <DialogCss>
          <Box
            sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '40px 30px', borderRadius: "10px" }}
          >
            <Box sx={{ textAlign: "center", fontSize: "22px", fontWeight: "500", marginBottom: "30px", color: "#1565c0" }}>FORGOT PASSWORD?</Box>
            <Box sx={{ textAlign: "center", fontSize: "14px", marginBottom: "20px", color: "#696969" }}>
              Enter the email address associated with your account and we’ll send you a link to
              reset your password.
            </Box>
            <Formik
              initialValues={{ email: '' }}
              validationSchema={forgotPasswordScreenValidationSchema}
            >
              {({ values, touched, errors, handleChange, handleBlur }) => (
                <Form
                noValidate
                  autoComplete="off"
                  onSubmit={async (e) => {
                    e.preventDefault();
                    let data = {
                      emailId: values.email.trim().toLowerCase()
                    };

                    if (values.email === '' || values.email === null) {
                      return setEmailMsg('Email is required!');
                    } else {
                      let isEmailValid = isValidEmail(values.email);
                      if (isEmailValid) {
                        setEmailMsg('');
                      } else {
                        return setEmailMsg('Enter Valid email!');
                      }
                    }
                    let response = await forgotPassword(data, props.setAuthSignError);
                    if (response && response.status === 200) {
                      setIsResetLink(true);
                    } else {
                      setIsResetLink(false);
                    }
                  }}
                >
                  <TextField
                    fullWidth
                    name="email"
                    label="Email Address"
                    onChange={(e) => {
                      setEmailMsg('');
                      handleChange(e);
                    }}
                    value={values.email}
                    variant="outlined"
                    error={emailMsg !== ''}
                    helperText={emailMsg !== '' ? emailMsg : ''}
                  />
                  <Box sx={{ marginTop: "20px" }}>
                    {isResetLink ? (
                      <h4>Link has been sent to your email.</h4>
                    ) : (
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ paddingTop: "10px", paddingBottom: "10px" }}
                      >
                        SEND RESET LINK
                      </Button>
                    )}
                  </Box>
                </Form>
              )}
            </Formik>
            <br />
            <SignInMsg>
              Don’t have an account? &nbsp;
              <Link href="/signup" underline="hover" color="#FF8008" className="h-color-orange">
                Sign Up
              </Link>
            </SignInMsg>
          </Box>
        </DialogCss>
        <br />
      </Dialog>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setAuthSignError: (details) => dispatch(setAuthSignError(details))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignInForm);
