import { SET_EXPERTISESKILLS_DETAILS } from '../actions/expertisewithskills';
import _get from 'lodash/get';

const expertisewithskills = (state = {}, action) => {
  switch (action.type) {
    case SET_EXPERTISESKILLS_DETAILS:
      return {
        ...state,
        ...action.payload
      };

    default:
      return state;
  }
};

export default expertisewithskills;
