import { Redirect } from 'react-router-dom';
import { isRegisteredUser, getUserType } from '../utils/user';
import Authorization from '../containers/Authorization';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setDrawerData } from '../store/actions/auth';
import { getCookie } from '../utils/cookie';

export const isRegisteredUserRoute = (Component, routeType, menuProfile) => () => {
  let dispatch = useDispatch();
  if (isRegisteredUser()) {
    useEffect(() => {
      dispatch(setDrawerData({
        "routeAuth": routeType,
        "menuType": menuProfile
      }))
    }, [])

    return (
      <Authorization routeAuth={routeType} renderComp={<Component />} menuType={menuProfile} />
    );
  } else {
    return <Redirect to="/signin" />;
  }
};

export const isNonRegisteredUserRoute = (Component) => () => {
  let dispatch = useDispatch();
  useEffect(() => {
    dispatch(setDrawerData({
      "routeAuth": undefined,
      "menuType": undefined
    }))
  }, [])

  return <Component />;
};

export const getUserDashboardRoute = (userType, userId) => {
  if (!!getCookie('redirect_profile_path')) {
    return getCookie('redirect_profile_path')
  }
  else if (!!!getCookie('redirect_profile_path')) {
    switch (userType) {
      case 'business':
        return '/gig/dashboard';
      case 'admin':
        return '/admin/engineer/profile';
      case 'engineer':
        return '/dashboard';
      default:
        return '/unauthorised';
    }
  }
  else {
    console.error("SOMETHING WENT WRONG", getCookie('redirect_profile_path'))
  }
};

export const getUserProfileRoute = (userType) => {
  switch (userType) {
    case 'business':
      return '/representative';
    case 'admin':
      return '/admin/engineer/profile';
    case 'engineer':
      return '/myprofile';
    default:
      return '/unauthorised';
  }
};
