import _get from 'lodash/get';
import apiService from './apiServices';

export const discoverOwners = async (data) => {
  const request = {
    data,
    method: 'post',
    url: '/opportunity/discoverOwners'
  };
  const response = await apiService(request);
  if (response && response.status === 200) {
    // sucess action
    return response.data;
  }
};

export const businessProfileGig = async (data, setGigsList, setListData) => {
  const request = {
    data,
    method: 'post',
    url: '/opportunity/discoverOwners'
  };
  const response = await apiService(request);
  if (response && response.status === 200) {
    // sucess action
    setGigsList(_get(response, 'data', {}));
    setListData(_get(response, 'data', {}));
  }
};

export const adminProfiles = async (data, setGigsList, setListData) => {
  const request = {
    data,
    method: 'post',
    url: '/admin/unverified-profiles'
  };
  const response = await apiService(request);
  if (response && response.status === 200) {
    // sucess action
    setGigsList(_get(response, 'data', {}));
    setListData(_get(response, 'data', {}));
  }
};

export const resendEmailVerification = async (emailId) => {
  const request = {
    method: 'get',
    url: `/admin/resend-verification-email?emailId=${emailId}`
  };
  try {
    return apiService(request);
  }
  catch (e) {
    console.error(e)
  }
}

export const getProfileOwnerDetByIdApi = async (id) => {
  const request = {
    method: 'get',
    url: `/profile/owner/${id}`
  };
  const response = await apiService(request);
  if (response && response.status === 200) {
    // sucess action
    return _get(response, 'data.profile', {});
  }
};
