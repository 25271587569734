import React, { useState } from 'react';
import { Button } from '@mui/material';
import _get from 'lodash/get';
import { toast } from 'react-toastify';
import Prompt from '../../components/Prompt/Prompt';
import { modifyEnginnerStatus } from '../../services/profile';
import { useEffect } from 'react';
import './AccountActivate.css'

const AccountActivate = (props) => {

    // state information for pop open and close
    const [open, setOpen] = useState(false);
    const [promptData, setPromptData] = useState(undefined);
    const [messageBody, setMessageBody] = useState('');
    const [accountStatus, setAccountStatus] = useState('');

    useEffect(() => {
        let status = _get(props.profileDet, 'basicInfo.status', '') === 'INACTIVE' ? 'Activate' : 'Deactivate';
        setAccountStatus(status);
    }, [props]);

    // modify the Account type on click
    const modifyAccount = () => {
        setPromptData({
            id: _get(props.profileDet, 'basicInfo.userId'),
            accountStatus
        }); // show confirmation prompt before change status
        setMessageBody(`Do you want to ${accountStatus} the Account?`); // confirmation message
        setOpen(true);
    };

    // on selecting/rejecting prompt
    const onPromptSelected = (data) => {
        setOpen(false);
        if (data.type !== 'no') {
            // make api call to deactivate the account
            const obj = {
                userId: data.id,
                status: data.accountStatus === 'Deactivate' ? 'INACTIVE' : 'ACTIVE',
                remarks: data.remarks,
                internalRemarks: data.comments
            };
            modifyEnginnerStatus(obj, () => {
                toast.success(`Account ${data.accountStatus}d Sucessfully`);
                setAccountStatus(data.accountStatus === 'Deactivate' ? 'Activate' : 'Deactivate');
            });
        }
    };

    return (
        <>
            <button
                // variant="contained"
                // className="activate-btn"
                onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    modifyAccount();
                }}
            >
                {accountStatus}
            </button>
            {open ? (
                <Prompt
                    message={messageBody}
                    show={open}
                    heading="Confirmation"
                    data={promptData}
                    values={['yes', 'no']}
                    showRemarks={true}
                    onPromptSelected={(data) => {
                        onPromptSelected(data);
                    }}
                ></Prompt>
            ) : (
                <></>
            )}
        </>
    );
};

export default AccountActivate;
