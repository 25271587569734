import React, { useState } from 'react';
import { getUserType } from '../../../../utils/user';
import AccountDeactivate from '../../../../components/AccountDeactivate/AccountDeactivate';
import VerifyProfile from '../../../../components/VerifyProfile/VerifyProfile';

const EngineerDetailView = (props) => {
  return (
    <>
      <div
        onClick={() => {
          props.goBack();
        }}
      >
        Engineer Detail View
      </div>
      {
        !props.profileDet.isVerified
        &&
        <button className='verify-btn-cta' onClick={()=>{props.handleVerifyAccount()}}>
          Verify
        </button>
      }
      <AccountDeactivate profileDet={props.profileDet} />
    </>
  );
};
const ProfileDetHeader = (props) => {
  const userType = getUserType(); // get the user type from tokens
  return (
    <>
      {userType == 'admin' && <EngineerDetailView goBack={props.goBack} handleVerifyAccount={props.handleVerifyAccount} profileDet={props.profileDet} />}
      {userType !== 'admin' && <><div onClick={() => {
        props.goBack();
      }}>Back to List</div>
      </>}
    </>
  );
};

export default ProfileDetHeader;
