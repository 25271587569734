import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';
import Card from '@mui/material/Card';
import { Grid } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getUploadApi } from '../../services/upload';
import { toast } from 'react-toastify';
import VerifiedUserOutlinedIcon from '@mui/icons-material/VerifiedUserOutlined';
import { getUserId, getUserType } from '../../utils/user';
import { Dialog, Typography } from '@mui/material';
import { getUserDashboardRoute } from '../../routes/authcheck';
import { PaddedBox, PaddedFooter } from '../app.styles';
import Footer from '../../components/footer1/footer';
import RaiseQueryIcon from '../../assests/RaiseQueryIcon.svg';
import { DragDropIcon } from '../profile/engineer/DragDrop/DragDrop';
import './RequestQuery.css';
import { queryrequestquery } from '../../services/gig';

const RequestQuery = (props) => {
  const userType = getUserType();

  const his = useHistory();
  const commonStyles = {
    bgcolor: 'background.paper',
    mr: 1,
    border: 1,
    width: '5rem',
    height: '5rem',
    color: '#0140aa',
    borderRadius: '4px',
    fontWeight: 600,
    padding: '12px 16px'
  };
  const [open, setOpen] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('sm');
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState('');
  const [fileName, setFileName] = useState('');
  const [query, setQuery] = useState('');
  const [queryMsg, setQueryMsg] = useState('');
  const [description, setDescription] = useState('');
  const [descriptionMsg, setDescriptionMsg] = useState('');

  const handelChange = async (filename, e) => {
    const checkedSize = 1024 * 1024 * 5;
    const selectedFileSize = filename && filename.size;
    if (selectedFileSize > checkedSize) {
      setFileName('');
      toast.error('File size should be less than 5 MB');
      return;
    }

    setFileName(filename.name);
    setLoading(true);
    let img = await getUploadApi(filename);
    setLoading(false);
    setFile(img?.data?.fileUrl);
  };

  const submitForm = async () => {
    let validate = true;
    if (query === '' || query === null) {
      validate = false;
      setQueryMsg('Please enter the query!');
    } else {
      setQueryMsg('');
    }
    if (description === '' || description === null) {
      validate = false;
      setDescriptionMsg('Please enter the description!');
    } else {
      setDescriptionMsg('');
    }

    if (validate) {
      let data = {
        title: query,
        emailId: props.profile.basicInfo.emailId,
        description: description,
        attachmedia: file,
        userType: getUserType(),
        userId: getUserId(),
        userName: props.profile.basicInfo.name
      };

      queryrequestquery(data).then((e) => {
        setOpen(true);
      });
    }
  };

  const handleClose = () => {
    setOpen(false);
    his.push(getUserDashboardRoute(userType));
  };

  return (
    <>
      <PaddedBox
        style={{
          paddingLeft: `${
            !!props.auth && !!props.auth.isDrawerOpen && props.auth.isDrawerOpen == true
              ? '240px'
              : '0px'
          }`,
          marginTop: '72px',
          minHeight: '580px'
        }}
      >
        <Card>
          <CardContent>
            <Grid
              container
              sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}
              xs={12}
              md={12}
              lg={12}
            >
              <Grid item xs={12} md={6} lg={6}>
                <TextField
                  fullWidth
                  id="query"
                  name="query"
                  value={query}
                  placeholder="SUBJECT OF THE QUERY"
                  label="TITLE"
                  onChange={(e) => {
                    setQuery(e.target.value);
                    setQueryMsg('');
                  }}
                  error={queryMsg !== ''}
                  helperText={queryMsg !== '' ? queryMsg : ''}
                />
              </Grid>
            </Grid>
            <Grid
              container
              sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}
              xs={12}
              md={12}
              lg={12}
            >
              <Grid item xs={12} md={6} lg={6}>
                <TextField
                  fullWidth
                  id="des"
                  name="description"
                  value={description}
                  placeholder="DESCRIPTION"
                  label="DESCRIPTION"
                  onChange={(e) => {
                    setDescription(e.target.value);
                    setDescriptionMsg('');
                  }}
                  error={descriptionMsg !== ''}
                  helperText={descriptionMsg !== '' ? descriptionMsg : ''}
                  multiline
                  rows={5}
                />
              </Grid>
            </Grid>
            <Grid
              container
              sx={{ mt: 3, display: 'flex', justifyContent: 'center' }}
              xs={12}
              md={12}
              lg={12}
            >
              <Grid item xs={12} md={6} lg={6}>
                <Box
                  component="span"
                  sx={{
                    ...commonStyles,
                    border: '1.6px dashed #0140AA',
                    width: 'fit-content',
                    height: 'fit-content',
                    display: 'flex',
                    alignItems: 'center',
                    padding: '0 10px'
                  }}
                >
                  <React.Fragment>
                    {loading ? (
                      <CircularProgress className="upload-Loading"></CircularProgress>
                    ) : (
                      <div className="raise-query-upload-icon" title="Max Size 5MB">
                        <DragDropIcon
                          onFileChange={handelChange}
                          text={<div className="attachment-text-query">ATTACHMENT</div>}
                          icons={<img src={RaiseQueryIcon} alt="RaiseQueryIcon" />}
                        ></DragDropIcon>
                      </div>
                    )}
                  </React.Fragment>
                </Box>
                <div className="textoverflow-request-3" title={fileName}>
                  <span>{fileName ? fileName : ''}</span>
                </div>
              </Grid>
            </Grid>
            <Grid
              container
              sx={{ mt: 3, display: 'flex', justifyContent: 'center' }}
              xs={12}
              md={12}
              lg={12}
            >
              <Grid sx={{ display: 'flex', justifyContent: 'center' }} item xs={12} md={6} lg={6}>
                <Button
                  variant="contained"
                  onClick={submitForm}
                  sx={{
                    background: 'linear-gradient(#ff8008, #FFC837)',
                    float: 'left'
                  }}
                >
                  SUBMIT
                </Button>
              </Grid>
            </Grid>

            <Dialog fullWidth={fullWidth} maxWidth={maxWidth} open={open} onClose={handleClose}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  padding: '20px'
                }}
              >
                <Typography sx={{ color: '#008000' }}>
                  <VerifiedUserOutlinedIcon fontSize="large" />
                </Typography>
                <br />
                <Typography>Query has been raised successfully.</Typography>
                <Button
                  sx={{
                    mt: '20px'
                  }}
                  onClick={handleClose}
                  color="inherit"
                >
                  Click to dismiss
                </Button>
              </Box>
            </Dialog>
          </CardContent>
        </Card>
      </PaddedBox>
      <PaddedFooter
        style={{
          paddingLeft: `${
            !!props.auth && !!props.auth.isDrawerOpen && props.auth.isDrawerOpen == true
              ? '240px'
              : '0px'
          }`
        }}
      >
        <Footer />
      </PaddedFooter>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    profile: state.profile
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    resetProfileSuccess: (details) => dispatch(resetProfileSuccess(details)),
    setProfileDetails: (details) => dispatch(setProfileDetails(details))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RequestQuery);
