import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Header from '../HomePage/Header';
import Grid from '@mui/material/Grid';
import headimg from '../Contact/header.jpg';
import v1 from './v1.jpg';
import v2 from './v2.jpg';
import v3 from './v3.jpg';
import v4 from './v4.jpg';
import v5 from './v5.jpg';
import values from './values.jpg';
import collab from './colaboration.jpg';
import './about.css';
import FooterAfterSignIn from '../footer1/FooterAfterSignIn';

const HomePage = () => {
  return (
    <React.Fragment>

      <Header />
      <div className='aboutUs-container'>

      <div className="heading-img">
        <div className='abt-pic'>
        <img src={headimg} alt=""  />
        </div>
        <div className='abt-cont'>
        <h1>ABOUT US</h1>
        <hr />
        <h4>
          “Science can amuse and fascinate us all, but it is engineering that changes the world.”{' '}
          <br />
          Isaac Asimov
        </h4>
        </div>
      </div>

      <Box
        sx={{
          width: { xs: '100%', md: '100%' },
          marginTop:"6%",
          position: 'relative',
          overflow: "hidden",
          pb: '100px'
        }}
      >
        <span className="RightArtwork"></span>
        <Box
          sx={{
            width: { xs: '90%', md: '90%' },
            margin: { xs: 'auto' },
            display: 'flex',
            'align-items': { xs: 'flex-start', md: 'center' },
            'justify-content': 'center'
          }}
        >
          <Grid
            container
            maxWidth="lg"
            rowSpacing={5}
            columnSpacing={10}
            sx={{
              'align-items': 'center',
              'justify-content': 'center',
              xs: { display: 'block' },
              md: { display: 'flex'},
            }}
          >

            <Grid item lg={5} xs={12}>
              <div className='collab'>
              <img src={collab}/>
              </div> 
            </Grid>

            <Grid item lg={6} xs={12}>
            <Typography
                sx={{
                  fontSize: { xs: '12px', md: '20px' },
                  fontWeight: '400',
                  color: { xs: '#7a675f', md: '#7a675f' },
                  mb: { xs: '20px', md: '10px' },
                  textAlign: 'justify'
                }}
              >
              
                Gig Engineer is a team of passionate changemakers aiming to disrupt the traditional
                way engineering projects are done. At a time when change is the only constant, we
                see Gig Engineer as the new workplace for engineers and businesses to connect,
                engage and collaborate on exciting projects.

                
              </Typography>
              <Typography
                sx={{
                  fontSize: { xs: '12px', md: '20px' },
                  fontWeight: '400',
                  color: { xs: '#7a675f', md: '#7a675f' },
                  mb: { xs: '20px', md: '10px' },
                  textAlign: 'justify'
                }}
              >
                We connect leading businesses with talented engineers allowing both sides to enjoy
                the benefits and power of the gig economy. We help businesses to become more agile
                and efficient by reducing search costs and underutilised resources. For engineers,
                we offer the freedom and flexibility to decide which projects you want to work on
                and when.
              </Typography>
            </Grid>

          </Grid>
        </Box>
      </Box>

      <div className="values">
        <div className="value-head">
          <h1>Welcome to the new norm for engineering work!</h1>
        </div>
        <div className="blueback">
          <h1>
            Our Values
            <hr />
          </h1>
        </div>
        <div>
          <img src={values} alt="" width={'100%'} />
        </div>
      </div>


      <Box
        sx={{
          width: { xs: '100%', md: '100%' },
          margin: { xs: 'auto' },
          position: 'relative',
          pb: '100px'
        }}
      >
        <span className="RightDotArtwork"></span>
        <Box
          sx={{
            width: { xs: '90%', md: '70%' },
            margin: { xs: 'auto' },
            display: 'flex',
            'align-items': { xs: 'flex-start', md: 'center' },
            'justify-content': 'center'
          }}
        >
          <Grid
            container
            maxWidth="xl"
            rowSpacing={0}
            columnSpacing={10}
            sx={{
              'align-items': 'center',
              'justify-content': 'center',
              xs: { display: 'block' },
              md: { display: 'flex' }
            }}
          >
            <Grid item md={7} xs={12}>
              <Typography
                sx={{
                  fontSize: { xs: '16px', md: '28px' },
                  fontWeight: '600',
                  lineHeight: '1',
                  color: { xs: '#0140AA', md: '#0140AA' },
                  mt: '5px',
                  mb: { xs: '20px', md: '20px' },
                  textAlign: 'left'
                }}
              >
                Passion is what drives us
              </Typography>
              <Typography
                sx={{
                  fontSize: { xs: '12px', md: '18px' },
                  fontWeight: '500',
                  color: { xs: '#3d3d3d', md: '#3d3d3d' },
                  mb: { xs: '20px', md: '10px' },
                  textAlign: 'left'
                }}
              >
                We are passionate about what we do. Our company was founded by engineers for
                engineers. Beyond just a platform, we are deeply vested in the success of our users
                both personally and professionally.
              </Typography>
            </Grid>
            <Grid item md={5} xs={12}>
              <div id='side-img'>
              <img src={v1} />
              </div>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Box
        sx={{
          width: { xs: '100%', md: '100%' },
          marginTop : { xs: '-50px' , md :"auto"},
          position: 'relative',
          pb: '100px'
        }}
      >
        <span className="LeftDotArtwork"></span>
        <Box
          sx={{
            width: { xs: '90%', md: '70%' },
            margin: { xs: 'auto' },
            "display": 'flex',
            'align-items': { xs: 'flex-start', md: 'center' },
            'justify-content': 'center',
          }}
        >
          <Grid
            container
            maxWidth="xl"
            spacing={0}
            sx={{
              'align-items': 'center',
              'justify-content': 'center',
              flexDirection: { xs: "column-reverse",md:"row"},
              xs: { display: 'flex' },
              md: { display: 'flex' }
            }}
          >
            <Grid item md={6} xs={12}>
            <div id='side-img'>
              <img src={v2} className="imgBorder"/>
              </div>
            </Grid>
            <Grid item md={6} xs={12}>
              <Typography
                sx={{
                  fontSize: { xs: '16px', md: '28px' },
                  fontWeight: '600',
                  lineHeight: '1',
                  color: { xs: '#0140AA', md: '#0140AA' },
                  mt: '5px',
                  mb: { xs: '20px', md: '20px' },
                  textAlign: 'left'
                }}
              >
                Impact Driven
              </Typography>
              <Typography
                sx={{
                  fontSize: { xs: '12px', md: '18px' },
                  fontWeight: '500',
                  color: { xs: '#3d3d3d', md: '#3d3d3d' },
                  mb: { xs: '20px', md: '10px' },
                  textAlign: 'left'
                }}
              >
                We are strongly results driven. Gig Engineer mitigates overhead costs, project
                resourcing risks and non-value-adding business processes by providing a more
                efficient way of working.
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Box
        sx={{
          width: { xs: '100%', md: '100%' },
          marginTop : { xs: '-50px' , md :"auto"},
          position: 'relative',
          pb: '100px'
        }}
      >
        <span className="RightDotArtwork"></span>
        <Box
          sx={{
            width: { xs: '90%', md: '70%' },
            margin: { xs: 'auto' },
            display: 'flex',
            'align-items': { xs: 'flex-start', md: 'center' },
            'justify-content': 'center'
          }}
        >
          <Grid
            container
            maxWidth="xl"
            rowSpacing={0}
            columnSpacing={10}
            sx={{
              'align-items': 'center',
              'justify-content': 'center',
              xs: { display: 'block' },
              md: { display: 'flex' }
            }}
          >
            <Grid item md={7} xs={12}>
              <Typography
                sx={{
                  fontSize: { xs: '16px', md: '28px' },
                  fontWeight: '600',
                  lineHeight: '1',
                  color: { xs: '#0140AA', md: '#0140AA' },
                  mt: '5px',
                  mb: { xs: '20px', md: '20px' },
                  textAlign: 'left'
                }}
              >
                Simplicity
              </Typography>
              <Typography
                sx={{
                  fontSize: { xs: '12px', md: '18px' },
                  fontWeight: '500',
                  color: { xs: '#3d3d3d', md: '#3d3d3d' },
                  mb: { xs: '20px', md: '10px' },
                  textAlign: 'left'
                }}
              >
                Our platform is designed to be easy to use, safe and secure. We simplify processes
                allowing engineers and businesses to focus on executing complex projects
                efficiently.
              </Typography>
            </Grid>
            <Grid item md={5} xs={12}>
            <div id='side-img'>
              <img src={v3} />
              </div>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Box
        sx={{
          width: { xs: '100%', md: '100%' },
          marginTop : { xs: '-50px' , md :"auto"},
          position: 'relative',
          pb: '100px'
        }}
      >
        <span className="LeftDotArtwork"></span>
        <Box
          sx={{
            width: { xs: '90%', md: '70%' },
            margin: { xs: 'auto' },
            display: 'flex',
            'align-items': { xs: 'flex-start', md: 'center' },
            'justify-content': 'center'
          }}
        >
          <Grid
            container
            maxWidth="xl"
            spacing={0}
            sx={{
              'align-items': 'center',
              'justify-content': 'center',
              flexDirection: { xs: "column-reverse",md:"row"},
              xs: { display: 'block' },
              md: { display: 'flex' }
            }}
          >
            <Grid item md={6} xs={12} >
            <div id='side-img'>
              <img src={v4} />
              </div>
            </Grid>
            <Grid item md={6} xs={12}>
              <Typography
                sx={{
                  fontSize: { xs: '16px', md: '28px' },
                  fontWeight: '600',
                  lineHeight: '1',
                  color: { xs: '#0140AA', md: '#0140AA' },
                  mt: '5px',
                  mb: { xs: '20px', md: '20px' },
                  textAlign: 'left'
                }}
              >
                Co-creation
              </Typography>
              <Typography
                sx={{
                  fontSize: { xs: '12px', md: '18px' },
                  fontWeight: '500',
                  color: { xs: '#3d3d3d', md: '#3d3d3d' },
                  mb: { xs: '20px', md: '10px' },
                  textAlign: 'left'
                }}
              >
                We work with our users to develop tailored, fit for purpose products and services.
                Our vision is a more engaged and connected engineering community.
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Box
        sx={{
          width: { xs: '100%', md: '100%' },
          marginTop : { xs: '-50px' , md :"auto"},
          position: 'relative',
          pb: '100px'
        }}
      >
        <span className="RightDotArtwork"></span>
        <Box
          sx={{
            width: { xs: '90%', md: '70%' },
            margin: { xs: 'auto' },
            display: 'flex',
            'align-items': { xs: 'flex-start', md: 'center' },
            'justify-content': 'center'
          }}
        >
          <Grid
            container
            maxWidth="xl"
            rowSpacing={0}
            columnSpacing={10} 
            sx={{
              'align-items': 'center',
              'justify-content': 'center',
              xs: { display: 'block' },
              md: { display: 'flex' }
            }}
          >
            <Grid item md={7} xs={12}>
              <Typography
                sx={{
                  fontSize: { xs: '16px', md: '28px' },
                  fontWeight: '600',
                  lineHeight: '1',
                  color: { xs: '#0140AA', md: '#0140AA' },
                  mt: '5px',
                  mb: { xs: '20px', md: '20px' },
                  textAlign: 'left'
                }}
              >
                Adapt or become obsolete
              </Typography>
              <Typography
                sx={{
                  fontSize: { xs: '12px', md: '18px' },
                  fontWeight: '500',
                  color: { xs: '#3d3d3d', md: '#3d3d3d' },
                  mb: { xs: '20px', md: '10px' },
                  textAlign: 'justify'
                }}
              >
                The future is a more adaptable, flexible, hybrid global workplace. Gig Engineer
                erases geographical boundaries and bureaucratic limitations, helping businesses
                become more agile.
              </Typography>
            </Grid>
            <Grid item md={5} xs={12}>
            <div id='side-img'>
              <img src={v5} className="imgBorder" />
              </div>
            </Grid>
          </Grid>
        </Box>
      </Box>

</div>
      <FooterAfterSignIn />

    </React.Fragment>
  );
};
export default HomePage;
