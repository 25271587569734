import { Button, Dialog, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react'

const signInDialog = (props) => {
  const [open, setOpen] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('sm');

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog fullWidth={fullWidth} maxWidth={maxWidth} open={open} onClose={handleClose}>
      <Box
        sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px', }}
      >
        <br />
        <Typography
          sx={{ textAlign: 'center' }}
        >
          By clicking confirm you agree to hire this applicant as per the terms of engagement.
          If you would like to attach any further company specific contractual terms and conditions you may do <br /> so via the chat function.
        </Typography>
        <Box
          sx={{
            display: 'flex',
            marginTop: '50px',
          }}
        >
          <Button
            variant="contained"
            className="checked-Process-btn"

            sx={{
              width: "140px",
              backgroundColor: '#0140AA',
              // cursor: 'not-allowed'
            }}
          >
            Proceed
          </Button>
          <Box
            sx={{
              marginLeft: '20px',
            }}                        >
            <Button
              variant="contained"
              className="popup-cancel"
              type="submit" sx={{
                width: '140px',
              }}
              onClick={handleClose}
            >
              Cancel
            </Button>
          </Box>
        </Box>
        <br />
      </Box>
    </Dialog>
  )
}

export default signInDialog
