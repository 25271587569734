import styled from 'styled-components';
import { Grid, IconButton, Button } from '@mui/material';

export const SearchBarGid = styled(Grid)`
  && {
    .inputRounded .MuiOutlinedInput-root {
      border-radius: 50px;
      border-left: 0px;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
      padding-right: 0px;
      width: 400px;
      @media only screen and (min-width: 310px) and (max-width: 321px) {
        width: 45vw;
      }
      @media only screen and (min-width: 321px) and (max-width: 400px) {
        width: 49vw;
      }
      @media only screen and (min-width: 400px) and (max-width: 500px) {
        width: 60vw;
      }
      @media only screen and (min-width: 500px) and (max-width: 768px) {
        width: 42vw;
      }
      @media only screen and (min-width: 400px) and (max-width: 500px) {
        width: 53vw;
      }
      @media only screen and (min-width: 500px) and (max-width: 768px) {
        width: 42vw;
      }
      @media only screen and (min-width: 400px) and (max-width: 500px) {
        width: 53vw;
      }
      @media only screen and (min-width: 500px) and (max-width: 768px) {
        width: 42vw;
      }
      @media only screen and (min-width: 400px) and (max-width: 500px) {
        width: 53vw;
      }
      @media only screen and (min-width: 500px) and (max-width: 768px) {
        width: 42vw;
      }
    }
  }
`;

export const SearchIconbtn = styled(IconButton)`
  && {
    border-radius: 0px;
    border-left: 0px;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    background-color: #f99600;
    svg {
      color: #fff5e6;
    }
    :hover {
      background-color: #f99600;
    }
  }
`;

export const FilterIconbtn = styled(IconButton)`
  && {
    svg {
      color: #7e84a3;
      font-size: 30px;
      @media only screen and (max-width: 425px) {
        font-size: 28px;
        margin-right: -4px;
      }
    }
  }
`;

export const ApplyBtn = styled(Button)`
  && {
    background-color: #f99600;
    box-shadow: 0px 2px 6px #00000029;
    border-radius: 4px;
    :hover {
      background-color: #f99600;
    }
  }
`;

export const CancelBtn = styled(Button)`
  && {
    color: #f99600;
    box-shadow: 0px 2px 6px #00000029;
    border-radius: 4px;
    text-transform: none;
    :hover {
      border-color: #f99600;
    }
  }
`;
