import * as React from 'react';
import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import headimg from "./header.jpg"
import emailimg from "./email.png"
import Header from '../HomePage/Header';
import Footer from '../footer1/footer';
import { AboutusWrapper, AboutusFormWrapper, FormBox, AboutusAddressWrap, AboutusAddressGrid, AboutusAddressItem } from './contact.styles';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import RadioGroup from '@mui/material/RadioGroup';
import { useHistory } from 'react-router-dom';
import { Dialog } from '@mui/material';
import { positions } from '@mui/system';
import style from "./contact.css"
import FooterAfterSignIn from '../footer1/FooterAfterSignIn';
import { QueryContactUs } from '../../services/gig';

const isValidEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

const Contact = () => {

  const [open, setOpen] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('sm');
  const his = useHistory();

  const [email, setEmail] = useState('');
  const [emailMsg, setEmailMsg] = useState('');
  const [query, setQuery] = useState("");
  const [queryMsg, setQueryMsg] = useState("");
  const [first, setFirst] = useState("");
  const [firstMsg, setFirstMsg] = useState("");
  const [last, setLast] = useState("");
  const [lastMsg, setLastMsg] = useState("");
  const [radioBtn, setRadioBtn] = useState("");
  const [radioBtnMsg, setRadioBtnMsg] = useState("");
  const [stateForHeader, setStateForHeader] = useState(null);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      setStateForHeader(false);
    });
  }, []);

  const isValidEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const submitForm = async () => {
    let validate = true;
    if (first === "" || first === null) {
      validate = false;
      setFirstMsg("Please enter the Firstname!");
    } else {
      setFirstMsg("");
    }
    if (last === "" || last === null) {
      validate = false;
      setLastMsg("Please enter the Lastname!");
    } else {
      setLastMsg("");
    }
    if (email === '' || email === null) {
      validate = false;
      setEmailMsg('Please enter the email!');
    } else {
      let isEmailValid = isValidEmail(email);
      if (isEmailValid) {
        setEmailMsg('');
      } else {
        validate = false;
        setEmailMsg('Enter Valid email!');
      }
    }

    if (query === "" || query === null) {
      validate = false;
      setQueryMsg("Please enter the query!");
    } else {
      setQueryMsg("");
    }

    if (validate) {
      let data = {
        firstname: first,
        lastname: last,
        emailId: email,
        description: query,
        hearus: radioBtn,
      }

      QueryContactUs(data).then((e)=>{
        setOpen(true);
        setFirst("");
        setLast("");
        setEmail("");
        setQuery("");
        setRadioBtn("");
        setStateForHeader(true);
      })
    }
  };

  const handleClose = () => {
    setOpen(false);
    setStateForHeader(true);
  };

  return (
    <React.Fragment>
      <Header stateForHeader={stateForHeader} />
      <div className='contactUS-container' >

        <AboutusWrapper>

          <div className='contact-img-container'>
            <div className='cnt-pic'>
              <img src={headimg} alt="" />
            </div>
            <div className='cntct-cont'>
              <h1>CONTACT US</h1>
              <hr />
              <h4>
                Get in touch, we'd love to hear from you! <br />
                We're available around the clock to answer any questions you have.
              </h4>
            </div>
          </div>
          <AboutusFormWrapper>
            <Container>
              <FormBox >
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      autoComplete="true"
                      name="firstName"
                      required
                      fullWidth
                      label="First Name"
                      type="text"
                      autoFocus
                      value={first}
                      onChange={(e) => {
                        e.preventDefault();
                        setFirst(e.target.value);
                        setFirstMsg("");
                        setStateForHeader(true);
                      }
                      }
                      error={firstMsg !== ""}
                      helperText={firstMsg !== "" ? firstMsg : ""}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      fullWidth
                      label="Last Name"
                      name="lastName"
                      type="text"
                      autoComplete="true"
                      value={last}
                      onChange={(e) => {
                        e.preventDefault();
                        setLast(e.target.value);
                        setLastMsg("");
                        setStateForHeader(true);
                      }
                      }
                      error={lastMsg !== ""}
                      helperText={lastMsg !== "" ? lastMsg : ""}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      label="Email Address"
                      name="email"
                      autoComplete="true"
                      type="email"
                      value={email}
                      onChange={(e) => {
                        e.preventDefault();
                        setEmail(e.target.value);
                        setEmailMsg('');
                        setStateForHeader(true);
                      }}
                      error={emailMsg !== ''}
                      helperText={emailMsg !== '' ? emailMsg : ''}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      name="query"
                      label="Query"
                      type="text"
                      autoComplete="new-password"
                      multiline
                      rows={2}
                      value={query}
                      onChange={(e) => {
                        e.preventDefault();
                        setQuery(e.target.value);
                        setQueryMsg("");
                        setStateForHeader(true);
                      }
                      }
                      error={queryMsg !== ""}
                      helperText={queryMsg !== "" ? queryMsg : ""}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle2" gutterBottom component="div"
                    sx={{
                      // color: '#757575',
                      mt: '3%',
                    }}
                  >
                    <Grid item xs={12} sx={{ fontWeight: "bold", }}>
                      <Grid item xs={12} sx={{ fontWeight: "bold", }}>
                        Where did you hear about us ?
                      </Grid>
                    </Grid>
                  </Typography>
                  <RadioGroup
                    column="true"
                    aria-labelledby="demo-form-control-label-placement"
                    name="position"
                    defaultValue="top"
                    value={radioBtn}
                    onChange={(e) => {
                      e.preventDefault();
                      setRadioBtn(e.target.value);
                      setStateForHeader(true);
                    }
                    }
                  >
                    <FormControlLabel value="Google / Search Engine" control={<Radio />} label="Google / Search Engine" />
                    <FormControlLabel value="Social Media" control={<Radio />} label="Social Media" />
                    <FormControlLabel value="Recommended by friend or colleague" control={<Radio />} label="Recommended by friend or colleague" />
                    <FormControlLabel value="Blog or publication" control={<Radio />} label="Blog or publication" />
                  </RadioGroup>
                </Grid>
                <Button
                  type="submit"
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  onClick={submitForm}
                >
                  SUBMIT
                </Button>
              </FormBox>
            </Container>
          </AboutusFormWrapper>
          <AboutusAddressWrap>
            <AboutusAddressGrid>
              <div className='Contact-email-logo'>
              <a href="mailto:info@gigengineer.io"><img src={emailimg} alt="" width="75px" /></a>
              </div>
              <AboutusAddressItem>
                <a href="mailto:info@gigengineer.io">info@gigengineer.io</a>
              </AboutusAddressItem>
            </AboutusAddressGrid>
          </AboutusAddressWrap>
        </AboutusWrapper>

        <Dialog fullWidth={fullWidth} maxWidth={maxWidth} open={open} onClose={handleClose}>
          <Box
            sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px', }}
          >
            <br />
            <Typography sx={{ textAlign: 'center' }}>
              Thanks for getting in touch. We will respond as soon as possible to your request.
            </Typography>
            <Box
              sx={{
                marginLeft: '20px',
                marginTop: '20px'
              }}                        >
              <Button
                variant="contained"
                className="popup-cancel"
                type="submit" sx={{
                  width: '140px',
                  // backgroundColor:' #FF8008 ',
                }}
                onClick={handleClose}
              >
                Close
              </Button>
            </Box>
          </Box>
        </Dialog>

      </div>

      <FooterAfterSignIn />
    </React.Fragment>
  );
};
export default Contact;
