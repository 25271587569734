import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { Grid } from '@mui/material';
import { PaddedBox, PaddedFooter } from '../../app.styles';
import GridTable from '../../../components/GridTable/GridTable';
import apiService from '../../../services/apiServices';
import _last from 'lodash/last';
import _toNumber from 'lodash/toNumber';
import _get from 'lodash/get';
import { formatDate, getGigLocation, getHireLocation } from '../../../utils/helpers';
import Typography from '@mui/material/Box';
import { connect } from 'react-redux';
import GridPastCurrent from '../../../components/GridTable/GridPastCurrent';
import './GigDashboard.css';
import { getCurrentGig } from '../../../services/gig';
import _isEmpty from 'lodash/isEmpty';
import Footer from '../../../components/footer1/footer';
import PageLoader from '../../../components/Loaders/PageLoader';

const Dashboard = (props) => {
  const history = useHistory();
  const [hireGigs, setHireGigs] = useState([]);
  const [hireRecords, setHireRecords] = useState(false);
  const [activeGigs, setActiveGigs] = useState([]);
  const [activeRecords, setActiveRecords] = useState(false);
  const [inactiveGigs, setInactiveGigs] = useState([]);
  const [inactiveRecords, setInactiveRecords] = useState(false);
  const [closedGigs, setClosedGigs] = useState([]);
  const [closedRecords, setClosedRecords] = useState(false);

  const fetchGigs = (type) => {
    const obj = {
      paginationInput: {
        pageNumber: 1,
        pageSize: 3
      },
      status: [type]
    };
    const request = {
      data: obj,
      method: 'post',
      url: '/gig/all'
    };
    return apiService(request);
  };

  const createData = (name, duration, rate, hours, location, id) => {
    if (rate === "") {
      rate = "Not Disclosed";
    }
    return [name, duration, rate, hours, location, id];
  };

  const headers = ['GIG NAME', 'APPLICATIONS', 'RATE', 'HOURS', 'LOCATION'];
  const myHire = ['ENGINEER NAME', 'GIG NAME', 'START DATE', 'END DATE', 'LOCATION'];

  const setData = (data, setGigs, setRecords) => {
    const rowData = [];
    const gigList = _get(data, 'gigList', []);
    const totalRecords = _get(data, 'paginationResult.totalRecords', 0);
    for (let i = 0; i < gigList.length; i++) {
      const gig = gigList[i];
      const name = _get(gig, 'titleAndSkills.projectTitle', '');
      const duration = _get(gig, 'applicantCount', 0);
      const rate = _get(gig, 'scopeAndBudget.hourlyRate', '') ? `${_get(gig, 'scopeAndBudget.currency', 'R')} ${_get(gig, 'scopeAndBudget.hourlyRate', '')} /hr` : '-';
      const hours = _get(gig, 'scopeAndBudget.availabilityHoursPerWeek', '') ? `${_get(gig, 'scopeAndBudget.availabilityHoursPerWeek', '')} hrs/week` : '-';
      const location = getGigLocation(gig.deliverablesAndLocation, gig.isRemote);
      const id = _get(gig, '_id', '');

      rowData.push(createData(name, duration, rate, hours, location, { id, name }));
    }
    setGigs(rowData);
    if (totalRecords > 3) {
      setRecords(true);
    }
  };

  const setHireData = (data, setHireGigs, setRecords) => {
    const rowData = [];
    const hireList = _get(data, 'hiredetailsList', []);
    const totalRecords = _get(data, 'paginationResult.totalRecords', 0);
    if (hireList && hireList.length > 0) {
      for (let obj of hireList) {
        const engineerName = _get(obj, 'engineerName', '');
        const gigName = _get(obj, 'gigName', '');
        const userId = _get(obj, 'userId', '');
        const gigId = _get(obj, 'gigId', '');
        const fromDate = _get(obj, 'fromDate', '') ? formatDate(_get(obj, 'fromDate', '')) : '-';
        let location = getHireLocation(obj.hireDetails, obj.isRemote);
        const toDate = '-';
        // const id = _get(obj, '_id', '');
        rowData.push([engineerName, gigName, fromDate, toDate, location, gigId, userId]);
      }
    }
    setHireGigs(rowData);
    if (totalRecords > 3) {
      setRecords(true);
    }
  };

  const redirectToActiveGigs = () => {
    history.push('/gig/active');
  };

  const redirectToInactiveGigs = () => {
    history.push('/gig/inactive');
  };

  const redirectToHireGigs = () => {
    history.push('/hires/currenthires');
  };

  const redirectToClosedGigs = () => {
    history.push('/gig/closed');
  };

  const redirectToPostNewGig = () => {
    history.push('/gig/new');
  };


  const handleRowhireClick = (obj, data) => {
    history.push(`/gig/hires/${_get(obj[5], '_id', ' ')}`, obj[5]);
  };

  const handleRhireClick = (data) => {
    const gigData = _last(data);
    history.push(`/engineer/details/${gigData?.profileData?.basicInfo?.userId}`);
  };

  const handleRowactiveClick = (data) => {
    const gigData = _last(data);
    history.push(`/gig/active/applicants/${gigData.id}?gigname=${gigData.name}`);
  };

  const handleRactiveClick = (data) => {
    const gigData = _last(data);
    history.push(`/gig/active/${gigData.id}`);
  };

  const handleRowinactiveClick = (data) => {
    const gigData = _last(data);
    history.push(`/gig/inact/applicants/${gigData.id}?gigname=${gigData.name}`);
  };

  const handleRinactiveClick = (data) => {
    const gigData = _last(data);
    history.push(`/gig/inact/${gigData.id}`);
  };

  const handleRowclosedClick = (data) => {
    const gigData = _last(data);
    history.push(`/gig/closed/applicants/${gigData.id}?gigname=${gigData.name}`);
  };

  const handleRclosedClick = (data) => {
    const gigData = _last(data);
    history.push(`/gig/closed/${gigData.id}`);
  };

  useEffect(() => {
    axios
      .all([fetchGigs('ACTIVE'), fetchGigs('INACTIVE'), fetchGigs('CLOSED'), getCurrentGig({ paginationInput: { pageNumber: 1, pageSize: 3 } })])
      .then(
        axios.spread((...responses) => {
          const actGigs = responses[0];
          const inactGigs = responses[1];
          const clsGigs = responses[2];
          const hireGigs = responses[3];
          // use/access the results
          setData(actGigs.data, setActiveGigs, setActiveRecords);
          setData(inactGigs.data, setInactiveGigs, setInactiveRecords);
          setData(clsGigs.data, setClosedGigs, setClosedRecords);
          setHireData(hireGigs.data, setHireGigs, setHireRecords);
        })
      )
      .catch((errors) => {
        // react on errors.
      });
  }, []);

  return (
    <>
      <PaddedBox style={{ paddingLeft: `${!!props.auth && !!props.auth.isDrawerOpen && props.auth.isDrawerOpen == true ? "240px" : "0px"}` }}>
        <Grid container>
          <Typography
            className='font-fix'
            variant="h6"
            component="h6"
            color="pColor.main"
          >
            My Gigs
          </Typography>
          <Grid container className="table-Padding">
            <GridPastCurrent
              className="active-gigs-grid"
              headProp="My Hires"
              TableHeadCellWidth="25%"
              headers={myHire}
              rows={hireGigs}
              handleRowClick={handleRowhireClick}
              handleRClick={handleRhireClick}
              hidePagination={true}
              emptyMessage="You don't have any hires yet"
              viewAll={hireRecords ? redirectToHireGigs : undefined}
            ></GridPastCurrent>
          </Grid>
          <Grid container className="table-Padding-text">
            <GridTable
              className="active-gigs-grid"
              TableHeadCellWidth="25%"
              headProp="Active Gigs"
              headers={headers}
              rows={activeGigs}
              handleRowClick={handleRowactiveClick}
              handleRClick={handleRactiveClick}
              hidePagination={true}
              emptyMessage="You don’t have any Active Gigs, would you like to create one?"
              emptyBoxButtonText="Post a Gig"
              onEmptyBoxButtonClick={redirectToPostNewGig}
              viewAll={activeRecords ? redirectToActiveGigs : undefined}
            ></GridTable>
          </Grid>
          <Grid container>
            <Grid container>
              <Grid container className="table-Padding">
                <GridTable
                  className="inactive-gigs-grid"
                  headProp="Inactive Gigs"
                  headers={headers}
                  rows={inactiveGigs}
                  handleRowClick={handleRowinactiveClick}
                  handleRClick={handleRinactiveClick}
                  hidePagination={true}
                  emptyMessage="You don’t have any Inactive Gigs"
                  viewAll={inactiveRecords ? redirectToInactiveGigs : undefined}
                ></GridTable>
              </Grid>
            </Grid>
            <Grid container>
              <Grid container className="table-Padding">
                <GridTable
                  className="closed-gigs-grid"
                  headProp="Closed Gigs"
                  headers={headers}
                  rows={closedGigs}
                  handleRowClick={handleRowclosedClick}
                  handleRClick={handleRclosedClick}
                  hidePagination={true}
                  emptyMessage="You don’t have any Closed Gigs"
                  viewAll={closedRecords ? redirectToClosedGigs : undefined}
                ></GridTable>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </PaddedBox>
      <PaddedFooter style={{ paddingLeft: `${!!props.auth && !!props.auth.isDrawerOpen && props.auth.isDrawerOpen == true ? "240px" : "0px"}` }}>
        <Footer />
      </PaddedFooter>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};
export default connect(mapStateToProps)(Dashboard);
