import React, { useEffect, useState } from 'react';
// import Header from "./header/Header";
// import Footer from "./footer/Footer";
import { BrowserRouter as Router, Route, Switch, Redirect, useLocation, useHistory } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { routes } from '../routes/index.js';
import { GlobalDiv } from './app.styles';
import { connect } from 'react-redux';
import sideNavLinks from '../routes/sideNavLinks.js';
import { getUserType } from '../utils/user.js';
// import { loadUser } from "../actions/tokenAuth";
// import { logout } from "../actions/auth";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";

// import { useDispatch, useSelector } from "react-redux";
import _get from 'lodash/get';
import { resetProfileSuccess } from '../store/actions/profile.js';
import { setDrawerState } from '../store/actions/auth';
import PersistentDrawerLeft from './AuthLayout/AuthLayout';
import { getUserProfileRoute } from '../routes/authcheck.js';

export function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

const theme = createTheme({
  components: {
    // Name of the component
    MuiFormHelperText: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          fontSize: '12px'
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          fontSize: '14px'
        }
      }
    }
  },
  palette: {
    type: 'light',
    pColor: {
      main: '#0140AA'
    },
    sColor: {
      main: '#f99600'
    },
    text: {
      primary: '#392722',
      secondary: '#7E84A3'
    },
    primary: {
      main: '#7E84A3'
    }
  },
  typography: {
    // fontFamily: 'Work Sans',
    fontFamily: "'Poppins', sansSerif", // specifying a new font
    h3: {
      fontSize: '48px',
      fontWeight: 600
    },
    h5: {
      fontSize: '24px',
      fontWeight: 400
    },
    h6: {
      fontSize: '24px'
    },
    body1: {
      fontSize: '16px'
    },
    body2: {
      fontSize: '12px'
    },
    subtitle1: {
      fontSize: '18px'
    }
  }
});

const App = (props) => {
  const [drawerOpen, setdrawerOpen] = useState(true)
  const menuList = sideNavLinks(props.auth.menuType); // get the menu list to be showed on the left nav
  const userType = getUserType(); // get the user type from tokens
  // user type in tokens and in user type mentioned route should allways match
  const routeAuthorized = (props.auth.routeAuth && props.auth.routeAuth.indexOf(userType) >= 0);
  const history = useHistory();


  useEffect(() => {
    props.setDrawerState({ isDrawerOpen: drawerOpen })
  }, [drawerOpen])

  useEffect(() => {
    let params = new URLSearchParams(window.location.search)
    if (!!params && !!params.get("access_token")) {
      sessionStorage.setItem("access_token", params.get("access_token"))
    }
    if (!!params && !!params.get("refresh_token")) {
      sessionStorage.setItem("refresh_token", params.get("refresh_token"))
    }
    const userType = getUserType(); // get the user type from tokens
    const route = getUserProfileRoute(userType)
    if (!!params && !!params.get("access_token")) {
      window.location.href = `${window.location.origin}${route}`
    }
  }, [])


  return (
    <Router>
      <GlobalDiv className="App">
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <ScrollToTop />
          {
            routeAuthorized
            &&
            <PersistentDrawerLeft
              drawerOpen={drawerOpen}
              setdrawerOpen={setdrawerOpen}
              menuList={menuList}
              percentage={_get(props, 'profile.profileCompletionPercent', 0)}
              userType={userType}
              menuType={props.auth.menuType}
              resetProfileSuccess={props.resetProfileSuccess}
            >
            </PersistentDrawerLeft>
          }
          <Switch>
            {routes.map((route, index) => (
              <Route
                key={`route_${index}`}
                path={route.path}
                component={route.component}
                exact={route.exact}
              />
            ))}
            <Route render={() => <Redirect to="/" />} />
          </Switch>
          <ToastContainer
            position="bottom-right"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick={false}
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </ThemeProvider>
      </GlobalDiv>
    </Router>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    profile: state.profile
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    resetProfileSuccess: (details) => dispatch(resetProfileSuccess(details)),
    setDrawerState: (payload) => dispatch(setDrawerState(payload))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
