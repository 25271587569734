import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import { format } from 'date-fns';
import { Grid, Avatar, Button, Link } from '@mui/material';
import { getUserType } from '../../../../utils/user';
import { getProfileDetByIdApi } from '../../../../services/profile';
import PageLoader from '../../../../components/Loaders/PageLoader';
import { checkIfVerified, disableEnableBodyScroll, getQueryParams, scrollOnTop } from '../../../../utils/helpers';
import ProfileDetHeader from './ProfileDetHeader';
import { PaddedBox, PaddedFooter } from '../../../app.styles';
import Chip from '@mui/material/Chip';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import HourlyRate from '../../../../assests/hourlyRate.svg';
import LocationEngineer from '../../../../assests/locationEngineer.svg';
import DownLoad from '../../../../assests/downLoad.svg';
import ChatIconProfile from '../../../../assests/chatIconProfile.svg';
import { connect } from 'react-redux';
import Countrylogoengineer from '../../../../assests/countrylogoengineer.svg';
import './GigApplied.scss';
import Footer from '../../../../components/footer1/footer';
import VerifyProfile from '../../../../components/VerifyProfile/VerifyProfile';
import VerifiedIcon from '../../../../assests/VerifiedTick.svg'
import '../../../gigUser/gigdetails/gigDetailsAttributes.scss';
import ReactStars from "react-rating-stars-component";
import { verifyManualFeedbackApi } from '../../../../services/gig';
import VerifyRatingModal from './VerifyRating';
import { checkIfPublished } from '../../../../utils/dataGigs';
import RejectRatingModal from './RejectRating';
import PaymentsIcon from '@mui/icons-material/Payments';
const ReadMore = ({ children }) => {
  let text = children;
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  if (!text) {
    text = ' ';
  }

  return (
    <p className="see-more-text">
      {isReadMore ? (text.length > 385 ? text.slice(0, 385) + '.........' : text) : text}
      <br></br>
      {text.length >= 385 ? (
        <span onClick={toggleReadMore} className="read-or-hide">
          {isReadMore ? 'See more' : 'See less'}
        </span>
      ) : (
        ''
      )}
    </p>
  );
};

const ReadLessMore = ({ children }) => {
  const text = children;
  const [isReadMoreLess, setIsReadMoreLess] = useState(true);
  const toggleReadMoreLess = () => {
    setIsReadMoreLess(!isReadMoreLess);
  };

  return (
    <p className="see-more-text">
      {isReadMoreLess ? text.slice(0, 0) : text}
      {text.length >= 385 ? (
        <span onClick={toggleReadMoreLess} className="read-or-hide">
          {isReadMoreLess ? 'More' : 'Less'}
        </span>
      ) : (
        ''
      )}
    </p>
  );
};

const GigAppliedEngineer = (props) => {
  const drawerWidth = 240;
  const [profileDet, setProfileDet] = useState({});
  const [referralsData, setreferralsData] = useState(undefined);
  const [seeMore, setSeeMore] = useState(false);
  const [seeMoreEducation, setSeeMoreEducation] = useState(false);
  const userType = getUserType();
  const { id } = useParams();
  const history = useHistory();
  const query = getQueryParams();
  const redirectUrl = query.redirectUrl;
  const [value, setValue] = useState([]);
  const [textMore, setTextMore] = useState(false);
  const url = window.location.href;
  let vars = url.split('/');
  let cc = vars[5].split('?');
  const goBack = (e) => {
    redirectUrl ? history.push(decodeURIComponent(redirectUrl)) : history.goBack();
  };
  async function someasync() {
    const details = await getProfileDetByIdApi(id);
    if (details && details.profile.skillList) {
      for (let i = 0; i < details.profile.skillList.length; i++) {
        const { level } = details.profile.skillList[i];
        if (level.toLowerCase() === 'entry') {
          value.push(2);
        } else if (level.toLowerCase() === 'senior') {
          value.push(3.5);
        } else if (level.toLowerCase() === 'expert') {
          value.push(5);
        }
      }
    }
    setProfileDet(details.profile);
    if (!!details.referralsDetails) {
      setreferralsData(details.referralsDetails)
    }
  }

  useEffect(() => {
    someasync();
  }, []);

  const [currentUser, setcurrentUser] = useState()
  const handleVerifyAccount = () => {
    setcurrentUser(profileDet)
  }
  const handleCloseVerifyModal = () => {
    setcurrentUser(undefined);
    someasync();
  }

  function getAge(dateString) {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age =
      today.getFullYear() - birthDate.getFullYear()
        ? today.getFullYear() - birthDate.getFullYear()
        : 'Not Disclosed';
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }

  const handleVerifyReview = (review, index, flag) => {
    let tempReviewArr = JSON.parse(JSON.stringify(_get(profileDet, 'reviewsList')))
    tempReviewArr[index].isVerified = flag;
    let tempProfileObj = JSON.parse(JSON.stringify(profileDet))
    tempProfileObj.reviewsList = tempReviewArr
    // console.log(vars[vars.length-1])
    verifyManualFeedbackApi({
      reviewsList: tempReviewArr,
      userId: vars[vars.length - 1]
    })
    setProfileDet(tempProfileObj)
    handleCloseVerifyRatingModal()
    // console.log(profileDet, tempReviewArr)
  }

  const handleRejectReview = (review, index, flag) => {
    let tempReviewArr = JSON.parse(JSON.stringify(_get(profileDet, 'reviewsList')))
    tempReviewArr[index].isRejected = flag;
    let tempProfileObj = JSON.parse(JSON.stringify(profileDet))
    tempProfileObj.reviewsList = tempReviewArr
    // console.log(vars[vars.length-1])
    verifyManualFeedbackApi({
      reviewsList: tempReviewArr,
      userId: vars[vars.length - 1]
    })
    setProfileDet(tempProfileObj)
    handleCloseRejectRatingModal()
    // console.log(profileDet, tempReviewArr)
  }

  const [openVerifyModal, setopenVerifyModal] = useState(false)
  const [openRejectModal, setopenRejectModal] = useState(false)
  const [activeRating, setactiveRating] = useState(undefined)
  const [activeRatingIndex, setactiveRatingIndex] = useState(undefined)

  const handleCloseVerifyRatingModal = () => {
    setopenVerifyModal(false)
    setactiveRating(undefined)
    setactiveRatingIndex(undefined)
    disableEnableBodyScroll()
  }

  const handleCloseRejectRatingModal = () => {
    setopenRejectModal(false)
    setactiveRating(undefined)
    setactiveRatingIndex(undefined)
    disableEnableBodyScroll()
  }

  return _isEmpty(profileDet) ? (
    <div className='gig-details-page-loader'>
      <PageLoader />
    </div>
  ) : (
    <>
      <PaddedBox
        style={{
          paddingLeft: `${!!props.auth && !!props.auth.isDrawerOpen && props.auth.isDrawerOpen == true
            ? '240px'
            : '0px'
            }`, minHeight: "580px"
        }}
      >
        <Grid container item className="h-margin-bottom-16">
          <Button
            sx={{
              marginRight: '16px',
              color: '#0140AA',
              display: 'flex',
              alignItems: 'center',
              gap: '3px',
              fontSize: '12px',
              fontWeight: '600',
              textTransform: 'capitalize'
            }}
          >
            <div
              onClick={() => {
                goBack();
              }}
            >
              <ArrowBackOutlinedIcon sx={{ width: '15px', marginTop: '6px' }} />
            </div>
            <ProfileDetHeader goBack={goBack} profileDet={profileDet} handleVerifyAccount={handleVerifyAccount} someasync={someasync} />
          </Button>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={8} md={8}>
            <Card
              sx={{
                boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
                borderRadius: '10px',
                marginTop: '-16px'
              }}
            >
              <CardContent sx={{ padding: '20px' }}>
                {/* profile pic and details */}

                <Box sx={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={8} md={4}>
                      <Grid
                        container
                        spacing={2}
                        sx={{ alignItems: 'center', justifyContent: 'center' }}
                      >
                        <Grid
                          item
                          xs={12}
                          sm={8}
                          md={12}
                          sx={{ alignItems: 'center', justifyContent: 'center' }}
                        >
                          <Avatar
                            src={_get(profileDet, 'myProfile.profilePic', '')}
                            sx={{
                              width: 230,
                              height: 230,
                              objectFit: 'cover',
                              boxShadow: 'rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset',
                              backgroundColor: '#0543AB',
                              margin: 'auto'
                            }}
                          ></Avatar>
                        </Grid>
                        <Grid item xs={12} sm={8} md={12}>
                          <Box sx={{ marginTop: '10px', marginLeft: '20px' }}>
                            <Box
                              sx={{
                                display: 'flex',
                                textAlign: 'center',
                                fontSize: '14px',
                                marginBottom: '5px',
                                fontWeight: '700',
                                marginLeft: '-4px'
                              }}
                            >
                            
                              <PaymentsIcon sx={{color: '#0543AB', marginLeft: '3px'}} />

                              <Box sx={{ marginLeft: '10px', marginTop: ' 2px' }}>
                                {_get(profileDet, 'myProfile.currency', 'R')} {_get(profileDet, 'myProfile.hourlyRate', '0')} / hour
                              </Box>
                            </Box>
                            <Box sx={{ display: "flex", textAlign: "center", fontSize: "14px", marginTop: '12px', marginLeft: '-3px' }} title="Location">
                              <img src="https://gigengineer-production.s3.af-south-1.amazonaws.com/Assets/Profile+Display+City.svg" alt="loctionengineer" fontSize="medium" width="11%" />
                              <Box className='text-city-overflow'>
                              {(_get(profileDet, 'basicInfo.city', '')) ? (_get(profileDet, 'basicInfo.city', '')) : 'Not Disclosed'}
                              </Box>
                            </Box>
                            <Box sx={{ display: "flex", textAlign: "center", fontSize: "14px", marginTop: '12px', marginLeft: '-3px' }}>
                              <img src="https://gigengineer-production.s3.af-south-1.amazonaws.com/Assets/Profile+Display+Country.svg" alt="countrylogoengineer" fontSize="medium" width="11%" />
                              <Box sx={{ color: '#007FED', marginLeft: '8px', marginTop: '2px' }}>
                                {(_get(profileDet, 'basicInfo.country', '')) ? (_get(profileDet, 'basicInfo.country', '')) : 'Not Disclosed'}
                              </Box>
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                      {/* </Box> */}
                    </Grid>
                    <Grid item xs={12} sm={8} md={8}>
                      <Box>
                        {/* chat icon */}
                        <Box sx={{ textAlign: 'right' }}>
                          {userType === 'business' && (
                            <Link
                              title="Chat"
                              className="card-Link"
                              component="button"
                              variant="body2"
                              onClick={(event) => {
                                event.stopPropagation();
                                history.push(`/ochat?to=${cc[0]}`);
                              }}
                            >
                              <img
                                sx={{ color: '#0000FF' }}
                                src={ChatIconProfile}
                                alt="chaticon"
                                fontSize="medium"
                                width="80%"
                              />
                            </Link>
                          )}
                        </Box>
                        <div className='name-title-overview-align'>
                          <Box
                            sx={{
                              textAlign: 'left',
                              fontSize: '18.6px',
                              marginLeft: '20px',
                              lineHeight: '20px',
                              fontWeight: '600',
                              // marginTop: '-27px',
                              textTransform: 'capitalize',
                              fontFamily: 'Poppins',
                              fontStyle: 'normal',
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            {_get(profileDet, 'basicInfo.name')}
                            {
                              !!_get(profileDet, 'isVerified') && (_get(profileDet, 'profileCompletionPercent') == 100)
                              &&
                              <img className='verified-name-ico' src="https://gigengineer-production.s3.af-south-1.amazonaws.com/Assets/Blue+Tick+Verification.svg" />
                            }
                          </Box>
                          <Box
                            sx={{
                              fontSize: '15.5px',
                              fontWeight: '500',
                              color: '#818181',
                              textAlign: 'left',
                              lineHeight: '20px',
                              paddingBottom: '10px',
                              marginTop: '20px',
                              marginLeft: '20px',
                              width: 'auto'
                            }}
                          >
                            {_get(profileDet, 'myProfile.title')}
                          </Box>
                          {/* gender age country */}
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              textAlign: 'center',
                              alignItems: 'center',
                              marginTop: '20px',
                              marginLeft: '20px'
                            }}
                          >
                            <Box
                              sx={{
                                fontSize: '12.4px',
                                fontWeight: '700',
                                lineHeight: '1',
                                display: 'flex',
                                color: '#0543AB',
                                justifyContent: 'center'
                              }}
                            >
                              Gender
                              <Box
                                sx={{
                                  fontSize: '12.4px',
                                  fontWeight: '500',
                                  lineHeight: '1',
                                  color: '#0E1724',
                                  marginLeft: '5px',
                                  marginTop: '0px'
                                }}
                              >
                                {_get(profileDet, 'basicInfo.gender', 'Not Disclosed')}
                              </Box>
                            </Box>
                            <Box
                              sx={{
                                fontSize: '12.4px',
                                fontWeight: '700',
                                lineHeight: '1',
                                marginLeft: '80px',
                                display: 'flex',
                                color: '#0543AB'
                              }}
                            >
                              Age
                              <Box
                                sx={{
                                  fontSize: '12.4px',
                                  fontWeight: '500',
                                  lineHeight: '1',
                                  color: '#0E1724',
                                  marginLeft: '5px',
                                  marginTop: '0px'
                                }}
                              >
                                {getAge(_get(profileDet, 'basicInfo.dob', 'Not Disclosed'))}
                              </Box>
                            </Box>
                          </Box>
                          <Box>
                            <CardContent sx={{ textOverflow: 'ellipsis' }}>
                              <Box
                                sx={{
                                  fontSize: '15.6px',
                                  fontWeight: '500',
                                  marginTop: '20px',
                                  marginLeft: '5px'
                                }}
                              >
                                <div className='read-overview' dangerouslySetInnerHTML={{ __html: _get(profileDet, 'myProfile.overview', '-') }}></div>
                                {/* <ReadMore>{_get(profileDet, 'myProfile.overview')}</ReadMore> */}
                              </Box>
                            </CardContent>
                          </Box>
                        </div>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </CardContent>
            </Card>

            {_get(profileDet, 'employmentHistoryList').length === 0
              ? ''
              : !_isEmpty(_get(profileDet, 'employmentHistoryList')).length > 0 && (
                <Card
                  sx={{
                    boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
                    borderRadius: '10px',
                    marginTop: '30px'
                  }}
                >
                  <CardContent sx={{ padding: '20px' }}>
                    <Box
                      sx={{
                        fontSize: '18.6px',
                        lineHeight: '35px',
                        fontWeight: '700',
                        color: '#000000',
                        textTransform: 'capitalize'
                      }}
                    >
                      Employment History
                    </Box>
                    <Divider
                      sx={{
                        borderColor: 'rgba(0, 0, 0, 0.2)',
                        marginTop: '13px',
                        marginBottom: '9px'
                      }}
                    />
                    {_get(profileDet, 'employmentHistoryList').length > 0 && seeMore === false ? (
                      <>
                        {_get(profileDet, 'employmentHistoryList').map((emp, index) =>
                          index <= 1 ? (
                            <React.Fragment>
                              <Box
                                key={`emp${index}`}
                                sx={{
                                  display: 'flex',
                                  alignItems: 'start',
                                  gap: '10px',
                                  paddingTop: '10px'
                                }}
                              >
                                <Grid item xs={12} md={12} lg={12}>
                                  <Box>
                                    <Box
                                      sx={{
                                        fontSize: '15.5px',
                                        fontWeight: '500',
                                        color: '#000000'
                                      }}
                                    >
                                      {_get(emp, 'designation')}
                                    </Box>
                                    <Box
                                      sx={{
                                        fontSize: '15.5px',
                                        fontWeight: '500',
                                        color: '#818181'
                                      }}
                                    >
                                      {_get(emp, 'companyName')}
                                    </Box>
                                    <Box
                                      sx={{
                                        fontSize: '15.5px',
                                        color: '#818181',
                                        display: 'flex',
                                        alignItems: 'center'
                                      }}
                                    >
                                      {
                                        !!(_get(emp, 'fromDate')) && (
                                          (format(new Date(_get(emp, 'fromDate')), 'MMM, yyyy'))
                                        )
                                      }&nbsp;-&nbsp;
                                      {
                                        !!(_get(emp, 'toDate')) ? (
                                          (format(new Date(_get(emp, 'toDate')), 'MMM, yyyy'))
                                        ) : 'Currently Working'
                                      }
                                    </Box>
                                    <Box sx={{ fontSize: '15.5px', color: '#818181' }}>
                                      {_get(emp, 'country')}
                                    </Box>
                                    <Box sx={{ fontSize: '15.5px', color: '#818181' }}>
                                      <ReadLessMore>{_get(emp, 'description')}</ReadLessMore>
                                    </Box>
                                    <Box sx={{ fontSize: '15.5px', color: '#818181' }}>
                                      {_get(emp, 'achievement')}
                                    </Box>
                                  </Box>
                                </Grid>
                              </Box>
                              {index != _get(profileDet, 'employmentHistoryList').length - 1 && (
                                <Divider
                                  sx={{ borderColor: 'rgba(0, 0, 0, 0.2)', marginTop: '15px' }}
                                />
                              )}
                            </React.Fragment>
                          ) : (
                            ''
                          )
                        )}
                        {_get(profileDet, 'employmentHistoryList').length > 2 ? (
                          <Box>
                            <Button
                              onClick={() => setSeeMore(true)}
                              sx={{
                                color: '#1976D2',
                                textTransform: 'capitalize',
                                fontSize: '15.5px',
                                marginLeft: '-9px',
                                marginTop: '20px'
                              }}
                            >
                              See more({_get(profileDet, 'employmentHistoryList').length - 2})
                            </Button>
                          </Box>
                        ) : (
                          ''
                        )}
                      </>
                    ) : (
                      ''
                    )}

                    {_get(profileDet, 'employmentHistoryList').length > 0 && seeMore === true ? (
                      <>
                        {_get(profileDet, 'employmentHistoryList').map((emp, index) => (
                          <React.Fragment>
                            <Box
                              key={`emp${index}`}
                              sx={{
                                display: 'flex',
                                alignItems: 'start',
                                gap: '10px',
                                paddingTop: '10px'
                              }}
                            >
                              <Grid item xs={12} md={12} lg={12}>
                                <Box>
                                  <Box
                                    sx={{ fontSize: '15.5px', fontWeight: '500', color: '#000000' }}
                                  >
                                    {_get(emp, 'designation')}
                                  </Box>
                                  <Box
                                    sx={{ fontSize: '15.5px', fontWeight: '500', color: '#818181' }}
                                  >
                                    {_get(emp, 'companyName')}
                                  </Box>
                                  <Box
                                    sx={{
                                      fontSize: '15.5px',
                                      color: '#818181',
                                      display: 'flex',
                                      alignItems: 'center'
                                    }}
                                  >
                                    {
                                      !!(_get(emp, 'fromDate')) && (
                                        (format(new Date(_get(emp, 'fromDate')), 'MMM, yyyy'))
                                      )
                                    }&nbsp;-&nbsp;
                                    {
                                      !!(_get(emp, 'toDate')) ? (
                                        (format(new Date(_get(emp, 'toDate')), 'MMM, yyyy'))
                                      ) : 'Currently Working'
                                    }
                                  </Box>
                                  <Box sx={{ fontSize: '15.5px', color: '#818181' }}>
                                    {_get(emp, 'country')}
                                  </Box>
                                  <Box sx={{ fontSize: '15.5px', color: '#818181' }}>
                                    <ReadLessMore>{_get(emp, 'description')}</ReadLessMore>
                                  </Box>
                                  <Box sx={{ fontSize: '15.5px', color: '#818181' }}>
                                    {_get(emp, 'achievement')}
                                  </Box>
                                </Box>
                              </Grid>
                            </Box>
                            {index != _get(profileDet, 'employmentHistoryList').length - 1 && (
                              <Divider
                                sx={{ borderColor: 'rgba(0, 0, 0, 0.2)', marginTop: '15px' }}
                              />
                            )}
                          </React.Fragment>
                        ))}
                        <Divider sx={{ borderColor: 'rgba(0, 0, 0, 0.2)', marginTop: '15px' }} />
                        <Box>
                          <Button
                            onClick={() => setSeeMore(false)}
                            sx={{
                              color: '#1976D2',
                              textTransform: 'capitalize',
                              fontSize: '15.5px',
                              marginLeft: '-9px',
                              marginTop: '20px'
                            }}
                          >
                            See Less
                          </Button>
                        </Box>
                      </>
                    ) : (
                      ''
                    )}
                  </CardContent>
                </Card>
              )}

            {!_isEmpty(_get(profileDet, 'educationInfoList')) && (
              <Card
                sx={{
                  boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
                  borderRadius: '10px',
                  marginTop: '30px'
                }}
              >
                <CardContent sx={{ padding: '20px' }}>
                  <Box
                    sx={{
                      fontSize: '18.6px',
                      lineHeight: '35px',
                      fontWeight: '600',
                      color: '#000000',
                      textTransform: 'uppercase',
                      textTransform: 'capitalize'
                    }}
                  >
                    Education History
                  </Box>
                  <Divider
                    sx={{ borderColor: 'rgba(0, 0, 0, 0.2)', marginTop: '13px', marginBottom: '9px' }}
                  />
                  {_get(profileDet, 'educationInfoList').length > 0 && seeMoreEducation === false ? (
                    <>
                      {_get(profileDet, 'educationInfoList').map((edu, index) =>
                        index <= 1 ? (
                          <React.Fragment>
                            <Box
                              key={`edu${index}`}
                              sx={{
                                display: 'flex',
                                alignItems: 'start',
                                gap: '10px',
                                paddingTop: '10px'
                              }}
                            >
                              <Box>
                                <Box sx={{ fontSize: '15.5px', fontWeight: '500' }}>
                                  {_get(edu, 'instituteName')}
                                </Box>
                                <Box sx={{ fontSize: '15.5px', fontWeight: '500', color: '#818181' }}>
                                  {_get(edu, 'degree')}
                                </Box>
                                <Box sx={{ fontSize: '15.5px', color: '#818181' }}>
                                  {_get(edu, 'areaOfSpecialization')}
                                </Box>
                                <Box
                                  sx={{
                                    fontSize: '15.5px',
                                    color: '#818181',
                                    display: 'flex',
                                    alignItems: 'center'
                                  }}
                                >
                                  {
                                    !!(_get(edu, 'fromDate')) && (
                                      (format(new Date(_get(edu, 'fromDate')), 'MMM, yyyy'))
                                    )
                                  }&nbsp;-&nbsp;
                                  {
                                    !!(_get(edu, 'toDate')) ? (
                                      (format(new Date(_get(edu, 'toDate')), 'MMM, yyyy'))
                                    ) : 'Currently Working'
                                  }
                                </Box>
                                {
                                  <Box sx={{ fontSize: '15.5px', color: '#818181' }}>
                                    <ReadLessMore>{_get(edu, 'description')}</ReadLessMore>
                                  </Box>
                                }
                              </Box>
                            </Box>
                            {index != _get(profileDet, 'educationInfoList').length - 1 && (
                              <Divider
                                sx={{ borderColor: 'rgba(0, 0, 0, 0.2)', marginTop: '15px' }}
                              />
                            )}
                          </React.Fragment>
                        ) : (
                          ''
                        )
                      )}
                      {_get(profileDet, 'educationInfoList').length > 2 ? (
                        <Box>
                          <Button
                            onClick={() => setSeeMoreEducation(true)}
                            sx={{
                              color: '#1976D2',
                              textTransform: 'capitalize',
                              fontSize: '15.5px',
                              marginLeft: '-9px',
                              marginTop: '20px'
                            }}
                          >
                            See more({_get(profileDet, 'educationInfoList').length - 2})
                          </Button>
                        </Box>
                      ) : (
                        ''
                      )}
                    </>
                  ) : (
                    ''
                  )}
                  {_get(profileDet, 'educationInfoList').length > 0 && seeMoreEducation === true ? (
                    <>
                      {_get(profileDet, 'educationInfoList').map((edu, index) => (
                        <React.Fragment>
                          <Box
                            key={`edu${index}`}
                            sx={{
                              display: 'flex',
                              alignItems: 'start',
                              gap: '10px',
                              paddingTop: '10px'
                            }}
                          >
                            <Box>
                              <Box sx={{ fontSize: '15.5px', fontWeight: '500' }}>
                                {_get(edu, 'instituteName')}
                              </Box>
                              <Box sx={{ fontSize: '15.5px', fontWeight: '500', color: '#818181' }}>
                                {_get(edu, 'degree')}
                              </Box>
                              <Box sx={{ fontSize: '15.5px', color: '#818181' }}>
                                {_get(edu, 'areaOfSpecialization')}
                              </Box>
                              <Box
                                sx={{
                                  fontSize: '15.5px',
                                  color: '#818181',
                                  display: 'flex',
                                  alignItems: 'center'
                                }}
                              >
                                {
                                  !!(_get(edu, 'fromDate')) && (
                                    (format(new Date(_get(edu, 'fromDate')), 'MMM, yyyy'))
                                  )
                                }&nbsp;-&nbsp;
                                {
                                  !!(_get(edu, 'toDate')) ? (
                                    (format(new Date(_get(edu, 'toDate')), 'MMM, yyyy'))
                                  ) : 'Currently Working'
                                }
                              </Box>
                              {
                                <Box sx={{ fontSize: '15.5px', color: '#818181' }}>
                                  <ReadLessMore>{_get(edu, 'description')}</ReadLessMore>
                                </Box>
                              }
                            </Box>
                          </Box>
                          {index != _get(profileDet, 'educationInfoList').length - 1 && (
                            <Divider sx={{ borderColor: 'rgba(0, 0, 0, 0.2)', marginTop: '15px' }} />
                          )}
                        </React.Fragment>
                      ))}
                      <Divider sx={{ borderColor: 'rgba(0, 0, 0, 0.2)', marginTop: '15px' }} />
                      <Box>
                        <Button
                          onClick={() => setSeeMoreEducation(false)}
                          sx={{
                            color: '#1976D2',
                            textTransform: 'capitalize',
                            fontSize: '15.5px',
                            marginLeft: '-9px',
                            marginTop: '20px'
                          }}
                        >
                          See Less
                        </Button>
                      </Box>
                    </>
                  ) : (
                    ''
                  )}
                </CardContent>
              </Card>
            )}

            {!_isEmpty(_get(profileDet, 'certificationList')) &&
              _get(profileDet, 'certificationList').length > 1 && (
                <Card
                  sx={{
                    boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
                    borderRadius: '10px',
                    marginTop: '30px'
                  }}
                >
                  <CardContent sx={{ padding: '20px' }}>
                    <Box
                      sx={{
                        fontSize: '18.6px',
                        lineHeight: '35px',
                        fontWeight: '600',
                        color: '#000000',
                        textTransform: 'capitalize'
                      }}
                    >
                      Certifications
                    </Box>
                    <Divider
                      sx={{
                        borderColor: 'rgba(0, 0, 0, 0.2)',
                        marginTop: '13px',
                        marginBottom: '9px'
                      }}
                    />
                    <>
                      {_get(profileDet, 'certificationList').map((certification, index) => (
                        <Grid
                          container
                          spacing={1}
                          alignItems="center"
                          className={`h-padding-top-${index < 1 ? '5' : '16'}`}
                          key={`certification${index}`}
                        >
                          <Grid item xs={12} md={12} lg={12}>
                            {_get(certification, 'certificationFile') != '' ? (
                              <Box
                                sx={{ color: '#000000', fontSize: '15.5px', fontFamily: 'Poppins' }}
                              >
                                {_get(certification, 'certificateName')}
                                <Box>
                                  <Link href={_get(certification, 'certificationFile')}>
                                    <img
                                      style={{
                                        float: 'right',
                                        marginRight: '23px',
                                        marginTop: '-23px'
                                      }}
                                      src={DownLoad}
                                      alt="download"
                                      fontSize="medium"
                                      width="18px"
                                    />
                                  </Link>
                                </Box>
                              </Box>
                            ) : (
                              <Box sx={{ color: '#000000', fontSize: '15.5px' }}>
                                {_get(certification, 'certificateName')}
                              </Box>
                            )}
                            {index != _get(profileDet, 'certificationList').length - 1 && (
                              <Divider
                                sx={{ borderColor: 'rgba(0, 0, 0, 0.2)', marginTop: '15px' }}
                              />
                            )}
                          </Grid>
                        </Grid>
                      ))}
                    </>
                  </CardContent>
                </Card>
              )}
            {!_isEmpty(_get(profileDet, 'myProfile.fieldOfSpecialization')) &&
              _get(profileDet, 'myProfile.fieldOfSpecialization').length >= 1 && (
                <Card
                  sx={{
                    boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
                    borderRadius: '10px',
                    marginTop: '30px'
                  }}
                >
                  <CardContent sx={{ padding: '20px' }}>
                    <Box
                      sx={{
                        fontSize: '18.6px',
                        lineHeight: '35px',
                        fontWeight: '600',
                        color: '#000000',
                        textTransform: 'capitalize'
                      }}
                    >
                      Field of expertise
                    </Box>
                    <Divider
                      sx={{
                        borderColor: 'rgba(0, 0, 0, 0.2)',
                        marginTop: '13px',
                        marginBottom: '9px'
                      }}
                    />
                    <>
                      {_get(profileDet, 'myProfile.fieldOfSpecialization').map((expertise, index) => (
                        <Grid
                          container
                          spacing={1}
                          alignItems="center"
                          className={`h-padding-top-${index < 1 ? '5' : '16'}`}
                          key={`certification${index}`}
                        >
                          <Grid item xs={12} md={9} lg={9}>
                            <Box sx={{ color: '#000000', fontSize: '15.5px' }}>{expertise}</Box>
                            {index !=
                              _get(profileDet, 'myProfile.fieldOfSpecialization').length - 1 && (
                                <Divider
                                  sx={{ borderColor: 'rgba(0, 0, 0, 0.2)', marginTop: '15px' }}
                                />
                              )}
                          </Grid>
                        </Grid>
                      ))}
                    </>
                  </CardContent>
                </Card>
              )}
            {!_isEmpty(_get(profileDet, 'publicationList')) &&
              _get(profileDet, 'publicationList').length > 1 && (
                <Card
                  sx={{
                    boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
                    borderRadius: '10px',
                    marginTop: '30px'
                  }}
                >
                  <CardContent sx={{ padding: '20px' }}>
                    <Box
                      sx={{
                        fontSize: '18.6px',
                        lineHeight: '35px',
                        fontWeight: '600',
                        color: '#000000',
                        textTransform: 'capitalize'
                      }}
                    >
                      Publications
                    </Box>
                    <Divider
                      sx={{
                        borderColor: 'rgba(0, 0, 0, 0.2)',
                        marginTop: '13px',
                        marginBottom: '9px'
                      }}
                    />
                    <>
                      <Grid
                        container
                        spacing={1}
                        className="h-padding-top-16"
                        alignItems="center"
                        color="#818181"
                      ></Grid>
                      {_get(profileDet, 'publicationList').map((publication, index) => (
                        <Grid
                          container
                          spacing={1}
                          alignItems="center"
                          className={`h-padding-top-${index < 1 ? '5' : '16'}`}
                          key={`publication${index}`}
                        >
                          <Grid item xs={12} md={12} lg={12}>
                            {_get(publication, 'publicationUrl') != '' ? (
                              <Link href={_get(publication, 'publicationUrl')} target="_blank">
                                <Box
                                  sx={{
                                    color: 'blue',
                                    fontSize: '15.5px',
                                    display: 'flex',
                                    width: '100%',
                                    display: 'block'
                                  }}
                                >
                                  {_get(publication, 'publicationName')}
                                </Box>
                              </Link>
                            ) : (
                              <Box
                                sx={{
                                  color: '#000000',
                                  fontSize: '15.5px',
                                  display: 'flex',
                                  width: '100%',
                                  display: 'block'
                                }}
                              >
                                {_get(publication, 'publicationName')}
                              </Box>
                            )}
                            {index != _get(profileDet, 'publicationList').length - 1 && (
                              <Divider
                                sx={{ borderColor: 'rgba(0, 0, 0, 0.2)', marginTop: '15px' }}
                              />
                            )}
                          </Grid>
                        </Grid>
                      ))}
                    </>
                  </CardContent>
                </Card>
              )}

            {!_isEmpty(_get(profileDet, 'myProfile.tools')) && (
              <Card
                sx={{
                  boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
                  borderRadius: '10px',
                  marginTop: '30px'
                }}
              >
                <CardContent sx={{ padding: '20px' }}>
                  <Box
                    sx={{
                      fontSize: '18.6px',
                      lineHeight: '35px',
                      fontWeight: '600',
                      color: '#000000'
                    }}
                  >
                    Tools and technologies
                  </Box>
                  <Divider
                    sx={{ borderColor: 'rgba(0, 0, 0, 0.2)', marginTop: '13px', marginBottom: '9px' }}
                  />
                  <Box sx={{ paddingTop: '20px', fontSize: '18px' }}>
                    {_get(profileDet, 'myProfile.tools').map((expertise, index) => (
                      <React.Fragment key={index}>
                        <Chip
                          label={expertise}
                          variant="outlined"
                          sx={{
                            minWidth: '100px',
                            fontWeight: '500',
                            color: '#000000',
                            marginBottom: '6px'
                          }}
                        />
                        &nbsp;&nbsp;
                      </React.Fragment>
                    ))}
                  </Box>
                </CardContent>
              </Card>
            )}
            <Box sx={{ marginTop: '40px' }}></Box>
            {
              !!referralsData
              &&
              Array.isArray(referralsData)
              &&
              referralsData.length > 0
              &&
              checkIfVerified(referralsData).length > 0
              &&
              <div className='reviews-wrapper'>
                <div className='reviews-heading reviews-heading-gig-applied-eng'>{'Work History'}</div>
                <div className='completed-gigs-txt'>{'Completed Gigs'}</div>
                <div className='completed-gigs-txt-underline' />
                {
                  checkIfVerified(referralsData).map((item, index) => (
                    <div>
                      <div className='spacer'></div>
                      <div className='review-gig-name'>{item.gigId.titleAndSkills.projectTitle}</div>
                      <div className='stars-row'>
                        <ReactStars classNames={'stars'} count={5} activeColor="#ffd700" size={25} edit={false} value={!!item.publicFeedback.feedbackStarData ? Number((item.publicFeedback.feedbackStarData.reduce((sum, feedback) => sum + (feedback.feedbackRating > 0 ? parseInt(feedback.feedbackRating) : 0), 0) / Math.max(item.publicFeedback.feedbackStarData.filter(feedback => feedback.feedbackRating > 0).length, 1)).toFixed(2)) : 0} />
                        <div className='stars-count-txt'>{!!item.publicFeedback.feedbackStarData && (item.publicFeedback.feedbackStarData.reduce((sum, feedback) => sum + (feedback.feedbackRating > 0 ? parseInt(feedback.feedbackRating) : 0), 0) / Math.max(item.publicFeedback.feedbackStarData.filter(feedback => feedback.feedbackRating > 0).length, 1)).toFixed(2)}</div>
                      </div>
                      <div className='feedback-txt'>{`“${item.publicFeedback.feedbackTextData}”`}</div>
                    </div>
                  ))
                }
              </div>
            }
          </Grid>
          <Box sx={{ marginLeft: '40px', borderRight: '1px solid #DAD7D7' }}></Box>
          <Grid item xs={12} sm={3} md={3} ml={3}>
            <div className='availability-skill-items'>
              <Card
                sx={{
                  boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
                  borderRadius: '10px',
                  marginTop: '-16px'
                }}
              >
                <CardContent sx={{ padding: '20px' }}>
                  <Box
                    sx={{
                      fontSize: '18.6px',
                      lineHeight: '35px',
                      fontWeight: '600',
                      textAlign: 'center',
                      color: '#000000'
                    }}
                  >
                    Availability
                  </Box>
                  <Box sx={{ textAlign: 'center' }}>
                    <Box
                      sx={{
                        fontSize: '15.5px',
                        fontWeight: '500',
                        lineHeight: '28px',
                        textAlign: 'center',
                        alignItems: 'center',
                        color: '#818181'
                      }}
                    >
                      {_get(profileDet, 'myProfile.availabilityHoursPerWeek')
                        ? ` ${_get(profileDet, 'myProfile.availabilityHoursPerWeek')} Hr/Week `
                        : 'Not Disclosed'}
                    </Box>
                  </Box>
                </CardContent>
              </Card>

              {!_isEmpty(_get(profileDet, 'languageList')) && (
                <Card
                  sx={{
                    boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
                    borderRadius: '10px',
                    marginTop: '30px'
                  }}
                >
                  <CardContent sx={{ padding: '20px' }}>
                    <Box
                      sx={{
                        fontSize: '18.6px',
                        lineHeight: '35px',
                        fontWeight: '600',
                        textAlign: 'center',
                        color: '#000000'
                      }}
                    >
                      Languages
                    </Box>
                    <Box sx={{ textAlign: 'center' }}>
                      {_get(profileDet, 'languageList').map((lang, index) => (
                        <Box
                          sx={{
                            fontSize: '15.5px',
                            fontWeight: '500',
                            lineHeight: '28px',
                            textAlign: 'center',
                            alignItems: 'center',
                            color: '#818181'
                          }}
                        >
                          {_get(lang, 'language')}: {_get(lang, 'proficienyLevel')}
                        </Box>
                      ))}
                    </Box>
                  </CardContent>
                </Card>
              )}

              {!_isEmpty(_get(profileDet, 'skillList')) && (
                <Card
                  sx={{
                    boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
                    borderRadius: '10px',
                    marginTop: '30px'
                  }}
                >
                  <CardContent sx={{ padding: '20px' }}>
                    <Box
                      sx={{
                        fontSize: '18.6px',
                        lineHeight: '35px',
                        fontWeight: '600',
                        textAlign: 'center',
                        color: '#000000'
                      }}
                    >
                      Skills
                    </Box>
                    <Box sx={{ textAlign: 'center' }}>
                      {_get(profileDet, 'skillList').map((skill, index) => (
                        <React.Fragment key={index}>
                          <Box
                            sx={{
                              fontSize: '15.5px',
                              fontWeight: '500',
                              lineHeight: '28px',
                              textAlign: 'center',
                              alignItems: 'center',
                              color: '#818181'
                            }}
                          >
                            {_get(skill, 'skillName')}
                          </Box>
                        </React.Fragment>
                      ))}
                    </Box>
                  </CardContent>
                </Card>
              )}
              {!_isEmpty(_get(profileDet, 'reviewsList')) && (
                (!_isEmpty(_get(profileDet, 'reviewsList')) &&
                  !!checkIfPublished(_get(profileDet, 'reviewsList'))
                  &&
                  checkIfPublished(_get(profileDet, 'reviewsList')).length > 0
                  ?
                  (
                    <Card
                      sx={{
                        boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
                        borderRadius: '10px',
                        marginTop: '30px'
                      }}
                    >
                      {/* {console.log(checkIfPublished(_get(profileDet, 'reviewsList')))} */}
                      <CardContent sx={{ padding: '20px' }}>
                        <Box
                          sx={{
                            fontSize: '18.6px',
                            lineHeight: '35px',
                            fontWeight: '600',
                            textAlign: 'center',
                            color: '#000000'
                          }}
                        >
                          {userType == 'admin' ? "Manual Reviews" : "Reviews"}
                        </Box>
                        <Box sx={{ textAlign: 'center' }}>
                          {
                            userType == 'admin' ?
                              _get(profileDet, 'reviewsList').map((review, index) => (
                                <React.Fragment key={index}>
                                  <Box
                                    sx={{
                                      fontSize: '15.5px',
                                      fontWeight: '500',
                                      lineHeight: '28px',
                                      textAlign: 'center',
                                      alignItems: 'center',
                                      color: '#818181'
                                    }}
                                  >
                                    <p style={{ textAlign: "center" }}>{review.nameOfSource != "Other" ? review.nameOfSource : review.specification}</p>
                                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }} className='stars-row'>
                                      <ReactStars classNames={'stars'} count={5} activeColor="#ffd700" size={25} edit={false} value={Number(((review.rating / review.outOf) * 5).toFixed(2))} />
                                      <div className='stars-count-txt'>{((review.rating / review.outOf) * 5).toFixed(2)}</div>
                                    </div>
                                    <p style={{ textAlign: "center" }}><strong>Link: </strong><a href={review.reviewLink} target={"_blank"}>{review.reviewLink}</a></p>
                                    {
                                      userType == 'admin'
                                      &&
                                      <div className='btns-wrapper'>
                                        {
                                          !(("isRejected" in review) && review.isRejected)
                                          &&
                                          (review.isVerified ?
                                            <button className='btns-cta verified'>Verified</button>
                                            :
                                            <button onClick={() => {
                                              scrollOnTop();
                                              disableEnableBodyScroll();
                                              setopenVerifyModal(true)
                                              setactiveRating(review)
                                              setactiveRatingIndex(index)
                                            }} className='btns-cta'>Verify</button>)
                                        }
                                        {
                                          !review.isVerified
                                          &&
                                          (("isRejected" in review) && review.isRejected ?
                                            <button className='btns-cta rejected'>Rejected</button>
                                            :
                                            <button onClick={() => {
                                              scrollOnTop();
                                              disableEnableBodyScroll();
                                              setopenRejectModal(true)
                                              setactiveRating(review)
                                              setactiveRatingIndex(index)
                                            }} className='btns-cta reject'>Reject</button>)
                                        }
                                      </div>
                                    }
                                    {
                                      index < _get(profileDet, 'reviewsList').length - 1
                                      &&
                                      <hr />
                                    }
                                  </Box>
                                </React.Fragment>
                              ))
                              :
                              checkIfPublished(_get(profileDet, 'reviewsList')).map((review, index) => (
                                <React.Fragment key={index}>
                                  <Box
                                    sx={{
                                      fontSize: '15.5px',
                                      fontWeight: '500',
                                      lineHeight: '28px',
                                      textAlign: 'center',
                                      alignItems: 'center',
                                      color: '#818181'
                                    }}
                                  >
                                    <p style={{ textAlign: "center" }}>{review.nameOfSource != "Other" ? review.nameOfSource : review.specification}</p>
                                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }} className='stars-row'>
                                      <ReactStars classNames={'stars'} count={5} activeColor="#ffd700" size={25} edit={false} value={Number(((review.rating / review.outOf) * 5).toFixed(2))} />
                                      <div className='stars-count-txt'>{((review.rating / review.outOf) * 5).toFixed(2)}</div>
                                    </div>
                                    <p style={{ textAlign: "center" }}><strong>Link: </strong><a href={review.reviewLink} target={"_blank"}>{review.reviewLink}</a></p>
                                    {
                                      userType == 'admin'
                                      &&
                                      <div className='btns-wrapper'>
                                        {
                                          !(("isRejected" in review) && review.isRejected)
                                          &&
                                          (review.isVerified ?
                                            <button className='btns-cta verified'>Verified</button>
                                            :
                                            <button onClick={() => {
                                              scrollOnTop();
                                              disableEnableBodyScroll();
                                              setopenVerifyModal(true)
                                              setactiveRating(review)
                                              setactiveRatingIndex(index)
                                            }} className='btns-cta'>Verify</button>)
                                        }
                                        {
                                          !review.isVerified
                                          &&
                                          (("isRejected" in review) && review.isRejected ?
                                            <button className='btns-cta rejected'>Rejected</button>
                                            :
                                            <button onClick={() => {
                                              scrollOnTop();
                                              disableEnableBodyScroll();
                                              setopenRejectModal(true)
                                              setactiveRating(review)
                                              setactiveRatingIndex(index)
                                            }} className='btns-cta reject'>Reject</button>)
                                        }
                                      </div>
                                    }
                                    {
                                      index < _get(profileDet, 'reviewsList').length - 1
                                      &&
                                      <hr />
                                    }
                                  </Box>
                                </React.Fragment>
                              ))
                          }
                        </Box>
                      </CardContent>
                    </Card>
                  )
                  :
                  (
                    <Card
                      sx={{
                        boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
                        borderRadius: '10px',
                        marginTop: '30px'
                      }}
                    >
                      <CardContent sx={{ padding: '20px' }}>
                        <Box
                          sx={{
                            fontSize: '18.6px',
                            lineHeight: '35px',
                            fontWeight: '600',
                            textAlign: 'center',
                            color: '#000000'
                          }}
                        >
                          {userType == 'admin' ? "Manual Reviews" : "Reviews"}
                        </Box>
                        <Box sx={{ textAlign: 'center' }}>
                          {
                            userType == 'admin' ?
                              _get(profileDet, 'reviewsList').map((review, index) => (
                                <React.Fragment key={index}>
                                  <Box
                                    sx={{
                                      fontSize: '15.5px',
                                      fontWeight: '500',
                                      lineHeight: '28px',
                                      textAlign: 'center',
                                      alignItems: 'center',
                                      color: '#818181'
                                    }}
                                  >
                                    <p style={{ textAlign: "center" }}>{review.nameOfSource != "Other" ? review.nameOfSource : review.specification}</p>
                                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }} className='stars-row'>
                                      <ReactStars classNames={'stars'} count={5} activeColor="#ffd700" size={25} edit={false} value={Number(((review.rating / review.outOf) * 5).toFixed(2))} />
                                      <div className='stars-count-txt'>{((review.rating / review.outOf) * 5).toFixed(2)}</div>
                                    </div>
                                    <p style={{ textAlign: "center" }}><strong>Link: </strong><a href={review.reviewLink} target={"_blank"}>{review.reviewLink}</a></p>
                                    {
                                      userType == 'admin'
                                      &&
                                      <div className='btns-wrapper'>
                                        {
                                          !(("isRejected" in review) && review.isRejected)
                                          &&
                                          (review.isVerified ?
                                            <button className='btns-cta verified'>Verified</button>
                                            :
                                            <button onClick={() => {
                                              scrollOnTop();
                                              disableEnableBodyScroll();
                                              setopenVerifyModal(true)
                                              setactiveRating(review)
                                              setactiveRatingIndex(index)
                                            }} className='btns-cta'>Verify</button>)
                                        }
                                        {
                                          !review.isVerified
                                          &&
                                          (("isRejected" in review) && review.isRejected ?
                                            <button className='btns-cta rejected'>Rejected</button>
                                            :
                                            <button onClick={() => {
                                              scrollOnTop();
                                              disableEnableBodyScroll();
                                              setopenRejectModal(true)
                                              setactiveRating(review)
                                              setactiveRatingIndex(index)
                                            }} className='btns-cta reject'>Reject</button>)
                                        }
                                      </div>
                                    }
                                    {
                                      index < _get(profileDet, 'reviewsList').length - 1
                                      &&
                                      <hr />
                                    }
                                  </Box>
                                </React.Fragment>
                              ))
                              :
                              checkIfPublished(_get(profileDet, 'reviewsList')).map((review, index) => (
                                <React.Fragment key={index}>
                                  <Box
                                    sx={{
                                      fontSize: '15.5px',
                                      fontWeight: '500',
                                      lineHeight: '28px',
                                      textAlign: 'center',
                                      alignItems: 'center',
                                      color: '#818181'
                                    }}
                                  >
                                    <p style={{ textAlign: "center" }}>{review.nameOfSource != "Other" ? review.nameOfSource : review.specification}</p>
                                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }} className='stars-row'>
                                      <ReactStars classNames={'stars'} count={5} activeColor="#ffd700" size={25} edit={false} value={Number(((review.rating / review.outOf) * 5).toFixed(2))} />
                                      <div className='stars-count-txt'>{((review.rating / review.outOf) * 5).toFixed(2)}</div>
                                    </div>
                                    <p style={{ textAlign: "center" }}><strong>Link: </strong><a href={review.reviewLink} target={"_blank"}>{review.reviewLink}</a></p>
                                    {
                                      userType == 'admin'
                                      &&
                                      <div className='btns-wrapper'>
                                        {
                                          !(("isRejected" in review) && review.isRejected)
                                          &&
                                          (review.isVerified ?
                                            <button className='btns-cta verified'>Verified</button>
                                            :
                                            <button onClick={() => {
                                              scrollOnTop();
                                              disableEnableBodyScroll();
                                              setopenVerifyModal(true)
                                              setactiveRating(review)
                                              setactiveRatingIndex(index)
                                            }} className='btns-cta'>Verify</button>)
                                        }
                                        {
                                          !review.isVerified
                                          &&
                                          (("isRejected" in review) && review.isRejected ?
                                            <button className='btns-cta rejected'>Rejected</button>
                                            :
                                            <button onClick={() => {
                                              scrollOnTop();
                                              disableEnableBodyScroll();
                                              setopenRejectModal(true)
                                              setactiveRating(review)
                                              setactiveRatingIndex(index)
                                            }} className='btns-cta reject'>Reject</button>)
                                        }
                                      </div>
                                    }
                                    {
                                      index < _get(profileDet, 'reviewsList').length - 1
                                      &&
                                      <hr />
                                    }
                                  </Box>
                                </React.Fragment>
                              ))
                          }
                        </Box>
                      </CardContent>
                    </Card>
                  )
                )
              )}

            </div>
          </Grid>
        </Grid>
        {
          !!activeRating && openVerifyModal
          &&
          <VerifyRatingModal activeRating={activeRating} activeRatingIndex={activeRatingIndex} handleCloseVerifyRatingModal={handleCloseVerifyRatingModal} handleVerifyReview={handleVerifyReview} />
        }
        {
          !!activeRating && openRejectModal
          &&
          <RejectRatingModal activeRating={activeRating} activeRatingIndex={activeRatingIndex} handleCloseRejectRatingModal={handleCloseRejectRatingModal} handleRejectReview={handleRejectReview} />
        }
      </PaddedBox>
      <PaddedFooter style={{ paddingLeft: `${!!props.auth && !!props.auth.isDrawerOpen && props.auth.isDrawerOpen == true ? "200px" : "0px"}` }}>
        <Footer />
      </PaddedFooter>
      {
        !!currentUser
        &&
        <VerifyProfile handleCloseVerifyModal={handleCloseVerifyModal} currentUser={profileDet} />
      }
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth
  };
};

export default connect(mapStateToProps)(GigAppliedEngineer);
