import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _each from 'lodash/each';
import { setChatMessage, setAllChats, setChatMsgs, setReceivedMessage, setChatNotifier, setChatUserName, setNotification } from '../../store/actions/chat';
import { getTokenDetails } from '../../utils/user';
import { unRegisterChatServices } from '../../utils/chatUtils';

let token = null;


const RegisterChat = (props) => {

  const onNewUserNotification = ({token, name}) => {
    // console.log(token, name);
  };

  const onPrivateMessage = (msg) => {
    // console.log('messages befor setting', msg);
    props.setReceivedMessage(msg);
  };

  const onGetChatList = (data) => {
    // console.log("list", data);
    props.setAllChats(data);
  }

  const onSpecificChat = (data) => {
    // console.log('chat', data);
    props.setChatMsgs(data);
  }

  const onReconnectNeeded = () => {
    // console.log('message not sent. Please Reconnect');
    toast.error('message not sent. Please Reconnect');
  }

  const onGetNotifications = (data) => {
    props.setNotification(data);
  }

  const onGetUserName = (data) => {
    // console.log('getuser name------------', data);
    props.setChatUserName(data);
  }

  const onDisconnect = () => {
    // console.log('================DISCONNECTED==================');
  }

  const onCheckPointGet = (response) => {
    // console.log('----------------------oncheckpointget');
    // console.log(response);
    let hasMessages = false;
    _each(response, (checkPoint) => {
      if(checkPoint.hasNewMessages) {
        hasMessages = true;
      }
    });
    if(hasMessages) {
      props.setChatNotifier(true);
    }
  }


  useEffect(() => {
    //set token and emit constant identifier
    const tokenDetails = getTokenDetails();
    token = tokenDetails.userId;
    const name = props.name || token;

    // unregister services for stopping it registering twice
    unRegisterChatServices();

    //new user notification
    socket.on('newuser', onNewUserNotification);

    //on message notification
    socket.on('privatemessage', onPrivateMessage);

    //on get all chat list
    socket.on('get-user-name', onGetUserName);

    //on get all chat list
    socket.on('get-all-users', onGetChatList);

    //get specific user chat
    socket.on('get-user-chat', onSpecificChat);



    //get specific user chat
    socket.on('reconnect', onReconnectNeeded);

    // on disconnect
    socket.on('disconnect', onDisconnect);

    //checkPointGet
    socket.on('checkPointGet', onCheckPointGet);

    socket.on('getNotification', onGetNotifications);

    // new user registration
    socket.emit('newuser', {token, name});
    
  }, []);

  

  return <></>;
};

const mapStateToProps = (state) => {
  return {
    messages: state.chat.messages
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setChatMessage: (details) => dispatch(setChatMessage(details)),
    setAllChats: (details) => dispatch(setAllChats(details)),
    setReceivedMessage: (details) => dispatch(setReceivedMessage(details)),
    setChatMsgs: (details) => dispatch(setChatMsgs(details)),
    setChatNotifier: (details) => dispatch(setChatNotifier(details)),
    setChatUserName: (details) => dispatch(setChatUserName(details)),
    setNotification: (details) => dispatch(setNotification(details)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterChat);
