import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import _map from 'lodash/map';

export default function Prompt({
  message,
  show,
  heading,
  data,
  onPromptSelected,
  values,
  showRemarks
}) {
  const [open, setOpen] = useState(show);
  const [comments, setComments] = useState();
  const [remarks, setRemarks] = useState();

  const promptSelected = (event) => {
    event.stopPropagation();
    const type = event.target.dataset.value;
    setOpen(false);
    const obj = {
      ...data,
      type,
      comments,
      remarks
    };
    onPromptSelected && onPromptSelected(obj);
  };

  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{heading || ''}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{message || ''}</DialogContentText>
        {showRemarks && (
          <>
            <Grid container item xs={12} sx={{ marginTop: '16px' }}>
              <TextField
                fullWidth
                label="Internal Comments"
                multiline
                maxRows={4}
                onChange={(event) => {
                  event.stopPropagation();
                  setComments(event.target.value);
                }}
              />
            </Grid>
            <Grid container item xs={12} sx={{ marginTop: '16px' }}>
              <TextField
                fullWidth
                label="Remarks to the user"
                multiline
                maxRows={4}
                onChange={(event) => {
                  event.stopPropagation();
                  setRemarks(event.target.value);
                }}
              />
            </Grid>
          </>
        )}
      </DialogContent>
      <DialogActions>
        {_map(values, (val) => (
          <Button key={`prompt-button-${val}`} onClick={promptSelected} data-value={val}>
            {val}
          </Button>
        ))}
      </DialogActions>
    </Dialog>
  );
}

Prompt.propTypes = {
  /**
   * show/hide prompt
   */
  show: PropTypes.bool,
  heading: PropTypes.string,
  message: PropTypes.string,
  onPromptSelected: PropTypes.func,
  values: PropTypes.arrayOf(PropTypes.string)
};

Prompt.defaultProps = {
  show: false,
  heading: '',
  message: '',
  onPromptSelected: () => {},
  values: []
};
