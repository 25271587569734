import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _toNumber from 'lodash/toNumber';
import { CustomRow, EditedCard, PaddedBox, PaddedFooter } from '../../app.styles';
import {
    Avatar,
    Button,
    CardContent,
    CardHeader,
    Chip,
    Grid,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Pagination,
    Typography,
} from '@mui/material';
import { getQueryParams, scrollOnTop } from '../../../utils/helpers';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import { getUserId, getUserType } from '../../../utils/user';
import Skills from '../../../assests/Skills.png';
import GTranslateOutlinedIcon from '@mui/icons-material/GTranslateOutlined';
import { connect } from 'react-redux';
import { engineerProfileGigs } from '../../../services/gig';
import Search from '../../../components/SearchBar/Search';
import { resetSFS } from '../../../store/actions/sfs';
import PageLoader from '../../../components/Loaders/PageLoader';
import NavigateNextOutlinedIcon from '@mui/icons-material/NavigateNextOutlined';
import GridViewIcon from '@mui/icons-material/GridView';
import LogoTable from '../../../assests/logoTable.svg';
import VerifiedIcon from '../../../assests/VerifiedTick.svg';
import { Box } from '@mui/system';
import AccountDeactivate from '../../../components/AccountDeactivate/AccountDeactivate';
import { adminCvDownload, loginUserViaAdmin } from '../../../services/profile';
import SortAdmin from '../../../components/SearchBar/SortAdmin';
import AccountActivate from '../../../components/AccountDeactivate/AccountActivate';
import GridTableNotPagination from '../../../components/GridTable/GridTableNotPagination';
import Footer from '../../../components/footer1/footer';
import './engineerProfile.scss'
import MoreVert from './more_vert.svg'
import VerifyProfile from '../../../components/VerifyProfile/VerifyProfile';
import EngineerBank from '../../../assests/dotbankicon.svg';

const EngineerProfiles = (props) => {

    const query = getQueryParams();
    const queryPage = query.page || 1; // active engineer page

    const [paginationVal, setPaginationVal] = useState(1);
    const [engineerProfile, setEngineerProfile] = useState([]);
    const userType = getUserType(); // get the user type from tokens
    const [tableData, setTableData] = useState("tableView");
    const [list, setList] = useState([]); // acitve engineer list returned from api
    const [page, setPage] = useState(_toNumber(queryPage)); // active enginner list page number
    const [count, setCount] = useState(1); // count of total engineer
    const history = useHistory();
    const sfsReset = useRef(true); // reset search filter sort on navigate to another page
    const [currentUser, setcurrentUser] = useState();
    const filterApplied =
        !_isEmpty(props.searchKey) || !_isEmpty(props.filters) || !_isEmpty(props.sortBy);

    const setListData = (data) => {
        setList(_get(data, 'engineerList', []));
        setPage(_get(data, 'paginationResult.currentPage', 1));
        setCount(_get(data, 'paginationResult.totalPages', 1));
    };

    const handleVerifyCurrentUser = (userData, userId) => {
        scrollOnTop();
        setcurrentUser(userData);
    }

    const handleCloseVerifyModal = () => {
        setcurrentUser(undefined);
        if (tableData === 'tableView') {
            fetchData(page);
        } else {
            fetchData(page);
        }
    }

    const handleCvDownload = (userId) => {
        adminCvDownload({
            "userId": userId
        })
    }

    const handleCvDownloadCard = (userId) => {
        adminCvDownload({
            "userId": props.basicInfo.userId
        })
    }

    const getMessageLink = (engineerName, userId, gigId, status, rowData) => {
        return (
            <div className='admin-list-cta-dd'>
                <img src={MoreVert} />
                <div className='admin-cta-list'>
                    {userType === 'admin' &&
                        <AccountActivate btnType="primary" profileDet={rowData} />
                    }
                    {(status === "INACTIVE" ?
                        <button
                            // className="admin-Side-login-btn"
                            disabled
                            onClick={() => { handleLoginUser(rowData) }}
                        >
                            Log in as user
                        </button>
                        : <button
                            // className="admin-Side-login-btn"
                            onClick={() => { handleLoginUser(rowData) }}
                        >
                            Log in as user
                        </button>
                    )}
                    {
                        (!rowData?.isVerified && rowData?.profileCompletionPercent === 100 ? <button onClick={() => { handleVerifyCurrentUser(rowData, userId) }}>Verify Profile</button> : "")
                    }
                    {
                        rowData.profileCompletionPercent >= 60
                        &&
                        <button onClick={() => { handleCvDownload(userId) }}>Download CV</button>
                    }
                     {
                        <button 
                        onClick={() => {
                            history.push({
                                pathname: '/admin/engineer/profile/viewBankDetails',
                                state: { billingDetails: rowData.billingDetails },
                            })
                        }}
                         >
                            View billing details
                            </button>
                    }
                </div>
            </div>
        )
    }

    const handleLoginUser = async (data) => {
        loginUserViaAdmin(data.basicInfo.userId);
    }

    const setData = (data) => {
        const rowData = [];
        const engineerListData = _get(data, 'engineerList', []);
        const totalRecords = _get(data, 'paginationResult', {});
        if (engineerListData && engineerListData.length > 0) {
            for (let obj of engineerListData) {
                const engineerName = _get(obj, 'basicInfo.name', '') ? _get(obj, 'basicInfo.name', '') : '-';
                const designation = _get(obj, 'myProfile.title', '') ? _get(obj, 'myProfile.title', '') : "-";
                const profilePercentage = `${_get(obj, 'profileCompletionPercent', '-')} %`;
                const gigId = _get(obj, 'applicationDetails.gigId', '');
                const location = _get(obj, 'basicInfo.country', '-');
                const userId = _get(obj, 'basicInfo.userId', '');
                const hourlyRate = _get(obj, 'myProfile.hourlyRate', '') ? `${_get(obj, 'myProfile.currency', 'R')} ${_get(obj, 'myProfile.hourlyRate', '')}/Hr` : '-';
                const availabilityHoursPerWeek = _get(obj, 'myProfile.availabilityHoursPerWeek', '') ? ` ${_get(obj, 'myProfile.availabilityHoursPerWeek', '')}Hr/week` : '-';
                const status = _get(obj, 'basicInfo.status', '');
                const id = _get(obj, '_id', '');
                rowData.push([engineerName, designation, profilePercentage, location, availabilityHoursPerWeek, hourlyRate, getMessageLink(engineerName, userId, gigId, status, obj), { id, name }, obj, gigId, userId]);
            }
        }
        setEngineerProfile(rowData);
        setPaginationVal(totalRecords.totalPages || 1);
    };

    useEffect(() => {
        if (tableData === 'tableView') {
            fetchData(filterApplied ? 1 : paginationVal);
        } else {
            fetchData(filterApplied ? 1 : page);
        }
    }, [props.searchKey, props.filters, props.sortBy]);

    const fetchData = (currentPage) => {
        const obj = {
            'userId': getUserId(),
            paginationInput: {
                pageNumber: currentPage,
                pageSize: 12
            },
            filter: {
                userStatusList: ['ACTIVE'],
                profileCompletionPercent: 60
            },
            ...props.sortBy
        };

        // admin page we need show the inactive users also
        if (userType === 'admin') {
            delete obj.filter.profileCompletionPercent;
            obj.filter.userStatusList.push('INACTIVE');
            if(obj.sortByKey=="UNVERIFIED_REVIEWS"){
                obj.sortByKey = undefined
                obj.sortByOrder = undefined
                obj.filter.isUnverifiedReviews = true
            }
            else{
                obj.sortByKey = "CREATEDAT",
                obj.sortByOrder = -1
            }
        }

        if (!_isEmpty(props.searchKey)) {
            obj.search = props.searchKey;
        }

        if (!_isEmpty(props.filters)) {
            obj.filter = {
                ...obj.filter,
                ...props.filters
            };
        }

        engineerProfileGigs(obj, setData, setListData);
    };

    const handleRClick = (data) => {
        const gigData = data.pop();
        history.push(`/admin/engineer/profile/${gigData}`);
    };

    const onPaginationClick = (event, value) => {
        scrollOnTop();
        fetchData(value);
        setPage(value);
    };

    const onTableChange = (event, displayView) => {
        if (displayView === 'cardView') {
            setTableData('cardView');
        } else {
            setTableData('tableView');
        }
    };

    const handleChange = (event, view) => {
        scrollOnTop();
        fetchData(view);
        setPaginationVal(view);
    };

    const engineerDetailsView = (engineerId) => {
        sfsReset.current = false;
        const redirectUrl = `${userType === 'admin' ? '/admin' : ''}/engineer/list?page=${page}`;
        history.push(
            `${userType === 'admin' ? '/admin' : ''
            }/engineer/details/${engineerId}?redirectUrl=${encodeURIComponent(redirectUrl)}`
        );
    };

    const getArrayList = (arrayVal, key) => {
        return Array.prototype.map.call(arrayVal, (s) => ` ${s[key]}`).toString();
    };

    const headers = ['Engineer Name', 'Title', 'Profile Percentage', 'Location', 'Availability', 'Hourly Rate', 'Action'];


    return (
        <>
            <PaddedBox style={{ textAlign: 'center', paddingLeft: `${!!props.auth && !!props.auth.isDrawerOpen && props.auth.isDrawerOpen == true ? "240px" : "0px"}`, minHeight: "580px" }}>
                <CustomRow container>
                    <Typography
                        variant="h6"
                        component="h6"
                        color="pColor.main"
                        sx={{ fontWeight: 'Medium', textTransform: 'capitalize', fontSize: '22px', margin: '0px 0 20px 0', alignItems: 'center' }}
                    >
                        Browse Engineer Profiles
                        {filterApplied && (
                            <Chip
                                label="Remove Filters"
                                variant="outlined"
                                sx={{
                                    fontWeight: 'normal',
                                    marginLeft: '10px'
                                }}
                                onDelete={() => {
                                    props.resetSFS('');
                                }}
                            />
                        )}
                    </Typography>
                </CustomRow>

                    <Grid container item xs="auto" justifyContent="flex-end">
                        <div className='search-sort-icon'>
                        <Search placeholder="Search" />
                        <SortAdmin sortType="engineer" />
                        {
                            (tableData === 'tableView') ?
                                <GridViewIcon onClick={(e) => onTableChange(e, 'cardView')} sx={{ fontSize: '35px', color: '#818181', cursor: 'pointer', marginTop: '10px' }} />
                                :
                                <Box sx={{ cursor: 'pointer', marginTop: '11px' }}>
                                    <img sx={{ color: '#0000FF' }} src={LogoTable} alt="logotable" fontSize="small"
                                        onClick={(e) => onTableChange(e, 'tableView')}
                                    /></Box>
                        }
                        </div>
                    </Grid>

                {_isEmpty(engineerProfile) && filterApplied && (
                    <Grid
                        container
                        item
                        justifyContent="center"
                        xs={12}
                        className="h-margin-top-10 h-padding-top-16"
                    >
                        <Typography variant="h6" component="h6">
                            No records found, Please refine your search
                        </Typography>
                    </Grid>
                )}

                {_isEmpty(engineerProfile) && !filterApplied && (
                    <Grid
                        container
                        item
                        justifyContent="center"
                        xs={12}
                        className="h-margin-top-10 h-padding-top-16"
                    >
                        <PageLoader />
                    </Grid>
                )}
                {(tableData === 'tableView') ?
                    <Grid container className="table-Padding" mt={2}>
                        <GridTableNotPagination
                            headers={headers}
                            rows={engineerProfile}
                            handleRClick={handleRClick}
                        ></GridTableNotPagination>
                    </Grid> :
                    <PaddedBox style={{ paddingLeft: `0px`, marginTop: '-15px', width: "100%" }} secondary>
                        <Grid container item className="section-Margin" spacing={4} xs={12} md={12} lg={12}>
                            {
                                list.map((job, index) => (
                                    (
                                        <Grid item xs={12} sm={4} md={4} lg={4} key={index}>
                                            <EditedCard sx={{ minHeight: '500px' }}>
                                            <CardHeader
                                                sx={{ position: "absolute", top: "1", right: "0" }}
                                                action={
                                                    <div style={{marginRight: '15px', cursor: 'pointer'}}>
                                                    <div className='admin-list-cta-dd-bank'>
                                                    <img src={EngineerBank}
                                                    alt="EngineerBank"
                                                    fontSize="medium"
                                                     width="100%" />
                                                     <div className='admin-cta-list-bank'>
                                                     {
                                                   (!job?.isVerified && job?.profileCompletionPercent === 100 ?
                                                     <button onClick={() => { handleVerifyCurrentUser(job, props.basicInfo.userId) }}>Verify Profile</button> : "")
                                                    }
                                                     {
                                                      job.profileCompletionPercent >= 60
                                                       &&
                                                     <button onClick={() => { handleCvDownloadCard() }}>Download CV</button>
                                                       }
                                                {
                                               <button onClick={() => {
                                                    history.push({
                                                        pathname: '/admin/engineer/profile/viewBankDetails',
                                                        state: { billingDetails: job.billingDetails },
                                                    })
                                                }}>View billing details</button>
                                                  }
                                                     </div>
                                                     </div>
                                                    </div>
                                                }
                                            />
                                                <Box
                                                    sx={{ cursor: 'pointer' }}
                                                    onClick={(event) => {
                                                        event.stopPropagation();
                                                        engineerDetailsView(_get(job, 'basicInfo.userId'));
                                                    }}
                                                >
                                                    <Box sx={{ margin: 'auto' }}>
                                                        <Avatar
                                                            src={_get(job, 'myProfile.profilePic', '')}
                                                            sx={{
                                                                width: 80,
                                                                height: 80,
                                                                objectFit: 'cover',
                                                                margin: 'auto',
                                                                border: '3px solid #eee',
                                                                boxShadow: 'rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset',
                                                                backgroundColor: '#CCD8EE'
                                                            }}
                                                        >
                                                            {_get(job, 'basicInfo.name[0]', '')}
                                                        </Avatar>
                                                        <Typography
                                                            variant="h6"
                                                            component="h6"
                                                            color="pColor.main"
                                                            sx={{
                                                                fontWeight: 'Medium',
                                                                textTransform: 'capitalize',
                                                                textAlign: 'center',
                                                                marginTop: '15px'
                                                            }}
                                                        >
                                                            <Typography
                                                                title="Engineer Name"
                                                                sx={{
                                                                    textAlign: 'center',
                                                                    fontSize: '16px',
                                                                    fontWeight: '600',
                                                                    lineHeight: '1.43'
                                                                }}
                                                            >
                                                                {`${_get(job, 'basicInfo.name', '')}`}
                                                                {
                                                                    !!_get(job, 'isVerified') && (_get(job, 'profileCompletionPercent') == 100)
                                                                    &&
                                                                    <img className='verified-name-ico' src="https://gigengineer-production.s3.af-south-1.amazonaws.com/Assets/Blue+Tick+Verification.svg" />
                                                                }
                                                            </Typography>
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                                <Box>
                                                    <CardContent className="card-Content">
                                                        <Typography
                                                            variant="subtitle1"
                                                            gutterBottom
                                                            sx={{
                                                                color: '#f99600',
                                                                textAlign: 'center',
                                                                fontSize: '16px',
                                                                fontWeight: '600',
                                                                marginTop: '0px'
                                                            }}
                                                        >
                                                            {_get(job, 'myProfile.title') ? _get(job, 'myProfile.title', '') : '-'}
                                                        </Typography>
                                                        <List>
                                                            <ListItem disablePadding title="Availability" sx={{ cursor: 'default' }}>
                                                                <ListItemIcon sx={{ minWidth: '20px', marginLeft: '-0px' }}>
                                                                    <AssignmentTurnedInOutlinedIcon sx={{ fontSize: '19px', color: '#000' }} />
                                                                </ListItemIcon>
                                                                <ListItemText
                                                                    primary={
                                                                        <Typography
                                                                            sx={{
                                                                                fontSize: '16px',
                                                                                color: '#383838',
                                                                                overflow: 'hidden',
                                                                                marginLeft: '5px'
                                                                            }}
                                                                        >
                                                                            {
                                                                                _get(job, 'myProfile.availabilityHoursPerWeek', '0') ? `${_get(job, 'myProfile.availabilityHoursPerWeek', '0')} hrs/week` : 'Not Disclosed'
                                                                            }</Typography>
                                                                    }
                                                                />
                                                            </ListItem>
                                                            <ListItem disablePadding title="Hourly Rate" sx={{ cursor: 'default', marginTop: '5px' }}>
                                                                <ListItemIcon sx={{ minWidth: '25px', marginLeft: '-0px', marginTop: '5px' }}>
                                                                    <span
                                                                        enterTouchDelay={0}
                                                                    >
                                                                        <AccessTimeIcon sx={{ fontSize: '19px', color: '#000' }} />
                                                                    </span>
                                                                </ListItemIcon>
                                                                <ListItemText
                                                                    primary={
                                                                        <Typography
                                                                            sx={{
                                                                                fontSize: '16px',
                                                                                color: '#383838',
                                                                                overflow: 'hidden',
                                                                                marginLeft: '3px'
                                                                            }}
                                                                        >
                                                                            {
                                                                                _get(job, 'myProfile.hourlyRate', '0') ? `${_get(job, 'myProfile.currency', 'R')} ${_get(job, 'myProfile.hourlyRate', '0')}/hr` : 'Not Disclosed'
                                                                            }
                                                                        </Typography>
                                                                    }
                                                                />
                                                            </ListItem>
                                                            <ListItem disablePadding title="Location" sx={{ cursor: 'default', marginTop: '5px' }}>
                                                                <ListItemIcon sx={{ minWidth: '25px', marginLeft: '-3px', marginTop: '5px' }}>
                                                                    <span
                                                                        enterTouchDelay={0}
                                                                    >
                                                                        <PlaceOutlinedIcon sx={{ fontSize: '23px', color: '#000' }} />
                                                                    </span>
                                                                </ListItemIcon>
                                                                <ListItemText
                                                                    primary={
                                                                        <Typography
                                                                            sx={{
                                                                                fontSize: '16px',
                                                                                color: '#383838',
                                                                                overflow: 'hidden',
                                                                                marginLeft: '3px'
                                                                            }}
                                                                        >
                                                                            {_get(job, 'basicInfo.city')
                                                                                ? `${_get(job, 'basicInfo.city')}, ${_get(job, 'basicInfo.country')}`
                                                                                : ' Not Disclosed'}
                                                                        </Typography>
                                                                    }
                                                                />
                                                            </ListItem>
                                                            <ListItem disablePadding title="Skills" sx={{ cursor: 'default', marginTop: '5px' }}>
                                                                <ListItemIcon sx={{ minWidth: '25px', marginTop: '5px' }}>
                                                                    <span
                                                                        enterTouchDelay={0}
                                                                    >
                                                                        <img sx={{ color: '#0000FF' }} src={Skills} alt="skills" width="19px" />
                                                                    </span>
                                                                </ListItemIcon>
                                                                <ListItemText
                                                                    sx={{ fontSize: '12px' }}
                                                                    primary={
                                                                        <Typography
                                                                            sx={{
                                                                                fontSize: '16px',
                                                                                color: '#383838',
                                                                                overflow: 'hidden'
                                                                            }}
                                                                        >
                                                                            {getArrayList(_get(job, 'skillList', []), 'skillName')
                                                                                ? getArrayList(_get(job, 'skillList', []), 'skillName')
                                                                                : 'Not Disclosed'}
                                                                        </Typography>
                                                                    }
                                                                />
                                                            </ListItem>
                                                            <ListItem disablePadding title="Language" sx={{ cursor: 'default', marginTop: '5px' }}>
                                                                <ListItemIcon sx={{ minWidth: '20px' }}>
                                                                    <GTranslateOutlinedIcon sx={{ fontSize: '18px', color: '#000' }} />
                                                                </ListItemIcon>
                                                                <ListItemText
                                                                    primary={
                                                                        <Typography
                                                                            sx={{
                                                                                fontSize: '16px',
                                                                                color: '#383838',
                                                                                overflow: 'hidden',
                                                                                marginLeft: '4px'
                                                                            }}
                                                                        >
                                                                            {getArrayList(_get(job, 'languageList', []), 'language')
                                                                                ? getArrayList(_get(job, 'languageList', []), 'language')
                                                                                : 'Not Disclosed'}
                                                                        </Typography>
                                                                    }
                                                                />
                                                            </ListItem>
                                                        </List>
                                                    </CardContent>
                                                </Box>

                                                {(_get(job, 'basicInfo.status')) === "INACTIVE" && userType === 'admin' ?
                                                    <Box className="card-Action" sx={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "15px" }}>
                                                        <AccountDeactivate btnType="primary" profileDet={job} />
                                                        <Button
                                                            className="admin-Side-login-btn"
                                                            disabled
                                                            sx={{
                                                                marginLeft: '5px',
                                                                width: "163.45px",
                                                                height: '40.41px',
                                                                backgroundColor: '#FFFFFF',
                                                                borderRadius: '6px',
                                                                border: '1px solid #0543AB',
                                                                color: '#0543AB',
                                                                fontSize: '14px',
                                                                textTransform: 'capitalize'
                                                            }}
                                                            onClick={() => { handleLoginUser(job) }}
                                                        >
                                                            Log in as user
                                                        </Button>
                                                    </Box> :
                                                    <Box className="card-Action" sx={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "15px" }}>
                                                        <AccountDeactivate btnType="primary" profileDet={job} />
                                                        <Button
                                                            className="admin-Side-login-btn"
                                                            sx={{
                                                                marginLeft: '5px',
                                                                width: "163.45px",
                                                                height: '40.41px',
                                                                backgroundColor: '#FFFFFF',
                                                                borderRadius: '6px',
                                                                border: '1px solid #0543AB',
                                                                color: '#0543AB',
                                                                fontSize: '14px',
                                                                textTransform: 'capitalize'
                                                            }}
                                                            onClick={() => { handleLoginUser(job) }}
                                                        >
                                                            Log in as user
                                                        </Button>
                                                    </Box>}

                                                {userType !== 'admin' && (
                                                    <Box className="card-Action" sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                        <Button
                                                            sx={{ color: "#0140AA" }}
                                                            onClick={(event) => {
                                                                event.stopPropagation();
                                                                engineerDetailsView(_get(job, 'basicInfo.userId'));
                                                            }}
                                                        >
                                                            View Details
                                                            <NavigateNextOutlinedIcon />
                                                        </Button>
                                                    </Box>
                                                )}
                                            </EditedCard>
                                        </Grid>
                                    )
                                ))
                            }
                        </Grid>
                    </PaddedBox>}
                {!_isEmpty(list) && (
                    <Box sx={{ padding: '30px 0 10px 0' }}>
                        <Pagination
                            page={page}
                            count={count}
                            shape="rounded"
                            className="pagination-root"
                            onChange={onPaginationClick}
                        />
                    </Box>
                )}
            </PaddedBox>
            {
                !!currentUser
                &&
                <VerifyProfile handleCloseVerifyModal={handleCloseVerifyModal} currentUser={currentUser} />
            }
            <PaddedFooter style={{ paddingLeft: `${!!props.auth && !!props.auth.isDrawerOpen && props.auth.isDrawerOpen == true ? "200px" : "0px"}` }}>
                <Footer />
            </PaddedFooter>
        </>
    );
};
const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        searchKey: state.sfs.searchKey,
        filters: state.sfs.filters,
        sortBy: state.sfs.sortBy,
        myProfile: state.profile.myProfile || {},
        skillList: state.profile.skillList || [],
        billing: state.profile.billingDetails,
        error: state.profile.error,
        saveSuccess: state.profile.saveSuccess,
        basicInfo: state.profile.basicInfo,
        profile: state.profile
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        resetSFS: (details) => dispatch(resetSFS(details)),
        setProfileError: (details) => dispatch(setProfileError(details)),
        setProfileSuccess: (details) => dispatch(setProfileSuccess(details)),
        resetProfileSuccess: (details) => dispatch(resetProfileSuccess(details))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EngineerProfiles);
