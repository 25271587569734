import React, { useEffect, useState } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import { format } from 'date-fns';
import { Grid, Avatar, Button, Typography } from '@mui/material';
import { getProfileDetNoValidationByIdApi } from '../../../src/services/profile';
import PageLoader from '../../../src/components/Loaders/PageLoader';
import { checkIfVerified } from '../../../src/utils/helpers';
import { PaddedBox } from '../app.styles';
import Chip from '@mui/material/Chip';
import Link from '@mui/material/Link';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Header from '../../../src/components/HomePage/Header.jsx';
import { connect } from 'react-redux';
import ChatIconProfile from '../../assests/chatIconProfile.svg';
import DownLoad from '../../assests/downLoad.svg';
import LogoGoogleEngineer from '../../assests/LogoGoogleEngineer.svg';
import { getUserType } from '../../utils/user';
import './EngineerDetailPage.scss';
import { getCookie, setCookie } from '../../utils/cookie';
import Footer from '../../components/footer1/footer';
import { LoginSocialGoogle } from 'reactjs-social-login';
import { useLinkedIn } from 'react-linkedin-login-oauth2';
import NotUserName from '../../components/NotFound/NotUserName';
import { LinkedInLoginUser, socialLogin } from '../../services/signIn';
import '../gigUser/gigdetails/gigDetailsAttributes.scss';
import ReactStars from 'react-rating-stars-component';
import { checkIfPublished } from '../../utils/dataGigs';
import FindEngineerPopup from '../../components/HomePage/FindEngineerPopup';
import FindGigPopup from '../../components/HomePage/FindGigPopup';

const ReadMore = ({ children }) => {
  let text = children;
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  return (
    <p className="see-more-text">
      {isReadMore ? (text.length > 385 ? text.slice(0, 385) + '.........' : text) : text}
      <br></br>
      {text.length >= 385 ? (
        <span onClick={toggleReadMore} className="read-or-hide">
          {isReadMore ? 'See more' : 'See less'}
        </span>
      ) : (
        ''
      )}
    </p>
  );
};

const ReadLessMore = ({ children }) => {
  const text = children;
  const [isReadMoreLess, setIsReadMoreLess] = useState(true);
  const toggleReadMoreLess = () => {
    setIsReadMoreLess(!isReadMoreLess);
  };
  return (
    <p className="see-more-text">
      {isReadMoreLess ? text.slice(0, 0) : text}
      {text.length >= 385 ? (
        <span onClick={toggleReadMoreLess} className="read-or-hide">
          {isReadMoreLess ? 'More' : 'Less'}
        </span>
      ) : (
        ''
      )}
    </p>
  );
};

const EngineerDetailsPage = (props) => {
  const [profileDet, setProfileDet] = useState({});
  const [referralsData, setreferralsData] = useState(undefined);
  const [seeMore, setSeeMore] = useState(false);
  const [seeMoreEducation, setSeeMoreEducation] = useState(false);
  const { username } = useParams();
  const history = useHistory();
  const userType = getUserType();
  const [value, setValue] = useState([]);
  const [showPassword, setShowPassword] = React.useState(false);
  const [readMoreDetails, setreadMoreDetails] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [openSignUp, setOpenSignUp] = React.useState(false);
  const [openForgetEngineer, setOpenForgetEngineer] = React.useState(false);
  const [openSignUpModal, setOpenSignUpModal] = React.useState(false);
  const [openBusinessModal, setOpenBusinessModal] = React.useState(false);
  const [openForgetBusiness, setopenForgetBusiness] = React.useState(false);
  const [loading, setLoading] = useState(true);

  const modalEngineerSignUpOpen = () => {
    setOpenSignUp(true);
  };

  const businessModal = () => {
    setOpenBusinessModal(true);
  };

  const onChangee = (value) => {
    setToken(value);
  };

  const getLocation = useLocation();

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClose = () => {
    setOpenEngineerModal(false);
    setOpenEngineerSignUp(false);
    setOpenBusinessSignUp(false);
    setOpenBusinessModal(false);
    setOpenForgetBusiness(false);
    setOpenForget(false);
    setIsResetLink(false);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const { linkedInLogin } = useLinkedIn({
    clientId: process.env.REACT_APP_LINKEDIN_CLIENT_ID,
    scope: 'r_liteprofile r_emailaddress',
    redirectUri: `${window.location.origin}/linkedin`, // for Next.js, you can use `${typeof window === 'object' && window.location.origin}/linkedin`
    onSuccess: (code) => {
      LinkedInLoginUser({
        code: code,
        redirect_uri: `${window.location.origin}/linkedin`
      });
    },
    onError: (error) => {
      console.error(error);
    }
  });

  const handleGoogleLogin = (resp, userRole) => {
    const { provider, data } = resp;
    socialLogin({
      displayName: data.name,
      email: data.email,
      photo: data.picture,
      provider: provider,
      userRole: userRole
    });
  };

  async function someasync() {
    const details = await getProfileDetNoValidationByIdApi(username);
    if (details && details.profile.skillList) {
      for (let i = 0; i < details.profile.skillList.length; i++) {
        const { level } = details.profile.skillList[i];
        if (level.toLowerCase() === 'entry') {
          value.push(2);
        } else if (level.toLowerCase() === 'senior') {
          value.push(3.5);
        } else if (level.toLowerCase() === 'expert') {
          value.push(5);
        }
      }
    }
    setProfileDet(details.profile);
    if (!!details.referralsDetails) {
      setreferralsData(details.referralsDetails);
    }
  }

  useEffect(() => {
    if (!!getCookie('redirect_profile_path')) {
      setCookie('redirect_profile_path', null, 1);
    }
    someasync();
  }, []);

  function getAge(dateString) {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }

  useEffect(() => {
    const fetchData = async () => {
      const details = await getProfileDetNoValidationByIdApi(username);
      setProfileDet(details.profile);
      if (details.referralsDetails) {
        setreferralsData(details.referralsDetails);
      }
      setLoading(false);
    };
    fetchData();
  }, [username]);

  if (loading) {
    return <PageLoader />;
  }

  if (_isEmpty(profileDet)) {
    return <NotUserName />;
  }

  return _isEmpty(loading) ? (
    <React.Fragment>
    <Header />
    <PaddedBox
      style={{
        marginRight: `${
          !!props.auth && !!props.auth.isDrawerOpen && props.auth.isDrawerOpen == true
            ? '0px'
            : ''
        }`,
        minHeight: '590px'
      }}
    >
      {(userType === 'engineer' || userType === 'business' || userType === 'admin') && (
        <div className="profile-display-content-res">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={7} md={7}>
              <Card
                sx={{
                  boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
                  borderRadius: '10px',
                  marginTop: '-16px'
                }}
              >
                <CardContent sx={{ padding: '20px' }}>
                  <Box
                    sx={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={8} md={4}>
                        <Grid
                          container
                          spacing={2}
                          sx={{ alignItems: 'center', justifyContent: 'center' }}
                        >
                          <Grid
                            item
                            xs={12}
                            sm={8}
                            md={12}
                            sx={{ alignItems: 'center', justifyContent: 'center' }}
                          >
                            <Avatar
                              src={_get(profileDet, 'profileData.myProfile.profilePic', '')}
                              sx={{
                                width: 230,
                                height: 230,
                                objectFit: 'cover',
                                boxShadow: 'rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset',
                                backgroundColor: '#0543AB',
                                margin: 'auto'
                              }}
                            ></Avatar>
                          </Grid>
                          <div className="profile-bottom-item-fix">
                            <Grid item xs={12} sm={8} md={12}>
                              <Box sx={{ marginTop: '10px', marginLeft: '20px', width: '210px' }}>
                                <Box
                                  sx={{
                                    display: 'flex',
                                    textAlign: 'center',
                                    fontSize: '14px',
                                    marginBottom: '5px',
                                    fontWeight: '700',
                                    marginLeft: '-5px'
                                  }}
                                >
                                  <img
                                    sx={{ color: '#0000FF' }}
                                    src="https://gigengineer-production.s3.af-south-1.amazonaws.com/Assets/Profile+Display+Hourly+Rate.svg"
                                    alt="hourlyrateengineer"
                                    fontSize="medium"
                                    width="12%"
                                  />
                                  <Box sx={{ marginLeft: '11px', fontFamily: 'Poppins', marginTop: '3px', display: 'flex' }}>
                                    <div>
                                    {_get(profileDet, 'profileData.myProfile.currency', '')}
                                    </div>
                                    &nbsp;
                                    {_get(
                                      profileDet,
                                      'profileData.myProfile.hourlyRate',
                                      '0'
                                    )}{' '}
                                    / hour
                                  </Box>
                                </Box>
                                <Box
                                  sx={{
                                    display: 'flex',
                                    textAlign: 'center',
                                    fontSize: '14px',
                                    marginTop: '12px',
                                    marginLeft: '-4px'
                                  }}
                                  title="City"
                                >
                                  <img
                                    src="https://gigengineer-production.s3.af-south-1.amazonaws.com/Assets/Profile+Display+City.svg"
                                    alt="loctionengineer"
                                    fontSize="medium"
                                    width="11%"
                                  />
                                  <Box className="text-city-overflow">
                                    {_get(
                                      profileDet,
                                      'profileData.basicInfo.city',
                                      'Not Disclosed'
                                    )}
                                  </Box>
                                </Box>
                                <Box
                                  sx={{
                                    display: 'flex',
                                    textAlign: 'center',
                                    fontSize: '14px',
                                    marginTop: '12px',
                                    marginLeft: '-5px'
                                  }}
                                >
                                  <img
                                    src="https://gigengineer-production.s3.af-south-1.amazonaws.com/Assets/Profile+Display+Country.svg"
                                    alt="countrylogoengineer"
                                    fontSize="medium"
                                    width="11%"
                                  />
                                  <Box sx={{ color: '#007FED', marginLeft: '10px' }}>
                                    {_get(
                                      profileDet,
                                      'profileData.basicInfo.country',
                                      'Not Disclosed'
                                    )}
                                  </Box>
                                </Box>
                              </Box>
                            </Grid>
                          </div>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={8} md={8}>
                        <div className="name-alignment-issue-engineer">
                          <Box>
                            <Box sx={{ textAlign: 'right', marginTop: '10px' }}>
                              {userType === 'business' && (
                                <Link
                                  title="Chat"
                                  className="card-Link"
                                  component="button"
                                  variant="body2"
                                  onClick={(event) => {
                                    event.preventDefault();
                                    history.push(
                                      `/ochat?to=${_get(profileDet, '_id', '')}`
                                      
                                    );
                                  }}
                                >
                                  <img
                                    sx={{ color: '#0000FF' }}
                                    src={ChatIconProfile}
                                    alt="chaticon"
                                    fontSize="medium"
                                    width="80%"
                                  />
                                </Link>
                              )}
                            </Box>
                            <div className="name-alignment-issue-engineer">
                              {(userType === 'engineer' || userType === 'admin') && (
                                <Box
                                  sx={{
                                    textAlign: 'left',
                                    fontSize: '18.6px',
                                    marginLeft: '30px',
                                    lineHeight: '20px',
                                    fontWeight: '600',
                                    marginTop: '0px',
                                    textTransform: 'capitalize',
                                    fontFamily: 'Poppins',
                                    fontStyle: 'normal'
                                  }}
                                >
                                  {_get(profileDet, 'profileData.basicInfo.name')}
                                </Box>
                              )}
                              {userType === 'business' && (
                                <Box
                                  sx={{
                                    textAlign: 'left',
                                    fontSize: '18.6px',
                                    marginLeft: '30px',
                                    lineHeight: '20px',
                                    fontWeight: '600',
                                    marginTop: '-32px',
                                    textTransform: 'capitalize',
                                    fontFamily: 'Poppins',
                                    fontStyle: 'normal'
                                  }}
                                >
                                  {_get(profileDet, 'profileData.basicInfo.name')}
                                </Box>
                              )}
                              <Box
                                sx={{
                                  fontSize: '15.5px',
                                  fontWeight: '500',
                                  color: '#818181',
                                  textAlign: 'left',
                                  lineHeight: '20px',
                                  paddingBottom: '10px',
                                  marginTop: '15px',
                                  marginLeft: '30px',
                                  width: '600px'
                                }}
                              >
                                {_get(profileDet, 'profileData.myProfile.title')}
                              </Box>
                              <Box
                                sx={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  textAlign: 'center',
                                  alignItems: 'center',
                                  marginTop: '15px',
                                  marginLeft: '30px'
                                }}
                              >
                                <Box
                                  sx={{
                                    fontSize: '12.4px',
                                    fontWeight: '700',
                                    lineHeight: '1',
                                    display: 'flex',
                                    color: '#0543AB',
                                    justifyContent: 'center'
                                  }}
                                >
                                  Gender
                                  <Box
                                    sx={{
                                      fontSize: '12.4px',
                                      fontWeight: '500',
                                      lineHeight: '1',
                                      color: '#0E1724',
                                      marginLeft: '5px',
                                      marginTop: '0px'
                                    }}
                                  >
                                    {_get(
                                      profileDet,
                                      'profileData.basicInfo.gender',
                                      'Not Disclosed'
                                    )}
                                  </Box>
                                </Box>
                                <Box
                                  sx={{
                                    fontSize: '12.4px',
                                    fontWeight: '700',
                                    lineHeight: '1',
                                    marginLeft: '80px',
                                    display: 'flex',
                                    color: '#0543AB'
                                  }}
                                >
                                  Age
                                  <Box
                                    sx={{
                                      fontSize: '12.4px',
                                      fontWeight: '500',
                                      lineHeight: '1',
                                      color: '#0E1724',
                                      marginLeft: '5px',
                                      marginTop: '0px'
                                    }}
                                  >
                                    {getAge(
                                      _get(
                                        profileDet,
                                        'profileData.basicInfo.dob',
                                        'Not Disclosed'
                                      )
                                    )}
                                  </Box>
                                </Box>
                              </Box>
                              <Box>
                                <CardContent sx={{ textOverflow: 'ellipsis' }}>
                                  {!!_get(profileDet, 'profileData.myProfile.overview') && (
                                    <div
                                      style={{
                                        height: `${readMoreDetails ? 'fit-content' : '190px'}`,
                                        overflow: 'hidden',
                                        fontSize: '14.6px',
                                        fontWeight: '500',
                                        marginLeft: '15px',
                                        fontFamily: 'Poppins'
                                      }}
                                    >
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html: _get(
                                            profileDet,
                                            'profileData.myProfile.overview'
                                          )
                                        }}
                                      ></div>
                                    </div>
                                  )}

                                  {!!_get(profileDet, 'profileData.myProfile.overview') &&
                                    _get(profileDet, 'profileData.myProfile.overview').length >
                                      353 && (
                                      <button
                                        className="profile-overview-error-msg"
                                        onClick={() => {
                                          setreadMoreDetails(!readMoreDetails);
                                        }}
                                      >{`${readMoreDetails ? 'See less' : 'See more'}`}</button>
                                    )}
                                </CardContent>
                              </Box>
                            </div>
                          </Box>
                        </div>
                      </Grid>
                    </Grid>
                  </Box>
                </CardContent>
              </Card>
              {!_isEmpty(_get(profileDet, 'profileData.employmentHistoryList')) &&
                _get(profileDet, 'profileData.employmentHistoryList').length > 0 && (
                  <Card
                    sx={{
                      boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
                      borderRadius: '10px',
                      marginTop: '30px'
                    }}
                  >
                    <CardContent sx={{ padding: '20px' }}>
                      <Box
                        sx={{
                          fontSize: '18.6px',
                          lineHeight: '35px',
                          fontWeight: '700',
                          color: '#000000',
                          textTransform: 'capitalize',
                          fontFamily: 'Poppins'
                        }}
                      >
                        Employment History
                      </Box>
                      <Divider
                        sx={{
                          borderColor: 'rgba(0, 0, 0, 0.2)',
                          marginTop: '13px',
                          marginBottom: '9px'
                        }}
                      />
                      {_get(profileDet, 'profileData.employmentHistoryList').length > 0 &&
                      seeMore === false ? (
                        <>
                          {_get(profileDet, 'profileData.employmentHistoryList').map(
                            (emp, index) =>
                              index <= 1 ? (
                                <React.Fragment>
                                  <Box
                                    key={`emp${index}`}
                                    sx={{
                                      display: 'flex',
                                      alignItems: 'start',
                                      gap: '10px',
                                      paddingTop: '10px'
                                    }}
                                  >
                                    <Grid item xs={12} md={12} lg={12}>
                                      <Box>
                                        <Box
                                          sx={{
                                            fontSize: '15.5px',
                                            fontWeight: '500',
                                            color: '#000000'
                                          }}
                                        >
                                          {_get(emp, 'designation')}
                                        </Box>
                                        <Box
                                          sx={{
                                            fontSize: '15.5px',
                                            fontWeight: '500',
                                            color: '#818181'
                                          }}
                                        >
                                          {_get(emp, 'companyName')}
                                        </Box>
                                        <Box
                                          sx={{
                                            fontSize: '15.5px',
                                            color: '#818181',
                                            display: 'flex',
                                            alignItems: 'center'
                                          }}
                                        >
                                          {!!_get(emp, 'fromDate') &&
                                            format(new Date(_get(emp, 'fromDate')), 'MMM, yyyy')}
                                          &nbsp;-&nbsp;
                                          {!!_get(emp, 'toDate')
                                            ? format(new Date(_get(emp, 'toDate')), 'MMM, yyyy')
                                            : 'Currently Working'}
                                        </Box>
                                        <Box sx={{ fontSize: '15.5px', color: '#818181' }}>
                                          {_get(emp, 'country')}
                                        </Box>
                                        <Box sx={{ fontSize: '15.5px', color: '#818181' }}>
                                          <ReadLessMore>{_get(emp, 'description')}</ReadLessMore>
                                        </Box>
                                        <Box sx={{ fontSize: '15.5px', color: '#818181' }}>
                                          {_get(emp, 'achievement')}
                                        </Box>
                                      </Box>
                                    </Grid>
                                  </Box>
                                  {index !=
                                    _get(profileDet, 'profileData.employmentHistoryList').length -
                                      1 && (
                                    <Divider
                                      sx={{
                                        borderColor: 'rgba(0, 0, 0, 0.2)',
                                        marginTop: '15px'
                                      }}
                                    />
                                  )}
                                </React.Fragment>
                              ) : (
                                ''
                              )
                          )}
                          {_get(profileDet, 'profileData.employmentHistoryList').length > 2 ? (
                            <Box>
                              <Button
                                onClick={() => setSeeMore(true)}
                                sx={{
                                  color: '#1976D2',
                                  textTransform: 'capitalize',
                                  fontSize: '15.5px',
                                  marginLeft: '-9px',
                                  marginTop: '20px'
                                }}
                              >
                                See more(
                                {_get(profileDet, 'profileData.employmentHistoryList').length - 2}
                                )
                              </Button>
                            </Box>
                          ) : (
                            ''
                          )}
                        </>
                      ) : (
                        ''
                      )}
                      {_get(profileDet, 'profileData.employmentHistoryList').length > 0 &&
                      seeMore === true ? (
                        <>
                          {_get(profileDet, 'profileData.employmentHistoryList').map(
                            (emp, index) => (
                              <React.Fragment>
                                <Box
                                  key={`emp${index}`}
                                  sx={{
                                    display: 'flex',
                                    alignItems: 'start',
                                    gap: '10px',
                                    paddingTop: '10px'
                                  }}
                                >
                                  <Grid item xs={12} md={12} lg={12}>
                                    <Box>
                                      <Box
                                        sx={{
                                          fontSize: '15.5px',
                                          fontWeight: '500',
                                          color: '#000000'
                                        }}
                                      >
                                        {_get(emp, 'designation')}
                                      </Box>
                                      <Box
                                        sx={{
                                          fontSize: '15.5px',
                                          fontWeight: '500',
                                          color: '#818181'
                                        }}
                                      >
                                        {_get(emp, 'companyName')}
                                      </Box>
                                      <Box
                                        sx={{
                                          fontSize: '15.5px',
                                          color: '#818181',
                                          display: 'flex',
                                          alignItems: 'center'
                                        }}
                                      >
                                        {!!_get(emp, 'fromDate') &&
                                          format(new Date(_get(emp, 'fromDate')), 'MMM, yyyy')}
                                        &nbsp;-&nbsp;
                                        {!!_get(emp, 'toDate')
                                          ? format(new Date(_get(emp, 'toDate')), 'MMM, yyyy')
                                          : 'Currently Working'}
                                      </Box>
                                      <Box sx={{ fontSize: '15.5px', color: '#818181' }}>
                                        {_get(emp, 'country')}
                                      </Box>
                                      <Box sx={{ fontSize: '15.5px', color: '#818181' }}>
                                        <ReadLessMore>{_get(emp, 'description')}</ReadLessMore>
                                      </Box>
                                      <Box sx={{ fontSize: '15.5px', color: '#818181' }}>
                                        {_get(emp, 'achievement')}
                                      </Box>
                                    </Box>
                                  </Grid>
                                </Box>
                                {index !=
                                  _get(profileDet, 'profileData.employmentHistoryList').length -
                                    1 && (
                                  <Divider
                                    sx={{ borderColor: 'rgba(0, 0, 0, 0.2)', marginTop: '15px' }}
                                  />
                                )}
                              </React.Fragment>
                            )
                          )}
                          <Divider
                            sx={{ borderColor: 'rgba(0, 0, 0, 0.2)', marginTop: '15px' }}
                          />
                          <Box>
                            <Button
                              onClick={() => setSeeMore(false)}
                              sx={{
                                color: '#1976D2',
                                textTransform: 'capitalize',
                                fontSize: '15.5px',
                                marginLeft: '-9px',
                                marginTop: '20px'
                              }}
                            >
                              See Less
                            </Button>
                          </Box>
                        </>
                      ) : (
                        ''
                      )}
                    </CardContent>
                  </Card>
                )}
              {!_isEmpty(_get(profileDet, 'profileData.educationInfoList')) &&
                _get(profileDet, 'profileData.educationInfoList').length > 0 && (
                  <Card
                    sx={{
                      boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
                      borderRadius: '10px',
                      marginTop: '30px'
                    }}
                  >
                    <CardContent sx={{ padding: '20px' }}>
                      <Box
                        sx={{
                          fontSize: '18.6px',
                          lineHeight: '35px',
                          fontWeight: '600',
                          color: '#000000',
                          textTransform: 'uppercase',
                          textTransform: 'capitalize',
                          fontFamily: 'Poppins'
                        }}
                      >
                        Education History
                      </Box>
                      <Divider
                        sx={{
                          borderColor: 'rgba(0, 0, 0, 0.2)',
                          marginTop: '13px',
                          marginBottom: '9px'
                        }}
                      />
                      {_get(profileDet, 'profileData.educationInfoList').length > 0 &&
                      seeMoreEducation === false ? (
                        <>
                          {_get(profileDet, 'profileData.educationInfoList').map((edu, index) =>
                            index <= 1 ? (
                              <React.Fragment>
                                <Box
                                  key={`edu${index}`}
                                  sx={{
                                    display: 'flex',
                                    alignItems: 'start',
                                    gap: '10px',
                                    paddingTop: '10px'
                                  }}
                                >
                                  <Box>
                                    <Box sx={{ fontSize: '15.5px', fontWeight: '500' }}>
                                      {_get(edu, 'instituteName')}
                                    </Box>
                                    <Box
                                      sx={{
                                        fontSize: '15.5px',
                                        fontWeight: '500',
                                        color: '#818181'
                                      }}
                                    >
                                      {_get(edu, 'degree')}
                                    </Box>
                                    <Box sx={{ fontSize: '15.5px', color: '#818181' }}>
                                      {_get(edu, 'areaOfSpecialization')}
                                    </Box>
                                    <Box
                                      sx={{
                                        fontSize: '15.5px',
                                        color: '#818181',
                                        display: 'flex',
                                        alignItems: 'center'
                                      }}
                                    >
                                      {!!_get(edu, 'fromDate') &&
                                        format(new Date(_get(edu, 'fromDate')), 'MMM, yyyy')}
                                      &nbsp;-&nbsp;
                                      {!!_get(edu, 'toDate')
                                        ? format(new Date(_get(edu, 'toDate')), 'MMM, yyyy')
                                        : 'Currently Working'}
                                    </Box>
                                    {
                                      <Box sx={{ fontSize: '15.5px', color: '#818181' }}>
                                        <ReadLessMore>{_get(edu, 'description')}</ReadLessMore>
                                      </Box>
                                    }
                                  </Box>
                                </Box>
                                {index !=
                                  _get(profileDet, 'profileData.educationInfoList').length -
                                    1 && (
                                  <Divider
                                    sx={{ borderColor: 'rgba(0, 0, 0, 0.2)', marginTop: '15px' }}
                                  />
                                )}
                              </React.Fragment>
                            ) : (
                              ''
                            )
                          )}
                          {_get(profileDet, 'profileData.educationInfoList').length > 2 ? (
                            <Box>
                              <Button
                                onClick={() => setSeeMoreEducation(true)}
                                sx={{
                                  color: '#1976D2',
                                  textTransform: 'capitalize',
                                  fontSize: '15.5px',
                                  marginLeft: '-9px',
                                  marginTop: '20px'
                                }}
                              >
                                See more(
                                {_get(profileDet, 'profileData.educationInfoList').length - 2})
                              </Button>
                            </Box>
                          ) : (
                            ''
                          )}
                        </>
                      ) : (
                        ''
                      )}
                      {_get(profileDet, 'profileData.educationInfoList').length > 0 &&
                      seeMoreEducation === true ? (
                        <>
                          {_get(profileDet, 'profileData.educationInfoList').map((edu, index) => (
                            <React.Fragment>
                              <Box
                                key={`edu${index}`}
                                sx={{
                                  display: 'flex',
                                  alignItems: 'start',
                                  gap: '10px',
                                  paddingTop: '10px'
                                }}
                              >
                                <Box>
                                  <Box sx={{ fontSize: '15.5px', fontWeight: '500' }}>
                                    {_get(edu, 'instituteName')}
                                  </Box>
                                  <Box
                                    sx={{
                                      fontSize: '15.5px',
                                      fontWeight: '500',
                                      color: '#818181'
                                    }}
                                  >
                                    {_get(edu, 'degree')}
                                  </Box>
                                  <Box sx={{ fontSize: '15.5px', color: '#818181' }}>
                                    {_get(edu, 'areaOfSpecialization')}
                                  </Box>
                                  <Box
                                    sx={{
                                      fontSize: '15.5px',
                                      color: '#818181',
                                      display: 'flex',
                                      alignItems: 'center'
                                    }}
                                  >
                                    {!!_get(edu, 'fromDate') &&
                                      format(new Date(_get(edu, 'fromDate')), 'MMM, yyyy')}
                                    &nbsp;-&nbsp;
                                    {!!_get(edu, 'toDate')
                                      ? format(new Date(_get(edu, 'toDate')), 'MMM, yyyy')
                                      : 'Currently Working'}
                                  </Box>
                                  {
                                    <Box sx={{ fontSize: '15.5px', color: '#818181' }}>
                                      <ReadLessMore>{_get(edu, 'description')}</ReadLessMore>
                                    </Box>
                                  }
                                </Box>
                              </Box>
                              {index !=
                                _get(profileDet, 'profileData.educationInfoList').length - 1 && (
                                <Divider
                                  sx={{ borderColor: 'rgba(0, 0, 0, 0.2)', marginTop: '15px' }}
                                />
                              )}
                            </React.Fragment>
                          ))}
                          <Divider
                            sx={{ borderColor: 'rgba(0, 0, 0, 0.2)', marginTop: '15px' }}
                          />
                          <Box>
                            <Button
                              onClick={() => setSeeMoreEducation(false)}
                              sx={{
                                color: '#1976D2',
                                textTransform: 'capitalize',
                                fontSize: '15.5px',
                                marginLeft: '-9px',
                                marginTop: '20px'
                              }}
                            >
                              See Less
                            </Button>
                          </Box>
                        </>
                      ) : (
                        ''
                      )}
                    </CardContent>
                  </Card>
                )}
              {/* {console.log(profileDet)} */}

              {!_isEmpty(_get(profileDet, 'profileData.certificationList')) &&
              _get(profileDet, 'profileData.certificationList').length > 0 ? (
                <Card
                  sx={{
                    boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
                    borderRadius: '10px',
                    marginTop: '30px'
                  }}
                >
                  <CardContent sx={{ padding: '20px' }}>
                    <Box
                      sx={{
                        fontSize: '18.6px',
                        lineHeight: '35px',
                        fontWeight: '600',
                        color: '#000000',
                        textTransform: 'capitalize',
                        fontFamily: 'Poppins'
                      }}
                    >
                      Certifications
                    </Box>
                    <Divider
                      sx={{
                        borderColor: 'rgba(0, 0, 0, 0.2)',
                        marginTop: '13px',
                        marginBottom: '9px'
                      }}
                    />
                    <>
                      {_get(profileDet, 'profileData.certificationList').map(
                        (certification, index) => (
                          <Grid
                            container
                            spacing={1}
                            alignItems="center"
                            className={`h-padding-top-${index < 1 ? '5' : '16'}`}
                            key={`certification${index}`}
                          >
                            <Grid item xs={12} md={12} lg={12}>
                              {_get(certification, 'certificationFile') != '' ? (
                                <Box
                                  sx={{
                                    color: '#000000',
                                    fontSize: '15.5px',
                                    fontFamily: 'Poppins'
                                  }}
                                >
                                  {_get(certification, 'certificateName')}
                                  <Box>
                                    <Link href={_get(certification, 'certificationFile')}>
                                      <img
                                        style={{
                                          float: 'right',
                                          marginRight: '23px',
                                          marginTop: '-23px'
                                        }}
                                        src={DownLoad}
                                        alt="download"
                                        fontSize="medium"
                                        width="18px"
                                      />
                                    </Link>
                                  </Box>
                                </Box>
                              ) : (
                                <Box sx={{ color: '#000000', fontSize: '15.5px' }}>
                                  {_get(certification, 'certificateName')}
                                </Box>
                              )}
                              {index !=
                                _get(profileDet, 'profileData.certificationList').length - 1 && (
                                <Divider
                                  sx={{ borderColor: 'rgba(0, 0, 0, 0.2)', marginTop: '15px' }}
                                />
                              )}
                            </Grid>
                          </Grid>
                        )
                      )}
                    </>
                  </CardContent>
                </Card>
              ) : (
                ''
              )}

              {!_isEmpty(_get(profileDet, 'profileData.myProfile.fieldOfSpecialization')) &&
                _get(profileDet, 'profileData.myProfile.fieldOfSpecialization').length > 0 && (
                  <Card
                    sx={{
                      boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
                      borderRadius: '10px',
                      marginTop: '30px'
                    }}
                  >
                    <CardContent sx={{ padding: '20px' }}>
                      <Box
                        sx={{
                          fontSize: '18.6px',
                          lineHeight: '35px',
                          fontWeight: '600',
                          color: '#000000',
                          fontFamily: 'Poppins',
                          textTransform: 'capitalize'
                        }}
                      >
                        Field of expertise
                      </Box>
                      <Divider
                        sx={{
                          borderColor: 'rgba(0, 0, 0, 0.2)',
                          marginTop: '13px',
                          marginBottom: '9px'
                        }}
                      />
                      <>
                        {_get(profileDet, 'profileData.myProfile.fieldOfSpecialization').map(
                          (expertise, index) => (
                            <Grid
                              container
                              spacing={1}
                              alignItems="center"
                              className={`h-padding-top-${index < 1 ? '5' : '16'}`}
                              key={`certification${index}`}
                            >
                              <Grid item xs={12} md={9} lg={9}>
                                <Box sx={{ color: '#000000', fontSize: '15.5px' }}>
                                  {expertise}
                                </Box>
                                {index !=
                                  _get(profileDet, 'profileData.myProfile.fieldOfSpecialization')
                                    .length -
                                    1 && (
                                  <Divider
                                    sx={{ borderColor: 'rgba(0, 0, 0, 0.2)', marginTop: '15px' }}
                                  />
                                )}
                              </Grid>
                            </Grid>
                          )
                        )}
                      </>
                    </CardContent>
                  </Card>
                )}
              {!_isEmpty(_get(profileDet, 'profileData.publicationList')) &&
                _get(profileDet, 'profileData.publicationList').length > 0 && (
                  <Card
                    sx={{
                      boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
                      borderRadius: '10px',
                      marginTop: '30px'
                    }}
                  >
                    <CardContent sx={{ padding: '20px' }}>
                      <Box
                        sx={{
                          fontSize: '18.6px',
                          lineHeight: '35px',
                          fontWeight: '600',
                          color: '#000000',
                          textTransform: 'capitalize',
                          fontFamily: 'Poppins'
                        }}
                      >
                        Publications
                      </Box>
                      <Divider
                        sx={{
                          borderColor: 'rgba(0, 0, 0, 0.2)',
                          marginTop: '13px',
                          marginBottom: '9px'
                        }}
                      />
                      <>
                        <Grid
                          container
                          spacing={1}
                          className="h-padding-top-16"
                          alignItems="center"
                          color="#818181"
                        ></Grid>
                        {_get(profileDet, 'profileData.publicationList').map(
                          (publication, index) => (
                            <Grid
                              container
                              spacing={1}
                              alignItems="center"
                              className={`h-padding-top-${index < 1 ? '5' : '16'}`}
                              key={`publication${index}`}
                            >
                              <Grid item xs={12} md={12} lg={12}>
                                {_get(publication, 'publicationUrl') != '' ? (
                                  <Link
                                    href={_get(publication, 'publicationUrl')}
                                    target="_blank"
                                  >
                                    <Box
                                      sx={{
                                        color: 'blue',
                                        fontSize: '15.5px',
                                        display: 'flex',
                                        width: '100%',
                                        display: 'block'
                                      }}
                                    >
                                      {_get(publication, 'publicationName')}
                                    </Box>
                                  </Link>
                                ) : (
                                  <Box
                                    sx={{
                                      color: '#000000',
                                      fontSize: '15.5px',
                                      display: 'flex',
                                      width: '100%',
                                      display: 'block'
                                    }}
                                  >
                                    {_get(publication, 'publicationName')}
                                  </Box>
                                )}
                                {index !=
                                  _get(profileDet, 'profileData.publicationList').length - 1 && (
                                  <Divider
                                    sx={{ borderColor: 'rgba(0, 0, 0, 0.2)', marginTop: '15px' }}
                                  />
                                )}
                              </Grid>
                            </Grid>
                          )
                        )}
                      </>
                    </CardContent>
                  </Card>
                )}
              {!_isEmpty(_get(profileDet, 'profileData.myProfile.tools')) &&
                _get(profileDet, 'profileData.myProfile.tools').length > 0 && (
                  <Card
                    sx={{
                      boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
                      borderRadius: '10px',
                      marginTop: '30px'
                    }}
                  >
                    <CardContent sx={{ padding: '20px' }}>
                      <Box
                        sx={{
                          fontSize: '18.6px',
                          lineHeight: '35px',
                          fontWeight: '600',
                          color: '#000000',
                          textTransform: 'capitalize',
                          fontFamily: 'Poppins'
                        }}
                      >
                        Tools and technologies
                      </Box>
                      <Divider
                        sx={{
                          borderColor: 'rgba(0, 0, 0, 0.2)',
                          marginTop: '13px',
                          marginBottom: '9px'
                        }}
                      />
                      <Box sx={{ paddingTop: '20px', fontSize: '18px' }}>
                        {_get(profileDet, 'profileData.myProfile.tools').map(
                          (expertise, index) => (
                            <React.Fragment key={index}>
                              <Chip
                                label={expertise}
                                variant="outlined"
                                sx={{
                                  minWidth: '100px',
                                  fontWeight: '500',
                                  color: '#000000',
                                  marginBottom: '6px'
                                }}
                              />
                              &nbsp;&nbsp;
                            </React.Fragment>
                          )
                        )}
                      </Box>
                    </CardContent>
                  </Card>
                )}
              <Box sx={{ marginTop: '40px' }}></Box>
              {!!referralsData &&
                Array.isArray(referralsData) &&
                referralsData.length > 0 &&
                checkIfVerified(referralsData).length > 0 && (
                  <div className="reviews-wrapper">
                    <div className="reviews-heading reviews-heading-gig-applied-eng">
                      {'Work History'}
                    </div>
                    <div className="completed-gigs-txt">{'Completed Gigs'}</div>
                    <div className="completed-gigs-txt-underline" />
                    {checkIfVerified(referralsData).map((item, index) => (
                      <div>
                        <div className="spacer"></div>
                        <div className="review-gig-name">
                          {item.gigId.titleAndSkills.projectTitle}
                        </div>
                        <div className="stars-row">
                          <ReactStars
                            classNames={'stars'}
                            count={5}
                            activeColor="#ffd700"
                            size={25}
                            edit={false}
                            value={
                              !!item.publicFeedback.feedbackStarData &&
                              (
                                item.publicFeedback.feedbackStarData.reduce(
                                  (sum, feedback) =>
                                    sum +
                                    (feedback.feedbackRating > 0
                                      ? parseInt(feedback.feedbackRating)
                                      : 0),
                                  0
                                ) /
                                Math.max(
                                  item.publicFeedback.feedbackStarData.filter(
                                    (feedback) => feedback.feedbackRating > 0
                                  ).length,
                                  1
                                )
                              ).toFixed(2)
                            }
                          />
                          <div className="stars-count-txt">
                            {!!item.publicFeedback.feedbackStarData &&
                              (
                                item.publicFeedback.feedbackStarData.reduce(
                                  (sum, feedback) =>
                                    sum +
                                    (feedback.feedbackRating > 0
                                      ? parseInt(feedback.feedbackRating)
                                      : 0),
                                  0
                                ) /
                                Math.max(
                                  item.publicFeedback.feedbackStarData.filter(
                                    (feedback) => feedback.feedbackRating > 0
                                  ).length,
                                  1
                                )
                              ).toFixed(2)}
                          </div>
                        </div>
                        <div className="feedback-txt">{`“${item.publicFeedback.feedbackTextData}”`}</div>
                      </div>
                    ))}
                    {/* <Pagination
                                      // count={props.pagination}
                                      shape="rounded"
                                      className="pagination-root"
                                      size='small'
                                      // onChange={props.handleChange}
                                      // defaultPage={props.defaultPage || 1}
                                      /> */}
                    {/* <div className='see-more'>{'See more.'}</div> */}
                  </div>
                )}
            </Grid>
            <div className="bet-ween-line-container"></div>
            <Grid item xs={12} sm={4} md={4} ml={3}>
              {!_isEmpty(_get(profileDet, 'profileData.myProfile.availabilityHoursPerWeek')) && (
                <Card
                  sx={{
                    boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
                    borderRadius: '10px',
                    marginTop: '-13px'
                  }}
                >
                  <CardContent sx={{ padding: '20px' }}>
                    <Box
                      sx={{
                        fontSize: '18.6px',
                        lineHeight: '35px',
                        fontWeight: '600',
                        textAlign: 'center',
                        color: '#000000',
                        textTransform: 'capitalize',
                        fontFamily: 'Poppins'
                      }}
                    >
                      Availability
                    </Box>
                    <Box sx={{ textAlign: 'center' }}>
                      <Box
                        sx={{
                          fontSize: '15.5px',
                          fontWeight: '500',
                          lineHeight: '28px',
                          textAlign: 'center',
                          alignItems: 'center',
                          color: '#818181'
                        }}
                      >
                        {_get(profileDet, 'profileData.myProfile.availabilityHoursPerWeek')
                          ? ` ${_get(
                              profileDet,
                              'profileData.myProfile.availabilityHoursPerWeek'
                            )} Hr/Week `
                          : 'Not Disclosed'}
                      </Box>
                    </Box>
                  </CardContent>
                </Card>
              )}
              {!_isEmpty(_get(profileDet, 'profileData.languageList')) && (
                <Card
                  sx={{
                    boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
                    borderRadius: '10px',
                    marginTop: '30px'
                  }}
                >
                  <CardContent sx={{ padding: '20px' }}>
                    <Box
                      sx={{
                        fontSize: '18.6px',
                        lineHeight: '35px',
                        fontWeight: '600',
                        textAlign: 'center',
                        color: '#000000',
                        textTransform: 'capitalize',
                        fontFamily: 'Poppins'
                      }}
                    >
                      Languages
                    </Box>
                    <Box sx={{ textAlign: 'center' }}>
                      {_get(profileDet, 'profileData.languageList').map((lang, index) => (
                        <Box
                          sx={{
                            fontSize: '15.5px',
                            fontWeight: '500',
                            lineHeight: '28px',
                            textAlign: 'center',
                            alignItems: 'center',
                            color: '#818181'
                          }}
                        >
                          {_get(lang, 'language')}: {_get(lang, 'proficienyLevel')}
                        </Box>
                      ))}
                    </Box>
                  </CardContent>
                </Card>
              )}
              {!_isEmpty(_get(profileDet, 'profileData.skillList')) && (
                <Card
                  sx={{
                    boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
                    borderRadius: '10px',
                    marginTop: '30px'
                  }}
                >
                  <CardContent sx={{ padding: '20px' }}>
                    <Box
                      sx={{
                        fontSize: '18.6px',
                        lineHeight: '35px',
                        fontWeight: '600',
                        textAlign: 'center',
                        color: '#000000',
                        textTransform: 'capitalize',
                        fontFamily: 'Poppins'
                      }}
                    >
                      Skills
                    </Box>
                    <Box sx={{ textAlign: 'center' }}>
                      {_get(profileDet, 'profileData.skillList').map((skill, index) => (
                        <React.Fragment key={index}>
                          <Box
                            sx={{
                              fontSize: '15.5px',
                              fontWeight: '500',
                              lineHeight: '28px',
                              textAlign: 'center',
                              alignItems: 'center',
                              color: '#818181'
                            }}
                          >
                            {_get(skill, 'skillName')}
                          </Box>
                        </React.Fragment>
                      ))}
                    </Box>
                  </CardContent>
                </Card>
              )}

              {!_isEmpty(_get(profileDet, 'profileData.reviewsList')) &&
                !!checkIfPublished(_get(profileDet, 'profileData.reviewsList')) &&
                checkIfPublished(_get(profileDet, 'profileData.reviewsList')).length > 0 && (
                  <Card
                    sx={{
                      boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
                      borderRadius: '10px',
                      marginTop: '30px'
                    }}
                  >
                    <CardContent sx={{ padding: '20px' }}>
                      <Box
                        sx={{
                          fontSize: '18.6px',
                          lineHeight: '35px',
                          fontWeight: '600',
                          textAlign: 'center',
                          color: '#000000'
                        }}
                      >
                        {userType == 'admin' ? 'Manual Reviews' : 'Reviews'}
                      </Box>
                      <Box sx={{ textAlign: 'center' }}>
                        {checkIfPublished(_get(profileDet, 'profileData.reviewsList')).map(
                          (review, index) => (
                            <React.Fragment key={index}>
                              <Box
                                sx={{
                                  fontSize: '15.5px',
                                  fontWeight: '500',
                                  lineHeight: '28px',
                                  textAlign: 'center',
                                  alignItems: 'center',
                                  color: '#818181'
                                }}
                              >
                                <p style={{ textAlign: 'center' }}>
                                  {review.nameOfSource != 'Other'
                                    ? review.nameOfSource
                                    : review.specification}
                                </p>
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                  }}
                                  className="stars-row"
                                >
                                  <ReactStars
                                    classNames={'stars'}
                                    count={5}
                                    activeColor="#ffd700"
                                    size={25}
                                    edit={false}
                                    value={Number(
                                      ((review.rating / review.outOf) * 5).toFixed(2)
                                    )}
                                  />
                                  <div className="stars-count-txt">
                                    {((review.rating / review.outOf) * 5).toFixed(2)}
                                  </div>
                                </div>
                                <p style={{ textAlign: 'center' }}>
                                  <strong>Link: </strong>
                                  <a href={review.reviewLink} target={'_blank'}>
                                    {review.reviewLink}
                                  </a>
                                </p>
                                {index <
                                  _get(profileDet, 'profileData.reviewsList').length - 1 && (
                                  <hr />
                                )}
                              </Box>
                            </React.Fragment>
                          )
                        )}
                      </Box>
                    </CardContent>
                  </Card>
                )}
            </Grid>
          </Grid>
        </div>
      )}
      {userType === undefined && (
        <div className="profile-display-content-responsive">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={8} md={8}>
              <Card
                sx={{
                  boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
                  borderRadius: '10px',
                  marginTop: '-16px'
                }}
              >
                <CardContent sx={{ padding: '20px' }}>
                  <Box
                    sx={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={8} md={4}>
                        <Grid
                          container
                          spacing={2}
                          sx={{ alignItems: 'center', justifyContent: 'center' }}
                        >
                          <Grid
                            item
                            xs={12}
                            sm={8}
                            md={12}
                            sx={{ alignItems: 'center', justifyContent: 'center' }}
                          >
                            <Avatar
                              src={_get(profileDet, 'profileData.myProfile.profilePic', '')}
                              sx={{
                                width: 230,
                                height: 230,
                                objectFit: 'cover',
                                boxShadow: 'rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset',
                                backgroundColor: '#0543AB',
                                margin: 'auto'
                              }}
                            ></Avatar>
                          </Grid>
                          <div className="profile-bottom-item-fix">
                            <Grid item xs={12} sm={8} md={12}>
                              <Box sx={{ marginTop: '10px', marginLeft: '20px', width: '210px' }}>
                                <Box
                                  sx={{
                                    display: 'flex',
                                    textAlign: 'center',
                                    fontSize: '14px',
                                    marginBottom: '5px',
                                    fontWeight: '700',
                                    marginLeft: '-5px'
                                  }}
                                >
                                  <img
                                    sx={{ color: '#0000FF' }}
                                    src="https://gigengineer-production.s3.af-south-1.amazonaws.com/Assets/Profile+Display+Hourly+Rate.svg"
                                    alt="hourlyrateengineer"
                                    fontSize="medium"
                                    width="12%"
                                  />
                                <Box sx={{ marginLeft: '11px', fontFamily: 'Poppins', marginTop: '3px', display: 'flex' }}>
                                    <div>
                                    {_get(profileDet, 'profileData.myProfile.currency', '')}
                                    </div>
                                    &nbsp;
                                    {_get(
                                      profileDet,
                                      'profileData.myProfile.hourlyRate',
                                      '0'
                                    )}{' '}
                                   / hour
                                  </Box>
                                </Box>
                                <Box
                                  sx={{
                                    display: 'flex',
                                    textAlign: 'center',
                                    fontSize: '14px',
                                    marginTop: '12px',
                                    marginLeft: '-4px'
                                  }}
                                  title="City"
                                >
                                  <img
                                    src="https://gigengineer-production.s3.af-south-1.amazonaws.com/Assets/Profile+Display+City.svg"
                                    alt="loctionengineer"
                                    fontSize="medium"
                                    width="11%"
                                  />
                                  <Box className="text-city-overflow">
                                    {_get(
                                      profileDet,
                                      'profileData.basicInfo.city',
                                      'Not Disclosed'
                                    )}
                                  </Box>
                                </Box>
                                <Box
                                  sx={{
                                    display: 'flex',
                                    textAlign: 'center',
                                    fontSize: '14px',
                                    marginTop: '12px',
                                    marginLeft: '-4px'
                                  }}
                                >
                                  <img
                                    src="https://gigengineer-production.s3.af-south-1.amazonaws.com/Assets/Profile+Display+Country.svg"
                                    alt="countrylogoengineer"
                                    fontSize="medium"
                                    width="11%"
                                  />
                                  <Box sx={{ color: '#007FED', marginLeft: '10px' }}>
                                    {_get(
                                      profileDet,
                                      'profileData.basicInfo.country',
                                      'Not Disclosed'
                                    )}
                                  </Box>
                                </Box>
                              </Box>
                            </Grid>
                          </div>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={8} md={8}>
                        <div className="name-alignment-issue-engineer">
                          <Box>
                            <Box
                              sx={{
                                textAlign: 'left',
                                fontSize: '18.6px',
                                marginLeft: '25px',
                                lineHeight: '20px',
                                fontWeight: '600',
                                marginTop: '10px',
                                textTransform: 'capitalize',
                                fontFamily: 'Poppins',
                                fontStyle: 'normal'
                              }}
                            >
                              {_get(profileDet, 'profileData.basicInfo.name')}
                            </Box>
                            <Box
                              sx={{
                                fontSize: '15.5px',
                                fontWeight: '500',
                                color: '#818181',
                                textAlign: 'left',
                                lineHeight: '20px',
                                paddingBottom: '10px',
                                marginTop: '15px',
                                marginLeft: '25px',
                                width: '600px'
                              }}
                            >
                              {_get(profileDet, 'profileData.myProfile.title')}
                            </Box>
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                textAlign: 'center',
                                alignItems: 'center',
                                marginTop: '15px',
                                marginLeft: '25px'
                              }}
                            >
                              <Box
                                sx={{
                                  fontSize: '12.4px',
                                  fontWeight: '700',
                                  lineHeight: '1',
                                  display: 'flex',
                                  color: '#0543AB',
                                  justifyContent: 'center'
                                }}
                              >
                                Gender
                                <Box
                                  sx={{
                                    fontSize: '12.4px',
                                    fontWeight: '500',
                                    lineHeight: '1',
                                    color: '#0E1724',
                                    marginLeft: '5px',
                                    marginTop: '0px'
                                  }}
                                >
                                  {_get(
                                    profileDet,
                                    'profileData.basicInfo.gender',
                                    'Not Disclosed'
                                  )}
                                </Box>
                              </Box>
                              <Box
                                sx={{
                                  fontSize: '12.4px',
                                  fontWeight: '700',
                                  lineHeight: '1',
                                  marginLeft: '80px',
                                  display: 'flex',
                                  color: '#0543AB'
                                }}
                              >
                                Age
                                <Box
                                  sx={{
                                    fontSize: '12.4px',
                                    fontWeight: '500',
                                    lineHeight: '1',
                                    color: '#0E1724',
                                    marginLeft: '5px',
                                    marginTop: '0px'
                                  }}
                                >
                                  {getAge(
                                    _get(profileDet, 'profileData.basicInfo.dob', 'Not Disclosed')
                                  )}
                                </Box>
                              </Box>
                            </Box>
                            <Box>
                              <CardContent sx={{ textOverflow: 'ellipsis' }}>
                                {!!_get(profileDet, 'profileData.myProfile.overview') && (
                                  <div
                                    style={{
                                      height: `${readMoreDetails ? 'fit-content' : '190px'}`,
                                      overflow: 'hidden',
                                      fontSize: '14.6px',
                                      fontWeight: '500',
                                      marginLeft: '15px',
                                      fontFamily: 'Poppins'
                                    }}
                                  >
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: _get(profileDet, 'profileData.myProfile.overview')
                                      }}
                                    ></div>
                                  </div>
                                )}

                                {!!_get(profileDet, 'profileData.myProfile.overview') &&
                                  _get(profileDet, 'profileData.myProfile.overview').length >
                                    353 && (
                                    <button
                                      className="profile-overview-error-msg"
                                      onClick={() => {
                                        setreadMoreDetails(!readMoreDetails);
                                      }}
                                    >{`${readMoreDetails ? 'See less' : 'See more'}`}</button>
                                  )}
                              </CardContent>
                            </Box>
                          </Box>
                        </div>
                      </Grid>
                    </Grid>
                  </Box>
                </CardContent>
              </Card>
              {!_isEmpty(_get(profileDet, 'profileData.employmentHistoryList')) &&
                _get(profileDet, 'profileData.employmentHistoryList').length > 0 && (
                  <Card
                    sx={{
                      boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
                      borderRadius: '10px',
                      marginTop: '30px'
                    }}
                  >
                    <CardContent sx={{ padding: '20px' }}>
                      <Box
                        sx={{
                          fontSize: '18.6px',
                          lineHeight: '35px',
                          fontWeight: '700',
                          color: '#000000',
                          textTransform: 'capitalize',
                          fontFamily: 'Poppins'
                        }}
                      >
                        Employment History
                      </Box>
                      <Divider
                        sx={{
                          borderColor: 'rgba(0, 0, 0, 0.2)',
                          marginTop: '13px',
                          marginBottom: '9px'
                        }}
                      />
                      {_get(profileDet, 'profileData.employmentHistoryList').length > 0 &&
                      seeMore === false ? (
                        <>
                          {_get(profileDet, 'profileData.employmentHistoryList').map(
                            (emp, index) =>
                              index <= 1 ? (
                                <React.Fragment>
                                  <Box
                                    key={`emp${index}`}
                                    sx={{
                                      display: 'flex',
                                      alignItems: 'start',
                                      gap: '10px',
                                      paddingTop: '10px'
                                    }}
                                  >
                                    <Grid item xs={12} md={12} lg={12}>
                                      <Box>
                                        <Box
                                          sx={{
                                            fontSize: '15.5px',
                                            fontWeight: '500',
                                            color: '#000000'
                                          }}
                                        >
                                          {_get(emp, 'designation')}
                                        </Box>
                                        <Box
                                          sx={{
                                            fontSize: '15.5px',
                                            fontWeight: '500',
                                            color: '#818181'
                                          }}
                                        >
                                          {_get(emp, 'companyName')}
                                        </Box>
                                        <Box
                                          sx={{
                                            fontSize: '15.5px',
                                            color: '#818181',
                                            display: 'flex',
                                            alignItems: 'center'
                                          }}
                                        >
                                          {!!_get(emp, 'fromDate') &&
                                            format(new Date(_get(emp, 'fromDate')), 'MMM, yyyy')}
                                          &nbsp;-&nbsp;
                                          {!!_get(emp, 'toDate')
                                            ? format(new Date(_get(emp, 'toDate')), 'MMM, yyyy')
                                            : 'Currently Working'}
                                        </Box>
                                        <Box sx={{ fontSize: '15.5px', color: '#818181' }}>
                                          {_get(emp, 'country')}
                                        </Box>
                                        <Box sx={{ fontSize: '15.5px', color: '#818181' }}>
                                          <ReadLessMore>{_get(emp, 'description')}</ReadLessMore>
                                        </Box>
                                        <Box sx={{ fontSize: '15.5px', color: '#818181' }}>
                                          {_get(emp, 'achievement')}
                                        </Box>
                                      </Box>
                                    </Grid>
                                  </Box>
                                  {index !=
                                    _get(profileDet, 'profileData.employmentHistoryList').length -
                                      1 && (
                                    <Divider
                                      sx={{
                                        borderColor: 'rgba(0, 0, 0, 0.2)',
                                        marginTop: '15px'
                                      }}
                                    />
                                  )}
                                </React.Fragment>
                              ) : (
                                ''
                              )
                          )}
                          {_get(profileDet, 'profileData.employmentHistoryList').length > 2 ? (
                            <Box>
                              <Button
                                onClick={() => setSeeMore(true)}
                                sx={{
                                  color: '#1976D2',
                                  textTransform: 'capitalize',
                                  fontSize: '15.5px',
                                  marginLeft: '-9px',
                                  marginTop: '20px'
                                }}
                              >
                                See more(
                                {_get(profileDet, 'profileData.employmentHistoryList').length - 2}
                                )
                              </Button>
                            </Box>
                          ) : (
                            ''
                          )}
                        </>
                      ) : (
                        ''
                      )}
                      {_get(profileDet, 'profileData.employmentHistoryList').length > 0 &&
                      seeMore === true ? (
                        <>
                          {_get(profileDet, 'profileData.employmentHistoryList').map(
                            (emp, index) => (
                              <React.Fragment>
                                <Box
                                  key={`emp${index}`}
                                  sx={{
                                    display: 'flex',
                                    alignItems: 'start',
                                    gap: '10px',
                                    paddingTop: '10px'
                                  }}
                                >
                                  <Grid item xs={12} md={12} lg={12}>
                                    <Box>
                                      <Box
                                        sx={{
                                          fontSize: '15.5px',
                                          fontWeight: '500',
                                          color: '#000000'
                                        }}
                                      >
                                        {_get(emp, 'designation')}
                                      </Box>
                                      <Box
                                        sx={{
                                          fontSize: '15.5px',
                                          fontWeight: '500',
                                          color: '#818181'
                                        }}
                                      >
                                        {_get(emp, 'companyName')}
                                      </Box>
                                      <Box
                                        sx={{
                                          fontSize: '15.5px',
                                          color: '#818181',
                                          display: 'flex',
                                          alignItems: 'center'
                                        }}
                                      >
                                        {!!_get(emp, 'fromDate') &&
                                          format(new Date(_get(emp, 'fromDate')), 'MMM, yyyy')}
                                        &nbsp;-&nbsp;
                                        {!!_get(emp, 'toDate')
                                          ? format(new Date(_get(emp, 'toDate')), 'MMM, yyyy')
                                          : 'Currently Working'}
                                      </Box>
                                      <Box sx={{ fontSize: '15.5px', color: '#818181' }}>
                                        {_get(emp, 'country')}
                                      </Box>
                                      <Box sx={{ fontSize: '15.5px', color: '#818181' }}>
                                        <ReadLessMore>{_get(emp, 'description')}</ReadLessMore>
                                      </Box>
                                      <Box sx={{ fontSize: '15.5px', color: '#818181' }}>
                                        {_get(emp, 'achievement')}
                                      </Box>
                                    </Box>
                                  </Grid>
                                </Box>
                                {index !=
                                  _get(profileDet, 'profileData.employmentHistoryList').length -
                                    1 && (
                                  <Divider
                                    sx={{ borderColor: 'rgba(0, 0, 0, 0.2)', marginTop: '15px' }}
                                  />
                                )}
                              </React.Fragment>
                            )
                          )}
                          <Divider
                            sx={{ borderColor: 'rgba(0, 0, 0, 0.2)', marginTop: '15px' }}
                          />
                          <Box>
                            <Button
                              onClick={() => setSeeMore(false)}
                              sx={{
                                color: '#1976D2',
                                textTransform: 'capitalize',
                                fontSize: '15.5px',
                                marginLeft: '-9px',
                                marginTop: '20px'
                              }}
                            >
                              See Less
                            </Button>
                          </Box>
                        </>
                      ) : (
                        ''
                      )}
                    </CardContent>
                  </Card>
                )}
              {!_isEmpty(_get(profileDet, 'profileData.educationInfoList')) &&
                _get(profileDet, 'profileData.educationInfoList').length > 0 && (
                  <Card
                    sx={{
                      boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
                      borderRadius: '10px',
                      marginTop: '30px'
                    }}
                  >
                    <CardContent sx={{ padding: '20px' }}>
                      <Box
                        sx={{
                          fontSize: '18.6px',
                          lineHeight: '35px',
                          fontWeight: '600',
                          color: '#000000',
                          textTransform: 'uppercase',
                          textTransform: 'capitalize',
                          fontFamily: 'Poppins'
                        }}
                      >
                        Education History
                      </Box>
                      <Divider
                        sx={{
                          borderColor: 'rgba(0, 0, 0, 0.2)',
                          marginTop: '13px',
                          marginBottom: '9px'
                        }}
                      />
                      {_get(profileDet, 'profileData.educationInfoList').length > 0 &&
                      seeMoreEducation === false ? (
                        <>
                          {_get(profileDet, 'profileData.educationInfoList').map((edu, index) =>
                            index <= 1 ? (
                              <React.Fragment>
                                <Box
                                  key={`edu${index}`}
                                  sx={{
                                    display: 'flex',
                                    alignItems: 'start',
                                    gap: '10px',
                                    paddingTop: '10px'
                                  }}
                                >
                                  <Box>
                                    <Box sx={{ fontSize: '15.5px', fontWeight: '500' }}>
                                      {_get(edu, 'instituteName')}
                                    </Box>
                                    <Box
                                      sx={{
                                        fontSize: '15.5px',
                                        fontWeight: '500',
                                        color: '#818181'
                                      }}
                                    >
                                      {_get(edu, 'degree')}
                                    </Box>
                                    <Box sx={{ fontSize: '15.5px', color: '#818181' }}>
                                      {_get(edu, 'areaOfSpecialization')}
                                    </Box>
                                    <Box
                                      sx={{
                                        fontSize: '15.5px',
                                        color: '#818181',
                                        display: 'flex',
                                        alignItems: 'center'
                                      }}
                                    >
                                      {!!_get(edu, 'fromDate') &&
                                        format(new Date(_get(edu, 'fromDate')), 'MMM, yyyy')}
                                      &nbsp;-&nbsp;
                                      {!!_get(edu, 'toDate')
                                        ? format(new Date(_get(edu, 'toDate')), 'MMM, yyyy')
                                        : 'Currently Working'}
                                    </Box>
                                    {
                                      <Box sx={{ fontSize: '15.5px', color: '#818181' }}>
                                        <ReadLessMore>{_get(edu, 'description')}</ReadLessMore>
                                      </Box>
                                    }
                                  </Box>
                                </Box>
                                {index !=
                                  _get(profileDet, 'profileData.educationInfoList').length -
                                    1 && (
                                  <Divider
                                    sx={{ borderColor: 'rgba(0, 0, 0, 0.2)', marginTop: '15px' }}
                                  />
                                )}
                              </React.Fragment>
                            ) : (
                              ''
                            )
                          )}
                          {_get(profileDet, 'profileData.educationInfoList').length > 2 ? (
                            <Box>
                              <Button
                                onClick={() => setSeeMoreEducation(true)}
                                sx={{
                                  color: '#1976D2',
                                  textTransform: 'capitalize',
                                  fontSize: '15.5px',
                                  marginLeft: '-9px',
                                  marginTop: '20px'
                                }}
                              >
                                See more(
                                {_get(profileDet, 'profileData.educationInfoList').length - 2})
                              </Button>
                            </Box>
                          ) : (
                            ''
                          )}
                        </>
                      ) : (
                        ''
                      )}
                      {_get(profileDet, 'profileData.educationInfoList').length > 0 &&
                      seeMoreEducation === true ? (
                        <>
                          {_get(profileDet, 'profileData.educationInfoList').map((edu, index) => (
                            <React.Fragment>
                              <Box
                                key={`edu${index}`}
                                sx={{
                                  display: 'flex',
                                  alignItems: 'start',
                                  gap: '10px',
                                  paddingTop: '10px'
                                }}
                              >
                                <Box>
                                  <Box sx={{ fontSize: '15.5px', fontWeight: '500' }}>
                                    {_get(edu, 'instituteName')}
                                  </Box>
                                  <Box
                                    sx={{
                                      fontSize: '15.5px',
                                      fontWeight: '500',
                                      color: '#818181'
                                    }}
                                  >
                                    {_get(edu, 'degree')}
                                  </Box>
                                  <Box sx={{ fontSize: '15.5px', color: '#818181' }}>
                                    {_get(edu, 'areaOfSpecialization')}
                                  </Box>
                                  <Box
                                    sx={{
                                      fontSize: '15.5px',
                                      color: '#818181',
                                      display: 'flex',
                                      alignItems: 'center'
                                    }}
                                  >
                                    {!!_get(edu, 'fromDate') &&
                                      format(new Date(_get(edu, 'fromDate')), 'MMM, yyyy')}
                                    &nbsp;-&nbsp;
                                    {!!_get(edu, 'toDate')
                                      ? format(new Date(_get(edu, 'toDate')), 'MMM, yyyy')
                                      : 'Currently Working'}
                                  </Box>
                                  {
                                    <Box sx={{ fontSize: '15.5px', color: '#818181' }}>
                                      <ReadLessMore>{_get(edu, 'description')}</ReadLessMore>
                                    </Box>
                                  }
                                </Box>
                              </Box>
                              {index !=
                                _get(profileDet, 'profileData.educationInfoList').length - 1 && (
                                <Divider
                                  sx={{ borderColor: 'rgba(0, 0, 0, 0.2)', marginTop: '15px' }}
                                />
                              )}
                            </React.Fragment>
                          ))}
                          <Divider
                            sx={{ borderColor: 'rgba(0, 0, 0, 0.2)', marginTop: '15px' }}
                          />
                          <Box>
                            <Button
                              onClick={() => setSeeMoreEducation(false)}
                              sx={{
                                color: '#1976D2',
                                textTransform: 'capitalize',
                                fontSize: '15.5px',
                                marginLeft: '-9px',
                                marginTop: '20px'
                              }}
                            >
                              See Less
                            </Button>
                          </Box>
                        </>
                      ) : (
                        ''
                      )}
                    </CardContent>
                  </Card>
                )}

            {(userType === 'engineer' || userType === 'business' || userType === 'admin') && (
              !_isEmpty(_get(profileDet, 'profileData.certificationList')) &&
                _get(profileDet, 'profileData.certificationList').length > 0 && (
                  <Card
                    sx={{
                      boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
                      borderRadius: '10px',
                      marginTop: '30px'
                    }}
                  >
                    <CardContent sx={{ padding: '20px' }}>
                      <Box
                        sx={{
                          fontSize: '18.6px',
                          lineHeight: '35px',
                          fontWeight: '600',
                          color: '#000000',
                          textTransform: 'capitalize',
                          fontFamily: 'Poppins'
                        }}
                      >
                        Certifications
                      </Box>
                      <Divider
                        sx={{
                          borderColor: 'rgba(0, 0, 0, 0.2)',
                          marginTop: '13px',
                          marginBottom: '9px'
                        }}
                      />
                      <>
                        {_get(profileDet, 'profileData.certificationList').map(
                          (certification, index) => (
                            <Grid
                              container
                              spacing={1}
                              alignItems="center"
                              className={`h-padding-top-${index < 1 ? '5' : '16'}`}
                              key={`certification${index}`}
                            >
                              <Grid item xs={12} md={12} lg={12}>
                                {_get(certification, 'certificationFile') != '' ? (
                                  <Box
                                    sx={{
                                      color: '#000000',
                                      fontSize: '15.5px',
                                      fontFamily: 'Poppins'
                                    }}
                                  >
                                    {_get(certification, 'certificateName')}
                                    <Box>
                                      <Link href={_get(certification, 'certificationFile')}>
                                        <img
                                          style={{
                                            float: 'right',
                                            marginRight: '23px',
                                            marginTop: '-23px'
                                          }}
                                          src={DownLoad}
                                          alt="download"
                                          fontSize="medium"
                                          width="18px"
                                        />
                                      </Link>
                                    </Box>
                                  </Box>
                                ) : (
                                  <Box sx={{ color: '#000000', fontSize: '15.5px' }}>
                                    {_get(certification, 'certificateName')}
                                  </Box>
                                )}
                                {index !=
                                  _get(profileDet, 'profileData.certificationList').length -
                                    1 && (
                                  <Divider
                                    sx={{ borderColor: 'rgba(0, 0, 0, 0.2)', marginTop: '15px' }}
                                  />
                                )}
                              </Grid>
                            </Grid>
                          )
                        )}
                      </>
                    </CardContent>
                  </Card>
                )
              )}

              {!_isEmpty(_get(profileDet, 'profileData.myProfile.fieldOfSpecialization')) &&
                _get(profileDet, 'profileData.myProfile.fieldOfSpecialization').length > 0 && (
                  <Card
                    sx={{
                      boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
                      borderRadius: '10px',
                      marginTop: '30px'
                    }}
                  >
                    <CardContent sx={{ padding: '20px' }}>
                      <Box
                        sx={{
                          fontSize: '18.6px',
                          lineHeight: '35px',
                          fontWeight: '600',
                          color: '#000000',
                          fontFamily: 'Poppins',
                          textTransform: 'capitalize'
                        }}
                      >
                        Field of expertise
                      </Box>
                      <Divider
                        sx={{
                          borderColor: 'rgba(0, 0, 0, 0.2)',
                          marginTop: '13px',
                          marginBottom: '9px'
                        }}
                      />
                      <>
                        {_get(profileDet, 'profileData.myProfile.fieldOfSpecialization').map(
                          (expertise, index) => (
                            <Grid
                              container
                              spacing={1}
                              alignItems="center"
                              className={`h-padding-top-${index < 1 ? '5' : '16'}`}
                              key={`certification${index}`}
                            >
                              <Grid item xs={12} md={9} lg={9}>
                                <Box sx={{ color: '#000000', fontSize: '15.5px' }}>
                                  {expertise}
                                </Box>
                                {index !=
                                  _get(profileDet, 'profileData.myProfile.fieldOfSpecialization')
                                    .length -
                                    1 && (
                                  <Divider
                                    sx={{ borderColor: 'rgba(0, 0, 0, 0.2)', marginTop: '15px' }}
                                  />
                                )}
                              </Grid>
                            </Grid>
                          )
                        )}
                      </>
                    </CardContent>
                  </Card>
                )}
                
                {(userType === 'engineer' || userType === 'business' || userType === 'admin') && (
                !_isEmpty(_get(profileDet, 'profileData.publicationList')) &&
                _get(profileDet, 'profileData.publicationList').length > 0 && (
                  <Card
                    sx={{
                      boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
                      borderRadius: '10px',
                      marginTop: '30px'
                    }}
                  >
                    <CardContent sx={{ padding: '20px' }}>
                      <Box
                        sx={{
                          fontSize: '18.6px',
                          lineHeight: '35px',
                          fontWeight: '600',
                          color: '#000000',
                          textTransform: 'capitalize',
                          fontFamily: 'Poppins'
                        }}
                      >
                        Publications
                      </Box>
                      <Divider
                        sx={{
                          borderColor: 'rgba(0, 0, 0, 0.2)',
                          marginTop: '13px',
                          marginBottom: '9px'
                        }}
                      />
                      <>
                        <Grid
                          container
                          spacing={1}
                          className="h-padding-top-16"
                          alignItems="center"
                          color="#818181"
                        ></Grid>
                        {_get(profileDet, 'profileData.publicationList').map(
                          (publication, index) => (
                            <Grid
                              container
                              spacing={1}
                              alignItems="center"
                              className={`h-padding-top-${index < 1 ? '5' : '16'}`}
                              key={`publication${index}`}
                            >
                              <Grid item xs={12} md={12} lg={12}>
                                {_get(publication, 'publicationUrl') != '' ? (
                                  <Link
                                    href={_get(publication, 'publicationUrl')}
                                    target="_blank"
                                  >
                                    <Box
                                      sx={{
                                        color: 'blue',
                                        fontSize: '15.5px',
                                        display: 'flex',
                                        width: '100%',
                                        display: 'block'
                                      }}
                                    >
                                      {_get(publication, 'publicationName')}
                                    </Box>
                                  </Link>
                                ) : (
                                  <Box
                                    sx={{
                                      color: '#000000',
                                      fontSize: '15.5px',
                                      display: 'flex',
                                      width: '100%',
                                      display: 'block'
                                    }}
                                  >
                                    {_get(publication, 'publicationName')}
                                  </Box>
                                )}
                                {index !=
                                  _get(profileDet, 'profileData.publicationList').length - 1 && (
                                  <Divider
                                    sx={{ borderColor: 'rgba(0, 0, 0, 0.2)', marginTop: '15px' }}
                                  />
                                )}
                              </Grid>
                            </Grid>
                          )
                        )}
                      </>
                    </CardContent>
                  </Card>
                )
              )}
              
              {!_isEmpty(_get(profileDet, 'profileData.myProfile.tools')) &&
                _get(profileDet, 'profileData.myProfile.tools').length > 0 && (
                  <Card
                    sx={{
                      boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
                      borderRadius: '10px',
                      marginTop: '30px'
                    }}
                  >
                    <CardContent sx={{ padding: '20px' }}>
                      <Box
                        sx={{
                          fontSize: '18.6px',
                          lineHeight: '35px',
                          fontWeight: '600',
                          color: '#000000',
                          textTransform: 'capitalize',
                          fontFamily: 'Poppins'
                        }}
                      >
                        Tools and technologies
                      </Box>
                      <Divider
                        sx={{
                          borderColor: 'rgba(0, 0, 0, 0.2)',
                          marginTop: '13px',
                          marginBottom: '9px'
                        }}
                      />
                      <Box sx={{ paddingTop: '20px', fontSize: '18px' }}>
                        {_get(profileDet, 'profileData.myProfile.tools').map(
                          (expertise, index) => (
                            <React.Fragment key={index}>
                              <Chip
                                label={expertise}
                                variant="outlined"
                                sx={{
                                  minWidth: '100px',
                                  fontWeight: '500',
                                  color: '#000000',
                                  marginBottom: '6px'
                                }}
                              />
                              &nbsp;&nbsp;
                            </React.Fragment>
                          )
                        )}
                      </Box>
                    </CardContent>
                  </Card>
                )}
              <Box sx={{ marginTop: '40px' }}></Box>

              {!!referralsData &&
                Array.isArray(referralsData) &&
                referralsData.length > 0 &&
                checkIfVerified(referralsData).length > 0 && (
                  <div className="reviews-wrapper">
                    <div className="reviews-heading reviews-heading-gig-applied-eng">
                      {'Work History'}
                    </div>
                    <div className="completed-gigs-txt">{'Completed Gigs'}</div>
                    <div className="completed-gigs-txt-underline" />
                    {checkIfVerified(referralsData).map((item, index) => (
                      <div>
                        <div className="spacer"></div>
                        <div className="review-gig-name">
                          {item.gigId.titleAndSkills.projectTitle}
                        </div>
                        <div className="stars-row">
                          <ReactStars
                            classNames={'stars'}
                            count={5}
                            activeColor="#ffd700"
                            size={25}
                            edit={false}
                            value={
                              !!item.publicFeedback.feedbackStarData &&
                              (
                                item.publicFeedback.feedbackStarData.reduce(
                                  (sum, feedback) =>
                                    sum +
                                    (feedback.feedbackRating > 0
                                      ? parseInt(feedback.feedbackRating)
                                      : 0),
                                  0
                                ) /
                                Math.max(
                                  item.publicFeedback.feedbackStarData.filter(
                                    (feedback) => feedback.feedbackRating > 0
                                  ).length,
                                  1
                                )
                              ).toFixed(2)
                            }
                          />
                          <div className="stars-count-txt">
                            {!!item.publicFeedback.feedbackStarData &&
                              (
                                item.publicFeedback.feedbackStarData.reduce(
                                  (sum, feedback) =>
                                    sum +
                                    (feedback.feedbackRating > 0
                                      ? parseInt(feedback.feedbackRating)
                                      : 0),
                                  0
                                ) /
                                Math.max(
                                  item.publicFeedback.feedbackStarData.filter(
                                    (feedback) => feedback.feedbackRating > 0
                                  ).length,
                                  1
                                )
                              ).toFixed(2)}
                          </div>
                        </div>
                        <div className="feedback-txt">{`“${item.publicFeedback.feedbackTextData}”`}</div>
                      </div>
                    ))}
                    {/* <Pagination
                                      // count={props.pagination}
                                      shape="rounded"
                                      className="pagination-root"
                                      size='small'
                                      // onChange={props.handleChange}
                                      // defaultPage={props.defaultPage || 1}
                                      /> */}
                    {/* <div className='see-more'>{'See more.'}</div> */}
                  </div>
                )}
            </Grid>
            <div className="bet-ween-line-container"></div>
            <Grid item xs={12} sm={3} md={3} ml={3}>
              <div className="skills-availability-res ">
                <div className="item-signup-cont">
                  <Card
                    sx={{
                      boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
                      borderRadius: '10px',
                      marginTop: '-16px',
                      backgroundColor: '#E6F6FF'
                    }}
                  >
                    <CardContent sx={{ padding: '12px' }}>
                      <Box
                        sx={{
                          fontSize: '16.6px',
                          lineHeight: '22px',
                          fontWeight: '400',
                          textAlign: 'center',
                          color: '#000000',
                          marginTop: '10px'
                        }}
                      >
                        Log in to find more profiles like this
                      </Box>
                      <Box>
                        <LoginSocialGoogle
                          client_id={process.env.REACT_APP_GOOGLE_CLIENT_ID || ''}
                          onLoginStart={() => {
                            console.log('STARTING NOW!!!!!');
                          }}
                          redirect_uri={'/'}
                          scope="openid profile email"
                          discoveryDocs="claims_supported"
                          access_type="offline"
                          onResolve={(resp) => {
                            handleGoogleLogin(resp, 'engineer');
                          }}
                          onReject={(err) => {
                            console.error(err);
                          }}
                        >
                          <Button className="google-text-btn" type="button">
                            <div className="not-user-logo">
                              <img
                                src={LogoGoogleEngineer}
                                alt="logogoogleengineer"
                                fontSize="large"
                                width="100%"
                              />
                            </div>
                            <div className="not-user-text">Continue with Google Workspace</div>
                          </Button>
                        </LoginSocialGoogle>
                      </Box>
                      <Typography
                        sx={{
                          fontSize: '13.8px',
                          textAlign: 'center',
                          marginTop: '25px',
                          fontFamily: 'Poppins'
                        }}
                      >
                        or
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: '18px',
                          textAlign: 'center',
                          marginTop: '10px',
                          fontFamily: 'Poppins',
                          fontWeight: '400'
                        }}
                      >
                        Sign up
                      </Typography>
                      <Box sx={{ textAlign: 'center' }}>
                        <Button
                          className="signup-business-text-btn"
                          type="button"
                          onClick={businessModal}
                        >
                          Sign Up as a Business
                        </Button>
                      </Box>
                      <Box sx={{ textAlign: 'center' }}>
                        <Button
                          className="signup-engineer-text-btn"
                          type="button"
                          onClick={modalEngineerSignUpOpen}
                        >
                          Sign Up as an Engineer
                        </Button>
                      </Box>
                    </CardContent>
                  </Card>
                </div>
                {!_isEmpty(
                  _get(profileDet, 'profileData.myProfile.availabilityHoursPerWeek')
                ) && (
                  <Card
                    sx={{
                      boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
                      borderRadius: '10px',
                      marginTop: '30px'
                    }}
                  >
                    <CardContent sx={{ padding: '20px' }}>
                      <Box
                        sx={{
                          fontSize: '18.6px',
                          lineHeight: '35px',
                          fontWeight: '600',
                          textAlign: 'center',
                          color: '#000000',
                          textTransform: 'capitalize',
                          fontFamily: 'Poppins'
                        }}
                      >
                        Availability
                      </Box>
                      <Box sx={{ textAlign: 'center' }}>
                        <Box
                          sx={{
                            fontSize: '15.5px',
                            fontWeight: '500',
                            lineHeight: '28px',
                            textAlign: 'center',
                            alignItems: 'center',
                            color: '#818181'
                          }}
                        >
                          {_get(profileDet, 'profileData.myProfile.availabilityHoursPerWeek')
                            ? ` ${_get(
                                profileDet,
                                'profileData.myProfile.availabilityHoursPerWeek'
                              )} Hr/Week `
                            : 'Not Disclosed'}
                        </Box>
                      </Box>
                    </CardContent>
                  </Card>
                )}
                {!_isEmpty(_get(profileDet, 'profileData.languageList')) && (
                  <Card
                    sx={{
                      boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
                      borderRadius: '10px',
                      marginTop: '30px'
                    }}
                  >
                    <CardContent sx={{ padding: '20px' }}>
                      <Box
                        sx={{
                          fontSize: '18.6px',
                          lineHeight: '35px',
                          fontWeight: '600',
                          textAlign: 'center',
                          color: '#000000',
                          textTransform: 'capitalize',
                          fontFamily: 'Poppins'
                        }}
                      >
                        Languages
                      </Box>
                      <Box sx={{ textAlign: 'center' }}>
                        {_get(profileDet, 'profileData.languageList').map((lang, index) => (
                          <Box
                            sx={{
                              fontSize: '15.5px',
                              fontWeight: '500',
                              lineHeight: '28px',
                              textAlign: 'center',
                              alignItems: 'center',
                              color: '#818181'
                            }}
                          >
                            {_get(lang, 'language')}: {_get(lang, 'proficienyLevel')}
                          </Box>
                        ))}
                      </Box>
                    </CardContent>
                  </Card>
                )}
                {!_isEmpty(_get(profileDet, 'profileData.skillList')) && (
                  <Card
                    sx={{
                      boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
                      borderRadius: '10px',
                      marginTop: '30px'
                    }}
                  >
                    <CardContent sx={{ padding: '20px' }}>
                      <Box
                        sx={{
                          fontSize: '18.6px',
                          lineHeight: '35px',
                          fontWeight: '600',
                          textAlign: 'center',
                          color: '#000000',
                          textTransform: 'capitalize',
                          fontFamily: 'Poppins'
                        }}
                      >
                        Skills
                      </Box>
                      <Box sx={{ textAlign: 'center' }}>
                        {_get(profileDet, 'profileData.skillList').map((skill, index) => (
                          <React.Fragment key={index}>
                            <Box
                              sx={{
                                fontSize: '15.5px',
                                fontWeight: '500',
                                lineHeight: '28px',
                                textAlign: 'center',
                                alignItems: 'center',
                                color: '#818181'
                              }}
                            >
                              {_get(skill, 'skillName')}
                            </Box>
                          </React.Fragment>
                        ))}
                      </Box>
                    </CardContent>
                  </Card>
                )}
              </div>
            </Grid>
          </Grid>
        </div>
      )}
    </PaddedBox>

    {open ? (
      <FindGigPopup
        signInEngineerPopup={open}
        modalEngineerOpen={(e) => {
          setOpen(true);
        }}
        modalEngineerClose={(e) => {
          setOpen(false);
        }}
        modalEngineerSignUpOpen={(e) => {
          setOpenSignUp(true);
        }}
        modalForgetEngineer={(e) => {
          setOpenForgetEngineer(true);
        }}
        modalEngineerSignUpClose={(e) => {
          setOpenSignUp(false);
        }}
        modalForgetClose={(e) => {
          setOpenForgetEngineer(false);
        }}
      />
    ) : (
      <></>
    )}

    {openSignUp ? (
      <FindGigPopup
        signUpModalOpen={openSignUp}
        modalEngineerSignUpOpen={(e) => {
          setOpenSignUp(true);
        }}
        modalEngineerOpen={(e) => {
          setOpen(true);
        }}
        modalEngineerSignUpClose={(e) => {
          setOpenSignUp(false);
        }}
        modalEngineerClose={(e) => {
          setOpen(false);
        }}
        modalForgetClose={(e) => {
          setOpenForgetEngineer(false);
        }}
      />
    ) : (
      <></>
    )}

    {openForgetEngineer ? (
      <FindGigPopup
        forgetSignIn={openForgetEngineer}
        modalForgetEngineer={(e) => {
          setOpenForgetEngineer(true);
        }}
        modalEngineerSignUpOpen={(e) => {
          setOpenSignUp(true);
        }}
        modalForgetClose={(e) => {
          setOpenForgetEngineer(false);
        }}
        modalEngineerClose={(e) => {
          setOpen(false);
        }}
        modalEngineerSignUpClose={(e) => {
          setOpenSignUp(false);
        }}
      />
    ) : (
      <></>
    )}

    {openSignUpModal ? (
      <FindEngineerPopup
        signInBusinessPopup={openSignUpModal}
        modalBusinessOpen={(e) => {
          setOpenSignUpModal(true);
        }}
        modalBusinessClose={(e) => {
          setOpenSignUpModal(false);
        }}
        businessModal={(e) => {
          setOpenBusinessModal(true);
        }}
        ForgetBusinessModalOpen={(e) => {
          setopenForgetBusiness(true);
        }}
        modalBusinessSignUpClose={(e) => {
          setOpenBusinessModal(false);
        }}
        modalBusinessForgetClose={(e) => {
          setopenForgetBusiness(false);
        }}
      />
    ) : (
      <></>
    )}

    {openBusinessModal ? (
      <FindEngineerPopup
        signUpBusinessModal={openBusinessModal}
        businessModal={(e) => {
          setOpenBusinessModal(true);
        }}
        modalBusinessOpen={(e) => {
          setOpenSignUpModal(true);
        }}
        modalBusinessSignUpClose={(e) => {
          setOpenBusinessModal(false);
        }}
        modalBusinessClose={(e) => {
          setOpenSignUpModal(false);
        }}
        modalBusinessForgetClose={(e) => {
          setopenForgetBusiness(false);
        }}
      />
    ) : (
      <></>
    )}

    {openForgetBusiness ? (
      <FindEngineerPopup
        forgetBusinessModal={openForgetBusiness}
        ForgetBusinessModalOpen={(e) => {
          setopenForgetBusiness(true);
        }}
        modalBusinessOpen={(e) => {
          setOpenSignUpModal(true);
        }}
        businessModal={(e) => {
          setOpenBusinessModal(true);
        }}
        modalBusinessForgetClose={(e) => {
          setopenForgetBusiness(false);
        }}
        modalBusinessClose={(e) => {
          setOpenSignUpModal(false);
        }}
        modalBusinessSignUpClose={(e) => {
          setOpenBusinessModal(false);
        }}
      />
    ) : (
      <></>
    )}

    <Footer />
  </React.Fragment>
  ) : (
    <NotUserName />
  );
};
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    myProfile: state.profile.myProfile || {},
    skillList: state.profile.skillList || [],
    error: state.profile.error,
    saveSuccess: state.profile.saveSuccess,
    basicInfo: state.profile.basicInfo,
    profile: state.profile
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setProfileError: (details) => dispatch(setProfileError(details)),
    setProfileSuccess: (details) => dispatch(setProfileSuccess(details)),
    resetProfileSuccess: (details) => dispatch(resetProfileSuccess(details))
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(EngineerDetailsPage);