import React, { useEffect, useState } from 'react';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _toNumber from 'lodash/toNumber';
import _last from 'lodash/last';
import { PaddedFooter } from '../../app.styles';
import { connect } from 'react-redux';
import '../../gigOwner/gigDashboard/GigDashboard.css';
import '../businessSide/ViewFeedback.scss';
import Footer from '../../../components/footer1/footer';
import ReactStars from "react-rating-stars-component";
import { useHistory, useLocation } from 'react-router-dom';
import { getGigsById, getAllFeedbackApi, addNewFeedbackApi } from '../../../services/gig';
import { getUserId } from '../../../utils/user';
import { formatDate } from '../../../utils/helpers';
import { toast } from 'react-toastify';

const ViewFeedbackEng = (props) => {
    const history = useHistory();
    const location = useLocation();
    const [referralsData, setreferralsData] = useState(undefined)
    const [referralMessage, setreferralMessage] = useState("")
    const [referralDate, setreferralDate] = useState(undefined)
    const [feedbackTypes, setfeedbackTypes] = useState([
        { "feedbackValue": "Soft Skills", "feedbackRating": 3 },
        { "feedbackValue": "Quality of work", "feedbackRating": 1 },
        { "feedbackValue": "Technical Knowledge", "feedbackRating": 4 },
        { "feedbackValue": "Adherence to schedule", "feedbackRating": 5 },
        { "feedbackValue": "Communication", "feedbackRating": 2 },
        { "feedbackValue": "Co-operation", "feedbackRating": 0 },
        { "feedbackValue": "Value for money", "feedbackRating": 4 },
    ])
    const [selectedGig, setselectedGig] = useState(undefined)
    useEffect(() => {
        // console.log(location.pathname.split("/")[location.pathname.split("/").length-1])
        getGigsById(location.pathname.split("/")[location.pathname.split("/").length-1]).then((e)=>{
            // console.log(e)
            setselectedGig(e)
            if(!!e.gig&&!!e.gig.referralsData){
                e.gig.referralsData.map((item,index)=>{
                    if(item.giverId!=getUserId()){
                        setreferralsData(item)
                        setfeedbackTypes([])
                        setreferralDate(item.createdAt)
                        setreferralMessage(item.publicFeedback.feedbackTextData)
                        setfeedbackTypes(item.publicFeedback.feedbackStarData)
                    }
                })
            }
            else{
                toast.warning("No data found!")
            }
            // getAllFeedbackApi({
            //     paginationInput: {
            //         pageNumber: 1,
            //         pageSize:10
            //     },
            //     giverId: !!e&&!!e.gig?e?.gig?.userId:"",
            //     recieverId: !!e&&!!e.gig&&!!e.gig.hireData?e.gig.hireData.userId:"",
            // }).then((e)=>{
            //     if(!!e.data.referralsList){
            //         setfeedbackTypes([])
            //         let feedback = e.data.referralsList[e.data.referralsList.length-1]
            //         setreferralsData(feedback)
            //         setfeedbackTypes(feedback.publicFeedback.feedbackStarData)
            //         setreferralMessage(feedback.publicFeedback.feedbackTextData)
            //         setreferralDate(feedback.createdAt)
            //     }
            // }).catch((err)=>console.error(err))
            // getAllFeedbackApi({
            //     paginationInput: {
            //         pageNumber: 1,
            //         pageSize:10
            //     },
            //     giverId: !!e&&!!e.gig?e?.gig?.userId:"",
            //     recieverId: getUserId(),
            // })
        })
    }, [])

    // const feedbackTypes = [
    //     { "name": "Soft Skills", "rating": 3 },
    //     { "name": "Quality of work", "rating": 1 },
    //     { "name": "Technical Knowledge", "rating": 4 },
    //     { "name": "Adherence to schedule", "rating": 5 },
    //     { "name": "Communication", "rating": 2 },
    //     { "name": "Co-operation", "rating": 0 },
    //     { "name": "Value for money", "rating": 4 },
    // ]

    const handlePublishFeedback = (flag) => {
        if(!!referralsData){
            addNewFeedbackApi({
                _id: referralsData._id,
                isPublished: flag
            }).then((e)=>{
                // console.log(e)
                setreferralsData(e.data.referrals)
                toast.success(`Feedback ${flag?"published":"unpublished"} successfully!`)
            }).catch((err)=>{console.error(err)})
        }
        else{
            toast.warn("No active referral")
        }
    }

    return (
        <React.Fragment>
            <div className='view-feedback-form-wrapper' style={{ paddingLeft: `${!!props.auth && !!props.auth.isDrawerOpen && props.auth.isDrawerOpen == true ? "276px" : "0px"}` }}>
                <div className='action-btns-top-row'>
                    <button className='back-btn-top' onClick={()=>history.goBack()}>{'Back'}</button>
                    <text className='page-heading-txt'>{'View Feedback'}</text>
                </div>
                <div className='feedback-container'>
                    <div className='star-rating-wrapper'>
                        <div className='star-rating-column'>
                            {feedbackTypes.map((val, idx) => <div className='star-rating-row'>
                                <ReactStars classNames={'stars'} count={5} activeColor="#ffd700" size={25} edit={false} value={Number(val.feedbackRating)} />
                                <div className='rating-cat-name'>{val.feedbackValue}</div>
                            </div>)}
                        </div>
                        <div className='posted-on'>{`Posted on ${!!referralDate?formatDate(referralDate):"01/01/2023"}`}</div>
                    </div>
                    <div className='total-txt'>{`Total - ${!!feedbackTypes && (feedbackTypes.reduce((sum, feedbackType) => Number(sum) + Number(feedbackType.feedbackRating), 0) / Math.max(feedbackTypes.filter(feedbackType => feedbackType.feedbackRating > 0).length, 1)).toFixed(2)}`}</div>
                    <text className='feedback-txt'>{`“${referralMessage}”`}</text>
                </div>
                {
                    !!referralsData&&referralsData.isPublished?
                    <button className='publish-btn' onClick={()=>{handlePublishFeedback(false)}}>{'UNPUBLISH'}</button>
                    :
                    <button className='publish-btn' onClick={()=>{handlePublishFeedback(true)}}>{'PUBLISH'}</button>
                }
            </div>
            <PaddedFooter style={{ paddingLeft: `${!!props.auth && !!props.auth.isDrawerOpen && props.auth.isDrawerOpen == true ? "240px" : "0px"}` }}>
                <Footer />
            </PaddedFooter>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        // newGig: state.newGig,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        // setNewGigDetails: (data) => dispatch(setNewGigDetails(data))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewFeedbackEng);