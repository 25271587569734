import React, { useEffect, useState } from 'react';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _toNumber from 'lodash/toNumber';
import _last from 'lodash/last';
import { PaddedFooter } from '../../app.styles';
import { connect } from 'react-redux';
import '../../gigOwner/gigDashboard/GigDashboard.css';
import '../businessSide/ViewFeedback.scss';
import Footer from '../../../components/footer1/footer';
import ReactStars from "react-rating-stars-component";
import { useHistory } from 'react-router-dom';
import { getAllFeedbackApi, getGigsById } from '../../../services/gig';
import { formatDate } from '../../../utils/helpers';

const ViewFeedbackAdmin = (props) => {
    const [howLikelyEng, sethowLikelyEng] = useState(5);
    const [howLikelyGig, sethowLikelyGig] = useState(3);
    const [gigData, setgigData] = useState(undefined)
    const [referralsData, setreferralsData] = useState(undefined)
    const [referralMessage, setreferralMessage] = useState("")
    const [referralDate, setreferralDate] = useState(undefined)
    const history = useHistory();

    const feedbackToEngPublicTypes = [
        { "name": "Soft Skills", "rating": 5 },
        { "name": "Quality of work", "rating": 1 },
        { "name": "Technical Knowledge", "rating": 3 },
        { "name": "Adherence to schedule", "rating": 2 },
        { "name": "Communication", "rating": 4 },
        { "name": "Cooperation", "rating": 0 },
        { "name": "Value for money", "rating": 3 },
    ];

    const feedbackToBusPublicTypes = [
        { "name": "Clarity on project requirements", "rating": 3 },
        { "name": "Timely response as per project schedule", "rating": 1 },
        { "name": "Communication", "rating": 4 },
        { "name": "Cooperation", "rating": 5 },
    ];

    useEffect(() => {
        // console.log(location.pathname.split("/")[location.pathname.split("/").length-1])
        getGigsById(location.pathname.split("/")[location.pathname.split("/").length-1]).then((e)=>{
            setgigData(e)
            // Get feedbacks
            setreferralsData(e.gig.referralsData)
            
            // getAllFeedbackApi({
            //     paginationInput: {
            //         pageNumber: 1,
            //         pageSize:10
            //     },
            //     gigId:location.pathname.split("/")[location.pathname.split("/").length-1],
            // }).then((e)=>{
            //     if(!!e.data.referralsList){
            //         // setfeedbackTypes(feedback.publicFeedback.feedbackStarData)
            //         // setreferralMessage(feedback.publicFeedback.feedbackTextData)
            //         // setreferralDate(feedback.createdAt)
            //     }
            // }).catch((err)=>console.error(err))

        })
    }, [])

    return (
        <React.Fragment>
            <div className='view-feedback-form-wrapper' style={{ paddingLeft: `${!!props.auth && !!props.auth.isDrawerOpen && props.auth.isDrawerOpen == true ? "276px" : "0px"}` }}>
                <div className='action-btns-top-row'>
                    <button className='back-btn-top' onClick={() => history.goBack()}>{'Back'}</button>
                    <text className='page-heading-txt'>{'View Feedback'}</text>
                </div>
                <div className='type-of-feedback-txt'>{'Public Feedback'}</div>
                <div className='feedback-container remove-top-padding'>
                    {
                        !!referralsData
                        &&
                        referralsData.map((item,index)=>(
                            <div>
                                <div className='star-rating-wrapper'>
                                    <div className='star-rating-column'>
                                        <div className='feedback-to-type-txt'>{`Feedback to ${item.recieverId==gigData.gig.userId?"Business owner":"Engineer"}:`}</div>
                                        {item.publicFeedback.feedbackStarData.map((val, idx) => <div className='star-rating-row'>
                                            <ReactStars classNames={'stars'} count={5} activeColor="#ffd700" size={25} edit={false} value={Number(val.feedbackRating)} />
                                            <div className='rating-cat-name'>{val.feedbackValue}</div>
                                        </div>)}
                                    </div>
                                    <div className='posted-on'>{`Posted on ${!!item.createdAt?formatDate(item.createdAt):"01/01/2023"}`}</div>
                                </div>
                                <div className='total-txt'>{`Total - ${!!item.publicFeedback.feedbackStarData && (item.publicFeedback.feedbackStarData.reduce((sum, feedbackType) => Number(sum) + Number(feedbackType.feedbackRating), 0) / Math.max(item.publicFeedback.feedbackStarData.filter(feedbackType => feedbackType.feedbackRating > 0).length, 1)).toFixed(2)}`}</div>
                                <text className='feedback-txt'>{`“${item.publicFeedback.feedbackTextData}”`}</text>
                                {
                                    referralsData.length-1 != index
                                    &&
                                    <div className='spacer' />
                                }
                            </div>
                        ))
                    }
                    {/* <div className='star-rating-wrapper'>
                        <div className='star-rating-column'>
                            <div className='feedback-to-type-txt'>{'Feedback to Business owner:'}</div>
                            {feedbackToBusPublicTypes.map((val, idx) => <div className='star-rating-row'>
                                <ReactStars classNames={'stars'} count={5} activeColor="#ffd700" size={25} edit={false} value={val.rating} />
                                <div className='rating-cat-name'>{val.name}</div>
                            </div>)}
                        </div>
                        <div className='posted-on'>{'Posted on 01/01/2023'}</div>
                    </div>
                    <div className='total-txt'>{'Total - 4.00'}</div>
                    <text className='feedback-txt'>{`“Amazing client to work with,\nGave me all the requirements correctly and explained the whole\nproject to me patiently.”`}</text> */}
                </div>
                <div className='type-of-feedback-txt'>{'Private Feedback'}</div>
                <div className='feedback-container remove-top-padding'>
                    {
                        !!referralsData
                        &&
                        referralsData.map((item,index)=>(
                            <div>
                                <div className='star-rating-wrapper'>
                                    <div className='star-rating-column'>
                                        <div className='feedback-to-type-txt'>{`Feedback to ${!!item.privateFeedback.reasonForClosing?"engineer":"Gig owner"}:`}</div>
                                    </div>
                                    <div className='posted-on'>{`Posted on ${!!item.createdAt?formatDate(item.createdAt):"01/01/2023"}`}</div>
                                </div>
                                {
                                    !!!!item.privateFeedback.reasonForClosing
                                    &&
                                    <div className='reason-for-closing'>{`Reason for closing gig: ${item.privateFeedback.reasonForClosing}`}</div>
                                }
                                <div className='how-likely-txt'>{`How likely are you to recommend this ${!!item.privateFeedback.reasonForClosing?"engineer":"business"} to a friend or colleague?`}</div>
                                <div className='how-likely-heading-text'>
                                    <div className='how-likely-pointers not-likely-space'>{'Not at all likely'}</div>
                                    <div className='how-likely-pointers'>{'Extremely Likely'}</div>
                                </div>
                                <div className='how-likely-row'>
                                    {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((val, idx) => <div className='how-likely-item' style={{ cursor: "default" }}>
                                        <input type="radio" name={`howLikelyEng-${index}`} checked={val == item.privateFeedback.recommendation} />
                                        <div className='radio-btn-txt'>{val}</div>
                                    </div>)}
                                </div>
                                {
                                    referralsData.length-1 != index
                                    &&
                                    <div className='spacer-pvt' />
                                }
                            </div>
                        ))
                    }

                    {/* <div className='star-rating-wrapper'>
                        <div className='star-rating-column'>
                            <div className='feedback-to-type-txt'>{'Feedback to Gig owner:'}</div>
                        </div>
                        <div className='posted-on'>{'Posted on 01/01/2023'}</div>
                    </div>
                    <div className='reason-for-closing'>{'Reason for closing gig: I completed the project 5 days before the expected date'}</div>
                    <div className='how-likely-txt'>{'How likely are you to recommend this gig owner to a friend or colleague?'}</div>
                    <div className='how-likely-heading-text'>
                        <div className='how-likely-pointers not-likely-space'>{'Not at all likely'}</div>
                        <div className='how-likely-pointers'>{'Extremely Likely'}</div>
                    </div>
                    <div className='how-likely-row'>
                        {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((val, idx) => <div className='how-likely-item' style={{ cursor: "default" }}>
                            <input type="radio" name="howLikelyGig" checked={howLikelyGig === val} />
                            <div className='radio-btn-txt'>{val}</div>
                        </div>)}
                    </div> */}
                </div>
            </div>
            <PaddedFooter style={{ paddingLeft: `${!!props.auth && !!props.auth.isDrawerOpen && props.auth.isDrawerOpen == true ? "240px" : "0px"}` }}>
                <Footer />
            </PaddedFooter>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        // newGig: state.newGig,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        // setNewGigDetails: (data) => dispatch(setNewGigDetails(data))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewFeedbackAdmin);