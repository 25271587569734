import React, { useState } from 'react';
import { connect } from 'react-redux';
import _isEmpty from 'lodash/isEmpty';
import { Grid, Menu, MenuItem } from '@mui/material';
import SortIcon from '@mui/icons-material/Sort';
import { FilterIconbtn } from './sfs.styles';
import { setSortOrder } from '../../store/actions/sfs';

const SortAdmin = (props) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const selectSortBy = (type, order) => {
        setAnchorEl(null);
        props.setSortOrder({
            sortByKey: type,
            sortByOrder: order
        });
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const selectOption = (type, order, index) => {
        let retVal = false;
        if (props.sortBy.sortByKey === type && props.sortBy.sortByOrder === order) {
            retVal = true;
        }

        if (_isEmpty(props.sortBy) && index === 0) {
            retVal = true;
        }
        return retVal;
    };

    let MenuItems = [
        {
          name: 'Skill Match',
          value: 'SKILL_MATCH',
          order: 1
        },
        {
          name: 'Hourly Rate - Low to High',
          value: 'HOURLY_RATE',
          order: 1
        },
        {
          name: 'Hourly Rate - High to Low',
          value: 'HOURLY_RATE',
          order: -1
        },
        {
          name: 'Weekly Availablity - Low to High',
          value: 'WEEKLY_AVAILABLITY',
          order: 1
        },
        {
          name: 'Weekly Availablity - High to Low',
          value: 'WEEKLY_AVAILABLITY',
          order: -1
        },
        {
          name: 'Unverified Reviews',
          value: 'UNVERIFIED_REVIEWS',
          order: 1
        },
    ];


    if (props.sortType === 'gig') {
        MenuItems = [
            {
                name: 'Relevance',
                value: 'RELEVANCE',
                order: 1
            },
            ...MenuItems
        ];
    }

    return (
        <Grid>
            <FilterIconbtn type="submit" aria-label="search" onClick={handleClick}>
            <SortIcon sx={{ width: '55px', height: '42px', marginTop: '-3px' }} />
            </FilterIconbtn>
            <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                {MenuItems.map((option, index) => (
                    <MenuItem
                        key={index}
                        selected={selectOption(option.value, option.order, index)}
                        onClick={() => {
                            selectSortBy(option.value, option.order);
                        }}
                    >
                        {option.name}
                    </MenuItem>
                ))}
            </Menu>
        </Grid>
    );
};

const mapStateToProps = (state) => {
    return {
        sortBy: state.sfs.sortBy
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setSortOrder: (details) => dispatch(setSortOrder(details))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SortAdmin);
