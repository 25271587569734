import React, { useEffect, useState } from 'react';
import { FieldArray, Formik, Form, getIn } from 'formik';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { Grid, Button, Box, Typography, Rating, Dialog } from '@mui/material';
import DatePicker from '@mui/lab/DatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import FormControl from '@mui/material/FormControl';
import { PaddedBox, PaddedFooter } from '../../../app.styles';
import { employmentValidationSchema } from '../../../../utils/formikValidations';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import { getCountryStateCity } from '../../../../services/expertisewithskills';
import { setCookie, getCookie } from '../../../../utils/cookie';
import {
  setProfileError,
  setProfileSuccess,
  resetProfileSuccess
} from '../../../../store/actions/profile';
import { setEngProfileApi } from '../../../../services/profile';
import { resetSuccessState } from '../../../../utils/helpers';
import HelpIcon from '@mui/icons-material/Help';
import { blue } from '@mui/material/colors';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControlLabel from '@mui/material/FormControlLabel';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import { Prompt, useHistory } from 'react-router-dom';
import Divider from '@mui/material/Divider';
import CheckCircle from "../../../../assests/check_circle.png";
import DangerCross from "../../../../assests/dangerous.png";
import MuiPhoneNumber from 'material-ui-phone-number';
import Footer from '../../../../components/footer1/footer';
import './employment.scss'
import { cancelProfileFeedBack, profileFeedBack, verifyManualFeedbackApi } from '../../../../services/gig';
import { getUserId } from '../../../../utils/user';

const Employment = (props) => {
  const history = useHistory();

  const defaultObject = {
    companyName: '',
    country: 'South Africa',
    province: '',
    city: '',
    designation: '',
    description: '',
    fromDate: null,
    toDate: null,
    checked: ''
  };

  const defaultReferenceObject = {
    name: '',
    emailId: '',
    phone: '+27',
    company: '',
    jobTitle: ''
  };

  const defaultReviewObject = {
    nameOfSource: null,
    reviewLink: null,
    rating: null,
    outOf: null,
    specification: null,
    isPublished: null,
    isVerified: null,
  }

  const [countries, setCountries] = useState([]);
  const [optedCountry, setOptedCountry] = useState('');
  const [optedProvince, setOptedProvince] = useState('');
  const [optedCity, setOptedCity] = useState('');
  const [optedNameOfSource, setoptedNameOfSource] = useState('');
  const percentage = _get(props, 'profile.profileCompletionPercent', 0);
  const [datachange, setDataChange] = useState(false);
  const [openComplete, setOpenComplete] = useState(getCookie('setOpenComplete'));
  const [rating, setRating] = useState(1);
  // const [feedback, setFeedback] = useState('');
  const [ratingError, setRatingError] = useState('');
  const isFeedbackGiven = _get(props, 'profile.isFeedbackGiven', false);

  const handleCloseforComplete = () => {
    setOpenComplete(false);
    setCookie('setOpenComplete', false, 30);
    cancelMethod();
  };

  const submitMethod = async () => {
    let feedback = document.getElementById("standard-search").value;
    let data = {
      feedback: feedback,
      rating: rating,
      isGiven: true,
      type: "profileCompletion"
    };
    if (data.rating === 0) {
      return setRatingError('Please give rating before submit.')
    }
    profileFeedBack(data).then((e)=>{
      setOpenComplete(false);
      toast.success("Success");
      setCookie('setOpenComplete', false, 30);
    })
  };

  const nameOfSourceList = [
    "General freelance platform",
    "Social media platform",
    "Other",
  ]

  const cancelMethod = async () => {
    // let feedback = document.getElementById("standard-search").value;
    let data = {
      isGiven: false,
      type: "profileCompletion"
    };
    cancelProfileFeedBack(data).then((e)=>{
      setOpenComplete(false);
    })
  };

  const BootstrapDialogTitle = (props) => {
    const { onClose } = props;

    return (
      <DialogTitle sx={{ m: 0, p: 2 }}>
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };

  BootstrapDialogTitle.propTypes = {
    onClose: PropTypes.func.isRequired,
  };

  const getCountriesList = async () => {
    let countries = await getCountryStateCity();
    if (countries?.data && countries?.data.length > 0) {
      setCountries(countries.data);
    }
  };

  const getProvinceValue = (val) => {
    if (val) setOptedProvince(val);
  };

  const getCityValue = (val) => {
    if (val) setOptedCity(val);
  };

  const getNameOfSourceValue = (val) => {
    if (val) setoptedNameOfSource(val);
  }

  useEffect(() => {
    getCountriesList();
    setOptedCountry(props?.employment?.country ? props.employment.country : '');
    setOptedProvince(props?.employment?.province);
    setOptedCity(props?.employment?.city);
  }, [props]);

  const emp = !_isEmpty(_get(props, 'employment', [])) ? props.employment : [defaultObject];
  const refList = !_isEmpty(_get(props, 'referencesList', [])) ? props.referencesList : [];
  const revList = !_isEmpty(_get(props, 'reviewsList', [])) ? props.reviewsList : [];

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250
      }
    }
  };

  const handleVerifyRating = (review, i, flag) => {
    let tempReviewArr = JSON.parse(JSON.stringify(revList))
    tempReviewArr[i].isPublished=flag;
    // console.log(tempReviewArr,getUserId())
    let currUserId = getUserId()
    // verifyManualFeedbackApi({
    //   reviewsList: tempReviewArr,
    //   userId: currUserId
    // })
    const obj = {
      employmentHistoryList: emp,
      referencesList: refList,
      reviewsList: tempReviewArr
    };
    setDataChange(false);
    setEngProfileApi(obj, props.setProfileError, props.setProfileSuccess);
    toast.success('Data Saved!');
    // if (values?.action === 'next') {
    //   setTimeout(() => {
    //     history.push(`/education`);
    //   }, 1000);
    // }
  }

  return (
    <React.Fragment>
      <Prompt when={datachange} message={() => "You have unsaved changes, Are you sure you want to leave?"} ></Prompt>
      {(percentage === 100 && !isFeedbackGiven && (getCookie('setOpenComplete') != false)) && (
        <div>
          <Dialog open={openComplete} onClose={handleCloseforComplete}>
            <Box
              sx={{ display: "flex", flexDirection: "column", padding: "20px" }}
            >
              <BootstrapDialogTitle id="customized-dialog-title" onClose={handleCloseforComplete}>
              </BootstrapDialogTitle>
              <h2>Hey there!</h2>
              <Typography>
                Congratulations on successfully completing your Gig Engineer
                profile! We would love to get feedback on your experience.
              </Typography>
              <Box sx={{ display: "flex" }}>
                <Typography sx={{ marginTop: "12px" }}>
                  <Rating
                    name="size-large"
                    defaultValue={rating}
                    size="large"
                    onChange={(event) => {
                      let starValue = event.target.value;
                      if (rating != parseInt(starValue)) {
                        setRating(parseInt(starValue));
                        setRatingError(null)
                      }
                      if (rating === parseInt(starValue)) {
                        setRating(0)
                        setRatingError('Please provide the rating.')
                      }
                    }}

                  />
                </Typography>
                <Box
                  sx={{ marginTop: "12px", marginLeft: "20px", fontSize: "20px" }}
                >
                  ({rating}/5)
                </Box>
              </Box>
              <Typography
                sx={{
                  fontSize: '12px',
                  color: 'red'
                }}
              >
                {ratingError}
              </Typography>
              <Box
                component="form"
                sx={{
                  "& .MuiTextField-root": { m: 1, width: "60ch" },
                  marginTop: "-10px",
                }}
                autoComplete="off"
              >
                <div>
                  <TextField
                    id="standard-search"
                    label="Feedback (Optional)"
                    // value={feedback}
                    type="search"
                    variant="standard"
                  />
                </div>
              </Box>
              <Box
                sx={{
                  textAlign: "right",
                }}
              >
                <Button
                  variant="contained"
                  className="popup-process"
                  type="submit"
                  sx={{
                    width: "120px",
                  }}
                  onClick={submitMethod}
                >
                  Submit
                </Button>
                <br />
              </Box>
            </Box>
          </Dialog>
        </div>
      )}
      <PaddedBox style={{ paddingLeft: `${!!props.auth && !!props.auth.isDrawerOpen && props.auth.isDrawerOpen == true ? "240px" : "0px"}` }} >
        <Formik
          initialValues={{
            employment: emp,
            referencesList: refList,
            reviewsList: revList
          }}
          enableReinitialize={true}
          validationSchema={employmentValidationSchema}
        >
          {(formProps) => {
            const { values, touched, errors, handleChange, handleBlur, setFieldValue } = formProps;
            return (
              <Form
                noValidate
                autoComplete="off"
                onChange={() => {
                  resetSuccessState(props);
                }}
                onSubmit={(e) => {
                  e.preventDefault()
                  if(Object.keys(errors).length>0){
                    toast.warn("Please fill all the required fields!")
                  }
                  else{
                  for (let i = 0; i < values.employment.length; i++) {
                    if (values.employment[i].toDate === null && (values.employment[i].checked === "" || values.employment[i].checked === false)) {
                      values.employment[i].checked = true;
                    };
                  }
                  let temprevList = []
                  if(!!values.reviewsList){
                    values.reviewsList.map((item,index)=>{
                      let tempObj = JSON.parse(JSON.stringify(item))
                      tempObj.rating = Number(tempObj.rating)
                      tempObj.outOf = Number(tempObj.outOf)
                      if(!!!tempObj.isPublished){
                        tempObj.isPublished = false
                      }
                      if(!!!tempObj.isVerified){
                        tempObj.isVerified = false
                      }
                      temprevList.push(tempObj)
                    })
                  }
                  const obj = {
                    employmentHistoryList: values.employment,
                    referencesList: values.referencesList,
                    reviewsList: temprevList
                  };
                  setDataChange(false);
                  setEngProfileApi(obj, props.setProfileError, props.setProfileSuccess);
                  toast.success('Data Saved!');
                  if (values?.action === 'next') {
                    setTimeout(() => {
                      history.push(`/education`);
                    }, 1000);
                  }
                  }
                }}
              >
                <Card sx={{ marginTop: '20px', marginBottom: '40px' }}>
                  <CardContent>
                    <Box
                      sx={{
                        fontSize: '20px',
                        marginBottom: '15px',
                        fontWeight: '600',
                        color: '#0140AA',
                        // textTransform: 'uppercase'
                      }}
                    >
                      Employment History
                    </Box>


                    <FieldArray name="employment">
                      {({ push, remove }) => (
                        <>
                          {/* {console.log(values.employment)} */}
                          {values.employment.map((employee, i) => {
                            const companyName = `employment[${i}].companyName`;
                            const touchedCertificate = getIn(touched, companyName);
                            const errorCertificate = getIn(errors, companyName);
                            const designation = `employment[${i}].designation`;
                            const toucheddesignation = getIn(touched, designation);
                            const errordesignation = getIn(errors, designation);
                            const fromDate = `employment[${i}].fromDate`;
                            const touchedfromDate = getIn(touched, fromDate);
                            const errorfromDate = getIn(errors, fromDate);
                            const showRemove = values.employment.length > 1;
                            return (
                              <Box key={`emp[${i}]`}>
                                <Grid
                                  container
                                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                                  rowSpacing={{ xs: 3, sm: 2, md: 3 }}
                                >
                                  <Grid item sm={4} xs={12}>
                                    <TextField
                                      fullWidth
                                      name={`employment[${i}].companyName`}
                                      label='Company'
                                      required
                                      value={_get(employee, 'companyName')}
                                      onBlur={handleBlur}
                                      error={touchedCertificate && Boolean(errorCertificate)}
                                      helperText={touchedCertificate && errorCertificate}
                                      variant="outlined"
                                      onChange={(e) => {
                                        handleChange(e);
                                        setDataChange(true);
                                      }}
                                      {...formProps}
                                      InputLabelProps={{ shrink: true }}
                                    />
                                  </Grid>
                                  <Grid item sm={4} xs={12}>
                                    <TextField
                                      fullWidth
                                      name={`employment[${i}].designation`}
                                      label='Title'
                                      required
                                      value={_get(employee, 'designation')}
                                      variant="outlined"
                                      onBlur={handleBlur}
                                      error={toucheddesignation && Boolean(errordesignation)}
                                      helperText={toucheddesignation && errordesignation}
                                      onChange={(e) => {
                                        handleChange(e);
                                        setDataChange(true);
                                      }}
                                      {...formProps}
                                      InputLabelProps={{ shrink: true }}
                                    />
                                  </Grid>
                                  <Grid item sm={4} xs={12}>
                                    <FormControl variant="outlined" fullWidth>
                                      <InputLabel id="demo-simple-select-outlined-label">
                                        Country
                                      </InputLabel>
                                      <Select
                                        labelId="country"
                                        name={`employment[${i}].country`}
                                        value={_get(employee, 'country')}
                                        onChange={(eve) => {
                                          handleChange(eve);
                                          setDataChange(true);
                                          setFieldValue(`employment[${i}].country`, eve.target.value);
                                          getCountryValue(eve.target.value);
                                        }}
                                        label="Country"
                                      >
                                        {countries.length > 0 &&
                                          countries.map(({ name }) => {
                                            return (
                                              <MenuItem value={name} key={name}>
                                                {name}
                                              </MenuItem>
                                            );
                                          })}
                                      </Select>
                                    </FormControl>
                                  </Grid>
                                  <Grid item sm={4} xs={12}>
                                    <FormControl variant="outlined" fullWidth>
                                      <InputLabel id="demo-simple-select-outlined-label">
                                        Province
                                      </InputLabel>
                                      <Select
                                        labelId="province"
                                        name={`employment[${i}].province`}
                                        value={_get(employee, 'province')}
                                        onChange={(e) => {
                                          handleChange(e);
                                          setDataChange(true);
                                          setFieldValue(`employment[${i}].province`, e.target.value);
                                          getProvinceValue(e.target.value);
                                        }}
                                        label="Province"
                                        MenuProps={MenuProps}
                                        InputLabelProps={{ shrink: true }}
                                      >
                                        {countries.length > 0 &&
                                          countries.find(
                                            (x) => x.name === _get(employee, 'country')
                                          )?.states.map(({ name }) => {
                                            return (
                                              <MenuItem value={name} key={name}>
                                                {name}
                                              </MenuItem>
                                            );
                                          })}
                                      </Select>
                                    </FormControl>
                                  </Grid>
                                  <Grid item sm={4} xs={12}>
                                    <FormControl variant="outlined"
                                      InputLabelProps={{ shrink: true }}
                                      fullWidth>
                                      <InputLabel id="demo-simple-select-outlined-label">City</InputLabel>
                                      <Select
                                        fullWidth
                                        labelId="city"
                                        name={`employment[${i}].city`}
                                        value={_get(employee, 'city')}
                                        onChange={(event) => {
                                          handleChange(event);
                                          setDataChange(true);
                                          setFieldValue(`employment[${i}].city`, event.target.value);
                                          getCityValue(event.target.value);
                                        }}
                                        label="City"
                                        MenuProps={MenuProps}
                                        InputLabelProps={{ shrink: true }}
                                      >
                                        {countries.length > 0 &&
                                          countries.find((el) => el.name === _get(employee, 'country'))
                                            ?.states.find((el) => el.name === _get(employee, 'province'))
                                            ?.cities.map(({ name }) => {
                                              return (
                                                <MenuItem value={name} key={name}>
                                                  {name}
                                                </MenuItem>
                                              );
                                            })}
                                      </Select>
                                    </FormControl>
                                  </Grid>
                                  <Grid item sm={4} xs={12}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                      <DatePicker
                                        fullWidth
                                        disableFuture
                                        openTo="year"
                                        views={['year', 'month', 'day']}
                                        label="Start date"
                                        name={`employment[${i}].fromDate`}
                                        value={_get(employee, 'fromDate')}
                                        onChange={(newValue) => {
                                          setDataChange(true);
                                          setFieldValue(`employment[${i}].fromDate`, newValue);
                                        }}
                                        renderInput={(params) => (
                                          <TextField
                                            fullWidth
                                            InputLabelProps={{ shrink: true }}
                                            id="outlined-basic"
                                            variant="outlined"
                                            {...params}
                                            onBlur={handleBlur}
                                            error={touchedfromDate && Boolean(errorfromDate)}
                                            helperText={touchedfromDate && errorfromDate}
                                            required
                                          />
                                        )}
                                        InputLabelProps={{ shrink: true }}
                                      />
                                    </LocalizationProvider>
                                  </Grid>
                                  <Grid item sm={4} xs={12}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                      <DatePicker
                                        fullWidth
                                        disableFuture
                                        openTo="year"
                                        views={['year', 'month', 'day']}
                                        disabled={employee.checked}
                                        name={`employment[${i}].toDate`}
                                        minDate={_get(employee, 'fromDate')}
                                        label="End date"
                                        value={_get(employee, 'toDate')}
                                        onChange={(newValue) => {
                                          setDataChange(true);
                                          setFieldValue(`employment[${i}].toDate`, newValue);
                                        }}
                                        renderInput={(params) => (
                                          <TextField fullWidth InputLabelProps={{ shrink: true }} id="outlined-basic" variant="outlined" {...params} />
                                        )}
                                        InputLabelProps={{ shrink: true }}
                                      />
                                    </LocalizationProvider>
                                  </Grid>
                                  <Grid item sm={8} xs={12}>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          checked={_get(employee, 'checked')}
                                          onChange={(event) => {
                                            setDataChange(true);
                                            setFieldValue(
                                              `employment[${i}].checked`,
                                              event.target.checked
                                            );
                                            setFieldValue(`employment[${i}].toDate`, null);
                                          }}
                                          name={`employment[${i}].checked`}
                                        />
                                      }
                                      label="I currently work here"
                                    />
                                  </Grid>
                                  <Grid item sm={8} xs={12}>
                                    <Box
                                      sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        gap: '5px',
                                        alignItems: 'center'
                                      }}
                                    >
                                      <TextField
                                        fullWidth
                                        name={`employment[${i}].description`}
                                        value={_get(employee, 'description')}
                                        placeholder="Describe the key highlights of your work experience here."
                                        label="Description"
                                        onChange={(e) => {
                                          handleChange(e);
                                          setDataChange(true);
                                        }}
                                        multiline
                                        rows={4}
                                        variant="outlined"
                                        {...formProps}
                                        InputLabelProps={{ shrink: true }}
                                      />
                                      <div title="Describe the key highlights of your work experience here.">
                                        <HelpIcon sx={{ color: blue[500], fontSize: 16 }} />
                                      </div>
                                    </Box>
                                  </Grid>
                                  <Grid item sm={12} xs={12}>
                                    {showRemove && (
                                      <Button
                                        fontSize="small"
                                        onClick={() => remove(i)}
                                        variant="outlined"
                                        color="error"
                                        startIcon={<DeleteIcon />}
                                      >
                                        DELETE
                                      </Button>
                                    )}
                                  </Grid>
                                </Grid>
                                <Divider
                                  sx={{
                                    marginTop: '30px',
                                    marginBottom: '30px',
                                    borderColor: 'rgba(1, 64, 170, 0.8)'
                                  }}
                                />
                              </Box>
                            );
                          })}

                          <Grid item xs={12} md={6} lg={6} className="h-padding-top-16">
                            <Button
                              variant="outlined"
                              className="section-Button"
                              onClick={() => push(defaultObject)}
                            >
                              + Add experience
                            </Button>
                          </Grid>
                        </>
                      )}
                    </FieldArray>
                  </CardContent>
                </Card>
                <Card sx={{ marginTop: '20px', marginBottom: '40px' }}>
                  <CardContent>
                    <Box
                      sx={{
                        fontSize: '20px',
                        marginBottom: '15px',
                        fontWeight: '600',
                        color: '#0140AA',
                        // textTransform: 'uppercase'
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '2px',
                      }}
                    >
                      Reviews
                      <div title="Feedback received from clients and other gig sites.">
                        <HelpIcon sx={{ color: blue[500], fontSize: 16 }} />
                      </div>
                    </Box>

                    <FieldArray name="reviewsList">
                      {({ push, remove }) => (
                        <>
                          {values.reviewsList.map((review, i) => {
                            const showRemove = values.reviewsList.length > 0;
                            const showAddMore = values.reviewsList.length < 4;
                            const nameOfSource = `reviewsList[${i}].nameOfSource`;
                            const reviewLink = `reviewsList[${i}].reviewLink`;
                            const specification = `reviewsList[${i}].specification`;
                            const rating = `reviewsList[${i}].rating`;
                            const outOf = `reviewsList[${i}].outOf`;
                            const touchedNameOfSource = getIn(touched, nameOfSource);
                            const errorNameOfSource = getIn(errors, nameOfSource);
                            const touchedreviewLink = getIn(touched, reviewLink);
                            const errorreviewLink = getIn(errors, reviewLink);
                            const touchedSpecification = getIn(touched, specification);
                            const errorSpecification = getIn(errors, specification);
                            const touchedRating = getIn(touched, rating);
                            const errorRating = getIn(errors, rating);
                            const touchedOutOf = getIn(touched, outOf);
                            const errorOutOf = getIn(errors, outOf);
                            return (
                              <Box key={`reviewsList[${i}]`}>
                                <Grid
                                  container
                                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                                  rowSpacing={{ xs: 3, sm: 2, md: 3 }}
                                >
                                  <Grid item sm={4} xs={12}>
                                    <FormControl variant="outlined"
                                      InputLabelProps={{ shrink: true }}
                                      fullWidth
                                    >
                                      <InputLabel id="demo-simple-select-outlined-label" error={touchedNameOfSource && Boolean(errorNameOfSource)}>Name of source</InputLabel>
                                      <Select
                                        fullWidth
                                        error={touchedNameOfSource && Boolean(errorNameOfSource)}
                                        labelId="nameOfSource"
                                        name={`reviewsList[${i}].nameOfSource`}
                                        value={review.nameOfSource}
                                        onChange={(event) => {
                                          handleChange(event);
                                          setDataChange(true);
                                          setFieldValue(`reviewsList[${i}].nameOfSource`, event.target.value);
                                          getNameOfSourceValue(event.target.value);
                                        }}
                                        label="Name of source"
                                        MenuProps={MenuProps}
                                        InputLabelProps={{ shrink: true }}
                                      >
                                        {nameOfSourceList.map((val, id) => {
                                          return (
                                            <MenuItem value={val} key={val}>
                                              {val}
                                            </MenuItem>
                                          );
                                        })}
                                      </Select>
                                    </FormControl>
                                  </Grid>
                                  {review.nameOfSource === "Other" ? <Grid item sm={4} xs={12}>
                                    <TextField
                                      fullWidth
                                      name={`reviewsList[${i}].specification`}
                                      label='Please Specify'
                                      required
                                      value={review.specification}
                                      onBlur={handleBlur}
                                      error={touchedSpecification && Boolean(errorSpecification)}
                                      helperText={touchedSpecification && errorSpecification}
                                      variant="outlined"
                                      onChange={(e) => {
                                        handleChange(e);
                                        setDataChange(true);
                                      }}
                                      {...formProps}
                                      InputLabelProps={{ shrink: true }}
                                    />
                                  </Grid> : <></>}
                                  <Grid item sm={4} xs={12}>
                                    <TextField
                                      fullWidth
                                      name={`reviewsList[${i}].reviewLink`}
                                      label='Source URL'
                                      required
                                      value={review.reviewLink}
                                      onBlur={handleBlur}
                                      error={touchedreviewLink && Boolean(errorreviewLink)}
                                      helperText={touchedreviewLink && errorreviewLink}
                                      variant="outlined"
                                      onChange={(e) => {
                                        handleChange(e);
                                        setDataChange(true);
                                      }}
                                      {...formProps}
                                      InputLabelProps={{ shrink: true }}
                                    />
                                  </Grid>
                                  {review.nameOfSource !== "Other" ? <Grid item sm={4} xs={12}></Grid> : <></>}
                                  <Grid item sm={1.5}>
                                    <TextField
                                      fullWidth
                                      name={`reviewsList[${i}].rating`}
                                      label='Rating'
                                      required
                                      value={review.rating}
                                      onBlur={handleBlur}
                                      error={touchedRating && Boolean(errorRating)}
                                      helperText={touchedRating && errorRating}
                                      variant="outlined"
                                      onChange={(e) => {
                                        handleChange(e);
                                        setDataChange(true);
                                      }}
                                      {...formProps}
                                      InputLabelProps={{ shrink: true }}
                                    />
                                  </Grid>
                                  <div style={{ padding: "24px 0px 0px 6px",  display: "flex", alignItems: "center" }}>Out of</div>
                                  <Grid item sm={1.5} style={{ paddingLeft: "6px", paddingRight: "18px" }}>
                                    <TextField
                                      fullWidth
                                      name={`reviewsList[${i}].outOf`}
                                      required
                                      value={review.outOf}
                                      onBlur={handleBlur}
                                      error={touchedOutOf && Boolean(errorOutOf)}
                                      helperText={touchedOutOf && errorOutOf}
                                      variant="outlined"
                                      onChange={(e) => {
                                        handleChange(e);
                                        setDataChange(true);
                                      }}
                                      {...formProps}
                                      InputLabelProps={{ shrink: true }}
                                    />
                                  </Grid>
                                  <Grid className='cta-wrapper-verify' item sm={12} xs={12}>
                                    {
                                      ("isVerified" in review) && review.isVerified!=null
                                      &&
                                      <div className={"verify-btn-wrapper"}>
                                        {
                                          review.isVerified?
                                          review.isPublished?
                                          <button title={"This review has been published."} disabled className={"verify-btn disabled"}>PUBLISHED</button>
                                          :
                                          <button onClick={(e)=>{e.preventDefault();handleVerifyRating(review, i, true)}} className={"verify-btn"}>PUBLISH</button>
                                          :
                                          <button title={(("isRejected" in review) && review.isRejected)?"":"This review has been submitted for verification"} disabled className={"verify-btn disabled"}>PUBLISH</button>
                                        }
                                        {
                                          (("isRejected" in review) && review.isRejected)?
                                          <img title={"Not verified. For more information contact Gig Engineer"} src={DangerCross}/>
                                          :
                                          (review.isVerified?
                                          <img title={review.isPublished?"This review has been published.":"This review has been verified and is ready to be published."} src={CheckCircle}/>
                                          :
                                          <img style={{display:"none"}} src={CheckCircle}/>)
                                        }
                                      </div>
                                    }
                                    {showRemove && (
                                      <Button
                                        fontSize="small"
                                        onClick={() => remove(i)}
                                        variant="outlined"
                                        color="error"
                                        startIcon={<DeleteIcon />}
                                      >
                                        DELETE
                                      </Button>
                                    )}
                                  </Grid>
                                </Grid>
                                <Divider
                                  sx={{
                                    marginTop: '30px',
                                    marginBottom: '30px',
                                    borderColor: 'rgba(1, 64, 170, 0.8)'
                                  }}
                                />
                              </Box>
                            );
                          })}

                          <Grid item xs={12} md={6} lg={6} className="h-padding-top-16">
                            {values.reviewsList.length < 5 &&
                              <Button
                                variant="outlined"
                                className="section-Button"
                                onClick={() => push(defaultReviewObject)}
                              >
                                + Add review
                              </Button>
                            }
                          </Grid>
                        </>
                      )}
                    </FieldArray>
                  </CardContent>
                </Card>
                <Card sx={{ marginTop: '20px', marginBottom: '40px' }}>
                  <CardContent>
                    <Box
                      sx={{
                        fontSize: '20px',
                        marginBottom: '15px',
                        fontWeight: '600',
                        color: '#0140AA',
                        // textTransform: 'uppercase'
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '2px',
                      }}
                    >
                      References
                      <div title="Contactable work references may be used for vetting purposes. We will not contact any referee without your permission. ">
                        <HelpIcon sx={{ color: blue[500], fontSize: 16 }} />
                      </div>
                    </Box>


                    <FieldArray name="referencesList">
                      {({ push, remove }) => (
                        <>
                          {values.referencesList.map((reference, i) => {
                            const showRemove = values.referencesList.length > 0;
                            const showAddMore = values.referencesList.length < 4;
                            const name = `referencesList[${i}].name`;
                            const emailId = `referencesList[${i}].emailId`;
                            const phone = `referencesList[${i}].phone`;
                            const company = `referencesList[${i}].company`;
                            const jobTitle = `referencesList[${i}].jobTitle`;
                            const touchedName = getIn(touched, name);
                            const errorName = getIn(errors, name);
                            const touchedEmail = getIn(touched, emailId);
                            const errorEmail = getIn(errors, emailId);
                            return (
                              <Box key={`emp[${i}]`}>
                                <Grid
                                  container
                                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                                  rowSpacing={{ xs: 3, sm: 2, md: 3 }}
                                >
                                  <Grid item sm={4} xs={12}>
                                    <TextField
                                      fullWidth
                                      name={`referencesList[${i}].name`}
                                      label='Full Name'
                                      required
                                      value={_get(reference, 'name')}
                                      onBlur={handleBlur}
                                      variant="outlined"
                                      error={touchedName && Boolean(errorName)}
                                      helperText={touchedName && errorName}
                                      onChange={(e) => {
                                        handleChange(e);
                                        setDataChange(true);
                                      }}
                                      {...formProps}
                                      InputLabelProps={{ shrink: true }}
                                    />
                                  </Grid>
                                  <Grid item sm={4} xs={12}>
                                    <TextField
                                      fullWidth
                                      name={`referencesList[${i}].emailId`}
                                      label='Email Address'
                                      required
                                      value={_get(reference, 'emailId')}
                                      onBlur={handleBlur}
                                      error={touchedEmail && Boolean(errorEmail)}
                                      helperText={touchedEmail && errorEmail}
                                      variant="outlined"
                                      onChange={(e) => {
                                        handleChange(e);
                                        setDataChange(true);
                                      }}
                                      {...formProps}
                                      InputLabelProps={{ shrink: true }}
                                    />
                                  </Grid>
                                  <Grid item sm={4} xs={12}>
                                    <MuiPhoneNumber
                                      fullWidth
                                      label="Phone number"
                                      value={_get(reference, 'phone')}
                                      disableAreaCodes={true}
                                      onChange={(e) => {
                                        // handleChange(e);
                                        setFieldValue(`referencesList[${i}].phone`, e)
                                        setDataChange(true);
                                      }}
                                      onBlur={handleBlur}
                                      variant="outlined"
                                      InputLabelProps={{ shrink: true }}
                                    />
                                  </Grid>
                                  <Grid item sm={4} xs={12}>
                                    <TextField
                                      fullWidth
                                      name={`referencesList[${i}].company`}
                                      label='Company'
                                      value={_get(reference, 'company')}
                                      onBlur={handleBlur}
                                      variant="outlined"
                                      onChange={(e) => {
                                        handleChange(e);
                                        setDataChange(true);
                                      }}
                                      {...formProps}
                                      InputLabelProps={{ shrink: true }}
                                    />
                                  </Grid>
                                  <Grid item sm={4} xs={12}>
                                    <TextField
                                      fullWidth
                                      name={`referencesList[${i}].jobTitle`}
                                      label='Job Title'
                                      value={_get(reference, 'jobTitle')}
                                      onBlur={handleBlur}
                                      variant="outlined"
                                      onChange={(e) => {
                                        handleChange(e);
                                        setDataChange(true);
                                      }}
                                      {...formProps}
                                      InputLabelProps={{ shrink: true }}
                                    />
                                  </Grid>

                                  <Grid item sm={12} xs={12}>
                                    {showRemove && (
                                      <Button
                                        fontSize="small"
                                        onClick={() => remove(i)}
                                        variant="outlined"
                                        color="error"
                                        startIcon={<DeleteIcon />}
                                      >
                                        DELETE
                                      </Button>
                                    )}
                                  </Grid>
                                </Grid>
                                <Divider
                                  sx={{
                                    marginTop: '30px',
                                    marginBottom: '30px',
                                    borderColor: 'rgba(1, 64, 170, 0.8)'
                                  }}
                                />
                              </Box>
                            );
                          })}

                          <Grid item xs={12} md={6} lg={6} className="h-padding-top-16">
                            {values.referencesList.length < 4 &&
                              <Button
                                variant="outlined"
                                className="section-Button"
                                onClick={() => push(defaultReferenceObject)}
                              >
                                + Add references
                              </Button>
                            }
                          </Grid>
                        </>
                      )}
                    </FieldArray>
                  </CardContent>
                </Card>
                <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Button
                    variant="contained"
                    className={`primary-Btn ${props.saveSuccess && 'successActive'}`}
                    type="submit"
                    onClick={() => {
                      setFieldValue("action", "save");
                    }}
                  >
                    {props.saveSuccess ? 'saved' : 'save'}
                  </Button>
                  <Button
                    variant="contained"
                    className="primary-Main-Btn"
                    type="submit"
                    onClick={() => {
                      setFieldValue("action", "next");
                    }}
                  >
                    Next
                  </Button>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </PaddedBox>
      <PaddedFooter style={{ paddingLeft: `${!!props.auth && !!props.auth.isDrawerOpen && props.auth.isDrawerOpen == true ? "200px" : "0px"}` }}>
        <Footer />
      </PaddedFooter>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    employment: state.profile.employmentHistoryList || [],
    referencesList: state.profile.referencesList || [],
    reviewsList: state.profile.reviewsList || [],
    error: state.profile.error,
    saveSuccess: state.profile.saveSuccess,
    profile: state.profile
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setProfileError: (details) => dispatch(setProfileError(details)),
    setProfileSuccess: (details) => dispatch(setProfileSuccess(details)),
    resetProfileSuccess: (details) => dispatch(resetProfileSuccess(details)),
    setProfileDetails: (details) => dispatch(setProfileDetails(details))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Employment);
