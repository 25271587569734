import styled from 'styled-components';
import { CustomGridContainer } from '../../app.styles';

export const GigContainer = styled(CustomGridContainer)`
  && {
    
    .RFS-StepperContainer {
      min-width: 90%;
      font-family: 'Poppins', sans-serif;
      padding: 0px 0px ;
    }
    .RFS-StepButtonContent {
      display: none;
    }
    .RFS-LabelContainer {
      span {
        font-size: 18px;
      }
    }
    .RFS-StepButton {
      &.active {
        border-radius: 20px;
        border: 6px solid #f99600;
        font-weight: bold;
        :hover {
          background-color: #d5d7e3;
        }
        + .RFS-LabelContainer {
          color: #f99600;
          span {
            font-weight: 500;
          }
        }
      }
      &.completed {
        + .RFS-LabelContainer {
          color: #0140AA;
          span {
            font-weight: bold;
          }
        }
      }

      + .RFS-LabelContainer {
        color: #7e84a3;
      }
    }
  }
`;
