import {
  SET_NEWGIG_DETAILS,
  SET_NEWGIG_STEP,
  SET_NEWGIG_ERROR,
  SET_NEWGIG_SUCCESS,
  RESET_NEWGIG_DETAILS
} from '../actions/newGig';

const newGig = (state = {}, action) => {
  switch (action.type) {
    case SET_NEWGIG_DETAILS:
      return {
        ...action.payload,
        error: {},
        saveSuccess: false,
        reComputeStep: true,
        newGigSaveSucess: false
      };
    case SET_NEWGIG_ERROR:
      return {
        ...state,
        error: {
          ...action.payload
        },
        saveSuccess: false,
        reComputeStep: false
      };
    case SET_NEWGIG_SUCCESS:
      return {
        ...state,
        ...action.payload,
        error: {},
        saveSuccess: true,
        reComputeStep: false
      };
    case SET_NEWGIG_STEP: {
      return {
        ...state,
        ...action.payload,
        error: {},
        saveSuccess: false,
        reComputeStep: true,
        newGigSaveSucess: false
      };
    }
    case RESET_NEWGIG_DETAILS:
      return {};
    default:
      return state;
  }
};

export default newGig;
