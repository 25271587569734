export const SET_CHAT_MSG = '@gig/chat/message';
export const SET_CHAT_NEWUSER = '@gig/chat/newuser';
export const SET_CHAT_USERS_LIST = '@gig/chat/users/list';
export const SET_CHAT_ALL = '@gig/chat/all';
export const SET_CHAT_MSGS = '@gig/chat/msgs';
export const SET_CHAT_SELECTED_USER = '@gig/chat/selected';
export const SET_RECEIVED_MSG = '@gig/chat/received';
export const SET_CHAT_NOTIFIER = '@gig/chat/notifier';
export const SET_CHAT_USER_NAME = '@gig/chat/username';
export const SET_NOTIFICATION = '@gig/chat/notification';

export const setChatMessage = (data) => ({
  type: SET_CHAT_MSG,
  payload: data
});

export const setChatNewUser = (data) => ({
  type: SET_CHAT_NEWUSER,
  payload: data
});

export const setAllChats = (data) => ({
  type: SET_CHAT_ALL,
  payload: data
});

export const setChatMsgs = (data) => ({
  type: SET_CHAT_MSGS,
  payload: data
});

export const setSelectedUser = (data) => ({
  type: SET_CHAT_SELECTED_USER,
  payload: data
});

export const setReceivedMessage = (data) => ({
  type: SET_RECEIVED_MSG,
  payload: data
});

export const setChatNotifier = (data) => ({
  type: SET_CHAT_NOTIFIER,
  payload: data
});

export const setChatUserName = (data) => ({
  type: SET_CHAT_USER_NAME,
  payload: data
});

export const setNotification = (data) => ({
  type: SET_NOTIFICATION,
  payload: data
});