import React from 'react';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { Drawer } from '@mui/material';
import { FilterIconbtn } from './sfs.styles';
import FiltersList from './filters/FiltersList';
import { useState } from 'react';
import { connect } from 'react-redux';
import _get from 'lodash/get';
import PopUpPercentage from '../PopUpPercentage/PopUpPercentage';

const Filter = (props) => {
  const [state, setState] = React.useState(false);
  const percentage = _get(props, 'profile.profileCompletionPercent', 0);
  const [openDashboard, setOpenDashboard] = useState(false);

  const modalOpen = () => {
    setOpenDashboard(true);
  }

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState(open);
  };

  return (
    <>
      {(percentage < 60) ?
        <FilterIconbtn aria-label="Filters" onClick={modalOpen} sx={{ paddingTop: '5px' }}>
          <FilterAltIcon />
        </FilterIconbtn> :
        <FilterIconbtn aria-label="Filters" onClick={toggleDrawer(true)} sx={{ paddingTop: '5px' }}>
          <FilterAltIcon />
        </FilterIconbtn>
      }
      <Drawer anchor="right" open={state} onClose={toggleDrawer(false)}>
        {/* <Toolbar /> */}
        <FiltersList filterType={props.filterType} toggleDrawer={toggleDrawer(false)} />
      </Drawer>
      {openDashboard ? <PopUpPercentage modalOpen={openDashboard} modalClose={(e) => { setOpenDashboard(false) }} /> : <></>}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    searchKey: state.sfs.searchKey,
    representative: state.profile.basicInfo || {},
    profile: state.profile
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSearchKey: (details) => dispatch(setSearchKey(details))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Filter);
