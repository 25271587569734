import React from 'react';
import { Container } from '@mui/material';
import HeaderWithLogo from '../../components/HeaderWithLogo/HeaderWithLogo';
import { BlogImage } from './Signin.styles';
import WorkingBlog from '../../assests/workingBlog.png';
import SignUpForm from '../../components/SignUpForm/SignUpForm';
import SignInForm from '../../components/SignInForm/SignInForm';
import CssBaseline from '@mui/material/CssBaseline';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { useHistory } from 'react-router-dom';
import '../../components/SignUpForm/SignUp.css';

const theme = createTheme();

const SignUp = () => {
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      email: data.get('email'),
      password: data.get('password')
    });
  };
  const his = useHistory();

  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: '100%' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{ display: { sm: 'none', xs: 'none', md: 'flex' }, alignItems: "center", justifyContent: "center" }}
        >
          <img src={WorkingBlog} alt="" width="70%" />
        </Grid>
        <Grid className='size-fix' item xs={12} sm={12} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 6,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              color: '#757575',
            }}
          >
            <HeaderWithLogo />
            <Typography
              sx={{
                mt: 4,
              }}
              component="h1" variant="h5">
              Create Account
            </Typography>
            <SignUpForm />
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>

  );
};
export default SignUp;
