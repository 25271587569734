import React from 'react';

const extractMessage = (msg) => {
  return msg.messageType === 'FILE' ? msg.data.message.fileName :msg.data.message;
}

const extractFileUrl = (msg) => {
  return msg.data.message.fileUrl;
} 

export const MessageLeft = (props) => {
  const message = extractMessage(props.message);
  const timestamp = props.timestamp ? props.timestamp : '';
  return (
    <div className='messageRow'>
      <div className="messageBlue">
        { props.messageType == "FILE" ? <a href={extractFileUrl(props.message)} download>{message}</a> :<p className='messageContent'>{message}</p>}
        <div className='messageTimeStampRight'>{timestamp}</div>
      </div>
    </div>
  );
};

export const MessageRight = (props) => {
  const message = extractMessage(props.message);
  const timestamp = props.timestamp ? props.timestamp : '';
  return (
    <div className='messageRowRight'>
      <div className='messageOrange'>
        { props.messageType == "FILE" ? <a href={extractFileUrl(props.message)} download>{message}</a> :<p className='messageContent'>{message}</p>}
        <div className='messageTimeStampRight'>{timestamp}</div>
      </div>
    </div>
  );
};
