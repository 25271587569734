import _get from 'lodash/get';
import apiService from './apiServices';

export const getUploadApi = async (file) => {
  const request = {
    method: 'get',
    url: '/aws/s3signedUrl?fileName=' + file.name
  };
  const response = file?.name ? await apiService(request) : '';
  if (response && response.status === 200) {
    await postUpload(response, file);
    return response;
  }
};

const postUpload = async (response, file) => {
  let formData = new FormData();
  for (let key in response.data.fields) {
    formData.append(key, response.data.fields[key]);
  }
  formData.append('file', file);
  const request = {
    method: 'post',
    url: response.data.url,
    data: formData
  };
  const responsee = await apiService(request);
};
