import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _toNumber from 'lodash/toNumber';
import _last from 'lodash/last';
import { PaddedBox } from '../../app.styles';
import { Button, Dialog, Grid } from '@mui/material';
import GridTable from '../../../components/GridTable/GridTable';
import { formatDate, getHireLocation, getQueryParams } from '../../../utils/helpers';
import { getGigs, hireUnHire } from '../../../services/gig';
import Typography from '@mui/material/Box';
import { Box } from '@mui/system';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import '../gigDashboard/GigDashboard.css';

const MyHires = (props) => {

    const query = getQueryParams();
    const queryPage = query.page;

    const [rows, setRows] = useState([]);
    const [page, setPage] = useState(queryPage || 1);
    const [paginationVal, setPaginationVal] = useState(1);
    const [open, setOpen] = React.useState(false);
    const [fullWidth, setFullWidth] = React.useState(true);
    const [maxWidth, setMaxWidth] = React.useState('sm');
    const history = useHistory();
    const [hireState, setHireState] = useState({});
    const [currentEngProfilePic, setcurrentEngProfilePic] = useState(null)

    const getMessageLink = (msglink, engineerName, gigName, userId, gigId) => {
        return (
            <div>
                <a
                    className="message-Link"
                    onClick={() => {
                        history.push(msglink);
                    }}
                >
                    Message
                    <span>&gt;</span>
                </a>
                <br />
                <Button
                    variant="contained"
                    className="popup-cancel"
                    sx={{
                        // textTransform: 'lowercase',
                        alignItems: 'center',
                        marginTop: '5px'
                    }}
                    onClick={() => {
                        setOpen(true);
                        setHireState({ engineerName, gigName, userId, gigId });
                    }}
                >
                    Unhire
                </Button>
            </div>
        )
    }

    const unhireSubmit = async (e, gigName) => {
        e.preventDefault();
        let data = {
            gigId: hireState.gigId,
            userId: hireState.userId,
        }

        hireUnHire(data).then((e)=>{
            socket.emit('postNotification', {
                "notificationDescription": `You've been unhired for ${gigName}`,
                "userRole": ["engineer"],
                "engineerId": hireState.userId,
                "isRead": false,
                "engineerProfilePic": currentEngProfilePic,
                "redirectUrl": "/active",
            });
            toast.success(response.message);
            setOpen(false);
            fetchData(1);
          })
    }

    const myhire = ['ENGINEER NAME', 'GIG NAME', 'LOCATION', 'START DATE', 'END DATE', 'ACTION'];

    const setData = (data) => {
        const rowData = [];
        const hireList = _get(data, 'hiredetailsList', []);
        const paginationObject = _get(data, 'paginationResult', {});
        if (hireList && hireList.length > 0) {
            for (let obj of hireList) {
                const engineerName = _get(obj, 'hireDetails.engineerName', '');
                const gigName = _get(obj, 'hireDetails.gigName', '');
                const userId = _get(obj, 'hireDetails.userId', '');
                const gigId = _get(obj, 'hireDetails.gigId', '');
                setcurrentEngProfilePic(_get(obj, 'userId.profileData.myProfile.profilePic', ''));
                let location = getHireLocation(obj.hireDetails, obj.isRemote);
                const fromDate = _get(obj, 'hireDetails.fromDate', '') ? formatDate(_get(obj, 'hireDetails.fromDate', '')) : '-';
                const toDate = _get(obj, 'hireDetails.toDate', '-');
                const message = `/ochat?to=${_get(obj, 'hireDetails.userId', '')}`
                rowData.push([engineerName, gigName, location, fromDate, toDate, getMessageLink(message, engineerName, gigName, userId, gigId), gigId, userId]);
            }
        }
        setRows(rowData);
        setPaginationVal(paginationObject.totalPages || 1);
    };

    const fetchData = (currentPage) => {
        const obj = {
            paginationInput: {
                pageNumber: _toNumber(currentPage),
                pageSize: 10
            },
            status: ['HIRE']
        };
        getGigs(obj, setData);
    };

    useEffect(() => {
        fetchData(page);
    }, []);

    const handleChange = (event, value) => {
        fetchData(value);
        setPage(value);
    };

    const handleClose = () => {
        setOpen(false);
    }

    const handleRowClick = (data) => {
        const gigData = data.at(-2);
        history.push(`/gig/hires/${gigData}`);
    };

    const handleRClick = (data) => {
        const gigData = _last(data);
        history.push(`/engineer/details/${gigData}`);
    };

    return (
        <PaddedBox style={{ textAlign: 'center', paddingLeft: `${!!props.auth && !!props.auth.isDrawerOpen && props.auth.isDrawerOpen == true ? "240px" : "0px"}` }}>
            <Grid container>
                <Typography
                    className='font-fix'
                    variant="h6"
                    component="h6"
                    color="pColor.main"
                >
                    My Hires
                </Typography>

                <Grid container className="table-Padding">
                    <GridTable
                        headers={myhire}
                        rows={rows}
                        handleRowClick={handleRowClick}
                        handleRClick={handleRClick}
                        handleChange={handleChange}
                        pagination={paginationVal}
                        defaultPage={_toNumber(page)}
                        emptyMessage="You don't have any hires yet"
                    ></GridTable>
                </Grid>
            </Grid>
            <Dialog fullWidth={fullWidth} maxWidth={maxWidth} open={open} onClose={handleClose}>
                <Box
                    sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px' }}
                >
                    <br />
                    <Typography>
                        Are you sure you want to unhire <strong>{hireState.engineerName}</strong> for <strong>{hireState.gigName}</strong>?
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            marginTop: '30px',
                        }}
                    >
                        <Button
                            variant="contained"
                            className="popup-process"
                            sx={{
                                width: "140px",
                                backgroundColor: '#0140AA',
                            }}
                            onClick={(e) => unhireSubmit(e, hireState.gigName)}
                        >
                            Proceed
                        </Button>
                        <Box
                            sx={{
                                marginLeft: '20px',
                            }}                        >
                            <Button
                                variant="contained"
                                className="popup-cancel"
                                type="submit" sx={{
                                    width: '140px',
                                }}
                                onClick={handleClose}
                            >
                                Cancel
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Dialog>
        </PaddedBox>
    );
};
const mapStateToProps = (state) => {
    return {
        auth: state.auth,
    };
};
export default connect(mapStateToProps)(MyHires);

