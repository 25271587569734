import _get from 'lodash/get';
import apiService from './apiServices';
import { downloadFileFromLink } from '../utils/helpers';

export const getProfileDetApi = async (setProfileDetails) => {
  const request = {
    method: 'get',
    url: '/profile/'
  };
  const response = await apiService(request);
  if (response && response.status === 200) {
    // sucess action
    setProfileDetails(_get(response, 'data.profile', {}));
  }
};

export const setEngProfileApi = async (data, setProfileError, setProfileSuccess) => {
  const request = {
    data,
    method: 'post',
    url: '/profile/engineer/'
  };
  const response = await apiService(request);
  if (response && response.status === 200) {
    // sucess action what to do
    //setProfileSuccess();
    setProfileSuccess(_get(response, 'data.profile', {}));
  } else {
    // show global error message via props
    setProfileError(response);
  }
};

export const setBusinessProfileApi = async (data, setProfileError, setProfileSuccess) => {
  const request = {
    data,
    method: 'post',
    url: '/profile/business/'
  };
  const response = await apiService(request);
  if (response && response.status === 200) {
    // sucess action what to do
    //setProfileSuccess();
    setProfileSuccess(_get(response, 'data.profile', {}));
  } else {
    // show global error message via props
    setProfileError(response);
  }
};

export const getProfileDetByIdApi = async (id) => {

  const request = {
    method: 'get',
    url: `/profile/${id}`
  };

  const response = await apiService(request);
  if (response && response.status === 200) {
    // sucess action
    return _get(response, 'data', {});
  }
};

export const verifyEngineerUser = async (data) => {

  const request = {
    data,
    method: 'post',
    url: `/profile/verify-engineer`
  };

  const response = await apiService(request);
  if (response && response.status === 200) {
    // sucess action
    return response;
  }
};

export const getProfileDetNoValidationByIdApi = async (userName) => {
  const request = {
    method: 'get',
    url: `/profile/username/${userName}`
  };
  const response = await apiService(request);
  if (response && response.status === 200) {
    // sucess action
    return _get(response, 'data', {});
  }
};

export const getAllEngineerList = async (data, setData, setListDataCard) => {
  const request = {
    data,
    method: 'post',
    url: `/opportunity/discoverEngineers`
  };
  const response = await apiService(request);
  if (response && response.status === 200) {
    // sucess action
    setListDataCard(_get(response, 'data', {}));
    setData(_get(response, 'data', {}));
    // callback(_get(response, 'data', {}));
  }
};

export const getAllAdminList = async (data, setListData, setListDataEngineer) => {
  const request = {
    data,
    method: 'post',
    url: `/opportunity`
  };
  const response = await apiService(request);
  if (response && response.status === 200) {
    // sucess action
    setListData(_get(response, 'data', {}));
    setListDataEngineer(_get(response, 'data', {}));
  }
};

export const modifyEnginnerStatus = async (data, callback) => {
  const request = {
    data,
    method: 'post',
    url: '/admin/'
  };
  const response = await apiService(request);
  if (response && response.status === 200) {
    // sucess action
    callback(_get(response, 'data', {}));
  }
};

export const loginUserViaAdmin = async (userId) => {
  const request = {
    data: { "userId": userId },
    method: 'post',
    url: '/auth/superUserLogin'
  };
  try {
    const response = await apiService(request);
    if (response && response.status === 200) {
      window.open(`${window.location.origin}/?access_token=${response.data.accessToken}&refresh_token=${response.data.refreshToken}`, "_blank")
      return response
    }
  }
  catch (e) {
    console.error(e)
  }
}

export const adminReporting = async (payload) => {
  const request = {
    data: payload,
    method: 'post',
    url: '/admin/reporting'
  };
  try {
    const response = await apiService(request);
    return response
  }
  catch (e) {
    console.error(e)
  }
}

export const adminCvDownload = async (payload) => {
  const request = {
    data: payload,
    method: 'post',
    url: '/admin/download-profile'
  };

  try {
    const response = await apiService(request);
    // console.log(response)
    if(!!response){
      downloadFileFromLink(response.data)
    }
  }
  catch (e) {
    console.error(e)
  }
}