import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
    Grid,
    CardHeader,
    CardContent,
    Avatar,
    Typography,
    Pagination,
    Box,
    Chip,
} from '@mui/material';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _toNumber from 'lodash/toNumber';
import { PaddedBox, CustomRow, EditedCard, PaddedFooter } from '../../../../app.styles';
import { getAllAdminList } from '../../../../../services/profile';
import PageLoader from '../../../../../components/Loaders/PageLoader';
import { formatDate, getDurationLabel, getGigLocation, getQueryParams, scrollOnTop } from '../../../../../utils/helpers';
import { getUserType } from '../../../../../utils/user';
import Search from '../../../../../components/SearchBar/Search';
import { resetSFS } from '../../../../../store/actions/sfs';
import Sort from '../../../../../components/SearchBar/Sort';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AllGigs from './gigDetails/AllGigs.css';
import Button from '@mui/material/Button';
import AllGigTable from '../../../../../assests/allGigTable.svg';
import GridViewIcon from '@mui/icons-material/GridView';
import ReactGA from "react-ga";
import Footer from '../../../../../components/footer1/footer';
import StatusRightIcon from '../../../../../assests/StatusRightIcon.svg';
import InactiveStatus from '../../../../../assests/InactiveStatus.svg';
import CloseStatus from '../../../../../assests/CloseStatus.svg';
import DraftStatus from '../../../../../assests/DraftStatus.svg';
import GridTableEngineer from '../../../../../components/GridTable/GridTableEngineer';
import Hours from "../../../../../assests/Hours.svg";
import Days from "../../../../../assests/Days.svg";
import Weeks from "../../../../../assests/Weeks.svg";
import Months from "../../../../../assests/Months.svg";
import Skills from "../../../../../assests/Skills.png";
import IcFeedbackRecieve from '../../../../../assests/ic-feedback-recieve.svg';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';

ReactGA.initialize(`G-1596JC7760`);
ReactGA.pageview(window.location.pathname + window.location.search);

const AllGigsAdmin = (props) => {

    const history = useHistory();
    const userType = getUserType(); // get the user type from tokens
    const query = getQueryParams();
    const queryPage = query.page || 1; // active engineer page
    const sfsReset = useRef(true); // reset search filter sort on navigate to another page
    const filterApplied =
        !_isEmpty(props.searchKey) || !_isEmpty(props.filters) || !_isEmpty(props.sortBy);
    const [paginationVal, setPaginationVal] = useState(1);
    const [list, setList] = useState([]); // acitve engineer list returned from api
    const [page, setPage] = useState(_toNumber(queryPage)); // active enginner list page number
    const [count, setCount] = useState(1); // count of total engineer
    const [tableData, setTableData] = useState("tableView");
    const [engineerProfile, setEngineerProfile] = useState([]);

    const setListData = (data) => {
        setList(_get(data, 'gigList', []));
        setPage(_get(data, 'paginationResult.currentPage', 1));
        setCount(_get(data, 'paginationResult.totalPages', 1));
    };
    const getMessageLink = (userId, _id, obj) => {
        return (
            <div>
                {userType === 'admin' && (
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Button
                            className="admin-all-btn"
                            sx={{
                                color: '#FFFFFF',
                                borderRadius: '5px',
                                width: '127px',
                                height: '32px',
                                textTransform: 'capitalize'
                            }}
                            onClick={() => {
                                history.push(`/admin/owner/allgigs/details/${_id}`, obj);
                            }}
                        >
                            View Details
                        </Button>
                        {
                            !!obj.referralsData
                            &&
                            Array.isArray(obj.referralsData)
                            &&
                            obj.referralsData.length>0?
                            <img style={{ marginLeft: "13.65px", cursor: "pointer" }} src={IcFeedbackRecieve} alt="viewRecievedFeedback" onClick={()=>history.push(`/gig/review/admin/viewFeedback/${_id}`)} />
                            :
                            <img style={{ marginLeft: "13.65px", cursor: "pointer", pointerEvents:"none", opacity: 0 }} src={IcFeedbackRecieve} alt="viewRecievedFeedback" />
                        }
                    </Box>
                )}
            </div>
        );
    };

    const fetchMoreData = (currentPage) => {
        const obj = {
            paginationInput: {
                pageNumber: currentPage,
                pageSize: 12
            },
            filter: {
                gigStatusList: ['ACTIVE'],
            },
            sortByKey: "CREATEDAT",
            sortByOrder: -1,
            ...props.sortBy
        };

        // admin page we need show the inactive users also
        if (userType === 'admin') {
            obj.filter.gigStatusList.push('INACTIVE');
            obj.filter.gigStatusList.push('CLOSED');
        }

        if (!_isEmpty(props.searchKey)) {
            obj.search = props.searchKey;
        }

        if (!_isEmpty(props.filters)) {
            obj.filter = {
                ...obj.filter,
                ...props.filters
            };
        }

        getAllAdminList(obj, setListData, setListDataEngineer);
    };

    const setListDataEngineer = (data) => {
        const rowData = [];
        const engineerListData = _get(data, 'gigList', []);
        const totalRecords = _get(data, 'paginationResult', {});
        if (engineerListData && engineerListData.length > 0) {
            for (let obj of engineerListData) {
                const gigName = _get(obj, 'titleAndSkills.projectTitle', '') ? _get(obj, 'titleAndSkills.projectTitle', '') : '-';
                const postOn = formatDate(_get(obj, 'createdAt', '-') ? _get(obj, 'createdAt', '-') : '-');
                const gigId = _get(obj, 'applicationDetails.gigId', '');
                const location = _get(obj, 'deliverablesAndLocation.location.country', '-') ? _get(obj, 'deliverablesAndLocation.location.country', '-') : '-';
                const postBy = _get(obj, 'companyDetails.companyName', '') ? _get(obj, 'companyDetails.companyName', '') : '-';
                const userId = _get(obj, 'userId', '');
                const id = _get(obj, '_id', '');
                rowData.push([gigName, postOn, location, postBy, getMessageLink(userId, id, obj), { id, name }, obj, gigId, userId]);
            }
        }
        setEngineerProfile(rowData);
        setPaginationVal(totalRecords.totalPages || 1);
    };

    // click on pagination number
    const onPaginationClick = (event, value) => {
        scrollOnTop();
        fetchMoreData(value);
        setPage(value);
    };

    const onTableChange = (event, displayView) => {
        fetchMoreData(1);
        if (displayView === 'cardView') {
          setTableData('cardView');
          setPaginationVal(1);
        } else {
          setTableData('tableView');
          setPage(1);
        }
    };

    const handleChange = (event, View) => {
        scrollOnTop();
        fetchMoreData(View);
        setPaginationVal(View);
    };

    const handleRClick = (data) => {
        const gigData = data.pop();
        history.push(`/admin/owner/allgigs/details/${data[6]._id}`);
    };

    useEffect(() => {
        if (tableData === 'tableView') {
            fetchMoreData(filterApplied ? 1 : page);
        } else {
            fetchMoreData(filterApplied ? 1 : paginationVal);
        }
    }, [props.searchKey, props.filters, props.sortBy]);

    useEffect(() => {
        return () => {
            sfsReset.current && props.resetSFS('');
        };
    }, []);

    const headers = ['Gig Name', 'Posted on', 'Location', 'Posted By', 'Action'];

    return (
        <>
            <PaddedBox style={{ paddingLeft: `${!!props.auth && !!props.auth.isDrawerOpen && props.auth.isDrawerOpen == true ? "240px" : "0px"}`, minHeight: "580px" }} secondary>
                <CustomRow container>
                    <Typography
                        variant="h6"
                        component="h6"
                        color="pColor.main"
                        sx={{ fontWeight: 'Medium', textTransform: 'capitalize', fontSize: '22px' }}
                    >
                        All Gigs
                        {filterApplied && (
                            <Chip
                                label="Remove Filters"
                                variant="outlined"
                                sx={{
                                    fontWeight: 'normal',
                                    marginLeft: '10px'
                                }}
                                onDelete={() => {
                                    props.resetSFS('');
                                }}
                            />
                        )}
                    </Typography>

                    <Grid container item xs="auto" display="flex" justifyContent="center" justifyItems="center" alignContent='center'>
                        <Search placeholder="Search by date, job title or job description" />
                        <Sort sortType="engineer" />
                        {
                            (tableData === 'tableView') ?
                                <Box sx={{ cursor: 'pointer', marginTop: '11px' }}>
                                    <img sx={{ color: '#0000FF' }} src={AllGigTable} alt="logotable" fontSize="small"
                                        onClick={(e) => onTableChange(e, 'cardView')}
                                    /></Box>
                                :
                                <GridViewIcon onClick={(e) => onTableChange(e, 'tableView')} sx={{ fontSize: '40px', color: '#818181', cursor: 'pointer', marginTop: '6px' }} />
                        }
                    </Grid>
                </CustomRow>

                {_isEmpty(list) && filterApplied && (
                    <Grid
                        container
                        item
                        justifyContent="center"
                        xs={12}
                        className="h-margin-top-10 h-padding-top-16"
                    >
                        <Typography variant="h6" component="h6">
                            No records found, Please refine your search
                        </Typography>
                    </Grid>
                )}

                {_isEmpty(list) && !filterApplied && (
                    <Grid
                        container
                        item
                        justifyContent="center"
                        xs={12}
                        className="h-margin-top-10 h-padding-top-16"
                    >
                        <PageLoader />
                    </Grid>
                )}
                {(tableData === 'tableView') ?
                    <PaddedBox style={{ paddingLeft: `0px`, marginTop: '-10px', width: "100%" }} secondary>
                        <Grid container item className="section-Margin" spacing={4} xs={12} md={12} lg={12}>
                            {
                                list.map((job, index) => (
                                    <Grid item xs={12} sm={4} md={4} lg={4} key={index}>
                                        <EditedCard sx={{ minHeight: '360px' }}>
                                            <CardHeader
                                                sx={{ position: "absolute", top: "0", left: "0" }}
                                                action={
                                                    <Typography
                                                        variant="subtitle1"
                                                        gutterBottom
                                                        sx={{
                                                            color: '#000000',
                                                            textAlign: 'center',
                                                            fontSize: '11px',
                                                            lineHeight: '18px',
                                                            lineHeight: '100%',
                                                            fontStyle: 'regular',
                                                            fontFamily: 'poppins',
                                                            fontWeight: '600',
                                                            display: 'flex',
                                                            marginTop: '10px'
                                                        }}
                                                    >
                                                        Posted on:
                                                        <Box sx={{ marginLeft: '3px' }}>
                                                            {_get(job, 'createdAt') ? formatDate(_get(job, 'createdAt', '')) : '-'}
                                                        </Box>
                                                    </Typography>
                                                }
                                            />
                                            {userType === 'admin' && (
                                                <div className='status-show-admin-side'>
                                                    <div className={`status-gig-admin ${_get(job, 'status', '') === 'ACTIVE' ? 'status-active' : _get(job, 'status', '') === 'INACTIVE' ? 'status-inactive' : _get(job, 'status', '') === 'CLOSED' ? 'status-closed' : _get(job, 'status', '') === 'DRAFT' ? 'status-draft' : ''}`}>
                                                        {
                                                            _get(job, 'status', '') === 'ACTIVE' ?
                                                                <img sx={{ color: '#0000FF' }} src={StatusRightIcon} alt="StatusRightIcon" fontSize="medium" width="23%" />
                                                                : _get(job, 'status', '') === 'INACTIVE' ? <img sx={{ color: '#0000FF' }} src={InactiveStatus} alt="InactiveStatus" fontSize="medium" width="19%" />
                                                                    : _get(job, 'status', '') === 'CLOSED' ? <img sx={{ color: '#0000FF' }} src={CloseStatus} alt="CloseStatus" fontSize="medium" width="22%" /> :
                                                                        _get(job, 'status', '') === 'DRAFT' ? <img sx={{ color: '#0000FF' }} src={DraftStatus} alt="DraftStatus" fontSize="medium" width="23%" /> : ''
                                                        }
                                                        <div className='sta-green'>
                                                            {_get(job, 'status', '') === 'ACTIVE' ? 'Active' : _get(job, 'status', '') === 'INACTIVE' ? 'Inactive' : _get(job, 'status', '') === 'CLOSED' ? 'Closed' : _get(job, 'status', '') === 'DRAFT' ? 'Draft' : ''}
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                            <Box
                                                sx={{ cursor: 'pointer', marginTop: '20px' }}
                                                onClick={() => {
                                                    history.push(`/admin/owner/allgigs/details/${_get(job, '_id', '')}`, job);
                                                }}
                                            >
                                                <Box sx={{ margin: 'auto' }}>
                                                    <Avatar
                                                        src={_get(job, 'companyDetails.logo', '')}
                                                        sx={{
                                                            width: 90,
                                                            height: 90,
                                                            objectFit: 'cover',
                                                            margin: 'auto',
                                                            // border: '3px solid #eee',
                                                            boxShadow: 'rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset',
                                                            backgroundColor: '#CCD8EE'
                                                        }}
                                                    >
                                                    </Avatar>
                                                    <Typography
                                                        variant="h6"
                                                        component="h6"
                                                        sx={{
                                                            color: '#5E6D55',
                                                            fontWeight: 'Medium',
                                                            textTransform: 'capitalize',
                                                            textAlign: 'center',
                                                            marginTop: '20px'
                                                        }}
                                                    >
                                                        <Typography
                                                            title="Project Title"
                                                            sx={{
                                                                textAlign: 'center',
                                                                fontSize: '16px',
                                                                fontWeight: '600',
                                                                lineHeight: '1.43'
                                                            }}
                                                        >
                                                            {`${_get(job, 'titleAndSkills.projectTitle', '')}`}
                                                        </Typography>
                                                    </Typography>
                                                    {
                                                        !!job.referralsData
                                                        &&
                                                        Array.isArray(job.referralsData)
                                                        &&
                                                        job.referralsData.length>0?
                                                        <div
                                                            onClick={(e)=>{ e.stopPropagation(); history.push(`/gig/review/admin/viewFeedback/${_get(job, '_id', '')}`); }}
                                                            style={{
                                                                display: "flex",
                                                                flexDirection: "row",
                                                                justifyContent: "center",
                                                                cursor: "pointer",
                                                                fontFamily: "Poppins",
                                                                fontWeight: "500",
                                                                fontSize: "16px",
                                                                lineHeight: "26px",
                                                                color: "#0140AA",
                                                            }}
                                                        >
                                                            {'View Feedback'}
                                                        </div>
                                                        :
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                flexDirection: "row",
                                                                justifyContent: "center",
                                                                cursor: "pointer",
                                                                fontFamily: "Poppins",
                                                                fontWeight: "500",
                                                                fontSize: "16px",
                                                                lineHeight: "26px",
                                                                color: "#0140AA",
                                                                opacity: 0,
                                                                userSelect:"none",
                                                            }}
                                                        >
                                                            {'View Feedback'}
                                                        </div>
                                                    }
                                                </Box>
                                            </Box>
                                            <Box>
                                                <CardContent className="card-Content">
                                                    <List sx={{ marginTop: '20px' }}>
                                                        <ListItem disablePadding
                                                            title="Skills" sx={{ cursor: 'default' }}>
                                                            <ListItemIcon sx={{ minWidth: '25px', marginLeft: '1px', marginTop: '6px' }} >
                                                                <span enterTouchDelay={0}>
                                                                    <img sx={{ color: '#0000FF' }} src={Skills} alt="skills" width="19px" />
                                                                </span>
                                                            </ListItemIcon>
                                                            <ListItemText
                                                                primary={
                                                                    <Typography
                                                                        sx={{
                                                                            fontSize: '16px',
                                                                            color: '#383838',
                                                                            overflow: 'hidden'
                                                                        }}
                                                                    >
                                                                        {_get(job, 'titleAndSkills.skillsRequired[0].engineerSkill', 'Not Disclosed')}
                                                                    </Typography>
                                                                }
                                                            />
                                                        </ListItem>
                                                        <ListItem disablePadding title="Location" sx={{ cursor: 'default', marginTop: '5px' }}>
                                                            <ListItemIcon sx={{ minWidth: '25px', marginLeft: '-3px', marginTop: '5px' }}>
                                                                <span
                                                                    enterTouchDelay={0}
                                                                >
                                                                    <PlaceOutlinedIcon sx={{ fontSize: '23px', color: '#000' }} />
                                                                </span>
                                                            </ListItemIcon>
                                                            <ListItemText
                                                                primary={
                                                                    <Typography
                                                                        sx={{
                                                                            fontSize: '16px',
                                                                            color: '#383838',
                                                                            overflow: 'hidden',
                                                                            marginLeft: '3px'
                                                                        }}
                                                                    >
                                                                        {getGigLocation(_get(job, 'deliverablesAndLocation'), _get(job, 'deliverablesAndLocation'))
                                                                        }
                                                                    </Typography>
                                                                }
                                                            />
                                                        </ListItem>
                                                        <ListItem disablePadding
                                                            title="Duration" sx={{ cursor: 'default', marginTop: '5px' }}>
                                                            <ListItemIcon sx={{ minWidth: '25px', marginTop: '0px' }}>
                                                                {(_get(job, 'scopeAndBudget.durationUnit')) === "PERHOUR" ? <img sx={{ color: '#0000FF' }} src={Hours} alt="hours" fontSize="medium" /> : (_get(job, 'scopeAndBudget.durationUnit')) === "PERDAY" ? <img sx={{ color: '#0000FF' }} src={Days} alt="days" fontSize="medium" /> : (_get(job, 'scopeAndBudget.durationUnit')) === "PERWEEK" ? <img sx={{ color: '#0000FF' }} src={Weeks} alt="weeks" fontSize="medium" /> : (_get(job, 'scopeAndBudget.durationUnit')) === "PERMONTH" ? <img sx={{ color: '#0000FF' }} src={Months} alt="months" fontSize="medium" /> : <img sx={{ color: '#0000FF' }} src={Hours} alt="hours" fontSize="medium" />}
                                                            </ListItemIcon>
                                                            <ListItemText
                                                                primary={
                                                                    <Typography
                                                                        sx={{
                                                                            fontSize: '16px',
                                                                            color: '#383838',
                                                                            overflow: 'hidden'
                                                                        }}
                                                                    >
                                                                        {_get(job, 'scopeAndBudget.projectDuration', '0')} {getDurationLabel(_get(job, 'scopeAndBudget.durationUnit', 'Hours'))}
                                                                    </Typography>
                                                                }
                                                            />
                                                        </ListItem>
                                                        <ListItem disablePadding title="Hourly Rate" sx={{ cursor: 'default', marginTop: '5px' }}>
                                                            <ListItemIcon sx={{ minWidth: '25px', marginLeft: '-0px', marginTop: '5px' }}>
                                                                <span
                                                                    enterTouchDelay={0}
                                                                >
                                                                    <AccessTimeIcon sx={{ fontSize: '19px', color: '#000' }} />
                                                                </span>
                                                            </ListItemIcon>
                                                            <ListItemText
                                                                primary={
                                                                    <Typography
                                                                        sx={{
                                                                            fontSize: '16px',
                                                                            color: '#383838',
                                                                            overflow: 'hidden',
                                                                            marginLeft: '3px'
                                                                        }}
                                                                    >
                                                                        {
                                                                            _get(job, 'scopeAndBudget.hourlyRate', '0') ? `${_get(job, 'scopeAndBudget.currency', 'R')} ${_get(job, 'scopeAndBudget.hourlyRate', '0')}/hr` : 'Not Disclosed'
                                                                        }
                                                                    </Typography>
                                                                }
                                                            />
                                                        </ListItem>


                                                    </List>
                                                </CardContent>
                                            </Box>

                                            {userType === 'admin' && (
                                                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                    <Button
                                                        className="admin-all-btn"
                                                        sx={{ color: "#FFFFFF", borderRadius: '10px', width: '190px', height: '45px', textTransform: 'capitalize' }}
                                                        onClick={() => {
                                                            history.push(`/admin/owner/allgigs/details/${_get(job, '_id', '')}`, job);
                                                        }}
                                                    >
                                                        View Details
                                                    </Button>
                                                </Box>
                                            )}
                                        </EditedCard>
                                    </Grid>
                                ))
                            }
                        </Grid>
                        {!_isEmpty(list) && (
                            <Box sx={{ padding: '30px 0 10px 0' }}>
                                <Pagination
                                    page={page}
                                    count={count}
                                    shape="rounded"
                                    className="pagination-root"
                                    onChange={onPaginationClick}
                                    defaultPage={page}
                                />
                            </Box>
                        )}
                    </PaddedBox>
                    :
                    <Grid container className="table-Padding" mt={2}>
                        <GridTableEngineer
                            headers={headers}
                            rows={engineerProfile}
                            handleRClick={handleRClick}
                            handleChange={handleChange}
                            pagination={paginationVal}
                            defaultPage={_toNumber(paginationVal)}
                        ></GridTableEngineer>
                    </Grid>
                }
            </PaddedBox>
            <PaddedFooter style={{ paddingLeft: `${!!props.auth && !!props.auth.isDrawerOpen && props.auth.isDrawerOpen == true ? "200px" : "0px"}` }}>
                <Footer />
            </PaddedFooter>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        searchKey: state.sfs.searchKey,
        filters: state.sfs.filters,
        sortBy: state.sfs.sortBy
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        resetSFS: (details) => dispatch(resetSFS(details))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AllGigsAdmin);
