import styled from 'styled-components';

const CustomBox = styled.div`
  padding: 10px;
  max-width: 328px;
`;

const SignUpMsg = styled.div`
  font-size: 13px;
  text-align: center;
`;

const ForgotPwd = styled.div`
  font-size: 12px;
  text-align: center;
  a {
    color: #f0142f;
  }
`;

const SignInDisclamer = styled.div`
  font-size: 12px;
  font-weight: 400px;
  text-align: center;
  color: #757575;
  line-heigth: 18px;
  padding: 20px 0 10px 0;
`;

const SignUpBusiness = styled.div`
  font-size: 10px;
  font-weight: 400px;
  text-align: center;
  color: #757575;
  line-heigth: 18px;
  padding: 20px 0 10px 0;
`;

const SignUpTextShow = styled.div`
  font-size: 9.38px;
  text-align: center;
  padding: 0px 0 10px 0;
`;

const SignInMsg = styled.div`
  font-size: 13px;
  text-align: center;
`;

const SignInMsgModal = styled.div`
  font-size: 10px;
  text-align: center;
  margin-top: -5px;
`;

const DialogCss = styled.div`
  .color-button {
    padding: 10px 60px;
    background-color: #f99600;
    :hover {
      background-color: #f99600;
    }
  }
`;

export { CustomBox, SignUpMsg, ForgotPwd, SignInDisclamer, DialogCss, SignInMsg, SignInMsgModal, SignUpBusiness, SignUpTextShow };
