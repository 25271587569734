import styled from 'styled-components';
import { Typography, Grid, Box, Card, Button } from '@mui/material';

const GlobalDiv = styled.div`
  .active-gigs-grid,
  .inactive-gigs-grid,
  .closed-gigs-grid {
    th {
      width: 15%;
      &:first-child {
        width: 25%;
      }
      &:last-child {
        width: 30%;
      }
    }
  }
  .MuiPhoneNumber-flagButton {
    max-width: 10px !important;
  }
  .MuiInputLabel-asterisk {
    color: red;
  }
  .css-asterik {
    .css-1g2f56u-MuiFormLabel-root-MuiInputLabel-root {
      &::after {
        bottom: 0;
        color: red;
        content: '*';
        position: absolute;
        transform: translate(-100%, 0);
      }
    }
  }
  position: relative;
  min-height: 100vh;
  span {
    font-size: 14px;
  }
  .h-color-orange {
    color: #f99600;
    font-weight: bold;
  }
  .UploadImage {
    width: 100%;
    display: flex;
    justify-content: space-between;
    border: 1px solid rgb(118, 118, 118, 0.4);
    padding: 15px;
  }
  .h-color-button {
    background-color: #f99600;
    box-shadow: 0px 2px 6px #00000029;
    border-radius: 4px;
    :hover {
      background-color: #f99600;
    }
    &.success {
      background-color: #21d59b;
      :hover {
        background-color: #21d59b;
      }
      :disabled {
        color: #ffffff;
      }
    }
  }

  .h-color-button-outlined {
    color: #f99600;
    box-shadow: 0px 2px 6px #00000029;
    border-radius: 4px;
    text-transform: none;
    :hover {
      border-color: #f99600;
    }
  }
  .h-color-primary {
    background-color: #fff5e6;
  }

  .h-bg-color-engineer,
  .h-bg-color-business {
    // background-color: #fff5e6;
    background-color: #f3f3f3;
  }

  .h-bg-color-admin {
    background-color: #f5f6fa;
  }

  .h-padding-top-16 {
    padding-top: 16px !important;
  }
  .h-padding-top-5 {
    padding-top: 5px;
  }
  .h-margin-bottom-16 {
    margin-bottom: 16px;
  }
  .h-margin-top-10 {
    margin-top: 10px;
  }
  .h-margin-left-10 {
    margin-left: 10px;
  }
  .MuiSelect-select {
    :focus {
      background: transparent;
    }
  }
  .chatToastContent {
    margin: 0px;
  }
  .upload-Loading {
    width: 22px !important;
    height: 22px !important;
  }
  .chat-Send-Loading {
    width: 30px !important;
    height: 28px !important;
    margin: 10px;
    margin-left: 25px;
    margin-top: 15px;
  }
`;

const CustomGridContainer = styled(Grid)`
  && {
    min-height: 80vh;
  }
`;

const CalendarBox = styled(Box)`
  && {
    background-color: white;
    padding: 8px;
    padding-left: 0px;
    // border: 2px solid #f99600;
    border-radius: 5px;
    display: flex;
    align-items: center;
    input {
      border: none;
      margin-left: 10px;
      max-width: 80px;
      cursor: pointer;
    }
    input:focus {
      border: none;
      outline: none;
    }
    svg {
      font-size: 1rem;
    }
  }
`;

const MultiCalendarBox = styled(CalendarBox)`
  && {
    padding: 5px;
    margin-left: 0px;
    margin-top: 10px;
    input {
      max-width: 60px;
    }
    svg {
      font-size: 1rem;
    }
  }
`;

const TypographyBold = styled(Typography)`
  && {
    font-weight: bold;
    position: relative;
  }
`;

const PaddedFooter = styled.div`
  width: 98.9vw;
  margin: auto;
  margin-top: 72px;
  padding-left: 240px;
`;

const PaddedBox = styled.div`
  width: 95vw;
  margin: auto;
  margin-top: 72px;
  padding-left: 240px;
  && {
    // padding: 80px 20px 20px 20px;
    .uploadIcon {
      color: #f99600;
    }
    .displayNone {
      display: none;
    }
    .inlineBlock {
      display: inline-block;
    }
    .expertiseMargin {
      margin-top: 0px;
      margin-bottom: 20px;
    }
    .floatRightBlock {
      float: right;
    }
    .primary-Main-Btn {background-image: linear-gradient(to right, #0140AA 0%, #1A53B2  51%, #0140AA  100%)}
    .primaryMainBtnDisable{background-image: linear-gradient(to right, #4D79C3 0%, #99B2DD  51%, #4D79C3 100%); pointer-events: none;}
  
    .primary-Btn {background-image: linear-gradient(to right, #FF8008 0%, #FFC837  51%, #FF8008  100%)}
    .primary-Btn, .primary-Main-Btn, .primaryMainBtnDisable {
       margin: 10px 10px;
       padding: 8px auto;
       text-align: center;
       text-transform: capitalize;
       transition: 0.5s;
       background-size: 200% auto;
       color: white;            
       box-shadow: 0 0 20px #eee;
       border-radius: 5px;
      //  display: block;
       @media only screen and (max-width:320px) {
         width: 100%;
         text-align: center;
        //  margin-top: 15px;
      }
      @media only screen and (max-width: 425px) {
        margin: 0;
        // width: 100%;
        text-align: center;
        // margin-top: 15px;
      }
    }

    .primary-Btn-post-gig {background-image: linear-gradient(to right, #FF8008 0%, #FFC837  51%, #FF8008  100%)}
    .primary-Btn-post-gig {
       margin: 10px 0px;
       padding: 8px auto;
       text-align: center;
       text-transform: uppercase;
       transition: 0.5s;
       background-size: 200% auto;
       color: white;            
       box-shadow: 0 0 20px #eee;
       border-radius: 5px;
       width: 120px;
       @media screen  and (min-width: 320px) and (max-width: 380px) {
         width: 100%;
         text-align: center;
      }
      @media screen  and (min-width: 380px) and (max-width: 450px) {
        margin: 0;
        text-align: center;
        width: 95px;
      }
      @media screen  and (min-width: 450px) and (max-width: 800px) {
        margin: 0;
        text-align: center;
        width: 110px;
      }
    }

    .primary-Btn-step2 {background-image: linear-gradient(to right, #FF8008 0%, #FFC837  51%, #FF8008  100%)}
    .primary-Btn-step2 {
       margin: 10px 0px;
       padding: 8px auto;
       text-align: center;
       text-transform: uppercase;
       transition: 0.5s;
       background-size: 200% auto;
       color: white;            
       box-shadow: 0 0 20px #eee;
       border-radius: 5px;
       width: 120px;
       margin-left: 10px;
       font-size: 12px;
       @media only screen and (max-width:320px) {
         width: 100%;
         text-align: center;
      }
      @media only screen and (max-width: 500px) {
        margin: 0;
        text-align: center;
        width: 80px
      }
    }

    .MuiBox-root > .MuiTypography-root {
      padding: 10px;
    }

    .MuiContainer-root {
      @media only screen and (max-width: 425px) {
        padding-bottom: 20px;
      }
    }

     .primary-Btn:hover, .primary-Main-Btn:hover, .primaryMainBtnDisable:hover {
       background-position: right center; /* change the direction of the change here */
       color: #fff;
       text-decoration: none;
     }
    .primary-Btn-o {border-color: #FF8008;}
    .primary-Btn-o {
       margin: 10px 0;
       text-align: center;
       text-transform: uppercase;
       transition: 0.5s;
       background-size: 200% auto;
       color: #FF8008;            
       box-shadow: 0 0 20px #eee;
       border-radius: 5px;
       display: block;
       @media only screen and (max-width:320px) {
        width: 100%;
        text-align: center;
      }
      @media only screen and (max-width: 425px) {
        width: 100%;
        text-align: center;
      }
    }

     .primary-Btn-o:hover {
       background-position: right center; /* change the direction of the change here */
       color: #FF8008;
       text-decoration: none;
       border-color: #FF8008;
     }
    .active-back-btn {
      margin: 10px 0;
      padding: 6px 20px;
      text-align: center;
      text-transform: uppercase;
      transition: 0.5s;
      background-size: 200% auto;
      box-shadow: 0 0 20px #eee;
      border-radius: 5px;
      display: block;
      font-size: 12px;
      @media only screen and (max-width: 425px) {
        width: 100%;
        text-align: center;
      }
    }
    .step3-back-btn {
      margin: 10px 0;
      padding: 6px 20px;
      text-align: center;
      text-transform: uppercase;
      transition: 0.5s;
      background-size: 200% auto;
      box-shadow: 0 0 20px #eee;
      border-radius: 5px;
      display: block;
      font-size: 12px;
      width: 120px;
      @media only screen and (max-width: 425px) {
        width: 100%;
        text-align: center;
      }
    }
    .continueButton1 {
      width: 130px;
      background: rgb(9, 9, 121);
      background: linear-gradient(90deg, rgba(9, 9, 121, 1) 35%, rgba(71, 122, 208, 1) 100%);
      // background-color: #f99600;
      box-shadow: 0px 2px 6px #00000029;
      border-radius: 4px;
      &:hover {
        background: rgb(71, 122, 208);
        background: linear-gradient(90deg, rgba(71, 122, 208, 1) 35%, rgba(9, 9, 121, 1) 100%);
        background-position: right center; /* change the direction of the change here */
      }
    }
    .successActive {
      background-color: #21d59b;
    }

    .floatRight {
      justify-content: flex-end;
    }

    .smallButton {
      line-height: 10px;
      border-color: #f99600;
    }

    .tableStyles {
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 1px 4px #15223214;
      border-radius: 6px;
      padding: 20px;
      overflow-x: visible;
    }
    .tableStyles .heading {
      font-weight: bold;
    }
    .pagination-root .Mui-selected {
      background-color: #f99600;
      color: #ffffff;
    }
    .tooltip:hover {
      cursor: pointer;
    }
    .tableStyles .MuiPaper-root {
      transition: none;
      border-radius: 0px;
      box-shadow: none;
    }
    .tableStyles .MuiTableRow-head {
      background: #f5f6fa 0% 0% no-repeat padding-box;
    }
    .tableStyles .MuiTableCell-root {
      border-bottom: none;
      text-align: center;
      padding: 9px;
    }
    .tableStyles .emptyBox {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 12px;
    }
    .tableStyles .viewAll {
      display: flex;
      font-size: 12px;
      align-items: center;
      // padding-top: 20px;
      color: #0140aa;
      font-weight: 500;
      cursor: pointer;
      line-height: 1.6;
      text-decoration: underline;
      text-decoration-color: rgba(25, 118, 210, 0.4);
      &:hover {
        text-decoration-color: #0140aa;
      }
    }
    .message-Link {
      text-decoration: none;
      color: #f99600;
      font-size: 14px;
      cursor: pointer;
    }
    .bottom-Margin {
      margin-bottom: 36px;
    }

    .section-Margin {
      margin-top: 0px;
    }
    .groupMargin {
      margin-top: 60px;
    }
    .section-Button {
      // width: 50%;
      border-color: #0140aa;
      color: #0140aa;
      padding: 5px 20px;
      text-transform: none;
    }
    .section-Button-Step3 {
      // width: 50%;
      border-color: #0140aa;
      color: #0140aa;
      padding: 5px 20px;
      margin-left: 19px;
      text-transform: none;
    }
    .sectionButton1 {
      border-color: #f99600;
      color: #f99600;
      text-transform: none;
    }
    .primaryButton {
      width: 100%;
      border-color: #f99600;
      color: #f99600;
      padding: 10px;
      text-transform: none;
    }
    .paddingTopClear {
      padding-top: 0px;
    }
    .cardLoading {
      display: flex;
      justify-content: space-around;
    }
    .dropDown-select {
      height: 30px;
      width: 120px;
    }
    .dropDown-list {
      font-size: 12px;
    }
    .edit-btn-center {
      justify-content: center;
      display: flex;
    }
    .paginationCard {
      width: 100%;
      margin-top: 50px;
      padding: 20px;
    }
    .emptyGigs {
      display: flex;
      justify-content: center;
      align-self: center;
      width: -webkit-fill-available;
    }
    .chat-Box {
      // min-width: 400px;
      // border: 1px solid #0a5c52;
      // left:10%;
      display: grid;
      grid-template-columns: 250px 1fr;
      gap: 5px;
      // max-height: 80vh;
      // max-width: 90%;
      // margin: auto;
      min-height: 85vh;
      padding: 20px 0;
    }
    .chat-SideBar {
      overflow: auto;
      // width: 25%;
      // min-width: 280px;
      border-radius: 10px;
    }
    .chat-List-Item {
      border-bottom: 1px solid #E0E0E0;
      // background-color: #F8F8F8;
      color: #0140aa;
      padding: 10px 15px;
      &:last-child {
        border-bottom: none;
      }
    }
    .chat-Item-Name {
      span{
        font-size: 15px;
        font-weight: 500;
        text-transform: capitalize;
      }
    }
    .chat-Item-Img {

    }
    .chat-Main-Body {
      background: #E5EBF6;
      width: 100%;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      border-radius: 10px;
    }
    .chat-Form {
      display: flex;
      width: 95%;
      margin: 0 auto;
      margin-bottom: 10px;
      display: flex;
      flex-direction: row;
      align-items: flex-end;
    }
    .chat-Text {
      border: none;
      width: 94%;
      :disabled {
        color: #ffffff;
      }
      @media screen  and (min-width: 320px) and (max-width: 380px) {
        border: none;
         width: 81%;
         padding-right: 2px;
      }
      @media screen  and (min-width: 380px) and (max-width: 500px) {
        border: none;
         width: 84%;
         padding-right: 2px;
      }
      @media screen  and (min-width: 600px) and (max-width: 800px) {
        border: none;
         width: 86%;
         padding-right: 2px;
      }
      @media screen  and (min-width: 800px) and (max-width: 1100px) {
        border: none;
         width: 87%;
         padding-right: 2px;
      }
      @media screen  and (min-width: 1300px) and (max-width: 1450px) {
        border: none;
         width: 93%;
         padding-right: 2px;
      }
    }
    .chat-Text-Container {
      width: 100%;
      position: relative;
      background-color: #fff;
    }
    .chat-Attachment-Container {
      position: absolute;
      right: 10px;
      bottom: 10px;
      .UploadImage {
        padding: 0;
        background-color: #b2c5e5;
        min-width: 35px;
        height: 35px;
        border-radius: 50%;
        border-color: transparent;
        .chatAttachIcon {
          color: #000;
          font-size: 16px;
        }
      }
    }
    .chat-Attachment-Dragdrop{
      position: absolute;
      right: 6px;
      margin-top: -50px;
      cursor: pointer;
    }
    .chatAttachIcon {
      float: right;
      color: gray;
    }
    .chat-Link {
      text-decoration: underline;
    }
    .chat-Remove-Attachment {
      cursor: pointer;
    }
    .chat-Send-Button {
      margin-left: 10px;
      height: 56px;
      width: 56px;
      min-width: 56px;
      border-radius: 50%;
      background-color: #0140aa;
      padding: 0;
    }
    .chat-Send-Enable {
      background-color: #f99600;
    }
    .chat-Body {
      width: calc( 100% - 20px );
      padding: 10px;
      overflow-y: auto;
      height: calc(100% - 80px);
      box-shadow: none;
      background-color: transparent;
    }
    .chat-Selected {
      background: #F8F8F8;
    }
    .chat-Notification {
      width: 10px;
      height: 10px;
      background: green;
      border-radius: 5px;
      margin-left: 5px;
    }
    .messageRow {
      display: flex;
    }
    .messageRowRight {
      display: flex;
      justify-content: flex-end;
    }
    .messageBlue {
      position: relative;
      margin-left: 20px;
      margin-bottom: 10px;
      padding: 10px;
      background-color: #dcdcdc;
      width: 75%;
      text-align: left;
      border: 1px solid #dcdcdc;
      border-radius: 10px;
      padding-bottom: 15px;
      color: #000;
      font-size: 14px;
      a {
        color: #000;
      }
      &:after {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        border-top: 15px solid #dcdcdc;
        border-left: 15px solid transparent;
        border-right: 15px solid transparent;
        top: 0;
        left: -15px;
      }
      &:before {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        border-top: 17px solid #dcdcdc;
        border-left: 16px solid transparent;
        border-right: 16px solid transparent;
        top: -1px;
        left: -17px;
      }
    }
    .messageOrange {
      position: relative;
      margin-right: 20px;
      margin-bottom: 10px;
      margin-top: 8px;
      padding: 10px;
      background-color: #0140aa;
      width: 75%;
      text-align: left;
      border: 1px solid #0140aa;
      border-radius: 10px;
      padding-bottom: 18px;
      color: #fff;
      font-size: 14px;
      a {
        color: #fff;
      }
      &:after {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        border-top: 15px solid #0140aa;
        border-left: 15px solid transparent;
        border-right: 15px solid transparent;
        top: 0;
        right: -15px;
      }
      &:before {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        border-top: 17px solid #0140aa;
        border-left: 16px solid transparent;
        border-right: 16px solid transparent;
        top: -1px;
        right: -17px;
      }
    }

    .messageContent {
      padding: 0;
      margin: 0;
      word-break: break-word;
    }

    .messageTimeStampRight {
      position: absolute;
      font-size: 10px;
      font-weight: 400;
      margin-top: 10px;
      bottom: 2px;
      right: 5px;
      color: #dcdcdc;
    }
  }
`;

const Notifier = styled.span`
  && {
    width: 10px;
    height: 10px;
    background: blue;
    border-radius: 5px;
    margin-left: 5px;
  }
`;

const CustomRow = styled(Grid)`
  && {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

const EditedCard = styled(Card)`
  && {
    padding: 15px;
    // min-height: 360px;
    position: relative;
    width: auto;
    margin-left: 0px;
    border-radius: 15px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    .card-Content {
      padding-top: 2px;
      padding-bottom: 10px;
    }

    p {
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    .card-Padding {
      padding-top: 10px;
    }
    .card-Styles {
      // white-space: nowrap;
      // overflow: hidden;
      // text-overflow: ellipsis;
    }
    .card-Messages {
      display: flex;
      justify-content: flex-end;
    }
    .card-Link {
      text-decoration: none;
      color: #f99600;
      font-size: 14px;
      cursor: pointer;
    }
    .card-Link-delete {
      text-decoration: none;
      color: #0000ff;
      font-size: 14px;
      cursor: pointer;
    }
    .cardMore {
      color: #0140aa;
      text-decoration: none;
      font-size: 14px;
      cursor: pointer;
      display: flex;
      align-items: center;
    }
    .testDiv {
      height: 40px;
      border: '1px solid green';
      margin: 6px;
      padding: 8px;
    }
    .project-Description {
      max-height: 56px;
      min-height: 56px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3; /* number of lines to show */
      line-clamp: 3;
      -webkit-box-orient: vertical;
    }
    .card-Action {
      // position: absolute;
      width: 100%;
      bottom: 0;
      left: 0;
      // padding: 0 24px 18px 24px;
      @media only screen and (min-width: 500px) and (max-width: 1000px) {
        position: absolute;
        width: 100%;
        display: inline-table;
        bottom: 0;
        left: 0;
        padding: 0 24px 18px 24px;
      }
    }
  }
`;

const NolongerAppliedContainer = styled.div`
  font-size: 14px;
  color: red;
  border: 2px solid #e9e4f0;
  border-radius: 10px;
  padding: 10px;
  margin-top: 0px;
  width: 325px;
  @media only screen and (max-width: 925px) {
    width: 200px;
    font-size: 10px;
  }
  @media only screen and (max-width: 825px) {
    width: 320px;
    font-size: 12px;
  }
`;

const NolongerApctContainer = styled.div`
font-size: 14px;
color: red;
border: 2px solid #E9E4F0;
border-radius: 10px;
padding: 10px;
margin-top: 0px;
width: 325px;
@media only screen and (max-width:925px  ) {
  width: 200px;
  font-size: 10px;
}
@media only screen and (max-width:825px  ) {
  width: 320px;
  font-size: 12px;
}
`;

const NolongerComplete = styled.div`
  font-size: 14px;
  color: red;
  border: 2px solid #e9e4f0;
  border-radius: 10px;
  padding: 10px;
  margin-top: 15px;
  width: 325px;
  @media only screen and (max-width: 925px) {
    width: 200px;
    font-size: 10px;
  }
  @media only screen and (max-width: 825px) {
    width: 320px;
    font-size: 12px;
  }
`;

const NoLongerApctImg = styled.div`
  width: 25px;
  height: 25px;
  margin-top: 9px;
  @media screen and (max-width: 1880px) {
    width: 22px;
    height: 22px;
    margin-right: 7px;
    margin-top: 9px;
  }
  @media screen and (max-width: 1380px) {
    width: 20px;
    height: 20px;
    margin-right: 7px;
    margin-top: 7px;
  }
  @media screen and (max-width: 880px) {
    width: 18px;
    height: 18px;
    margin-right: 7px;
    margin-top: 9px;
  }
  @media screen and (max-width: 880px) {
    width: 20px;
    height: 20px;
    margin-right: 7px;
    margin-top: 9px;
    margin-left: 3px;
  }
  @media screen and (max-width: 680px) {
    width: 20px;
    height: 21px;
    margin-right: 7px;
    margin-top: 9px;
  }
  @media screen and (max-width: 580px) {
    width: 18px;
    height: 18px;
    margin-right: 7px;
    margin-top: 5px;
    margin-left: 3px;
  }
  @media screen and (max-width: 500px) {
    width: 15px;
    height: 15px;
    margin-right: 7px;
    margin-top: 5px;
    margin-left: 3px;
  }
  @media screen and (max-width: 427px) {
    width: 18px;
    height: 13px;
    margin-right: 7px;
    margin-top: 5px;
  }
  @media screen and (max-width: 377px) {
    width: 19px;
    height: 10px;
    margin-right: 7px;
    margin-top: 7px;
    margin-left: 3px;
  }
  @media screen and (max-width: 321px) {
    width: 19px;
    height: 8px;
    margin-right: 7px;
    margin-top: 5px;
  }
`;

const BackBtn = styled.button`
  width: 100px;
  cursor: pointer;
  height: 45px;
  background-color: #3f52b6;
  border-radius: 5px;
  color: white;
  border: white;
  font-weight: 500;
  font-family: Poppins;
  text-transform: uppercase;
  @media screen and (max-width: 500px) {
    width: 60px;
    height: 34px;
    margin-left: 10px;
  }
`;

const GiglinkInput = styled.input`
  width: 100%;
  height: 38px;
  background-color: #d9d9d9;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  margin-left: -5px;
  border: 1px solid white;
  padding-left: 5px;
  @media screen and (max-width: 400px) {
    height: 20px;
  }
`;

const TimerImg = styled.image`
  width: 346px;
  height: 346px;
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.23);
  @media screen and (max-width: 376px) {
    height: 246px;
    width: 246px;
  }
`;

const DraftStep1Btn = styled.button`
  width: 120px;
  height: 45px;
  font-size: 12px;
  border: 0.1px solid #FF8008;
  color: #FF8008;
  align-items: center;
  cursor: pointer;
  border-radius: 5px;
  background-color: white;
  font-weight: 500;
  font-family: Poppins;
  text-transform: uppercase;
  @media screen and (max-width: 400px) {
    margin-right: 12px;
  }
`;

const DraftBtnStep2 = styled.button`
  width: 109%;
  height: 45px;
  font-size: 12px;
  border: 0.1px solid #FF8008;
  color: #FF8008;
  align-items: center;
  cursor: pointer;
  border-radius: 5px;
  background-color: white;
  font-weight: 500;
  font-family: Poppins;
  text-transform: uppercase;
  margin: 5px
  @media screen and (max-width: 400px) {
    margin-right: 15px;
  }
`;

const DraftBtnStep3 = styled.button`
  width: 22%;
  height: 45px;
  font-size: 12px;
  border: 0.1px solid #FF8008;
  color: #FF8008;
  align-items: center;
  cursor: pointer;
  border-radius: 5px;
  background-color: white;
  font-weight: 500;
  font-family: Poppins;
  text-transform: uppercase;
  margin: 5px;
  @media screen  and (min-width: 300px) and (max-width: 380px) {
    width: 33%;
  }
  @media screen  and (min-width: 400px) and (max-width: 500px) {
    width: 33%;
  }
  @media screen  and (min-width: 500px) and (max-width: 800px) {
    width: 26%;
  }
  @media screen  and (min-width: 800px) and (max-width: 1100px) {
    width: 18%;
  }
`;

const GigBtnStep3 = styled.button`
  width: 81%;
  height: 45px;
  font-size: 12px;
  border: 0.1px solid #FF8008;
  color: #FF8008;
  align-items: center;
  cursor: pointer;
  border-radius: 5px;
  background-color: white;
  font-weight: 500;
  font-family: Poppins;
  text-transform: uppercase;
  margin: 5px
`;

const ButtonContainerStep2 = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 30px;
  gap: 10px;
  @media screen  and (min-width: 320px) and (max-width: 380px) {
    justify-content: space-evenly;
    display: flex;
    width: 103%;
    height: 100%;
  }
  @media screen  and (min-width: 380px) and (max-width: 500px) {
    justify-content: space-evenly;
    display: flex;
    width: 100%;
    height: 100%;
  }
  @media screen  and (min-width: 500px) and (max-width: 1045px) {
    display: flex;
    width: 100%;
    height: 100%;
  }
  @media screen  and (min-width: 1430px) and (max-width: 2000px) {
    display: flex;
    width: 100%;
    height: 100%;
  }
`;

const ButtonContainerStep3 = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 30px;
  gap: 10px;
  @media screen  and (min-width: 320px) and (max-width: 380px) {
    justify-content: space-evenly;
    display: flex;
    width: 95%;
    height: 100%;
    padding-left: 12px;
  }
  @media screen  and (min-width: 380px) and (max-width: 500px) {
    justify-content: space-evenly;
    display: flex;
    width: 96%;
    height: 100%;
    padding-left: 9px;
  }
  @media screen  and (min-width: 500px) and (max-width: 1045px) {
    display: flex;
    width: 95%;
    height: 100%;
  }
  @media screen  and (min-width: 1430px) and (max-width: 2000px) {
    display: flex;
    width: 96%;
    height: 100%;
  }
`;

export {
  GlobalDiv,
  CustomGridContainer,
  CalendarBox,
  MultiCalendarBox,
  TypographyBold,
  PaddedFooter,
  PaddedBox,
  CustomRow,
  EditedCard,
  Notifier,
  NolongerApctContainer,
  NolongerComplete,
  NolongerAppliedContainer,
  NoLongerApctImg,
  BackBtn,
  GiglinkInput,
  TimerImg,
  DraftStep1Btn,
  ButtonContainerStep2,
  DraftBtnStep2,
  ButtonContainerStep3,
  DraftBtnStep3,
  GigBtnStep3
};
