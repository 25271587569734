import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import {
  Box,
  Paper
} from '@mui/material';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _find from 'lodash/find';
import _indexOf from 'lodash/indexOf';
import { TextInput } from '../../components/Messenger/TextInput';
import { MessageLeft, MessageRight } from '../../components/Messenger/Message';
import { setChatMessage } from '../../store/actions/chat';
import _map from 'lodash/map';
import { getTokenDetails } from '../../utils/user';
import { format } from 'date-fns';
import './chat.css';

const ChatMessages = (props) => {
  const tokenDetails = getTokenDetails();
  const token = tokenDetails.userId;

  useEffect(() => {
    socket.emit('get-user-chat', props.chatId);
    socket.emit('get-all-users', token);
  },
    [props.chatId]);

  useEffect(() => {
    // console.log('messagess - changed');
    if (props.messages.length) {
      let lastMsg = props.messages[props.messages.length - 1];
      socket.emit('checkPointUpdate', { chatid: lastMsg.cId, messageId: lastMsg._id });
      socket.emit('get-all-users', token);
    }
  },
    [props.messages]);

  const sendMessage = (msg, type) => {
    // console.log(msg);
    const msgObj = {
      data: msg,
      messageType: type
    };
    props.sendMsg && props.sendMsg(msgObj);
  };

  const AlwaysScrollToBottom = () => {
    const elementRef = useRef();
    useEffect(() => elementRef.current.scrollIntoView());
    return <div ref={elementRef} />;
  };

  const extractDate = (msg) => {
    return format(msg.createdAt ? new Date(msg.createdAt) : new Date(), 'dd/MM/yy HH:mm');
  }

  //socket.emit('get-user-chat', key);

  return (
    <Box className="chat-Main-Body">
      <Paper id="style-1" className="chat-Body">
        {props.messages.map((msg, index) => {
          {
            return (token === msg.senderId) ? (
              <div className='chat-body-right'>
                <MessageRight message={msg} timestamp={extractDate(msg)} key={`${extractDate(msg)}-${index}`} messageType={msg.messageType} />
              </div>
            ) : (
              <MessageLeft message={msg} timestamp={extractDate(msg)} key={`${extractDate(msg)}-${index}`} messageType={msg.messageType} />
            )
          }
        })}
        <AlwaysScrollToBottom />
      </Paper>
      <TextInput sendMessage={sendMessage} />
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    messages: state.chat.messages || []
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setChatMessage: (details) => dispatch(setChatMessage(details))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChatMessages);
