import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Avatar,
  List,
  ListItem,
  ListItemText,
  Box
} from '@mui/material';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _find from 'lodash/find';
import _indexOf from 'lodash/indexOf';
import { PaddedBox } from '../app.styles';
import ChatMessages from './ChatMessages';
import { getQueryParams } from '../../utils/helpers';
import { setChatMessage, setChatNewUser, setSelectedUser } from '../../store/actions/chat';
import { getTokenDetails } from '../../utils/user';
import _map from 'lodash/map';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { getUserType } from '../../utils/user';
import chatBusiness from '../../assests/chat-business-side.png';
import chatEngineer from '../../assests/chat-engineer-side.png'
import './chat.css';
import VerifiedIcon from '../../assests/VerifiedTick.svg';

const Chat = (props) => {
  const query = getQueryParams();
  const [sendMsgto, setSendMsgTo] = useState(query.to);
  const tokenDetails = getTokenDetails();
  const token = tokenDetails.userId;
  let actionkey = '';
  if (sendMsgto) {
    actionkey = (token < sendMsgto) ? `${token}-${sendMsgto}` : `${sendMsgto}-${token}`;
  }
  const [key, setKey] = useState(actionkey);
  const [userType, setUserType] = React.useState(getUserType());

  const sendMessageTo = (msg) => {
    msg.senderId = token;
    msg.receiverId = sendMsgto;
    let receiverProfilePic = null;
    let receiveruserRole = null;
    props.users.map((e) => {
      if (e.otherUserId == sendMsgto) { 
        receiverProfilePic = e.profilePic; 
        receiveruserRole = e.userRole; 
      }
    });
    msg.name = props.profile.basicInfo.name || 'Anonymous';
    // msg.createdAt = format(new Date(), 'dd/MM/yy HH:mm');
    socket.emit('privatemessage', msg);
    if (receiveruserRole == "engineer") {
      socket.emit('postNotification', {
        "notificationDescription": `New message from ${props.profile.basicInfo.name || 'Anonymous'}`,
        "userRole": ["engineer"],
        "engineerId": msg.receiverId,
        "isRead": false,
        "engineerProfilePic": receiverProfilePic || "",
        "redirectUrl": "/echat"
      });
    } 
    if (receiveruserRole == "business") {
      socket.emit('postNotification', {
        "notificationDescription": `New message from ${props.profile.basicInfo.name || 'Anonymous'}`,
        "userRole": ["business"],
        "businessId": msg.receiverId,
        "isRead": false,
        "businessProfilePic": receiverProfilePic || "",
        "redirectUrl": "/ochat"
      });
    }
    socket.emit('get-all-users', token);
    props.setChatMessage(msg);
  };

  const onNameSelect = (e) => {
    const selectedUser = e.currentTarget.dataset.value;
    setSendMsgTo(selectedUser);
    const conKey = (token < selectedUser) ? `${token}-${selectedUser}` : `${selectedUser}-${token}`;
    setKey(conKey);
    props.setSelectedUser(conKey);
    // socket.emit('get-user-chat', conKey);
  };

  const getListIdentifier = (val) => {
    return val.replace(`${token}-`, '').replace(`-${token}`, '')
  };

  useEffect(() => {
    if (sendMsgto) {
      const chatUser = {
        chatId: key,
        name: sendMsgto,
        otherUserId: sendMsgto
      }
      socket.emit('get-user-name', sendMsgto);
      props.setChatNewUser(chatUser);
    }
    socket.emit('get-all-users', token);
    return () => {
      props.setSelectedUser('');
      props.setChatNewUser({});
    };
  }, []);

  useEffect(() => {
    if (key === '' && props.users.length > 0) {
      const selUser = props.users[0];
      setKey(selUser.chatId);
      setSendMsgTo(getListIdentifier(selUser.chatId));
      props.setSelectedUser(selUser.chatId);
    }
  }, [props.users]);

  return (
    <>
      <PaddedBox style={{ paddingLeft: `${!!props.auth && !!props.auth.isDrawerOpen && props.auth.isDrawerOpen == true ? "240px" : "0px"}` }}>
        {(props.users.length <= 0) ?
          (
            <Box className="chat-Box-chat">


              <div className="chat__msger">
                {
                  (userType === 'business') && (<img loading="lazy" src={chatBusiness} alt="No messages yet" title="No Message, Yet!" />)
                }
                {
                  (userType === 'engineer') && (<img loading="lazy" src={chatEngineer} alt="No messages yet" title="No Message, Yet!" />)
                }

              </div>
            </Box>
          ) :
          (<Box className='chat-Box'>
            <Card className="chat-SideBar">
              <CardContent sx={{ padding: '0' }}>
                <List sx={{ padding: '0' }}>

                  {_map(props.users, (value, indexKey) => (
                    <ListItem className={`chat-List-Item ${(key === value.chatId) ? "chat-Selected" : ""}`} button key={Math.random()} data-value={getListIdentifier(value.chatId)} onClick={onNameSelect}>
                      <Avatar
                        alt={'image'}
                        className='chat-Item-Img'
                        src={value.profilePic ? value.profilePic : ""}
                      ></Avatar>
                      <div className='chat-name-left'>
                        <ListItemText className='chat-Item-Name' primary={value.name} sx={{ marginLeft: '10px' }} />
                        <div className='icon-tick-chat'>
                          {
                            !!value?.isVerified && (value?.profileCompletionPercent == 100)
                            &&
                            <img className='verified-name-ico' src="https://gigengineer-production.s3.af-south-1.amazonaws.com/Assets/Blue+Tick+Verification.svg" />
                          }
                        </div>
                      </div>
                      {(_indexOf(props.chatNotifications, value.chatId) !== -1) && <span className='chat-Notification'></span>}
                    </ListItem>
                  ))}
                </List>
              </CardContent>
            </Card>
            {key && <ChatMessages chatId={key} sendMsg={sendMessageTo}></ChatMessages>}
          </Box>)}
      </PaddedBox>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    profile: state.profile,
    auth: state.auth,
    users: state.chat.users,
    messages: state.chat.messages,
    chatNotifications: state.chat.chatNotifications
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setChatMessage: (details) => dispatch(setChatMessage(details)),
    setChatNewUser: (details) => dispatch(setChatNewUser(details)),
    setSelectedUser: (details) => dispatch(setSelectedUser(details))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Chat);