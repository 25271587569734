import _get from 'lodash/get';
import {
  SET_SFS_SEARCH_KEY,
  RESET_SFS,
  SET_SFS_FILTERS_KEY,
  SET_SFS_SORT_KEY
} from '../actions/sfs';

const Initial_value = {
  searchKey: '',
  filters: {},
  sortBy: {}
};

const sfs = (state = Initial_value, action) => {
  switch (action.type) {
    case SET_SFS_SEARCH_KEY:
      return {
        ...state,
        searchKey: action.payload || ''
      };

    case SET_SFS_FILTERS_KEY:
      return {
        ...state,
        filters: action.payload || {}
      };

    case SET_SFS_SORT_KEY:
      return {
        ...state,
        sortBy: action.payload
      };

    case RESET_SFS:
      return {
        ...state,
        ...Initial_value
      };

    default:
      return state;
  }
};

export default sfs;
