import _get from 'lodash/get';
import apiService from './apiServices';

const resetPasswordReq = async (data, setAuthSignError) => {
  const request = {
    data,
    method: 'post',
    url: '/auth/password/reset'
  };
  const response = await apiService(request);
  return response;
};

export default resetPasswordReq;
