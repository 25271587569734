import { combineReducers } from 'redux';
import authReducer from './auth';
import profileReducer from './profile';
import newGigReducer from './newGig';
import gigsReducer from './gigs';
import savedReducer from './saved';
import draftGigReducer from './DraftGigs';
import expertiseSkillsRed from './expertisewithskills';
import sfsReducer from './sfs';
import chatReducer from './chat';

export default combineReducers({
  auth: authReducer,
  profile: profileReducer,
  newGig: newGigReducer,
  gigs: gigsReducer,
  saved: savedReducer,
  draftGigs: draftGigReducer,
  expertiseSkills: expertiseSkillsRed,
  sfs: sfsReducer,
  chat: chatReducer
});
