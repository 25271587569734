import React from 'react';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import { Button, Typography } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import { connect } from 'react-redux';
import LogoGoogleEngineer from '../../../assests/google__G__Logo.png';
import './gigDetail.css';
import { LoginSocialGoogle } from 'reactjs-social-login';
import { useLinkedIn } from 'react-linkedin-login-oauth2';
import FindGigPopup from '../../../components/HomePage/FindGigPopup';
import FindEngineerPopup from '../../../components/HomePage/FindEngineerPopup';
import { LinkedInLoginUser } from '../../../services/signIn';

const SignINUPmodal = (props) => {
  const [open, setOpen] = React.useState(false);
  const [openSignUp, setOpenSignUp] = React.useState(false);
  const [openForgetEngineer, setOpenForgetEngineer] = React.useState(false);
  const [openSignUpModal, setOpenSignUpModal] = React.useState(false);
  const [openBusinessModal, setOpenBusinessModal] = React.useState(false);
  const [openForgetBusiness, setopenForgetBusiness] = React.useState(false);

  const modalEngineerSignUpOpen = () => {
    setOpenSignUp(true);
  };

  const businessModal = () => {
    setOpenBusinessModal(true);
  };

  const { linkedInLogin } = useLinkedIn({
    clientId: process.env.REACT_APP_LINKEDIN_CLIENT_ID,
    scope: 'r_liteprofile r_emailaddress',
    redirectUri: `${window.location.origin}/linkedin`, // for Next.js, you can use `${typeof window === 'object' && window.location.origin}/linkedin`
    onSuccess: (code) => {
      LinkedInLoginUser({
        code: code,
        redirect_uri: `${window.location.origin}/linkedin`
      });
    },
    onError: (error) => {
      console.error(error);
    }
  });

  return (
    <React.Fragment>
      <div className="Sign-up-main-container">
        <div className="item-signup-cont">
          <Card
            sx={{
              boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
              borderRadius: '10px',
              marginTop: '-16px',
              backgroundColor: '#E6F6FF'
            }}
          >
            <CardContent sx={{ padding: '12px' }}>
              <Box
                sx={{
                  fontSize: '16.6px',
                  lineHeight: '22px',
                  fontWeight: '400',
                  textAlign: 'center',
                  color: '#000000',
                  marginTop: '10px'
                }}
              >
                Log in to find more profiles like this
              </Box>
              <Box>
                <LoginSocialGoogle
                  client_id={process.env.REACT_APP_GOOGLE_CLIENT_ID || ''}
                  onLoginStart={() => {
                    console.log('STARTING NOW!!!!!');
                  }}
                  redirect_uri={'/'}
                  scope="openid profile email"
                  discoveryDocs="claims_supported"
                  access_type="offline"
                  onResolve={(resp) => {
                    handleGoogleLogin(resp, 'engineer');
                  }}
                  onReject={(err) => {
                    console.error(err);
                  }}
                >
                  <Button className="google-text-btn" type="button">
                    <div className="not-user-logo">
                      <img
                        src={LogoGoogleEngineer}
                        alt="logogoogleengineer"
                        fontSize="large"
                        width="100%"
                      />
                    </div>
                    <div className="not-user-text">Continue with Google Workspace</div>
                  </Button>
                </LoginSocialGoogle>
              </Box>
              <Typography
                sx={{
                  fontSize: '13.8px',
                  textAlign: 'center',
                  marginTop: '25px',
                  fontFamily: 'Poppins'
                }}
              >
                or
              </Typography>
              <Typography
                sx={{
                  fontSize: '18px',
                  textAlign: 'center',
                  marginTop: '10px',
                  fontFamily: 'Poppins',
                  fontWeight: '400'
                }}
              >
                Sign up
              </Typography>
              <Box sx={{ textAlign: 'center' }}>
                <Button
                  className="signup-engineer-text-btn"
                  type="button"
                  onClick={modalEngineerSignUpOpen}
                >
                  Sign Up as an Engineer
                </Button>
              </Box>
              <Box sx={{ textAlign: 'center' }}>
                <Button className="signup-business-text-btn" type="button" onClick={businessModal}>
                  Sign Up as a Business
                </Button>
              </Box>
            </CardContent>
          </Card>
        </div>
      </div>

      {open ? (
        <FindGigPopup
          signInEngineerPopup={open}
          modalEngineerOpen={(e) => {
            setOpen(true);
          }}
          modalEngineerClose={(e) => {
            setOpen(false);
          }}
          modalEngineerSignUpOpen={(e) => {
            setOpenSignUp(true);
          }}
          modalForgetEngineer={(e) => {
            setOpenForgetEngineer(true);
          }}
          modalEngineerSignUpClose={(e) => {
            setOpenSignUp(false);
          }}
          modalForgetClose={(e) => {
            setOpenForgetEngineer(false);
          }}
        />
      ) : (
        <></>
      )}

      {openSignUp ? (
        <FindGigPopup
          signUpModalOpen={openSignUp}
          modalEngineerSignUpOpen={(e) => {
            setOpenSignUp(true);
          }}
          modalEngineerOpen={(e) => {
            setOpen(true);
          }}
          modalEngineerSignUpClose={(e) => {
            setOpenSignUp(false);
          }}
          modalEngineerClose={(e) => {
            setOpen(false);
          }}
          modalForgetClose={(e) => {
            setOpenForgetEngineer(false);
          }}
        />
      ) : (
        <></>
      )}

      {openForgetEngineer ? (
        <FindGigPopup
          forgetSignIn={openForgetEngineer}
          modalForgetEngineer={(e) => {
            setOpenForgetEngineer(true);
          }}
          modalEngineerSignUpOpen={(e) => {
            setOpenSignUp(true);
          }}
          modalForgetClose={(e) => {
            setOpenForgetEngineer(false);
          }}
          modalEngineerClose={(e) => {
            setOpen(false);
          }}
          modalEngineerSignUpClose={(e) => {
            setOpenSignUp(false);
          }}
        />
      ) : (
        <></>
      )}

      {openSignUpModal ? (
        <FindEngineerPopup
          signInBusinessPopup={openSignUpModal}
          modalBusinessOpen={(e) => {
            setOpenSignUpModal(true);
          }}
          modalBusinessClose={(e) => {
            setOpenSignUpModal(false);
          }}
          businessModal={(e) => {
            setOpenBusinessModal(true);
          }}
          ForgetBusinessModalOpen={(e) => {
            setopenForgetBusiness(true);
          }}
          modalBusinessSignUpClose={(e) => {
            setOpenBusinessModal(false);
          }}
          modalBusinessForgetClose={(e) => {
            setopenForgetBusiness(false);
          }}
        />
      ) : (
        <></>
      )}

      {openBusinessModal ? (
        <FindEngineerPopup
          signUpBusinessModal={openBusinessModal}
          businessModal={(e) => {
            setOpenBusinessModal(true);
          }}
          modalBusinessOpen={(e) => {
            setOpenSignUpModal(true);
          }}
          modalBusinessSignUpClose={(e) => {
            setOpenBusinessModal(false);
          }}
          modalBusinessClose={(e) => {
            setOpenSignUpModal(false);
          }}
          modalBusinessForgetClose={(e) => {
            setopenForgetBusiness(false);
          }}
        />
      ) : (
        <></>
      )}

      {openForgetBusiness ? (
        <FindEngineerPopup
          forgetBusinessModal={openForgetBusiness}
          ForgetBusinessModalOpen={(e) => {
            setopenForgetBusiness(true);
          }}
          modalBusinessOpen={(e) => {
            setOpenSignUpModal(true);
          }}
          businessModal={(e) => {
            setOpenBusinessModal(true);
          }}
          modalBusinessForgetClose={(e) => {
            setopenForgetBusiness(false);
          }}
          modalBusinessClose={(e) => {
            setOpenSignUpModal(false);
          }}
          modalBusinessSignUpClose={(e) => {
            setOpenBusinessModal(false);
          }}
        />
      ) : (
        <></>
      )}
    </React.Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    auth: state.auth
  };
};
export default connect(mapStateToProps)(SignINUPmodal);
