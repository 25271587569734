import { Box, Grid, TableContainer, Typography, Paper, Table, TableBody, styled, TableCell, tableCellClasses, TableRow, TextField, Input, Button } from '@mui/material';
import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import DatePicker from '@mui/lab/DatePicker';
import { LocalizationProvider } from '@mui/lab';
import { InputUnstyled, TextareaAutosize } from '@mui/base';
import { useState, useRef } from 'react';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import './styles.scss'
import CircularProgress from '@mui/material/CircularProgress';
import GigLogo from '../../../../assests/logos.png'
import { CalendarBox, CustomRow } from '../../../app.styles';
import { Form, Formik } from 'formik';
import { InvoiceSchema } from '../../../../utils/formikValidations';
import { getUserId, getUserType } from '../../../../utils/user';
import { getActiveGigs } from '../../../../services/engineerGig';
import { toast } from 'react-toastify';
import { createUpdateInvoice } from '../../../../services/invoicing';
import { getGigs } from '../../../../services/gig';
import CopyIcon from '../images/content_copy.svg'
import { getUploadApi } from '../../../../services/upload';
import { NumberFormatter } from '../../../../utils/helpers';
import { DragDropIcon } from '../../../profile/engineer/DragDrop/DragDrop';

const TextInputWithCopy = (props) => {
    const inputRef = useRef()
    const copyData = () => {
        // var copyText = document.getElementById(`${props.idRef}`);
        var copyText = inputRef.current;
        copyText.select();
        copyText.setSelectionRange(0, 99999);
        let tempStr = JSON.parse(JSON.stringify(copyText.value));
        if (props.label == "Total Amount") {
            tempStr = tempStr.split(" ")[1]
        }
        navigator.clipboard.writeText(tempStr).then((e) => {
            toast.success("Copied to clipboard!")
        }).catch((err) => {

        })
    }
    return (
        <div className="etf-input-wrapper">
            <p>{props.label}</p>
            <span>
                <input ref={inputRef} disabled value={props.value} />
                <img className={props.showCopy ? "visible" : "hidden"} onClick={() => { copyData() }} src={CopyIcon} />
            </span>
        </div>
    )
}

function EftModal(props) {
    const { seteftModalOpen, eftModalOpen, handleEftModalClose, activeInvoice } = props;
    const dispatch = useDispatch();
    const history = useHistory();
    const userType = getUserType();
    const [fileProof, setfileProof] = useState()
    const [uploadedFilename, setuploadedFilename] = useState("")
    const [loading, setLoading] = useState(false);

    const handleModalFormSubmit = (values) => {
        // console.log(values)
    }

    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);

            fileReader.onload = () => {
                resolve(fileReader.result);
            };

            fileReader.onerror = (error) => {
                reject(error);
            };
        });
    };

    const handleFileUploaded = async (file, event) => {
        const base64 = await convertBase64(file);
        try {
            setLoading(true);
            getUploadApi(file).then((e) => {
                setLoading(false);
                setfileProof(e.data.fileUrl)
                setuploadedFilename(file.name)
            }).catch((err) => {
                console.error(err)
                toast.error("Error uploading file. Please try again!")
            })
        }
        catch (err) {
            console.error(err)
            toast.error("Error uploading file. Please try again!")
        }
    }



    const handleFormSubmit = () => {
        if (!!activeInvoice) {
            let data = {
                "gigId": activeInvoice.gigId,
                "engineerId": activeInvoice.engineerId,
                "businessId": activeInvoice.businessId,
                "currency": activeInvoice.currency,
                "status": "PAYMENTINITIATED",
                "invoiceType": activeInvoice.invoiceType,
                "invoiceDescription": activeInvoice.invoiceDescription,
                "hoursWorked": Number(activeInvoice.hoursWorked),
                "hourlyRate": Number(activeInvoice.hourlyRate),
                "invoiceTotal": activeInvoice.invoiceTotal,
                "finalTotal": activeInvoice.finalTotal,
                "invoiceDate": (new Date()).toISOString(),
                "proofPayment": !!fileProof ? fileProof : "",
                "fromDate": activeInvoice.fromDate,
                "toDate": activeInvoice.toDate,
                "_id": activeInvoice._id,
            }

            try {
                createUpdateInvoice(data).then((e) => {
                    toast.success("Submitted Successfully!")
                    handleEftModalClose();
                }).catch((err) => { console.error(e) })
            }
            catch (err) {
                console.error(err)
            }
        }
        else {
            toast.error("Form not filled properly. Please try again!")
        }

    }

    return (
        <div className="modal-wrapper">
            <div className="modal-card">
                <div className="modal-top">
                    <span onClick={() => { handleEftModalClose() }}>&times;</span>
                </div>
                <div className="modal-header">
                    <img src={GigLogo} />
                    EFT
                </div>
                <div className="modal-header">
                    <p className="eft-header-info">Please make the payment to the mentioned details and upload the proof of payment.</p>
                </div>
                <Grid container>
                    <Grid item xs={12} md={6}>
                        <TextInputWithCopy label={"Inoice Number"} value={activeInvoice.invoiceId} showCopy={false} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextInputWithCopy label={"Gig Name"} value={activeInvoice.gigDetails?.titleAndSkills?.projectTitle} showCopy={false} />
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} md={6}>
                        <TextInputWithCopy label={"Inoice Type"} value={activeInvoice.invoiceType} showCopy={false} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextInputWithCopy label={"Bank Name"} value={"Investec Bank"} showCopy={true} />
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} md={6}>
                        <TextInputWithCopy label={"Branch Code"} value={"580105"} showCopy={true} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextInputWithCopy label={"Swift Code"} value={"IVESZAJJ"} showCopy={true} />
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} md={6}>
                        <TextInputWithCopy label={"Account Name"} value={"Gig Engineer (PVT) Limited"} showCopy={true} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextInputWithCopy label={"Account Number"} value={"7154865598"} showCopy={true} />
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} md={6}>
                        <TextInputWithCopy label={"Total Amount"} value={`${activeInvoice.currency} ${NumberFormatter(activeInvoice.invoiceTotal)}`} showCopy={true} />
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} md={6}>
                        <div className="etf-input-wrapper">
                            <p>Upload your proof of payment</p>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <Button
                                    variant="contained"
                                    component="label"
                                    style={{ backgroundColor: `${!!fileProof ? "#0140AA" : "#7E84A3"}` }}
                                >
                                    <React.Fragment>
                                        {loading ? <CircularProgress sx={{ color: 'white' }} className="upload-Loading"></CircularProgress> :
                                            <div title="Max Size 5MB">
                                                <DragDropIcon text="Upload" onFileChange={(e) => { handleFileUploaded(e) }}></DragDropIcon>
                                            </div>
                                        }
                                    </React.Fragment>
                                </Button>
                                <span title={uploadedFilename} style={{ marginLeft: "12px", width: "44%", height: "36px", display: "block", paddingTop: "8px", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis", }}>{!!uploadedFilename ? uploadedFilename : "No file uploaded"}</span>
                            </div>
                        </div>
                    </Grid>
                </Grid>

                <div style={{ display: "flex", justifyContent: "center" }}>
                    {
                        !!fileProof ?
                            <button className='confirm-cta' onClick={() => { handleFormSubmit() }}>Confirm</button>
                            :
                            <button className='confirm-cta disabled'>Confirm</button>
                    }
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        gigs: state.gigs
    };
};

export default connect(mapStateToProps)(EftModal)
