import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import { connect } from 'react-redux';
import { Grid, TextField, Button, Box, Typography, Rating } from '@mui/material';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import { setBusinessProfileApi } from '../../../../services/profile';
import { PaddedBox, PaddedFooter } from '../../../app.styles';
import { representativeValidationSchema } from '../../../../utils/formikValidations';
import {
  setProfileError,
  setProfileSuccess,
  resetProfileSuccess
} from '../../../../store/actions/profile';
import { setNotification } from '../../../../store/actions/chat';
import { resetSuccessState } from '../../../../utils/helpers';
import MuiPhoneNumber from 'material-ui-phone-number';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import "./Representative.css";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { toast } from 'react-toastify';
import { Prompt, useHistory } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import { setCookie, getCookie } from '../../../../utils/cookie';
import Footer from '../../../../components/footer1/footer';
import { getUserId, getUserType } from '../../../../utils/user';
import { cancelProfileFeedBack, profileFeedBack } from '../../../../services/gig';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const Representative = (props) => {
  const history = useHistory();

  const defaultInfo = {
    gender: 'Male',
    name: '',
    emailId: '',
    mobileNumber: '+27',
    designation: '',
    description: ''
  };

  let rep = { ...defaultInfo, ...props.representative };
  const [datachange, setDataChange] = useState(false);
  const percentage = _get(props, 'profile.profileCompletionPercent', 0);
  const [open, setOpen] = useState(getCookie('setOpen'));
  const [openComplete, setOpenComplete] = useState(getCookie('setOpenComplete'));
  const [rating, setRating] = useState(1);
  // const [feedback, setFeedback] = useState('');
  const [ratingError, setRatingError] = useState('');
  const isFeedbackGiven = _get(props, 'profile.isFeedbackGiven', false);
  const userType = getUserType();
  const userId = getUserId();
  // const onGetNotifications = (data) => {
  //   props.setNotification(data);
  // }
  useEffect(() => {
    if (userType === 'business') {
      socket.emit('getNotification', {
        'businessId': userId,
        'userRole': [userType]
      })
    }
  }, [])

  const handleClose = () => {
    setOpen(false);
    setCookie('setOpen', false, 30);
  };

  const handleCloseforComplete = () => {
    setOpenComplete(false);
    setCookie('setOpenComplete', false, 30);
    cancelMethod();
  };

  const BootstrapDialogTitle = (props) => {
    const { onClose } = props;

    return (
      <DialogTitle sx={{ m: 0, p: 2 }}>
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };

  BootstrapDialogTitle.propTypes = {
    onClose: PropTypes.func.isRequired,
  };

  const submitMethod = async () => {
    let feedback = document.getElementById("standard-search").value;
    let data = {
      feedback: feedback,
      rating: rating,
      isGiven: true,
      type: "profileCompletion"
    };
    if (data.rating === 0) {
      return setRatingError('Please give rating before submit.')
    }
    profileFeedBack(data).then((e)=>{
      setOpenComplete(false);
      toast.success("Success");
      setCookie('setOpenComplete', false, 30);
    })
  };

  const cancelMethod = async () => {
    // let feedback = document.getElementById("standard-search").value;
    let data = {
      isGiven: false,
      type: "profileCompletion"
    };
    cancelProfileFeedBack(data).then((e)=>{
      setOpenComplete(false);
    })
  };

  return (
    <React.Fragment>
      <Prompt
        when={datachange}
        message={() => 'You have unsaved changes, Are you sure you want to leave?'}
      ></Prompt>
      {percentage < 60 && (
        <div>
          <BootstrapDialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
          >
            <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
            </BootstrapDialogTitle>
            <Typography sx={{ marginBottom: '15px', fontSize: '19px', fontWeight: '500', marginTop: '-10px', marginLeft: '15px' }}>
              Welcome to Gig Engineer!
            </Typography>
            <DialogContent dividers>
              <Typography gutterBottom>
                Please complete your profile. Once your profile is complete you
                will be able to post gigs and connect with engineers.
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button autoFocus onClick={handleClose}>
                Close
              </Button>
            </DialogActions>
          </BootstrapDialog>
        </div>
      )}
      {(percentage === 100 && !isFeedbackGiven && (getCookie('setOpenComplete') != false)) && (
        <div>
          <Dialog open={openComplete} onClose={handleCloseforComplete}>
            <Box
              sx={{ display: "flex", flexDirection: "column", padding: "20px" }}
            >
              <BootstrapDialogTitle id="customized-dialog-title" onClose={handleCloseforComplete}>
              </BootstrapDialogTitle>
              <h2>Hey there!</h2>
              <Typography>
                Congratulations on successfully completing your Gig Engineer
                profile! We would love to get feedback on your experience.
              </Typography>
              <Box sx={{ display: "flex" }}>
                <Typography sx={{ marginTop: "12px" }}>
                  <Rating
                    name="size-large"
                    defaultValue={rating}
                    size="large"
                    onChange={(event) => {
                      let starValue = event.target.value;
                      if (rating != parseInt(starValue)) {
                        setRating(parseInt(starValue));
                        setRatingError(null)
                      }
                      if (rating === parseInt(starValue)) {
                        setRating(0)
                        setRatingError('Please provide the rating.')
                      }
                    }}

                  />
                </Typography>
                <Box
                  sx={{ marginTop: "12px", marginLeft: "20px", fontSize: "20px" }}
                >
                  ({rating}/5)
                </Box>
              </Box>
              <Typography
                sx={{
                  fontSize: '12px',
                  color: 'red'
                }}
              >
                {ratingError}
              </Typography>
              <Box
                component="form"
                sx={{
                  "& .MuiTextField-root": { m: 1, width: "60ch" },
                  marginTop: "-10px",
                }}
                autoComplete="off"
              >
                <div>
                  <TextField
                    id="standard-search"
                    label="Feedback (Optional)"
                    // value={feedback}
                    type="search"
                    variant="standard"
                  />
                </div>
              </Box>
              <Box
                sx={{
                  textAlign: "right",
                }}
              >
                <Button
                  variant="contained"
                  className="popup-process"
                  type="submit"
                  sx={{
                    width: "120px",
                  }}
                  onClick={submitMethod}
                >
                  Submit
                </Button>
                <br />
              </Box>
            </Box>
          </Dialog>
        </div>
      )}
      <PaddedBox style={{ paddingLeft: `${!!props.auth && !!props.auth.isDrawerOpen && props.auth.isDrawerOpen == true ? "240px" : "0px"}`, minHeight: "580px" }}>
        <Formik
          initialValues={rep}
          enableReinitialize={true}
          validationSchema={representativeValidationSchema}
          onSubmit={(values) => {
            const obj = { basicInfo: { ...values } };
            setDataChange(false);
            setBusinessProfileApi(obj, props.setProfileError, props.setProfileSuccess);
            toast.success('Data Saved!');
            if (values?.action === 'next') {
              setTimeout(() => {
                history.push(`/companydetails`);
              }, 1000);
            }
          }}
        >
          {({ values, touched, errors, handleChange, handleBlur, setFieldValue }) => (
            <Form
              noValidate
              autoComplete="off"
              onChange={() => {
                resetSuccessState(props);
              }}
            >
              <Typography
                variant="h6"
                component="h6"
                color="pColor.main"
                sx={{ fontWeight: 'Medium', textTransform: 'capitalize', fontSize: '22px' }}
              >
                Representative Details
              </Typography>
              <Card sx={{ marginTop: '20px', marginBottom: '40px' }}>
                <CardContent>
                  <Grid container className="section-Margin" xs={12} md={12} lg={12} rowSpacing={{ xs: 2, sm: 2, md: 3 }} columnSpacing={{ xs: 0, sm: 3, md: 3 }}>
                    <Grid item xs={12} md={4} lg={4}>
                      <TextField
                        fullWidth
                        id="name"
                        name="name"
                        label="Name"
                        value={values.name}
                        onChange={(e) => {
                          handleChange(e);
                          setDataChange(true);
                        }}
                        onBlur={handleBlur}
                        error={touched.name && Boolean(errors.name)}
                        helperText={touched.name && errors.name}
                        variant="outlined"
                        required
                      />
                    </Grid>
                    <Grid item xs={12} md={4} lg={4}>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel>Gender</InputLabel>
                        <Select
                          name="gender"
                          value={values.gender}
                          placeholder="Select your gender."
                          label="GENDER"
                          onChange={(e) => {
                            handleChange(e);
                            setDataChange(true);
                          }}
                        >
                          <MenuItem value="">
                            <em>Select your gender.</em>
                          </MenuItem>
                          <MenuItem value={'Male'}>Male</MenuItem>
                          <MenuItem value={'Female'}>Female</MenuItem>
                          <MenuItem value={'Rather not say'}>Rather not say</MenuItem>
                          <MenuItem value={'Other'}>Other</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4} lg={4}>
                      <TextField
                        fullWidth
                        label="Email address"
                        variant="outlined"
                        disabled
                        value={values.emailId}
                      />
                    </Grid>
                    <Grid item xs={12} md={4} lg={4}>
                      <MuiPhoneNumber
                        className="css-asterik"
                        fullWidth
                        label="Mobile number"
                        value={values.mobileNumber}
                        disableAreaCodes={true}
                        onChange={(e) => {
                          setFieldValue('mobileNumber', e);
                          setDataChange(true);
                        }}
                        onBlur={handleBlur}
                        error={touched.mobileNumber && Boolean(errors.mobileNumber)}
                        helperText={touched.mobileNumber && errors.mobileNumber}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        required
                      />
                    </Grid>
                    <Grid item xs={12} md={4} lg={4}>
                      <TextField
                        fullWidth
                        label="Title"
                        id="designation"
                        name="designation"
                        value={values.designation}
                        onChange={(e) => {
                          handleChange(e);
                          setDataChange(true);
                        }}
                        onBlur={handleBlur}
                        error={touched.designation && Boolean(errors.designation)}
                        helperText={touched.designation && errors.designation}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <TextField
                        fullWidth
                        label="Role description"
                        id="description"
                        name="description"
                        value={values.description}
                        onChange={(e) => {
                          handleChange(e);
                          setDataChange(true);
                        }}
                        onBlur={handleBlur}
                        error={touched.description && Boolean(errors.description)}
                        helperText={touched.description && errors.description}
                        variant="outlined"
                        multiline
                        rows={3}
                      />
                      {/* <TextareaAutosize 
                        fullWidth
                        label="SUMMARY / DESCRIPTION"
                        id="description"
                        name="description"
                        value={values.description}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.description && Boolean(errors.description)}
                        helperText={touched.description && errors.description}
                        variant="outlined"
                    /> */}
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
              <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button
                  variant="contained"
                  className={`primary-Btn ${props.saveSuccess && 'successActive'}`}
                  type="submit"
                  onClick={() => {
                    setFieldValue("action", "save");
                  }}
                >
                  {props.saveSuccess ? 'saved' : 'save'}
                </Button>
                <Button
                  variant="contained"
                  className="primary-Main-Btn"
                  type="submit"
                  onClick={() => {
                    setFieldValue("action", "next");
                  }}
                >
                  Next
                </Button>
              </Grid>
            </Form>
          )}
        </Formik>
      </PaddedBox>
      <PaddedFooter style={{ paddingLeft: `${!!props.auth && !!props.auth.isDrawerOpen && props.auth.isDrawerOpen == true ? "240px" : "0px"}` }}>
        <Footer />
      </PaddedFooter>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    representative: state.profile.basicInfo || {},
    error: state.profile.error,
    saveSuccess: state.profile.saveSuccess,
    profile: state.profile
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setProfileError: (details) => dispatch(setProfileError(details)),
    setProfileSuccess: (details) => dispatch(setProfileSuccess(details)),
    resetProfileSuccess: (details) => dispatch(resetProfileSuccess(details)),
    setNotification: (details) => dispatch(setNotification(details)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Representative);
