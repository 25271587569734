import React from 'react';
import { connect } from 'react-redux';
import { IconButton, InputAdornment, Link, List, ListItem, ListItemText } from '@mui/material';
import {
  TextField,
  Button,
  Alert,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  FormLabel
} from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import { Formik, Form } from 'formik';
import VerifiedUserOutlinedIcon from '@mui/icons-material/VerifiedUserOutlined';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import { SignUpMsg, SignInDisclamer, RadioInput } from './SignUpForm.styles';
import { signUpScreenValidationSchema } from '../../utils/formikValidations';
import signUpReq from '../../services/signUp';
import { setAuthSignError } from '../../store/actions/auth';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import ReactGA from "react-ga";
import { useHistory } from 'react-router-dom';
import ReCAPTCHA from "react-google-recaptcha";
import './SignUp.css';
ReactGA.initialize(`G-1596JC7760`);
ReactGA.pageview(window.location.pathname + window.location.search);

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="/">
        Gig Engineer
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const SignUpForm = (props) => {

  const history = useHistory();
  const [open, setOpen] = React.useState(false);
  const [userType, setUserType] = React.useState('');
  const [promoted, setPromoted] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('sm');
  const [token, setToken] = React.useState(null);

  const handleClose = () => {
    setOpen(false);
    window.location.href = '/signin';
  };

  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const onChangee = (value) => {
    setToken(value)
  }
  return (
    <>
      {!_isEmpty(_get(props, 'auth.signError', {})) && (
        <Alert severity="error" className="h-margin-bottom-16">
          {_get(props, 'auth.signError.message', 'some thing went wrong please try again')}
        </Alert>
      )}
      <Formik
        initialValues={{
          fullName: '',
          companyName: '',
          emailId: '',
          password: '',
          signUpAs: '',
          promoOpted: ''
        }}
        enableReinitialize={true}
        validationSchema={signUpScreenValidationSchema}
        onSubmit={async (values) => {
          const obj = { ...values };
          obj['userType'] = userType;
          obj['promoOpted'] = promoted;
          setEmail(obj.emailId);
          if(token){
            let response = await signUpReq(obj, props.setAuthSignError);
            if (response && response.status === 200) setOpen(true);
          }
        }}
      >
        {({ values, touched, errors, handleChange, handleBlur }) => (
          <Form noValidate autoComplete="off">

            <FormControl sx={{ mt: 2 }} component="fieldset" variant="outlined">
              <FormLabel component="legend" sx={{ fontSize: "12px", fontWeight: "600" }}>Sign up as:</FormLabel>
              <RadioInput>
                <RadioGroup
                  onChange={(e) => {
                    setUserType(e.target.value);
                  }}
                  value={userType}
                  row
                  aria-label="signUpAs"
                  name="row-radio-buttons-group"
                  size="small"
                >
                  <FormControlLabel value="business" control={<Radio />} label="Business" size="small" />
                  <FormControlLabel value="engineer" control={<Radio />} label="Engineer" size="small" />
                </RadioGroup>
              </RadioInput>
            </FormControl>

            <TextField
              margin="normal"
              fullWidth
              id="fullName"
              name="fullName"
              label="Full Name"
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.fullName && Boolean(errors.fullName)}
              helperText={touched.fullName && errors.fullName}
              value={values.fullName}
              variant="outlined"
              size="small"
            />
            {(userType === "business") && (
              <TextField
                margin="normal"
                fullWidth
                id="companyName"
                name="companyName"
                label="Company Name"
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.companyName && Boolean(errors.companyName)}
                helperText={touched.companyName && errors.companyName}
                value={values.companyName}
                variant="outlined"
                size="small"
              />
            )}
            <TextField
              margin="normal"
              fullWidth
              id="emailId"
              name="emailId"
              label="Email"
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.emailId && Boolean(errors.emailId)}
              helperText={touched.emailId && errors.emailId}
              value={values.emailId}
              variant="outlined"
              size="small"
            />
            <TextField
              margin="normal"
              fullWidth
              type={showPassword ? "text" : "password"}
              id="password"
              name="password"
              label="Password"
              autoComplete="current-password"
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.password && Boolean(errors.password)}
              helperText={touched.password && errors.password}
              value={values.password}
              variant="outlined"
              size="small"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />

            <FormControl sx={{ mt: 2 }} component="fieldset" variant="outlined">
              <FormLabel component="legend" sx={{ fontSize: "12px", fontWeight: "600" }}>
                Do you wish to receive our newsletters and promotional content?
              </FormLabel>
              <RadioInput>
                <RadioGroup row aria-label="signUpAs" name="row-radio-buttons-group"
                  size="small">
                  <FormControlLabel value="Yes" control={<Radio onChange={(e) => {
                    setPromoted("yes");
                  }} />} label="Yes"
                    size="small" />
                  <FormControlLabel value="No" control={<Radio onChange={(e) => {
                    setPromoted("no");
                  }} />} label="No"
                    size="small" />
                </RadioGroup>
              </RadioInput>
            </FormControl>
            <div className="recapcha-engineer-login">
            <ReCAPTCHA
              sitekey="6LcuRXkkAAAAAL3IChCb5HG95esZx8V655kBpwyS"
              onChange={onChangee}
            />
            </div>

            <SignInDisclamer>
              By continuing, you agree to accept our Privacy Policy &amp; Terms of Service.
            </SignInDisclamer>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ paddingTop: "10px", paddingBottom: "10px" }}>
              SIGN UP
            </Button>

            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", mt: "8px" }}>
              <SignUpMsg>
                Already have an account?&nbsp;&nbsp;
                <Link href="/signin" underline="hover" sx={{ cursor: 'pointer', fontWeight: '600', fontSize: '14px' }}>
                  Sign in
                </Link>
              </SignUpMsg>
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
              <List sx={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                <ListItem disablePadding>
                  <ListItemText
                    primary={
                      <Typography
                        sx={{
                          fontSize: '13px',
                          color: '#5E5E5E',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden'
                        }}
                        onClick={() => { history.push(`/termsconditions`); }}
                      >
                        <Link href="" underline="hover">
                          Terms and Conditions
                        </Link>
                      </Typography>
                    }
                  />
                </ListItem>
                <ListItem disablePadding>
                  <ListItemText
                    primary={
                      <Typography
                        sx={{
                          fontSize: '13px',
                          color: '#5E5E5E',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden'
                        }}
                        onClick={() => { history.push(`/privacypolicy`); }}
                      >
                        <Link href="" underline="hover">
                          Privacy Policy
                        </Link>
                      </Typography>
                    }
                  />
                </ListItem>
              </List>
            </Box>

            <Box sx={{ marginTop: '14px', display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
              <Copyright />
            </Box>

          </Form>
        )}
      </Formik>
      <Dialog fullWidth={fullWidth} maxWidth={maxWidth} open={open} onClose={handleClose}>
        <Box
          sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px', }}
        >
          <Typography
            sx={{ color: "#008000" }}
          >
            <VerifiedUserOutlinedIcon fontSize="large" />
          </Typography>
          <br />
          <Typography>
            A verification email has been sent to {email}. Please check your inbox for the mail and click the verification link to activate your account. If you did not receive the link, please check your spam folders or contact us if you have any queries.
          </Typography>
          <Button onClick={handleClose} color="inherit">
            Click to dismiss
          </Button>
        </Box>
      </Dialog>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth
  };
};


const mapDispatchToProps = (dispatch) => {
  return {
    setAuthSignError: (details) => dispatch(setAuthSignError(details))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUpForm);
