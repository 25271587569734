import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _toNumber from 'lodash/toNumber';
import { CustomRow, EditedCard, PaddedBox, PaddedFooter } from '../../app.styles';
import PinDropIcon from '@mui/icons-material/PinDrop';
import {
    Avatar,
    Button,
    CardContent,
    Chip,
    Grid,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Pagination,
    Typography,
} from '@mui/material';
import { getQueryParams, scrollOnTop } from '../../../utils/helpers';
import { getUserId, getUserType } from '../../../utils/user';
import { connect } from 'react-redux';
import Search from '../../../components/SearchBar/Search';
import { resetSFS } from '../../../store/actions/sfs';
import PageLoader from '../../../components/Loaders/PageLoader';
import NavigateNextOutlinedIcon from '@mui/icons-material/NavigateNextOutlined';
import GridViewIcon from '@mui/icons-material/GridView';
import LogoTable from '../../../assests/logoTable.svg';
import { Box } from '@mui/system';
import AccountDeactivate from '../../../components/AccountDeactivate/AccountDeactivate';
import SortAdmin from '../../../components/SearchBar/SortAdmin';
import AccountActivate from '../../../components/AccountDeactivate/AccountActivate';
import { businessProfileGig } from '../../../services/owners';
import { loginUserViaAdmin } from '../../../services/profile';
import GridTableNotHover from '../../../components/GridTable/GridTableNotHover';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import TaxImg from '../../../assests/TaxImg.png';
import Resisternumber from '../../../assests/Resisternumber.png';
import Footer from '../../../components/footer1/footer';
import MoreVert from './more_vert.svg'
const BusinessProfiles = (props) => {

    const query = getQueryParams();
    const queryPage = query.page;

    const [paginationVal, setPaginationVal] = useState(1);
    const [businessProfile, setBusinessProfile] = useState([]);
    const userType = getUserType(); // get the user type from tokens
    const [tableData, setTableData] = useState("tableView");
    const [list, setList] = useState([]); // acitve engineer list returned from api
    const [page, setPage] = useState(_toNumber(queryPage)); // active enginner list page number
    const [count, setCount] = useState(1); // count of total engineer
    const history = useHistory();
    const sfsReset = useRef(true); // reset search filter sort on navigate to another page
    const filterApplied =
        !_isEmpty(props.searchKey) || !_isEmpty(props.filters) || !_isEmpty(props.sortBy);

    const setListData = (data) => {
        setList(_get(data, 'ownerList', []));
        setPage(_get(data, 'paginationResult.currentPage', 1));
        setCount(_get(data, 'paginationResult.totalPages', 1));
    };

    const getMessageLink = (representativeName, userId, status, rowData) => {
        return (
            <div className='admin-list-cta-dd'>
                <img src={MoreVert} />
                <div className='admin-cta-list'>
                    {userType === 'admin' &&
                        <AccountActivate btnType="primary" profileDet={rowData} />
                    }
                    {(status === "INACTIVE" ?
                        <button
                            // className="admin-Side-login-btn"
                            disabled
                            onClick={() => { handleLoginUser(rowData) }}
                        >
                            Log in as user
                        </button>
                        : <button
                            // className="admin-Side-login-btn"
                            onClick={() => { handleLoginUser(rowData) }}
                        >
                            Log in as user
                        </button>
                    )}
                </div>
            </div>
        )
    }

    const setData = (data) => {
        const rowData = [];
        const businessProfileData = _get(data, 'ownerList', []);
        const totalRecords = _get(data, 'paginationResult', {});
        if (businessProfileData && businessProfileData.length > 0) {
            for (let obj of businessProfileData) {
                const representativeName = _get(obj, 'basicInfo.name', '');
                const companyName = _get(obj, 'companyDetails.companyName', '-') ? _get(obj, 'companyDetails.companyName', '-') : '-';
                const profilepercent = `${_get(obj, 'profileCompletionPercent', '')} %`;
                const location = _get(obj, 'companyDetails.country', '-');
                const userId = _get(obj, 'basicInfo.userId', '');
                const status = _get(obj, 'basicInfo.status', '');
                const registrationNumber = _get(obj, 'companyDetails.registerNumber', '-') ? _get(obj, 'companyDetails.registerNumber', '-') : '-';
                const id = _get(obj, '_id', '');
                rowData.push([representativeName, companyName, profilepercent, location, registrationNumber, getMessageLink(representativeName, userId, status, obj), { id, name }, userId]);
            }
        }
        setBusinessProfile(rowData);
        setPaginationVal(totalRecords.totalPages || 1);
    };

    const fetchData = (currentPage) => {
        const obj = {
            'userId': getUserId(),
            paginationInput: {
                pageNumber: currentPage,
                pageSize: 12
            },
            filter: {
                userStatusList: ['ACTIVE'],
                profileCompletionPercent: 60
            },
            ...props.sortBy
        };

        // admin page we need show the inactive users also
        if (userType === 'admin') {
            delete obj.filter.profileCompletionPercent;
            obj.filter.userStatusList.push('INACTIVE');
            if(obj.sortByKey=="UNVERIFIED_REVIEWS"){
                obj.sortByKey = undefined
                obj.sortByOrder = undefined
                obj.filter.isUnverifiedReviews = true
            }
        }

        if (!_isEmpty(props.searchKey)) {
            obj.search = props.searchKey;
        }

        if (!_isEmpty(props.filters)) {
            obj.filter = {
                ...obj.filter,
                ...props.filters
            };
        }

        businessProfileGig(obj, setData, setListData);
    };

    useEffect(() => {
        if (tableData === 'tableView') {
            fetchData(filterApplied ? 1 : paginationVal);
        } else {
            fetchData(filterApplied ? 1 : page);
        }
    }, [props.searchKey, props.filters, props.sortBy]);

    const onPaginationClick = (event, value) => {
        scrollOnTop();
        fetchData(value);
        setPage(value);
    };

    const handleRowClick = (data) => {
        const gigData = data.pop();
        history.push(`/admin/business/details/${gigData}`);
    };

    const onTableChange = (event, displayView) => {
        if (displayView === 'cardView') {
            setTableData('cardView');
        } else {
            setTableData('tableView');
        }
    };

    const handleChange = (event, view) => {
        scrollOnTop();
        fetchData(view);
        setPaginationVal(view);
    };

    const engineerDetailsView = (engineerId) => {
        sfsReset.current = false;
        const redirectUrl = `${userType === 'admin' ? '/admin' : ''}/engineer/list?page=${page}`;
        history.push(
            `${userType === 'admin' ? '/admin' : ''
            }/business/details/${engineerId}?redirectUrl=${encodeURIComponent(redirectUrl)}`
        );
    };

    const getArrayList = (arrayVal, key) => {
        return Array.prototype.map.call(arrayVal, (s) => ` ${s[key]}`).toString();
    };

    const headers = ['Representative Name', 'Company Name', 'Profile Percentage', 'Location', 'Registration number', 'Action'];

    const handleLoginUser = async (data) => {
        loginUserViaAdmin(data.basicInfo.userId);
    }

    return (
        <>
            <PaddedBox style={{ textAlign: 'center', paddingLeft: `${!!props.auth && !!props.auth.isDrawerOpen && props.auth.isDrawerOpen == true ? "240px" : "0px"}`, minHeight: "580px" }}>
                <CustomRow container>
                    <Typography
                        variant="h6"
                        component="h6"
                        color="pColor.main"
                        sx={{ fontWeight: 'Medium', textTransform: 'capitalize', fontSize: '22px', margin: '0px 0 20px 0', alignItems: 'center' }}
                    >
                        Browse Business Profiles
                        {filterApplied && (
                            <Chip
                                label="Remove Filters"
                                variant="outlined"
                                sx={{
                                    fontWeight: 'normal',
                                    marginLeft: '10px'
                                }}
                                onDelete={() => {
                                    props.resetSFS('');
                                }}
                            />
                        )}
                    </Typography>
                    </CustomRow>
                    <Grid container item xs="auto" justifyContent="flex-end">
                    <div className='search-sort-icon'>
                        <Search placeholder="Search" />
                        <SortAdmin sortType="engineer" />
                        {(tableData === 'tableView') ?
                            <GridViewIcon onClick={(e) => onTableChange(e, 'cardView')} sx={{ fontSize: '35px', color: '#818181', cursor: 'pointer', marginTop: '10px' }} />
                            :
                            <Box sx={{ cursor: 'pointer', marginTop: '11px' }}>
                                <img sx={{ color: '#0000FF' }} src={LogoTable} alt="logotable" fontSize="small"
                                    onClick={(e) => onTableChange(e, 'tableView')}
                                /></Box>
                        }
                        </div>
                    </Grid>

                {_isEmpty(businessProfile) && filterApplied && (
                    <Grid
                        container
                        item
                        justifyContent="center"
                        xs={12}
                        className="h-margin-top-10 h-padding-top-16"
                    >
                        <Typography variant="h6" component="h6">
                            No records found, Please refine your search
                        </Typography>
                    </Grid>
                )}

                {_isEmpty(businessProfile) && !filterApplied && (
                    <Grid
                        container
                        item
                        justifyContent="center"
                        xs={12}
                        className="h-margin-top-10 h-padding-top-16"
                    >
                        <PageLoader />
                    </Grid>
                )}
                {(tableData === 'tableView') ?
                    <Grid container className="table-Padding" mt={2}>
                        <GridTableNotHover
                            headers={headers}
                            handleRowClick={handleRowClick}
                            rows={businessProfile}
                        ></GridTableNotHover>
                    </Grid> :
                    <PaddedBox style={{ paddingLeft: `0px`, marginTop: '-15px', width: "100%" }} secondary>
                        <Grid container item className="section-Margin" spacing={4} xs={12} md={12} lg={12}>
                            {
                                list.map((job, index) => (
                                    (
                                        <Grid item xs={12} sm={4} md={4} lg={4} key={index}>
                                            <EditedCard sx={{ minHeight: '495px' }}>
                                                <Box
                                                    sx={{ cursor: 'pointer' }}
                                                    onClick={(event) => {
                                                        event.stopPropagation();
                                                        engineerDetailsView(_get(job, 'basicInfo.userId'));
                                                    }}
                                                >
                                                    <Box sx={{ margin: 'auto' }}>
                                                        <Avatar
                                                            src={_get(job, 'companyDetails.profilePic', '')}
                                                            sx={{
                                                                width: 80,
                                                                height: 80,
                                                                objectFit: 'cover',
                                                                margin: 'auto',
                                                                border: '3px solid #eee',
                                                                boxShadow: 'rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset',
                                                                backgroundColor: '#CCD8EE'
                                                            }}
                                                        >
                                                            {_get(job, 'basicInfo.name[0]', '')}
                                                        </Avatar>
                                                        <Typography
                                                            variant="h6"
                                                            component="h6"
                                                            color="pColor.main"
                                                            sx={{
                                                                fontWeight: 'Medium',
                                                                textTransform: 'capitalize',
                                                                textAlign: 'center',
                                                                marginTop: '15px'
                                                            }}
                                                        >
                                                            <Typography
                                                                title="Business Name"
                                                                sx={{
                                                                    textAlign: 'center',
                                                                    fontSize: '16px',
                                                                    fontWeight: '600',
                                                                    lineHeight: '1.43'
                                                                }}
                                                            >
                                                                {`${_get(job, 'basicInfo.name', '')}`}
                                                            </Typography>
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                                <Box>
                                                    <CardContent className="card-Content">
                                                        <Typography
                                                            variant="subtitle1"
                                                            gutterBottom
                                                            sx={{
                                                                color: '#f99600',
                                                                textAlign: 'center',
                                                                fontSize: '16px',
                                                                fontWeight: '600',
                                                                marginTop: '0px'
                                                            }}
                                                        >
                                                            {_get(job, 'companyDetails.companyName') ? _get(job, 'companyDetails.companyName', '') : '-'}
                                                        </Typography>
                                                        <List>
                                                            <ListItem disablePadding title="Area Code" sx={{ cursor: 'default' }}>
                                                                <ListItemIcon sx={{ minWidth: '20px', marginLeft: '-0px' }}>
                                                                    <PinDropIcon sx={{ fontSize: '21px', color: '#000' }} />
                                                                </ListItemIcon>
                                                                <ListItemText
                                                                    primary={
                                                                        <Typography
                                                                            sx={{
                                                                                fontSize: '16px',
                                                                                color: '#383838',
                                                                                overflow: 'hidden',
                                                                                marginLeft: '5px'
                                                                            }}
                                                                        >
                                                                            {
                                                                                _get(job, 'companyDetails.areaCode', '0') ? `${_get(job, 'companyDetails.areaCode', '0')}` : 'Not Disclosed'
                                                                            }</Typography>
                                                                    }
                                                                />
                                                            </ListItem>
                                                            <ListItem disablePadding title="Tax Idenfitication Number" sx={{ cursor: 'default', marginTop: '5px' }}>
                                                                <ListItemIcon sx={{ minWidth: '25px', marginLeft: '-0px', marginTop: '5px' }}>
                                                                    <img sx={{ color: 'red' }} src={TaxImg} alt="taximg" width="19px" />
                                                                </ListItemIcon>
                                                                <ListItemText
                                                                    primary={
                                                                        <Typography
                                                                            sx={{
                                                                                fontSize: '16px',
                                                                                color: '#383838',
                                                                                overflow: 'hidden',
                                                                                marginLeft: '1px'
                                                                            }}
                                                                        >
                                                                            {
                                                                                _get(job, 'companyDetails.tinNumber', '0') ? `${_get(job, 'companyDetails.tinNumber', '0')}` : 'Not Disclosed'
                                                                            }
                                                                        </Typography>
                                                                    }
                                                                />
                                                            </ListItem>
                                                            <ListItem disablePadding title="Location" sx={{ cursor: 'default', marginTop: '5px' }}>
                                                                <ListItemIcon sx={{ minWidth: '25px', marginLeft: '-3px', marginTop: '5px' }}>
                                                                    <span
                                                                        enterTouchDelay={0}
                                                                    >
                                                                        <PlaceOutlinedIcon sx={{ fontSize: '23px', color: '#000' }} />
                                                                    </span>
                                                                </ListItemIcon>
                                                                <ListItemText
                                                                    primary={
                                                                        <Typography
                                                                            sx={{
                                                                                fontSize: '16px',
                                                                                color: '#383838',
                                                                                overflow: 'hidden',
                                                                                marginLeft: '4px'
                                                                            }}
                                                                        >
                                                                            {_get(job, 'companyDetails.city')
                                                                                ? ` ${_get(job, 'companyDetails.city')}, ${_get(job, 'companyDetails.country')}` : ' Not Disclosed'}
                                                                        </Typography>
                                                                    }
                                                                />
                                                            </ListItem>
                                                            <ListItem disablePadding title="Email" sx={{ cursor: 'default', marginTop: '5px' }}>
                                                                <ListItemIcon sx={{ minWidth: '25px', marginLeft: '-1px', marginTop: '3px' }}>
                                                                    <MailOutlineIcon
                                                                        sx={{ fontSize: '19px', color: '#000' }}
                                                                    />
                                                                </ListItemIcon>
                                                                <ListItemText
                                                                    sx={{ fontSize: '12px' }}
                                                                    primary={
                                                                        <Typography
                                                                            sx={{
                                                                                fontSize: '16px',
                                                                                color: '#383838',
                                                                                overflow: 'hidden',
                                                                                marginLeft: '3px'
                                                                            }}
                                                                        >
                                                                            {_get(job, 'basicInfo.emailId')
                                                                                ? _get(job, 'basicInfo.emailId')
                                                                                : 'Not Disclosed'}
                                                                        </Typography>
                                                                    }
                                                                />
                                                            </ListItem>
                                                            <ListItem disablePadding title="Registration Number" sx={{ cursor: 'default', marginTop: '5px' }}>
                                                                <ListItemIcon sx={{ minWidth: '25px', marginTop: '3px', marginLeft: '-0px' }}>
                                                                    <img sx={{ color: '#0000FF' }} src={Resisternumber} alt="resisternumber" width="17.2px" />
                                                                </ListItemIcon>
                                                                <ListItemText
                                                                    sx={{ fontSize: '12px' }}
                                                                    primary={
                                                                        <Typography
                                                                            sx={{
                                                                                fontSize: '16px',
                                                                                color: '#383838',
                                                                                overflow: 'hidden',
                                                                                marginLeft: '3px'
                                                                            }}
                                                                        >
                                                                            {_get(job, 'companyDetails.registerNumber')
                                                                                ? _get(job, 'companyDetails.registerNumber')
                                                                                : 'Not Disclosed'}
                                                                        </Typography>
                                                                    }
                                                                />
                                                            </ListItem>

                                                        </List>
                                                    </CardContent>
                                                </Box>

                                                {(_get(job, 'basicInfo.status')) === "INACTIVE" && userType === 'admin' ?
                                                    <Box className="card-Action" sx={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "15px" }}>
                                                        <AccountDeactivate btnType="primary" profileDet={job} />
                                                        <Button
                                                            className="admin-Side-login-btn"
                                                            disabled
                                                            onClick={() => { handleLoginUser(job) }}
                                                            sx={{
                                                                marginLeft: '5px',
                                                                width: "163.45px",
                                                                height: '40.41px',
                                                                backgroundColor: '#FFFFFF',
                                                                borderRadius: '6px',
                                                                border: '1px solid #0543AB',
                                                                color: '#0543AB',
                                                                fontSize: '14px',
                                                                textTransform: 'capitalize'
                                                            }}
                                                        >
                                                            Log in as user
                                                        </Button>
                                                    </Box> : <Box className="card-Action" sx={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "15px" }}>
                                                        <AccountDeactivate btnType="primary" profileDet={job} />
                                                        <Button
                                                            className="admin-Side-login-btn"
                                                            onClick={() => { handleLoginUser(job) }}
                                                            sx={{
                                                                marginLeft: '5px',
                                                                width: "163.45px",
                                                                height: '40.41px',
                                                                backgroundColor: '#FFFFFF',
                                                                borderRadius: '6px',
                                                                border: '1px solid #0543AB',
                                                                color: '#0543AB',
                                                                fontSize: '14px',
                                                                textTransform: 'capitalize'
                                                            }}
                                                        >
                                                            Log in as user
                                                        </Button>
                                                    </Box>
                                                }

                                                {userType !== 'admin' && (
                                                    <Box className="card-Action" sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                        <Button
                                                            sx={{ color: "#0140AA" }}
                                                            onClick={(event) => {
                                                                event.stopPropagation();
                                                                engineerDetailsView(_get(job, 'basicInfo.userId'));
                                                            }}
                                                        >
                                                            View Details
                                                            <NavigateNextOutlinedIcon />
                                                        </Button>
                                                    </Box>
                                                )}
                                            </EditedCard>
                                        </Grid>
                                    )
                                ))
                            }
                        </Grid>
                    </PaddedBox>}
                {!_isEmpty(list) && (
                    <Box sx={{ padding: '30px 0 10px 0' }}>
                        <Pagination
                            page={page}
                            count={count}
                            shape="rounded"
                            className="pagination-root"
                            onChange={onPaginationClick}
                        />
                    </Box>
                )}
            </PaddedBox>
            <PaddedFooter style={{ paddingLeft: `${!!props.auth && !!props.auth.isDrawerOpen && props.auth.isDrawerOpen == true ? "200px" : "0px"}` }}>
                <Footer />
            </PaddedFooter>
        </>
    );
};
const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        searchKey: state.sfs.searchKey,
        filters: state.sfs.filters,
        sortBy: state.sfs.sortBy
    };
};


const mapDispatchToProps = (dispatch) => {
    return {
        resetSFS: (details) => dispatch(resetSFS(details))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(BusinessProfiles);
