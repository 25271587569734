import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import SendIcon from '@mui/icons-material/Send';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Button from '@mui/material/Button';
import UploadImage from '../../containers/UploadImage/UploadImage';
import { getUploadApi } from '../../services/upload';
import CircularProgress from '@mui/material/CircularProgress';
import { toast } from 'react-toastify';
import _isEmpty from 'lodash/isEmpty';
import _trim from 'lodash/trim';
import _escape from 'lodash/escape';
import { FileUploader } from 'react-drag-drop-files';
import DragChatIcon from '../../assests/DragChatIcon.svg';
import { DragDropIcon } from '../../containers/profile/engineer/DragDrop/DragDrop';

export const TextInput = (props) => {
  const [textVal, setTextVal] = useState('');
  const [certificate, setCertificate] = useState(null);
  const [loading, setLoading] = useState(false);
  const [enable, setEnable] = useState(false);

  const onMessageSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (!enable) {
      return;
    }
    if (certificate) {
      setLoading(true);
      let file = await getUploadApi(certificate);
      setLoading(false);
      setCertificate(null);
      if (file.status == 200) {
        props.sendMessage({ message: { ...file.data, fileName: textVal } }, 'FILE');
      } else {
        toast.error('File not sent. Please try again!!!', { position: "top-right" });
      }
    } else if (!_isEmpty(_trim(textVal))) {
      props.sendMessage && props.sendMessage({ message: textVal }, 'TEXT');
    }
    setTextVal('');
    setEnable(false);
  };

  const onValueChange = (event) => {
    const val = event.target.value;
    setEnable(!_isEmpty(_trim(val)));
    setTextVal(val);
  };

  const onSelectFile = (fileData) => {
    setCertificate(fileData);
    setTextVal(fileData.name);
    setEnable(true);
  }

  const removeAttachment = (e) => {
    setCertificate(null);
    setTextVal("");
    setEnable(false);
  }

  return (
    <>
      <form className='chat-Form' noValidate autoComplete="off" onSubmit={onMessageSubmit}>
        <div className='chat-Text-Container'>
          <TextField
            name="textbox"
            size="sm"
            id="standard-text"
            placeholder="Message"
            className={"chat-Text " + (certificate ? 'chat-Link' : ' ')}
            value={textVal}
            onChange={onValueChange}
            multiline={true}
            maxRows={3}
            disabled={certificate ? true : false}
            inputProps={{
              maxLength: (certificate && textVal.length) || 1000,
              underline: {
                "&&&:before": {
                  borderBottom: "none"
                },
                "&&:after": {
                  borderBottom: "none"
                }
              }
            }}
          />
          {certificate ?
            <div className='chat-Attachment-Container'>
              <HighlightOffIcon className='chat-Remove-Attachment' onClick={removeAttachment}></HighlightOffIcon>
            </div>
            :
            <div title='Add attachment' className='chat-Attachment-Dragdrop'>
              <DragDropIcon onFileChange={onSelectFile} icons={<img src={DragChatIcon} alt="DragChatIcon" />}></DragDropIcon>
            </div>
          }
        </div>

        {loading ?
          <CircularProgress className='chat-Send-Loading'></CircularProgress> :
          <Button type="submit" className={"chat-Send-Button " + (enable ? 'chat-Send-Enable' : '')} variant="contained" color="primary">
            <SendIcon fontSize="small" />
          </Button>}
      </form>
    </>
  );
};
