import _get from 'lodash/get';
import apiService from './apiServices';
import { setCookie } from '../utils/cookie';
import { getUserId, getUserType } from '../utils/user';
import { getUserDashboardRoute } from '../routes/authcheck';

export const signInReq = async (data, setAuthSignError) => {
  if (window.location.href.includes("/profile")||window.location.href.includes("gig/public")) {
    setCookie('redirect_profile_path', window.location.href, 1);
  }
  const request = {
    data,
    method: 'post',
    url: '/auth/login'
  };
  const response = await apiService(request);
  if (response && response.status === 200) {
    // set cookie and remove after discussion
    setCookie('access_token', _get(response, 'data.accessToken', ''), 30);
    setCookie('refresh_token', _get(response, 'data.refreshToken', ''), 30);
    setCookie('setOpen', true, 30);
    setCookie('setOpenComplete', true, 30);
    // redirect
    const userType = getUserType();
    const userId = getUserId();
    window.location.href = getUserDashboardRoute(userType, userId);
  } else {
    // show global error message via props
    setAuthSignError(response);
  }
};


export const socialLogin = async (data) => {
  const { displayName, email, photo, provider, userRole } = data;
  const request = {
    method: 'get',
    url: `/auth/social-login?displayName=${displayName}&emails=${email}&photos=${photo}&provider=${provider}&userRole=${userRole}`,
  };
  const response = await apiService(request);
  if (response && response.status === 200) {
    // set cookie and remove after discussion	
    setCookie('access_token', _get(response, 'data.accessToken', ''), 30);
    setCookie('refresh_token', _get(response, 'data.refreshToken', ''), 30);
    setCookie('setOpen', true, 30);
    setCookie('setOpenComplete', true, 30);
    // redirect	
    const userType = getUserType();
    window.location.href = getUserDashboardRoute(userType);
  } else {
    // show global error message via props	
    setAuthSignError(response);
  }
}
export const LinkedInLoginUser = async (data) => {
  const request = {
    data,
    method: 'post',
    url: `/auth/social-login-linkedin`,
  };
  const response = await apiService(request);
  if (response && response.status === 200) {
    if (!!response.data && response.data != "") {
      let email = response.data.linkedinEmailData.elements[0]["handle~"].emailAddress
      let displayName = `${response.data.linkedinLoginData.firstName.localized.en_US} ${response.data.linkedinLoginData.lastName.localized.en_US}`
      let photo = !!response.data.linkedinLoginData.profilePicture ? response.data.linkedinLoginData.profilePicture["displayImage~"].elements[0].identifiers[0].identifier : ""
      let provider = 'linkedin'
      let userRole = 'engineer'
      socialLogin({
        "displayName": displayName,
        "email": email,
        "photo": photo,
        "provider": provider,
        "userRole": userRole,
      })
    }
    else {
      let err = "Unable to login via LinkedIn. Please try again later!"
      console.error("Invalid data from API")
      setAuthSignError(err);
    }
  } else {
    // show global error message via props	
    console.error(response)
    setAuthSignError(response);
  }
}
