import React, { useState } from 'react'
import { Button, Card, CardContent, Dialog, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material'
import { connect } from 'react-redux';
import { Box } from '@mui/system';
import Footer from '../../../../components/footer1/footer';
import { CustomRow, PaddedBox, PaddedFooter } from '../../../app.styles';
import './bankDetails.css';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import Delete from '../../../../assests/bankcrossicon.svg';
import { Form, Formik, getIn } from 'formik';
import { billingValidation } from '../../../../utils/formikValidations';
import { getProfileDetApi, setEngProfileApi } from '../../../../services/profile';
import DeleteIcon from '@mui/icons-material/Delete';
import { toast } from 'react-toastify';
import { setProfileSuccess, setProfileError, resetProfileSuccess } from '../../../../store/actions/profile';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import { deleteRecord } from '../../../../services/gig';
import HelpIcon from '@mui/icons-material/Help';

const bankDtails = (props) => {

    const defaultBankInfo = {
        holderName: '',
        bankName: '',
        accountNumber: '',
        branchCode: '',
        accountType: ''
    };

    let bank = { ...defaultBankInfo, ...props.billing || {} };

    const history = useHistory();
    const [open, setOpen] = useState(false);
    const [datachange, setDataChange] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    function refreshPage() {
        window.location.reload(false);
    }

    const deleteBankRecord = async (e) => {
        let data = {
            "billingDetails": {}
        }

        deleteRecord(data).then((e) => {
            getProfileDetApi(props.setProfileDetails);
            setOpen(false);
            refreshPage();
        });
    };

    return (
        <>
            <PaddedBox
                style={{ paddingLeft: `${!!props.auth && !!props.auth.isDrawerOpen && props.auth.isDrawerOpen == true ? "240px" : "0px"}`, marginTop: '90px', minHeight: "580px" }}>
                <Formik
                    initialValues={bank}
                    enableReinitialize={true}
                    validationSchema={billingValidation}
                    onSubmit={(values) => {
                        const obj = {
                            billingDetails: values
                        };
                        setDataChange(false);
                        setEngProfileApi(obj, props.setProfileError, props.setProfileSuccess);
                        toast.success('Data Saved!');
                    }}
                >
                    {(formProps) => {
                        const { values, touched, errors, handleChange, handleBlur } = formProps;
                        return (
                            <Form noValidate autoComplete="off" >
                                <Card className='card-container'>
                                    <CardContent>
                                        <div className='bank-page-responsive'>
                                            <Box
                                                sx={{
                                                    fontSize: '20px',
                                                    marginBottom: '15px',
                                                    fontWeight: '600',
                                                    color: '#0140AA',
                                                    display: 'flex'
                                                }}
                                            >
                                                Billing Details
                                                <div title="Please provide your banking details in order to receive payments for completed work once payment is made by the gig owner. Your details are saved securely and will not be visible to the public.">
                                                    <HelpIcon sx={{ color: '#2196f3', fontSize: 16 }} />
                                                </div>
                                            </Box>
                                            <Box sx={{ marginTop: '25px' }}>
                                                <Grid container
                                                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                                                    rowSpacing={{ xs: 3, sm: 2, md: 3 }}
                                                >
                                                    <Grid item xs={12} md={3.5} lg={3.5}>
                                                        <TextField
                                                            fullWidth
                                                            label='Account Holder Name'
                                                            id="holderName"
                                                            name="holderName"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            error={touched.holderName && Boolean(errors.holderName)}
                                                            helperText={touched.holderName && errors.holderName}
                                                            value={values.holderName}
                                                            {...formProps}
                                                            InputLabelProps={{ shrink: true }}
                                                            required
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={3.5} lg={3.5}>
                                                        <TextField
                                                            fullWidth
                                                            label='Bank Name'
                                                            id="bankName"
                                                            name="bankName"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            error={touched.bankName && Boolean(errors.bankName)}
                                                            helperText={touched.bankName && errors.bankName}
                                                            value={values.bankName}
                                                            {...formProps}
                                                            InputLabelProps={{ shrink: true }}
                                                            required
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={3.5} lg={3.5}>
                                                        <TextField
                                                            fullWidth
                                                            label='Account Number'
                                                            id="accountNumber"
                                                            name="accountNumber"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            error={touched.accountNumber && Boolean(errors.accountNumber)}
                                                            helperText={touched.accountNumber && errors.accountNumber}
                                                            value={values.accountNumber}
                                                            {...formProps}
                                                            InputLabelProps={{ shrink: true }}
                                                            required
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={3.5} lg={3.5}>
                                                        <TextField
                                                            fullWidth
                                                            label='Swift/Branch Code (Optional)'
                                                            id="branchCode"
                                                            name="branchCode"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.branchCode}
                                                            {...formProps}
                                                            InputLabelProps={{ shrink: true }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={3.5} lg={3.5}>
                                                        <FormControl variant="outlined" fullWidth>
                                                            <InputLabel>Account Type</InputLabel>
                                                            <Select
                                                                placeholder="Select Account Type"
                                                                id="accountType"
                                                                name="accountType"
                                                                label="Account Type"
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                error={touched.accountType && Boolean(errors.accountType)}
                                                                value={values.accountType}
                                                                {...formProps}
                                                                InputLabelProps={{ shrink: true }}
                                                                required
                                                            >
                                                                <MenuItem value={'Savings'}> Savings </MenuItem>
                                                                <MenuItem value={'Current'}> Current </MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                        <div className='error-msg-bank-account-type'>{errors.accountType}</div>
                                                    </Grid>
                                                </Grid>
                                                {
                                                    props.billing.accountNumber != undefined ?
                                                        <Button className='all-record-delete-btn'
                                                            fontSize="small"
                                                            variant="outlined"
                                                            color="error"
                                                            startIcon={<DeleteIcon />}
                                                            onClick={() => {
                                                                setOpen(true);
                                                            }}
                                                        >
                                                            DELETE
                                                        </Button>
                                                        :
                                                        ""
                                                }


                                            </Box>
                                        </div>
                                    </CardContent>
                                </Card>

                                {
                                    props.saveSuccess ?
                                        <Button
                                            className={`btn-bank-save ${props.saveSuccess && 'successActive'}`}
                                            type="submit"
                                        >
                                            saved
                                        </Button>
                                        :
                                        <Button
                                            className={`btn-bank-save ${props.saveSuccess && 'successActive'}`}
                                            type="submit"
                                        >
                                            {props.saveSuccess ? 'saved' : 'save'}
                                        </Button>
                                }
                            </Form>
                        );
                    }}
                </Formik>

                <Dialog open={open} onClose={handleClose}>
                    <div className='wrapper-main-text'>
                        Delete bank details!
                        <img className='bank-cross-icon' src={Delete} onClick={handleClose} />
                    </div>
                    <div className='delete-text-wrapper'>
                        Are you sure you want to delete your bank details?
                    </div>
                    <div className='btn-container-delete'>
                        <Button
                            variant="contained"
                            className="cancel-btn-bank"
                            type="submit"
                            sx={{
                                marginRight: '10px',
                                textTransform: 'none',
                                width: '110px',
                                height: '30px',
                                border: '1px solid #F99600',
                                boxShadow: 'none'
                            }}
                            onClick={handleClose}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            className="popup-process"
                            type="button"
                            sx={{
                                textTransform: 'none',
                                width: '110px',
                                height: '30px',
                                boxShadow: 'none'
                            }}
                            onClick={() => {
                                deleteBankRecord();
                            }}
                        >
                            Delete
                        </Button>
                    </div>
                </Dialog>

            </PaddedBox>
            <PaddedFooter style={{ paddingLeft: `${!!props.auth && !!props.auth.isDrawerOpen && props.auth.isDrawerOpen == true ? "240px" : "0px"}` }}>
                <Footer />
            </PaddedFooter>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        myProfile: state.profile.myProfile || {},
        error: state.profile.error,
        basicInfo: state.profile.basicInfo,
        saveSuccess: state.profile.saveSuccess,
        profile: state.profile,
        billing: state.profile.billingDetails || {},
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setProfileError: (details) => dispatch(setProfileError(details)),
        setProfileSuccess: (details) => dispatch(setProfileSuccess(details)),
        resetProfileSuccess: (details) => dispatch(resetProfileSuccess(details)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(bankDtails);