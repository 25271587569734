import React from 'react'
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { CustomRow, PaddedBox, PaddedFooter } from '../../app.styles';
import { useState } from 'react';
import './styles.scss'
import { getUserId, getUserType } from '../../../utils/user';
import Footer from '../../../components/footer1/footer';
import { Button, Typography } from '@mui/material';

function ProposalDetails(props) {

    const dispatch = useDispatch();
    const history = useHistory();
    const userType = getUserType();
    return (
        <>
            <PaddedBox style={{ paddingLeft: `${!!props.auth && !!props.auth.isDrawerOpen && props.auth.isDrawerOpen == true ? "240px" : "0px"}`, minHeight: "580px" }}>
                <CustomRow sx={{ display: "flex", justifyContent: "flex-start !important" }} container>
                    <Button variant="outlined" sx={{ marginRight: "12px" }} className="h-color-button-outlined smallButton" onClick={() => { history.go(-1); }}>Back</Button>
                    <Typography variant="h6" component="h6" color="pColor.main" sx={{ fontWeight: 'Medium', textTransform: 'capitalize', fontSize: '22px' }}>
                        View Proposal
                    </Typography>
                </CustomRow>
                <div className='proposal-page-wrapper'>
                    <table cellPadding={10}>
                        {
                            !!history.location.state.coverLetter
                            &&
                            <tr>
                                <th>Cover Letter</th>
                                <td>
                                    <div dangerouslySetInnerHTML={{
                                        __html:
                                            !!history.location.state
                                                &&
                                                !!history.location.state.coverLetter ?
                                                history.location.state.coverLetter
                                                :
                                                "-"
                                    }}>
                                    </div>
                                </td>
                            </tr>
                        }
                        {
                            !!history.location.state.attachment
                            &&
                            <tr>
                                <th>Attachments </th>
                                <td>
                                    {
                                        !!history.location.state
                                            &&
                                            !!history.location.state.attachment ?
                                            <a href={history.location.state.attachment} target={'_blank'}>{history.location.state.attachment.split("/")[history.location.state.attachment.split("/").length - 1]}</a>
                                            :
                                            "-"
                                    }
                                </td>
                            </tr>
                        }
                    </table>
                </div>
            </PaddedBox>
            <PaddedFooter style={{ paddingLeft: `${!!props.auth && !!props.auth.isDrawerOpen && props.auth.isDrawerOpen == true ? "200px" : "0px"}` }}>
                <Footer />
            </PaddedFooter>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        gigs: state.gigs
    };
};

export default connect(mapStateToProps)(ProposalDetails)