import styled from 'styled-components';
import { Grid } from '@mui/material';

export const ProfileTitle = styled(Grid)`
  && {
    text-align: center;
    font-size: 15px;
    font-weight: bold;
    color: #171725;
    padding-top: 16px;
    padding-bottom: 10px;
  }
`;

export const ProfileNavContainer = styled.div`
  && {
    font-size: 14px;
    padding: 36px;
    display: flex;
    flex-direction: column;
  }
`;

export const ProfileNavDetails = styled(Grid)`
  && {
    font-size: 14px;
    padding: 8px 0px;
    color: #392722;
    overflow-wrap: break-word;
  }
`;

export const ProfileSubHeadingTop = styled.div`
  && {
    font-size: 12px;
    color: #7e84a3;
    display: block;
    overflow-wrap: break-word;
  }
`;
