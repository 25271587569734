import _get from 'lodash/get';
import { toast } from 'react-toastify';
import apiService from './apiServices';

const resetPassword = async (data) => {
    const request = {
        data,
        method: 'post',
        url: '/auth/internal-password-resest'
    };
    const response = await apiService(request);
    if (response && response.status === 200) {
        toast.success('Password Change Successfull');
    } else {
        // show global error message via props
        toast.warning(response?.message);
    }
};

export default resetPassword;
