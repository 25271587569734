import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import Gig from './Gig';
import { getDraftGigByIdApi } from '../../../services/gig';
import { setNewGigDetails } from '../../../store/actions/newGig';

const EditGig = (props) => {
  const { id } = useParams();
  useEffect(() => {
    getDraftGigByIdApi(id, props.setNewGigDetails);
  }, []);

  return <>{props.newGig && <Gig />}</>;
};

const mapStateToProps = (state) => {
  return {
    newGig: state.newGig
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setNewGigDetails: (details) => dispatch(setNewGigDetails(details))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditGig);
