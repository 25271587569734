import React from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import { useHistory } from 'react-router-dom';
import {
  Dialog,
  FormControlLabel,
  FormLabel,
  Grid,
  List,
  ListItem,
  ListItemText,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from '@mui/material';
import GigEngineerLogo from '../../assests/GigEngineerLogo.png';
import './HomepageButton.scss';
import { InputAdornment } from '@mui/material';
import { Alert } from '@mui/material';
import { Formik, Form } from 'formik';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
require('dotenv').config();
import {
  signInScreenValidationSchema,
  forgotPasswordScreenValidationSchema
} from '../../utils/formikValidations';
import forgotPassword from '../../services/forgotPassword';
import { signUpScreenValidationSchema } from '../../utils/formikValidations';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {
  DialogCss,
  SignInMsg,
  SignInMsgModal,
  SignUpBusiness,
  SignUpMsg
} from '../SignInForm/SignInForm.styles';
import GoogleLogoIcon from '../../assests/GoogleLogoIcon.svg';
import VerifiedUserOutlinedIcon from '@mui/icons-material/VerifiedUserOutlined';
import signUpReq from '../../services/signUp';
import LinkedinLogoSignIn from '../../assests/LinkedinLogoSignIn.svg';
import { LoginSocialGoogle } from 'reactjs-social-login';
import { useLinkedIn } from 'react-linkedin-login-oauth2';
import ReCAPTCHA from 'react-google-recaptcha';
import './FindGigPopup.scss';
import { socialLogin, LinkedInLoginUser, signInReq } from '../../services/signIn';
import OrSeparator from '../OrSeparator';
import { connect } from 'react-redux';
import { setAuthSignError } from '../../store/actions/auth';

const FindGigPopup = (props) => {
  const history = useHistory();
  const [openSignUpModal, setOpenSignUpModal] = React.useState(false);
  const [openForget, setOpenForget] = React.useState(false);
  const [isResetLink, setIsResetLink] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('sm');
  const [token, setToken] = React.useState(null);
  const [emailMsg, setEmailMsg] = React.useState('');

  const isValidEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };
  
  const onChangee = (value) => {
    setToken(value);
  };

  const handleClose = () => {
    props.modalEngineerClose();
    props.modalEngineerSignUpClose();
    props.modalForgetClose();
  };

  const [promoted, setPromoted] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const { linkedInLogin } = useLinkedIn({
    clientId: process.env.REACT_APP_LINKEDIN_CLIENT_ID,
    scope: 'r_liteprofile r_emailaddress',
    redirectUri: `${window.location.origin}/linkedin`, // for Next.js, you can use `${typeof window === 'object' && window.location.origin}/linkedin`
    onSuccess: (code) => {
      LinkedInLoginUser({
        code: code,
        redirect_uri: `${window.location.origin}/linkedin`
      });
    },
    onError: (error) => {
      console.error(error);
    }
  });

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleGoogleLogin = (resp, userRole) => {
    const { provider, data } = resp;
    socialLogin({
      displayName: data.name,
      email: data.email,
      photo: data.picture,
      provider: provider,
      userRole: userRole
    });
  };

  return (
    <React.Fragment>
      <Dialog
        sx={{ borderRadius: '10px' }}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={props.signInEngineerPopup}
        onClose={handleClose}
      >
        <div className="dialog-text">
          <Box>
            <img
              sx={{ color: '#0000FF' }}
              src={GigEngineerLogo}
              alt="giglogo"
              fontSize="medium"
              width="200px"
              height="41px"
            />
          </Box>

          <Typography
            sx={{
              textAlign: 'center',
              color: '#5E5E5E',
              fontSize: '16px',
              fontWeight: '500px',
              marginTop: '15px'
            }}
          >
            Sign in as an Engineer
          </Typography>
          <div className="all-button-popup">
            <LoginSocialGoogle
              client_id={process.env.REACT_APP_GOOGLE_CLIENT_ID || ''}
              onLoginStart={() => {
                console.log('STARTING NOW!!!!!');
              }}
              redirect_uri={'/'}
              scope="openid profile email"
              discoveryDocs="claims_supported"
              access_type="offline"
              onResolve={(resp) => {
                handleGoogleLogin(resp, 'engineer');
              }}
              onReject={(err) => {
                console.error(err);
              }}
            >
              <Button className="google-btn">
                <div className="contin-with-google">
                  <img
                    className="logo-logo"
                    src={GoogleLogoIcon}
                    alt="googlelogoicon"
                    fontSize="medium"
                    width="75%"
                  />
                </div>
                <div className="continu-with-google-text">Continue with Google</div>
              </Button>
            </LoginSocialGoogle>
            <Button className="google-btn" sx={{ marginLeft: '15px' }} onClick={linkedInLogin}>
              <div className="contin-with-google-link">
                <img
                  className="logo-logo-linkedin"
                  src={LinkedinLogoSignIn}
                  alt="linkdinicon"
                  fontSize="medium"
                  width="71%"
                />
              </div>
              <div className="continu-with-google-text-link">Continue with Linkedin</div>
            </Button>
          </div>
          <p className="text-or">Or</p>
          <Formik
            initialValues={{
              emailId: '',
              password: ''
            }}
            enableReinitialize={true}
            validationSchema={signInScreenValidationSchema}
            onSubmit={(values) => {
              const obj = { ...values };
              signInReq(obj, props.setAuthSignError);
            }}
          >
            {({ values, touched, errors, handleChange, handleBlur, setFieldValue }) => (
              <Form noValidate autoComplete="off">
                <TextField
                  margin="normal"
                  className="textfild-width"
                  id="emailId"
                  name="emailId"
                  label="Email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.emailId && Boolean(errors.emailId)}
                  helperText={touched.emailId && errors.emailId}
                  value={values.emailId}
                  size="small"
                />
                <TextField
                  margin="normal"
                  className="textfild-width"
                  type={showPassword ? 'text' : 'password'}
                  id="password"
                  name="password"
                  label="Password"
                  autoComplete="current-password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.password && Boolean(errors.password)}
                  helperText={touched.password && errors.password}
                  value={values.password}
                  variant="outlined"
                  size="small"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
                <Button
                  type="submit"
                  className="signin-gig"
                  variant="contained"
                  sx={{ mt: 3, mb: 2, paddingTop: '10px', paddingBottom: '10px' }}
                >
                  SIGN IN
                </Button>
              </Form>
            )}
          </Formik>
          <div className="forget-btn-signin-btn">
            <div
              className="forget-pas-text"
              onClick={() => {
                props.modalEngineerClose();
                props.modalForgetEngineer();
              }}
            >
              Forgot password?
            </div>
            <div className="text-sign-1">
              <div className="text-signin-text-1">
                Want to create a new account instead?&nbsp;&nbsp;
              </div>
              <Box
                sx={{
                  fontSize: '11px',
                  color: '#1976d2',
                  fontWeight: '700',
                  cursor: 'pointer'
                }}
                onClick={() => {
                  props.modalEngineerClose();
                  setTimeout(() => {
                    props.modalEngineerSignUpOpen();
                  }, 500);
                }}
              >
                Sign up
              </Box>
            </div>
          </div>
          <p className="simpl-text">
            By continuing, you agree to accept our Privacy Policy & Terms of Service.
          </p>
          <Box
            sx={{
              mt: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%'
            }}
          >
            <List sx={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
              <ListItem disablePadding>
                <ListItemText
                  primary={
                    <Typography
                      sx={{
                        fontSize: '13px',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden'
                      }}
                      onClick={() => {
                        history.push(`/termsconditions`);
                      }}
                    >
                      <Link className="terms-text" href="" underline="hover">
                        Terms and Conditions
                      </Link>
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem disablePadding>
                <ListItemText
                  primary={
                    <Typography
                      sx={{
                        fontSize: '13px',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden'
                      }}
                      onClick={() => {
                        history.push(`/privacypolicy`);
                      }}
                    >
                      <Link className="privacy-text" href="" underline="hover">
                        Privacy Policy
                      </Link>
                    </Typography>
                  }
                />
              </ListItem>
            </List>
          </Box>
          <p className="copyrigth-text">Copyright © Gig Engineer 2024.</p>
          <br />
        </div>
        <></>
      </Dialog>
      <Dialog
        sx={{ borderRadius: '10px' }}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={props.signUpModalOpen}
        onClose={handleClose}
      >
        <div className="signup-dialog-text">
          <Box sx={{ textAlign: 'center' }}>
            <img
              sx={{ color: '#0000FF' }}
              src={GigEngineerLogo}
              alt="giglogo"
              fontSize="medium"
              width="200px"
              height="41px"
            />
          </Box>

          <Typography
            sx={{
              textAlign: 'center',
              color: '#5E5E5E',
              fontSize: '16px',
              fontWeight: '500px',
              marginTop: '10px'
            }}
          >
            Create an account as an Engineer
          </Typography>
          <div className="bnt-signup-engineer-text">
            <LoginSocialGoogle
              client_id={process.env.REACT_APP_GOOGLE_CLIENT_ID || ''}
              onLoginStart={() => {
                console.log('STARTING NOW!!!!!');
              }}
              redirect_uri={'/'}
              scope="openid profile email"
              discoveryDocs="claims_supported"
              access_type="offline"
              onResolve={(resp) => {
                handleGoogleLogin(resp, 'engineer');
              }}
              onReject={(err) => {
                console.error(err);
              }}
            >
              <Button className="google-btn">
                <div className="logo-google-work">
                  <img
                    className="logo-logo"
                    src={GoogleLogoIcon}
                    alt="googlelogoicon"
                    fontSize="medivum"
                    width="75%"
                  />
                </div>
                <div className="continu-with-google-text">Continue with Google</div>
              </Button>
            </LoginSocialGoogle>
            <Button className="google-btn" sx={{ marginLeft: '15px' }} onClick={linkedInLogin}>
              <div className="logo-link-din-conti">
                <img
                  className="logo-logo-linkedin"
                  src={LinkedinLogoSignIn}
                  alt="linkdinicon"
                  fontSize="medium"
                  width="70%"
                />
              </div>
              <div className="continu-with-google-text-link">Continue with Linkedin</div>
            </Button>
          </div>
          {!_isEmpty(_get(props, 'auth.signError', {})) && (
            <Alert severity="error" className="h-margin-bottom-16">
              {_get(props, 'auth.signError.message', 'some thing went wrong please try again')}
            </Alert>
          )}

          <p className="text-or">Or</p>
          <Formik
            initialValues={{
              fullName: '',
              companyName: '',
              emailId: '',
              password: '',
              signUpAs: '',
              promoOpted: ''
            }}
            enableReinitialize={true}
            validationSchema={signUpScreenValidationSchema}
            onSubmit={async (values) => {
              const obj = { ...values };
              obj['userType'] = 'engineer';
              obj['promoOpted'] = promoted;
              setEmail(obj.emailId);
              if (token) {
                let response = await signUpReq(obj, props.setAuthSignError);
                if (response && response.status === 200);
              }
            }}
          >
            {({ values, touched, errors, handleChange, handleBlur }) => (
              <Form noValidate autoComplete="off">
                <TextField
                  margin="normal"
                  fullWidth
                  id="fullName"
                  name="fullName"
                  label="Full Name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.fullName && Boolean(errors.fullName)}
                  helperText={touched.fullName && errors.fullName}
                  value={values.fullName}
                  variant="outlined"
                  size="small"
                />
                <TextField
                  margin="normal"
                  fullWidth
                  id="emailId"
                  name="emailId"
                  label="Email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.emailId && Boolean(errors.emailId)}
                  helperText={touched.emailId && errors.emailId}
                  value={values.emailId}
                  variant="outlined"
                  size="small"
                />
                <TextField
                  margin="normal"
                  fullWidth
                  type={showPassword ? 'text' : 'password'}
                  id="password"
                  name="password"
                  label="Password"
                  autoComplete="current-password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.password && Boolean(errors.password)}
                  helperText={touched.password && errors.password}
                  value={values.password}
                  variant="outlined"
                  size="small"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />

                <p className="text-1">
                  Do you wish to receive our newsletters and promotional content?
                </p>
                <Box sx={{ marginTop: '-12px' }}>
                  <RadioGroup row aria-label="signUpAs" name="row-radio-buttons-group" size="small">
                    <FormControlLabel
                      value="Yes"
                      control={
                        <Radio
                          onChange={(e) => {
                            setPromoted('yes');
                          }}
                        />
                      }
                      label="Yes"
                      size="small"
                    />
                    <FormControlLabel
                      value="No"
                      control={
                        <Radio
                          onChange={(e) => {
                            setPromoted('no');
                          }}
                        />
                      }
                      label="No"
                      size="small"
                    />
                  </RadioGroup>
                </Box>
                <div className="recapcha-engineer-login">
                  <ReCAPTCHA
                    sitekey="6LcuRXkkAAAAAL3IChCb5HG95esZx8V655kBpwyS"
                    onChange={onChangee}
                  />
                </div>

                <SignUpBusiness>
                  By continuing, you agree to accept our Privacy Policy & Terms of Service.
                </SignUpBusiness>
                <Button
                  type="submit"
                  className="signup-1"
                  fullWidth
                  variant="contained"
                  sx={{ paddingTop: '10px', paddingBottom: '10px' }}
                >
                  SIGN UP
                </Button>

                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginTop: '15px',
                    marginLeft: '2px'
                  }}
                >
                  <SignUpMsg>
                    <Typography
                      sx={{
                        color: '#757575',
                        fontSize: '11px',
                        fontWeight: '500',
                        fontFamily: 'Poppins',
                        display: 'flex'
                      }}
                    >
                      Already have an account?&nbsp;&nbsp;
                      <Box
                        sx={{
                          fontSize: '11px',
                          color: '#1976d2',
                          fontWeight: '700',
                          cursor: 'pointer'
                        }}
                        onClick={() => {
                          props.modalEngineerSignUpClose();
                          setTimeout(() => {
                            props.modalEngineerOpen();
                          }, 500);
                        }}
                      >
                        Sign in
                      </Box>
                    </Typography>
                  </SignUpMsg>
                </Box>
              </Form>
            )}
          </Formik>
          <Box
            sx={{
              mt: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%'
            }}
          >
            <List sx={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
              <ListItem disablePadding>
                <ListItemText
                  primary={
                    <Typography
                      sx={{
                        fontSize: '11px',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden'
                      }}
                      onClick={() => {
                        history.push(`/termsconditions`);
                      }}
                    >
                      <Link className="terms-text" href="" underline="hover">
                        Terms and Conditions
                      </Link>
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem disablePadding>
                <ListItemText
                  primary={
                    <Typography
                      sx={{
                        fontSize: '11px',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden'
                      }}
                      onClick={() => {
                        history.push(`/privacypolicy`);
                      }}
                    >
                      <Link className="privacy-text" href="" underline="hover">
                        Privacy Policy
                      </Link>
                    </Typography>
                  }
                />
              </ListItem>
            </List>
          </Box>
          <p className="copyrigth-text-1">Copyright © Gig Engineer 2024.</p>
        </div>
        <></>
      </Dialog>
      <Dialog
        sx={{ borderRadius: '10px' }}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        onClose={handleClose}
      >
        <Box
          sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px' }}
        >
          <Typography sx={{ color: '#008000' }}>
            <VerifiedUserOutlinedIcon fontSize="large" />
          </Typography>
          <br />
          <Typography>
            A verification email has been sent to {email}. Please check your inbox for the mail and
            click the verification link to activate your account. If you did not receive the link,
            please check your spam folders or contact us if you have any queries.
          </Typography>
          <Button
            color="inherit"
            onClick={() => {
              setOpenSignUpModal(false);
              setOpen(true);
            }}
          >
            Click to dismiss
          </Button>
        </Box>
      </Dialog>
      <Dialog
        sx={{ borderRadius: '10px' }}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={props.forgetSignIn}
        onClose={handleClose}
      >
        <DialogCss>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              padding: '40px 30px',
              borderRadius: '10px'
            }}
          >
            <Box
              sx={{
                textAlign: 'center',
                fontSize: '22px',
                fontWeight: '500',
                marginBottom: '30px',
                color: '#1565c0'
              }}
            >
              FORGOT PASSWORD?
            </Box>
            <Box
              sx={{ textAlign: 'center', fontSize: '14px', marginBottom: '20px', color: '#696969' }}
            >
              Enter the email address associated with your account and we’ll send you a link to
              reset your password.
            </Box>
            <Formik
              initialValues={{ email: '' }}
              validationSchema={forgotPasswordScreenValidationSchema}
            >
              {({ values, touched, errors, handleChange, handleBlur }) => (
                <Form
                noValidate
                  autoComplete="off"
                  onSubmit={async (e) => {
                    e.preventDefault();
                    let data = {
                      emailId: values.email.trim().toLowerCase()
                    };

                    if (values.email === '' || values.email === null) {
                      return setEmailMsg('Email is required!');
                    } else {
                      let isEmailValid = isValidEmail(values.email);
                      if (isEmailValid) {
                        setEmailMsg('');
                      } else {
                        return setEmailMsg('Enter Valid email!');
                      }
                    }
                    let response = await forgotPassword(data, props.setAuthSignError);
                    if (response && response.status === 200) {
                      setIsResetLink(true);
                    } else {
                      setIsResetLink(false);
                    }
                  }}
                >
                  <TextField
                    fullWidth
                    name="email"
                    label="Email Address"
                    onChange={(e) => {
                      setEmailMsg('');
                      handleChange(e);
                    }}
                    value={values.email}
                    variant="outlined"
                    error={emailMsg !== ''}
                    helperText={emailMsg !== '' ? emailMsg : ''}
                  />
                  <Box sx={{ marginTop: '20px' }}>
                    {isResetLink ? (
                      <h4>Link has been sent to your email.</h4>
                    ) : (
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ paddingTop: '10px', paddingBottom: '10px' }}
                      >
                        SEND RESET LINK
                      </Button>
                    )}
                  </Box>
                </Form>
              )}
            </Formik>
            <br />
            <SignInMsg>
              Don’t have an account? &nbsp;
              <Link
                underline="hover"
                color="#FF8008"
                className="h-color-orange"
                onClick={() => {
                  props.modalForgetClose();
                  props.modalEngineerSignUpOpen();
                }}
                sx={{ cursor: 'pointer' }}
              >
                Sign Up
              </Link>
            </SignInMsg>
          </Box>
        </DialogCss>
        <br />
      </Dialog>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setAuthSignError: (details) => dispatch(setAuthSignError(details)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FindGigPopup);
