import React, { useState } from 'react';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _toNumber from 'lodash/toNumber';
import _last from 'lodash/last';
import { PaddedFooter } from '../../app.styles';
import { connect } from 'react-redux';
import '../../gigOwner/gigDashboard/GigDashboard.css';
import './index.scss';
import Footer from '../../../components/footer1/footer';
import ReactStars from "react-rating-stars-component";
import { useHistory, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { addNewFeedbackApi, getGigsById } from '../../../services/gig';
import { toast } from 'react-toastify';

const ReviewGig = (props) => {
    const history = useHistory();
    const location = useLocation();
    // console.log(location)
    const [selectedReason, setselectedReason] = useState("Work completed and final invoice paid");
    const [howLikely, sethowLikely] = useState();
    const [selectedGig, setselectedGig] = useState(undefined)
    const [specification, setspecification] = useState("")
    const [feedbackText, setfeedbackText] = useState("")

    useEffect(() => {
        // console.log(location.pathname.split("/")[location.pathname.split("/").length-1])
        getGigsById(location.pathname.split("/")[location.pathname.split("/").length-1]).then((e)=>{
            // console.log(e)
            setselectedGig(e)
        })
    }, [])
    

    const reasonList = [
        { "reason": "Work completed and final invoice paid" },
        { "reason": "Project suspended or terminated" },
        { "reason": "I want to hire someone else" },
        { "reason": "Other" },
    ];

    // const publicFeedbackTypes = [
    //     { "name": "Soft Skills", value: 0 },
    //     { "name": "Quality of work", value: 0 },
    //     { "name": "Technical Knowledge", value: 0 },
    //     { "name": "Adherence to schedule", value: 0 },
    //     { "name": "Communication", value: 0 },
    //     { "name": "Co-operation", value: 0 },
    //     { "name": "Value for money", value: 0 },
    // ]

    const [publicFeedbackTypes, setpublicFeedbackTypes] = useState([
        { "name": "Soft Skills", value: 0 },
        { "name": "Quality of work", value: 0 },
        { "name": "Technical Knowledge", value: 0 },
        { "name": "Adherence to schedule", value: 0 },
        { "name": "Communication", value: 0 },
        { "name": "Co-operation", value: 0 },
        { "name": "Value for money", value: 0 },
    ])

    const handleChangeRating = (name, rating) => {
        setpublicFeedbackTypes((prevFeedbackTypes) => {
          const updatedFeedbackTypes = prevFeedbackTypes.map((feedbackType) => {
            if (feedbackType.name === name) {
              return { ...feedbackType, value: Number(rating) };
            }
            return feedbackType;
          });
          return updatedFeedbackTypes;
        });
    };

    const handleSubmit = () => {
        let submitData = [];
        publicFeedbackTypes.map((item,index)=>{
            submitData.push({ 
                feedbackValue: item.name,
                feedbackRating: String(item.value)
            })
        })
        addNewFeedbackApi({
            // _id: location.pathname.split("/")[location.pathname.split("/").length-1],
            privateFeedback: {
                reasonForClosing: selectedReason,
                specification: selectedReason === "Other" ? specification : "",
                recommendation: howLikely
            },
            publicFeedback: {
                feedbackStarData: submitData,
                feedbackTextData: feedbackText
            },
            recieverId: !!selectedGig&&!!selectedGig.gig&&selectedGig?.gig?.hireData?selectedGig?.gig?.hireData?.userId:"-",
            giverId: !!selectedGig&&!!selectedGig.gig?selectedGig?.gig?.userId:"",
            gigId: !!selectedGig&&!!selectedGig.gig?selectedGig?.gig?._id:"",
            isPublished: false
        }).then((e)=>{
            if(e.status<300){
                toast.success("Feedback submitted successfully!");
                setTimeout(() => {
                    history.go(-1)
                }, 1000);
            }
            else{
                toast.warn(e.message);
            }
        }).catch((err)=>{console.error(err)})
    }

    return (
        <React.Fragment>
            <div className='review-gig-form-wrapper' style={{ paddingLeft: `${!!props.auth && !!props.auth.isDrawerOpen && props.auth.isDrawerOpen == true ? "276px" : "0px"}` }}>
                <div className='action-btns-top-row'>
                    <button className='back-btn-top' onClick={()=>history.goBack()}>{'Back'}</button>
                    <text className='submit-btn-txt'>{'Submit feedback'}</text>
                </div>
                <div className='description-container'>
                    <div className='eng-name-title'>{'Gig Engineer'}</div>
                    <div className='eng-name'>{!!selectedGig&&!!selectedGig.gig&&selectedGig?.gig?.hireData?selectedGig?.gig?.hireData?.engineerName:"-"}</div>
                    <div className='eng-name-title project-title-heading-padding'>{'Project Title'}</div>
                    <div className='eng-name project-title-txt'>{!!selectedGig&&!!selectedGig.gig?selectedGig?.gig?.titleAndSkills?.projectTitle:"-"}</div>
                </div>
                <div className='description-container prvt-feedback-container'>
                    <div className='eng-name-title prvt-feedback-padding'>{'Private Feedback'}</div>
                    <div className='eng-name project-title-txt prvt-feedback-padding prvt-feedback-desc-btm-padding'>{'This feedback will be kept anonymous and not shared publicly'}</div>
                    <div className='spacer' />
                    <div className='eng-name project-title-txt prvt-feedback-padding reason-for-closing-pvt-padding'>{'Reason for closing gig'}</div>
                    <select className='reason-for-closing-select' name="reasonForClosing" value={selectedReason} onChange={(e) => setselectedReason(e.target.value)}>
                        {reasonList.map((item, index) => (<option value={item.reason}>{item.reason}</option>))}
                    </select>
                    {selectedReason === "Other" && <textarea value={specification} onChange={(e)=>{setspecification(e.target.value)}} className='please-specify-textarea' rows={6} placeholder='Please Specify' />}
                    <div className='eng-name project-title-txt prvt-feedback-padding how-likely-txt-padding'>{'How likely are you to recommend this engineer to a friend or colleague?'}</div>
                    <div className='prvt-feedback-padding how-likely-heading-text'>
                        <div className='eng-name project-title-txt not-likely-space'>{'Not at all likely'}</div>
                        <div className='eng-name project-title-txt'>{'Extremely Likely'}</div>
                    </div>
                    <div className='how-likely-row'>
                        {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((val, idx) => <div className='how-likely-item' onClick={() => sethowLikely(val)}>
                            <input type="radio" name="howLikely" value={val} checked={howLikely === val} style={{ cursor: 'pointer' }} onChange={(e) => sethowLikely(parseInt(e.target.value))} />
                            <div className='radio-btn-txt'>{val}</div>
                        </div>)}
                    </div>
                </div>
                <div className='description-container public-feedback-container'>
                    <div className='eng-name-title public-feedback-padding pbl-fdbk-heading-txt'>{'Public Feedback'}</div>
                    <div className='eng-name project-title-txt public-feedback-padding pbl-feedback-desc-btm-padding'>{'This feedback would be shared to the engineer.'}</div>
                    <div className='spacer' />
                    <div className='eng-name project-title-txt feedback-to-eng-txt'>{'Feedback to engineer'}</div>
                    {publicFeedbackTypes.map((val, idx) => <div className='feedback-eng-row'>
                        <ReactStars classNames={'stars'} onChange={(e)=>{handleChangeRating(val.name,e)}} count={5} activeColor="#ffd700" size={25} />
                        <div className='feedback-cat-name'>{val.name}</div>
                    </div>)}
                    <div className='total-txt'>{`Total : ${(publicFeedbackTypes.reduce((sum, feedbackType) => sum + feedbackType.value, 0) / Math.max(publicFeedbackTypes.filter(feedbackType => feedbackType.value > 0).length, 1)).toFixed(2)}`}</div>
                    <div className='share-exp-txt'>{'Share your experience with this engineer to the Gig Engineer Community'}</div>
                    <textarea value={feedbackText} onChange={(e)=>{setfeedbackText(e.target.value)}} className='share-exp-textarea' rows={7} />
                </div>
                <div className='description-container action-btns-btm-container'>
                    <button className='submit-btn' onClick={()=>{handleSubmit()}}>{'SUBMIT'}</button>
                    <button className='cancel-btn' onClick={()=>history.goBack()}>{'CANCEL'}</button>
                </div>
            </div>
            <PaddedFooter style={{ paddingLeft: `${!!props.auth && !!props.auth.isDrawerOpen && props.auth.isDrawerOpen == true ? "240px" : "0px"}` }}>
                <Footer />
            </PaddedFooter>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        // newGig: state.newGig,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        // setNewGigDetails: (data) => dispatch(setNewGigDetails(data))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReviewGig);