import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import { Grid, Drawer, Toolbar, Avatar, Button, Box } from '@mui/material';
import AccountDeactivate from '../../../../components/AccountDeactivate/AccountDeactivate';
import { getProfileOwnerDetByIdApi } from '../../../../services/owners';
import Typography from '@mui/material/Typography';
import PageLoader from '../../../../components/Loaders/PageLoader';
import { PaddedBox, CustomRow, TypographyBold, PaddedFooter } from '../../../app.styles';
import { ProfileNavContainer, ProfileTitle, ProfileSubHeadingTop } from './gigOwner.styles';
import DP from '../../../../assests/2.jpg';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { connect } from 'react-redux';
import Footer from '../../../../components/footer1/footer';

function BasicBreadcrumbs() {
  const history = useHistory();
  return (
    <Box role="presentation">
      <Breadcrumbs aria-label="breadcrumb">
        <Link
          underline="hover"
          component="button"
          onClick={() => history.push('/admin/business/profile')}
        >
          Owner List
        </Link>
        <Typography color="text.primary">Owner Details</Typography>
      </Breadcrumbs>
    </Box>
  );
}

const GigOwnerDetails = (props) => {
  const drawerWidth = 240;
  const [profileDet, setProfileDet] = useState({});
  const { id } = useParams();
  const history = useHistory();

  useEffect(() => {
    async function someasync() {
      const details = await getProfileOwnerDetByIdApi(id);
      setProfileDet(details);
    }
    someasync();
  }, []);

  return _isEmpty(profileDet) ? (
    <div className='gig-details-page-loader'>
     <PageLoader />
    </div>
  ) : (
    <Grid container spacing={2}>
      {/* Start of Left Nav */}
      <Grid
        container
        item
        xs="auto"
        sx={{
          display: { xs: 'none', lg: 'block' },
          padding: '5px'
        }}
      >
        <Drawer
          variant="permanent"
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' }
          }}
        >
          <Toolbar />
          <ProfileNavContainer>
            <Toolbar />
            <Grid container item xs={12} justifyContent="center">
              <Avatar
                alt="image"
                src={_get(profileDet, 'companyDetails.profilePic') || DP}
                sx={{ width: 150, height: 150 }}
              />
            </Grid>
            <Toolbar />
            <ProfileTitle>
              {_get(profileDet, 'companyDetails.companyName') || 'Not Disclosed'}
            </ProfileTitle>
          </ProfileNavContainer>
        </Drawer>
      </Grid>

      {/* End of Left Nav */}

      <Grid container item xs>
        <PaddedBox
          style={{
            paddingLeft: `${
              !!props.auth && !!props.auth.isDrawerOpen && props.auth.isDrawerOpen == true
                ? '240px'
                : '0px'
            }`
          }}
        >
          {BasicBreadcrumbs()}

          <Card sx={{ marginTop: '20px', marginBottom: '40px' }}>
            <CardContent>
              <Box
                sx={{
                  fontSize: '1.25rem',
                  color: '#0140AA',
                  lineHeight: '1.6',
                  letterSpacing: '0.0075em',
                  marginBottom: '0.35em',
                  fontWeight: '500'
                }}
              >
                Representative Details
              </Box>
              <Grid item container rowSpacing={2} className="h-padding-top-16">
                <Grid item xs={12} md={12} lg={12}>
                  <ProfileSubHeadingTop>NAME</ProfileSubHeadingTop>
                  <Box>{_get(profileDet, 'basicInfo.name', 'Not Disclosed')}</Box>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <ProfileSubHeadingTop>EMAIL ADDRESS</ProfileSubHeadingTop>
                  <Box>{_get(profileDet, 'basicInfo.emailId', 'Not Disclosed')}</Box>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <ProfileSubHeadingTop>MOBILE NUMBER</ProfileSubHeadingTop>
                  <Box>{_get(profileDet, 'basicInfo.mobileNumber', 'Not Disclosed')}</Box>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <ProfileSubHeadingTop>TITLE</ProfileSubHeadingTop>
                  <Box>{_get(profileDet, 'basicInfo.designation', 'Not Disclosed')}</Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Card sx={{ marginTop: '20px', marginBottom: '40px' }}>
            <CardContent>
              <Box
                sx={{
                  fontSize: '1.25rem',
                  color: '#0140AA',
                  lineHeight: '1.6',
                  letterSpacing: '0.0075em',
                  marginBottom: '0.35em',
                  fontWeight: '500'
                }}
              >
                Company Details
              </Box>
              <Grid container spacing={1} className="h-padding-top-16" alignItems="center">
                <Grid item xs={12} md={4} lg={4}>
                  <ProfileSubHeadingTop>COMPANY NAME</ProfileSubHeadingTop>
                  <Box>
                    {_get(profileDet, 'companyDetails.companyName') == '' ||
                    _get(profileDet, 'companyDetails.companyName') == null
                      ? 'Not disclosed'
                      : _get(profileDet, 'companyDetails.companyName')}
                  </Box>
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                  <ProfileSubHeadingTop>INDUSTRY TYPE</ProfileSubHeadingTop>
                  <Box>
                    {_get(profileDet, 'companyDetails.industryType') == '' ||
                    _get(profileDet, 'companyDetails.industryType') == null
                      ? 'Not disclosed'
                      : _get(profileDet, 'companyDetails.industryType')}
                  </Box>
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                  <ProfileSubHeadingTop>REGISTRATION NUMBER</ProfileSubHeadingTop>
                  <Box>
                    {_get(profileDet, 'companyDetails.registerNumber') == '' ||
                    _get(profileDet, 'companyDetails.registerNumber') == null
                      ? 'Not disclosed'
                      : _get(profileDet, 'companyDetails.registerNumber')}
                  </Box>
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                  <ProfileSubHeadingTop>TIN NUMBER</ProfileSubHeadingTop>
                  <Box>
                    {_get(profileDet, 'companyDetails.tinNumber') == '' ||
                    _get(profileDet, 'companyDetails.tinNumber') == null
                      ? 'Not disclosed'
                      : _get(profileDet, 'companyDetails.tinNumber')}
                  </Box>
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                  <ProfileSubHeadingTop>LINKEDIN PAGE URL</ProfileSubHeadingTop>
                  <Box>
                    {_get(profileDet, 'companyDetails.linkedinUrl') == '' ||
                    _get(profileDet, 'companyDetails.linkedinUrl') == null
                      ? 'Not disclosed'
                      : _get(profileDet, 'companyDetails.linkedinUrl')}
                  </Box>
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                  <ProfileSubHeadingTop>WEBSITE URL </ProfileSubHeadingTop>
                  <Box>
                    {_get(profileDet, 'companyDetails.websiteUrl') == '' ||
                    _get(profileDet, 'companyDetails.websiteUrl') == null
                      ? 'Not disclosed'
                      : _get(profileDet, 'companyDetails.websiteUrl')}
                  </Box>
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                  <ProfileSubHeadingTop>CITY</ProfileSubHeadingTop>
                  <Box>
                    {_get(profileDet, 'companyDetails.city') == '' ||
                    _get(profileDet, 'companyDetails.city') == null
                      ? 'Not disclosed'
                      : _get(profileDet, 'companyDetails.city')}
                  </Box>
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                  <ProfileSubHeadingTop>PROVINCE</ProfileSubHeadingTop>
                  <Box>
                    {_get(profileDet, 'companyDetails.state') == '' ||
                    _get(profileDet, 'companyDetails.state') == null
                      ? 'Not disclosed'
                      : _get(profileDet, 'companyDetails.state')}
                  </Box>
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                  <ProfileSubHeadingTop>COUNTRY</ProfileSubHeadingTop>
                  <Box>
                    {_get(profileDet, 'companyDetails.country') == '' ||
                    _get(profileDet, 'companyDetails.country') == null
                      ? 'Not disclosed'
                      : _get(profileDet, 'companyDetails.country')}
                  </Box>
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                  <ProfileSubHeadingTop>AREACODE</ProfileSubHeadingTop>
                  <Box>
                    {_get(profileDet, 'companyDetails.areaCode') == '' ||
                    _get(profileDet, 'companyDetails.areaCode') == null
                      ? 'Not disclosed'
                      : _get(profileDet, 'companyDetails.areaCode')}
                  </Box>
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                  <ProfileSubHeadingTop>ADDRESS</ProfileSubHeadingTop>
                  <Box>
                    {_get(profileDet, 'companyDetails.physicalAddress') == '' ||
                    _get(profileDet, 'companyDetails.physicalAddress') == null
                      ? 'Not disclosed'
                      : _get(profileDet, 'companyDetails.physicalAddress')}
                  </Box>
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                  <ProfileSubHeadingTop>COMPANY DESCRIPTION</ProfileSubHeadingTop>
                  <Box>
                    {_get(profileDet, 'companyDetails.description') == '' ||
                    _get(profileDet, 'companyDetails.description') == null
                      ? 'Not disclosed'
                      : _get(profileDet, 'companyDetails.description')}
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <CustomRow container>
            <Typography
              variant="h6"
              component="h6"
              color="pColor.main"
              sx={{ fontWeight: 'Medium', textTransform: 'capitalize', fontSize: '22px' }}
            >
              <AccountDeactivate profileDet={profileDet} />
            </Typography>
            <Button
              variant="contained"
              className="primary-Main-Btn"
              onClick={() => {
                history.push(`/admin/owner/details/${id}/allgigs`);
              }}
            >
              Show All Gigs
            </Button>
          </CustomRow>
        </PaddedBox>
        <PaddedFooter style={{ paddingLeft: `${!!props.auth && !!props.auth.isDrawerOpen && props.auth.isDrawerOpen == true ? "200px" : "0px"}` }}>
        <Footer />
      </PaddedFooter>
      </Grid>
    </Grid>
  );
};
const mapStateToProps = (state) => {
  return {
    auth: state.auth
  };
};
export default connect(mapStateToProps)(GigOwnerDetails);
