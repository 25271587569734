import _get from 'lodash/get';
import apiService from './apiServices';

export const createUpdateInvoice = async (data) => {
    const request = {
      data,
      method: 'post',
      url: '/invoice'
    };
    const response = await apiService(request);
    if (response && response.status === 200) {
      // sucess action
      return response.data;
    }
};

export const fetchInvoiceByStatus = async (data) => {
    const request = {
      data,
      method: 'post',
      url: '/invoice/all'
    };
    const response = await apiService(request);
    if (response && response.status === 200) {
      // sucess action
      return response.data;
    }
};

export const fetchInvoiceListEngineer = async (data) => {
    const request = {
      data,
      method: 'post',
      url: '/invoice/engineer/all'
    };
    const response = await apiService(request);
    if (response && response.status === 200) {
      // sucess action
      return response.data;
    }
};

export const fetchInvoiceListBusiness = async (data) => {
    const request = {
      data,
      method: 'post',
      url: '/invoice/business/all'
    };
    const response = await apiService(request);
    if (response && response.status === 200) {
      // sucess action
      return response.data;
    }
};

export const fetchInvoiceById = async (data) => {
    const request = {
      method: 'get',
      url: `invoice/${data.id}`
    };
    const response = await apiService(request);
    if (response && response.status === 200) {
      // sucess action
      return response.data;
    }
};
