import { React } from 'react';
import { PaddedBox } from '../../containers/app.styles';
import Header from '../HomePage/Header';
import Footer from '../footer1/footer';
import "./TermsConditions.css";
import { connect } from 'react-redux';

const TermsConditions = (props) => {

    return (
        <div>
            <Header />
                <div className='heading-tc-wrapper'>
                    <h1>Gig Engineer Terms and Conditions</h1>
                </div>
                <div className='para'>
                    <p>Last updated 11 July 2022</p>
                </div>
                <div className='paras'>
                    <h3>Definitions:</h3>
                </div>
                <div className='paras1'>
                    <h5>"Account" means the account associated with your email address.</h5>
                    <h5>"Buyer" means a User that purchases Services from Sellers or identifies a Seller through the Gig Engineer Website. A User may be both a Buyer and a Seller under this agreement.</h5>
                    <h5>“Clients”, “Customers”, “Gig Owner”, “Project Owner” are individuals and/or businesses seeking to obtain professional services from Independent Professionals. </h5>
                    <h5>“Deliverable” means any product, item, service or project deliverable due by the Seller or Independent Professional to the Client in order to complete the scope of services for which he has been appointed for, agreed to undertake or been paid for either partially or fully as agreed in the contractual provisions between the Buyer and Seller.  </h5>
                    <h5>"Dispute Resolution Process" means the process to be followed by Buyers and Sellers to resolve a dispute in relation to a Project.</h5>
                    <h5>“Brokerage fee”, “Service Fee” or “Commission”, any payments made to Independent Professionals for services rendered to Clients.</h5>
                    <h5>"Gig Engineer", "We", "Our", "Company", "The Company" or "Us" means GIG ENGINEER PROPRIETARY LIMITED.</h5>
                    <h5>"Verified User” means a user who has been satisfactorily verified through Gig Engineer internal verification processes.</h5>
                    <h5>"Inactive Account" means a User Account that has not been logged into for a 6-month period, or other period determined by us from time to time.</h5>
                    <h5>"Intellectual Property Rights" means any and all intellectual property rights, existing worldwide and the subject matter of such rights, including: (a) patents, copyright, rights in circuit layouts (or similar rights), registered designs, registered and unregistered trademarks, and any right to have confidential information kept confidential; and (b) any application or right to apply for registration of any of the rights referred to in paragraph (a), whether or not such rights are registered or capable of being registered and whether existing under any laws, at common law or in equity.</h5>
                    <h5>"Payment" means a payment made by the Buyer for the provision of Seller Services under a User Contract and which will be released in accordance with the terms and conditions of this User Agreement.</h5>
                    <h5>"Project", “Gig”, "Listing" or “Posting” means a job offered or awarded by a Buyer via the Gig Engineer Website, which may include a Project or Gig listed by a Buyer, a Project awarded by a Buyer, a service bought by a Buyer from a Seller, and service awarded by a Buyer to a Seller as a result of a job posting hosted via the Website.</h5>
                    <h5>"Seller", “Independent Professional” or “Engineer” means a User that offers and provides services or identifies as a Seller through the Website. A User may be both a Buyer and a Seller under this agreement.</h5>
                    <h5>"Seller Services" means all services provided by a Seller or Independent Professional.</h5>
                    <h5>"User", "You", "Your", “Yourself”, “Third-party” means an individual who visits or uses the Website.</h5>
                    <h5>"User Contract", “User Agreement” means: (1) the Terms and Conditions comprising this agreement; (2) any other contractual provisions accepted by both the Seller and Buyer uploaded to the Gig Engineer Website, to the extent that such contractual provisions are not inconsistent with this User Agreement; (4) the Project terms as awarded and accepted on the Website, to the extent not inconsistent with this User Agreement; and (5) any other material incorporated by reference from time to time.</h5>
                    <h5>"Website", “Platform” means the website operated and maintained by Gig Engineer available at: gigengineer.io.</h5>
                </div>
                <div className='paras1'>
                    <h3>1.	General</h3>
                </div>
                <div className='paras1'>
                    <h5>By accessing or using the Website, you acknowledge that you have read, understand, and agree,
                        without limitation or qualification, to be bound by these Terms and Conditions.</h5>
                    <h5>GIG ENGINEER DOES NOT (NOR DOES IT INTEND TO) PROVIDE RECRUITMENT SERVICES, OR ACT IN ANY WAY
                        AS AN EMPLOYMENT AGENCY. </h5>
                    <h5>We bear no responsibility or liability for any recruitment services provided to you by any
                        third-party making use of our platform.</h5>
                    <h5>We may amend this User Agreement and any linked information from time to time by posting
                        amended terms on the Website without notice to you.</h5>
                    <h5>The Website is an online platform which enables the connection between clients and independent
                        professionals. Clients and independent professionals together are hereinafter referred to
                        as “Users.” </h5>
                    <h5>The Website is a platform for Users to connect and deliver work, but we are not responsible
                        for the performance of Users, nor do we have control over the quality, timing, legality,
                        failure to provide, or any other aspect whatsoever of services rendered for Clients by
                        independent professionals, nor of the integrity, responsibility or any of the actions
                        or omissions whatsoever </h5>
                    <h5>of any Users. We make no representations about the suitability, reliability, timeliness,
                        or accuracy of the services requested and provided by Users identified through the Website
                        whether in public, private, or offline interactions.</h5>
                    <h5>Users are required to register for an Account in order to buy or sell Services. The Website
                        enables Users to work together online to complete and pay for Projects and to use the products
                        and services we provide to facilitate this. We are not party to any contractual agreements
                        between Buyer and Seller in the online venue, we merely facilitate connections between the
                        parties.</h5>
                    <h5>We may, from time to time, and without notice, change or add to the Website or the information,
                        products or services described herein. Although we try our best to keep the Website up to date
                        as far as practically possible, we are not liable to you or anyone else if any error occurs
                        in the information provided on the Website or if that information is not current.</h5>
                </div>
                <div className='paras1'>
                    <h3>2. Scope</h3>
                    <h5>Before using the Website, you are expected to familiarise yourself with the whole of this User
                        Agreement, the Website policies and all further linked information.</h5>
                    <h5>You are required to read and accept all of the terms in, and linked to, this User Agreement,
                        the Gig Engineer Privacy Policy and all Website policies as applicable. By accepting this
                        User Agreement as a pre-requisite to access the Gig Engineer Website, you agree that this
                        User Agreement will apply whenever you use the Website, or when you use the tools, products
                        or services we make available to our users to interact with the Website. </h5>
                </div>
                <div className='paras1'>
                    <h3>3.	Eligibility</h3>
                    <h5>You will not use the Website if you:</h5>
                    <h5>1.	are not able to form legally binding contracts;</h5>
                    <h5>2.	are under the age of 16 years old;</h5>
                    <h5>3.	a person barred from receiving and rendering services under the laws of South Africa or
                        other applicable jurisdictions;</h5>
                    <h5>4.	are suspended from using the Website; </h5>
                    <h5>5.	have a criminal record; or</h5>
                    <h5>6.	do not hold a valid email address.</h5>
                    <h5>All free user accounts are associated with individuals. Login credentials should not be shared
                        by users with others. The individual associated with the account will be held responsible for
                        all actions taken by the account, without limitation.</h5>
                    <h5>Users may provide a business name or a company name, which is associated with the User's Account.
                        Users acknowledge and agree that where a business name or company name is associated with their
                        Account, this User Agreement is a contract with the User as an individual (not the business
                        or company) and Users remain solely responsible for all activity undertaken in respect of
                        their Account.</h5>
                    <h5>A company, corporation, trust, partnership or other non-individual corporate entity may be
                        a User subject to an eligible corporate account which pays any applicable subscriptions defined
                        by the website.</h5>
                    <h5>We may, at our absolute discretion, refuse to register any person or entity as a User.</h5>
                    <h5>You cannot transfer or assign any rights or obligations you have under this agreement without
                        prior written consent.</h5>
                </div>
                <div className='paras1'>
                    <h3>4.	Using Gig Engineer</h3>
                    <h5>In using the Website, the user shall: (i) use its best endeavours to promote the interests of Gig
                        Engineer and shall not do anything that may damage or negatively affect our image or that of the
                        Website; (ii) comply with all applicable legislation and shall not at any time act in contravention
                        of any South African law; (iii) shall under no circumstances, at any time engage clients other than
                        through the Website, failure to comply with this provision may result in the user’s profile being
                        suspended or removed from the Website; (iv) it is the user’s responsibility to agree to the terms
                        of the services to be rendered (including but not limited to the scope of services to be rendered,
                        working hours, special conditions and fees) and to enter into an appropriate agreement with such
                        parties (Clients) for such services. For the avoidance of doubt, we shall not be a party to such
                        agreement and shall not in any way accept any obligations or liability under such agreement;
                        (v) in the event that the user fails to comply with the obligations, then, without prejudice
                        to our rights and remedies at law or in terms of these Terms and Conditions, we will be
                        entitled to claim compensation and the user will be liable for all losses, damages and costs
                        suffered or incurred by us as a result thereof; and (vi) the user of the Website does
                        so at his or her own risk. We shall not be liable to any independent professional using
                        the Website for any non-payment by a client or any additional costs or fees which may be
                        incurred by the independent professional in the course of rendering services to the
                        client.</h5>
                    <h5>While using the Website, you will not attempt to or otherwise do any of the following:</h5>
                    <h5>1.	post content or items in inappropriate categories or areas on our Websites and services;</h5>
                    <h5>2.	infringe any laws, third party rights or our policies;</h5>
                    <h5>3.	fail to deliver payment for services delivered to you;</h5>
                    <h5>4.	fail to deliver Seller Services purchased from you;</h5>
                    <h5>5.	circumvent or manipulate our fee structure, the billing process, or fees owed to Gig Engineer;</h5>
                    <h5>6.	post false, inaccurate, misleading, deceptive, defamatory or offensive content (including personal information);</h5>
                    <h5>7.	take any action that may undermine the feedback or reputation system (such as displaying, importing or exporting feedback information or using it for purposes unrelated to the Website);</h5>
                    <h5>8.	transfer your Gig Engineer account (including feedback) and Username to another party without our consent;</h5>
                    <h5>9.	distribute or post spam, unsolicited, or bulk electronic communications, chain letters, or pyramid schemes;</h5>
                    <h5>10.	distribute viruses or any other technologies that may harm Gig Engineer, the Website, or the interests or property of Gig Engineer users (including their Intellectual Property Rights, privacy and publicity rights) or is unlawful, threatening, abusive, defamatory, invasive of privacy, vulgar, obscene, profane or which may harass or cause distress or inconvenience to, or incite hatred of, any person;</h5>
                    <h5>11.	download and aggregate listings from our website for display with listings from other websites without our express written permission, "frame", "mirror" or otherwise incorporate any part of the Website into any other website without our prior written authorisation;</h5>
                    <h5>12.	attempt to modify, translate, adapt, edit, decompile, disassemble, or reverse engineer any software programs used by us in connection with the Website;</h5>
                    <h5>13.	copy, modify or distribute rights or content from the Website or Gig Engineer's copyrights and trademarks; or</h5>
                    <h5>14.	harvest or otherwise collect information about Users, including email addresses, without their consent.</h5>
                </div>

                <div className='paras1'>
                    <h3>5.	Non-circumvention</h3>
                    <h5>You irrevocably agree not to attempt to circumvent, avoid or bypass the Gig Engineer platform, directly or indirectly for the purpose of avoiding payment of Website service fees or commissions, or otherwise, individually, by way of any corporation, trust, partnership or other entity, or in conjunction with transaction or transactions of business involving you and any client. </h5>
                    <h5>It is understood that, without the previous written consent from Gig Engineer, you will not enter, either directly or indirectly, into any discussions, solicit or accept offers, enter into any agreements, conduct negotiations with or otherwise engage in any other independent communications with any third party to whom you were introduced to by any member, shareholder, officer, director, employee, agent, or other representative of Gig Engineer or any client as the case may be; any third party to whom you were “informed of” by any member, shareholder, officer, director, employee, agent, or other representative of Gig Engineer or the client as the case may be; or any employee, financial partner, investor, contractor, consultant or other business partner of Gig Engineer or the client.</h5>
                    <h5>You further irrevocably agree not to, other than through the Gig Engineer platform or without the prior written consent of Gig Engineer, either during or within 12 months, engage, contract, be employed or otherwise solicit or be solicited for employment whether directly or indirectly with a client.</h5>
                    <h5>You agree that you will not make any contact, deal, or otherwise be involved with the clients of Gig Engineer, unless otherwise authorised by Gig Engineer.</h5>
                    <h5>No attempt, or suggestion of circumvention, will be permitted by Gig Engineer, and you agree and understand that any overt, or covert act in circumvention shall constitute a fraudulent act against Gig Engineer, and will be subject to judicial action, recompense for damages, possible punitive damages and interdictory relief.</h5>
                    <h5>It is further understood that where you contravene the integrity of this non-circumvention provision, you shall identify fully, and pay over all brokerage fees, commissions, or monies obtained by you as a result of such circumvention directly or indirectly to Gig Engineer, and indemnify and be liable for all legal fees for Gig Engineer, which may be incurred in the course of adjudication of said violation.</h5>
                </div>
                <div className='paras1'>
                    <h3>6.	Intellectual Property </h3>
                    <h5>All content available on the Website, including, but not limited to, text, graphics, logos, button icons, images, audio clips, data compilations, and software, and the compilation thereof (the "Content") is our property. The trademarks, logos, and service marks displayed on the Website (collectively, the "Trademarks") are our registered and unregistered marks. All Trademarks not owned by us that appear on the Website are the property of their respective owners.</h5>
                    <h5>Except as set forth in the limited license in clause E above, or as required under applicable law, neither the Content, the Trademarks, nor any other portion of the Website may be used, reproduced, duplicated, copied, sold, resold, accessed, modified, or otherwise exploited, in full or in part, for any purpose without our prior written consent.</h5>
                    <h5>It is our policy to respond to clear notices of alleged intellectual property rights infringement. If you believe that your Intellectual Property Rights have been violated, please notify us via the contact details provided on our Website and we will investigate.</h5>
                </div>
                <div className='paras1'>
                    <h3>7.	 Fees </h3>
                    <h5>We make no recommendations regarding project fees or hourly rates charged by independent professionals. The independent professional shall charge the client the hourly rate as quoted by him or her on the Website from time to time, or any hourly rate or project fee as agreed between the independent professional and the client. Gig Engineer withholds an amount equal to ten percent (10%) of any payments made to independent professionals for services rendered to clients, to be known as a brokerage or service fee. This is in essence the fee charged by Gig Engineer for the use of our platform. The independent professional mandates that we have the authority to accept payments for services rendered to clients on the independent professional’s behalf which will be held in escrow until such time as the payment is approved by the client for work done. The independent professional agrees that we shall only release the payments for services rendered, less the 10% service fee, to the independent professional once the project deliverables have been met in accordance with a separate agreement between the independent professional and the client. All payments will then be paid into the independent professional’s bank account within three (3) business days from the date of approval for payment received from the client. </h5>
                    <h5>Gig Engineer may charge fees for certain additional services, such as a subscription fee for dedicated account management support to clients. When you use a service that has a fee, you have an opportunity to review and accept the fees that you will be charged based on our published schedule of Fees and Charges on our Website, which we may change from time to time. In addition, Gig Engineer may choose to temporarily change the fees for our services for promotional events or new services (for example, discounts for first time users or reduced subscription fees).</h5>
                    <h5>Every registered User (client) is responsible to notify us immediately if an independent professional suggests making payments outside of the Gig Engineer platform. If any User (client) offers traditional employment to an independent professional, you agree to pay Gig Engineer a fee of 20 percent (20%) of the annualized Base Salary payable to said independent professional (the “Employment Fee”). Base Salary means the annualized base salary and does not include any percentage of signing, discretionary or other possible bonuses, moving expenses, tuition reimbursement or any other compensation or type of allowance (the “Base Salary”). </h5>
                    <h5>Unless otherwise stated, all fees are quoted in South African Rand.</h5>
                </div>
                <div className='paras1'>
                    <h3>8.	Taxes </h3>
                    <h5>It is your responsibility as an independent professional or client to determine what, if any, taxes apply to the payments you make or receive, and it is your responsibility to collect, report and remit the correct tax to the appropriate tax authority. We are not responsible for determining whether taxes apply to your transaction, or for collecting, reporting or remitting any taxes arising from any transaction on your behalf. If the independent professional is a company, then the independent professional shall, where applicable, be liable for payment to the applicable revenue authorities of all taxes relating to the services rendered by the independent professional to the client, including any income tax, VAT or any other taxes as might be relevant and the independent professional indemnifies us for any claim and/or damages we may suffer as a result of the independent professional’s failure to comply with its obligations in this regard.</h5>

                </div>
                <div className='paras1'>
                    <h3>9.	Payment Administration Agent</h3>
                    <h5>You acknowledge and agree that we may in our sole discretion, from time to time, appoint our related bodies corporate, affiliates, or any other third party to act as our agent to accept or make payments (including merchant facilities) from or to Users on our behalf.</h5>
                    <h5>Such a third party will have the same rights, powers and privileges that we have under this User Agreement and will be entitled to exercise or enforce their rights, powers and privileges as our agent or in their own name. In no event shall we be liable to any User for any loss, damage or liability resulting from the Payment Administration Agent's negligence and/or acts beyond the authority given by Gig Engineer.</h5>
                </div>
                <div className='paras1'>
                    <h3>10.	Promotion </h3>
                    <h5>We may display your company or business name, logo, images or other media as part of the Gig Engineer Services and/or other marketing materials relating to the Website, except where you have explicitly requested that we do not do this, and we have agreed to such a request in writing.</h5>
                    <h5>You acknowledge that we may use the public description of your Projects and the content of your profile information on the Website for marketing and other related purposes, except where you have explicitly requested that we do not do this.</h5>
                </div>
                <div className='paras1'>
                    <h3>11.	User Content </h3>
                    <h5>If you use the Website, you are responsible for ensuring the information provided in the registration form is true, accurate and complete. We reserve the right to refuse service, terminate accounts, or remove or edit content, for any reason, in our sole discretion without prior notice.</h5>
                    <h5>When you give us content, you grant us a worldwide, perpetual, irrevocable, royalty-free, sublicensable (through multiple tiers) right to exercise any and all copyright, trademark, publicity, and database rights (but no other rights) you have in the content, in any media known now or in the future.</h5>
                    <h5>You acknowledge and agree that: (1) we act only as a forum for the online distribution and publication of User content. We make no warranty that User content is made available on the Website. We have the right (but not the obligation) to take any action deemed appropriate by us with respect to your User content; (2) we have no responsibility or liability for the deletion or failure to store any content, whether or not the content was actually made available on the Website; and (3) any and all content submitted to the Website is subject to our approval. We may reject, approve or modify your User content at our sole discretion.</h5>
                    <h5>You represent and warrant that your content:</h5>
                    <h5>1.	will not infringe upon or misappropriate any copyright, patent, trademark, trade secret, or other intellectual property right or proprietary right or right of publicity or privacy of any person;</h5>
                    <h5>2.	will not violate any law or regulation;</h5>
                    <h5>3.	will not be defamatory or trade libellous;</h5>
                    <h5>4.	will not be obscene or contain child pornography;</h5>
                    <h5>5.	will not contain the development, design, manufacture or production of missiles, or nuclear, chemical or biological weapons</h5>
                    <h5>6.	will not contain material linked to terrorist activities</h5>
                    <h5>7.	will not include incomplete, false or inaccurate information about User or any other individual; and</h5>
                    <h5>8.	will not contain any viruses or other computer programming routines that are intended to damage, detrimentally interfere with, surreptitiously intercept or expropriate any system, data or personal information.</h5>
                    <h5>You acknowledge and agree that we may transfer your personal information to a related body corporate and your information may be transferred outside of South Africa. If you wish to withdraw your consent, you acknowledge and agree that we may be unable to provide you with access to the Website and Gig Engineer Services and may close your Account.</h5>
                    <h5>Information on the Website may contain general information about legal, financial, health and other matters. The information is not advice and should not be treated as such. You must not rely on the information on the Website as an alternative to professional advice. If you have specific questions about any matter you should consult your professional adviser.</h5>
                    <h5>We provide unmonitored access to third party content, including User feedback and articles with original content and opinions (or links to such third party content). We only act as a portal and have no liability based on, or related to, third party content on the Website, whether arising under the laws of copyright or other intellectual property, defamation, libel, privacy, obscenity, or any other legal discipline.</h5>
                    <h5>The Website may contain links to other third party websites. We do not control the websites to which we link from the Website. We do not endorse the content, products, services, practices, policies or performance of the websites we link to from the Website. Use of third party content, links to third party content and/or websites is at your risk.</h5>
                    <h5>In relation to deletion or hiding of any information or content, using the Website to delete, hide or otherwise dispose of information does not imply permanent deletion of content or information. Information may be retained for a period of time to fulfil record keeping, regulatory, compliance, statistical, law enforcement and other obligations.</h5>
                    <h5>Any information provided on the Website is for general information purposes only. We try to ensure that all information provided on the Website is as accurate as possible and that information is correct (to the best of our knowledge) at the time of posting onto the Website and is reviewed regularly. However, to the fullest extent permitted by law, we do not warrant that any information available on the Website is accurate, complete, reliable, current, or error-free.</h5>
                </div>
                <div className='paras1'>
                    <h3>12.	 Feedback, Reputation and Reviews</h3>
                    <h5>You acknowledge that you transfer copyright of any feedback, reputation or reviews you leave consisting of comments and any rating(s) (e.g. quality, communication, etc.) together with any composite rating by us. You acknowledge that such feedback, reputation and reviews belong solely to us, notwithstanding that we permit you to use it on our Website while you remain a User. You must not use, or deal with, such feedback, reputation and reviews in any way inconsistent with our policies as posted on the Website from time to time without our prior written permission.</h5>
                    <h5>You may not do (or omit to do) anything that may undermine the integrity of the Gig Engineer feedback system. We are entitled to suspend or terminate your Account at any time if we, in our sole and absolute discretion, are concerned by any feedback about you, or your feedback rating, where we believe our feedback system may be subverted.</h5>
                    <h5>Our feedback ratings belong to us and may not be used for any purpose other than facilitating the provision of Seller Services via the Website. You may not use your Seller or Buyer feedback (including, but not limited to, marketing or exporting your any or all of your composite rating(s) or feedback comments) in any real or virtual venue other than a website operated by Gig Engineer or its related entities, without our written permission.</h5>
                </div>
                <div className='paras1'>
                    <h3>13.	Advertising </h3>
                    <h5>Unless otherwise agreed with us, you must not advertise an external website, product or service on the Website. Any website address posted on the Website, including in a listing, bid, listing description, clarification board or the message board, must relate to a Project, contest, item listed, user or service being performed on the Website.</h5>
                    <h5>We may display advertisements or promotions on the Website. You acknowledge and agree that we shall not be responsible for any loss or damage of any kind incurred by you as a result of the presence of such advertisements or promotions or any subsequent dealings with third parties. Furthermore, you acknowledge and agree that content of any advertisements or promotions may be protected by copyrights, trademarks, service marks, patents or other intellectual property or proprietary rights and laws. Unless expressly authorised by Gig Engineer or third party right holders, you agree not to modify, sell, distribute, appropriate or create derivative works based on such advertisement/promotions.</h5>
                </div>
                <div className='paras1'>
                    <h3>14.	 Communication With Other Users </h3>
                    <h5>Communication with other users on the Website must be conducted through the communication tools and channels provided on the Website.</h5>
                    <h5>You must not post your email address or any other contact information (including but not limited to social media or other identifying strings on other platforms) on the Website, except in the "email" field of the signup form, at our request or as otherwise permitted by us on the Website.</h5>
                    <h5>Unless you have a prior relationship with a User, you must only communicate with Users via the Website. You must not, and must not attempt to, communicate with other Users through any other means including but not limited to email, telephone, Skype, Facebook messenger, Whatsapp, Telegram, Signal, AIM, MSN Messenger, WeChat, SnapChat, Google Meet, GTalk, GChat or Yahoo.</h5>
                    <h5>In relation to video chat and audio chat, any terms agreed to between any Users must be confirmed in writing using the chat or direct message function.</h5>
                    <h5>Gig Engineer may use information such as your name, location, display or username, and or your image, in relation to the provision messaging services on the Website or in the mobile apps.</h5>
                    <h5>We may read all correspondence posted to the Website and download or access, and test (if necessary), all uploaded files, programs and websites related to your use of the Website for the purpose of investigating fraud, regulatory compliance, risk management and other related purposes.</h5>
                </div>
                <div className='paras1'>
                    <h3>15.	Third party links </h3>
                    <h5>We are not responsible for the content of any off-Website pages or any other websites linked to or from the Website. Links appearing on the Website are for convenience only and are not an endorsement by us or any of our associated companies of the referenced content, product, service, or supplier. Linking to or from any off-Website pages or other websites is at your own risk. We are in no way responsible for examining or evaluating, and we do not warrant the offerings of, off-Website pages or any other websites linked to or from the Website, nor do we assume any responsibility or liability for the actions, content, products, or services of such pages and websites, including, without limitation, their privacy statements and terms and conditions. You should carefully review the terms and conditions and privacy policies of all off-Website pages and other websites that you visit.</h5>
                </div>
                <div className='paras1'>
                    <h3>16.	 Identity / Know Your Customer </h3>
                    <h5>You authorise us, directly or through third parties, to make any inquiries we consider necessary to validate your identity. You must, at our request: (1) provide further information to us, which may include your date of birth and or other information that will allow us to reasonably identify you; (2) take steps to confirm ownership of your email address or financial instruments; or (3) verify your information against third party databases or through other sources.</h5>
                    <h5>You must also, at our request, provide copies of identification documents (such as your South African identity document, passport or driver's licence). We may also ask you to provide photographic identification holding your identification together as an additional identity verification step. We also reserve the right to request a video interview with you to validate this information, your identity, your background and your skills.</h5>
                    <h5>We reserve the right to close, suspend, or limit access to your Account, the Website and/or Gig Engineer Services in the event we are unable to obtain or verify to our satisfaction the information which we request under this section.</h5>
                    <h5>We reserve the right to update your particulars on the website in order to match any identity documentation that has been provided. Disbursements such as electronic transfers from the website may only be made to the beneficiary matching your provided identity documents and account information.</h5>
                    <h5>If you are not Gig Engineer Verified you may not be able to receive funds from your Gig Engineer Account, and other restrictions may apply. </h5>
                </div>
                <div className='paras1'>
                    <h3>17.	 User Services </h3>
                    <h5>Upon the Buyer (client) awarding a Project or Contest to the Seller (independent professional), and the Seller's acceptance on the Website, the Buyer and Seller will be deemed to have entered into a User Contract under which the Buyer agrees to purchase, and the Seller agrees to deliver the Seller Services. You agree not to enter into any contractual provisions in conflict with the User Agreement.</h5>
                    <h5>You are solely responsible for ensuring that you comply with your obligations to other Users. If you do not, you may become liable to that User. You must ensure that you are aware of any domestic laws (including common law), international laws, statutes, ordinances and regulations relevant to you as a Buyer or Seller, or in any other uses you make of the Website.</h5>
                    <h5>If another User breaches any obligation to you, you are solely responsible for enforcing any rights that you may have. For the avoidance of doubt, we have no responsibility for enforcing any rights under a User Contract.</h5>
                    <h5>Depending on their jurisdiction, Sellers and Buyers may have rights under statutory warranties that cannot lawfully be excluded. Nothing in this User Agreement is intended to override a right that by applicable law may not be excluded. Nothing in this User Agreement is intended to violate any laws relating to unfair contracts, and this agreement has been specifically redrafted to ensure compliance with unfair contracts legislation. To the extent that any component of this User Agreement is in conflict with inalienable rights under local laws, all parties intend for this agreement to be read down only insofar as to be in compliance with such local laws and no further.</h5>
                    <h5>Each User acknowledges and agrees that the relationship between Buyers and Sellers is that of an independent contractor. Nothing in this User Agreement creates a partnership, joint venture, agency or employment relationship between Users. Nothing in this User Agreement shall in any way be construed as forming a joint venture, partnership or an employer-employee relationship between Gig Engineer and any User.</h5>
                </div>
                <div className='paras1'>
                    <h3>18.	 Special Provisions for Local Jobs, Projects or Deliverables </h3>
                    <h5>Each User acknowledges:</h5>
                    <h5>1.	Gig Engineer does not review, approve, recommend or verify any of the credentials, licences or statements of capability in relation to Local Jobs (or, for the avoidance of doubt, any non-Local Jobs on the Website);</h5>
                    <h5>2.	Gig Engineer provides matchmaking and platform services only. Users agree that Gig Engineer has no liability for any other aspect of service delivery or interaction between Buyer and Seller. Gig Engineer is not a party to any disputes between Buyer and Seller, although we provide a dispute resolution mechanism to assist the parties in resolving issues;</h5>
                    <h5>3.	Gig Engineer may from time to time include map features and Gig Engineer may display the location of Users to persons browsing the Website on that map. Every Buyer seeking services for Local Jobs will be asked to provide the location where the Local Job is to be performed. You expressly agree that Gig Engineer has no liability for displaying such information.</h5>
                    <h5>4.	A User must never disclose, in any Project posted, personal details such as the User's name, street number, phone number or the email address in any Project description for a Local Job or in any other public communication on the Website (these may be disclosed for Local Jobs as required in private direct messages);</h5>
                    <h5>5.	Gig Engineer may collect location related data from you via technologies including but not limited to GPS, IP address location, WiFi, and by other methods. This data may be shared in the context of facilitating services for Local Jobs and each User specifically consents to this collection and sharing as part of this agreement;</h5>
                    <h5>6.	Upon completion of a Local Job or Project or Deliverable, the User (Independent Professional) must log on to the Website and click the "Complete" button for that Local Job, Project or Deliverable as soon as practically possible.</h5>
                    <h5>7.	Failure to complete the service or task will constitute a breach of this User Agreement; and</h5>
                    <h5>8.	Our fees are applied to the amount of the awarded Seller's bid to perform the services for the Local Job. Any items purchased by the Seller as part of performing the service are between the Buyer and Seller.</h5>
                </div>
                <div className='paras1'>
                    <h3>19.	 Funds</h3>
                    <h5>You may have positive funds in your Gig Engineer Account if you have prepaid for fees or charges or for services to be provided to you via the Website. If you are a Seller, you may have positive funds if you have successfully completed a Project and funds have been released to you. There are also circumstances where funds may have been credited to your Account in relation to an affiliate program or a referral program.</h5>
                    <h5>Funds in your Account are held by us in our operating accounts held with financial institutions. Funds in your Account are not held separately by us, and may be commingled with our general operating funds, and/or funds of other User's Accounts.</h5>
                    <h5>You are not entitled to any interest, or other earnings for funds that are in your Account.</h5>
                    <h5>We may receive interest on funds held by us in our operating accounts from financial institutions with whom we hold our operating accounts. Any such interest earned belongs to Gig Engineer and we will not be liable to any User for any imputed interest on such funds.</h5>
                    <h5>If your Account has negative funds, we may:</h5>
                    <h5>1.	set-off the negative amount with funds that you subsequently receive into your Account;</h5>
                    <h5>2.	if you have funds in multiple currencies in your Account and one of the currencies becomes negative for any reason, we may set-off the negative amount against funds you maintain in a different currency (at an exchange rate applied by us);</h5>
                    <h5>3.	reverse payments you have made from your Account to other User Accounts on the Website;</h5>
                    <h5>4.	deduct amounts you owe us from money you subsequently add or receive into your Account; or</h5>
                    <h5>5.	immediately suspend or limit your Account until such time as your Account no longer has a negative amount.</h5>
                    <h5>In the event that we offset a negative amount of funds pursuant to this section, it may be bundled with another debit coming out of your Account.</h5>
                    <h5>We reserve the right to collect any funds owed to us by any other legal means.</h5>
                    <h5>You acknowledge and agree that:</h5>
                    <h5>1.	we are not a bank or other licensed financial institution and do not provide banking services or any financial services to you;</h5>
                    <h5>2.	the funds shown in your Account (which may include Milestone Payments and/or any prepayment of fees and charges which you owe to us) represents our unsecured obligations to you with respect to your rights to direct us to make payment in relation to the purchase and sale of Seller Services through the Website and provision of the Gig Engineer Services;</h5>
                    <h5>3.	if you were a User acquired in an acquisition and your account was migrated to the Website, we are responsible for your positive funds only to the extent of the legal documentation between us and any acquired marketplace, along with this agreement, and you acknowledge specifically that the onus is on you to confirm the validity of your fund, and that any understatement or misstatement in relation to this is not a claim against us, and belongs with the counterparty of any prior user agreement to which you agreed;</h5>
                    <h5>4.	to the extent that we are required to release funds from your Account to you, you will become our unsecured creditor until such funds are paid to you;</h5>
                    <h5>5.	we are not acting as a trustee or fiduciary with respect to such funds or payments;</h5>
                    <h5>6.	the amount of funds showing in your Account is not insured and is not a guaranteed deposit;</h5>
                    <h5>7.	funds may only loaded into your Account, or released from your Account, by us and you must only use the mechanisms available on the Website to pay for, or receive funds in respect of Seller Services;</h5>
                    <h5>8.	any refunds required to be processed in your favour will be returned only to the source of the original deposit, and cannot be redirected to any other payment source;</h5>
                    <h5>9.	we will hold funds in respect of the amount of your Account (including Milestone Payments) in an account held by us with a financial institution (or in any manner that we decide in our sole discretion from time to time) and such funds are not segregated into a separate account; and</h5>
                    <h5>10.	we may commingle your funds with funds of other Users and our own funds and such commingled funds could be used to pay other Users or for our general corporate purposes or otherwise, however, we will remain obliged to release or refund funds at your direction in accordance with this User Agreement.</h5>
                    <h5></h5>
                </div>
                <div className='paras1'>
                    <h3>20.	 Limits & Fraud Prevention</h3>
                    <h5>We reserve the right to suspend a User withdrawal request if the source of the funds is suspected to be fraudulent.</h5>
                    <h5>If we become aware that any funds received into an Account from another Account as a result of a fraudulent transaction, this will be reversed immediately. If those funds have already been released to you, you must pay the funds into your Account. If you do not do so, we may suspend, limit or cancel your account, or take action against you to recover those funds.</h5>
                    <h5>We may, in our sole discretion, place a limit on any or all of the funds in your Account (thereby preventing any use of the funds) if:</h5>
                    <h5>1.	we believe there may be an unacceptable level of risk associated with you, your Account, or any or all of your transactions, including if we believe that there is a risk that such funds will be subject to reversal or chargeback;</h5>
                    <h5>2.	we believe that the beneficiary of the payment is someone other than you;</h5>
                    <h5>3.	we believe that the payment is being made to a country where we do not offer our Service; or</h5>
                    <h5>4.	we are required to do so by law or applicable law enforcement agencies.</h5>
                    <h5>If you are involved in a dispute, we may (in certain circumstances) place a temporary limit on the funds in your Account to cover the amount of any potential liability. If the dispute is resolved in your favour, we will lift the limit on your funds and those funds may be released to you. If the dispute is not resolved in your favour, we may remove the funds from your Account. We may also place a limit on your account in circumstances where we suspect you of fraudulent or other unacceptable behaviour, while we investigate any such matter.</h5>
                </div>
                <div className='paras1'>
                    <h3>21.	 Refunds </h3>
                    <h5>You may ask for a refund at any time for any funds that you have paid into your Account except if the amount to refund relates to a Milestone Payment or relates to fees or charges payable to us.</h5>
                    <h5>If we agree to the refund, the funds will be received by the User via the same payment method(s) that the User used to make the original payment to us.</h5>
                    <h5>We may refund funds to Users irrespective of whether a User has requested funds be refunded if: (1) we are required by law or consider that we are required by law to do so; (2) we determine that refunding funds to the User will avoid any dispute or an increase in our costs; (3) we refund funds to the User in accordance with any refund policy specified by us from time to time; (4) we find out that the original payment made by the User is fraudulent; (5) the User made a duplicate payment in error; or (6) we consider, in our sole opinion, that it is likely that the refund of funds is necessary to avoid a credit card chargeback.</h5>
                    <h5>If we reasonably determine, having considered all the relevant circumstances, that you have made an excessive or unreasonable number of requests to refund funds back to you or chargebacks, we may suspend, limit or close your Account.</h5>
                </div>
                <div className='paras1'>
                    <h3>22.	 Withdrawals </h3>
                    <h5>Your first withdrawal of funds earned may be delayed for up to fifteen days for security and fraud purposes.</h5>
                    <h5>We may impose a minimum withdrawal amount for funds earned. The maximum you can withdraw per month is R150,000 unless otherwise specifically agreed with support.</h5>
                    <h5>You are required to be FICA (Financial Intelligence Centre Act) compliant before you can withdraw funds from your Gig Engineer Account, irrespective of whether or not a delay has been enforced. </h5>
                    <h5>You acknowledge and agree that we may seek to verify your identity or request additional information from you at the discretion by Gig Engineer. </h5>
                </div>
                <div className='paras1'>
                    <h3>23.	 Reversals and Chargebacks</h3>
                    <h5>A chargeback (being a challenge to a payment that a User files with their card issuer or financial institution), and any subsequent reversal instruction, is made by the payment product issuer or third parties (such as payment processors) and not by us. We are bound to follow such instructions.</h5>
                    <h5>You acknowledge and agree that we will be entitled to recover any chargebacks and reversals that may be imposed on us by a payment product issuer or third parties (such as payment processors) on funds paid to you by Buyers through the Website, as well as any processing or any other fees whatsoever incurred by us on those chargebacks and reversals.</h5>
                    <h5>You agree that we may reverse any such payments made to you, which are subject to chargeback or reversal instruction via your payment product issuer or third parties (such as payment processors). If you initiate any chargeback request or other "Request for Information" or similar process, you expressly agree and consent to us to share any and all information in relation to your agreement of these terms and conditions, in order to defeat any such chargeback request.</h5>
                </div>
                <div className='paras1'>
                    <h3>24.	 Inactive Accounts </h3>
                    <h5>User Accounts that have not been logged into for a period of time as determined by Gig Engineer. </h5>
                    <h5>We reserve the right to close an Inactive Account.</h5>
                    <h5>We reserve the right to close an account with nil or negative funds.</h5>
                </div>
                <div className='paras1'>
                    <h3>25.	 Right to Refuse Service</h3>
                    <h5>We may close, suspend or limit your access to your Account without reason. Without limiting the foregoing, we may close, suspend or limit your access to your Account:</h5>
                    <h5>1.	if we determine that you have breached, or are acting in breach of this User Agreement;</h5>
                    <h5>2.	if you under-bid on any Project in an attempt to renegotiate the actual price privately, to attempt to avoid fees;</h5>
                    <h5>3.	if we determine that you have infringed legal rights (resulting in actual or potential claims), including infringing Intellectual Property Rights;</h5>
                    <h5>4.	if we determine that you have engaged, or are engaging, in fraudulent, or illegal activities;</h5>
                    <h5>5.	you do not respond to account verification requests;</h5>
                    <h5>6.	you do not complete account verification when requested within 3 months of the date of request;</h5>
                    <h5>7.	you are the subject of any sanctions regime, or our banking and payment relationships otherwise preclude us from conducting business with you;</h5>
                    <h5>8.	to manage any risk of loss to us, a User, or any other person; or</h5>
                    <h5>9.	for other reasons.</h5>
                    <h5>If we close your Account due to your breach of this User Agreement, you may also become liable for certain fees as described in this User Agreement.</h5>
                    <h5>Without limiting our other remedies, to the extent you have breached this User Agreement, you must pay us all fees owed to us and reimburse us for all losses and costs (including any and all of our employee time) and reasonable expenses (including legal fees) related to investigating such breach and collecting such fees.</h5>
                    <h5>If we close your Account for a reason other than as a result of your breach of this User Agreement, unless as otherwise specified in this User Agreement, you will be entitled to receive any payment due from us to you.</h5>
                    <h5>In the event that we close your Account, you will have no claim whatsoever against us in respect of any such suspension or termination of your Account.</h5>
                </div>
                <div className='paras1'>
                    <h3>26.	 Milestone Payments</h3>
                    <h5>We do not operate an escrow service in relation to the Website. We do however, provide a service on this Website which allows controlled payments to be made with respect to a service, called Milestone Payments.</h5>
                    <h5>Subject to the User Contract, the Buyer can make a Milestone Payment, which will be locked from the Buyer's Account and cannot be claimed by the Seller until:</h5>
                    <h5>1.	the Buyer and Seller agree that the funds can be claimed by the Seller;</h5>
                    <h5>2.	if there is a dispute, the Buyer and Seller have concluded the Dispute Resolution Process and the Dispute is resolved in the Seller's favour;</h5>
                    <h5>3.	the Buyer instructs us to pay a Seller for services performed by the Seller in respect of a Project or Contest; or</h5>
                    <h5>4.	the Buyer acknowledges that the Seller has completed the services fully and satisfactory.</h5>
                    <h5>If a Buyer does not approve of the Seller's work product, the parties may elect to resolve the issue under the Dispute Resolution Process.</h5>
                    <h5>If we have not received any instructions or dispute from a Buyer or Seller in respect of a Milestone Payment within six months or any other reasonable length of time after the day that the Milestone Payment was paid and the Buyer has not logged into their Account during that time, the Milestone Payment will be unlocked and released back to the Buyer.</h5>
                </div>
                <div className='paras1'>
                    <h3>27.	 Other Disputes With Users</h3>
                    <h5>You acknowledge and agree that in the event that a dispute arises between you and another User in relation to any Project that you will first attempt to resolve any differences that you have in relation to such Project, including in relation to the quality of the services provided.</h5>
                    <h5>If you continue to have any difficulties or problems in relation to a dispute with another User in relation to a Project we encourage you to contact us via the Contact Us form on our Website.</h5>
                    <h5>You agree that any dispute that is not related to a Milestone Payment arising between you and another User will be handled in accordance with this clause. Gig Engineer will have full rights and powers to make a determination for all such disputes. Upon receipt of a dispute, Gig Engineer shall have the right to request the Seller and the Buyer to provide documentation in support of their claim or position in relation to the dispute. You agree that Gig Engineer has absolute discretion to accept or reject any document provided. You also acknowledge that Gig Engineer is not a judicial or alternative dispute resolution institution and that we will make the determinations only as an ordinary reasonable person. In addition, we do not warrant that the documents provided by the parties to the dispute will be true, complete or correct and you agree to indemnify and (to the maximum extent permitted by law) hold Gig Engineer and any of our affiliates harmless against any damages or liability you may suffer as a result of any documentation or material subsequently being found to be false or misleading.</h5>
                    <h5>In relation to disputes with any other users of the Website, you hereby agree to indemnify Gig Engineer from any and all claims, demands, and damages, actual and consequential, of every kind and nature, known and unknown, that is related to such a dispute.</h5>
                    <h5>It is agreed by you that you will make every endeavour at fair play and post reasonable and fair demands/offers on your dispute. No conduct relating to threats, blackmail, intimidation or inducement of users to leave positive feedback will be tolerated, in particular any refusal to provide either deliverables or payment in relation to the provision of feedback.</h5>
                    <h5>A User found to be in breach of the Dispute Resolution process applied by Gig Engineer may automatically lose the dispute in favour of the other party involved, regardless of the origin of the dispute. The User who breached the Code of Conduct may also incur further disciplinary action.</h5>
                </div>
                <div className='paras1'>
                    <h3>28.	 Disputes With Us </h3>
                    <h5>If a dispute arises between you (the User) and Gig Engineer, our goal is to address your concerns immediately and, if we are unable to do so to your satisfaction, to provide you with a means of resolving the dispute quickly. We strongly encourage you to first contact us directly to seek a resolution by using our customer support contact form on our Website.</h5>
                    <h5>For any claim, Gig Engineer may elect to resolve the dispute in a cost-effective manner through binding non-appearance-based arbitration. If Gig Engineer elects arbitration, such arbitration will be initiated through an established alternative dispute resolution (ADR) provider, which is to be selected by you from a panel of ADR providers that Gig Engineer will provide to you. The ADR provider and the parties must comply with the following rules: a) the arbitration shall be conducted by telephone, online and/or be solely based on written submissions, the specific manner shall be chosen by the party initiating the arbitration; b) the arbitration shall not involve any personal appearance by the parties or witnesses unless otherwise mutually agreed by the parties; and c) any judgment on the award rendered by the arbitrator may be entered in any court of competent jurisdiction.</h5>
                    <h5>All claims you bring against Gig Engineer must be resolved in accordance with the terms of this Agreement. All claims filed or brought contrary to this Agreement shall be considered improperly filed and a breach of this Agreement. Should you file a claim contrary to the terms of this Agreement, Gig Engineer may recover its legal fees and costs (including in-house lawyers and paralegals), provided that Gig Engineer has notified you in writing of the improperly filed claim, and you have failed to promptly withdraw the claim.</h5>
                    <h5>You agree that you will not pursue any claims arising under this User Agreement on a class or other representative basis and will not seek to coordinate or consolidate any arbitration or action hereunder with any other proceeding.</h5>
                    <h5>If any proceeding by or against you is commenced under any provision of any bankruptcy or insolvency law, Gig Engineer will be entitled to recover all reasonable costs or expenses (including reasonable legal fees and expenses) incurred in connection with the enforcement of this Agreement.</h5>
                    <h5>Gig Engineer's failure to act with respect to a breach by you or others does not waive our right to act with respect to subsequent or similar breaches.</h5>
                </div>
                <div className='paras1'>
                    <h3>29.	 Currencies</h3>
                    <h5>The Gig Engineer Website will display rates in South African Rands (ZAR) unless otherwise stated. Where rates are based on a conversion from the originating currency indicative market exchange rates shall be used. You understand and agree that these rates are only indicative and the amount specified in the origin currency is the actual amount.</h5>
                    <h5>We reserve the right to reject any request for a conversion of currency at any time.</h5>
                    <h5>You are responsible for all risks associated with converting and maintaining funds in various available currencies, including but not limited to the risk that the value of these funds will fluctuate as exchange rates change, which could result in decreases in the value of your funds in aggregate. You must not use (or attempt to use) the Website to engage in speculative trading, which could result in substantial losses. We are not a financial services provider.</h5>
                    <h5>All information included on the Website in respect of currency conversion is general information only. Where the use of currency conversion applies, it is done so at your own risk. Currency conversions are final and irreversible.</h5>
                </div>
                <div className='paras1'>
                    <h3>30.	 Access and Interference </h3>
                    <h5>You agree that you will not use any robot, spider, scraper or other automated means to access the Website via any means, including for the avoidance of doubt access to our API or application programming interface, for any purpose without our express written permission.</h5>
                    <h5>Additionally, you agree that you will not:</h5>
                    <h5>1.	take any action that imposes or may impose (in our sole discretion, exercised reasonably) an unreasonable or disproportionately large load on our infrastructure;</h5>
                    <h5>2.	interfere with, damage, manipulate, disrupt, disable, modify, overburden, or impair any device, software system or network connected to or used (by you or us) in relation to the Website or your Account, or assist any other person to do any of these things, or take any action that imposes, or may impose, in our discretion, an unreasonable or disproportionately large load on our infrastructure;</h5>
                    <h5>3.	copy, reproduce, modify, create derivative works from, distribute, or publicly display any content (except for your information) from the websites without the prior express written permission of Gig Engineer and the appropriate third party, as applicable;</h5>
                    <h5>4.	interfere or attempt to interfere with the proper working of the Websites, services or tools, or any activities conducted on or with the Websites, services or tools; or</h5>
                    <h5>5.	bypass our robot exclusion headers or other measures we may use to prevent or restrict access to the Website.</h5>
                </div>
                <div className='paras1'>
                    <h3>31.	 Closing Your Account</h3>
                    <h5>You may close your Account at any time. The option is located under the Account Settings tab.</h5>
                    <h5>Account closure is subject to:</h5>
                    <h5>1.	not having any outstanding listings on the Website;</h5>
                    <h5>2.	resolving any outstanding matters (such as a suspension or restriction on your Account); and</h5>
                    <h5>3.	paying any outstanding fees or amounts owing on the Account.</h5>
                    <h5>We may retain some of your personal information to satisfy regulatory requirements and our own external obligations. Closing your account does not necessarily delete or remove all of the information we hold.</h5>
                </div>
                <div className='paras1'>
                    <h3>32.	 Privacy </h3>
                    <h5>We use your information as described in Gig Engineer’s Privacy Policy. If you object to your information being transferred or used in accordance with our Privacy Policy, then you may not make use of our services. For the avoidance of doubt, your name and personal details shall be used for identity purposes in the normal course of conducting business on the Gig Engineer platform. For example: on invoices and purchase orders including but not limited to between transacting parties, including those automatically generated for awarding, accepting and payment of services rendered.</h5>
                </div>
                <div className='paras1'>
                    <h3>33.	 Indemnity</h3>
                    <h5>You agree to indemnify us (and our officers, directors, agents, subsidiaries, joint venturers and employees) against any claim or demand, including legal fees and costs, made against us by any third party due to or arising out of your breach of this Agreement, or your infringement of any law or the rights of a third party in the course of using the Website and Gig Engineer Services.</h5>
                    <h5>In addition, we can apply any funds in your Account against any liabilities you owe to us or loss suffered by us as a result of your non-performance or breach of this User Agreement.</h5>
                </div>
                <div className='paras1'>
                    <h3>34.	 Security </h3>
                    <h5>You must immediately notify us upon becoming aware of any unauthorised access or any other security breach to the Website, your Account or the Gig Engineer Services and do everything possible to mitigate the unauthorised access or security breach (including preserving evidence and notifying appropriate authorities). Your User Account is yours only, and you must not share your password with others. You are solely responsible for securing your password. We will not be liable for any loss or damage arising from unauthorised access of your account resulting from your failure to secure your password.</h5>
                </div>
                <div className='paras1'>
                    <h3>35.	 No Warranty as to Each User's Purported Identity</h3>
                    <h5>We cannot and do not confirm each User's purported identity on the Website. We may provide information about a User, such as a strength or rating, geographical location, or third party background check or verification of identity or credentials. However, such information is based solely on data that a User submits and we provide such information solely for the convenience of Users and the provision of such information is not an introduction, endorsement or recommendation by us.</h5>
                </div>
                <div className='paras1'>
                    <h3>36.	 No Warranty as to Content </h3>
                    <h5>The Website is a dynamic time-sensitive Website. The Website is presented "as is". As such, information on the Website will change frequently. It is possible that some information could be considered offensive, harmful, inaccurate or misleading or mislabelled or deceptively labelled accidentally by us or accidentally or purposefully by a third party. </h5>
                    <h5>We make no representations or warranties of any kind whatsoever, express or implied, statutory or otherwise, in connection with these terms and conditions or any information or services provided on the website.</h5>
                    <h5>Our Services, the Website and all content on it are provided on an 'as is', 'with all faults' and 'as available' basis and without warranties of any kind either express or implied. Without limiting the foregoing, we make no representation or warranty about:</h5>
                    <h5>1.	the Website or any Seller Services or Gig Engineer Services;</h5>
                    <h5>2.	the accuracy, reliability, availability, veracity, timeliness or content of the Website or any Seller Services or Gig Engineer Services;</h5>
                    <h5>3.	whether the Website or Seller Services or Gig Engineer Services will be up-to-date, uninterrupted, secure, error-free or non-misleading;</h5>
                    <h5>4.	whether defects in the Website will be corrected;</h5>
                    <h5>5.	whether the Website, the Seller Services or the Gig Engineer Services or any data, content or material will be backed up or whether business continuity arrangements are in place in respect of the Website, Seller Services or Gig Engineer Services;</h5>
                    <h5>6.	any third party agreements or any guarantee of business gained by you through the Website, Seller Services or Gig Engineer Services or us; or</h5>
                    <h5>7.	the Website, Seller Services or Gig Engineer Services or infrastructure on which they are based, being error or malicious code free, secure, confidential or performing at any particular standard or having any particular function.</h5>
                    <h5>To every extent permitted by law, we specifically disclaim any implied warranties of title, merchantability, fitness for a particular purpose, quality, suitability and non-infringement.</h5>
                </div>
                <div className='paras1'>
                    <h3>37.	Limitation of Liability</h3>
                    <h5>Notwithstanding any other provision of these terms and conditions, under no circumstances shall we be liable to you for any indirect, incidental, special or consequential damages, including, without limitation, loss of profits and loss of software or data, resulting from or arising out of the matter set out herein, whether a claim for such damage is based upon warranty, contract, tort, negligence or otherwise. </h5>
                    <h5>In no event shall we, our related entities, our affiliates or staff be liable, whether in contract, warranty, tort (including negligence), or any other form of liability, for:</h5>
                    <h5>1.	any indirect, special, incidental or consequential damages that may be incurred by you;</h5>
                    <h5>2.	any loss of income, business or profits (whether direct or indirect) that may be incurred by you;</h5>
                    <h5>3.	any claim, damage, or loss which may be incurred by you as a result of any of your transactions involving the Website.</h5>
                    <h5>The limitations on our liability to you above shall apply whether or not we, our related entities, our affiliates or staff have been advised of the possibility of such losses or damages arising.</h5>
                    <h5>We shall not be liable for any default arising due to any act of god, war, terrorist action, strike, lockout, industrial action, fire, flood, drought, tempest or any other event beyond our reasonable control.</h5>
                    <h5>Notwithstanding the above provisions, nothing in this User Agreement is intended to limit or exclude any liability on the part of us and our affiliates and related entities where and to the extent that applicable law prohibits.</h5>
                    <h5>To the extent that we are able to limit the remedies available under this User Agreement, we expressly limit our liability for breach of a non-excludable condition or warranty implied by virtue of any legislation to the following remedies (the choice of which is to be at our sole discretion) to the supply of the Gig Engineer services again or the payment of the cost of having the Gig Engineer services supplied again.</h5>
                </div>
                <div className='paras1'>
                    <h3>38.	Notices </h3>
                    <h5>Legal notices will be served or to the email address you provide to Gig Engineer during the registration process. Notice will be deemed given 24 hours after email is sent, unless the sending party is notified that the email address is invalid or that the email has not been delivered. Alternatively, we may give you legal notice by mail to the address provided by you during the registration process. In such case, notice will be deemed given three days after the date of mailing.</h5>
                    <h5>Any notices to Gig Engineer must be given by registered ordinary post.</h5>
                </div>
                <div className='paras1'>
                    <h3>39.	Law and Forum for Legal Disputes </h3>
                    <h5>This Agreement will be governed in all respects by the laws of South Africa. We encourage you to try and resolve disputes using certified mediation (such as online dispute resolution processes). If a dispute cannot be resolved then you and Gig Engineer irrevocably submit to the non-exclusive jurisdiction of the courts of South Africa.</h5>
                </div>
                <div className='paras1'>
                    <h3>40.	General</h3>
                    <h5>These Terms and Conditions shall be governed by the laws of the Republic of South Africa and you hereby submit to the exclusive jurisdiction of the courts of the Republic of South Africa. You acknowledge and agree that these Terms and Conditions, together with our Privacy Policy, constitute the complete and exclusive agreement between us concerning your use of the Website, and supersede all previous agreements. We reserve the right, in our sole discretion, to change these Terms and Conditions at any time by posting the changes on the Website. Any changes are effective immediately upon posting to the Website.</h5>
                    <h5>Your continued use of the Website constitutes your agreement to all such Terms and Conditions. We may, with or without prior notice, terminate any of the rights granted by these Terms and Conditions. You shall comply immediately with any termination or other notice, including, as applicable, by ceasing all use of the Website.</h5>
                    <h5>Nothing contained in these Terms and Conditions shall be construed as creating any agency or partnership between us. Our failure to enforce at any time or for any period any one of more of these Terms and Conditions shall not be deemed to be a waiver of them or of the right at any time subsequently to enforce any of these Terms and Conditions nor shall our waiver of a breach of any provision hereof be taken or held to be a waiver of the provision itself.</h5>
                    <h5>If any term or provision in these Terms and Conditions shall in whole or in part be held to any extent to be illegal or unenforceable under any enactment or rule of law that term or provision or part shall to that extent be deemed not to form part of these Terms and Conditions and the enforceability of the remainder of these Terms and Conditions shall not be affected.</h5>
                </div>
                <div className='paras1'>
                    <h3>41.	Severability </h3>
                    <h5>The provisions of this User Agreement are severable, and if any provision of this User Agreement is held to be invalid or unenforceable, such provision may be removed and the remaining provisions will be enforced. This Agreement may be assigned by us to an associated entity at any time, or to a third party without your consent in the event of a sale or other transfer of some or all of our assets. In the event of any sale or transfer you will remain bound by this User Agreement.</h5>
                </div>
                <div className='paras1'>
                    <h3>42.	No Waiver</h3>
                    <h5>Our failure to act with respect to an anticipated or actual breach by you or others does not waive our right to act with respect to subsequent or similar breaches. Nothing in this section shall exclude or restrict your liability arising out of fraud or fraudulent misrepresentation.</h5>
                </div>
                <div className='paras1'>
                    <h3>43.	Communications</h3>
                    <h5>You consent to receive notices and information from us in respect of the Website and Services by electronic communication. You may withdraw this consent at any time, but if you do so we may choose to suspend or close your Account.</h5>
                </div>
                <div className='paras1'>
                    <h3>44.	Additional Terms</h3>
                    <h5>It is important to read and understand all our policies as they provide the rules for using the Gig Engineer Website. In addition, there may be specific policies or rules that apply, and it is your responsibility to check our updated policies to make sure you comply. </h5>
                    <h5>Our policies may be changed from time to time. Changes take effect when we post them on the Gig Engineer Website. When using particular services on our Website, you are subject to any posted policies or rules applicable to services you use through the Website, which may be posted from time to time. </h5>
                </div>
                <div className='paras1'>
                    <h3>45.	Feedback </h3>
                    <h5>If you have any questions about this User Agreement or if you wish to report breaches of this User Agreement, please contact us by using our customer support website or emailing us at info@gigengineer.io.</h5>
                </div>

            <Footer />
        </div >


    );

};
const mapStateToProps = (state) => {
    return {
      auth: state.auth,
    };
};
export default connect(mapStateToProps)(TermsConditions);
