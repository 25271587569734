import React from 'react';
import Logos from '../../assests/logos.png';
import { CustomColoumn } from './HeaderWithLogo.styles';
import { useHistory } from 'react-router-dom';

const HeaderWithLogo = () => {
  const his = useHistory();
  return (
    <CustomColoumn>
      <img src={Logos} alt="GIG Engineer" size="medium" onClick={() => { his.push(`/`); }} width="50%" />
    </CustomColoumn>
  );
};
export default HeaderWithLogo;
