import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  Grid,
  Button,
  CardContent,
  Link,
  Typography,
  Dialog
} from '@mui/material';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import { formatDistance } from 'date-fns';
import { PaddedBox, CustomRow, EditedCard, PaddedFooter } from '../../app.styles';
import { deleteGigdraft, getDraftGigApi } from '../../../services/gig';
import { setDraftGigList } from '../../../store/actions/gig';
import { GigTitle, GigPercentitle, GigPercentTile } from './DraftGig.Styles';
import Pagination from '@mui/material/Pagination';
import { getDurationLabel, getGigLocation, scrollOnTop } from '../../../utils/helpers';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { Box } from '@mui/material';
import Delete from '../../../assests/delete.svg';
import Report from '../../../assests/report.svg';
import Footer from '../../../components/footer1/footer';
import '../composeGig/sections/Step_1.css';
import PageLoader from '../../../components/Loaders/PageLoader';

const DraftTite = ({ updatedDate }) => {
  return <GigTitle>Edited {formatDistance(new Date(updatedDate), new Date())} ago </GigTitle>;
};

const getSkillsList = (skills) => {
  return Array.prototype.map.call(skills, (s) => ` ${s.engineerSkill}`).toString();
};

const DraftGigs = (props) => {
  const history = useHistory();
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(1);
  const [list, setList] = useState([]);
  const [gigId, setGigId] = useState();
  const [open, setOpen] = useState(false);

  const setListData = (data) => {
    setList([..._get(data, 'gigList', [])]);
    setPage(_get(data, 'paginationResult.currentPage', {}) || 1);
    setCount(_get(data, 'paginationResult.totalPages', {}) || 1);
  };

  const fetchMoreData = (currentPage) => {
    const obj = {
      paginationInput: {
        pageNumber: currentPage,
        pageSize: 12
      }
    };
    getDraftGigApi(obj, setListData);
  };

  const handleChange = (event, value) => {
    scrollOnTop();
    fetchMoreData(value);
    setPage(value);
  };

  useEffect(() => {
    fetchMoreData(page);
  }, []);

  const DeleteSubmit = async (e) => {
    let data = {
      gigId: gigId
    };

    deleteGigdraft(data).then((e) => {
      setOpen(false);
      fetchMoreData(page);
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <PaddedBox
        style={{
          paddingLeft: `${
            !!props.auth && !!props.auth.isDrawerOpen && props.auth.isDrawerOpen == true
              ? '240px'
              : '0px'
          }`,
          minHeight: '580px'
        }}
      >
        <CustomRow container>
          <Typography
            variant="subtitle1"
            color="pColor.main"
            sx={{
              fontWeight: '500',
              textTransform: 'capitalize',
              fontSize: '22px',
              marginBottom: '20px'
            }}
          >
            Draft Gigs
          </Typography>
        </CustomRow>

        {_isEmpty(list) && (
          <Grid
            container
            item
            justifyContent="center"
            xs={12}
            className="no-record-text-table"
          >
            There are currently no draft gigs in progress. To start drafting a new gig go to 'Post
            New Gig'
          </Grid>
        )}
        <Grid container item spacing={3}>
          {list.map((job, index) => (
            <Grid item xs={12} sm={6} md={4} lg={4} key={index}>
              <EditedCard>
                <CardContent className="card-Content">
                  <List>
                    <div className="draftgig-list">
                      <ListItem disablePadding>
                        <ListItemText
                          secondary={<DraftTite updatedDate={_get(job, 'updatedAt', '')} />}
                        />
                      </ListItem>
                      <Link
                        className="card-Link-delete"
                        component="button"
                        variant="body2"
                        onClick={() => {
                          setOpen(true);
                          setGigId(_get(job, '_id'));
                        }}
                        title={'Delete'}
                      >
                        <img
                          sx={{ color: '#0000FF' }}
                          src={Delete}
                          alt="delete"
                          fontSize="medium"
                          width="50%"
                        />
                      </Link>
                    </div>
                    <ListItem disablePadding>
                      <ListItemText
                        secondary={
                          <Typography
                            title="Project Title"
                            placement="top"
                            enterTouchDelay={0}
                            variant="h6"
                            component="h6"
                            color="pColor.main"
                            sx={{
                              fontWeight: '600',
                              textTransform: 'capitalize',
                              fontSize: '16px',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis'
                            }}
                          >
                            {_get(job, 'titleAndSkills.projectTitle', '')}
                          </Typography>
                        }
                      />
                    </ListItem>
                    <ListItem disablePadding>
                      <ListItemText
                        secondary={
                          <Typography
                            title="Project Details"
                            enterTouchDelay={0}
                            variant="body2"
                            className="project-Description"
                            display="block"
                            color="text.secondary"
                          >
                            <div
                              className="read-overview"
                              dangerouslySetInnerHTML={{
                                __html: _get(job, 'titleAndSkills.projectDetails', '')
                              }}
                            ></div>
                          </Typography>
                        }
                      />
                    </ListItem>
                    <ListItem disablePadding>
                      <ListItemText
                        primary={
                          <Typography
                            sx={{
                              fontSize: '12px',
                              color: '#BDBDBD',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden'
                            }}
                          >
                            Skills
                          </Typography>
                        }
                        secondary={
                          <Typography
                            sx={{
                              fontSize: '14px',
                              color: '#5E5E5E',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden'
                            }}
                            title="Skills"
                          >
                            {getSkillsList(_get(job, 'titleAndSkills.skillsRequired', []))}
                          </Typography>
                        }
                      />
                    </ListItem>
                    <ListItem disablePadding>
                      <ListItemText
                        primary={
                          <Typography
                            sx={{
                              fontSize: '12px',
                              color: '#BDBDBD',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden'
                            }}
                          >
                            Location
                          </Typography>
                        }
                        secondary={
                          <Typography
                            sx={{
                              fontSize: '14px',
                              color: '#5E5E5E',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden'
                            }}
                            title="Location"
                          >
                            {_get(job, 'deliverablesAndLocation.location')
                              ? getGigLocation(job.deliverablesAndLocation)
                              : ' Not Yet Provided'}
                          </Typography>
                        }
                      />
                    </ListItem>
                    <ListItem disablePadding>
                      <ListItemText
                        primary={
                          <Typography
                            sx={{
                              fontSize: '12px',
                              color: '#BDBDBD',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden'
                            }}
                          >
                            Duration
                          </Typography>
                        }
                        secondary={
                          <Typography
                            sx={{
                              fontSize: '14px',
                              color: '#5E5E5E',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden'
                            }}
                            title="Duration"
                          >
                            {_get(job, 'scopeAndBudget.projectDuration', '0')}{' '}
                            {getDurationLabel(_get(job, 'scopeAndBudget.durationUnit', 'Hours'))}
                          </Typography>
                        }
                      />
                    </ListItem>
                    <ListItem disablePadding>
                      <ListItemText
                        primary={
                          <Typography
                            sx={{
                              fontSize: '12px',
                              color: '#BDBDBD',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden'
                            }}
                          >
                            Rate
                          </Typography>
                        }
                        secondary={
                          <Typography
                            sx={{
                              fontSize: '14px',
                              color: '#5E5E5E',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden'
                            }}
                            title="Hourly Rate"
                          >
                            {_get(job, 'scopeAndBudget.hourlyRate')
                              ? `${_get(job, 'scopeAndBudget.currency', 'R')} ${_get(
                                  job,
                                  'scopeAndBudget.hourlyRate'
                                )}/hr`
                              : ` Not Yet Provided`}
                          </Typography>
                        }
                      />
                    </ListItem>
                  </List>

                  {/* <Divider className="card-Padding" /> */}

                  <Grid container className="card-Padding">
                    <Grid item xs={9} md={10} lg={10}>
                      <GigPercentitle>Draft Completion</GigPercentitle>

                      <Grid container alignItems="center">
                        {[...Array(10)].map((x, i) => (
                          <GigPercentTile
                            key={i}
                            active={_get(job, 'percentageCompletion', 0) / 10 <= i}
                          />
                        ))}
                      </Grid>
                    </Grid>

                    <Grid className="card-Messages" item xs={3} md={2} lg={2}>
                      <Link
                        className="card-Link"
                        component="button"
                        variant="body2"
                        onClick={() => {
                          history.push(`/gig/edit/${_get(job, '_id')}`);
                        }}
                      >
                        Edit <span>&gt;</span>
                      </Link>
                    </Grid>
                  </Grid>
                </CardContent>
              </EditedCard>
            </Grid>
          ))}
        </Grid>

        <Dialog open={open} onClose={handleClose}>
          <Typography
            sx={{
              fontSize: '26px',
              lineHeight: '55px',
              fontWeight: '640',
              display: 'flex',
              paddingLeft: '20px'
            }}
          >
            <img src={Report} alt="delete" fontSize="medium" width="8%" />
            Delete Draft
          </Typography>
          <Typography
            sx={{
              textAlign: 'center',
              borderTop: '1px solid #E1E1E1',
              borderBottom: '1px solid #E1E1E1',
              width: '500px',
              fontSize: '20px',
              lineHeight: '55px',
              fontWeight: '400'
            }}
          >
            Are you sure you want to delete this draft gig?
          </Typography>
          <Box
            sx={{
              marginLeft: '20px',
              marginTop: '20px',
              paddingBottom: '20px',
              marginLeft: '220px'
            }}
          >
            <Button
              variant="contained"
              className="cancel-btn"
              type="submit"
              sx={{
                marginRight: '10px',
                textTransform: 'none',
                width: '120px',
                height: '40px',
                border: '2px solid grey',
                boxShadow: 'none'
              }}
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              className="popup-process"
              type="submit"
              sx={{
                textTransform: 'none',
                width: '120px',
                height: '40px',
                boxShadow: 'none'
              }}
              onClick={() => {
                DeleteSubmit();
              }}
            >
              Delete
            </Button>
          </Box>
        </Dialog>

        {!_isEmpty(list) && (
          <Box sx={{ marginTop: '40px' }}>
            <Pagination
              page={page}
              count={count}
              shape="rounded"
              className="pagination-root"
              onChange={handleChange}
              defaultPage={1}
            />
          </Box>
        )}
      </PaddedBox>
      <PaddedFooter
        style={{
          paddingLeft: `${
            !!props.auth && !!props.auth.isDrawerOpen && props.auth.isDrawerOpen == true
              ? '240px'
              : '0px'
          }`
        }}
      >
        <Footer />
      </PaddedFooter>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    gigs: state.draftGigs || []
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setDraftGigList: (details) => dispatch(setDraftGigList(details))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DraftGigs);
