import React, { useEffect, useState } from 'react';
import { FieldArray, Formik, getIn, Form } from 'formik';
import { connect } from 'react-redux';
import { Grid, TextField, Button, Alert, Box, Rating, Typography } from '@mui/material';
import DatePicker from '@mui/lab/DatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _isString from 'lodash/isString';
import { PaddedBox, CustomRow, PaddedFooter } from '../../../app.styles';
import { engineerScreenValidationSchema } from '../../../../utils/formikValidations';
import { setEngProfileApi } from '../../../../services/profile';
import { setProfileError, setProfileSuccess } from '../../../../store/actions/profile';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import HelpIcon from '@mui/icons-material/Help';
import { blue } from '@mui/material/colors';
import { setCookie, getCookie } from '../../../../utils/cookie';
import { Prompt, useHistory } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { toast } from 'react-toastify';
import MuiPhoneNumber from 'material-ui-phone-number';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AddIcon from '@mui/icons-material/Add';
import { getCountryStateCity } from '../../../../services/expertisewithskills';
import { getLanguage } from '../../../../services/expertisewithskills';
import Footer from '../../../../components/footer1/footer';
import { cancelProfileFeedBack, profileFeedBack } from '../../../../services/gig';

let don = '';

const Engineer = (props) => {
  const history = useHistory();

  const enggDefaults = {
    gender: '',
    racegroup: '',
    dob: null,
    emailId: '',
    name: '',
    address: '',
    disability: '',
    mobileNumber: '+27',
    country: 'South Africa',
    nationality: 'South Africa',
    state: '',
    city: '',
    areaCode: ''
  };

  let engg = { ...enggDefaults, ...props.engineer };
  engg.languageList = _isEmpty(props.languageList) ? engg.languageList : props.languageList;

  const [value, setValue] = useState();
  const percentage = _get(props, 'profile.profileCompletionPercent', 0);
  const [language, setLanguage] = useState([]);
  const [countries, setCountries] = useState([]);
  const [optedCountry, setOptedCountry] = useState('');
  const [optedProvince, setOptedProvince] = useState('');
  const [optedCity, setOptedCity] = useState('');
  const [datachange, setDataChange] = useState(false);
  const [openComplete, setOpenComplete] = useState(getCookie('setOpenComplete'));
  const [rating, setRating] = useState(1);
  // const [feedback, setFeedback] = useState('');
  const [ratingError, setRatingError] = useState('');
  const isFeedbackGiven = _get(props, 'profile.isFeedbackGiven', false);

  const handleCloseforComplete = () => {
    setOpenComplete(false);
    setCookie('setOpenComplete', false, 30);
    cancelMethod();
  };

  const BootstrapDialogTitle = (props) => {
    const { onClose } = props;

    return (
      <DialogTitle sx={{ m: 0, p: 2 }}>
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };

  BootstrapDialogTitle.propTypes = {
    onClose: PropTypes.func.isRequired,
  };

  const submitMethod = async () => {
    let feedback = document.getElementById("standard-search").value;
    let data = {
      feedback: feedback,
      rating: rating,
      isGiven: true,
      type: "profileCompletion"
    };
    if (data.rating === 0) {
      return setRatingError('Please give rating before submit.')
    }
    profileFeedBack(data).then((e)=>{
      setOpenComplete(false);
      toast.success("Success");
      setCookie('setOpenComplete', false, 30);
    })
  };

  const cancelMethod = async () => {
    let data = {
      isGiven: false,
      type: "profileCompletion"
    };
    cancelProfileFeedBack(data).then((e)=>{
      setOpenComplete(false);
    })
  };

  const getLanguageList = async () => {
    let language = await getLanguage();
    if (language?.data && language?.data.length > 0) {
      setLanguage(language.data);
    }
  };

  const getCountriesList = async () => {
    let countries = await getCountryStateCity();
    if (countries?.data && countries?.data.length > 0) {
      setCountries(countries.data);
    }
  };

  const getCountryValue = (val) => {
    if (val) setOptedCountry(val);
  };

  const getProvinceValue = (val) => {
    if (val) setOptedProvince(val);
  };

  const getCityValue = (val) => {
    if (val) setOptedCity(val);
  };

  const donhelper = (dateString) => {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    console.log(age)
    if (age > 18) {
      don = false;
    } else {
      don = true;
    }
  };
  useEffect(() => {
    getCountriesList();
    getLanguageList();
    setOptedCountry(props?.engineer?.country ? props.engineer.country : '');
    setOptedProvince(props?.engineer?.state);
    setOptedCity(props?.engineer?.city);
  }, [props]);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 300
      }
    }
  };



  return (
    <div>
      <Prompt
        when={datachange}
        message={() => 'You have unsaved changes, Are you sure you want to leave?'}
      ></Prompt>
      {(percentage === 100 && !isFeedbackGiven && (getCookie('setOpenComplete') != false)) && (
        <div>
          <Dialog open={openComplete} onClose={handleCloseforComplete}>
            <Box
              sx={{ display: "flex", flexDirection: "column", padding: "20px" }}
            >
              <BootstrapDialogTitle id="customized-dialog-title" onClose={handleCloseforComplete}>
              </BootstrapDialogTitle>
              <h2>Hey there!</h2>
              <Typography>
                Congratulations on successfully completing your Gig Engineer
                profile! We would love to get feedback on your experience.
              </Typography>
              <Box sx={{ display: "flex" }}>
                <Typography sx={{ marginTop: "12px" }}>
                  <Rating
                    name="size-large"
                    defaultValue={rating}
                    size="large"
                    onChange={(event) => {
                      let starValue = event.target.value;
                      if (rating != parseInt(starValue)) {
                        setRating(parseInt(starValue));
                        setRatingError(null)
                      }
                      if (rating === parseInt(starValue)) {
                        setRating(0)
                        setRatingError('Please provide the rating.')
                      }
                    }}

                  />
                </Typography>
                <Box
                  sx={{ marginTop: "12px", marginLeft: "20px", fontSize: "20px" }}
                >
                  ({rating}/5)
                </Box>
              </Box>
              <Typography
                sx={{
                  fontSize: '12px',
                  color: 'red'
                }}
              >
                {ratingError}
              </Typography>
              <Box
                component="form"
                sx={{
                  "& .MuiTextField-root": { m: 1, width: "60ch" },
                  marginTop: "-10px",
                }}
                autoComplete="off"
              >
                <div>
                  <TextField
                    id="standard-search"
                    label="Feedback (Optional)"
                    // value={feedback}
                    type="search"
                    variant="standard"
                  />
                </div>
              </Box>
              <Box
                sx={{
                  textAlign: "right",
                }}
              >
                <Button
                  variant="contained"
                  className="popup-process"
                  type="submit"
                  sx={{
                    width: "120px",
                  }}
                  onClick={submitMethod}
                >
                  Submit
                </Button>
                <br />
              </Box>
            </Box>
          </Dialog>
        </div>
      )}
      <PaddedBox style={{ paddingLeft: `${!!props.auth && !!props.auth.isDrawerOpen && props.auth.isDrawerOpen == true ? "240px" : "0px"}` }}>
        {!_isEmpty(_get(props, 'error', {})) && (
          <CustomRow container>
            <Alert severity="error" className="h-margin-bottom-16">
              {_get(props, 'error.message', 'some thing went wrong please try again')}
            </Alert>
          </CustomRow>
        )}
        <Formik
          initialValues={engg}
          enableReinitialize={true}
          validationSchema={engineerScreenValidationSchema}
          onSubmit={(values) => {
            const obj = { basicInfo: { ...values }, languageList: values.languageList };
            delete obj.basicInfo.languageList;
            if (obj.basicInfo.dob) {
              obj.basicInfo.dob = _isString(obj.basicInfo.dob)
                ? obj.basicInfo.dob
                : obj.basicInfo.dob.toISOString();
            } else {
              delete obj.basicInfo.dob;
            }
            setDataChange(false);
            setEngProfileApi(obj, props.setProfileError, props.setProfileSuccess);
            toast.success('Data Saved!');
            if (values?.action === 'next') {
              setTimeout(() => {
                history.push(`/employment`)
              }, 1000);
            }
          }}
        >
          {({ values, touched, errors, handleChange, handleBlur, setFieldValue }) => (
            <Form noValidate autoComplete="off">
              <Card sx={{ marginTop: '20px', marginBottom: '40px' }}>
                <CardContent>
                  <Box
                    sx={{
                      fontSize: '20px',
                      marginBottom: '15px',
                      fontWeight: '600',
                      color: '#0140AA',
                      // textTransform: 'uppercase'
                    }}
                  >
                    Personal Information
                  </Box>

                  <Grid container spacing={3}>
                    <Grid item xs={12} md={4} lg={4}>
                      <TextField
                        fullWidth
                        id="name"
                        name="name"
                        label="Full name"
                        required
                        value={values.name}
                        onChange={(e) => {
                          handleChange(e);
                          setDataChange(true);
                        }}
                        onBlur={handleBlur}
                        error={touched.name && Boolean(errors.name)}
                        helperText={touched.name && errors.name}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    <Grid item xs={12} md={4} lg={4}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          disableFuture
                          label="Date of birth"
                          required
                          openTo="year"
                          views={['year', 'month', 'day']}
                          value={values.dob}
                          inputFormat="dd/MM/yyyy"
                          onChange={(newValue) => {
                            setDataChange(true);
                            setFieldValue('dob', newValue);
                            donhelper(newValue);
                          }}
                          InputLabelProps={{ shrink: true }}
                          renderInput={(params) => (
                            <TextField
                              id="standard-basic"
                              fullWidth
                              variant="outlined"
                              onBlur={handleBlur}
                              helperText={don ? "You must be 18 years old or above." : ''}
                              {...params}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} md={4} lg={4}>
                      <TextField
                        fullWidth
                        label="Email address"
                        variant="outlined"
                        disabled
                        value={values.emailId}
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    <Grid item xs={12} md={4} lg={4}>
                      <MuiPhoneNumber
                        fullWidth
                        label="Phone number"
                        required
                        value={values.mobileNumber}
                        disableAreaCodes={true}
                        onChange={(e) => {
                          setFieldValue('mobileNumber', e);
                          setDataChange(true);
                        }}
                        onBlur={handleBlur}
                        error={touched.mobileNumber && Boolean(errors.mobileNumber)}
                        helperText={touched.mobileNumber && errors.mobileNumber}
                        variant="outlined"
                        MenuProps={MenuProps}
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    <Grid item xs={12} md={4} lg={4}>
                      <FormControl variant="outlined" sx={{ width: '100%' }}>
                        <InputLabel id="demo-simple-select-standard-label">Country</InputLabel>
                        <Select
                          value={values.country}
                          onChange={(e) => {
                            handleChange(e);
                            setFieldValue('country', e.target.value);
                            getCountryValue(e.target.value);
                            setDataChange(true);
                          }}
                          label="Country"
                          required
                          MenuProps={MenuProps}
                          InputLabelProps={{ shrink: true }}
                        >
                          {countries.length > 0 &&
                            countries.map(({ name }) => {
                              return (
                                <MenuItem value={name} key={name}>
                                  {name}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4} lg={4}>
                      <FormControl variant="outlined" sx={{ width: '100%' }}>
                        <InputLabel id="demo-simple-select-standard-label">Province</InputLabel>
                        <Select
                          value={optedProvince}
                          onChange={(e) => {
                            handleChange(e);
                            setFieldValue('state', e.target.value);
                            getProvinceValue(e.target.value);
                            setDataChange(true);
                          }}
                          label="Province"
                          MenuProps={MenuProps}
                          InputLabelProps={{ shrink: true }}
                        >
                          {countries.length > 0 &&
                            countries.find((x) => x.name === values.country)?.states.map(({ name }) => {
                              return (
                                <MenuItem value={name} key={name}>
                                  {name}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4} lg={4}>
                      <FormControl fullWidth variant="outlined">
                        <InputLabel id="demo-simple-select-standard-label">City</InputLabel>
                        <Select
                          value={optedCity}
                          onChange={(e) => {
                            handleChange(e);
                            setFieldValue('city', e.target.value);
                            getCityValue(e.target.value);
                            setDataChange(true);
                          }}
                          label="City"
                          MenuProps={MenuProps}
                          InputLabelProps={{ shrink: true }}
                        >
                          {countries.length > 0 &&
                            countries.find((el) => el.name === values.country)
                              ?.states.find((el) => el.name === values.state)
                              ?.cities.map(({ name }) => {
                                return (
                                  <MenuItem value={name} key={name}>
                                    {name}
                                  </MenuItem>
                                );
                              })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4} lg={4}>
                      <TextField
                        fullWidth
                        label="Area code"
                        id="areaCode"
                        name="areaCode"
                        value={values.areaCode}
                        onChange={(e) => {
                          handleChange(e);
                          setDataChange(true);
                        }}
                        onBlur={handleBlur}
                        error={touched.areaCode && Boolean(errors.areaCode)}
                        helperText={touched.areaCode && errors.areaCode}
                        variant="outlined"
                        required
                      // InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    {/* <Grid item xs={12} md={3} lg={3}>
                <Stepper
                  label="Availablity"
                  legend="Hours Per Week"
                  minVal={Number(values.availabilityHoursPerWeek)}
                  thresold={1}
                  id="availabilityHoursPerWeek"
                  name="availabilityHoursPerWeek"
                  onChange={(val) => {
                    setFieldValue('availabilityHoursPerWeek', val);
                  }}
                />
              </Grid> */}

                    {/* <Grid item xs={12} md={3} lg={3}>
                <Stepper
                  label="Hourly Rate"
                  legend="Rate"
                  minVal={Number(values.hourlyRate)}
                  thresold={10}
                  incrementBy={10}
                  id="hourlyRate"
                  name="hourlyRate"
                  onChange={(val) => {
                    setFieldValue('hourlyRate', val);
                  }}
                />
              </Grid> */}

                    {/* <Grid item xs={12} md={3} lg={3}>
                <Stepper
                  label="RESPONSE TIME"
                  legend="Hours"
                  subLabel="(Turn around time)"
                  minVal={Number(values.responseTime)}
                  thresold={1}
                  incrementBy={1}
                  id="responseTime"
                  name="responseTime"
                  onChange={(val) => {
                    setFieldValue('responseTime', val);
                  }}
                />
              </Grid> */}
                    <Grid item xs={12} md={8} lg={8}>
                      <TextField
                        fullWidth
                        multiline
                        id="address"
                        name="address"
                        label="Address"
                        required
                        value={values.address}
                        onChange={(e) => {
                          handleChange(e);
                          setDataChange(true);
                        }}
                        onBlur={handleBlur}
                        error={touched.address && Boolean(errors.address)}
                        rows={3}
                        helperText="Your address will not be shared with others. Only your city and country will be displayed to prospective clients."
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
              <Card sx={{ marginTop: '20px', marginBottom: '40px' }}>
                <CardContent>
                  <Box
                    sx={{
                      fontSize: '20px',
                      marginBottom: '15px',
                      fontWeight: '600',
                      color: '#0140AA',
                      display: 'flex'
                      // textTransform: 'uppercase'
                    }}
                  >
                    Demographic Information
                    <div title="Demographic data is collected for statistical purposes only. Data may be shared with clients only when it is a specific requirement for a gig.">
                      <HelpIcon sx={{ color: blue[500], fontSize: 16 }} />
                    </div>
                  </Box>
                  <Grid
                    container
                    spacing={3}
                  >
                    <Grid item xs={12} md={4} lg={4}>
                      <FormControl variant="outlined" fullWidth required>
                        <InputLabel>Gender</InputLabel>
                        <Select
                          name="gender"
                          value={values.gender}
                          placeholder="Select your gender."
                          label="Gender"
                          onChange={(e) => {
                            handleChange(e);
                            setDataChange(true);
                          }}
                          MenuProps={MenuProps}
                          InputLabelProps={{ shrink: true }}
                        >
                          <MenuItem value="">
                            <em>Select your gender</em>
                          </MenuItem>
                          <MenuItem value={'Male'}>Male</MenuItem>
                          <MenuItem value={'Female'}>Female</MenuItem>
                          <MenuItem value={'Rather not say'}>Rather not say</MenuItem>
                          <MenuItem value={'Other'}>Other</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4} lg={4}>
                      <FormControl variant="outlined" sx={{ width: '100%' }}>
                        <InputLabel id="demo-simple-select-standard-label">Nationality</InputLabel>
                        <Select
                          value={values.nationality}
                          onChange={(e) => {
                            handleChange(e);
                            setDataChange(true);
                            setFieldValue('nationality', e.target.value);
                            MenuProps = { MenuProps };
                          }}
                          label="Nationality"
                          MenuProps={MenuProps}
                          InputLabelProps={{ shrink: true }}
                        >
                          {countries.length > 0 &&
                            countries.map(({ name }) => {
                              return (
                                <MenuItem value={name} key={name}>
                                  {name}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4} lg={4}>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel>Race group</InputLabel>
                        <Select
                          name="racegroup"
                          value={values.racegroup}
                          label="RACE GROUP"
                          placeholder="Select your Race Group."
                          onChange={(e) => {
                            handleChange(e);
                            setDataChange(true);
                          }}
                          MenuProps={MenuProps}
                          InputLabelProps={{ shrink: true }}
                        >
                          <MenuItem value="">
                            <em>Select your Race Group</em>
                          </MenuItem>
                          <MenuItem value={'African'}>African</MenuItem>
                          <MenuItem value={'Coloured'}>Coloured</MenuItem>
                          <MenuItem value={'Indian/Asian'}>Indian/Asian</MenuItem>
                          <MenuItem value={'White'}>White</MenuItem>
                          <MenuItem value={'Rather not say'}>Rather not say</MenuItem>
                          <MenuItem value={'Other'}>Other</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4} lg={4}>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel>Disability</InputLabel>
                        <Select
                          name="disability"
                          value={values.disability}
                          label="Disability"
                          placeholder="Do you have any disability?"
                          onChange={(e) => {
                            handleChange(e);
                            setDataChange(true);
                          }}
                          MenuProps={MenuProps}
                          InputLabelProps={{ shrink: true }}
                        >
                          <MenuItem value="">
                            <em>Do you have any disability?</em>
                          </MenuItem>
                          <MenuItem value={'Yes'}>Yes</MenuItem>
                          <MenuItem value={'No'}>No</MenuItem>
                          <MenuItem value={'Rather not say'}>Rather not say</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    {/* <Grid item xs={12} md={3} lg={3}>
                <TextField
                  fullWidth
                  label="CHOOSE CURRENCY"
                  id="currencyName"
                  name="currencyName"
                  value={values.currencyName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.currencyName && Boolean(errors.currencyName)}
                  helperText={touched.currencyName && errors.currencyName}
                  variant="outlined"
                />
              </Grid> */}
                    {/* <Grid item xs={12} md={3} lg={3}>
                <FormControl
                  fullWidth
                  variant="outlined"
                  error={_get(touched, 'isRemote') && Boolean(_get(errors, 'isRemote'))}
                >
                  <InputLabel>Remote Job Prefered</InputLabel>
                  <Select
                    value={_get(values, 'isRemote')}
                    label="Remote Job Prefered"
                    onChange={(event) => {
                      setFieldValue('isRemote', event.target.value);
                    }}
                  >
                    <MenuItem value={'true'}>{`Yes`}</MenuItem>
                    <MenuItem value={'false'}>{`No`}</MenuItem>
                  </Select>
                </FormControl>
              </Grid> */}

                    {/* <Grid item xs={12} md={3} lg={3}>
                <TextField
                  fullWidth
                  label="JOB TYPE"
                  id="jobType"
                  name="jobType"
                  value={values.jobType}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.jobType && Boolean(errors.jobType)}
                  helperText={touched.jobType && errors.jobType}
                  variant="outlined"
                />
              </Grid> */}

                    {/* <Grid item xs={12} md={3} lg={3}>
                <Specialisation
                  selectedVal={values.fieldOfSpecialization}
                  setFieldValue={setFieldValue}
                  validationError={
                    touched.fieldOfSpecialization && Boolean(errors.fieldOfSpecialization)
                  }
                />
              </Grid>
              <Grid item xs={12} md={3} lg={3}>
                <FormControl
                  fullWidth
                  variant="outlined"
                  error={
                    _get(touched, 'levelOfExperience') && Boolean(_get(errors, 'levelOfExperience'))
                  }
                >
                  <InputLabel>LEVEL OF EXPERTISE</InputLabel>
                  <Select
                    value={_get(values, 'levelOfExperience')}
                    label="LEVEL OF EXPERTISE"
                    onChange={(event) => {
                      setFieldValue('levelOfExperience', event.target.value);
                    }}
                  >
                    <MenuItem value={'Entry'}>{`Entry (<3 years experience)`}</MenuItem>
                    <MenuItem
                      value={'Intermediate'}
                    >{`Intermediate (5-10 years experience)`}</MenuItem>
                    <MenuItem value={'Senior'}>{`Senior (10-20 years experience)`}</MenuItem>
                    <MenuItem value={'Expert'}>{`Expert (>20 years experience)`}</MenuItem>
                  </Select>
                </FormControl>
              </Grid> */}
                  </Grid>
                </CardContent>
              </Card>
              <Card sx={{ marginTop: '20px', marginBottom: '40px' }}>
                <CardContent>
                  <Box
                    sx={{
                      fontSize: '20px',
                      marginBottom: '15px',
                      fontWeight: '600',
                      color: '#0140AA',
                      // textTransform: 'uppercase'
                    }}
                  >
                    Language Proficiency
                  </Box>
                  <Grid container spacing={3}>
                    <FieldArray name="languageList">
                      {({ push, remove }) => (
                        <>
                          {values.languageList &&
                            values.languageList.map((p, i) => {
                              const level = `languageList[${i}].language`;
                              const touchedLevel = getIn(touched, level);
                              const errorLevel = getIn(errors, level);

                              const skill = `languageList[${i}].proficienyLevel`;
                              const touchedSkill = getIn(touched, skill);
                              const errorSkill = getIn(errors, skill);
                              const showRemove = values.languageList.length > 1;
                              return (
                                <React.Fragment key={`languageList${i}`}>
                                  <Grid item xs={12} md={4} lg={4}>
                                    <FormControl variant="outlined" fullWidth>
                                      <InputLabel>Language</InputLabel>
                                      <Select
                                        name={`languageList[${i}].language`}
                                        value={_get(p, `language`, '')}
                                        onChange={(event) => {
                                          handleChange(event.target.value);
                                          setDataChange(true);
                                          setFieldValue(
                                            `languageList[${i}].language`,
                                            event.target.value
                                          );
                                        }}
                                        placeholder="Select your language"
                                        onBlur={handleBlur}
                                        label="Language"
                                        MenuProps={MenuProps}
                                        InputLabelProps={{ shrink: true }}
                                      >
                                        {language.map((language) => (
                                          <MenuItem value={language.name}>{language.name}</MenuItem>
                                        ))}
                                      </Select>
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={12} md={4} lg={4}>
                                    <FormControl variant="outlined" fullWidth>
                                      <InputLabel>Proficiency</InputLabel>
                                      <Select
                                        name={`languageList[${i}].proficienyLevel`}
                                        placeholder="Select your Proficiency"
                                        value={_get(p, `proficienyLevel`, '')}
                                        onChange={(event) => {
                                          handleChange(event.target.value);
                                          setDataChange(true);
                                          setFieldValue(
                                            `languageList[${i}].proficienyLevel`,
                                            event.target.value
                                          );
                                        }}
                                        onBlur={handleBlur}
                                        label="Proficiency"
                                        MenuProps={MenuProps}
                                        InputLabelProps={{ shrink: true }}
                                      >
                                        <MenuItem value="">
                                          <em>Select your Proficiency</em>
                                        </MenuItem>
                                        <MenuItem value={'Basic'}>Basic</MenuItem>
                                        <MenuItem value={'Conversational'}>Conversational</MenuItem>
                                        <MenuItem value={'Fluent'}>Fluent</MenuItem>
                                        <MenuItem value={'Native or Bilingual'}>
                                          Native or Bilingual
                                        </MenuItem>
                                      </Select>
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={12} md={4} lg={4}>
                                    {showRemove && (
                                      <Button
                                        variant="outlined"
                                        sx={{
                                          width: '40px',
                                          minWidth: '40px',
                                          height: '40px',
                                          minHeight: '40px',
                                          border: 'none',
                                          borderRadius: '50%',
                                          boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
                                          marginTop: '6px'
                                        }}
                                        onClick={() => remove(i)}
                                      >
                                        <img src="https://gigengineer-production.s3.af-south-1.amazonaws.com/Assets/Delete.svg" alt="delete" width="200%" />
                                      </Button>
                                    )}
                                  </Grid>
                                </React.Fragment>
                              );
                            })}
                          <Grid item xs={12} md={12} lg={12}>
                            <Button
                              variant="outlined"
                              sx={{ borderColor: '#0140AA', color: '#0140AA', fontWeight: '600' }}
                              className="section-Button"
                              onClick={() => push({ language: '', proficienyLevel: '' })}
                            >
                              <AddIcon fontSize="small" /> Add languages
                            </Button>
                          </Grid>
                        </>
                      )}
                    </FieldArray>
                  </Grid>
                </CardContent>
              </Card>
              <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button
                  variant="contained"
                  className={`primary-Btn ${props.saveSuccess && 'successActive'}`}
                  type="submit"
                  onClick={() => {
                    setFieldValue("action", "save");
                  }}
                >
                  {props.saveSuccess ? 'saved' : 'save'}
                </Button>
                <Button
                  variant="contained"
                  className="primary-Main-Btn"
                  type="submit"
                  onClick={() => {
                    setFieldValue("action", "next");
                  }}
                >
                  Next
                </Button>
              </Grid>
            </Form>
          )}
        </Formik>
      </PaddedBox>
      <PaddedFooter style={{ paddingLeft: `${!!props.auth && !!props.auth.isDrawerOpen && props.auth.isDrawerOpen == true ? "200px" : "0px"}` }}>
        <Footer />
      </PaddedFooter>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    engineer: state.profile.basicInfo || {},
    languageList: state.profile.languageList || [],
    error: state.profile.error,
    saveSuccess: state.profile.saveSuccess,
    profile: state.profile
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setProfileError: (details) => dispatch(setProfileError(details)),
    setProfileSuccess: (details) => dispatch(setProfileSuccess(details)),
    setProfileDetails: (details) => dispatch(setProfileDetails(details))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Engineer);
