import React from 'react';
import { connect } from 'react-redux';
import { TextField, Button, Alert } from '@mui/material';
import { Formik, Form } from 'formik';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import { CustomBox, SignInDisclamer } from './SignInForm.styles';
import { resetPasswordScreenValidationSchema } from '../../utils/formikValidations';
import resetPasswordReq from '../../services/resetPassword';
import { setAuthSignError } from '../../store/actions/auth';
import ResetPasswordMsg from '../../containers/signin/ResetPasswordMsg';

const SignInForm = (props) => {
  const [err, setErr] = React.useState('');
  const [open, setOpen] = React.useState(false);

  return (
    <CustomBox>
      {err && (
        <Alert severity="error" className="h-margin-bottom-16">
          {err}
        </Alert>
      )}
      {(open === true) && (<ResetPasswordMsg
        open={open}
      />)}
      <Formik
        initialValues={{
          password: '',
          confirmPassword: ''
        }}
        enableReinitialize={true}
        validationSchema={resetPasswordScreenValidationSchema}
        onSubmit={async (values) => {
          const obj = { newPassword: values.password, verifyToken: props.token };
          if (values.password === values.confirmPassword) {
            const res = await resetPasswordReq(obj, props.setAuthSignError);
            setOpen(true);
          } else {
            setErr('Password and Confirm password should be same!');
          }
        }}
      >
        {({ values, touched, errors, handleChange, handleBlur }) => (
          <Form noValidate autoComplete="off">
            <h2 className='reset-pass-text'>Reset Password</h2>
            <div>
              <TextField
                fullWidth
                type="password"
                id="password"
                name="password"
                label="New Password"
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.password && Boolean(errors.password)}
                helperText={touched.password && errors.password}
                value={values.password}
                variant="outlined"
              />
            </div>
            <div>
              <TextField
              sx = {{marginTop: '20px'}}
                fullWidth
                type="password"
                id="confirmPassword"
                name="confirmPassword"
                label="Confirm New Password"
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.confirmPassword && Boolean(errors.confirmPassword)}
                helperText={touched.confirmPassword && errors.confirmPassword}
                value={values.confirmPassword}
                variant="outlined"
              />
            </div>
            <div className="h-padding-top-16">
              <Button fullWidth variant="contained" sx={{ "backgroundImage": "linear-gradient(-90deg,#0277bd,#0277bd)", "color": "#fff", marginTop: '30px'}} type="submit">
                RESET PASSWORD
              </Button>
            </div>
          </Form>
        )}
      </Formik>
      <br />
      <SignInDisclamer>
        By continuing, you agree to accept our Privacy Policy & Terms of Service.
      </SignInDisclamer>
    </CustomBox>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setAuthSignError: (details) => dispatch(setAuthSignError(details))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignInForm);
