import React, { useState } from 'react';
import { connect } from 'react-redux';
import _isEmpty from 'lodash/isEmpty';
import { Grid, Menu, MenuItem } from '@mui/material';
import SortIcon from '@mui/icons-material/Sort';
import { FilterIconbtn } from './sfs.styles';
import { setSortOrder } from '../../store/actions/sfs';
import { getUserType } from '../../utils/user';
import _get from 'lodash/get';
import PopUpPercentage from '../PopUpPercentage/PopUpPercentage';

const Sort = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const percentage = _get(props, 'profile.profileCompletionPercent', 0);
  const [openDashboard, setOpenDashboard] = useState(false);
  const userType = getUserType();
  const open = Boolean(anchorEl);

  const modalOpen = () => {
    setOpenDashboard(true);
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const selectSortBy = (type, order) => {
    setAnchorEl(null);
    props.setSortOrder({
      sortByKey: type,
      sortByOrder: order
    });
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const selectOption = (type, order, index) => {
    let retVal = false;
    if (props.sortBy.sortByKey === type && props.sortBy.sortByOrder === order) {
      retVal = true;
    }

    if (_isEmpty(props.sortBy) && index === 0) {
      retVal = true;
    }
    return retVal;
  };

  let MenuItems = [
    {
      name: 'Skill Match',
      value: 'SKILL_MATCH',
      order: 1
    },
    {
      name: 'Hourly Rate - Low to High',
      value: 'HOURLY_RATE',
      order: 1
    },
    {
      name: 'Hourly Rate - High to Low',
      value: 'HOURLY_RATE',
      order: -1
    },
    {
      name: 'Weekly Availablity - Low to High',
      value: 'WEEKLY_AVAILABLITY',
      order: 1
    },
    {
      name: 'Weekly Availablity - High to Low',
      value: 'WEEKLY_AVAILABLITY',
      order: -1
    },
  ];
  if (userType === 'admin') {
    MenuItems = [
      {
        name: 'Sort oldest to newest',
        value: 'CREATEDAT',
        order: 1
      },
      {
        name: 'Sort newest to oldest',
        value: 'CREATEDAT',
        order: -1
      }
    ]
  }

  if (props.sortType === 'gig') {
    MenuItems = [
      {
        name: 'Relevance',
        value: 'RELEVANCE',
        order: 1
      },
      ...MenuItems
    ];
  }

  return (
    <>
      {(userType === 'engineer' || userType === 'business') && (
        (percentage < 60) ?
          <FilterIconbtn type="submit" aria-label="search" onClick={modalOpen}>
            <SortIcon sx={{ width: '50px', height: '35px' }} />
          </FilterIconbtn>
          :
          <Grid>
            <FilterIconbtn type="submit" aria-label="search" onClick={handleClick}>
              <SortIcon sx={{ width: '50px', height: '35px' }} />
            </FilterIconbtn>
            <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
              {MenuItems.map((option, index) => (
                <MenuItem
                  key={index}
                  selected={selectOption(option.value, option.order, index)}
                  onClick={() => {
                    selectSortBy(option.value, option.order);
                  }}
                >
                  {option.name}
                </MenuItem>
              ))}
            </Menu>
          </Grid>
      )}
      {(userType === 'admin') && (
        <Grid>
          <FilterIconbtn type="submit" aria-label="search" onClick={handleClick}>
            <SortIcon sx={{ width: '50px', height: '35px' }} />
          </FilterIconbtn>
          <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
            {MenuItems.map((option, index) => (
              <MenuItem
                key={index}
                selected={selectOption(option.value, option.order, index)}
                onClick={() => {
                  selectSortBy(option.value, option.order);
                }}
              >
                {option.name}
              </MenuItem>
            ))}
          </Menu>
        </Grid>
      )}
      {openDashboard ? <PopUpPercentage modalOpen={openDashboard} modalClose={(e) => { setOpenDashboard(false) }} /> : <></>}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    sortBy: state.sfs.sortBy,
    myProfile: state.profile.myProfile || {},
    profile: state.profile,
    representative: state.profile.basicInfo || {},
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSortOrder: (details) => dispatch(setSortOrder(details))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Sort);
