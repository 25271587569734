import React, { useEffect, useState } from 'react';
import _get from 'lodash/get';
import { useHistory } from 'react-router-dom';
import _isEmpty from 'lodash/isEmpty';
import _toNumber from 'lodash/toNumber';
import { PaddedBox, PaddedFooter } from '../../app.styles';
import { Grid } from '@mui/material';
import GridTable from '../../../components/GridTable/GridTable';
import _last from 'lodash/last';
import { getQueryParams } from '../../../utils/helpers';
import { getGigs, updateGigStatus } from '../../../services/gig';
import Prompt from '../../../components/Prompt/Prompt';
import ActionDropDown from '../../../components/DropDown/DropDown';
import Typography from '@mui/material/Box';
import { connect } from 'react-redux';
import '../gigDashboard/GigDashboard.css';
import { getUserId } from '../../../utils/user';
import Footer from '../../../components/footer1/footer';
import IcFeedbackGive from '../../../assests/ic-feedback-give.svg';
import IcFeedbackRecieve from '../../../assests/ic-feedback-recieve.svg';
import IcFeedbackView from '../../../assests/ic-feedback-view.svg';
import PageLoader from '../../../components/Loaders/PageLoader';

const ClosedGigs = (props) => {
  const query = getQueryParams();
  const history = useHistory();
  const queryPage = query.page;
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(queryPage || 1);
  const [paginationVal, setPaginationVal] = useState(1);
  const [open, setOpen] = useState(false);
  const [promptData, setPromptData] = useState(undefined);
  const [messageBody, setMessageBody] = useState('');

  const createData = (name, duration, rate, hours, location, id, dropdown) => {
    if (rate === "") {
      rate = "Not Disclosed";
    }
    return [name, duration, rate, hours, location, id, dropdown];
  };

  const resetPage = (data) => {
    const dd = getDropDown(data.id, data.index);
    rows[data.index][5] = dd;
    setRows(rows);
  };

  const onPromptSelected = (data) => {
    setOpen(false);
    if (data.type === 'no') {
      resetPage(data);
    } else {
      resetPage(data);
      // action
      const obj = {
        _id: data.id,
        status: data.action === 'Active' ? 'ACTIVE' : 'INACTIVE'
      };
      updateGigStatus(
        obj,
        (response) => {
          //error response
        },
        (response) => {
          //success response
          fetchData(page);
        }
      );
    }
  };

  const getDropDown = (id, index, count, gig) => {
    const onDropDownValueChange = (action) => {
      const valueObj = {
        Active: 'activate',
        Inactive: 'deactivate'
      };
      if (action !== 'Close') {
        setPromptData({ action, id, index });
        setMessageBody(`Do you want to ${valueObj[action]} the gig?`);
        setOpen(true);
      }
    };
    return (
      <div style={{ display: "flex", flexDirection: "row", justifyContent: "end" }}>
        <ActionDropDown
          dropDownChange={onDropDownValueChange}
          defaultValue={'Close'}
          values={['Close']}
          editIcon={count}
          getEditOption={getEditOption}
          id={"closed"}
        />
        {
          !!gig.referralsData
          ?
          (
            gig.referralsData.length>1?
            <img title={"View submitted feedback"} style={{ marginLeft: "16.59px", cursor: "pointer" }} src={IcFeedbackView} alt="viewFeedback" onClick={()=>history.push(`/gig/review/business/viewSubmittedFeedback/${gig._id}`)} />
            :
            gig.referralsData.length==1&&gig.referralsData[0].giverId==getUserId()?
            <img title={"View submitted feedback"} style={{ marginLeft: "16.59px", cursor: "pointer" }} src={IcFeedbackView} alt="viewFeedback" onClick={()=>history.push(`/gig/review/business/viewSubmittedFeedback/${gig._id}`)} />
            :
            <img title={"Submit feedback"} style={{ marginLeft: "16.59px", cursor: "pointer" }} src={IcFeedbackGive} alt="writeFeedback" onClick={()=>history.push(`/gig/review/business/add/${id}`)} />
          )
          :
          <img title={"Submit feedback"} style={{ marginLeft: "16.59px", cursor: "pointer" }} src={IcFeedbackGive} alt="writeFeedback" onClick={()=>history.push(`/gig/review/business/add/${id}`)} />
        }
        {
          !!gig.referralsData
          ?
          gig.referralsData.length>1?
          <img title={"View received feedback"} style={{ marginLeft: "15px", cursor: "pointer" }} src={IcFeedbackRecieve} alt="viewRecievedFeedback" onClick={()=>history.push(`/gig/review/business/viewFeedback/${id}`)} />
          :
          gig.referralsData.length==1&&gig.referralsData[0].giverId!=getUserId()?
          <img title={"View received feedback"} style={{ marginLeft: "15px", cursor: "pointer" }} src={IcFeedbackRecieve} alt="viewRecievedFeedback" onClick={()=>history.push(`/gig/review/business/viewFeedback/${id}`)} />
          :
          <img title={"View received feedback"} style={{ marginLeft: "15px", cursor: "pointer", pointerEvents:"none", opacity: 0 }} src={IcFeedbackRecieve} alt="viewRecievedFeedback" onClick={()=>history.push(`/gig/review/business/viewFeedback/${id}`)} />
          :
          <img title={"View received feedback"} style={{ marginLeft: "15px", cursor: "pointer", pointerEvents:"none", opacity: 0 }} src={IcFeedbackRecieve} alt="viewRecievedFeedback" onClick={()=>history.push(`/gig/review/business/viewFeedback/${id}`)} />
        }
      </div>
    );
  };

  const getEditOption = (action, id) => {
    if (action == 'Edit') {
      setPromptData({ action, id });
      setMessageBody(`Do you want to ${action} this gig?`);
      setOpen(true);
    }
  };

  const setData = (data) => {
    const rowData = [];
    const gigList = _get(data, 'gigList', []);
    const paginationObject = _get(data, 'paginationResult', {});
    for (let i = 0; i < gigList.length; i++) {
      const gig = gigList[i];
      const name = _get(gig, 'titleAndSkills.projectTitle', '');
      const duration = _get(gig, 'applicantCount', 0);
      const rate = _get(gig, 'scopeAndBudget.hourlyRate', '') ? `${_get(gig, 'scopeAndBudget.currency', 'R')} ${_get(gig, 'scopeAndBudget.hourlyRate', '')} /hr` : '';
      const hours = _get(gig, 'scopeAndBudget.availabilityHoursPerWeek', '') ? `${_get(gig, 'scopeAndBudget.availabilityHoursPerWeek', '')} hrs/week` : '';
      const location = _get(gig, 'deliverablesAndLocation.location.country', '') ? _get(gig, 'deliverablesAndLocation.location.country', '') : "Remote";
      const id = _get(gig, '_id', '');
      // console.log(gig);
      // console.log(getUserId());
      rowData.push(
        createData(name, duration, rate, hours, location, getDropDown(id, i, duration, gig), { id, name })
      );
    }
    setRows(rowData);
    setPaginationVal(paginationObject.totalPages || 1);
  };

  const handleRowClick = (data) => {
    const gigData = _last(data);
    history.push(`/gig/closed/applicants/${gigData.id}?page=${page}&gigname=${gigData.name}`);
  };

  const handleRClick = (data) => {
    const gigData = _last(data);
    history.push(`/gig/closed/${gigData.id}`);
  };

  const fetchData = (currentPage) => {
    const obj = {
      paginationInput: {
        pageNumber: currentPage,
        pageSize: 10
      },
      status: ['CLOSED']
    };
    getGigs(obj, setData);
  };

  useEffect(() => {
    fetchData(page);
  }, []);

  const handleChange = (event, value) => {
    fetchData(value);
    setPage(value);
  };

  const headers = ['GIG NAME', 'APPLICATIONS', 'RATE', 'AVAILABILITY', 'LOCATION', 'ACTIONS'];

  return (
    <>
      <PaddedBox style={{ paddingLeft: `${!!props.auth && !!props.auth.isDrawerOpen && props.auth.isDrawerOpen == true ? "240px" : "0px"}`, minHeight: "580px" }}>
        <Grid container>
          <Typography
            className='font-fix'
            variant="h6"
            component="h6"
            color="pColor.main"
          >
            Closed Gigs
          </Typography>
          <Grid container className="table-Padding">
            <GridTable
              headers={headers}
              rows={rows}
              handleRowClick={handleRowClick}
              handleRClick={handleRClick}
              handleChange={handleChange}
              pagination={paginationVal}
              defaultPage={_toNumber(page)}
              emptyMessage="You don’t have any Closed Gigs"
            ></GridTable>
          </Grid>
        </Grid>
        {open ? (
          <Prompt
            message={messageBody}
            show={open}
            heading="Confirmation"
            data={promptData}
            values={['yes', 'no']}
            onPromptSelected={(data) => {
              onPromptSelected(data);
            }}
          ></Prompt>
        ) : (
          <></>
        )}
      </PaddedBox>
      <PaddedFooter style={{ paddingLeft: `${!!props.auth && !!props.auth.isDrawerOpen && props.auth.isDrawerOpen == true ? "240px" : "0px"}` }}>
        <Footer />
      </PaddedFooter>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};
export default connect(mapStateToProps)(ClosedGigs);

// export default ClosedGigs;
