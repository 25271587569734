import React, { useEffect, useState } from 'react';
import { FieldArray, Formik, Form, getIn } from 'formik';
import { connect } from 'react-redux';
import { Grid, TextField, Button, Box, CircularProgress, Dialog, Rating, Typography } from '@mui/material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import _times from 'lodash/times';
import { toast } from 'react-toastify';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import { Prompt, useHistory } from 'react-router-dom';
import { setCookie, getCookie } from '../../../../utils/cookie';
import {
  PaddedBox, PaddedFooter
} from '../../../app.styles';
import { educationValidationSchema } from '../../../../utils/formikValidations';
import {
  setProfileError,
  setProfileSuccess,
  resetProfileSuccess
} from '../../../../store/actions/profile';
import { setEngProfileApi } from '../../../../services/profile';
import { resetSuccessState } from '../../../../utils/helpers';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { Autocomplete } from '@mui/material';
import { getUniversityApi } from '../../../../services/expertisewithskills';
import { getUploadApi } from '../../../../services/upload';
import { createFilterOptions } from '@mui/material/Autocomplete';
import './Education.css';
import Footer from '../../../../components/footer1/footer';
import DragDropiconred from '../../../../assests/DragDropiconred.svg';
import { DragDropIcon } from '../DragDrop/DragDrop';
import { cancelProfileFeedBack, profileFeedBack } from '../../../../services/gig';

const filter = createFilterOptions();

const Education = (props) => {
  console.log(props)
  const history = useHistory();

  const defaultObject = {
    instituteName: '',
    degree: '',
    areaOfSpecialization: '',
    description: '',
    fromDate: null,
    toDate: null
  };
  const certification = !_isEmpty(_get(props, 'certification', []))
    ? props.certification
    : [{ certificateName: '', certificationFile: '', certificationFileName: '', certificationLoading: false }];
  const publication = !_isEmpty(_get(props, 'publication', []))
    ? props.publication
    : [{ publicationName: '', publicationUrl: '' }];

  const [datachange, setDataChange] = useState(false);

  const edu = !_isEmpty(_get(props, 'education', [])) ? props.education : [defaultObject];

  useEffect(() => {
    getUniversityList();
  }, [props]);

  const handelChange = async (filename, ind, { setFieldValue }) => {
    const checkedSize = 1024 * 1024 * 5;
    const selectedFileSize = filename && filename.size;
    if (selectedFileSize > checkedSize) {
      toast.error('File size should be less than 5 MB');
      return;
    }
    if (props.onSelect) {
      props.onSelect(filename);
    } else {
      setFieldValue(`certification[${ind}].certificationFileName`, filename.name);
      setFieldValue(`certification[${ind}].certificationLoading`, true);
      let img = await getUploadApi(filename);
      setDataChange(true);
      setFieldValue(`certification[${ind}].certificationFile`, img?.data?.fileUrl);
      setFieldValue(`certification[${ind}].certificationLoading`, false);
    }
  };

  const handelCloseDelete = async (ind, { setFieldValue }) => {
    setFieldValue(`certification[${ind}].certificationFileName`, null);
    setFieldValue(`certification[${ind}].certificationLoading`, true);
    setDataChange(true);
    setFieldValue(`certification[${ind}].certificationFile`, null);
    setFieldValue(`certification[${ind}].certificationLoading`, false);
  };

  const [universityList, setUniversityList] = useState([]);
  const percentage = _get(props, 'profile.profileCompletionPercent', 0);
  const [value1, setValue] = useState([]);
  const [openComplete, setOpenComplete] = useState(getCookie('setOpenComplete'));
  const [rating, setRating] = useState(1);
  // const [feedback, setFeedback] = useState('');
  const [ratingError, setRatingError] = useState('');
  const isFeedbackGiven = _get(props, 'profile.isFeedbackGiven', false);

  const handleCloseforComplete = () => {
    setOpenComplete(false);
    setCookie('setOpenComplete', false, 30);
    cancelMethod();
  };

  const BootstrapDialogTitle = (props) => {
    const { onClose } = props;

    return (
      <DialogTitle sx={{ m: 0, p: 2 }}>
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };

  BootstrapDialogTitle.propTypes = {
    onClose: PropTypes.func.isRequired,
  };

  const submitMethod = async () => {
    let feedback = document.getElementById("standard-search").value;
    let data = {
      feedback: feedback,
      rating: rating,
      isGiven: true,
      type: "profileCompletion"
    };
    if (data.rating === 0) {
      return setRatingError('Please give rating before submit.')
    }

    profileFeedBack(data).then((e)=>{
      setOpenComplete(false);
      toast.success("Success");
      setCookie('setOpenComplete', false, 30);
    })
  };

  const cancelMethod = async () => {
    // let feedback = document.getElementById("standard-search").value;
    let data = {
      isGiven: false,
      type: "profileCompletion"
    };

    cancelProfileFeedBack(data).then((e)=>{
      setOpenComplete(false);
    })
  };

  const getUniversityList = async () => {
    let university = await getUniversityApi();
    if (university?.data && university?.data.length > 0) {
      setUniversityList(university.data);
    }
  };

  const isDateRangeCorrect = (start, end) => {
    let startDate = new Date(start);
    let endDate = new Date(end);
    if (startDate >= endDate) {
      return false;
    } else {
      return true
    }
  }
  return (
    <React.Fragment>
      <Prompt when={datachange} message={() => "You have unsaved changes, Are you sure you want to leave?"} ></Prompt>
      {(percentage === 100 && !isFeedbackGiven && (getCookie('setOpenComplete') != false)) && (
        <div>
          <Dialog open={openComplete} onClose={handleCloseforComplete}>
            <Box
              sx={{ display: "flex", flexDirection: "column", padding: "20px" }}
            >
              <BootstrapDialogTitle id="customized-dialog-title" onClose={handleCloseforComplete}>
              </BootstrapDialogTitle>
              <h2>Hey there!</h2>
              <Typography>
                Congratulations on successfully completing your Gig Engineer
                profile! We would love to get feedback on your experience.
              </Typography>
              <Box sx={{ display: "flex" }}>
                <Typography sx={{ marginTop: "12px" }}>
                  <Rating
                    name="size-large"
                    defaultValue={rating}
                    size="large"
                    onChange={(event) => {
                      let starValue = event.target.value;
                      if (rating != parseInt(starValue)) {
                        setRating(parseInt(starValue));
                        setRatingError(null)
                      }
                      if (rating === parseInt(starValue)) {
                        setRating(0)
                        setRatingError('Please provide the rating.')
                      }
                    }}

                  />
                </Typography>
                <Box
                  sx={{ marginTop: "12px", marginLeft: "20px", fontSize: "20px" }}
                >
                  ({rating}/5)
                </Box>
              </Box>
              <Typography
                sx={{
                  fontSize: '12px',
                  color: 'red'
                }}
              >
                {ratingError}
              </Typography>
              <Box
                component="form"
                sx={{
                  "& .MuiTextField-root": { m: 1, width: "60ch" },
                  marginTop: "-10px",
                }}
                autoComplete="off"
              >
                <div>
                  <TextField
                    id="standard-search"
                    label="Feedback (Optional)"
                    // value={feedback}
                    type="search"
                    variant="standard"
                  />
                </div>
              </Box>
              <Box
                sx={{
                  textAlign: "right",
                }}
              >
                <Button
                  variant="contained"
                  className="popup-process"
                  type="submit"
                  sx={{
                    width: "120px",
                  }}
                  onClick={submitMethod}
                >
                  Submit
                </Button>
                <br />
              </Box>
            </Box>
          </Dialog>
        </div>
      )}
      <PaddedBox style={{ paddingLeft: `${!!props.auth && !!props.auth.isDrawerOpen && props.auth.isDrawerOpen == true ? "240px" : "0px"}` }}>
        <Formik
          initialValues={{
            education: edu,
            certification,
            publication
          }}
          enableReinitialize={true}
          validationSchema={educationValidationSchema}
        >
          {(formProps) => {
            const { values, touched, errors, handleChange, handleBlur, setFieldValue } = formProps;
            return (
              <Form
                noValidate
                autoComplete="off"
                onChange={() => {
                  resetSuccessState(props);
                }}
                onSubmit={(e) => {
                  e.preventDefault()
                  if (values.education && values.education.length > 0) {
                    for (let item of values.education) {
                      let range = isDateRangeCorrect(item.fromDate, item.toDate);
                      if (!range) {
                        toast.warning("Please select valid date range!");
                        return;
                      }
                    }
                  }
                  const obj = {
                    educationInfoList: values.education,
                    certificationList: values.certification,
                    publicationList: values.publication
                  };
                  {let tempArr = []
                  values.publication.map((item,index)=>{
                    if(item.publicationName == "" || item.publicationUrl == ""){
                      return
                    }
                    else{
                      if((item.publicationName != "" && item.publicationUrl != "")){
                        tempArr.push(item)
                      }
                    }
                  })
                  obj.publicationList = tempArr;}
                                  {
                    let certiArray = []
                  values.certification.map((item,index)=>{
                    if(item.certificateName == "" || item.certificationFile == "" || item.certificationFileName == ""){
                      return
                    }
                    else{
                      if((item.certificateName != "" && item.certificationFile != "" && item.certificationFileName != "")){
                        certiArray.push(item)
                      }
                    }
                  })
                  obj.certificationList = certiArray;
                  }

                  toast.success('Data Saved!');
                  setDataChange(false);
                  setEngProfileApi(obj, props.setProfileError, props.setProfileSuccess);
                }}
              >
                <Card sx={{ marginTop: '20px', marginBottom: '40px' }}>
                  <CardContent>
                    <Box
                      sx={{
                        fontSize: '20px',
                        marginBottom: '25px',
                        fontWeight: '600',
                        color: '#0140AA',
                        // textTransform: 'uppercase'
                      }}
                    >
                      Education
                    </Box>
                    <FieldArray name="education">
                      {({ push, remove }) => (
                        <>
                          {values.education.map((edu, i) => {
                            const instituteName = `education[${i}].instituteName`;
                            const touchedinstituteName = getIn(touched, instituteName);
                            const errorinstituteName = getIn(errors, instituteName);
                            const degree = `education[${i}].degree`;
                            const toucheddegree = getIn(touched, degree);
                            const errordegree = getIn(errors, degree);
                            const areaOfSpecialization = `education[${i}].areaOfSpecialization`;
                            const touchedareaOfSpecialization = getIn(touched, areaOfSpecialization);
                            const errorareaOfSpecialization = getIn(errors, areaOfSpecialization);
                            const fromDate = `education[${i}].fromDate`;
                            const touchedfromDate = getIn(touched, fromDate);
                            const errorfromDate = getIn(errors, fromDate);
                            const showRemove = values.education.length > 1;
                            return (
                              <>
                                <Grid
                                  container
                                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                                  rowSpacing={{ xs: 3, sm: 2, md: 3 }} key={`edu[${i}]`}>
                                  <Grid item xs={12} md={4} lg={4}>
                                    {/* <TextField
                                      fullWidth
                                      name={`education[${i}].instituteName`}
                                      value={_get(edu, 'instituteName')}
                                      label='SCHOOL'
                                      required
                                      onChange={(e) => {
                                        handleChange(e);
                                        setDataChange(true);
                                      }}
                                      variant="outlined"
                                      onBlur={handleBlur}
                                      error={touchedinstituteName && Boolean(errorinstituteName)}
                                      helperText={touchedinstituteName && errorinstituteName}
                                      {...formProps}
                                      InputLabelProps={{ shrink: true }}
                                    /> */}

                                    <Autocomplete
                                      name={`education[${i}].instituteName`}
                                      value={_get(edu, 'instituteName')}
                                      onChange={(event, newValue) => {
                                        if (newValue) {
                                          // Create a new value from the user input
                                          setFieldValue(`education[${i}].instituteName`, newValue.name);
                                        }
                                      }}
                                      filterOptions={(options, params) => {
                                        const filtered = filter(options, params);
                                        const { inputValue } = params;
                                        // Suggest the creation of a new value
                                        const isExisting = options.some((option) => inputValue === option.name);
                                        if (inputValue !== '' && !isExisting) {
                                          filtered.push({
                                            inputValue,
                                            name: `${inputValue}`,
                                          });
                                        }
                                        return filtered;
                                      }}
                                      // selectOnFocus
                                      handleHomeEndKeys
                                      id="free-solo-with-text-demo"
                                      options={universityList}
                                      getOptionLabel={(option) => {
                                        // Value selected with enter, right from the input
                                        if (typeof option === 'string') {
                                          return option;
                                        }
                                        // Regular option
                                        return option.name;
                                      }}
                                      renderOption={(props, option) => <li {...props}>{option.name}</li>}
                                      sx={{}}
                                      freeSolo
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          label="School or university"
                                          onBlur={handleBlur}
                                          onChange={(e) => { setFieldValue(`education[${i}].instituteName`, e.target.value) }}
                                          required
                                        />
                                      )}
                                    />

                                    {/* <Autocomplete
                                      freeSolo
                                      fullWidth
                                      id="school-uni"
                                      name={`education[${i}].instituteName`}
                                      options={universityList.map((option) => option.name)}
                                      value={_get(edu, 'instituteName')}
                                      onChange={(e, newValue) => {
                                        setDataChange(true);
                                        setFieldValue(`education[${i}].instituteName`, newValue);
                                      }}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          variant="outlined"
                                          label="School or university"
                                          required
                                        />
                                      )} /> */}
                                  </Grid>

                                  <Grid item xs={12} md={4} lg={4}>
                                    <TextField
                                      fullWidth
                                      name={`education[${i}].degree`}
                                      label='Degree'
                                      required
                                      value={_get(edu, 'degree')}
                                      onChange={(e) => {
                                        handleChange(e);
                                        setDataChange(true);
                                      }}
                                      variant="outlined"
                                      onBlur={handleBlur}
                                      error={toucheddegree && Boolean(errordegree)}
                                      helperText={toucheddegree && errordegree}
                                      {...formProps}
                                      InputLabelProps={{ shrink: true }}
                                    />
                                  </Grid>

                                  <Grid item xs={12} md={4} lg={4}>
                                    <TextField
                                      fullWidth
                                      name={`education[${i}].areaOfSpecialization`}
                                      label='Field of study'
                                      // required
                                      value={_get(edu, 'areaOfSpecialization')}
                                      onChange={(e) => {
                                        handleChange(e);
                                        setDataChange(true);
                                      }}
                                      variant="outlined"
                                      onBlur={handleBlur}
                                      error={touchedareaOfSpecialization && Boolean(errorareaOfSpecialization)}
                                      helperText={touchedareaOfSpecialization && errorareaOfSpecialization}
                                      {...formProps}
                                      InputLabelProps={{ shrink: true }}
                                    />
                                  </Grid>

                                  <Grid item xs={12} md={4} lg={4}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                      <DatePicker
                                        disableFuture
                                        openTo="year"
                                        views={['year', 'month', 'day']}
                                        label='Start date'
                                        name={`education[${i}].fromDate`}
                                        value={_get(edu, 'fromDate')}
                                        onChange={(newValue) => {
                                          setDataChange(true);
                                          setFieldValue(`education[${i}].fromDate`, newValue);
                                        }}
                                        renderInput={(params) => (
                                          <TextField
                                            fullWidth
                                            id="outlined-basic"
                                            required
                                            variant="outlined"
                                            InputLabelProps={{ shrink: true }}
                                            {...params}
                                            onBlur={handleBlur}
                                            error={touchedfromDate && Boolean(errorfromDate)}
                                            helperText={touchedfromDate && errorfromDate}
                                          />
                                        )}
                                      />
                                    </LocalizationProvider>
                                  </Grid>

                                  <Grid item xs={12} md={4} lg={4}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                      <DatePicker
                                        name={`education[${i}].toDate`}
                                        openTo="year"
                                        views={['year', 'month', 'day']}
                                        minDate={_get(edu, 'fromDate')}
                                        label="End date"
                                        value={_get(edu, 'toDate')}
                                        onChange={(newValue) => {
                                          setDataChange(true);
                                          setFieldValue(`education[${i}].toDate`, newValue);
                                        }}
                                        renderInput={(params) => (
                                          <TextField fullWidth id="outlined-basic" variant="outlined" InputLabelProps={{ shrink: true }} {...params} />
                                        )}
                                      />
                                    </LocalizationProvider>
                                  </Grid>

                                  <Grid item xs={12} md={6} lg={6} className="h-margin-top-10">
                                    <TextField
                                      fullWidth
                                      name={`education[${i}].description`}
                                      value={_get(edu, 'description')}
                                      label="Achievements"
                                      multiline
                                      rows={4}
                                      onChange={(e) => {
                                        handleChange(e);
                                        setDataChange(true);
                                      }}
                                      variant="outlined"
                                      {...formProps}
                                      InputLabelProps={{ shrink: true }}
                                    />
                                  </Grid>

                                  <Grid item xs={12} md={12} lg={12}>
                                    {showRemove && (
                                      <Button
                                        fontSize="small"
                                        onClick={() => remove(i)}
                                        variant="outlined"
                                        color="error"
                                        startIcon={<DeleteForeverIcon />}
                                      >
                                        DELETE
                                      </Button>
                                    )}
                                  </Grid>
                                </Grid>
                                {
                                  showRemove && (
                                    <Divider
                                      sx={{
                                        marginTop: '30px',
                                        marginBottom: '30px',
                                        borderColor: 'rgba(1, 64, 170, 0.8)'
                                      }}
                                    />
                                  )
                                }
                              </>
                            );
                          })}
                          <Grid item xs={12} md={6} lg={6} className="h-padding-top-16">
                            <Button
                              variant="outlined"
                              className="section-Button"
                              onClick={() => push(defaultObject)}
                            >
                              <AddIcon fontSize="small" /> Add education
                            </Button>
                          </Grid>
                        </>
                      )}
                    </FieldArray>
                  </CardContent>
                </Card>

                <Card sx={{ marginTop: '20px', marginBottom: '40px' }}>
                  <CardContent>
                    <Box
                      sx={{
                        fontSize: '20px',
                        marginBottom: '25px',
                        fontWeight: '600',
                        color: '#0140AA',
                        // textTransform: 'uppercase'
                      }}
                    >
                      Certifications
                    </Box>
                    <FieldArray name="certification">
                      {({ push, remove }) => (
                        <>
                          {values.certification &&
                            values.certification.map((p, i) => {
                              const certificate = `certification[${i}].certificateName`;
                              const touchedCertificate = getIn(touched, certificate);
                              const errorCertificate = getIn(errors, certificate);
                              const showRemove = values.certification.length > 1;
                              return (
                                <Grid
                                  container
                                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                                  rowSpacing={{ xs: 3, sm: 2, md: 3 }}
                                  sx={{ marginBottom: "30px", alignItems: "center" }}
                                >
                                  <Grid item xs={12} md={5} lg={5}>
                                    <TextField
                                      fullWidth
                                      name={`certification[${i}].certificateName`}
                                      label="Enter certification details"
                                      value={_get(p, 'certificateName')}
                                      onChange={(e) => {
                                        handleChange(e);
                                        setDataChange(true);
                                      }}
                                      onBlur={handleBlur}
                                      error={touchedCertificate && Boolean(errorCertificate)}
                                      helperText={touchedCertificate && errorCertificate}
                                      variant="outlined"
                                      InputLabelProps={{ shrink: true }}
                                    />
                                  </Grid>
                                  <Grid item xs={12} md={3} lg={3}>
                                    <div className='not-uploaded-cert'>
                                      {(!_get(p, 'certificationFileName')) &&
                                        <React.Fragment>
                                          {_get(p, 'certificationLoading') ? <CircularProgress className="upload-Loading"></CircularProgress> : props.icon ? props.icon :
                                            <div className='certificate-icon-drag'>
                                              <DragDropIcon onFileChange={(e) => handelChange(e, i, { ...formProps })} icons={<img src={DragDropiconred} alt="dragdropiconred" />}></DragDropIcon>
                                            </div>
                                          }
                                        </React.Fragment>
                                      }
                                      <div>
                                        {(_get(p, 'certificationFileName')) &&
                                          <Button
                                            title="Delete"
                                            component="label"
                                            variant="outlined"
                                            sx={{
                                              width: '40px',
                                              minWidth: '40px',
                                              height: '40px',
                                              minHeight: '40px',
                                              border: 'none',
                                              borderRadius: '50%',
                                              backgroundColor: '#ffff',
                                              boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
                                              ml: '40px'
                                            }}
                                            onClick={() => {
                                              handelCloseDelete(i, { ...formProps })
                                            }}
                                          >
                                            {_get(p, 'certificationLoading') ? <CircularProgress className="upload-Loading"></CircularProgress> : props.icon ? props.icon : (<HighlightOffIcon sx={{ color: 'red', cursor: 'pointer', fontSize: "28px" }} />)}
                                          </Button>
                                        }
                                      </div>
                                      <div className='textoverflow-small' title={_get(p, 'certificationFileName')}>
                                        {_get(p, 'certificationFileName') ? _get(p, 'certificationFileName') : ''}
                                      </div>
                                    </div>
                                  </Grid>
                                  <Grid item xs={12} md={3} lg={3}>
                                    {showRemove && (
                                      <Button
                                        variant="outlined"
                                        sx={{
                                          width: '40px',
                                          minWidth: '40px',
                                          height: '40px',
                                          minHeight: '40px',
                                          border: 'none',
                                          borderRadius: '50%',
                                          boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
                                          marginTop: '-10px'
                                        }}
                                        onClick={() => {
                                          remove(i);
                                          setDataChange(true);
                                        }}
                                      >
                                        <img src="https://gigengineer-production.s3.af-south-1.amazonaws.com/Assets/Delete.svg" alt="delete" width="200%" />
                                      </Button>
                                    )}
                                  </Grid>
                                </Grid>
                              );
                            })}

                          <Grid item xs={12} md={6} lg={6}
                            sx={{ marginTop: "-20px" }}
                          >
                            <Button
                              variant="outlined"
                              className="section-Button"
                              onClick={() => push({ certificateName: '', certificationFile: '' })}
                            >
                              <AddIcon fontSize="small" />  Add certifications
                            </Button>
                          </Grid>
                        </>
                      )}
                    </FieldArray>
                  </CardContent>
                </Card>
                <Card sx={{ marginTop: '20px', marginBottom: '40px' }}>
                  <CardContent>
                    <Box
                      sx={{
                        fontSize: '20px',
                        marginBottom: '25px',
                        fontWeight: '600',
                        color: '#0140AA',
                        // textTransform: 'uppercase'
                      }}
                    >
                      Publications
                    </Box>

                    <FieldArray name="publication">
                      {({ push, remove }) => (
                        <Grid item xs={12} md={12} lg={12}>
                          {values.publication &&
                            values.publication.map((p, i) => {
                              const pbcn = `publication[${i}].publicationName`;
                              const touchedPbcn = getIn(touched, pbcn);
                              const errorPbcn = getIn(errors, pbcn);
                              const pbcnurl = `publication[${i}].publicationUrl`;
                              const touchedpbcnurl = getIn(touched, pbcnurl);
                              const errorpbcnurl = getIn(errors, pbcnurl);
                              const showRemove = values.publication.length > 1;
                              return (
                                <Grid
                                  container
                                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                                  rowSpacing={{ xs: 3, sm: 2, md: 3 }}
                                  sx={{ marginBottom: "30px", alignItems: "center", alignItems: 'baseline' }}
                                >
                                  <Grid item xs={12} md={5} lg={5}>
                                    <TextField
                                      fullWidth
                                      name={`publication[${i}].publicationName`}
                                      label="Enter publication details"
                                      value={_get(p, 'publicationName')}
                                      onChange={(e) => {
                                        handleChange(e);
                                        setDataChange(true);
                                      }}
                                      onBlur={handleBlur}
                                      error={touchedPbcn && Boolean(errorPbcn)}
                                      helperText={touchedPbcn && errorPbcn}
                                      variant="outlined"
                                      InputLabelProps={{ shrink: true }}
                                    />
                                  </Grid>
                                  <Grid item xs={12} md={4} lg={4}>
                                    <TextField
                                      fullWidth
                                      name={`publication[${i}].publicationUrl`}
                                      label="Enter publication url"
                                      value={_get(p, 'publicationUrl')}
                                      onChange={(e) => {
                                        handleChange(e);
                                        setDataChange(true);
                                      }}
                                      onBlur={handleBlur}
                                      error={touchedpbcnurl && Boolean(errorpbcnurl)}
                                      helperText={touchedpbcnurl && errorpbcnurl}
                                      variant="outlined"
                                      InputLabelProps={{ shrink: true }}
                                    />
                                  </Grid>
                                  <Grid item xs={12} md={3} lg={3}>
                                    {showRemove && (
                                      <Button
                                        variant="outlined"
                                        sx={{
                                          width: '40px',
                                          minWidth: '40px',
                                          height: '40px',
                                          minHeight: '40px',
                                          border: 'none',
                                          borderRadius: '50%',
                                          boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
                                          marginTop: '28px'
                                        }}
                                        onClick={() => {
                                          remove(i);
                                          setDataChange(true);
                                        }}
                                      >
                                       <img src="https://gigengineer-production.s3.af-south-1.amazonaws.com/Assets/Delete.svg" alt="delete" width="200%" />
                                      </Button>
                                    )}
                                  </Grid>
                                </Grid>
                              );
                            })}

                          <Grid item xs={12} md={12} lg={12}>
                            <Button
                              variant="outlined"
                              className="section-Button"
                              onClick={() => push({ publicationName: '', publicationUrl: '' })}
                            >
                              + Add publications
                            </Button>
                          </Grid>
                        </Grid>
                      )}
                    </FieldArray>
                  </CardContent>
                </Card>
                <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Button
                    variant="contained"
                    className={`primary-Btn ${props.saveSuccess && 'successActive'}`}
                    type="submit"
                  >
                    {props.saveSuccess ? 'saved' : 'save'}
                  </Button>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </PaddedBox>
      <PaddedFooter style={{ paddingLeft: `${!!props.auth && !!props.auth.isDrawerOpen && props.auth.isDrawerOpen == true ? "200px" : "0px"}` }}>
        <Footer />
      </PaddedFooter>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    education: state.profile.educationInfoList,
    certification: state.profile.certificationList,
    publication: state.profile.publicationList,
    error: state.profile.error,
    saveSuccess: state.profile.saveSuccess,
    profile: state.profile
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setProfileError: (details) => dispatch(setProfileError(details)),
    setProfileSuccess: (details) => dispatch(setProfileSuccess(details)),
    resetProfileSuccess: (details) => dispatch(resetProfileSuccess(details)),
    setProfileDetails: (details) => dispatch(setProfileDetails(details))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Education);
