export const SET_AUTH_SIGN_ERROR = '@gig/auth/signin/error';
export const SET_DRAWER_DATA = "SET_DRAWER_DATA"
export const SET_DRAWER_STATE = "SET_DRAWER_STATE"

export const setAuthSignError = (data) => ({
  type: SET_AUTH_SIGN_ERROR,
  payload: data
});

export const setDrawerData = (data) => ({
  type: SET_DRAWER_DATA,
  payload: data
});

export const setDrawerState = (data) => ({
  type: SET_DRAWER_STATE,
  payload: data
});
