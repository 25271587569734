import React from 'react';
import { Link } from '@mui/material';
import { CustomStack } from './basicFooter.styles';

const BasicFooter = () => {
  return (
    <CustomStack direction="row" spacing={2} justifyContent="flex-end">
      <Link href="/" underline="hover">
        © Gig Engineer 2023.
      </Link>
      <Link href="/termsconditions" underline="hover">
        {' '}
        Terms and Conditions
      </Link>
      <Link href="/privacypolicy" underline="hover">
        Privacy Policy
      </Link>
    </CustomStack>
  );
};
export default BasicFooter;
