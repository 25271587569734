import { TableContainer, Typography, Paper, Table, TableBody, styled, TableCell, tableCellClasses, TableRow, TableHead, Pagination } from '@mui/material';
import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { CustomRow, PaddedBox, PaddedFooter } from '../../app.styles';
import { useState } from 'react';
import NoInvoiceGraphic from '../common/images/NoInvoiceGraphic.png'
import './styles.scss'
import { getUserType } from '../../../utils/user';
import { createUpdateInvoice, fetchInvoiceByStatus} from '../../../services/invoicing';
import { _get } from 'lodash/get';
import { _toNumber } from 'lodash/toNumber';
import { toast } from 'react-toastify';
import Footer from '../../../components/footer1/footer';
import { NumberFormatter } from '../../../utils/helpers';

function InvoiceConsolidated(props) {

    const dispatch = useDispatch();
    const history = useHistory();
    const userType = getUserType();
    const [paginationVal, setPaginationVal] = useState(1);
    const [page, setPage] = useState(1); // active enginner list page number
    const [count, setCount] = useState(1); // count of total engineer
    const [selectedStatus, setselectedStatus] = useState("Show All")
    
    const [invoiceList, setinvoiceList] = useState([]);

    const options = [
        "Show All",
        "Pending",
        "Payment Initiated",
        "Payment Received",
        "Paid To Engineer",
        "Rejected",
    ]

    const status = [
        "PENDING",
        "PAYMENTINITIATED",
        "PAYMENTRECIEVED",
        "PAIDTOENGINEER",
        "REJECTED",
    ]

    const [activestatusList, setactivestatusList] = useState(status)

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
          backgroundColor: theme.palette.common.black,
          color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
          fontSize: 14,
        },
    }));
      
    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
          backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
          border: 0,
        },
    }));

    const createTableRows = (data) => {
        let rows = []
        data.invoiceList.map((item,index)=>{
            rows.push({
                invoiceId: item._id,
                invoiceNumber: item.invoiceId,
                gigName: item.gigDetails.titleAndSkills.projectTitle,
                invoiceType: item.invoiceType,
                dateCreated: (new Date(item.invoiceDate).toLocaleDateString('en-in', {year:"numeric", month:"short", day:"numeric"})),
                amount: item.invoiceTotal,
                status: item.status,
                buttonActive: false,
                fullStory: item,
                currency: item.currency,
                gigId: item.gigId,
            })
        })
        setinvoiceList(rows)
    }

    const fetchData = (pageNumber = 1, st = status) => {
        fetchInvoiceByStatus({
            "paginationInput": {
                "pageNumber": pageNumber,
                "pageSize": 10
            },
            "status": st,
        }).then((e)=>{
            createTableRows(e)
            setListData(e)
        }).catch((err)=>{
            console.error(err)
        })
    }

    useEffect(() => {
        try{
            fetchData(1)
        }
        catch(e){
            console.error(e)
        }
    }, [])
    
    const handleRedirectToInvoice = (id) => {
        if(userType=="engineer"){
            history.push(`/engineer/invoices/${id}`)
        }
        else if(userType=="business"){
            history.push(`/business/invoices/${id}`)
        }
        else if(userType=="admin"){
            history.push(`/admin/invoices/${id}`)
        }
    }

    const handleChange = (event, View) => {
        fetchData(View);
        setPaginationVal(View);
    };

    const setListData = (data) => {
        setPage(data.paginationResult.currentPage);
        setCount(data.paginationResult.totalPages);
        setPaginationVal(data.paginationResult.totalPages);
    };

    const handleButtonActivate = (index,newStatus) => {
        let temp = JSON.parse(JSON.stringify(invoiceList));
        temp[index].status = newStatus;
        temp[index].buttonActive = true;
        setinvoiceList(temp);
    }

    const handleUpdateStatus = (index,invoiceObj) => {
        let data = {
            "_id": invoiceObj._id,
            "gigId":invoiceObj.gigId,
            "engineerId":invoiceObj.engineerId,
            "businessId":invoiceObj.businessId,
            "currency":invoiceObj.currency,
            "status": invoiceList[index].status,
            "invoiceType": invoiceObj.invoiceType,
            "invoiceDescription": invoiceObj.invoiceDescription,
            "hoursWorked":invoiceObj.hoursWorked,
            "hourlyRate":invoiceObj.hourlyRate,
            "invoiceTotal":invoiceObj.invoiceTotal,
            "finalTotal":invoiceObj.finalTotal,
            "invoiceDate": invoiceObj.invoiceDate,
            "proofPayment": invoiceObj.proofPayment,
            "fromDate": invoiceObj.fromDate,
            "toDate": invoiceObj.toDate,
            "rejectReason": invoiceList[index].status=="REJECTED"?"Rejected by Admin":""
        }
        try{
            createUpdateInvoice(data).then((e)=>{
                toast.success("Invoice Updated Successfully!")
                fetchData(page);

            }).catch((err)=>{console.error(e)})
        }
        catch(err){
            console.error(err)
        }
    }

    const handleChangeStatus = (selectedOption) => {
        setselectedStatus(selectedOption)
        let temp = []
        switch (selectedOption) {
            case "Show All":
                temp = status;
                break;
            case "Pending":
                temp.push(status[0]);
                break;
            case "Payment Initiated":
                temp.push(status[1]);            
                break;
            case "Payment Received":               
                temp.push(status[2]);                
                break;
            case "Paid To Engineer":
                temp.push(status[3]);                
                break;
            case "Rejected":
                temp.push(status[4]);                
                break;
            default:
                break;
        }
        fetchData(page,temp)
    }

    const handleGigClick = (gigId) => {
        if(userType=="admin"){
            history.push(`/admin/owner/allgigs/details/${gigId}`)
        }
        else if(userType=="business"){
            history.push(`/gig/active/${gigId}`)
        }
        else if(userType=="engineer"){
            history.push(`/active/${gigId}`)
        }
        else{
            console.error("Failed to get user type")
        }
    }

    return (
        <>
        <PaddedBox style={{ paddingLeft: `${!!props.auth && !!props.auth.isDrawerOpen && props.auth.isDrawerOpen==true?"240px":"0px"}`, minHeight: "580px"}}>
            <CustomRow container>
                <Typography variant="h6" component="h6" color="pColor.main" sx={{ fontWeight: 'Medium', textTransform: 'capitalize', fontSize: '22px' }}>                    
                    Invoices
                </Typography>
                <select className="status-dd" onChange={(e)=>{handleChangeStatus(options[e.target.selectedIndex])}}>
                    {
                        options.map((item,index)=>(
                            <option>{item}</option>
                        ))
                    }
                </select>
            </CustomRow>
            
            {
                !!invoiceList&&invoiceList.length>0?
                <div className='data-table-wrapper'>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 700 }} aria-label="customized table">
                            <TableHead>
                            <TableRow>
                                <TableCell align="center">Invoice Number</TableCell>
                                <TableCell align="center">Gig Name</TableCell>
                                <TableCell align="center">Invoice Type</TableCell>
                                <TableCell align="center">Date Created</TableCell>
                                <TableCell align="center">Amount</TableCell>
                                <TableCell align="center">Status</TableCell>
                                <TableCell align="center">Actions</TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                            {invoiceList.map((row,index) => (
                                <StyledTableRow key={row.invoiceNumber}>
                                <TableCell align="center"><span className='invoice-id' title={"Click to view details"} onClick={()=>{handleRedirectToInvoice(row.invoiceId)}}>{row.invoiceNumber}</span></TableCell>
                                <TableCell align="center"><span className='invoice-id' title={"Click to view details"} onClick={()=>{handleGigClick(row.gigId)}}>{row.gigName}</span></TableCell>
                                <TableCell align="center">{row.invoiceType}</TableCell>
                                <TableCell align="center">{row.dateCreated}</TableCell>
                                <TableCell align="center">{row.currency} {NumberFormatter(row.amount)}</TableCell>
                                <TableCell align="center">
                                    <select className='admin-select-dd' defaultValue={row.status} onChange={(e)=>{if(status[e.target.selectedIndex]!=row.status){handleButtonActivate(index,status[e.target.selectedIndex])}else{}}}>
                                        {
                                            status.map((item,indx)=>(
                                                <option value={item}>{options[indx+1]}</option>
                                            ))
                                        }
                                    </select>
                                </TableCell>
                                <TableCell align="center">
                                    {
                                        row.buttonActive?
                                        <button onClick={()=>{handleUpdateStatus(index,row.fullStory)}} className='btn-cta'>Confirm</button>
                                        :
                                        <button className='btn-cta disabled'>Confirm</button>
                                    }
                                </TableCell>
                                </StyledTableRow>
                            ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <br/>
                    <Pagination
                        page={page}
                        count={paginationVal}
                        shape="rounded"
                        className="pagination-root"
                        onChange={handleChange}
                        defaultPage={page}
                    />
                </div>
                :
                <div className='no-data-wrapper'>
                    <img src={NoInvoiceGraphic} />
                    <p>No invoices found</p>
                </div>
            }

        </PaddedBox>
         <PaddedFooter style={{ paddingLeft: `${!!props.auth && !!props.auth.isDrawerOpen && props.auth.isDrawerOpen == true ? "200px" : "0px"}` }}>
         <Footer />
       </PaddedFooter>
     </>
    )
}

const mapStateToProps = (state) => {
    return {
      auth: state.auth,
      gigs: state.gigs
    };
};

export default connect(mapStateToProps)(InvoiceConsolidated)
