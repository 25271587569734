import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import _isEmpty from 'lodash/isEmpty';
import _toNumber from 'lodash/toNumber';
import _last from 'lodash/last';
import { TypographyBold, PaddedBox, CustomRow, PaddedFooter } from '../../../app.styles';
import { getQueryParams } from '../../../../utils/helpers';
import { Grid } from '@mui/material';
import { discoverOwners } from '../../../../services/owners';
import GridTable from '../../../../components/GridTable/GridTable';
import {
  Typography, Box
} from '@mui/material';
import { connect } from 'react-redux';
import Footer from '../../../../components/footer1/footer';
const headers = [
  'COMPANY NAME',
  'WEBSITE URL',
  'CONTACT EMAIL',
  'PROFILE COMPLETION',
  'REGISTRATION NUMBER',
  'ACTIONS'
];

const Dashboard = (props) => {

  const query = getQueryParams();
  const queryPage = query.page;
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [promptData, setPromptData] = useState(undefined);
  const [messageBody, setMessageBody] = useState('');
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(queryPage || 1);
  const [paginationVal, setPaginationVal] = useState(1);

  const data = {
    filter: {
      userStatusList: []
    },
    paginationInput: {
      pageNumber: 1,
      pageSize: 20
    },
    status: ['ACTIVE']
  };

  const fetchData = async (currentPage) => {
    data['paginationInput']['pageNumber'] = currentPage;
    let res = discoverOwners(data);
    let list = await Promise.resolve(res);
    let rowData = list.ownerList.map((item) => [
      item?.companyDetails?.companyName || '-',
      item?.companyDetails?.websiteUrl || '-',
      item?.basicInfo?.emailId || '-',
      (item?.profileCompletionPercent && `${item?.profileCompletionPercent}%`) || '-',
      item?.companyDetails?.registerNumber || '-',
      <Box display="flex" height={26} sx={{ marginTop: '10px' }}>
        <Box className="viewAll">
          <span
          >
            Show All Gigs {'>'}
          </span>
        </Box>
      </Box>,
      item?.basicInfo?.userId
    ]);
    setRows(rowData);
    setPaginationVal(list.paginationResult.totalPages);
  };

  const handleChange = (event, value) => {
    fetchData(value);
    setPage(value);
  };

  const handleRowClick = (val) => {
    history.push(`/admin/owner/details/${val[val.length - 1]}`);
  };

  useEffect(() => {
    fetchData(page);
  }, []);

  return (
    <>
      <PaddedBox style={{ paddingLeft: `${!!props.auth && !!props.auth.isDrawerOpen && props.auth.isDrawerOpen == true ? "240px" : "0px"}`, minHeight: "580px" }}>
        <Grid container>
          <Typography
            variant="h6"
            component="h6"
            color="pColor.main"
            sx={{ fontWeight: 'Medium', textTransform: 'capitalize', fontSize: '22px', margin: '0 0 20px 0' }}
          >
            Gig Owners
          </Typography>
          <Grid container className="table-Padding">
            <GridTable
              headers={headers}
              rows={rows}
              dataFrom="ownerList"
              handleChange={handleChange}
              pagination={paginationVal}
              defaultPage={_toNumber(page)}
              emptyMessage="You don’t have any Gig Owner yet"
              handleRowClick={(val) => handleRowClick(val)}
            ></GridTable>
          </Grid>
        </Grid>
        {open ? (
          <Prompt
            message={messageBody}
            show={open}
            heading="Confirmation"
            data={promptData}
            values={['yes', 'no']}
            onPromptSelected={(data) => {
              onPromptSelected(data);
            }}
          ></Prompt>
        ) : (
          <></>
        )}
      </PaddedBox>
      <PaddedFooter style={{ paddingLeft: `${!!props.auth && !!props.auth.isDrawerOpen && props.auth.isDrawerOpen == true ? "240px" : "0px"}` }}>
        <Footer />
      </PaddedFooter>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};
export default connect(mapStateToProps)(Dashboard);
