import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import { connect } from 'react-redux';
import { Grid, TextField, Button, Avatar, Box, Typography, Rating } from '@mui/material';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import { PaddedBox, PaddedFooter } from '../../../app.styles';
import { companyDetailsValidationSchema } from '../../../../utils/formikValidations';
import { setBusinessProfileApi } from '../../../../services/profile';
import CircularProgress from '@mui/material/CircularProgress';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import {
  setProfileError,
  setProfileSuccess,
  resetProfileSuccess
} from '../../../../store/actions/profile';
import { resetSuccessState } from '../../../../utils/helpers';
import { createFilterOptions } from '@mui/material/Autocomplete';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Prompt, useHistory } from 'react-router-dom';
import { getUploadApi } from '../../../../services/upload';
import { toast } from 'react-toastify';
import { getCountryStateCity } from '../../../../services/expertisewithskills';
import { getUserType } from '../../../../utils/user';
import { Autocomplete } from '@mui/material';
import MuiPhoneNumber from 'material-ui-phone-number';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import { setCookie, getCookie } from '../../../../utils/cookie';
import ReactGA from "react-ga";
import Footer from '../../../../components/footer1/footer';
import DragDropiconred from '../../../../assests/DragDropiconred.svg';
import { DragDropIcon } from '../../engineer/DragDrop/DragDrop';
import './CompanyDetails.scss';
import { cancelProfileFeedBack, profileFeedBack } from '../../../../services/gig';
ReactGA.initialize(`G-1596JC7760`);
ReactGA.pageview(window.location.pathname + window.location.search);
const industry = [
  { title: 'Aerospace ' },
  { title: 'Agriculture' },
  { title: 'Automotive' },
  { title: 'Banking' },
  { title: 'Chemicals' },
  { title: 'Construction ' },
  { title: 'Consulting' },
  { title: 'Defence' },
  { title: 'Education ' },
  { title: 'Financial Services' },
  { title: 'Fast Moving Consumer Goods (FMCG)' },
  { title: 'Food & Beverage' },
  { title: 'Health Care' },
  { title: 'Information & Communications Technology (ICT)' },
  { title: 'Manufacturing' },
  { title: 'Maritime' },
  { title: 'Mining' },
  { title: 'Nuclear' },
  { title: 'Oil & Gas' },
  { title: 'Paper & Pulp' },
  { title: 'Pharmaceutical ' },
  { title: 'Power ' },
  { title: 'Renewable Energy ' },
  { title: 'Technology' },
  { title: 'Transportation' },
  { title: 'Utilities' },
  { title: 'Water' }
];

const filter = createFilterOptions();

const CompanyDetails = (props) => {
  const history = useHistory();

  const companyDefaults = {
    companyName: '',
    websiteUrl: '',
    registerNumber: '',
    tinNumber: '',
    linkedinUrl: '',
    contactNumber: '+27',
    industryType: '',
    physicalAddress: '',
    description: '',
    country: 'South Africa',
    state: '',
    city: '',
    areaCode: '',
    profilePic: '',
    companySize: ''
  };

  let company = { ...companyDefaults, ...props.companyDetails };

  const [value1, setValue] = useState('');
  const [countries, setCountries] = useState([]);
  const percentage = _get(props, 'profile.profileCompletionPercent', 0);
  const [optedCountry, setOptedCountry] = useState('');
  const [optedProvince, setOptedProvince] = useState('');
  const [optedCity, setOptedCity] = useState('');
  const [datachange, setDataChange] = useState(false);
  const [img1, setImg1] = useState(props.companyDetails?.profilePic);
  const [loading, setLoading] = useState(false);
  const [fileKey, setfileKey] = useState(Math.random().toString(36));
  const [openComplete, setOpenComplete] = useState(getCookie('setOpenComplete'));
  const [rating, setRating] = useState(1);
  // const [feedback, setFeedback] = useState('');
  const [ratingError, setRatingError] = useState('');
  const isFeedbackGiven = _get(props, 'profile.isFeedbackGiven', false);

  const getCountriesList = async () => {
    let countries = await getCountryStateCity();
    if (countries?.data && countries?.data.length > 0) {
      setCountries(countries.data);
    }
  };

  const handleCloseforComplete = () => {
    setOpenComplete(false);
    setCookie('setOpenComplete', false, 30);
    cancelMethod();
  };
  const BootstrapDialogTitle = (props) => {
    const { onClose } = props;

    return (
      <DialogTitle sx={{ m: 0, p: 2 }}>
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };

  BootstrapDialogTitle.propTypes = {
    onClose: PropTypes.func.isRequired,
  };

  const submitMethod = async () => {
    let feedback = document.getElementById("standard-search").value;
    let data = {
      feedback: feedback,
      rating: rating,
      isGiven: true,
      type: "profileCompletion"
    };
    if (data.rating === 0) {
      return setRatingError('Please give rating before submit.')
    }
    profileFeedBack(data).then((e)=>{
      setOpenComplete(false);
      toast.success("Success");
      setCookie('setOpenComplete', false, 30);
    })
  };

  const cancelMethod = async () => {
    // let feedback = document.getElementById("standard-search").value;
    let data = {
      isGiven: false,
      type: "profileCompletion"
    };
    cancelProfileFeedBack(data).then((e)=>{
      setOpenComplete(false);
    })
  };

  const handelChange = async (filename, e) => {
    setfileKey(Math.random().toString(36));
    const checkedSize = 1024 * 1024 * 5;
    const selectedFileSize = filename && filename.size;
    if (selectedFileSize > checkedSize) {
      toast.error('File size should be less than 5 MB');
      return;
    }

    if (props.onSelect) {
      props.onSelect(filename);
    } else {
      setLoading(true);
      let img = await getUploadApi(filename);
      setDataChange(true);
      setImg1(img?.data?.fileUrl);
      setLoading(false);
    }
  };

  const userType = getUserType();

  const handleDeleteChange = async (e) => {
    setDataChange(true);
    setImg1("");
  };

  const getCountryValue = (val) => {
    if (val) setOptedCountry(val);
  };

  const getProvinceValue = (val) => {
    if (val) setOptedProvince(val);
  };

  const getCityValue = (val) => {
    if (val) setOptedCity(val);
  };

  useEffect(() => {
    getCountriesList();
    setOptedCountry(props?.companyDetails?.country ? props.companyDetails.country : '');
    setOptedProvince(props?.companyDetails?.state);
    setOptedCity(props?.companyDetails?.city);
  }, [props]);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 300
      }
    }
  };

  return (
    <React.Fragment>
      <Prompt
        when={datachange}
        message={() => 'You have unsaved changes, Are you sure you want to leave?'}
      ></Prompt>
      {(percentage === 100 && !isFeedbackGiven && (getCookie('setOpenComplete') != false)) && (
        <div>
          <Dialog open={openComplete} onClose={handleCloseforComplete}>
            <Box
              sx={{ display: "flex", flexDirection: "column", padding: "20px" }}
            >
              <BootstrapDialogTitle id="customized-dialog-title" onClose={handleCloseforComplete}>
              </BootstrapDialogTitle>
              <h2>Hey there!</h2>
              <Typography>
                Congratulations on successfully completing your Gig Engineer
                profile! We would love to get feedback on your experience.
              </Typography>
              <Box sx={{ display: "flex" }}>
                <Typography sx={{ marginTop: "12px" }}>
                  <Rating
                    name="size-large"
                    defaultValue={rating}
                    size="large"
                    onChange={(event) => {
                      let starValue = event.target.value;
                      if (rating != parseInt(starValue)) {
                        setRating(parseInt(starValue));
                        setRatingError(null)
                      }
                      if (rating === parseInt(starValue)) {
                        setRating(0)
                        setRatingError('Please provide the rating.')
                      }
                    }}

                  />
                </Typography>
                <Box
                  sx={{ marginTop: "12px", marginLeft: "20px", fontSize: "20px" }}
                >
                  ({rating}/5)
                </Box>
              </Box>
              <Typography
                sx={{
                  fontSize: '12px',
                  color: 'red'
                }}
              >
                {ratingError}
              </Typography>
              <Box
                component="form"
                sx={{
                  "& .MuiTextField-root": { m: 1, width: "60ch" },
                  marginTop: "-10px",
                }}
                autoComplete="off"
              >
                <div>
                  <TextField
                    id="standard-search"
                    label="Feedback (Optional)"
                    // value={feedback}
                    type="search"
                    variant="standard"
                  />
                </div>
              </Box>
              <Box
                sx={{
                  textAlign: "right",
                }}
              >
                <Button
                  variant="contained"
                  className="popup-process"
                  type="submit"
                  sx={{
                    width: "120px",
                  }}
                  onClick={submitMethod}
                >
                  Submit
                </Button>
                <br />
              </Box>
            </Box>
          </Dialog>
        </div>
      )}
      <PaddedBox style={{ paddingLeft: `${!!props.auth && !!props.auth.isDrawerOpen && props.auth.isDrawerOpen == true ? "240px" : "0px"}` }}>
        <Formik
          initialValues={company}
          enableReinitialize={true}
          validationSchema={companyDetailsValidationSchema}
          onSubmit={(values) => {
            let finalData = { ...values, 'profilePic': img1, 'industryType': value1.title };
            const obj = { companyDetails: { ...finalData } };
            setDataChange(false);
            setBusinessProfileApi(obj, props.setProfileError, props.setProfileSuccess);
            toast.success('Data Saved!');
            if (values?.action === 'next') {
              setTimeout(() => {
                history.push(`/engineer/list`);
              }, 1000);
            }
          }}
        >

          {({ values, touched, errors, handleChange, handleBlur, setFieldValue }) => (

            <Form
              noValidate
              autoComplete="off"
              onChange={() => {
                resetSuccessState(props);
              }}
            >
              <Typography
                variant="h6"
                component="h6"
                color="pColor.main"
                sx={{ fontWeight: 'Medium', textTransform: 'capitalize', fontSize: '22px' }}
              >
                Company Details
              </Typography>

              <Card sx={{ marginTop: '20px', marginBottom: '40px' }}>
                <CardContent>
                  <Grid container spacing={2} xs={12} md={12} lg={12}>
                    <div className='company-details-items'>
                      <div className='company-detail-grid-data'>
                        <Grid item container spacing={3} xs={12} md={9} lg={9}
                        >
                          <Grid item xs={12} md={6} lg={6}>
                            <TextField
                              fullWidth
                              label="Company name"
                              id="companyName"
                              name="companyName"
                              value={values.companyName}
                              onChange={(e) => {
                                handleChange(e);
                                setDataChange(true);
                              }}
                              onBlur={handleBlur}
                              error={touched.companyName && Boolean(errors.companyName)}
                              helperText={touched.companyName && errors.companyName}
                              variant="outlined"
                            />
                          </Grid>

                          <Grid item xs={12} md={6} lg={6}>
                            {/* <TextField
                          fullWidth
                          id="industryType"
                          name="industryType"
                          label="INDUSTRY TYPE"
                          value={values.industryType}
                          onChange={(e) => {
                            handleChange(e);
                            setDataChange(true);
                          }}
                          onBlur={handleBlur}
                          error={touched.industryType && Boolean(errors.industryType)}
                          helperText={touched.industryType && errors.industryType}
                          variant="outlined"
                        />  */}
                            <Autocomplete
                              value={values.industryType}
                              onChange={(event, newValue) => {
                                if (newValue) {
                                  // Create a new value from the user input
                                  setValue(newValue);
                                }
                              }}
                              filterOptions={(options, params) => {
                                const filtered = filter(options, params);
                                const { inputValue } = params;
                                // Suggest the creation of a new value
                                const isExisting = options.some((option) => inputValue === option.title);
                                if (inputValue !== '' && !isExisting) {
                                  filtered.push({
                                    inputValue,
                                    title: `${inputValue}`,
                                  });
                                }
                                return filtered;
                              }}
                              // selectOnFocus
                              handleHomeEndKeys
                              id="free-solo-with-text-demo"
                              options={industry}
                              getOptionLabel={(option) => {
                                // Value selected with enter, right from the input
                                if (typeof option === 'string') {
                                  return option;
                                }
                                // Regular option
                                return option.title;
                              }}
                              renderOption={(props, option) => <li {...props}>{option.title}</li>}
                              sx={{}}
                              freeSolo
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Industry type"
                                  onBlur={handleBlur}
                                  onChange={(e) => { setValue({ title: e.target.value }) }}
                                  error={touched.industryType && Boolean(errors.industryType)}
                                  helperText={touched.industryType && errors.industryType}
                                />
                              )}
                            />
                            {/* <Autocomplete
                          value={values.industryType}
                          onChange={(event, newValue) => {
                            if (newValue) {
                              // Create a new value from the user input
                              setValue(newValue);
                            }
                          }}
                          filterOptions={(options, params) => {
                            const filtered = filter(options, params);
                            const { inputValue } = params;
                            // Suggest the creation of a new value
                            const isExisting = options.some((option) => inputValue === option.title);
                            if (inputValue !== '' && !isExisting) {
                              filtered.push({
                                inputValue,
                                title: `${inputValue}`,
                              });
                            }
                            return filtered;
                          }}
                          // selectOnFocus
                          handleHomeEndKeys
                          id="free-solo-with-text-demo"
                          options={industry}
                          getOptionLabel={(option) => {
                            // Value selected with enter, right from the input
                            if (typeof option === 'string') {
                              return option;
                            }
                            // Regular option
                            return option.title;
                          }}
                          renderOption={(props, option) => <li {...props}>{option.title}</li>}
                          sx={{ width: 300 }}
                          freeSolo
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="INDUSTRY TYPE"
                              onBlur={handleBlur}
                              error={touched.industryType && Boolean(errors.industryType)}
                              helperText={touched.industryType && errors.industryType}
                            />
                          )}
                        /> */}
                          </Grid>

                          <Grid item xs={12} md={6} lg={6}>
                            <MuiPhoneNumber
                              fullWidth
                              name="contactNumber"
                              label="Contact number"
                              value={values.contactNumber}
                              disableAreaCodes={true}
                              onChange={(e) => {
                                setFieldValue('contactNumber', e);
                                setDataChange(true);
                              }}
                              onBlur={handleBlur}
                              error={touched.contactNumber && Boolean(errors.contactNumber)}
                              helperText={touched.contactNumber && errors.contactNumber}
                              variant="outlined"
                              MenuProps={MenuProps}
                              InputLabelProps={{ shrink: true }}
                              required
                            />
                          </Grid>

                          <Grid item xs={12} md={6} lg={6}>
                            <FormControl variant="outlined" fullWidth>
                              <InputLabel>Company size</InputLabel>
                              <Select
                                name="companySize"
                                label="COMPANY SIZE"
                                value={values.companySize}
                                onChange={(e) => {
                                  handleChange(e);
                                  setDataChange(true);
                                }}
                                onBlur={handleBlur}
                                error={touched.companySize && Boolean(errors.companySize)}
                                helperText={touched.companySize && errors.companySize}
                              >
                                <MenuItem value="size">
                                  Select your company size
                                </MenuItem>
                                <MenuItem value={'1-10'}>1-10</MenuItem>
                                <MenuItem value={'11-50'}>11-50</MenuItem>
                                <MenuItem value={'50-200'}>50-200</MenuItem>
                                <MenuItem value={'200+'}>200+</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>

                          <Grid item xs={12} md={8} lg={8}>
                            <TextField
                              fullWidth
                              id="description"
                              name="description"
                              label="Company description"
                              value={values.description}
                              onChange={(e) => {
                                handleChange(e);
                                setDataChange(true);
                              }}
                              onBlur={handleBlur}
                              error={touched.description && Boolean(errors.description)}
                              helperText={touched.description && errors.description}
                              variant="outlined"
                              multiline
                              rows={3}
                            />
                          </Grid>

                          <Grid item xs={12} md={6} lg={6}>
                            <TextField
                              fullWidth
                              label="Company registration number"
                              id="registerNumber"
                              name="registerNumber"
                              value={values.registerNumber}
                              onChange={(e) => {
                                handleChange(e);
                                setDataChange(true);
                              }}
                              onBlur={handleBlur}
                              error={touched.registerNumber && Boolean(errors.registerNumber)}
                              helperText={touched.registerNumber && errors.registerNumber}
                              variant="outlined"
                            // required
                            />
                          </Grid>

                          <Grid item xs={12} md={6} lg={6}>
                            <TextField
                              fullWidth
                              id="tinNumber"
                              name="tinNumber"
                              label="Tax Identification Number (Vat No.)"
                              value={values.tinNumber}
                              onChange={(e) => {
                                handleChange(e);
                                setDataChange(true);
                              }}
                              onBlur={handleBlur}
                              error={touched.tinNumber && Boolean(errors.tinNumber)}
                              helperText={touched.tinNumber && errors.tinNumber}
                              variant="outlined"
                            />
                          </Grid>

                          <Grid item xs={12} md={6} lg={6}>
                            <TextField
                              fullWidth
                              label="Website URL"
                              id="websiteUrl"
                              name="websiteUrl"
                              value={values.websiteUrl}
                              onChange={(e) => {
                                handleChange(e);
                                setDataChange(true);
                              }}
                              onBlur={handleBlur}
                              error={touched.websiteUrl && Boolean(errors.websiteUrl)}
                              helperText={touched.websiteUrl && errors.websiteUrl}
                              variant="outlined"
                            />
                          </Grid>

                          <Grid item xs={12} md={6} lg={6}>
                            <TextField
                              fullWidth
                              id="linkedinUrl"
                              name="linkedinUrl"
                              label="Linkedin page URL"
                              value={values.linkedinUrl}
                              onChange={(e) => {
                                handleChange(e);
                                setDataChange(true);
                              }}
                              onBlur={handleBlur}
                              error={touched.linkedinUrl && Boolean(errors.linkedinUrl)}
                              helperText={touched.linkedinUrl && errors.linkedinUrl}
                              variant="outlined"
                            />
                          </Grid>
                        </Grid>
                      </div>
                      {/* <div className='dghedgrsdg'> */}
                      <Grid
                        item
                        xs={12}
                        md={3}
                        lg={3}
                        justifyContent="center"
                      >
                        <Box display="flex" justifyContent="center" alignItems="center" minHeight="188px">
                          <div className="avtar_profile">
                            <Avatar alt="Remy Sharp" src={img1} sx={{ width: 150, height: 150 }} />
                            <div className="avtar_group_profile">
                              <React.Fragment>
                                {loading ? <CircularProgress className="upload-Loading"></CircularProgress> : props.icon ? props.icon :
                                  <div className='profile-icon-drag'>
                                    <DragDropIcon onFileChange={(e) => handelChange(e)} icons={<img src={DragDropiconred} alt="dragdropiconred" />}></DragDropIcon>
                                  </div>
                                }
                              </React.Fragment>
                              <Button
                                variant="outlined"
                                component="label"
                                onClick={handleDeleteChange}
                                sx={{ width: '40px', minWidth: '40px', height: '40px', minHeight: '40px', border: 'none', borderRadius: '50%', backgroundColor: '#ffff', boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px' }}
                              >
                                <DeleteForeverOutlinedIcon
                                  sx={{ color: 'red', cursor: 'pointer' }}
                                  fontSize="small"
                                />
                              </Button>
                            </div>
                          </div>
                        </Box>
                        <Grid align="center"
                          item
                          xs={12}
                          md={12}
                          lg={12}
                        >
                          <div className='text-company-icon'
                          >
                            Company Logo
                          </div>

                        </Grid>
                      </Grid>
                      {/* </div> */}
                    </div>
                  </Grid>
                </CardContent>
              </Card>

              <Card sx={{ marginTop: '20px', marginBottom: '40px' }}>
                <div className='location-items'>
                <Typography
                  variant="h6"
                  component="h6"
                  color="pColor.main"
                  sx={{ fontWeight: 'Medium', textTransform: 'capitalize', fontSize: '22px', marginBottom: '10px', marginTop: '10px', marginLeft: '15px' }}
                >
                  Location
                </Typography>
                <CardContent>
                  <Grid container spacing={2} xs={12} md={12} lg={12}>
                    <Grid item container spacing={3} xs={12} md={9} lg={9}
                    >
                      <Grid item xs={12} md={6} lg={6}>
                        <FormControl variant="outlined" sx={{ width: '100%' }}>
                          <InputLabel id="demo-simple-select-standard-label">Country</InputLabel>
                          <Select
                            value={values.country}
                            onChange={(e) => {
                              handleChange(e.target.value);
                              setFieldValue('country', e.target.value);
                              getCountryValue(e.target.value);
                              setDataChange(true);
                            }}
                            label="Country"
                            required
                            MenuProps={MenuProps}
                            InputLabelProps={{ shrink: true }}
                          >
                            {countries.length > 0 &&
                              countries.map(({ name }) => {
                                return (
                                  <MenuItem value={name} key={name}>
                                    {name}
                                  </MenuItem>
                                );
                              })}
                          </Select>
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} md={6} lg={6}>
                        <FormControl variant="outlined" sx={{ width: '100%' }}>
                          <InputLabel id="demo-simple-select-standard-label">Province</InputLabel>
                          <Select
                            value={optedProvince}
                            onChange={(e) => {
                              handleChange(e.target.value);
                              setFieldValue('state', e.target.value);
                              getProvinceValue(e.target.value);
                              setDataChange(true);
                            }}
                            label="Province"
                            MenuProps={MenuProps}
                            InputLabelProps={{ shrink: true }}
                          >
                            {countries.length > 0 &&
                              countries.find((x) => x.name === values.country)?.states.map(({ name }) => {
                                return (
                                  <MenuItem value={name} key={name}>
                                    {name}
                                  </MenuItem>
                                );
                              })}
                          </Select>
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} md={6} lg={6}>
                        <FormControl fullWidth variant="outlined">
                          <InputLabel id="demo-simple-select-standard-label">City</InputLabel>
                          <Select
                            value={optedCity}
                            onChange={(e) => {
                              handleChange(e.target.value);
                              setFieldValue('city', e.target.value);
                              getCityValue(e.target.value);
                              setDataChange(true);
                            }}
                            label="City"
                            MenuProps={MenuProps}
                            InputLabelProps={{ shrink: true }}
                          >
                            {countries.length > 0 &&
                              countries.find((el) => el.name === values.country)
                                ?.states.find((el) => el.name === values.state)
                                ?.cities.map(({ name }) => {
                                  return (
                                    <MenuItem value={name} key={name}>
                                      {name}
                                    </MenuItem>
                                  );
                                })}
                          </Select>
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} md={8} lg={8}>
                        <TextField
                          fullWidth
                          id="physicalAddress"
                          name="physicalAddress"
                          label="Address"
                          value={values.physicalAddress}
                          onChange={(e) => {
                            handleChange(e);
                            setDataChange(true);
                          }}
                          onBlur={handleBlur}
                          error={touched.physicalAddress && Boolean(errors.physicalAddress)}
                          helperText={touched.physicalAddress && errors.physicalAddress}
                          variant="outlined"
                          multiline
                          rows={3}
                          required
                        />
                      </Grid>

                      <Grid item xs={12} md={6} lg={6}>
                        <TextField
                          fullWidth
                          id="areaCode"
                          name="areaCode"
                          label="Area code"
                          value={values.areaCode}
                          onChange={(e) => {
                            handleChange(e);
                            setDataChange(true);
                          }}
                          onBlur={handleBlur}
                          error={touched.areaCode && Boolean(errors.areaCode)}
                          helperText={touched.areaCode && errors.areaCode}
                          variant="outlined"
                          required
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
                </div>
              </Card>

              <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button
                  variant="contained"
                  className={`primary-Btn ${props.saveSuccess && 'successActive'}`}
                  type="submit"
                  onClick={() => {
                    setFieldValue("action", "save");
                  }}
                >
                  {props.saveSuccess ? 'saved' : 'save'}
                </Button>
                <Button
                  variant="contained"
                  className="primary-Main-Btn"
                  type="submit"
                  onClick={() => {
                    setFieldValue("action", "next");
                  }}
                >
                  Next
                </Button>
              </Grid>
            </Form>
          )}
        </Formik>
      </PaddedBox>
      <PaddedFooter style={{ paddingLeft: `${!!props.auth && !!props.auth.isDrawerOpen && props.auth.isDrawerOpen == true ? "240px" : "0px"}` }}>
        <Footer />
      </PaddedFooter>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    companyDetails: state.profile.companyDetails || {},
    error: state.profile.error,
    saveSuccess: state.profile.saveSuccess,
    profile: state.profile
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setProfileError: (details) => dispatch(setProfileError(details)),
    setProfileSuccess: (details) => dispatch(setProfileSuccess(details)),
    resetProfileSuccess: (details) => dispatch(resetProfileSuccess(details)),
    setProfileDetails: (details) => dispatch(setProfileDetails(details))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyDetails);