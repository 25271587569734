import { Typography, styled, TableCell, tableCellClasses, TableRow, Button } from '@mui/material';
import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { CustomRow, PaddedBox, PaddedFooter } from '../../app.styles';
import { useState } from 'react';
import './styles.scss'
import { fetchInvoiceById } from './../../../services/invoicing';
import DownloadIcon from '../common/images/DownloadIcon.svg'
import DownloadIconWhite from '../common/images/download-white.svg'
import EditIcon from '../common/images/edit_square.svg'
import { getUserType } from '../../../utils/user';
import EftModal from '../common/EftModal';
import { NumberFormatter, disableEnableBodyScroll, scrollOnTop } from '../../../utils/helpers';
import RejectModal from '../common/RejectModal';
import InvoiceModal from '../common/InvoiceModal';
import { setUnsetInvoiceType } from '../../../store/actions/gig';
import Footer from '../../../components/footer1/footer';
function InvoiceDetails(props) {

    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();

    const userType = getUserType();

    const [invoiceDetails, setinvoiceDetails] = useState(undefined)
    const [eftModalOpen, seteftModalOpen] = useState(false)
    const [rejectModalOpen, setrejectModalOpen] = useState(false)
    const [activeInvoice, setactiveInvoice] = useState(undefined)
    const [addInvoiceModalOpen, setaddInvoiceModalOpen] = useState(false)
    
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
          backgroundColor: theme.palette.common.black,
          color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
          fontSize: 14,
        },
    }));
      
    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
          backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
          border: 0,
        },
    }));

    const fetchData = () => {
        fetchInvoiceById({
            id: !!location.pathname.split('/engineer/invoices/')[1]?location.pathname.split('/engineer/invoices/')[1]:!!location.pathname.split('/business/invoices/')[1]?location.pathname.split('/business/invoices/')[1]:!!location.pathname.split('/admin/invoices/')[1]?location.pathname.split('/admin/invoices/')[1]:"63e3e3136be2eb5b0c6f4d0a"
        }).then((e)=>{
            setinvoiceDetails(e.invoice)
            dispatch(setUnsetInvoiceType({invoiceType: getStatus(e.invoice.status).split(" ")[0].toLowerCase()}))
            getStatus(e.invoice.status)
        }).catch((err)=>{console.error(err)})
    }

    useEffect(() => {
        fetchData()
        return () => {
          dispatch(setUnsetInvoiceType({invoiceType: undefined}))
        }
    }, [])
    
    const getStatus = (status) => {
        let result;
        switch (status) {
            case "PENDING":
                result = "Pending"
                break;
            case "PAYMENTINITIATED":
                result = "Pending"
                break;
            case "PAYMENTRECIEVED":
                if(userType=="business"){
                    result = "Paid"
                }
                else{
                    result = "Pending"
                }
                break;
            case "PAIDTOENGINEER":
                if(userType=="admin"){
                    result = "Paid to Engineer"
                }
                else{
                    result = "Paid"
                }
                break;
            case "REJECTED":
                result = "Rejected"
                break;
            default:
                console.error("Unable to process status")
                break;
        }
        return result;
    }

    
    const handleOpenEftModal = (gigData,invoiceId) => {
        scrollOnTop()
        disableEnableBodyScroll()
        seteftModalOpen(true)
    }

    const handleEftModalClose = () => {
        seteftModalOpen(false)
        disableEnableBodyScroll()
        fetchData()
    }

    
    const handleOpenRejectModal = (gigData,invoiceId) => {
        scrollOnTop()
        disableEnableBodyScroll()
        setrejectModalOpen(true)
    }

    const handleRejectModalClose = () => {
        setrejectModalOpen(false)
        disableEnableBodyScroll()
        fetchData()
    }

    const handleModalClose = () => {
        setaddInvoiceModalOpen(false)
        setactiveInvoice(undefined)
        fetchData()
    }

    const handleEditGig = () => {
        setaddInvoiceModalOpen(true)
    }

    return (
        <>
        <PaddedBox style={{ paddingLeft: `${!!props.auth && !!props.auth.isDrawerOpen && props.auth.isDrawerOpen==true?"240px":"0px"}`, minHeight: "580px" }}>
            {eftModalOpen && <EftModal activeInvoice={invoiceDetails} eftModalOpen={eftModalOpen} handleEftModalClose={handleEftModalClose} seteftModalOpen={seteftModalOpen} />}
            {rejectModalOpen && <RejectModal activeInvoice={invoiceDetails} rejectModalOpen={rejectModalOpen} handleRejectModalClose={handleRejectModalClose} setrejectModalOpen={setrejectModalOpen} />}
            {addInvoiceModalOpen && <InvoiceModal activeInvoice={invoiceDetails} addInvoiceModalOpen={addInvoiceModalOpen} handleModalClose={handleModalClose} setaddInvoiceModalOpen={setaddInvoiceModalOpen} />}
            <CustomRow sx={{display: "flex", justifyContent: "flex-start !important"}} container>
                <Button variant="outlined" sx={{marginRight:"12px"}} className="h-color-button-outlined smallButton" onClick={() => { history.go(-1); }}>Back</Button>
                <Typography variant="h6" component="h6" color="pColor.main" sx={{ fontWeight: 'Medium', textTransform: 'capitalize', fontSize: '22px' }}>                    
                    Invoice
                </Typography>
            </CustomRow>
            <div className='details-wrapper'>
                <div className='details-heading'>
                    <span className='label-wrapper'>
                        My Invoice 
                        <a target={'_blank'} href={!!invoiceDetails&&invoiceDetails.pdflink?invoiceDetails.pdflink:"#"}><img src={DownloadIcon}/></a>
                        {
                            userType=="engineer" && !!invoiceDetails && invoiceDetails.status=="REJECTED"
                            &&
                            <span className='edit-icon-engineer-side' onClick={()=>{handleEditGig()}}><img src={EditIcon}/></span>
                        }
                    </span>
                    <span className={`status ${!!invoiceDetails&&!!invoiceDetails.status?getStatus(invoiceDetails.status):""}`}>{!!invoiceDetails&&!!invoiceDetails.status?getStatus(invoiceDetails.status):""}</span>
                </div>
                <div className='data-info-wrapper'>
                    <div className='details-left'>
                        <p>Gig Owner: {!!invoiceDetails&&!!invoiceDetails.businessDetails?invoiceDetails.businessDetails.businessProfileData.basicInfo.name:""}</p>
                        <p>{!!invoiceDetails&&!!invoiceDetails.businessDetails?`${invoiceDetails.businessDetails.businessProfileData.companyDetails.physicalAddress}, ${invoiceDetails.businessDetails.businessProfileData.companyDetails.city}`:""}</p>
                        <p>{!!invoiceDetails&&!!invoiceDetails.businessDetails?`${invoiceDetails.businessDetails.businessProfileData.companyDetails.state}, ${invoiceDetails.businessDetails.businessProfileData.companyDetails.country}`:""}</p>
                    </div>
                    <div className='details-right'>
                        <p>Invoice Number: {!!invoiceDetails&&!!invoiceDetails.invoiceId?invoiceDetails.invoiceId:""}</p>
                        <p>Invoice Date: {!!invoiceDetails&&!!invoiceDetails.invoiceDate?(new Date(invoiceDetails.invoiceDate).toLocaleDateString('en-in', {year:"numeric", month:"short", day:"numeric"})):""}</p>
                        <p>Gig Engineer: {!!invoiceDetails&&!!invoiceDetails.engineerDetails?`${invoiceDetails.engineerDetails.profileData.basicInfo.name}`:""}</p>
                    </div>
                </div>
                <div className='details-table'>
                    <div className={`container ${!!invoiceDetails && invoiceDetails.status=="REJECTED"?"rejected-grid-container":""}`}>
                        <div className="GigName">Gig Name</div>
                        <div className="Description">Description</div>
                        <div className="Hourly-Rate">Hourly Rate</div>
                        <div className="Hours-Worked">Hours Worked</div>
                        {
                            !!invoiceDetails && invoiceDetails.status=="REJECTED"
                            &&
                            <div className="Rejection-Reason">Reason of Rejection</div>
                        }
                        <div className="Amount">{userType=="engineer"?"Amount":"Total Amount"}</div>
                        <div className="GigText" title={!!invoiceDetails&&!!invoiceDetails.gigDetails.titleAndSkills.projectTitle?invoiceDetails.gigDetails.titleAndSkills.projectTitle:""}><span>{!!invoiceDetails&&!!invoiceDetails.gigDetails.titleAndSkills.projectTitle?invoiceDetails.gigDetails.titleAndSkills.projectTitle:""}</span></div>
                        <div className="DescText" title={!!invoiceDetails&&!!invoiceDetails.invoiceDescription?invoiceDetails.invoiceDescription:""}><span>{!!invoiceDetails&&!!invoiceDetails.invoiceDescription?invoiceDetails.invoiceDescription:""}</span></div>
                        <div className="HourText">{!!invoiceDetails&&!!invoiceDetails.currency?invoiceDetails.currency:""} {!!invoiceDetails&&!!invoiceDetails.hourlyRate?(NumberFormatter(invoiceDetails.hourlyRate)):""}</div>
                        <div className="HwText">{!!invoiceDetails&&!!invoiceDetails.hoursWorked?invoiceDetails.hoursWorked:""}</div>
                        {
                            !!invoiceDetails && invoiceDetails.status=="REJECTED"
                            &&
                            <div className="RRText" title={!!invoiceDetails&&!!invoiceDetails.rejectReason?invoiceDetails.rejectReason:""}><span>{!!invoiceDetails&&!!invoiceDetails.rejectReason?invoiceDetails.rejectReason:""}</span></div>
                        }
                        <div className="AmountText">{!!invoiceDetails&&!!invoiceDetails.currency?invoiceDetails.currency:""} {userType=="engineer"?(!!invoiceDetails&&!!invoiceDetails.invoiceTotal? NumberFormatter(invoiceDetails.invoiceTotal):""):(!!invoiceDetails&&!!invoiceDetails.invoiceTotal? NumberFormatter(invoiceDetails.invoiceTotal):"")}
                        </div>
                        {
                            userType=="engineer"
                            &&
                            <>
                                <div className="SummaryText"><p>Gig Engineer Fee (10%)</p></div>
                                <div className="Summary">{!!invoiceDetails&&!!invoiceDetails.currency?invoiceDetails.currency:""} {!!invoiceDetails&&!!invoiceDetails.invoiceTotal?(NumberFormatter((invoiceDetails.invoiceTotal)*0.1)):""}</div>
                                <div className="TotalText"><p>Total Amount</p></div>
                                <div className="Total">{!!invoiceDetails&&!!invoiceDetails.currency?invoiceDetails.currency:""} {!!invoiceDetails&&!!invoiceDetails.finalTotal? NumberFormatter(invoiceDetails.finalTotal):""}</div>
                            </>
                        }
                    </div>
                </div>
                <div className='cta-container'>
                {
                    !!invoiceDetails&&invoiceDetails.proofPayment&&(invoiceDetails.proofPayment!=""&&invoiceDetails.proofPayment!="string")&&userType=="admin"
                    &&
                    <Button variant="contained" className="primary-Main-Btn" sx={{ display: "flex", alignItems: "center" }} onClick={() => {window.open(invoiceDetails.proofPayment, '_blank')}}><img src={DownloadIconWhite}/>Proof of Payment</Button>
                }
                {
                    userType == "business" && !!invoiceDetails && !!invoiceDetails.status && invoiceDetails.status == "PENDING"
                    &&
                    <>
                        <Button variant="contained" className="primary-Main-Btn" sx={{ width: "140px" }} onClick={() => {handleOpenEftModal()}}>Pay</Button>
                        <Button variant="contained" className="primary-Main-Btn" sx={{ width: "140px", marginRight: "0px !important", background: "#F99600", backgroundImage: "linear-gradient(to right, #F99600, #F99600) !important" }} onClick={() => {handleOpenRejectModal()}}>Reject</Button>
                    </>
                    }
                </div>
            </div>

        </PaddedBox>
         <PaddedFooter style={{ paddingLeft: `${!!props.auth && !!props.auth.isDrawerOpen && props.auth.isDrawerOpen == true ? "200px" : "0px"}` }}>
         <Footer />
       </PaddedFooter>
     </>
    )
}

const mapStateToProps = (state) => {
    return {
      auth: state.auth,
      gigs: state.gigs
    };
};

export default connect(mapStateToProps)(InvoiceDetails)
