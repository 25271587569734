import { Box, Grid, TableContainer, Typography, Paper, Table, TableBody, styled, TableCell, tableCellClasses, TableRow, TextField, Input, Button } from '@mui/material';
import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import DatePicker from '@mui/lab/DatePicker';
import { LocalizationProvider } from '@mui/lab';
import { InputUnstyled, TextareaAutosize } from '@mui/base';
import { useState, useRef } from 'react';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import './styles.scss'
import GigLogo from '../../../../assests/logos.png'
import { CalendarBox, CustomRow } from '../../../app.styles';
import { Form, Formik } from 'formik';
import { InvoiceSchema } from '../../../../utils/formikValidations';
import { getUserId, getUserType } from '../../../../utils/user';
import { getActiveGigs } from '../../../../services/engineerGig';
import { toast } from 'react-toastify';
import { createUpdateInvoice } from '../../../../services/invoicing';
import { getGigs } from '../../../../services/gig';
import CopyIcon from '../images/content_copy.svg'

function RejectModal(props) {
    const { setrejectModalOpen, rejectModalOpen, handleRejectModalClose, activeInvoice } = props;
    const dispatch = useDispatch();
    const history = useHistory();
    const userType = getUserType();
    const [fileProof, setfileProof] = useState()
    let options = [ 
        "Did not complete the gig on time", 
        "Invoice description not correct", 
        "Other"
    ];
    const [selectedReason, setselectedReason] = useState(options[0])
    const [otherReason, setotherReason] = useState("")
    

    const handleFormSubmit = () => {
        if(!!activeInvoice){
            let data = {
                "gigId": activeInvoice.gigId,
                "engineerId": activeInvoice.engineerId,
                "businessId": activeInvoice.businessId,
                "currency":activeInvoice.currency,
                "status": "REJECTED",
                "invoiceType": activeInvoice.invoiceType,
                "invoiceDescription": activeInvoice.invoiceDescription,
                "hoursWorked": Number(activeInvoice.hoursWorked),
                "hourlyRate": Number(activeInvoice.hourlyRate),
                "invoiceTotal": activeInvoice.invoiceTotal,
                "finalTotal": activeInvoice.finalTotal,
                "invoiceDate": (new Date()).toISOString(),
                "fromDate": activeInvoice.fromDate,
                "toDate": activeInvoice.toDate,
                "_id": activeInvoice._id,
                "rejectReason": selectedReason=="Other"?otherReason:selectedReason,
            }

            try{
                createUpdateInvoice(data).then((e)=>{
                    toast.success("Submitted Successfully!")
                    handleRejectModalClose();
                }).catch((err)=>{console.error(e)})
            }
            catch(err){
                console.error(err)
            }
        }
        else{
            toast.error("Form not filled properly. Please try again!")
        }

    }

    return (
        <div className="reject-modal-wrapper">
            <div className="modal-card">
                <div className="modal-header">
                    Reject Invoice
                    <span className='cross' onClick={()=>{handleRejectModalClose()}}>&times;</span>
                </div>
                <p className="modal-infotext">Why do you want to reject this invoice?</p>
                <select className="reject-dd-list" defaultValue={selectedReason} onChange={(e)=>{setselectedReason(options[e.target.selectedIndex])}}>
                    {options.map((item,index)=>(
                        <option>{item}</option>
                    ))}
                </select>
                {
                    selectedReason=="Other"
                    &&
                    <span>
                        <br/>
                        <textarea maxLength={100} placeholder={'Tell us why (Max 100 Characters)'} type={"text"} value={otherReason} onChange={(e)=>{setotherReason(e.target.value)}} />
                        <br/>
                    </span>
                }
                <button className='confirm-cta' onClick={()=>{handleFormSubmit()}}>Submit</button>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
      auth: state.auth,
      gigs: state.gigs
    };
};

export default connect(mapStateToProps)(RejectModal)
