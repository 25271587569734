import React from 'react'
import { toast } from 'react-toastify';
import { verifyEngineerUser } from '../../services/profile'
import './verifyProfile.scss'

function VerifyProfile(props) {
    const { handleCloseVerifyModal, currentUser } = props;

    const handleVerify = () => {
        verifyEngineerUser({
            "isVerified": true,
            "userId": currentUser.basicInfo.userId
        }).then((e)=>{
            toast.success(`User verified sucessfully!`);
            handleCloseVerifyModal();
        }).catch((err)=>{
            toast.error("Error verifying user. Please try again!");
            handleCloseVerifyModal();
        })
    }

    return (
        <div className='verify-modal-wrapper'>
            <div className="modal-card">
                <div className="modal-top">
                    <h2>Verify Profile</h2>
                    <span onClick={()=>{handleCloseVerifyModal();}}>&times;</span>
                </div>
                <div className="modal-text">
                    Are you sure you want to verify this profile?
                </div>
                <div className='cta-wrapper'>
                    <button className='confirm-cta cancel' onClick={()=>{handleCloseVerifyModal();}}>Cancel</button>
                    <button className='confirm-cta' onClick={handleVerify}>Verify</button>
                </div>
            </div>
        </div>
    )
}

export default VerifyProfile