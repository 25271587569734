import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {
  Grid,
  CardHeader,
  CardContent,
  Toolbar,
  Avatar,
  Link,
  Typography,
  Drawer,
  Button
} from '@mui/material';
import { getProfileOwnerDetByIdApi } from '../../../../../services/owners';
import { ProfileNavContainer, ProfileTitle } from '../gigOwner.styles';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _find from 'lodash/find';
import { TypographyBold, PaddedBox, CustomRow, EditedCard, PaddedFooter } from '../../../../app.styles';
import { red } from '@mui/material/colors';
import { getGigs } from '../../../../../services/engineerGig';
import DP from '../../../../../assests/2.jpg';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Pagination from '@mui/material/Pagination';
import { Box } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import SettingsApplicationsOutlinedIcon from '@mui/icons-material/SettingsApplicationsOutlined';
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { connect } from 'react-redux';
import { formatDate, getDurationLabel, getGigLocation, getGigLocationDis, getQueryParams, scrollOnTop } from '../../../../../utils/helpers';
import Footer from '../../../../../components/footer1/footer';
import Hours from "../../../../../assests/Hours.svg";
import Days from "../../../../../assests/Days.svg";
import Weeks from "../../../../../assests/Weeks.svg";
import Months from "../../../../../assests/Months.svg";

function BasicBreadcrumbs() {
  const { id } = useParams();
  const history = useHistory();
  return (
    <div role="presentation">
      <Breadcrumbs aria-label="breadcrumb">
        <Link
          underline="hover"
          component="button"
          onClick={() => history.push('/admin/business/profile')}
        >
          Owner List
        </Link>
        <Link
          underline="hover"
          component="button"
          onClick={() => history.push(`/admin/owner/details/${id}`)}
        >
          Owner Details
        </Link>
        <Typography color="text.primary">All Gigs</Typography>
      </Breadcrumbs>
    </div>
  );
}

const getSkillsList = (skills) => {
  return Array.prototype.map.call(skills, (s) => ` ${s.engineerSkill}`).toString();
};

const AllGigs = (props) => {
  const drawerWidth = 240;
  const { id } = useParams();
  const history = useHistory();
  const [list, setList] = useState([]);
  const [profileDet, setProfileDet] = useState({});
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(1);

  const setListData = (data) => {
    setList([..._get(data, 'gigList', [])]);
    setPage(_get(data, 'paginationResult.currentPage', {}) || 1);
    setCount(_get(data, 'paginationResult.totalPages', {}) || 1);
  };

  const fetchMoreData = (currentPage) => {
    const obj = {
      paginationInput: {
        pageNumber: currentPage,
        pageSize: 12
      },
      filter: {
        gigStatusList: ['ACTIVE', 'INACTIVE', 'CLOSED'],
        businessUserId: id
      }
    };
    getGigs(obj, setListData);
  };
  const handleChange = (event, value) => {
    fetchMoreData(value);
    setPage(value);
  };

  useEffect(() => {
    async function someasync() {
      const details = await getProfileOwnerDetByIdApi(id);
      setProfileDet(details);
    }
    someasync();
    fetchMoreData(page);
  }, []);

  return (
    <Grid container spacing={2}>
      {/* Start of Left Nav */}
      <Grid
        container
        item
        xs="auto"
        sx={{
          display: { xs: 'none', lg: 'block' },
          padding: '5px'
        }}
      >
        <Drawer
          variant="permanent"
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' }
          }}
        >
          <Toolbar />
          <ProfileNavContainer>
            <Toolbar />
            <Grid container item xs={12} justifyContent="center">
              <Avatar
                alt="image"
                src={_get(profileDet, 'companyDetails.profilePic') || DP}
                sx={{ width: 150, height: 150 }}
              />
            </Grid>
            <Toolbar />
            <ProfileTitle>
              {_get(profileDet, 'companyDetails.companyName') || 'Not Disclosed'}
            </ProfileTitle>
          </ProfileNavContainer>
        </Drawer>
      </Grid>

      {/* End of Left Nav */}
      <Grid container item xs>
        <PaddedBox style={{ paddingLeft: `${!!props.auth && !!props.auth.isDrawerOpen && props.auth.isDrawerOpen == true ? "240px" : "0px"}`, minHeight: "580px" }} >
          {BasicBreadcrumbs()}
          <Typography
            variant="h6"
            component="h6"
            color="pColor.main"
            sx={{ fontWeight: 'Medium', textTransform: 'capitalize', fontSize: '22px', margin: '20px 0 0 0' }}
          >
            All Gigs
          </Typography>
          <Grid container item className="section-Margin" spacing={4} xs={12} md={12} lg={12}>
            {list.map((job, index) => (

              <Grid item xs={1} md={4} lg={4} key={index}>
                <EditedCard
                  sx={{ minHeight: '360px', height: "450px" }}
                  onClick={() => {
                    history.push(`/admin/owner/details/${job._id}/allgigs/gigdetails`, job);
                  }}
                >
                  <CardHeader
                    sx={{ position: "absolute", top: "0", left: "0" }}
                    action={
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        sx={{
                          color: '#000000',
                          textAlign: 'center',
                          fontSize: '11px',
                          lineHeight: '18px',
                          lineHeight: '100%',
                          fontStyle: 'regular',
                          fontFamily: 'poppins',
                          fontWeight: '600',
                          display: 'flex',
                          marginTop: '10px'
                        }}
                      >
                        Posted on:
                        <Box sx={{ marginLeft: '3px' }}>
                          {_get(job, 'createdAt') ? formatDate(_get(job, 'createdAt', '')) : '-'}
                        </Box>
                      </Typography>
                    }
                  />
                  <Box
                    sx={{ cursor: 'pointer', marginTop: '20px' }}
                  >
                    <Avatar
                      src={_get(job, 'companyDetails.logo', '')}
                      sx={{
                        width: 90,
                        height: 90,
                        objectFit: 'cover',
                        margin: 'auto',
                        // border: '3px solid #eee',
                        boxShadow: 'rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset',
                        backgroundColor: '#CCD8EE'
                      }}
                    >
                    </Avatar>
                    <Typography
                      title='Project Title'
                      variant="h6"
                      component="h6"
                      sx={{
                        color: '#5E6D55',
                        fontWeight: 'Medium',
                        textTransform: 'capitalize',
                        fontSize: '18px',
                        textAlign: 'center',
                        marginTop: '20px'
                      }}
                    >{_get(job, 'titleAndSkills.projectTitle', '') ? _get(job, 'titleAndSkills.projectTitle', '') : "-"}</Typography>
                  </Box>
                  <Box>
                    <CardContent className="card-Content">
                      <List sx={{ marginTop: '20px' }}>
                        <ListItem disablePadding sx={{ cursor: 'default' }} title="Skills">
                          <ListItemIcon sx={{ minWidth: '20px' }}>
                            <SettingsApplicationsOutlinedIcon
                              sx={{ fontSize: '16px', color: '#000' }}
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Typography
                                sx={{
                                  fontSize: '13px',
                                  color: '#5E5E5E',
                                  whiteSpace: 'nowrap',
                                  overflow: 'hidden'
                                }}
                              >
                                {getSkillsList(_get(job, 'titleAndSkills.skillsRequired', []))}
                              </Typography>
                            }
                          />
                        </ListItem>
                        <ListItem disablePadding sx={{ cursor: 'default' }} title="Location">
                          <ListItemIcon sx={{ minWidth: '20px' }}>
                            <HomeOutlinedIcon sx={{ fontSize: '16px', color: '#000' }} />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Typography
                                sx={{
                                  fontSize: '13px',
                                  color: '#5E5E5E',
                                  whiteSpace: 'nowrap',
                                  overflow: 'hidden'
                                }}
                              >
                                {getGigLocationDis(
                                  _get(job, 'deliverablesAndLocation'),
                                  _get(job, 'deliverablesAndLocation')
                                )}
                              </Typography>
                            }
                          />
                        </ListItem>
                        <ListItem disablePadding sx={{ cursor: 'default' }} title="Duration">
                          <ListItemIcon sx={{ minWidth: '20px' }}>
                            <AssignmentTurnedInOutlinedIcon sx={{ fontSize: '16px', color: '#000' }} />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Typography
                                sx={{
                                  fontSize: '13px',
                                  color: '#5E5E5E',
                                  whiteSpace: 'nowrap',
                                  overflow: 'hidden'
                                }}
                              >
                              {_get(job, 'scopeAndBudget.projectDuration', '0')} {getDurationLabel(_get(job, 'scopeAndBudget.durationUnit', 'Hours'))}
                              </Typography>
                            }
                          />
                        </ListItem>
                        <ListItem disablePadding sx={{ cursor: 'default' }} title="Rate">
                          <ListItemIcon sx={{ minWidth: '20px' }}>
                            <AccessTimeIcon sx={{ fontSize: '16px', color: '#000' }} />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Typography
                                sx={{
                                  fontSize: '13px',
                                  color: '#5E5E5E',
                                  whiteSpace: 'nowrap',
                                  overflow: 'hidden'
                                }}
                              >
                                {_get(job, 'scopeAndBudget.hourlyRate')
                                  ? ` ${_get(job, 'scopeAndBudget.currency', 'R')} ${_get(job, 'scopeAndBudget.hourlyRate')}/hr `
                                  : 'Not Disclosed'}
                              </Typography>
                            }
                          />
                        </ListItem>
                      </List>
                      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: '5px' }}>
                        <Link
                          component="button"
                          variant="caption"
                        >
                          <Button
                            className="admin-all-btn"
                            sx={{ color: "#FFFFFF", borderRadius: '10px', width: '190px', height: '45px', textTransform: 'capitalize' }}
                          >
                            more
                          </Button>
                        </Link>
                      </Box>
                    </CardContent>
                  </Box>
                </EditedCard>
              </Grid>
            ))}
            {list.length === 0 && (
              <div className="emptyGigs">
                <span>You don’t have any GIGs</span>
              </div>
            )}
          </Grid>
        </PaddedBox>
        <Box sx={{ marginTop: '40px' }}>
          <Pagination
            page={page}
            count={count}
            shape="rounded"
            className="pagination-root"
            onChange={handleChange}
            defaultPage={1}
          />
        </Box>
      </Grid>
      <PaddedFooter style={{ paddingLeft: `${!!props.auth && !!props.auth.isDrawerOpen && props.auth.isDrawerOpen == true ? "240px" : "0px"}` }}>
        <Footer />
      </PaddedFooter>
    </Grid>
  );
};
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};
export default connect(mapStateToProps)(AllGigs);
