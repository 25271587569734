import React from 'react';
import { Container, Box, Typography } from '@mui/material';

const unAuthorizedComp = () => {
  return (
    <Container>
      <Box sx={{ maxWidth: 640, margin: 'auto', textAlign: 'center', marginTop: '50px' }}>
        <Typography variant="h3" paragraph>
          Un Authorized Page Request
        </Typography>
        <Typography sx={{ color: 'text.secondary' }}>
          Sorry, You dont have required permission to access this page.
        </Typography>
      </Box>
    </Container>
  );
};

export default unAuthorizedComp;
