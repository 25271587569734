import { Box, Grid, TableContainer, Typography, Paper, Table, TableBody, styled, TableCell, tableCellClasses, TableRow } from '@mui/material';
import React from 'react'
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { CalendarBox, CustomRow, PaddedBox, PaddedFooter } from '../../app.styles';
import DatePicker from '@mui/lab/DatePicker';
import { LocalizationProvider } from '@mui/lab';
import { InputUnstyled } from '@mui/base';
import { useState } from 'react';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import './styles.css'
import { adminReporting } from '../../../services/profile';
import Footer from '../../../components/footer1/footer';
import { downloadFileFromLink } from '../../../utils/helpers';

function Reporting(props) {

    const [startDate, setstartDate] = useState(undefined)
    const [endDate, setendDate] = useState(undefined)

    const dispatch = useDispatch();
    const history = useHistory();

    const [rows, setrows] = useState([
        { name: "Engineer Info", format: "csv", type: "engineerInfo" },
        { name: "Business Info", format: "csv", type: "businessInfo" },
        { name: "Gig Info", format: "csv", type: "gigInfo" },
        { name: "Profile Completion Feedback Info", format: "csv", type: "profileCompletionfeedbackInfo" },
        { name: "After Hire Feedback Info", format: "csv", type: "afterHirefeedbackInfo" },
        { name: "Verification Email Info", format: "csv", type: "verifyEmailInfo" },
    ])

    const handleChangeFormat = (index) => {
        let data = JSON.parse(JSON.stringify(rows));
        data[index].format = (data[index].format == "csv") ? "excel" : "csv";
        setrows(data)
    }

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));

    const downloadCSV = (data, name) => {
        const url = window.URL.createObjectURL(new Blob([data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${name}.csv`)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
    }


    const handleExport = (row, index) => {
        let reqData = {
            "reportingType": row.type,
            "reportingFormat": row.format
        }
        if (!!startDate) {
            reqData.fromDate = startDate
        }
        if (!!endDate) {
            reqData.toDate = endDate
        }
        try {
            adminReporting(reqData).then((e) => {
                if (row.format == "csv") {
                    downloadCSV(e, row.name)
                }
                else if (row.format == "excel") {
                    downloadFileFromLink(e)
                }
                else {
                    console.error("Error while downloading file!")
                }
            }).catch((err) => { console.error(err) })
        }
        catch (e) {
            console.error(e)
        }
    }


    return (
        <>
            <PaddedBox style={{ paddingLeft: `${!!props.auth && !!props.auth.isDrawerOpen && props.auth.isDrawerOpen == true ? "240px" : "0px"}`, minHeight: "580px" }}>
                <CustomRow container>
                    <Typography variant="h6" component="h6" color="pColor.main" sx={{ fontWeight: 'Medium', textTransform: 'capitalize', fontSize: '22px' }}>
                        Reporting
                    </Typography>
                </CustomRow>
                <CustomRow container sx={{ display: "flex", justifyContent: "flex-start", marginTop: "12px", marginLeft: '-17px' }}>
                    <Grid item xs={12} md={3} lg={2} mx={2}>
                        <Box sx={{ marginBottom: '5px', color: 'primary.main', marginTop: '10px' }}>
                            Start Date
                        </Box>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                views={['year', 'month', 'day']}
                                value={new Date(startDate || '')}
                                name="startDateAfter"
                                inputFormat="dd/MM/yyyy"
                                onChange={(newValue) => {
                                    setstartDate(newValue);
                                }}
                                renderInput={({ inputRef, inputProps, InputProps }) => (
                                    <CalendarBox sx={{ border: '1px solid #7E84A3', marginTop: '10px' }}>
                                        {InputProps?.endAdornment}
                                        <InputUnstyled className="calendar-Input" ref={inputRef} {...inputProps} />
                                    </CalendarBox>
                                )}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} md={3} lg={2} mx={2}>
                        <Box sx={{ marginBottom: '5px', color: 'primary.main', marginTop: '10px' }}>
                            End Date
                        </Box>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                views={['year', 'month', 'day']}
                                value={new Date(endDate || '')}
                                name="endDateAfter"
                                inputFormat="dd/MM/yyyy"
                                onChange={(newValue) => {
                                    setendDate(newValue);
                                }}
                                renderInput={({ inputRef, inputProps, InputProps }) => (
                                    <CalendarBox sx={{ border: '1px solid #7E84A3', marginTop: '10px' }}>
                                        {InputProps?.endAdornment}
                                        <InputUnstyled className="calendar-Input" ref={inputRef} {...inputProps} />
                                    </CalendarBox>
                                )}
                            />
                        </LocalizationProvider>
                    </Grid>
                </CustomRow>


                <TableContainer sx={{ marginTop: "16px" }} component={Paper}>
                    <Table aria-label="customized table">
                        <TableBody>
                            {rows.map((row, index) => (
                                <StyledTableRow key={row.name}>
                                    <StyledTableCell component="th" scope="row">
                                        {row.name}
                                    </StyledTableCell>
                                    {/* <StyledTableCell title='Click to change file format' className='format-info' align="center" onClick={() => { handleChangeFormat(index) }}>{row.format}</StyledTableCell> */}
                                    <StyledTableCell className='format-info' align="center">{row.format}</StyledTableCell>
                                    <StyledTableCell align="center"><button className={"export-cta"} onClick={() => { handleExport(row, index) }}>Export</button></StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

            </PaddedBox>
            <PaddedFooter style={{ paddingLeft: `${!!props.auth && !!props.auth.isDrawerOpen && props.auth.isDrawerOpen == true ? "200px" : "0px"}` }}>
                <Footer />
            </PaddedFooter>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        gigs: state.gigs
    };
};

export default connect(mapStateToProps)(Reporting)