export const SET_SFS_SEARCH_KEY = '@gig/sfs/searchkey';
export const SET_SFS_FILTERS_KEY = '@gig/sfs/filters';
export const SET_SFS_SORT_KEY = '@gig/sfs/sort';
export const RESET_SFS = '@gig/sfs/reset';

export const setSearchKey = (data) => ({
  type: SET_SFS_SEARCH_KEY,
  payload: data
});

export const resetSFS = (data) => ({
  type: RESET_SFS,
  payload: data
});

export const setFliters = (data) => ({
  type: SET_SFS_FILTERS_KEY,
  payload: data
});

export const setSortOrder = (data) => ({
  type: SET_SFS_SORT_KEY,
  payload: data
});
