import React, { useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import DragDropiconred from '../../../../assests/DragDropiconred.svg';

const fileTypes = ["JPG", "PNG", "PDF"];

export function DragDropIcon(props) {
  const [file, setFile] = useState(null);
  const handleChange = (file) => {
    setFile(file);
    props.onFileChange(file);
  };
  return (
    <>
      <FileUploader
        children={<div style={{ display: "flex", cursor: 'pointer' }}>
          <div>
            {props.text}
          </div>
          <div style={{ marginLeft: "5px", marginTop: '2px' }}>
            {props.icons}
          </div>
        </div>}
        handleChange={handleChange}
        name="file"
        types={fileTypes} />
    </>
  );
}