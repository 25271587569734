import {
  Button,
  Chip,
  Grid,
  Pagination,
  Typography
} from '@mui/material';
import { Box } from '@mui/system';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import Footer from '../../../components/footer1/footer';
import GridTableNotHover from '../../../components/GridTable/GridTableNotHover';
import PageLoader from '../../../components/Loaders/PageLoader';
import Search from '../../../components/SearchBar/Search';
import { adminProfiles, resendEmailVerification } from '../../../services/owners';
import { resetSFS } from '../../../store/actions/sfs';
import { formatDateWithTime, getQueryParams, scrollOnTop } from '../../../utils/helpers';
import { CustomRow, PaddedBox, PaddedFooter } from '../../app.styles';

const UnverifiedProfiles = (props) => {

  const query = getQueryParams();
  const [isSendingEmail, setResendingEmail] = useState(false);
  const [unverifiedProfiles, setUnverifiedProfiles] = useState([]);
  const [list, setList] = useState([]); // acitve engineer list returned from api
  const [page, setPage] = useState(1); // active enginner list page number
  const [count, setCount] = useState(1); // count of total engineer
  const filterApplied =
    !_isEmpty(props.searchKey) || !_isEmpty(props.filters) || !_isEmpty(props.sortBy);

  const setListData = (data) => {
    setList(_get(data, 'unverifiedProfiles', []));
    setPage(_get(data, 'paginationResult.currentPage', 1));
    setCount(_get(data, 'paginationResult.totalPages', 1));
  };

  const getMessageLink = (emailId) => {
    return (
      <Button
        className="admin-all-btn"
        sx={{
          color: '#FFFFFF',
          borderRadius: '5px',
          width: '127px',
          height: '32px',
          textTransform: 'capitalize'
        }}
        onClick={() => { handleResendEmail(emailId) }}
      >
        Resend Email
      </Button>
    )
  }

  const setData = (data) => {
    const rowData = [];
    const unverifiedProfilesData = _get(data, 'unverifiedProfiles', []);
    const totalRecords = _get(data, 'paginationResult', {});
    if (unverifiedProfilesData?.length) {
      for (let obj of unverifiedProfilesData) {
        const name = _get(obj, 'name', '');
        const emailId = _get(obj, 'emailId', '');
        const role = _get(obj, 'userRole', '');
        const createdAt = formatDateWithTime(_get(obj, 'createdAt', ''));
        const updatedAt = formatDateWithTime(_get(obj, 'updatedAt', ''));
        const id = _get(obj, '_id', '');
        rowData.push([name, emailId, role, createdAt, updatedAt, getMessageLink(emailId)]);
      }
    }
    setUnverifiedProfiles(rowData);
  };

  const fetchData = (currentPage) => {
    const obj = {
      paginationInput: {
        pageNumber: currentPage,
        pageSize: 10
      }
    };

    if (!_isEmpty(props.searchKey)) {
      obj.search = props.searchKey;
    }
    adminProfiles(obj, setData, setListData);
  };
  useEffect(() => {
    fetchData(filterApplied ? 1 : page);
  }, [props.searchKey, props.filters, props.sortBy]);

  const onPaginationClick = (event, value) => {
    scrollOnTop();
    fetchData(value);
    setPage(value);
  };

  const headers = ['Name', 'Email Id', 'Role', 'Created At', 'Updated At', 'Action'];

  const handleResendEmail = async (emailId) => {
    try {
      setResendingEmail(true);
      const resp = await resendEmailVerification(emailId);
      if (resp?.status === 200)
        toast.success(resp.message);
    } catch (error) {
      console.error(error);
      toast.error('Something bad happened', error.message || JSON.stringify(error));
    } finally {
      setResendingEmail(false);
    }
  }

  return (
    <>
      <PaddedBox style={{ textAlign: 'center', paddingLeft: `${!!props.auth && !!props.auth.isDrawerOpen && props.auth.isDrawerOpen == true ? "240px" : "0px"}`, minHeight: "580px" }}>
        <CustomRow container>
          <Typography
            variant="h6"
            component="h6"
            color="pColor.main"
            sx={{ fontWeight: 'Medium', textTransform: 'capitalize', fontSize: '22px', margin: '0px 0 20px 0', alignItems: 'center' }}
          >
            Unverified Profiles
            {filterApplied && (
              <Chip
                label="Remove Filters"
                variant="outlined"
                sx={{
                  fontWeight: 'normal',
                  marginLeft: '10px'
                }}
                onDelete={() => {
                  props.resetSFS('');
                }}
              />
            )}
          </Typography>
        </CustomRow>
        <Grid container item xs="auto" justifyContent="flex-end">
          <div className='search-sort-icon'>
            <Search placeholder="Search" />
          </div>
        </Grid>

        {_isEmpty(unverifiedProfiles) && filterApplied && (
          <Grid
            container
            item
            justifyContent="center"
            xs={12}
            className="h-margin-top-10 h-padding-top-16"
          >
            <Typography variant="h6" component="h6">
              No records found, Please refine your search
            </Typography>
          </Grid>
        )}

        {_isEmpty(unverifiedProfiles) && !filterApplied && (
          <Grid
            container
            item
            justifyContent="center"
            xs={12}
            className="h-margin-top-10 h-padding-top-16"
          >
            <PageLoader />
          </Grid>
        )}

        {isSendingEmail && (
          <Grid
            container
            item
            justifyContent="center"
            xs={12}
            className="h-margin-top-10 h-padding-top-16"
          >
            <PageLoader />
          </Grid>
        )}
        {
          <Grid container className="table-Padding" mt={2}>
            <GridTableNotHover
              headers={headers}
              rows={unverifiedProfiles}
            ></GridTableNotHover>
          </Grid>
        }
        {!_isEmpty(list) && (
          <Box sx={{ padding: '30px 0 10px 0' }}>
            <Pagination
              page={page}
              count={count}
              shape="rounded"
              className="pagination-root"
              onChange={onPaginationClick}
            />
          </Box>
        )}
      </PaddedBox>
      <PaddedFooter style={{ paddingLeft: `${!!props.auth && !!props.auth.isDrawerOpen && props.auth.isDrawerOpen == true ? "200px" : "0px"}` }}>
        <Footer />
      </PaddedFooter>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    searchKey: state.sfs.searchKey,
    filters: state.sfs.filters,
    sortBy: state.sfs.sortBy
  };
};


const mapDispatchToProps = (dispatch) => {
  return {
    resetSFS: (details) => dispatch(resetSFS(details))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UnverifiedProfiles);
