import { SET_AUTH_SIGN_ERROR, SET_DRAWER_DATA, SET_DRAWER_STATE } from '../actions/auth';

const Initial_value = {
  signError: {},
  menuType: undefined,
  routeAuth: undefined,
  isDrawerOpen: undefined,
};

const authRed = (state = Initial_value, action) => {
  switch (action.type) {
    case SET_AUTH_SIGN_ERROR:
      return {
        ...state,
        signError: {
          ...action.payload
        }
      };
    case SET_DRAWER_DATA:
      return {
        ...state,
        menuType: action.payload.menuType,
        routeAuth: action.payload.routeAuth
      };
    case SET_DRAWER_STATE:
      return {
        ...state,
        isDrawerOpen: action.payload.isDrawerOpen
      };
    default:
      return state;
  }
};

export default authRed;
