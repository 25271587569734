import styled from 'styled-components';

export const GigTitleIcon = styled.span`
  && {
    border-radius: 10px;
    border: 4px solid #f99600;
    width: 16px;
    height: 16px;
    margin-right: 5px;
  }
`;

export const GigTitle = styled.span`
  && {
    font-size: 12px;
    font-weight: 500;
    color: #999999;
  }
`;

export const GigPercentitle = styled.div`
  && {
    font-size: 12px;
    color: #7e84a3;
  }
`;

export const GigPercentTile = styled.div`
  ${(props) => `
      width: 13px;
      height: 8px;
      background: ${props.active ? '#E6E9F4' : ' #F99600'} 0% 0% no-repeat padding-box;
      border-radius: 3px;
      margin-right: 5px;
      margin-top: 5px;
  `}
`;
