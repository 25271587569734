import React, { useEffect, useState } from 'react';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _toNumber from 'lodash/toNumber';
import _last from 'lodash/last';
import { PaddedFooter } from '../../app.styles';
import { connect, useDispatch } from 'react-redux';
import '../../gigOwner/gigDashboard/GigDashboard.css';
import './ViewFeedback.scss';
import Footer from '../../../components/footer1/footer';
import ReactStars from "react-rating-stars-component";
import { useHistory, useLocation } from 'react-router-dom';
import { getFeedbackDetail, getGigsById } from '../../../services/gig';
import { formatDate } from '../../../utils/helpers';
import { getUserId } from '../../../utils/user';

const ViewSubmittedFeedback = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const location = useLocation();
    const [referralsData, setreferralsData] = useState(undefined)
    const [referralMessage, setreferralMessage] = useState("")
    const [referralDate, setreferralDate] = useState(undefined)
    const [feedbackTypes, setfeedbackTypes] = useState([
        { "feedbackValue": "Soft Skills", "feedbackRating": 3 },
        { "feedbackValue": "Quality of work", "feedbackRating": 1 },
        { "feedbackValue": "Technical Knowledge", "feedbackRating": 4 },
        { "feedbackValue": "Adherence to schedule", "feedbackRating": 5 },
        { "feedbackValue": "Communication", "feedbackRating": 2 },
        { "feedbackValue": "Co-operation", "feedbackRating": 0 },
        { "feedbackValue": "Value for money", "feedbackRating": 4 },
    ])
    // const feedbackTypes = [
    //     { "feedbackValue": "Soft Skills", "feedbackRating": 3 },
    //     { "feedbackValue": "Quality of work", "feedbackRating": 1 },
    //     { "feedbackValue": "Technical Knowledge", "feedbackRating": 4 },
    //     { "feedbackValue": "Adherence to schedule", "feedbackRating": 5 },
    //     { "feedbackValue": "Communication", "feedbackRating": 2 },
    //     { "feedbackValue": "Co-operation", "feedbackRating": 0 },
    //     { "feedbackValue": "Value for money", "feedbackRating": 4 },
    // ]

    
    useEffect(() => {
        getGigsById(location.pathname.split("/")[location.pathname.split("/").length-1]).then((e)=>{
            if(!!e.gig&&!!e.gig.referralsData){
                e.gig.referralsData.map((item,index)=>{
                    if(item.giverId==getUserId()){
                        setreferralsData(item)
                        setfeedbackTypes([])
                        setreferralDate(item.createdAt)
                        setreferralMessage(item.publicFeedback.feedbackTextData)
                        setfeedbackTypes(item.publicFeedback.feedbackStarData)
                    }
                })
            }
            else{
                toast.warning("No data found!")
            }
        }).catch((err)=>console.error(err))
        // getFeedbackDetail(location,location.pathname.split("/")[location.pathname.split("/").length-1]).then((e)=>{
        //     setreferralsData(e.data.referrals)
        //     setfeedbackTypes([])
        //     setreferralDate(e.data.referrals.createdAt)
        //     setreferralMessage(e.data.referrals.publicFeedback.feedbackTextData)
        //     setfeedbackTypes(e.data.referrals.publicFeedback.feedbackStarData)
        // }).catch((err)=>console.error(err))
    }, [])

    return (
        <React.Fragment>
            <div className='view-feedback-form-wrapper' style={{ paddingLeft: `${!!props.auth && !!props.auth.isDrawerOpen && props.auth.isDrawerOpen == true ? "276px" : "0px"}` }}>
                <div className='action-btns-top-row'>
                    <button className='back-btn-top' onClick={()=>history.goBack()}>{'Back'}</button>
                    <text className='page-heading-txt'>{'View Submitted Feedback'}</text>
                </div>
                <div className='feedback-container'>
                    <div className='star-rating-wrapper'>
                        <div className='star-rating-column'>
                            {feedbackTypes.map((val, idx) => <div className='star-rating-row'>
                                <ReactStars classNames={'stars'} count={5} activeColor="#ffd700" size={25} edit={false} value={Number(val.feedbackRating)} />
                                <div className='rating-cat-name'>{val.feedbackValue}</div>
                            </div>)}
                        </div>
                        <div className='posted-on'>{`Posted on ${!!referralDate?formatDate(referralDate):"01/01/2023"}`}</div>
                    </div>
                    <div className='total-txt'>{`Total - ${!!feedbackTypes && (feedbackTypes.reduce((sum, feedbackType) => Number(sum) + Number(feedbackType.feedbackRating), 0) / Math.max(feedbackTypes.filter(feedbackType => feedbackType.feedbackRating > 0).length, 1)).toFixed(2)}`}</div>
                    <text className='feedback-txt'>{`“${referralMessage}”`}</text>
                </div>
            </div>
            <PaddedFooter style={{ paddingLeft: `${!!props.auth && !!props.auth.isDrawerOpen && props.auth.isDrawerOpen == true ? "240px" : "0px"}` }}>
                <Footer />
            </PaddedFooter>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        // newGig: state.newGig,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        // setNewGigDetails: (data) => dispatch(setNewGigDetails(data))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewSubmittedFeedback);