import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import VerifiedUserOutlinedIcon from '@mui/icons-material/VerifiedUserOutlined';
import Box from '@mui/material/Box';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2)
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1)
    }
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500]
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired
};

export default function CustomizedDialogs(props) {
    const [open, setOpen] = React.useState(props.open);
    console.log(props)

    const handleClose = () => {
        setOpen(false);
        window.location.href = '/signin/';
    };

    return (
        <>
            <BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
                <BootstrapDialogTitle
                    id="customized-dialog-title"
                    onClose={handleClose}
                ></BootstrapDialogTitle>

                <DialogContent>
                    <Box
                        sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '10px', }}
                    >
                        <Typography
                            sx={{ color: "#008000", alignItems: "center" }}
                        >
                            <VerifiedUserOutlinedIcon fontSize="large" />
                        </Typography>
                        <Typography style={{ padding: '10px 5px' }}>
                            Your password has been reset successfully.
                        </Typography>
                    </Box>
                </DialogContent>
            </BootstrapDialog>
        </>
    );
}
